<template>
  <VeeValidateValidationProvider
    ref="input"
    :class="isRequired ? 'v-input-required' : ''"
    v-bind="validationProviderProps"
    v-slot="{ errors }"
  >
    <slot :label="name" :errors="errors" :validate="validate"></slot>
  </VeeValidateValidationProvider>
</template>

<script>
import { ValidationProvider as VeeValidateValidationProvider } from "vee-validate";

export default {
  components: {
    VeeValidateValidationProvider
  },
  // Copied from: https://vee-validate.logaretm.com/v3/api/validation-provider.html#props
  props: {
    bails: {
      type: Boolean,
      default: true
    },
    customMessages: {
      type: Object
    },
    debounce: {
      type: Number
    },
    detectInput: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean
    },
    immediate: {
      type: Boolean
    },
    name: {
      type: String
    },
    rules: {
      type: [String, Object]
    },
    skipIfEmpty: {
      type: Boolean,
      default: true
    },
    tag: {
      type: String
    },
    vid: {
      type: String
    }
  },
  computed: {
    isRequired() {
      const rules = this.rules;
      if (!rules) {
        return false;
      }

      if (typeof rules === "string") {
        return rules.includes("required");
      }

      if (Object.keys(rules).includes("required")) {
        return rules.required;
      }

      return false;
    },
    validationProviderProps() {
      const props = { ...this.$props };

      Object.keys(props).forEach((key) => {
        if (props[key] === undefined) {
          delete props[key];
        }
      });

      return props;
    }
  },
  methods: {
    validate() {
      return this.$refs.input.validate();
    }
  }
};
</script>

<style>
.v-input-required .v-input .v-label::after {
  content: " *";
}
</style>
