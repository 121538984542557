import { TIMEFRAMES } from "@/types/timeframes";
import i18n from "@/plugins/i18n";

export const isTimeframeValid = ({
  activeIds,
  timeframe,
  $notify,
  isObv
} = {}) => {
  let valid = true;
  let message = "";

  if (activeIds === TIMEFRAMES.CUSTOM) {
    if (
      timeframe.start > timeframe.end ||
      (!isObv && timeframe.start >= timeframe.end)
    ) {
      valid = false;
      message += `${i18n.t("validation.timeframe")} `;
    }
    if (!timeframe.start) {
      valid = false;
      message += `${i18n.t("validation.required", {
        field: i18n.t("reports.report-start")
      })} `;
    }
    if (!timeframe.end) {
      valid = false;
      message += `${i18n.t("validation.required", {
        field: i18n.t("reports.report-end")
      })} `;
    }
  }

  if (!valid) {
    $notify({
      group: "error",
      text: message
    });
  }

  return valid;
};
