<script>
import { Bar } from "vue-chartjs";
import colors from "@/colors/colors";

export default {
  extends: Bar,
  props: {
    indicators: {
      type: Array,
      default: () => []
    },
    isThresholds: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    defaultData: [20, 10, 55, 96, 88, 13, 45, 60]
  }),
  computed: {
    legend() {
      return this.indicators.map((i) => i.title);
    },
    annotations() {
      const annotations = [];

      this.indicators.forEach((indicator, index) => {
        const isFirstThreshold = this.isThresholds && index === 0;

        annotations.push({
          id: indicator.id,
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: indicator.value,
          lineColor: indicator.color,
          borderColor: isFirstThreshold ? "transparent" : indicator.color,
          borderDash: [10, 10],
          borderWidth: 2,
          label: {
            enabled: !isFirstThreshold,
            position: "right",
            content: indicator.value
          }
        });
      });

      return annotations;
    },
    data() {
      if (!this.indicators.length || !this.isThresholds) {
        return this.defaultData;
      }

      const indicatorValues = this.indicators.map((i) => parseFloat(i.value));

      const zeroValue = indicatorValues.findIndex((i) => i === 0);
      indicatorValues.splice(zeroValue, 1);

      let maxValue = Math.max(...indicatorValues) + 5;
      if (maxValue > 100) {
        maxValue = 100;
      }

      let minValue = Math.min(...indicatorValues) - 5;
      if (minValue < 0) {
        minValue = 1;
      }

      const values = [minValue, maxValue];

      indicatorValues.forEach((value) => {
        values.splice(-1, 0, value);
      });

      return values;
    },
    labels() {
      return this.data.map((d) => d);
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: this.getBackgroundColor(),
            data: this.data
          }
        ]
      };
    },
    options() {
      const suggestedMax = this.isThresholds
        ? Math.max(...this.data) + 10
        : 105;
      return {
        tooltips: {
          enabled: false
        },
        hover: {
          enabled: false
        },
        spanGaps: true,
        animation: false,
        annotation: {
          events: ["click"],
          annotations: this.annotations
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        legend: {
          display: true,
          labels: {
            generateLabels: this.generateLabels
          }
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          type: "linear",
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true,
                suggestedMax: suggestedMax
              }
            }
          ]
        }
      };
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    getBackgroundColor() {
      if (this.annotations.length === 0) {
        return colors.lightPurellGray;
      } else {
        const backgroundColors = [];

        this.data.forEach((value) => {
          let backgroundColor = colors.lightPurellGray;

          for (let i = 0; i < this.annotations.length; i++) {
            if (this.isThresholds) {
              if (value >= this.annotations[i].value) {
                backgroundColor = this.annotations[i].lineColor;
              }
            } else {
              if (value <= this.annotations[i].value) {
                backgroundColor = this.annotations[i].lineColor;

                break;
              }
            }
          }
          backgroundColors.push(backgroundColor);
        });

        return backgroundColors;
      }
    },
    generateLabels() {
      const labels = [];
      this.indicators.forEach((i) => {
        labels.push({
          text: i.title,
          fillStyle: i.color
        });
      });

      return labels;
    }
  },
  watch: {
    annotations() {
      this.renderChart(this.chartData, this.options);
    },
    legend() {
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>
