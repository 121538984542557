<template>
  <table
    class="
      tw-w-full tw-m-auto tw-max-w-screen-lg tw-border-collapse tw-bg-white
    "
  >
    <thead>
      <tr>
        <th class="tw-text-left tw-font-normal">
          <table class="tw-min-w-full tw-border-collapse">
            <tr>
              <td>
                <table>
                  <tr>
                    <td style="width: 1%">
                      <img
                        :src="logo"
                        max-width="78px"
                        class="tw-mr-6"
                        @load="$data.purellLogoLoaded = true"
                        @error="$data.purellLogoLoaded = true"
                        style="width: 73px; height: 43.33px"
                      />
                    </td>
                    <td>
                      <h1 class="tw-text-2xl tw-font-semibold">{{ title }}</h1>
                      <h2 class="tw-text-base" v-if="facilityName">
                        {{ facilityName }}
                      </h2>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <report-filters-block
                  data-name="go-filter-block"
                  class="tw-bg-lightTertiary"
                />
              </td>
            </tr>
          </table>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <report-filter-details-block
            data-name="go-filter-block-details"
            class="tw-bg-lightTertiary"
          />
        </td>
      </tr>
      <tr>
        <td>
          <slot></slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ReportFiltersBlock from "@/views/reports/components/filters/ReportFiltersBlock.vue";
import ReportFilterDetailsBlock from "@/views/reports/components/filters/ReportFilterDetailsBlock.vue";
import { ALL_REPORT_DISPLAY_NAMES } from "@/types/allReports";
import { mapState } from "vuex";
import logo from "@/assets/images/logos/purellLogo.svg";

export default {
  components: {
    ReportFiltersBlock,
    ReportFilterDetailsBlock
  },
  data: () => ({
    purellLogoLoaded: false,
    componentMounted: false,
    logo
  }),
  computed: {
    ...mapState("application", ["pdfServiceInvocation"]),
    ...mapState("reports", ["exportPdfTitle"]),
    title() {
      return (
        this.exportPdfTitle ||
        this.$t(ALL_REPORT_DISPLAY_NAMES[this.$store.state.reports.reportId])
      );
    },
    facilityName() {
      return this.$store.state.facilities.byId[
        this.$store.state.customers.facilityId
      ]?.text;
    },
    readyToPrint() {
      return this.$data.purellLogoLoaded && this.$data.componentMounted;
    }
  },
  mounted() {
    if (this.pdfServiceInvocation) {
      this.$store.commit(
        "reports/setReportId",
        parseInt(this.$route.query?.reportId)
      );
    }
    this.$data.componentMounted = true;
  },
  watch: {
    readyToPrint() {
      if (this.pdfServiceInvocation) {
        return;
      }
      window.print();
    }
  }
};
</script>

<style lang="postcss" scoped>
[data-name="go-filter-block"] {
  transform: translateY(1.5rem);
}
[data-name="go-filter-block-details"] {
  transform: translateY(-0.5rem);
}
:deep(.chartjs-render-monitor) {
  @apply tw-pointer-events-none;
}
</style>
