import { put, remove, get } from "./apiClient";

export default {
  async getShifts(facilityId) {
    if (!facilityId) {
      throw new Error("Facility ID is required");
    }

    return await get(`facility/${facilityId}/shifts`);
  },
  async updateShifts(facilityId, shifts) {
    return await put(`facility/${facilityId}/shifts`, shifts);
  },
  async deleteShift(shiftId) {
    await remove(`shifts/${shiftId}`);
  }
};
