/* eslint-disable no-unreachable */
import { FILTERS } from "@/types/filters";
import { reportDateRangeConverter } from "@/views/reports/utils/reportDateRangeConverter";
import { PRODUCTS } from "@/types/products";
import { ALL_REPORT_RAW_EXPORT_NAMES } from "@/types/allReports";

function showError({
  commit,
  message,
  retryAction = null,
  retryParameter = null
} = {}) {
  commit(
    "application/SET_ERROR",
    {
      message,
      retryAction,
      retryParameter
    },
    { root: true }
  );
}

export const initialTableColumnSort = {
  sortBy: "",
  sortDesc: null
};

export const initialGraphActions = {
  displayDataLabels: true,
  zoomReport: false,
  displayThresholds: true
};

export const reportsModule = {
  namespaced: true,
  state: () => ({
    csvData: {},
    isSlideshow: false,
    reloadReportFlag: false,
    reportFilters: [],
    forcedReportFilters: [],
    hiddenReportActions: [],
    loadingFilters: true,
    reportLoading: true,
    reportId: undefined,
    changingReport: true,
    exportPdfTitle: "",
    reportDateRange: "",
    rowKey: undefined,
    selectedData: [],
    tableColumnSort: initialTableColumnSort,
    graphActions: initialGraphActions
  }),
  mutations: {
    setReloadReportFlag: (state, value) => (state.reloadReportFlag = value),
    setSlideshowFlag: (state, value) => (state.isSlideshow = value),
    setCsvData: (state, value) => (state.csvData = value),
    setHiddenReportActions: (state, value) =>
      (state.hiddenReportActions = value),
    setReportFilters: (state, value) => (state.reportFilters = value),
    setForcedReportFilters: (state, value) =>
      (state.forcedReportFilters = value),
    setLoadingFilters: (state, value) => (state.loadingFilters = value),
    setReportLoading: (state, value) => (state.reportLoading = value),
    setReportId: (state, reportId) => {
      if (reportId && state.reportId !== reportId) {
        state.changingReport = true;
        state.reportLoading = true;
        state.reportId = reportId;
      }
    },
    setChangingReport: (state, value) => (state.changingReport = value),
    setRowKey: (state, value) => (state.rowKey = value),
    setReportDateRange: (state, value) => (state.reportDateRange = value),
    setTableColumnSort: (state, value) => (state.tableColumnSort = value),
    resetTableColumnSort: (state) =>
      (state.tableColumnSort = initialTableColumnSort),
    setGraphActions: (state, value) =>
      (state.graphActions = value || initialGraphActions),
    resetGraphActions: (state) => (state.graphActions = initialGraphActions),
    setSelectedData: (state, value) => (state.selectedData = value),
    setExportPdfTitle: (state, value) => (state.exportPdfTitle = value)
  },
  getters: {
    filterModel(state, getters, rootState) {
      return {
        jobRoleIds: rootState[`filters/${FILTERS.IMSJOBROLES}`].activeIds,
        obvJobRoleIds: rootState[`filters/${FILTERS.OBVJOBROLES}`].activeIds,
        shiftIds: rootState[`filters/${FILTERS.SHIFTS}`].activeIds,
        locationIds: rootState[`filters/${FILTERS.LOCATIONS}`].activeIds,
        startDate: rootState[`filters/${FILTERS.TIMEFRAME}`].start,
        endDate: rootState[`filters/${FILTERS.TIMEFRAME}`].end,
        reportDateRange: reportDateRangeConverter(
          rootState[`filters/${FILTERS.TIMEFRAME}`].activeIds
        ),
        deviceIds: rootState[`filters/${FILTERS.DEVICES}`].activeIds,
        facilityId: rootState.customers?.facilityId,
        hygieneProtocolOpportunityIds:
          rootState[`filters/${FILTERS.HYGIENEPROTOCOLOPPORTUNITIES}`]
            .activeIds,
        precautionOption:
          rootState[`filters/${FILTERS.PRECAUTIONOPTIONS}`].activeIds,
        activityBy: rootState[`filters/${FILTERS.ACTIVITYBY}`].activeIds,
        obvYears: rootState[`filters/${FILTERS.OBVYEARS}`].activeIds,
        months: rootState[`filters/${FILTERS.MONTHS}`].activeIds,
        daysToReplacement:
          rootState[`filters/${FILTERS.REPLACEMENT_DATE}`].activeIds,
        monthsToExpiration:
          rootState[`filters/${FILTERS.EXPIRATION_DATE}`].activeIds,
        activeObserversOnly:
          rootState[`filters/${FILTERS.OBSERVER_OPTIONS}`].activeIds
      };
    },
    activeFilters(state, getters, rootState) {
      const reportFilters = state.reportFilters;

      if (!reportFilters.length) {
        return reportFilters;
      }

      return Object.values(FILTERS).reduce(function (acc, cur) {
        if (
          reportFilters == null ||
          !reportFilters.map((f) => f.toLowerCase()).includes(cur.toLowerCase())
        ) {
          return acc;
        }
        if (!rootState[`filters/${cur}`].allIds.length) {
          return acc;
        }
        acc.push(cur);
        return acc;
      }, []);
    },
    exportModel(state, getters, rootState) {
      const filterModel = Object.assign({}, getters.filterModel);
      filterModel.employeeIds = [];
      if (Array.isArray(state.serviceReportData)) {
        filterModel.employeeIds = [
          ...new Set(state.serviceReportData.map((d) => d.employeeId))
        ];
      }

      if (
        filterModel.reportDateRange &&
        filterModel.reportDateRange === "custom"
      ) {
        filterModel.startDate = new Date(
          filterModel.startDate -
            filterModel.startDate.getTimezoneOffset() * 60000
        );
        filterModel.endDate = new Date(
          filterModel.endDate - filterModel.endDate.getTimezoneOffset() * 60000
        );
      }

      filterModel.selectedFacility = rootState.customers?.facilityId;
      filterModel.selectedLocations =
        rootState[`filters/${FILTERS.LOCATIONS}`].activeIds;
      filterModel.dateIntervals = 1;
      filterModel.opportunityIds =
        rootState[`filters/${FILTERS.HYGIENEPROTOCOLOPPORTUNITIES}`].activeIds;
      filterModel.PPEPrecautionType =
        rootState[`filters/${FILTERS.PRECAUTIONOPTIONS}`].activeIds;
      filterModel.obvYears = rootState[`filters/${FILTERS.OBVYEARS}`].activeIds;
      filterModel.months = rootState[`filters/${FILTERS.MONTHS}`].activeIds;

      return {
        filterModel,
        selectedReport: ALL_REPORT_RAW_EXPORT_NAMES[state.reportId],
        productId: PRODUCTS[rootState.customers?.productId]
      };
    }
  },
  actions: {
    clearFilters({ commit }) {
      Object.values(FILTERS)
        .filter((f) => f !== FILTERS.EMPLOYEES && f !== FILTERS.TIMEFRAME)
        .forEach((filter) => {
          commit(`filters/${filter}/clear`, null, { root: true });
        });
    },
    resetFilters({ commit }) {
      Object.values(FILTERS)
        .filter((f) => f !== FILTERS.EMPLOYEES)
        .forEach((filter) => {
          commit(`filters/${filter}/reset`, null, { root: true });
        });
    },
    async loadFilters({ state, rootState, commit, dispatch }) {
      const { reportId } = state;
      const { facilityId, customerId, productId } = rootState.customers;

      if (!facilityId || !customerId || !productId) {
        return false;
      }

      commit("setReportLoading", true);
      commit("setLoadingFilters", true);
      await dispatch("clearFilters");
      commit("application/SET_ERROR", null, { root: true });
      try {
        const parallelFilters = Object.values(FILTERS).filter(
          (f) =>
            f !== FILTERS.DEVICES &&
            f !== FILTERS.EMPLOYEES &&
            f !== FILTERS.TIMEFRAME
        );

        await Promise.all(
          parallelFilters.map((filter) =>
            dispatch(
              `filters/${filter}/load`,
              {
                customerId,
                facilityId,
                reportId,
                productId
              },
              { root: true }
            )
          )
        );

        await dispatch(
          `filters/${FILTERS.DEVICES}/load`,
          {
            customerId,
            facilityId,
            reportId
          },
          { root: true }
        );
      } catch (error) {
        showError({
          commit,
          message: error,
          retryAction: "reports/loadFilters"
        });
      } finally {
        commit("setLoadingFilters", false);
      }
    }
  }
};
