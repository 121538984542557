<template>
  <div
    class="
      tw-my-20
      login-form
      tw-border-solid
      tw-border
      tw-border-purellGray
      tw-p-4
      tw-bg-white
      tw-rounded
      tw-w-full
      tw-max-w-xl
      tw-m-auto
    "
  >
    <v-snackbar
      @input="result.display = false"
      :value="result.display"
      :color="result.color"
      :top="true"
      :timeout="5000"
      class="tw-p-12"
    >
      {{ result.content }}
    </v-snackbar>
    <login-logo />
    <h3>{{ $t("account.reset-password.create-new-password") }}</h3>
    <ValidationObserver ref="form" v-slot="{ pristine }">
      <v-form @submit.prevent="validateAndSubmit">
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              vid="password"
              :name="$t('account.manage.new-password')"
              skipIfEmpty
              :rules="{ password: true, required: true }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="password"
                :error-messages="errors"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :label="$t('account.manage.new-password')"
              />
            </ValidationProvider>
            <ValidationProvider
              :name="$t('account.manage.confirm-password')"
              skipIfEmpty
              :rules="{ required: true, confirmed: 'password' }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="confirmPassword"
                :error-messages="errors"
                :type="showConfirmPassword ? 'text' : 'password'"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                :label="$t('account.manage.confirm-password')"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" class="tw-pb-5">
            <password-checker class="tw-mb-5 md:tw-mb-0" :password="password" />
          </v-col>
        </v-row>
        <v-btn
          :disabled="pristine"
          :loading="loading"
          block
          class="tw-mb-5"
          color="primary"
          type="submit"
        >
          {{ $t("log-in.form.submit") }}
        </v-btn>
      </v-form>
    </ValidationObserver>
    <div class="tw-flex tw-justify-center">
      <country-dropdown />
    </div>
  </div>
</template>

<script>
import LoginLogo from "@/views/login/components/Logo.vue";
import PasswordChecker from "@/components/layout/PasswordChecker.vue";
import CountryDropdown from "@/components/ui/CountryDropdown.vue";
import accountService from "@/api/accountService";
import i18n from "@/plugins/i18n";
import { ROUTE_NAMES } from "@/types/routeNames";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    guid: {
      type: String
    }
  },
  components: {
    LoginLogo,
    PasswordChecker,
    CountryDropdown
  },
  data: () => ({
    result: {
      display: false,
      content: "",
      color: ""
    },
    loading: false,
    password: "",
    showPassword: false,
    confirmPassword: "",
    showConfirmPassword: false
  }),
  methods: {
    async validateAndSubmit() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);
      } else {
        this.sendForgotPasswordForm();
      }
    },
    async sendForgotPasswordForm() {
      this.loading = true;
      try {
        await accountService.resetPassword({
          resetPasswordGuid: this.guid,
          password: this.password,
          confirmPassword: this.confirmPassword
        });

        this.handleSuccess();
      } catch (error) {
        this.handleError(error);
      }
    },
    async handleSuccess() {
      this.loading = false;
      this.result.display = true;

      this.result.color = "primary";
      this.result.content = i18n.t(
        "account.reset-password.results.password-success"
      );
      setTimeout(async () => {
        this.$router.push({ name: ROUTE_NAMES.login });
      }, 5000);
    },
    async handleError(status) {
      this.loading = false;
      this.result.display = true;

      switch (status) {
        case 409:
          this.result.color = "error";
          this.result.content = i18n.t(
            "account.reset-password.results.password-conflict"
          );
          break;
        default:
          this.result.color = "error";
          this.result.content = i18n.t(
            "account.manage.results.something-went-wrong"
          );
          break;
      }
    }
  }
};
</script>
