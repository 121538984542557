import { post, get } from "./apiClient";

export default {
  async login(email, password, rememberMe) {
    const user = {
      UserName: email,
      Password: password,
      RememberMe: rememberMe
    };

    await post(`login`, user);
  },
  async logOut() {
    await post(`logout`);
  },
  async forgotPassword(email) {
    const forgottenPasswordViewModel = {
      Email: email
    };

    await post(`forgotPassword`, forgottenPasswordViewModel);
  },
  async resetPassword(data) {
    await post(`account/resetPassword`, data);
  },
  async manageAccount(data) {
    return await post(`account/manage`, data);
  },
  async getUnregisteredUser(guid) {
    return await get(`unregisteredUser?guid=${guid}`);
  },
  async registerAccount(data) {
    return await post(`account/register`, data);
  }
};
