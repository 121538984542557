import Vue from "vue";
import appStore from "@/store/appStore";
import i18n from "@/plugins/i18n";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
const axios = require("axios");

const baseURL = "/api/";
let currentAbortControllers = [];

export const get = async (
  url = "",
  requestOverrides,
  enforceSingleRequestKey
) => {
  return await fetchInternal(
    url,
    "GET",
    {},
    requestOverrides,
    enforceSingleRequestKey
  );
};

export const post = async (
  url = "",
  body = {},
  requestOverrides,
  enforceSingleRequestKey
) => {
  return await fetchInternal(
    url,
    "POST",
    body,
    requestOverrides,
    enforceSingleRequestKey
  );
};

export const put = async (
  url = "",
  body = {},
  requestOverrides,
  enforceSingleRequestKey
) => {
  return await fetchInternal(
    url,
    "PUT",
    body,
    requestOverrides,
    enforceSingleRequestKey
  );
};

export const remove = async (
  url = "",
  body = {},
  requestOverrides,
  enforceSingleRequestKey
) => {
  return await fetchInternal(
    url,
    "DELETE",
    body,
    requestOverrides,
    enforceSingleRequestKey
  );
};

const fetchInternal = async (
  url,
  method,
  body,
  requestOverrides,
  enforceSingleRequestKey
) => {
  const requestObject = {
    url: `${baseURL}${url}`,
    method: method,
    headers: {
      "Content-Type": "application/json"
    },
    data: body,
    ...requestOverrides
  };

  if (enforceSingleRequestKey) {
    requestObject.signal = setupAbortableRequest(enforceSingleRequestKey);
  }

  const requestTimestamp = Date.now();
  let apiResponse;
  await axios(requestObject)
    .then(function (response) {
      apiResponse = response.data;
    })
    .catch(function (error) {
      handleError(error);
    });

  try {
    const e = document.createEvent("CustomEvent");
    e.initCustomEvent("apiRequestTime", false, false, {
      requestTime: requestTimestamp
    });
    window.dispatchEvent(e);
  } catch {
    window.dispatchEvent(
      new CustomEvent("apiRequestTime", {
        detail: { requestTime: requestTimestamp }
      })
    );
  }

  if (enforceSingleRequestKey) {
    currentAbortControllers = currentAbortControllers.filter(function (
      abortController
    ) {
      return abortController.key !== enforceSingleRequestKey;
    });
  }

  return apiResponse;
};

function setupAbortableRequest(enforceSingleRequestKey) {
  const matchingAbortControllers = currentAbortControllers.filter(function (
    abortController
  ) {
    return abortController.key === enforceSingleRequestKey;
  });

  if (matchingAbortControllers.length) {
    matchingAbortControllers.forEach((abortController) =>
      abortController.abort()
    );
  }

  const abortController = new AbortController();
  abortController.key = enforceSingleRequestKey;
  currentAbortControllers.push(abortController);

  return abortController.signal;
}

function handleError(error) {
  if (error.message === "canceled") {
    throw "Request canceled";
  }

  const status = error.response?.status;
  if (status === 401 && window.location.pathname.toLowerCase() !== "/login") {
    window.location.href = `/login?returnUrl=${encodeURI(
      window.location.pathname
    )}${window.location.search}`;
  }

  if (status === 403) {
    Vue.prototype.$appInsights?.trackEvent({
      name: "User Received Forbidden Permissions"
    });

    appStore.commit("application/SET_ERROR", {
      message: i18n.t("errors.forbidden-permissions")
    });
  }

  throw status;
}
