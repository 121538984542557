<template>
  <div>
    <report-block-row :items="firstRow" />
    <report-block-row :items="secondRow" />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import ReportBlockRow from "@/views/reports/components/reportSections/reportBlock/ReportBlockRow.vue";
import { mapState } from "vuex";
import reportMixin from "@/views/reports/mixins/reportMixin";
import { organizeRow } from "../components/reportSections/reportBlock/reportBlockUtils";
import { SA_REPORTS } from "@/types/saReports";

export default {
  mixins: [reportMixin],
  components: {
    ReportBlockRow
  },
  data() {
    return {
      compatibleReportId: 43,
      hiddenReportActions: ["print", "csvExport", "rawExport"],
      reportFilters: [""],
      items: {},
      firstRow: [],
      secondRow: [],
      refreshInterval: 60000
    };
  },
  computed: {
    ...mapState("reports", ["isSlideshow"]),
    ...mapState("customers", ["facilityId"]),
    hasRefillsWithExpirationDate() {
      return this.$store.state.facilities?.byId[this.facilityId]
        ?.hasRefillsWithExpirationDate;
    }
  },
  methods: {
    createFirstRow() {
      const firstRow = [
        {
          title: i18n.t("reports.sa.battery-alerts"),
          dataKey: "data",
          value: "lowBatteries",
          display: "Number",
          color: "blue",
          reportId: SA_REPORTS.BATTERY_ALERTS
        },
        {
          title: i18n.t("reports.sa.device-alerts"),
          dataKey: "data",
          value: "deviceFaultAlarms",
          display: "Number",
          color: "indigo",
          reportId: SA_REPORTS.DEVICE_ALERTS
        },
        {
          title: i18n.t("reports.sa.offline-devices"),
          dataKey: "data",
          value: "offlineDispensers",
          display: "Number",
          color: "purple",
          reportId: SA_REPORTS.OFFLINE_DEVICES
        }
      ];
      this.firstRow = organizeRow(firstRow, this.items);
    },
    createSecondRow() {
      const secondRow = [
        {
          title: i18n.t("reports.sa.refill-alerts"),
          dataKey: "data",
          value: "lowRefillDispensers",
          display: "Number",
          color: "green",
          reportId: SA_REPORTS.REFILL_ALERTS
        }
      ];
      if (this.hasRefillsWithExpirationDate) {
        secondRow.push({
          title: i18n.t("reports.sa.expiration-alerts"),
          color: "sky-blue",
          reportId: SA_REPORTS.EXPIRATION_ALERTS,
          rows: [
            {
              value: "expiredRefills",
              display: "Number",
              dataKey: "data",
              text: i18n.t("reports.sa.headers.already-expired")
            },
            {
              value: "expiresWithinThreeMonths",
              display: "Number",
              dataKey: "data",
              text: i18n.t("reports.sa.headers.expiring-three-months")
            }
          ]
        });
      }
      this.secondRow = organizeRow(secondRow, this.items);
    },
    handleReportResponse() {
      this.items = {
        data: {
          ...this.reportResponse
        }
      };
      this.createFirstRow();
      this.createSecondRow();
    }
  }
};
</script>
