<template>
  <div class="tw-mt-3">
    <div v-if="loading">
      <p>
        {{ $t("admin.obv-job-role.loading-facilities") }}
      </p>
      <v-progress-linear indeterminate />
    </div>
    <ValidationObserver v-else ref="copyObvJobRoleForm" v-slot="{ pristine }">
      <v-form @submit.prevent="validateAndSubmit">
        <ValidationProvider
          :name="$t('admin.facility-dashboard.obv-job-roles')"
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-select
            v-model="selectedJobRoleIds"
            :error-messages="errors"
            :label="label"
            :placeholder="$t('admin.obv-job-role.select')"
            :items="allJobRoles"
            deletable-chips
            clearable
            chips
            multiple
          />
        </ValidationProvider>
        <ValidationProvider
          :name="$t('admin.customer-dashboard.facilities')"
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-select
            v-model="selectedFacilityIds"
            :error-messages="errors"
            :label="label"
            :placeholder="$t('admin.obv-job-role.select-facilities')"
            :items="allFacilities"
            deletable-chips
            clearable
            chips
            multiple
          />
        </ValidationProvider>
        <v-row dense class="tw-mb-3">
          <v-col cols="12" lg="6">
            <v-btn
              class="tw-mr-5 tw-w-24"
              :disabled="pristine"
              :loading="waiting"
              color="primary"
              type="submit"
            >
              {{ $t("ui.actions.save") }}
            </v-btn>
            <v-btn
              class="tw-mr-5 tw-w-24"
              color="background2"
              @click="closeCloneJobRoleView"
            >
              {{ $t("ui.actions.cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import customerService from "@/api/customerService";
import obvJobRoleService from "@/api/obvJobRoleService";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import { PRODUCTS } from "@/types/products";
import i18n from "@/plugins/i18n";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    customerId: { type: Number },
    facilityId: { type: Number },
    jobRoles: { type: Array, default() {} },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      loading: false,
      waiting: false,
      PERMISSIONS,
      allJobRoles: [],
      selectedJobRoleIds: [],
      allFacilities: [],
      selectedFacilityIds: []
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"])
  },
  async created() {
    this.$appInsights?.trackEvent({
      name: `Clone OBV Job Roles View Loaded`
    });
    this.loading = true;

    const facilities = await customerService.getListOfFacilities(
      this.customerId
    );
    const obvFacilities = facilities.filter(
      (facility) =>
        facility.licenses.some((license) => license === PRODUCTS.OBS) &&
        facility.facilityId !== this.facilityId
    );
    this.allFacilities = obvFacilities.map((facility) => {
      return {
        text: facility.facilityName,
        value: facility.facilityId
      };
    });

    this.allJobRoles = this.jobRoles.map((jobRole) => {
      return {
        text: jobRole.name,
        value: jobRole.id
      };
    });
    this.selectedJobRoleIds = this.jobRoles.map((jobRole) => jobRole.id);

    this.loading = false;
  },
  methods: {
    setScrollPosition() {
      document.querySelector(
        "div.obv-job-roles-data-table div.v-data-table__wrapper"
      ).scrollTop = 0;
    },
    async validateAndSubmit() {
      const valid = await this.$refs.copyObvJobRoleForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);
        return;
      }
      this.waiting = true;

      try {
        await obvJobRoleService.cloneJobRolesToFacilities({
          jobRoleIds: this.selectedJobRoleIds,
          facilityIds: this.selectedFacilityIds,
          originalFacilityId: this.facilityId,
          customerId: this.customerId
        });
        this.$appInsights?.trackEvent({
          name: `Clone OBV Job Roles Form Success`
        });

        this.waiting = false;

        this.setLocalizedWidgetMessage({
          message: i18n.t("admin.obv-job-role.success-messages.copy")
        });

        this.closeCloneJobRoleView();
      } catch (err) {
        this.$appInsights?.trackEvent({
          name: `Clone OBV Job Roles Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.obv-job-role.error-messages.copy")
        });
        this.waiting = false;
      }
    },
    closeCloneJobRoleView() {
      this.$emit("close-clone-job-role-view");
    }
  }
};
</script>
