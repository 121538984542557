<template>
  <div>
    <div class="tw-flex">
      <v-list-item v-if="showSelectAll" ripple @click="toggleSelectAll">
        <v-list-item-action>
          <v-icon :color="iconColor">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("ui.checkbox-popup.select-all") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hierarchyItems.length"
        ripple
        @click="$emit('update:allOpen', !allOpen)"
      >
        <v-list-item-content>
          <v-list-item-title v-if="allOpen">
            <v-icon left> mdi-collapse-all </v-icon>
            {{ $t("ui.collapse-all") }}
          </v-list-item-title>
          <v-list-item-title v-else>
            <v-icon left> mdi-expand-all </v-icon>
            {{ $t("ui.expand-all") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-divider class="tw-mt-2" />
  </div>
</template>

<script>
export const ALL_SELECTED_ICON = "mdi-close-box";
export const SOME_SELECTED_ICON = "mdi-minus-box";
export const NONE_SELECTED_ICON = "mdi-checkbox-blank-outline";

export default {
  name: "filters-popup-header",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    allIds: {
      type: Array,
      default: () => []
    },
    allOpen: {
      type: Boolean,
      default: false
    },
    hierarchyItems: {
      type: Array,
      default: () => []
    },
    orderedItems: {
      type: Array,
      default: () => []
    },
    showSelectAll: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    allSelected() {
      return this.value.length === this.allIds.length;
    },
    someSelected() {
      return this.value.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) {
        return ALL_SELECTED_ICON;
      }
      if (this.someSelected) {
        return SOME_SELECTED_ICON;
      }
      return NONE_SELECTED_ICON;
    },
    iconColor() {
      if (this.someSelected) {
        return "indigo darken-4";
      } else {
        return "";
      }
    }
  },
  methods: {
    toggleSelectAll() {
      if (this.allSelected) {
        this.$emit("input", []);
      } else {
        this.$emit("input", this.allIds);
      }
    }
  }
};
</script>
