export const REPLACEMENT_DATES = {
  ALL: null,
  TODAY: 0,
  TOMORROW: 1,
  NEXT_7_DAYS: 7
};

export const REPLACEMENT_DATES_STRINGS = {
  all: REPLACEMENT_DATES.ALL,
  today: REPLACEMENT_DATES.TODAY,
  tomorrow: REPLACEMENT_DATES.TOMORROW,
  nextSevenDays: REPLACEMENT_DATES.NEXT_7_DAYS
};
