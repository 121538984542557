import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportPerformanceThresholds from "@/views/reports/components/reportSections/ReportPerformanceThresholds.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";

import { sumEntries, createPercentage } from "@/views/reports/utils/calculate";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportPerformanceThresholds,
    ReportGraph
  },
  data() {
    return {
      reportFilters: ["shifts", "locations", "timeframe"],
      hiddenReportActions: [],
      performanceThresholds: {},
      cells: [],
      items: [],
      chartData: {},
      headers: [
        {
          text: i18n.t("reports.date"),
          value: "reportDate",
          display: "Date"
        },
        {
          text: i18n.t("reports.events"),
          value: "numberOfEvents"
        },
        {
          text: i18n.t("reports.opportunities"),
          value: "numberOfOpportunities"
        },
        {
          text: i18n.t("reports.perf-rate"),
          value: "complianceIndex",
          display: "Percentage"
        }
      ]
    };
  },
  computed: {
    showGraphThresholdsActions() {
      return this.performanceThresholds?.thresholds?.length > 0;
    },
    valueScaleTicks() {
      const ticksObject = {
        format: "${value}%"
      };

      if (!this.graphActions.zoomReport) {
        return ticksObject;
      }

      const itemValues = this.items.map((item) =>
        Math.round(item.complianceIndex)
      );
      const thresholdValues = this.performanceThresholds.thresholds
        .filter((threshold) => threshold.value !== 0)
        .map((threshold) => threshold.value);

      const itemMin = Math.min(...itemValues);
      const thresholdMin = Math.min(...thresholdValues);
      let min =
        itemMin <= thresholdMin
          ? Math.floor(itemMin / 10) * 10
          : Math.floor(thresholdMin / 10) * 10;

      if (min === itemMin || min === thresholdMin) {
        min = min - 10;
      }

      const itemMax = Math.max(...itemValues);
      const thresholdMax = Math.max(...thresholdValues);
      let max =
        itemMax <= thresholdMax
          ? Math.ceil(thresholdMax / 10) * 10
          : Math.ceil(itemMax / 10) * 10;

      if (max === itemMax || min === thresholdMax) {
        max = max + 10;
      }

      ticksObject.min = min < 0 ? 0 : min;
      ticksObject.max = max > 100 ? 100 : max;

      return ticksObject;
    }
  },
  methods: {
    createGraph() {
      const builder = new ChartBuilder({
        displayLegend: false,
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });
      builder
        .chartTimeSeries({
          type: this.chartType,
          items: this.items,
          valueField: "complianceIndex",
          valueFieldType: GraphDataTypes.FIXED,
          valueScaleOptions: {
            label: i18n.t("reports.perf-rate"),
            position: "left",
            ticks: this.valueScaleTicks
          },
          dateTimeField: "reportDate",
          timeframe: this.$store.state["filters/timeframe"]
        })
        .valueScale.showPerformanceThresholds(
          this.graphActions.displayThresholds
            ? this.performanceThresholds.thresholds
            : []
        );
      this.chartData = builder.build();
    },
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    createSummary() {
      const data = this.reportResponse.dataPoints;
      const totalEvents = sumEntries({
        entries: data,
        inputField: "numberOfEvents"
      });
      const totalOpportunities = sumEntries({
        entries: data,
        inputField: "numberOfOpportunities"
      });
      const totalPercentage = createPercentage({
        dividend: totalEvents,
        divisor: totalOpportunities
      });

      this.cells = [
        {
          text: i18n.t("reports.events"),
          value: totalEvents,
          display: "Locale"
        },
        {
          text: i18n.t("reports.opportunities"),
          value: totalOpportunities,
          display: "Locale"
        },
        {
          text: i18n.t("reports.performance-rate"),
          value: totalPercentage,
          display: "Percentage"
        }
      ];
    },
    handleReportResponse() {
      const reportResponse = this.reportResponse;

      this.createSummary();
      this.items = reportResponse.dataPoints;
      this.performanceThresholds = reportResponse.performanceThresholds || {};
      this.createGraph();
    }
  },
  watch: {
    graphActions() {
      if (!this.reportLoading) {
        this.createGraph();
      }
    }
  }
};
