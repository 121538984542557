import { createPercentage } from "@/views/reports/utils/calculate";

export function buildTotalRow(value, numberOfColumns) {
  const newColumns = [];
  for (let i = 0; i < numberOfColumns; i++) {
    let opportunities = 0;
    let events = 0;

    value.forEach((item) => {
      opportunities += item[`column${i}Opportunities`];
      events += item[`column${i}Events`];
    });

    const percentage = createPercentage({
      dividend: events,
      divisor: opportunities
    });

    newColumns.push({
      percentage,
      number: opportunities
    });
  }

  return newColumns;
}
