import { post } from "./apiClient";

export default {
  async post({ viewModel }) {
    if (!viewModel) {
      throw new Error("View Model is required");
    }

    await post(`reportExport/${viewModel.productId}/raw`, viewModel);
  }
};
