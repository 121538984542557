import Vue from "vue";
import App from "./App.vue";
import store from "./store/appStore";
import Router from "vue-router";
Vue.use(Router);
import appRouter from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "@/plugins/vueNotification";
import "@/plugins/veeValidateRules";
import "@/plugins/vuetifyDatetimePicker";
import "@/assets/styles/tailwind.css";

Vue.config.productionTip = false;
window.Vue = Vue;

if (process.env.NODE_ENV !== "production") {
  Vue.config.devtools = true;
}

if (document.querySelector("#app")) {
  if (window.$isSpa) {
    new Vue({
      store,
      router: appRouter,
      vuetify,
      i18n,
      render: (h) => h(App),
      created() {}
    }).$mount("#app");
  }
}
