import { stringTranslationSubstitute } from "@/utils/stringUtils";
import { determineThresholdColor } from "@/utils/performanceBandCalculator";

export function organizeRow(blockDefinitions, data, thresholds = null) {
  const blocks = [];

  blockDefinitions.forEach((block) => {
    const body =
      block.rows == null
        ? data[block.dataKey]
          ? data[block.dataKey][block.value]
          : null
        : block.rows.map((row) => {
            return {
              value: data[row.dataKey][row.value],
              display: row.display,
              text: stringTranslationSubstitute(row.text)
            };
          });

    blocks.push({
      title: stringTranslationSubstitute(block.title),
      body,
      display: block.display,
      bgColor: `tw-bg-smartlink-summary-${block.color}-light`,
      headerColor: `tw-bg-smartlink-summary-${block.color}`,
      thresholdColor: determineThresholdColor(thresholds, body),
      reportId: block.reportId
    });
  });

  return blocks;
}
