export default function roundValue(value) {
  const formatValue =
    typeof value === "string" ? value.replace(/,/g, ".") : value;
  if (!Number(formatValue)) {
    return formatValue;
  }

  const roundedValue = Math.round(formatValue * 10) / 10;

  return roundedValue.toFixed(1);
}
