<template>
  <div>
    <div v-if="displayNewJobRoleView">
      <manage-ims-job-role
        :customerId="customerId"
        :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
        @close-create-job-role-view="closeCreateJobRoleView"
      />
    </div>
    <div v-show="!displayNewJobRoleView">
      <v-data-table
        class="ims-job-roles-data-table small-headers"
        item-key="id"
        :items="jobRoles"
        :headers="headers"
        :search="search"
        :loading="loading"
        :loading-text="$t('admin.customer-dashboard.job-roles-widget.loading')"
        :no-data-text="$t('admin.customer-dashboard.job-roles-widget.empty')"
        :height="fullscreen ? '55vh' : ''"
        @update:page="setScrollPosition"
      >
        <template v-slot:top>
          <div
            class="
              tw-flex
              tw-justify-between
              tw-items-center
              tw-sticky
              tw-top-0
              tw-bg-white
            "
            style="z-index: 2"
          >
            <v-text-field
              v-model="search"
              :label="`${$t('ui.input-select.search')}`"
              class="tw-mr-4"
              clearable
              prepend-inner-icon="mdi-magnify"
            />
            <v-btn
              v-if="checkPermission(PERMISSIONS.manageJobRoles)"
              class="tw-max-w-xs"
              primary
              color="primary"
              @click="openCreateJobRoleView"
            >
              {{ $t("admin.customer-dashboard.job-roles-widget.new-job-role") }}
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-switch
            v-model="item.isActive"
            :loading="item.isLoading"
            :readonly="
              checkPermission(PERMISSIONS.manageJobRoles) && item.hasAssignment
            "
            :disabled="
              !checkPermission(PERMISSIONS.manageJobRoles) || item.hasAssignment
            "
            @change="toggleActiveStatus(item)"
            @click="checkAssignment(item)"
          />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ManageImsJobRole from "@/views/admin/components/customerWidgets/manageWidgetViews/ManageImsJobRole.vue";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import imsJobRoleService from "@/api/imsJobRoleService";
import { orderBy } from "lodash";
import i18n from "@/plugins/i18n";

export default {
  components: {
    ManageImsJobRole
  },
  props: {
    customerId: { type: Number },
    fullscreen: { type: Boolean, default: false },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      loading: true,
      PERMISSIONS,
      search: "",
      jobRoles: [],
      headers: [
        {
          text: i18n.t("admin.customer-dashboard.job-roles-widget.job-role"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-dashboard.job-roles-widget.job-type"),
          value: "jobType",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-dashboard.job-roles-widget.active"),
          value: "isActive",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      displayNewJobRoleView: false
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"])
  },
  async mounted() {
    this.loadJobRoles();
  },
  methods: {
    async loadJobRoles() {
      try {
        const jobRoles = orderBy(
          await imsJobRoleService.getJobRolesByCustomerId(this.customerId),
          ["name"]
        );
        jobRoles.forEach((f) => (f.isLoading = false));
        this.jobRoles = jobRoles;
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.job-roles-widget.error-messages.get-job-roles"
          )
        });
      } finally {
        this.loading = false;
      }
    },
    async toggleActiveStatus(item) {
      try {
        item.isLoading = true;
        await imsJobRoleService.setActiveStatus({
          customerId: this.customerId,
          ...item
        });
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.job-roles-widget.error-messages.set-active-status"
          )
        });
        item.isActive = !item.isActive;
      } finally {
        item.isLoading = false;
      }
    },
    checkAssignment(item) {
      if (item.hasAssignment) {
        this.setLocalizedWidgetMessage({
          message: i18n.t(
            "admin.customer-dashboard.job-roles-widget.error-messages.set-active-status-assignment"
          ),
          showError: true,
          customSnackbarTimeout: 5000
        });
      }
    },
    setScrollPosition() {
      document.querySelector(
        "div.ims-job-roles-data-table div.v-data-table__wrapper"
      ).scrollTop = 0;
    },
    openCreateJobRoleView() {
      this.displayNewJobRoleView = true;
    },
    closeCreateJobRoleView() {
      this.displayNewJobRoleView = false;
      this.loadJobRoles();
    }
  }
};
</script>
