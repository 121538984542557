import { get, post, put, remove } from "./apiClient";

export default {
  async convertAndEditDevicePlaceholder(
    facilityId,
    placeHolderId,
    serialNumber,
    deviceUpdates
  ) {
    return await put(
      `facility/${facilityId}/devicePlaceholders/${placeHolderId}/convertandedit`,
      { serialNumber, deviceUpdates }
    );
  },
  async convertDevicePlaceholder(
    facilityId,
    devicePlaceholderId,
    serialNumber
  ) {
    return await put(
      `facility/${facilityId}/devicePlaceholders/${devicePlaceholderId}/convert`,
      {
        serialNumber
      }
    );
  },
  async createRoomTemplatePlaceholders(facilityId, locationIds) {
    return await post(`roomTemplateDevicePlaceholders`, {
      facilityId,
      locationIds
    });
  },
  async createDevicePlaceholder(locationIds, devicePlaceholder) {
    return await post(`devicePlaceholders`, { locationIds, devicePlaceholder });
  },
  async deletePlaceholder(facilityId, deviceId) {
    await remove(`facility/${facilityId}/devicePlaceholders/${deviceId}`);
  },
  async editDevicePlaceholder(devicePlaceholderId, devicePlaceholder) {
    return await put(
      `devicePlaceholders/${devicePlaceholderId}`,
      devicePlaceholder
    );
  },
  async getById(devicePlaceholderId) {
    return await get(`devicePlaceholders/${devicePlaceholderId}`);
  }
};
