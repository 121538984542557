import i18n from "@/plugins/i18n";

export default {
  sortById(state, isDescending) {
    return Object.values(state.byId)?.sort((a, b) => {
      return isDescending ? b.id - a.id : a.id - b.id;
    });
  },
  sortByText(state) {
    return Object.values(state.byId)?.sort((a, b) =>
      a.text.localeCompare(b.text)
    );
  },
  defaultSelectedText(
    state,
    getters,
    options = { emptyText: "ui.none-selected", textKey: "text" }
  ) {
    const { activeIds } = state;
    const { selectableIds } = getters;
    if (!selectableIds || !selectableIds.length) {
      return null;
    }
    if (
      activeIds === undefined ||
      (activeIds instanceof Array && activeIds.length === 0)
    ) {
      return i18n.t(options.emptyText);
    }
    const ids = activeIds instanceof Array ? activeIds : [activeIds];
    return getters.orderedItems
      .filter((item) => ids.includes(item.id))
      .map((item) => item[options.textKey])
      .join(", ");
  },
  defaultFilterText(state, getters) {
    const { activeIds } = state;
    const { selectableIds } = getters;
    if (selectableIds.length === activeIds.length) {
      return i18n.t("ui.all-selected");
    }
    if (activeIds.length === 0) {
      return getters.selectedText(state);
    }
    return `${i18n.t("count.count-of-total", {
      count: activeIds instanceof Array ? activeIds.length : 1,
      total: selectableIds.length
    })}: ${getters.selectedText(state)}`;
  },
  defaultGetSelectableIds({ allIds }) {
    return [...allIds];
  },
  getShiftsOrderedItems(state, getters, rootState) {
    const orderedItems = [...Object.values(state.byId)];

    orderedItems.forEach((item, index) => {
      const startTime = item.startTime.split(":");
      const endTime = item.endTime.split(":");

      orderedItems[index].text = `${item.name} (${new Date(
        new Date().setHours(startTime[0], startTime[1], 0, 0)
      ).toLocaleTimeString(rootState.preferences.language, {
        hour: "2-digit",
        minute: "2-digit"
      })} - ${new Date(
        new Date().setHours(endTime[0], endTime[1], 0, 0)
      ).toLocaleTimeString(rootState.preferences.language, {
        hour: "2-digit",
        minute: "2-digit"
      })})`;
    });

    return orderedItems.sort((a, b) => a.text.localeCompare(b.text));
  }
};
