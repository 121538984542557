<template>
  <v-snackbar
    @input="$emit('report-actions-snackbar-closed')"
    :value="value"
    :color="reportActionsSnackbarContent.color"
    :top="true"
    :timeout="reportActionsSnackbarContent.disableTimeout ? -1 : 5000"
    class="tw-p-12"
  >
    <div v-if="reportActionsSnackbarContent.button">
      <span
        :class="
          reportActionsSnackbarContent.textColor
            ? `tw-text-${reportActionsSnackbarContent.textColor}`
            : `tw-text-purellGray`
        "
      >
        {{ reportActionsSnackbarContent.content }}
      </span>
      <v-btn
        :href="reportActionsSnackbarContent.button.link"
        :target="reportActionsSnackbarContent.button.target || '_self'"
        color="primary"
        style="margin-left: 10px"
      >
        {{ reportActionsSnackbarContent.button.text }}
      </v-btn>
    </div>
    <div v-else>
      <span
        :class="
          reportActionsSnackbarContent.textColor
            ? `tw-text-${reportActionsSnackbarContent.textColor}`
            : `tw-text-purellGray`
        "
      >
        {{ reportActionsSnackbarContent.content }}
      </span>
    </div>
    <template v-slot:action="{ close }">
      <v-btn
        icon
        @click="(e) => $emit('report-actions-snackbar-closed')"
        v-bind="close"
      >
        <v-icon
          :color="
            reportActionsSnackbarContent.textColor
              ? `${reportActionsSnackbarContent.textColor}`
              : `purellGray`
          "
        >
          mdi-close-thick
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    reportActionsSnackbarContent: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
