import { makeStaticResourceModule } from "./makeModule";
import userPermissionsService from "@/api/userPermissionsService";

const permissions = makeStaticResourceModule({
  service: userPermissionsService,
  getters: {
    checkPermission: (state) => (permission) => {
      return state[permission] ?? false;
    }
  },
  normalizer: (permissionChecksResponse) =>
    permissionChecksResponse?.permissionResponses?.length
      ? Object.fromEntries(
          permissionChecksResponse.permissionResponses.map(
            (permissionResponse) => [
              permissionResponse.permissionRequestName,
              permissionResponse.hasPermission
            ]
          )
        )
      : {}
});

export default permissions;
