<template>
  <div>
    <report-table
      :headers="reportHeaders"
      :items="items"
      :initialSortedColumns="['estimatedReplacementDate']"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import reportMixin from "@/views/reports/mixins/reportMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";

export default {
  mixins: [reportMixin],
  components: { ReportTable },
  data() {
    const commonHeaders = [
      {
        text: i18n.t("reports.sa.headers.floor"),
        value: "floor",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.unit"),
        value: "unit",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.room"),
        value: "room",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-name"),
        value: "deviceName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.serial-number"),
        value: "serialNumber",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.weekly-avg-refills"),
        value: "weeklyAverageRefills",
        sortable: true,
        class: "tw-align-text-top"
      }
    ];
    return {
      compatibleReportId: 31,
      reportHeaders: [
        ...commonHeaders,
        {
          text: i18n.t("reports.sa.headers.est-replacement-date"),
          value: "estimatedReplacementDate",
          sortable: true,
          class: "tw-align-text-top",
          displayText: "estimatedReplacementDateFormatted"
        }
      ],
      csvHeaders: [
        ...commonHeaders,
        {
          text: i18n.t("reports.sa.headers.in-service-date"),
          value: "inServiceDateFormatted",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.est-replacement-date"),
          value: "estimatedReplacementDateFormatted",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      items: [],
      reportFilters: ["locations", "daysToReplacement"],
      hiddenReportActions: ["rawExport", "saveSlide"]
    };
  },
  methods: {
    createCsvData() {
      const headers = this.csvHeaders;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      const items = this.reportResponse.dataPoints;
      this.items = items.map((item) => {
        return {
          ...item,
          estimatedReplacementDate: new Date(item.estimatedReplacementDate)
        };
      });
    }
  }
};
</script>
