import Router from "vue-router";
import { PRODUCTS } from "@/types/products";
import { auth } from "@/middleware/auth";
import { determineReportRoute } from "@/middleware/determineReportRoute";
import { skipAuthentication } from "@/middleware/skipAuthentication";

import AccountHome from "@/views/account/Home.vue";
import ManageAccount from "@/views/account/ManageAccount.vue";
import RegisterAccount from "@/views/account/RegisterAccount";

import UnlicensedAccountHome from "@/views/unlicensedAccount/Home.vue";
import AllProducts from "@/views/unlicensedAccount/AllProducts.vue";

import AlertSubscriptionsHome from "@/views/alertSubscriptions/Home.vue";
import AlertSubscriptionList from "@/views/alertSubscriptions/SubscriptionList.vue";
import AlertSubscriptionsManage from "@/views/alertSubscriptions/ManageAlert.vue";
import AlertSubscriptionsUnsubscribe from "@/views/alertSubscriptions/Unsubscribe.vue";

import ReportsHome from "@/views/reports/Home.vue";
import Report from "@/views/reports/Report.vue";

import ReportSubscriptionsHome from "@/views/reportSubscriptions/Home.vue";
import ReportSubscriptions from "@/views/reportSubscriptions/Product.vue";
import ReportSubscriptionsUnsubscribe from "@/views/reportSubscriptions/Unsubscribe.vue";

import SlideshowsHome from "@/views/slideshows/Home.vue";
import SlideshowEdit from "@/views/slideshows/Edit.vue";
import SlideshowView from "@/views/slideshows/View.vue";
import SlideshowList from "@/views/slideshows/List.vue";

import DownloadsHome from "@/views/downloads/Home.vue";
import Downloads from "@/views/downloads/Downloads.vue";

import LoginHome from "@/views/login/Home.vue";
import Login from "@/views/login/Login.vue";
import ForgotPassword from "@/views/login/ForgotPassword.vue";
import ResetPassword from "@/views/login/ResetPassword.vue";

import AdminHome from "@/views/admin/Home.vue";
import CustomerDashboard from "@/views/admin/CustomerDashboard.vue";
import CustomerList from "@/views/admin/CustomerList.vue";
import FacilityDashboard from "@/views/admin/FacilityDashboard.vue";
import ManageCustomer from "@/views/admin/ManageCustomer.vue";
import ManageFacility from "@/views/admin/ManageFacility.vue";
import FacilityBasicInfo from "@/views/admin/components/manageFacility/FacilityBasicInfo.vue";
import DeviceMaintenance from "@/views/admin/DeviceMaintenance.vue";

import OBVSystemHome from "@/views/observation/Home.vue";
import OBVSystem from "@/views/observation/OBVSystem.vue";

import SystemStatusHome from "@/views/systemStatus/Home.vue";
import SystemStatus from "@/views/systemStatus/SystemStatus.vue";
import SystemStatusHistory from "@/views/systemStatus/SystemStatusHistory.vue";

import { PERMISSIONS } from "@/types/permissions";
import { ROUTE_NAMES } from "@/types/routeNames";

const router = new Router({
  mode: "history",
  base: "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      redirect: { name: ROUTE_NAMES.redirectionRoute }
    },
    {
      path: "/redirect",
      name: ROUTE_NAMES.redirectionRoute,
      meta: {
        redirectToDefaultRoute: true
      }
    },
    {
      path: "/account",
      component: AccountHome,
      children: [
        {
          path: "manageaccount",
          component: ManageAccount,
          name: ROUTE_NAMES.manageAccount,
          meta: {
            pageTagName: "Manage Account"
          }
        },
        {
          path: "register",
          component: RegisterAccount,
          name: ROUTE_NAMES.registerAccount,
          props: (route) => ({
            guid: route.query.guid
          }),
          meta: {
            pageTagName: "Register Account",
            skipAuthentication: true
          }
        }
      ]
    },
    {
      path: "/alertsubscriptions/unsubscribe/:token",
      component: AlertSubscriptionsUnsubscribe,
      props: (route) => ({
        token: route.params.token
      }),
      meta: {
        skipAuthentication: true,
        pageTagName: "Unsubscribe From Alert Subscription"
      }
    },
    {
      path: "/unlicensedaccount",
      component: UnlicensedAccountHome,
      children: [
        {
          path: "",
          component: AllProducts,
          name: ROUTE_NAMES.allProductsUnlicensed,
          meta: {
            pageTagName: "All Products Unlicensed"
          }
        }
      ]
    },
    {
      path: "/alertsubscriptions",
      component: AlertSubscriptionsHome,
      meta: {
        permission: PERMISSIONS.manageAlerts
      },
      children: [
        {
          path: "",
          component: AlertSubscriptionList,
          name: ROUTE_NAMES.alertSubscriptions,
          meta: {
            pageTagName: "Alert Subscriptions"
          }
        },
        {
          path: "createalert",
          component: AlertSubscriptionsManage,
          name: ROUTE_NAMES.createAlertSubscription,
          props: (route) => ({
            isInstantAlert:
              String(route.query.isInstantAlert).toLowerCase() === "true",
            alertSubscriptionId: parseInt(route.query.alertSubscriptionId)
          }),
          meta: {
            pageTagName: "Create Alert Subscription"
          }
        },
        {
          path: "editalert",
          component: AlertSubscriptionsManage,
          name: ROUTE_NAMES.editAlertSubscription,
          props: (route) => ({
            isInstantAlert:
              String(route.query.isInstantAlert).toLowerCase() === "true",
            alertSubscriptionId: parseInt(route.query.alertSubscriptionId)
          }),
          meta: {
            pageTagName: "Edit Alert Subscription"
          }
        }
      ]
    },
    {
      path: "/reports",
      name: ROUTE_NAMES.reports,
      component: ReportsHome,
      meta: {
        pageTagName: "Reports Home"
      },
      beforeEnter(to, from, next) {
        if (to.matched.length > 1) {
          next();
        } else {
          const redirectRoute = determineReportRoute(to.query?.reportId);
          next(redirectRoute);
        }
      },
      children: [
        {
          path: "ims",
          component: Report,
          name: ROUTE_NAMES.imsReports,
          props: (route) => ({ reportId: parseInt(route.query?.reportId) }),
          meta: {
            permission: PERMISSIONS.imsReports,
            product: PRODUCTS.IMS,
            pageTagName: "IMS Home"
          }
        },
        {
          path: "ams",
          component: Report,
          name: ROUTE_NAMES.amsReports,
          props: (route) => ({ reportId: parseInt(route.query?.reportId) }),
          meta: {
            permission: PERMISSIONS.amsReports,
            product: PRODUCTS.ECM,
            pageTagName: "AMS Home"
          }
        },
        {
          path: "obv",
          component: Report,
          name: ROUTE_NAMES.obvReports,
          props: (route) => ({ reportId: parseInt(route.query?.reportId) }),
          meta: {
            permission: PERMISSIONS.obvReports,
            product: PRODUCTS.OBS,
            pageTagName: "OBV Home"
          }
        },
        {
          path: "sa",
          component: Report,
          name: ROUTE_NAMES.saReports,
          props: (route) => ({ reportId: parseInt(route.query?.reportId) }),
          meta: {
            permission: PERMISSIONS.saReports,
            product: PRODUCTS.SA,
            pageTagName: "SA Home"
          }
        }
      ]
    },
    {
      path: "/manageslideshows",
      component: SlideshowsHome,
      meta: {
        permission: PERMISSIONS.slideshows
      },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.slideshowsList,
          component: SlideshowList,
          meta: {
            pageTagName: "Manage Slideshows"
          }
        },
        {
          path: "edit",
          name: ROUTE_NAMES.slideshowEdit,
          component: SlideshowEdit,
          props: (route) => ({
            facilityId: parseInt(route.query.facilityId),
            slideshowId: parseInt(route.query.slideshowId)
          }),
          meta: {
            pageTagName: "Edit Slideshow"
          }
        },
        {
          path: "create",
          name: ROUTE_NAMES.slideshowCreate,
          component: SlideshowEdit,
          props: (route) => ({
            facilityId: parseInt(route.query.facilityId)
          }),
          meta: {
            pageTagName: "Create Slideshow"
          }
        },
        {
          path: "view",
          component: SlideshowView,
          name: ROUTE_NAMES.slideshowView,
          props: (route) => ({
            facilityId: parseInt(route.query.facilityId),
            slideshowId: parseInt(route.query.slideshowId)
          }),
          meta: {
            product: PRODUCTS.ECM,
            pageTagName: "View Slideshow",
            slideshowView: true
          }
        }
      ]
    },
    {
      path: "/managedownloads",
      component: DownloadsHome,
      meta: {
        permission: PERMISSIONS.downloads
      },
      children: [
        {
          path: "",
          component: Downloads,
          name: ROUTE_NAMES.downloads,
          meta: {
            pageTagName: "Downloads"
          }
        }
      ]
    },
    {
      path: "/reportsubscriptions/unsubscribe/:token",
      component: ReportSubscriptionsUnsubscribe,
      props: (route) => ({
        token: route.params.token
      }),
      meta: {
        skipAuthentication: true,
        pageTagName: "Unsubscribe From Report Subscription"
      }
    },
    {
      path: "/reportsubscriptions",
      component: ReportSubscriptionsHome,
      meta: {
        permission: PERMISSIONS.reportSubscriptions
      },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.reportSubscriptions,
          component: ReportSubscriptions,
          meta: {
            component: "List",
            pageTagName: "Report Subscriptions List"
          }
        },
        {
          path: "edit",
          name: ROUTE_NAMES.reportSubscriptionEdit,
          component: ReportSubscriptions,
          props: (route) => ({
            reportSubscriptionId: parseInt(route.query.reportSubscriptionId),
            facilityId: parseInt(route.query.facilityId)
          }),
          meta: {
            component: "Edit",
            pageTagName: "Edit Report Subscription"
          }
        },
        {
          path: "create",
          name: ROUTE_NAMES.reportSubscriptionCreate,
          component: ReportSubscriptions,
          props: (route) => ({
            facilityId: parseInt(route.query.facilityId)
          }),
          meta: {
            component: "Edit",
            pageTagName: "Create Report Subscription"
          }
        }
      ]
    },
    {
      path: "/login",
      component: LoginHome,
      meta: {
        skipAuthentication: true
      },
      children: [
        {
          path: "",
          component: Login,
          name: ROUTE_NAMES.login
        },
        {
          path: "forgot",
          component: ForgotPassword,
          name: ROUTE_NAMES.forgotPassword
        },
        {
          path: "resetpassword",
          component: ResetPassword,
          name: ROUTE_NAMES.resetPassword,
          props: (route) => ({
            guid: route.query.guid
          }),
          meta: {
            pageTagName: "Reset Password"
          }
        }
      ]
    },
    {
      path: "/admin",
      component: AdminHome,
      meta: {
        permission: PERMISSIONS.administerCustomer
      },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.adminCustomerList,
          component: CustomerList,
          meta: {
            pageTagName: "Admin Customer List"
          }
        },
        {
          path: "customer/create",
          name: ROUTE_NAMES.createCustomer,
          component: ManageCustomer,
          meta: {
            pageTagName: "Create New Customer",
            permission: PERMISSIONS.addCustomers
          }
        },
        {
          path: "customer/edit",
          name: ROUTE_NAMES.editCustomer,
          component: ManageCustomer,
          props: (route) => ({
            customerId: parseInt(route.query.customerId)
          }),
          meta: {
            pageTagName: "Edit Customer"
          }
        },
        {
          path: "customerdashboard",
          name: ROUTE_NAMES.customerDashboard,
          component: CustomerDashboard,
          props: (route) => ({
            customerId: parseInt(route.query.customerId)
          }),
          meta: {
            pageTagName: "Customer Dashboard"
          }
        },
        {
          path: "facilitydashboard",
          name: ROUTE_NAMES.facilityDashboard,
          component: FacilityDashboard,
          props: (route) => ({
            facilityId: parseInt(route.query.facilityId),
            customerId: parseInt(route.query.customerId)
          }),
          meta: {
            pageTagName: "Facility Dashboard"
          }
        },
        {
          path: "facility/create",
          name: ROUTE_NAMES.createFacility,
          component: FacilityBasicInfo,
          props: (route) => ({
            customerId: parseInt(route.query.customerId)
          }),
          meta: {
            pageTagName: "Create New Facility",
            permission: PERMISSIONS.addFacilities
          }
        },
        {
          path: "facility/edit/:facilityId",
          name: ROUTE_NAMES.editFacility,
          component: ManageFacility,
          props: (route) => ({
            facilityId: parseInt(route.params.facilityId),
            setting: route.query.setting
          }),
          meta: {
            pageTagName: "Edit Facility",
            permission: PERMISSIONS.editFacilities
          }
        },
        {
          path: "deviceMaintenance",
          name: ROUTE_NAMES.deviceMaintenance,
          component: DeviceMaintenance,
          props: (route) => ({
            facilityId: parseInt(route.query.facilityId),
            deviceType: route.query.deviceType,
            locationId: parseInt(route.query.locationId)
          }),
          meta: {
            pageTagName: "Device Maintenance"
          }
        }
      ]
    },
    {
      path: "/observation",
      component: OBVSystemHome,
      meta: {
        permission: PERMISSIONS.observeCompliance
      },
      children: [
        {
          path: "",
          name: ROUTE_NAMES.obvSystem,
          component: OBVSystem,
          meta: {
            pageTagName: "OBV System"
          }
        }
      ]
    },
    {
      path: "/systemStatus",
      component: SystemStatusHome,
      children: [
        {
          path: "",
          name: ROUTE_NAMES.systemStatus,
          component: SystemStatus,
          meta: {
            pageTagName: "System Status",
            permission: PERMISSIONS.systemStatus
          }
        },
        {
          path: "history",
          name: ROUTE_NAMES.systemStatusHistory,
          component: SystemStatusHistory,
          meta: {
            pageTagName: "System Status History",
            permission: PERMISSIONS.systemStatusHistory
          }
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    window.location.href = to.fullPath;
    return;
  }
  if (skipAuthentication(to)) {
    next();
    return;
  }
  return auth(to, from, next);
});

export default router;

import { determineProductFromReportId } from "@/middleware/determineProductFromReportId";

export function navigateToReport(reportId) {
  const productId = determineProductFromReportId(reportId);
  if (!productId) {
    throw new Error(`Unknown reportId: ${reportId}`);
  }

  const productRouteName = router.getRoutes().find((route) => {
    return route.meta?.product === productId;
  })?.name;
  if (
    router.currentRoute?.name === productRouteName &&
    parseInt(router.currentRoute.query?.reportId) === reportId
  ) {
    return;
  }
  router.push({
    name: productRouteName,
    query: {
      reportId
    }
  });
}
