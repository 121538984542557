import i18n from "@/plugins/i18n";

export const precautionOptions = {
  all: "ALL",
  airborne: "AIRBORNE",
  contact: "CONTACT",
  droplet: "DROPLET",
  standard: "STANDARD"
};

export const precautionOptionStrings = {
  ALL: i18n.t("precautionOptions.all"),
  AIRBORNE: i18n.t("precautionOptions.air"),
  CONTACT: i18n.t("precautionOptions.contact"),
  DROPLET: i18n.t("precautionOptions.droplet"),
  STANDARD: i18n.t("precautionOptions.standard")
};
