var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-snackbar',{attrs:{"color":"primary","top":"","timeout":"5000"},model:{value:(_vm.showSuccessMessage),callback:function ($$v) {_vm.showSuccessMessage=$$v},expression:"showSuccessMessage"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]),_c('facility-selection-bar',{staticClass:"tw-my-8"}),_c('header',[_c('h1',[_vm._v(_vm._s(_vm.$t("report-subscriptions.manage")))])]),_c('deleteDialog',{attrs:{"deleteDialogData":_vm.deleteDialogData,"loading":_vm.deleteLoading},on:{"deletion-confirmed":_vm.deleteSubscription,"toggle-delete-dialog":_vm.toggleDeleteDialog}}),_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('report-subscriptions.loading'),"no-data-text":_vm.$t('report-subscriptions.empty')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-sticky tw-top-0 tw-bg-white",staticStyle:{"z-index":"2"}},[_c('v-text-field',{staticClass:"tw-mr-4",attrs:{"label":`${_vm.$t('ui.input-select.search')}`,"clearable":"","prepend-inner-icon":"mdi-magnify"},on:{"focus":function($event){return _vm.trackSearchAnalytics()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"tw-max-w-xs",attrs:{"primary":"","color":"primary","to":{
              name: 'reportSubscriptionCreate',
              query: { facilityId: _vm.facilityId }
            }}},[_vm._v(" "+_vm._s(_vm.$t("report-subscriptions.actions.new"))+" ")])],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),(_vm.checkPermission(_vm.PERMISSIONS.administerCustomer))?_c('td',[_vm._v(" "+_vm._s(item.creator)+" ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(item.recipients.remaining === 0 ? item.recipients.name : `${item.recipients.name} (+${_vm.$tc( "count.other", item.recipients.remaining )})`)+" ")]),_c('td',[_vm._v(_vm._s(_vm.$t(item.frequency)))]),_c('td',[_vm._v(" "+_vm._s(item.locations.remaining === 0 ? item.locations.name : `${item.locations.name} (+${_vm.$tc( "count.other", item.locations.remaining )})`)+" ")]),_c('td',[_vm._v(_vm._s(_vm.$t(item.reportType)))]),_c('td',[(
                _vm.userId === item.userId ||
                _vm.checkPermission(_vm.PERMISSIONS.administerCustomer)
              )?_c('router-link',{staticClass:"tw-no-underline",attrs:{"to":{
                name: 'reportSubscriptionEdit',
                query: { reportSubscriptionId: item.id, facilityId: _vm.facilityId }
              }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),_c('td',[(
                _vm.userId === item.userId ||
                _vm.checkPermission(_vm.PERMISSIONS.administerCustomer)
              )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleDeleteDialog(item.id, item.name)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }