<template>
  <div>
    <div
      class="
        login-form
        tw-border-solid
        tw-border
        tw-border-purellGray
        tw-mb-6
        tw-mt-20
        tw-p-4
        tw-bg-white
        tw-rounded
        tw-w-full
        tw-max-w-xl
        tw-m-auto
      "
    >
      <login-logo />
      <div class="tw-mb-6">
        <h1 class="tw-text-lg tw-font-bold tw-mb-3">
          {{ $t("log-in.reset-password") }}
        </h1>
        <p class="tw-text-base">
          {{ $t("log-in.reset-enter-email") }}
        </p>
      </div>
      <v-snackbar
        @input="showEmailSentMessage = false"
        :value="showEmailSentMessage"
        color="primary"
        :top="true"
        :timeout="-1"
        class="tw-p-12"
      >
        <div class="tw-text-lg tw-mb-3">
          {{ $t("log-in.reset-instructions-sent") }}
        </div>
        <div>
          {{ $t("log-in.reset-instructions") }}
        </div>
      </v-snackbar>
      <ValidationObserver ref="form" v-slot="{ pristine }">
        <v-form @submit.prevent="submitPasswordReset">
          <ValidationProvider
            :name="$t('log-in.form.email-address')"
            :rules="{ required: true, email: true }"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="email"
              :error-messages="errors"
              name="email"
              id="email"
              value=""
              required
              :label="$t('log-in.form.email-address')"
              type="text"
              class="tw-mb-4"
            />
          </ValidationProvider>

          <v-btn
            :disabled="pristine || showEmailSentMessage"
            color="primary"
            block
            type="submit"
          >
            {{ $t("log-in.form.submit") }}
          </v-btn>
        </v-form>
      </ValidationObserver>
    </div>
    <div class="tw-flex tw-justify-center">
      <country-dropdown />
    </div>
  </div>
</template>

<script>
import LoginLogo from "@/views/login/components/Logo.vue";
import CountryDropdown from "@/components/ui/CountryDropdown.vue";
import accountService from "@/api/accountService";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  components: {
    LoginLogo,
    CountryDropdown
  },
  data: () => ({
    email: "",
    showEmailSentMessage: false
  }),
  methods: {
    async submitPasswordReset() {
      const valid = await this.$refs.form.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);
      } else {
        await accountService.forgotPassword(this.email);
        this.showEmailSentMessage = true;
      }
    }
  }
};
</script>
