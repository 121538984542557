<template>
  <div>
    <div
      class="
        tw-mt-20 tw-mb-6
        login-form
        tw-border-solid
        tw-border
        tw-border-purellGray
        tw-p-4
        tw-bg-white
        tw-rounded
        tw-w-full
        tw-max-w-xl
        tw-m-auto
      "
    >
      <login-logo />
      <div class="tw-mb-6">
        <div
          v-if="errorMessage"
          class="tw-text-md tw-font-bold tw-mb-0 tw-text-red-500"
        >
          {{ errorMessage }}
        </div>
      </div>
      <ValidationObserver ref="form" v-slot="{ failed }">
        <v-form @submit.prevent="signIn" class="tw-mb-6">
          <ValidationProvider
            :name="$t('log-in.form.email-address')"
            :rules="{ required: true, email: true }"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="email"
              :error-messages="errors"
              name="email"
              id="email"
              :label="$t('log-in.form.email-address')"
              :placeholder="autofilled ? ' ' : null"
              :persistent-placeholder="autofilled"
              @focus="autofilled = false"
            />
          </ValidationProvider>

          <ValidationProvider
            :name="$t('log-in.form.password')"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="password"
              :error-messages="errors"
              name="password"
              id="password"
              :label="$t('log-in.form.password')"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :placeholder="autofilled ? ' ' : null"
              :persistent-placeholder="autofilled"
              @focus="autofilled = false"
            />
          </ValidationProvider>

          <v-checkbox
            :label="`${$t('log-in.form.remember-me')}`"
            v-model="rememberMe"
          />

          <v-btn
            :disabled="failed"
            :loading="loading"
            color="primary"
            block
            type="submit"
          >
            {{ $t("log-in.form.log-in") }}
          </v-btn>
        </v-form>
      </ValidationObserver>

      <a href="/login/forgot" class="tw-text-blue-500 hover:tw-no-underline">
        {{ $t("log-in.forgot-your-password") }}?
      </a>
    </div>
    <div class="tw-flex tw-justify-center">
      <country-dropdown />
    </div>
    <v-dialog v-model="showModal" max-width="460" persistent>
      <v-card class="tw-py-4">
        <v-card-text style="color: red">
          {{
            $t(pastMarchFirst ? "ie11-popup.warning2" : "ie11-popup.warning")
          }}
        </v-card-text>
        <v-card-text style="color: black">
          {{ $t("ie11-popup.better-experience") }}
        </v-card-text>
        <v-card-text style="color: black">
          {{ $t("ie11-popup.recommended-browsers") }}
        </v-card-text>
        <div
          class="tw-flex tw-flex-row tw-items-center tw-cursor-pointer tw-px-6"
        >
          <div
            class="tw-flex tw-flex-col tw-items-center"
            @click="openBrowser('Edge')"
          >
            <v-img :src="MSEdgeLogo" class="browserLogo" />
            <p>{{ $t("ie11-popup.edge") }}</p>
          </div>
          <div
            class="
              tw-flex tw-flex-col tw-items-center tw-cursor-pointer tw-ml-8
            "
            @click="openBrowser('Chrome')"
          >
            <v-img :src="ChromeLogo" class="browserLogo" />
            <p>{{ $t("ie11-popup.chrome") }}</p>
          </div>
        </div>
        <v-btn
          icon
          absolute
          style="top: 0; right: 0"
          @click="showModal = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoginLogo from "@/views/login/components/Logo.vue";
import CountryDropdown from "@/components/ui/CountryDropdown.vue";
import accountService from "@/api/accountService";
import { ROUTE_NAMES } from "@/types/routeNames";
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import ChromeLogo from "@/assets/images/logos/chromeLogo.svg";
import MSEdgeLogo from "@/assets/images/logos/msEdgeLogo.svg";

export default {
  components: {
    LoginLogo,
    CountryDropdown
  },
  data: () => ({
    autofilled: false,
    email: "",
    intervalDetectAutofill: null,
    localStorageKey: "smartLinkLogInEmail",
    loading: false,
    password: "",
    rememberMe: false,
    errorMessage: "",
    pastMarchFirst: new Date() > new Date("March 1 2023 00:00:00"),
    showModal: false,
    showPassword: false,
    ChromeLogo,
    MSEdgeLogo
  }),
  computed: {
    ...mapGetters("application", ["isChromiumBrowser"])
  },
  mounted() {
    if (this.$route.query.timeout) {
      this.errorMessage = i18n.t("log-in.user-timeout");
    }

    const savedLoginEmail = localStorage.getItem(this.localStorageKey);
    if (savedLoginEmail) {
      this.email = savedLoginEmail;
      this.rememberMe = true;
    }

    this.intervalDetectAutofill = this.isChromiumBrowser
      ? setInterval(() => {
          if (
            document.querySelectorAll('input[type="password"]:-webkit-autofill')
              .length > 0
          ) {
            this.autofilled = true;
          }
        }, 100)
      : null;

    const threeSeconds = 3000;
    setTimeout(() => {
      if (this.intervalDetectAutofill) {
        clearInterval(this.intervalDetectAutofill);
        this.intervalDetectAutofill = null;
      }
    }, threeSeconds);

    this.showModal = window.document.documentMode;
  },
  methods: {
    async signIn() {
      this.loading = true;
      const valid = await this.$refs.form.validate();

      if (!valid) {
        this.loading = false;
        return;
      }

      this.rememberMe === true
        ? localStorage.setItem(this.localStorageKey, this.email)
        : localStorage.setItem(this.localStorageKey, "");

      try {
        await accountService.login(this.email, this.password, this.rememberMe);

        this.handleSuccess();
      } catch (error) {
        this.handleError(error);
      }
    },
    handleSuccess() {
      const returnUrl =
        this.$route.query?.ReturnUrl ?? this.$route.query?.returnUrl;
      const queryParams = { ...this.$route.query };
      delete queryParams.returnUrl;
      delete queryParams.ReturnUrl;
      delete queryParams.timeout;
      if (returnUrl) {
        this.$router
          .push({ path: decodeURIComponent(returnUrl), query: queryParams })
          .catch(() => {
            /* ignore intended "double" redirect (when not privileged to go to returnUrl) */
          });
      } else {
        this.$router.push({ name: ROUTE_NAMES.redirectionRoute }).catch(() => {
          /* ignore intended "double" redirect */
        });
      }
    },
    handleError(error) {
      if (error === 401) {
        this.errorMessage = i18n.t("log-in.invalid-credentials");
        this.loading = false;
        return;
      }

      if (error === 429) {
        this.errorMessage = i18n.t("log-in.account-lockout");
        this.loading = false;
      }

      if (error === 500) {
        this.errorMessage = i18n.t("log-in.internal-error");
        this.loading = false;
      }
    },
    openBrowser(type) {
      if (type === "Chrome") {
        window.open("https://www.google.com/chrome/");
      } else {
        window.open(
          "https://www.microsoft.com/en-us/edge/download?form=MA13FJ"
        );
      }
    }
  },
  watch: {
    async autofilled() {
      if (this.intervalDetectAutofill) {
        clearInterval(this.intervalDetectAutofill);
        this.intervalDetectAutofill = null;
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.browserLogo {
  height: 60px;
  width: 60px;
}
</style>
