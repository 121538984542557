<template>
  <dashboard :loading="loading" :logo="customerLogo" :item="customer">
    <template v-slot:title>
      <h1 class="tw-text-3xl tw-mr-2">{{ customer.name }}</h1>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="checkPermission(PERMISSIONS.administerCustomer)"
            icon
            primary
            v-bind="attrs"
            v-on="on"
            :to="{
              name: ROUTE_NAMES.editCustomer,
              query: { customerId }
            }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t("admin.manage-customer.edit") }}
        </span>
      </v-tooltip>
    </template>
    <template v-slot:widgets>
      <dashboard-widget
        title="admin.customer-dashboard.facilities"
        v-slot="{ fullscreen }"
      >
        <facilities-widget
          :customerId="customerId"
          :customerName="customer.name"
          :fullscreen="fullscreen"
        />
      </dashboard-widget>
      <users-widget :customerId="customerId" :export-name="customer.name" />
      <dashboard-widget
        v-if="showIMSOptions"
        title="admin.customer-dashboard.badge-management"
        v-slot="{ fullscreen, setLocalizedWidgetMessage }"
      >
        <badge-management-widget
          :customerId="customerId"
          :fullscreen="fullscreen"
          :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
        />
      </dashboard-widget>
      <dashboard-widget
        v-if="showIMSOptions"
        title="admin.customer-dashboard.ims-job-roles"
        v-slot="{ fullscreen, setLocalizedWidgetMessage }"
      >
        <ims-job-roles-widget
          :customerId="customerId"
          :fullscreen="fullscreen"
          :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
        />
      </dashboard-widget>
      <dashboard-widget
        v-if="checkPermission(PERMISSIONS.manageLicenses)"
        title="admin.facility-dashboard.licenses"
        v-slot="{ fullscreen, setLocalizedWidgetMessage }"
      >
        <licenses-widget
          :customerId="customerId"
          :facilities="customer.facilities"
          :fullscreen="fullscreen"
          :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
        />
      </dashboard-widget>
    </template>
  </dashboard>
</template>

<script>
import customerService from "@/api/customerService";
import Dashboard from "@/views/admin/components/Dashboard.vue";
import DashboardWidget from "@/views/admin/components/DashboardWidget.vue";
import BadgeManagementWidget from "@/views/admin/components/customerWidgets/BadgeManagementWidget.vue";
import FacilitiesWidget from "@/views/admin/components/customerWidgets/FacilitiesWidget.vue";
import ImsJobRolesWidget from "@/views/admin/components/customerWidgets/ImsJobRolesWidget.vue";
import LicensesWidget from "@/views/admin/components/facilityWidgets/LicensesWidget.vue";
import UsersWidget from "@/views/admin/components/customerWidgets/UsersWidget.vue";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import { PRODUCTS } from "@/types/products";
import { ROUTE_NAMES } from "@/types/routeNames";

export default {
  components: {
    Dashboard,
    DashboardWidget,
    BadgeManagementWidget,
    FacilitiesWidget,
    ImsJobRolesWidget,
    LicensesWidget,
    UsersWidget
  },
  props: {
    customerId: { type: Number }
  },
  data() {
    return {
      customer: {},
      customerLogo: "",
      hideSwitches: false,
      loading: true,
      PERMISSIONS,
      ROUTE_NAMES,
      showIMSOptions: false,
      showPendingUsers: false
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"])
  },
  async created() {
    await this.loadCustomer();
  },
  methods: {
    async loadCustomer() {
      try {
        this.customer = await customerService.get({
          customerid: this.customerId
        });

        const today = new Date();
        this.showIMSOptions = this.customer?.facilities.some((f) =>
          f.licenses.some(
            (l) =>
              l.product?.code === PRODUCTS.IMS &&
              new Date(l.expirationDate) >= today
          )
        );
      } catch (error) {
        if (error === 403) {
          this.$router.push({
            name: ROUTE_NAMES.adminCustomerList
          });
        }
      } finally {
        this.loading = false;

        if (this.customer?.mediaItemId) {
          this.customerLogo = await customerService.getCustomerLogo(
            this.customerId
          );
        }
      }
    },
    toggleHideWidgetSwitches() {
      this.hideSwitches = !this.hideSwitches;
    }
  }
};
</script>

<style scoped lang="postcss">
.customer-property-title {
  font-size: 12px;
  color: #8a8d8f;
}

.customer-property-text {
  font-size: 16px;
}

#customer-properties p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.customer-logo {
  max-width: 160px;
  max-height: 120px;
  margin-right: 20px;
  width: auto;
  height: auto;
}

.multi-address {
  margin-top: 0px;
  margin-bottom: 0px;
}

:deep(.v-data-table__wrapper) {
  height: 300px;
  overflow-y: auto;
}

:deep(.v-data-table.autoheight .v-data-table__wrapper) {
  height: auto;
}

:deep(.v-data-table__wrapper thead th) {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
</style>
