<template>
  <div class="tw-w-full tw-px-4 tw-py-2" data-name="custom-timeframe-selection">
    <div class="tw-my-4 tw-w-full">
      <v-datetime-picker
        :label="$t('reports.report-start')"
        :timePickerProps="{
          allowedMinutes: [0, 30]
        }"
        :datePickerProps="{
          min: minDate
        }"
        :textFieldProps="{
          filled: true,
          'hide-details': true,
          'append-icon': 'mdi-calendar'
        }"
        @input="(e) => onInput({ start: e })"
        :datetime="start"
      >
        <template v-slot:dateIcon>
          <v-icon> mdi-calendar </v-icon>
        </template>
        <template v-slot:timeIcon>
          <v-icon> mdi-clock </v-icon>
        </template>
      </v-datetime-picker>
    </div>
    <div class="tw-my-4 tw-w-full">
      <v-datetime-picker
        :label="$t('reports.report-end')"
        :timePickerProps="{
          allowedMinutes: [0, 30]
        }"
        :textFieldProps="{
          filled: true,
          'hide-details': true,
          'append-icon': 'mdi-calendar'
        }"
        @input="(e) => onInput({ end: e })"
        :datetime="end"
      >
        <template v-slot:dateIcon>
          <v-icon> mdi-calendar </v-icon>
        </template>
        <template v-slot:timeIcon>
          <v-icon> mdi-clock </v-icon>
        </template>
      </v-datetime-picker>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AMS_REPORTS } from "@/types/amsReports";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        start: undefined,
        end: undefined
      })
    }
  },
  data: () => ({
    start: "",
    end: ""
  }),
  computed: {
    ...mapState("reports", ["reportId"]),
    minDate() {
      if (this.reportId === AMS_REPORTS.DEVICE_METRICS) {
        const today = new Date();
        return new Date(today.setDate(today.getDate() - 45)).toISOString();
      }
      return null;
    }
  },
  mounted() {
    this.start = this.value.start;
    if (!this.start) {
      const date = new Date();
      this.start = new Date(date.setHours(0, 0, 0, 0));
    }

    this.end = this.value.end;
    if (!this.end) {
      const date = new Date();
      const hours = date.getHours();
      let minutes = date.getMinutes();

      minutes = minutes < 30 ? 0 : 30;
      this.end = new Date(date.setHours(hours, minutes, 0, 0));
    }

    this.$emit("input", { start: this.start, end: this.end });
  },
  methods: {
    onInput({ start = this.start, end = this.end } = {}) {
      this.start = start;
      this.end = end;
      this.$emit("input", { start, end });
    }
  }
};
</script>
