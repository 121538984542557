import colors from "@/colors/colors";
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";

// We put dynamic component references here (VDialog, VBottomSheet) to accommodate Vuetify tree shaking
import Vuetify, { VDialog, VBottomSheet } from "vuetify/lib";
Vue.use(Vuetify, {
  components: { VDialog, VBottomSheet }
});

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: colors
    }
  }
});
