import { TIMEFRAMES } from "@/types/timeframes";
import i18n from "@/plugins/i18n";

const timeframeValues = [
  {
    id: TIMEFRAMES.TODAY,
    text: i18n.t("timeframes.today")
  },
  {
    id: TIMEFRAMES.YESTERDAY,
    text: i18n.t("timeframes.yesterday")
  },
  {
    id: TIMEFRAMES.LAST_24_HOURS,
    text: i18n.t("timeframes.last-24-hours")
  },
  {
    id: TIMEFRAMES.LAST_7_DAYS,
    text: i18n.t("timeframes.last-7-days")
  },
  {
    id: TIMEFRAMES.LAST_30_DAYS,
    text: i18n.t("timeframes.last-30-days")
  },
  {
    id: TIMEFRAMES.LAST_90_DAYS,
    text: i18n.t("timeframes.last-90-days")
  },
  {
    id: TIMEFRAMES.LAST_6_MONTHS,
    text: i18n.t("timeframes.last-6-months")
  },
  {
    id: TIMEFRAMES.LAST_12_MONTHS,
    text: i18n.t("timeframes.last-12-months")
  },
  {
    id: TIMEFRAMES.CUSTOM,
    text: i18n.t("timeframes.custom")
  }
];

export default {
  list() {
    return new Promise((res) => res(timeframeValues));
  },
  getState() {
    const timeframeState = {
      byId: {},
      allIds: timeframeValues.map((t) => t.id)
    };

    timeframeValues.forEach((t) => {
      timeframeState.byId[t.id] = t;
    });

    return timeframeState;
  }
};
