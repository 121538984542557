var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.facility.licenses.length)?_c('ValidationObserver',{ref:"manageReportSettingsForm",scopedSlots:_vm._u([{key:"default",fn:function({ pristine }){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateAndSave.apply(null, arguments)}}},[_c('h2',[_vm._v(_vm._s(_vm.$t("admin.manage-facility.settings.report-settings")))]),(_vm.hasObvLicense || _vm.hasAmsLicense)?_c('ValidationProvider',[_c('p',{staticClass:"tw-mt-2"},[_vm._v(" "+_vm._s(_vm.$t( "admin.manage-facility.report-settings.max-compliance-description" ))+" ")]),_c('v-checkbox',{attrs:{"label":_vm.$t('admin.manage-facility.report-settings.max-compliance')},model:{value:(_vm.maxCompliance),callback:function ($$v) {_vm.maxCompliance=$$v},expression:"maxCompliance"}})],1):_vm._e(),(_vm.hasAmsLicense)?[_c('v-divider',{staticClass:"tw-mb-4"}),_c('h2',[_vm._v(" "+_vm._s(_vm.$t("admin.manage-facility.report-settings.ams-settings"))+" ")]),_c('p',{staticClass:"tw-mt-2"},[_vm._v(" "+_vm._s(_vm.$t( "admin.manage-facility.report-settings.outbound-opportunities-description" ))+" ")]),_c('ValidationProvider',[_c('v-checkbox',{attrs:{"label":_vm.$t(
                  'admin.manage-facility.report-settings.outbound-opportunities'
                )},model:{value:(_vm.outboundOnly),callback:function ($$v) {_vm.outboundOnly=$$v},expression:"outboundOnly"}})],1),_c('h4',[_vm._v(" "+_vm._s(_vm.$t("admin.manage-facility.report-settings.opportunity-offset"))+" ")]),_c('p',{staticClass:"tw-mt-2"},[_vm._v(" "+_vm._s(_vm.$t( "admin.manage-facility.report-settings.opportunity-offset-description" ))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('admin.manage-facility.report-settings.opportunity-offset'),"rules":{ numeric: true, min_value: 1, max_value: 100 },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"tw-w-20 tw-pt-0",attrs:{"type":"number","suffix":"%","hide-spin-buttons":"","error-messages":errors,"placeholder":_vm.$t(
                  'admin.manage-facility.report-settings.opportunity-offset-helper-text'
                )},model:{value:(_vm.opportunityOffset),callback:function ($$v) {_vm.opportunityOffset=$$v},expression:"opportunityOffset"}})]}}],null,true)}),_c('v-container',[_c('v-row',[_c('h4',[_vm._v(_vm._s(_vm.$t("reports.performance-thresholds")))]),_c('v-col',{attrs:{"cols":"12"}},[_c('performance-thresholds',{ref:"performanceThresholds",attrs:{"locationId":_vm.locationId,"levelName":_vm.$t('locations.levelNames.LocationLevelFacility')}})],1)],1)],1)]:_vm._e(),(_vm.hasImsLicense)?[_c('v-divider',{staticClass:"tw-mb-4 tw-mt-2"}),_c('h2',[_vm._v(" "+_vm._s(_vm.$t("admin.manage-facility.report-settings.ims-settings"))+" ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('performance-bands',{ref:"performanceBands",attrs:{"facilityId":_vm.facility.id}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                    'admin.manage-facility.report-settings.look-behind-seconds'
                  ),"rules":{ numeric: true, max_value: 300, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t(
                      'admin.manage-facility.report-settings.look-behind-seconds'
                    )},model:{value:(_vm.lookBehindSeconds),callback:function ($$v) {_vm.lookBehindSeconds=$$v},expression:"lookBehindSeconds"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('admin.manage-facility.report-settings.look-ahead-seconds'),"rules":{ numeric: true, max_value: 300, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t(
                      'admin.manage-facility.report-settings.look-ahead-seconds'
                    )},model:{value:(_vm.lookAheadSeconds),callback:function ($$v) {_vm.lookAheadSeconds=$$v},expression:"lookAheadSeconds"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{staticClass:"xl:tw-w-1/2 xl:tw-pr-3",attrs:{"name":_vm.$t('admin.manage-facility.report-settings.pop-in-window-seconds'),"rules":{ numeric: true, max_value: 60, min_value: 0 },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t(
                  'admin.manage-facility.report-settings.pop-in-window-seconds'
                )},model:{value:(_vm.popInWindowSeconds),callback:function ($$v) {_vm.popInWindowSeconds=$$v},expression:"popInWindowSeconds"}})]}}],null,true)})]:_vm._e(),(_vm.hasObvLicense)?[_c('v-divider',{staticClass:"tw-mb-4 tw-mt-2"}),_c('h2',[_vm._v(" "+_vm._s(_vm.$t("admin.manage-facility.report-settings.obv-settings"))+" ")]),_c('ValidationProvider',{staticClass:"xl:tw-w-1/2 xl:tw-pr-3",attrs:{"name":_vm.$t('admin.manage-facility.report-settings.goal'),"rules":{ numeric: true },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('admin.manage-facility.report-settings.goal')},model:{value:(_vm.obvGoal),callback:function ($$v) {_vm.obvGoal=$$v},expression:"obvGoal"}})]}}],null,true)})]:_vm._e(),_c('v-row',{staticClass:"tw-justify-end"},[_c('v-btn',{staticClass:"tw-mr-5 tw-w-24",attrs:{"color":"primary","type":"submit","disabled":pristine &&
              (_vm.$refs.performanceBands
                ? !_vm.$refs.performanceBands.indicatorsChanged
                : true) &&
              (_vm.$refs.performanceThresholds
                ? !_vm.$refs.performanceThresholds.indicatorsChanged
                : true)}},[_vm._v(" "+_vm._s(_vm.$t("ui.actions.save"))+" ")]),_c('v-btn',{staticClass:"tw-mr-5 tw-w-24",attrs:{"color":"background2","disabled":pristine &&
              (_vm.$refs.performanceBands
                ? !_vm.$refs.performanceBands.indicatorsChanged
                : true) &&
              (_vm.$refs.performanceThresholds
                ? !_vm.$refs.performanceThresholds.indicatorsChanged
                : true)},on:{"click":function($event){return _vm.setSettings(_vm.facility)}}},[_vm._v(" "+_vm._s(_vm.$t("ui.actions.reset"))+" ")])],1)],2)]}}],null,false,1688513979)}):(!_vm.loading)?_c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("admin.manage-facility.report-settings.no-settings-title"))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$t('admin.manage-facility.report-settings.no-settings-body', {
            facilityName: _vm.facility.name
          })
        )}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }