import { extend, configure } from "vee-validate";
import {
  required,
  required_if,
  min_value,
  min,
  max_value,
  max,
  confirmed,
  email,
  numeric,
  is_not,
  mimes,
  size,
  regex,
  alpha_num,
  double
} from "vee-validate/dist/rules";
import i18n from "@/plugins/i18n";

// Built in VeeValidate rules
// https://vee-validate.logaretm.com/v3/guide/rules.html#rules
extend("required", required);
extend("required_if", required_if);
extend("min_value", min_value);
extend("min", min);
extend("max_value", max_value);
extend("max", max);
extend("confirmed", confirmed);
extend("email", email);
extend("numeric", numeric);
extend("is_not", is_not);
extend("mimes", mimes);
extend("size", size);
extend("regex", regex);
extend("alpha_num", alpha_num);
extend("double", double);

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validations.${values._rule_}`, values);
  }
});

// Custom Rules
// https://vee-validate.logaretm.com/v3/guide/basics.html#adding-rules

extend("password", (value) => {
  const passwordRegex = new RegExp(
    /(?=^.{8,100}$)((?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[a-z])(?=.*[@#$%^&£*\-_+=[\]{}<>|\\:',.?/`~""();!])|(?=.*\d)(?=.*[A-Z])(?=.*[@#$%^&£*\-_+=[\]{}<>|\\:',.?/`~""();!])|(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&£*\-_+=[\]{}<>|\\:',.?/`~""();!]))^.*/,
    "i"
  );

  if (passwordRegex.test(value)) {
    return true;
  }

  return i18n.t(`validations.password`);
});

extend("isNotInList", (value, list) => {
  const valueIsNotNumber = isNaN(value);
  let matchingValues;

  if (valueIsNotNumber) {
    matchingValues = list.filter(
      (item) => item.toLowerCase() === value.toLowerCase()
    );
  } else {
    matchingValues = list.filter((item) => item === value);
  }

  if (matchingValues.length > 1) {
    return i18n.t(`validations.isNotInList`);
  }

  return true;
});

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validations.${values._rule_}`, values);
  }
});
