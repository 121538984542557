<template>
  <div
    v-show="
      Object.keys(hiddenReportActions).length === 0 ||
      !hiddenReportActions.saveSlide ||
      !hiddenReportActions.csvExport ||
      !hiddenReportActions.rawExport ||
      !hiddenReportActions.print
    "
    class="tw-flex tw-flex-row tw-items-center"
  >
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          depressed
          color="tertiary"
          v-on="on"
          @click="reportActionsMenuClicked()"
          style="color: white !important"
        >
          <v-icon class="tw-mr-2"> mdi-export </v-icon>
          {{ $t("ui.actions.export") }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="!hiddenReportActions.print"
          @click="onShowPrintDialog()"
        >
          <v-list-item-title>{{ $t("ui.actions.pdfPrint") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!hiddenReportActions.csvExport"
          @click="onCsvExport()"
        >
          <v-list-item-title>{{ $t("ui.actions.excel") }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!hiddenReportActions.saveSlide"
          @click="onSaveSlideDialog()"
        >
          <v-list-item-title>{{
            $t("slideshows.save-slide")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!hiddenReportActions.rawExport && canRawExport"
          @click="onRawExport()"
        >
          <v-list-item-title>{{
            $t("ui.actions.raw-export")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <report-actions-dialog
      v-if="showReportActionsDialog"
      v-model="showReportActionsDialog"
      @show-save-slide-success-message="showSaveSlideSuccessMessage"
      @close-report-actions-dialog="closeReportActionsDialog"
      @cancel="onCancel"
      :reportTitle="report ? $t(report) : ''"
      :reportActionsDialogContent="reportActionsDialogContent"
      :serverErrorMessage="serverErrorMessage"
      :loading="loading"
    />
    <report-actions-snackbar
      v-model="showReportActionsSnackbar"
      :reportActionsSnackbarContent="reportActionsSnackbarContent"
      @report-actions-snackbar-closed="
        () => {
          reportActionsSnackbarContent = {};
          showReportActionsSnackbar = false;
        }
      "
    />
  </div>
</template>
<script>
import ReportActionsDialog from "@/views/reports/components/reportActions/Dialog.vue";
import ReportActionsSnackbar from "@/views/reports/components/reportActions/Snackbar.vue";
import rawExportService from "@/api/rawExportService";
import slideService from "@/api/slideService";
import pdfService from "@/api/pdfService";
import i18n from "@/plugins/i18n";
import { DISPLAY_MODES } from "@/store/modules/application";
import { mapState, mapGetters } from "vuex";
import { generateReportUrl } from "@/views/reports/utils/urlGenerator";
import { downloadCSVFile } from "@/utils/downloadFile";
import { ALL_REPORT_DISPLAY_NAMES } from "@/types/allReports";

export default {
  components: {
    ReportActionsDialog,
    ReportActionsSnackbar
  },
  data: () => ({
    loading: false,
    showReportActionsDialog: false,
    showReportActionsSnackbar: false,
    reportActionsDialogContent: {},
    reportActionsSnackbarContent: {},
    serverErrorMessage: []
  }),
  computed: {
    ...mapGetters("application", ["isChromiumBrowser"]),
    ...mapState("reports", ["csvData", "reportId"]),
    ...mapGetters("reports", ["activeFilters"]),
    ...mapGetters("user/permissions", ["checkPermission"]),
    canRawExport() {
      return this.$store.state.facilities?.byId[
        this.$store.state.customers.facilityId
      ]?.canRawExport;
    },
    report() {
      return ALL_REPORT_DISPLAY_NAMES[this.reportId];
    },
    hiddenReportActions() {
      const hiddenReportActions = this.$store.state.reports.hiddenReportActions;

      if (!hiddenReportActions) {
        return {};
      }

      return {
        print: hiddenReportActions.includes("print"),
        saveSlide: hiddenReportActions.includes("saveSlide"),
        csvExport: hiddenReportActions.includes("csvExport"),
        rawExport: hiddenReportActions.includes("rawExport")
      };
    }
  },
  methods: {
    generateReportUrl,
    onCsvExport() {
      this.$appInsights?.trackEvent({ name: `CSV Export Clicked` });
      const csvData = this.csvData;

      const headers = csvData.headersText.map((h) => `"${h}"`);

      const rowsData = csvData.data
        .map((rowCells) =>
          rowCells.map((r) => (r !== null ? `"${r}"` : "-")).join(",")
        )
        .join("\n");

      downloadCSVFile(headers, rowsData);
    },
    async onRawExport() {
      this.$appInsights?.trackEvent({ name: `Raw Export Clicked` });

      try {
        await rawExportService.post({
          viewModel: this.$store.getters["reports/exportModel"]
        });
        this.showReportActionsSnackbar = true;
        this.reportActionsSnackbarContent = {
          color: "white",
          content: i18n.t("reports.raw-export.raw-data-request"),
          button: {
            link: "/managedownloads",
            text: i18n.t("reports.raw-export.downloads"),
            target: "_blank"
          }
        };
      } catch (error) {
        this.showReportActionsSnackbar = true;
        this.reportActionsSnackbarContent = {
          color: "error",
          textColor: "white",
          content:
            error === 409
              ? i18n.t("reports.raw-export.export-request-limit")
              : i18n.t("reports.raw-export.something-went-wrong")
        };
      }
    },
    showSaveSlideSuccessMessage() {
      this.showReportActionsSnackbar = true;
      this.reportActionsSnackbarContent = {
        color: "white",
        content: i18n.t("slideshows.save-slide-successful"),
        button: {
          link: "/manageslideshows",
          text: i18n.t("slideshows.manage-slideshows")
        }
      };
    },
    onKeyDown(event) {
      const { key, ctrlKey, metaKey } = event;

      if ((ctrlKey || metaKey) && key === "p") {
        event.preventDefault();
        this.onShowPrintDialog();
      }
    },
    onShowPrintDialog() {
      this.$appInsights?.trackEvent({ name: `PDF/Print Clicked` });
      this.showReportActionsDialog = true;
      this.reportActionsDialogContent = {
        type: "print",
        dialogTitle: i18n.t("ui.print-options"),
        dialogTextLabel: i18n.t("reports.report-title"),
        dialogAction: i18n.t("ui.actions.print")
      };
    },
    onSaveSlideDialog() {
      this.$appInsights?.trackEvent({ name: `Save Slide Clicked` });
      this.showReportActionsDialog = true;

      const inputCounter = 150;
      this.reportActionsDialogContent = {
        type: "saveSlide",
        dialogTitle: i18n.t("ui.save-slide-options"),
        dialogTextLabel: i18n.t("slideshows.slide-description"),
        dialogAction: i18n.t("ui.actions.save"),
        inputHint: i18n.t("slideshows.slide-description-hint"),
        inputCounter: inputCounter,
        inputRules: { required: true, max: inputCounter }
      };
    },
    async closeReportActionsDialog(title) {
      if (this.reportActionsDialogContent.type === "print" && !title) {
        title = this.$t(
          ALL_REPORT_DISPLAY_NAMES[this.$store.state.reports.reportId]
        );
      } else if (!title) {
        this.showReportActionsDialog = false;
        return;
      }

      const store = this.$store;
      if (this.reportActionsDialogContent.type === "saveSlide") {
        this.loading = true;

        const slideViewModel = {
          slideName: title,
          reportId: store.state.reports.reportId,
          facilityId: store.state.customers.facilityId,
          filters: store.getters["reports/filterModel"],
          reportControls: store.state.reports.graphActions
        };

        try {
          await slideService.post({ slideViewModel });
          this.loading = false;
          this.showSaveSlideSuccessMessage();
        } catch {
          this.serverErrorMessage = [
            i18n.t("slideshows.error-messages.duplicate-slide-name")
          ];
          this.loading = false;

          return;
        }
      } else if (
        this.reportActionsDialogContent.type === "print" &&
        !this.isChromiumBrowser
      ) {
        this.showReportActionsDialog = false;

        this.showReportActionsSnackbar = true;
        this.reportActionsSnackbarContent = {
          color: "white",
          content: i18n.t("ui.pdf-in-progress"),
          disableTimeout: true
        };

        const fileName = title;
        const reportUrl = this.generateReportUrl();
        try {
          await pdfService.get(fileName, reportUrl);
        } catch {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("ui.pdf-error")
          });
        }

        this.showReportActionsSnackbar = false;
      } else {
        const urlObj = this.generateReportUrl();
        const queryParams = urlObj.queryParams;
        this.$router.push({ path: queryParams }).catch(() => {});

        store.commit("application/SET_DISPLAY_MODE", DISPLAY_MODES.PRINT);
        store.commit("reports/setExportPdfTitle", title);
      }
      this.showReportActionsDialog = false;
    },
    onCancel() {
      this.showReportActionsDialog = false;
    },
    reportActionsMenuClicked() {
      this.$appInsights?.trackEvent({ name: `Report Export Options Clicked` });
    }
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
  },
  destroyed() {
    window.removeEventListener("keydown", this.onKeyDown);
  }
};
</script>
