<template>
  <div>
    <report-table
      :headers="headers"
      :items="items"
      :initialSortedColumns="['location']"
    />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportTable
  },
  data() {
    return {
      compatibleReportId: 3,
      reportFilters: ["shifts", "locations", "timeframe"],
      items: [],
      headers: [
        {
          text: i18n.t("reports.table.room"),
          value: "location",
          sortable: true
        },
        {
          text: i18n.t("reports.table.unit"),
          value: "parentLocation",
          sortable: true
        },
        {
          text: i18n.t("reports.table.events"),
          value: "totalEvents",
          sortable: true
        },
        {
          text: i18n.t("reports.table.opportunities"),
          value: "totalOpportunities",
          sortable: true
        },
        {
          text: i18n.t("reports.table.performanceFullWord"),
          value: "percentCompliant",
          sortable: true,
          display: "Percentage"
        }
      ]
    };
  },
  methods: {
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      this.items = this.reportResponse.dataPoints.map((item) => {
        return {
          ...item,
          percentCompliant: item.totalOpportunities
            ? item.percentCompliant
            : null
        };
      });
    }
  }
};
</script>
