import { get, put } from "./apiClient";

export default {
  async get(facilityId) {
    return await get(`facility/${facilityId}/performanceBands`);
  },
  async put(facilityId, performanceBands) {
    return await put(
      `facility/${facilityId}/performanceBands`,
      performanceBands
    );
  }
};
