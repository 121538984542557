import { render, staticRenderFns } from "./PerformanceThresholds.vue?vue&type=template&id=2443b467&scoped=true"
import script from "./PerformanceThresholds.vue?vue&type=script&lang=js"
export * from "./PerformanceThresholds.vue?vue&type=script&lang=js"
import style0 from "./PerformanceThresholds.vue?vue&type=style&index=0&id=2443b467&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2443b467",
  null
  
)

export default component.exports