export const DISPLAY_MODES = {
  NORMAL: 1,
  PRINT: 2
};

const application = {
  namespaced: true,
  state: () => ({
    displayMode: DISPLAY_MODES.NORMAL,
    navDrawerVisible: false,
    loading: {
      header: false,
      overlay: false,
      navigation: true
    },
    error: null,
    applicationInsightsLoaded: false,
    pdfServiceInvocation: false,
    idleTimeout: null,
    showSuccessMessage: false,
    successMessage: ""
  }),
  getters: {
    isChromiumBrowser() {
      const brands = navigator.userAgentData?.brands;
      return brands ? brands.some((e) => e.brand === "Chromium") : false;
    }
  },
  mutations: {
    SET_DISPLAY_MODE(state, value) {
      state.displayMode = value;
    },
    SET_NAV_DRAWER(state, value) {
      state.navDrawerVisible = value;
    },
    SET_LOADING_PROGRESS_INDICATOR(state, value) {
      state.loading.header = !!value;
    },
    SET_LOADING_NAVIGATION(state, value) {
      state.loading.navigation = !!value;
    },
    TOGGLE_NAV_DRAWER(state) {
      state.navDrawerVisible = !state.navDrawerVisible;
    },
    SET_ERROR(state, value) {
      state.error = value;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    SET_PDF_SERVICE_INVOCATION(state, value) {
      state.pdfServiceInvocation = value;
    },
    SET_APPLICATION_INSIGHTS_LOADED(state) {
      state.applicationInsightsLoaded = true;
    },
    SET_IDLE_TIMEOUT(state, value) {
      state.idleTimeout = value;
    },
    SET_SHOW_SUCCESS_MESSAGE(state, value) {
      state.showSuccessMessage = value;
    },
    SET_SUCCESS_MESSAGE(state, value) {
      state.successMessage = value;
      state.showSuccessMessage = true;
    }
  },
  actions: {}
};

export default application;
