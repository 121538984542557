<template>
  <v-footer
    id="app-footer"
    color="transparent"
    class="tw-flex-col lg:tw-flex-row tw-items-start lg:tw-items-center tw-mt-5"
  >
    <a href="/" class="tw-self-center lg:tw-self-auto">
      <img
        :src="logo"
        style="max-width: 78px"
        class="tw-mb-2 lg:tw-mb-0 lg:tw-mr-4 tw-mr-0"
        alt="Purell Smartlink logo"
      />
    </a>
    <span
      class="
        tw-text-sm tw-text-purellGray tw-mb-4
        lg:tw-mb-0
        tw-self-center
        lg:tw-self-auto
        tw-text-center
      "
      id="footer__copyright"
    >
      {{ $t("layout.footer.copyright") }}
    </span>
    <div class="tw-flex-1"></div>
    <div class="tw-flex-row tw-justify-center tw-w-full tw-flex lg:tw-w-auto">
      <v-btn
        href="mailto:SMARTLINKsupport@gojo.com"
        text
        color="purellGray"
        class="tw-mr-4 lg:tw-mr-0"
      >
        {{ $t("layout.footer.actions.support") }}
      </v-btn>
      <v-btn
        href="http://www.gojo.com/SMARTLINK-User-Guides"
        text
        color="purellGray"
        class="tw-mr-4 lg:tw-mr-0"
      >
        {{ $t("layout.footer.actions.user-guide") }}
      </v-btn>
      <country-dropdown id="footer__language-trigger" />
    </div>
  </v-footer>
</template>

<script>
import CountryDropdown from "@/components/ui/CountryDropdown.vue";
import logo from "@/assets/images/logos/purellLogo.svg";

export default {
  components: {
    CountryDropdown
  },
  data() {
    return {
      logo: logo
    };
  }
};
</script>

<style scoped lang="postcss">
#app-footer .v-btn {
  @apply tw-px-0 tw-mb-0 tw-capitalize;
}

@media (min-width: 768px) {
  #app-footer .v-btn {
    @apply tw-m-0 tw-mx-2;
  }
}
</style>
