import i18n from "@/plugins/i18n";
import { FILTERS } from "@/types/filters";
import cloneDeep from "lodash/cloneDeep";

export default {
  getDevicesHierarchy({ byId }, rootGetters) {
    const hierarchy = cloneDeep(
      rootGetters[`filters/${FILTERS.LOCATIONS}/getHierarchy`]
    );
    const devicesArray = Object.values(byId);

    devicesArray.sort((a, b) =>
      b.text.localeCompare(a.text, undefined, {
        numeric: true,
        sensitivity: "base"
      })
    );

    devicesArray.forEach((device) => {
      findAndAddDeviceToLocation(hierarchy.items, device);
    });

    const locationHierarchyWithDevices = hierarchy.items.filter(
      (i) => i.childLocations && i.childLocations.length
    );
    locationHierarchyWithDevices.forEach((location) => {
      trimAndPrefixLocation(location);
    });

    return {
      items: locationHierarchyWithDevices
    };
  },
  getDevicesSelectedText({ activeIds, hierarchy }) {
    if (!hierarchy) {
      return;
    }
    if (!activeIds || (activeIds instanceof Array && activeIds.length === 0)) {
      return i18n.t("ui.none-selected");
    }

    const pluralizedName =
      activeIds.length === 1
        ? i18n.t("devices.singular")
        : i18n.t("devices.plural");

    return `${activeIds.length} ${pluralizedName}`;
  },
  getDevicesFilterText({ activeIds, allIds, hierarchy }) {
    if (!hierarchy || !hierarchy.levels) {
      return;
    }
    if (!activeIds || (activeIds instanceof Array && activeIds.length === 0)) {
      return i18n.t("ui.none-selected");
    }
    if (allIds.length === activeIds.length) {
      return i18n.t("ui.all-selected");
    }

    const countOf = i18n.t("count.count-of-total", {
      count: activeIds.length,
      total: allIds.length
    });
    const pluralizedName =
      activeIds.length === 1
        ? i18n.t("devices.singular")
        : i18n.t("devices.plural");

    return `${countOf} ${pluralizedName}`;
  }
};

function findAndAddDeviceToLocation(locations, device) {
  let found = false;

  function addDeviceToLocation(location, device) {
    if (!found) {
      if (location.id === device.locationId) {
        if (location.childLocations) {
          location.childLocations.unshift(device);
        } else {
          location.childLocations = [device];
        }

        found = true;
      } else {
        if (location.childLocations) {
          location.childLocations.forEach((l) => {
            addDeviceToLocation(l, device);
          });
        }
      }
    }
  }

  locations.forEach((location) => {
    addDeviceToLocation(location, device);
  });
}

function trimAndPrefixLocation(location) {
  if (!location.ecmDeviceType) {
    location.childLocations = location.childLocations.filter(
      (l) => (l.childLocations && l.childLocations.length) || l.ecmDeviceType
    );

    location.id = `location-${location.id}`;
  }

  if (location.childLocations) {
    location.childLocations.forEach((c) => {
      trimAndPrefixLocation(c);
    });
  }
}
