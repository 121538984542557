<template>
  <div
    class="
      app
      tw-w-full tw-bg-background tw-flex tw-flex-col tw-min-h-screen tw-h-full
    "
  >
    <v-container>
      <v-card>
        <section class="tw-p-4 tw-px-8 tw-inline-block tw-w-full">
          <h1 class="tw-mb-4">{{ $t(header) }}</h1>
          <p
            v-html="
              $t(body, { value: subscription ? subscription.name : null })
            "
          ></p>
          <v-btn
            v-if="subscription"
            @click="unsubscribe()"
            color="primary"
            class="tw-mt-8 tw-mb-4"
            :loading="loading"
          >
            {{ $t("report-subscription-unsubscribe.cancel-subscription") }}
          </v-btn>
        </section>
        <app-footer />
      </v-card>
    </v-container>
    <v-snackbar color="red" bottom right v-model="showError" timeout="5000">
      {{ $t(error) }}
    </v-snackbar>
  </div>
</template>

<script>
import AppFooter from "@/components/layout/AppFooter.vue";
import reportSubscriptionService from "@/api/reportSubscriptionService";
import logo from "@/assets/images/logos/purellLogo.svg";

export default {
  components: {
    AppFooter
  },
  props: {
    token: {
      type: String
    }
  },
  data: () => ({
    body: "",
    error: undefined,
    header: "",
    loading: false,
    logo,
    showError: false,
    subscription: undefined
  }),
  async mounted() {
    this.subscription =
      await reportSubscriptionService.getReportSubscriptionByToken(this.token);

    if (this.subscription) {
      this.header = "report-subscription-unsubscribe.unsubscribe";
      this.body = "report-subscription-unsubscribe.confirm-unsubscribe";
    } else {
      this.header = "report-subscription-unsubscribe.subscription-not-found";
      this.body = "report-subscription-unsubscribe.not-subscribed";
    }
  },
  methods: {
    async unsubscribe() {
      try {
        this.loading = true;
        await reportSubscriptionService.unsubscribe(this.token);

        this.header = "report-subscription-unsubscribe.unsubscribed";
        this.body = "";
        this.subscription = undefined;
      } catch (err) {
        this.error =
          "report-subscription-unsubscribe.errors.unsubscribe-message";
        this.showError = true;
        this.loading = false;
      }
    }
  }
};
</script>
