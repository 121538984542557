export const EXPIRATION_DATES = {
  EXPIRED: 0,
  ONE_MONTH: 1,
  TWO_MONTHS: 2,
  THREE_MONTHS: 3
};

export const EXPIRATION_DATES_STRINGS = {
  alreadyExpired: EXPIRATION_DATES.EXPIRED,
  oneMonth: EXPIRATION_DATES.ONE_MONTH,
  twoMonths: EXPIRATION_DATES.TWO_MONTHS,
  threeMonths: EXPIRATION_DATES.THREE_MONTHS
};
