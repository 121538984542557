<template>
  <div>
    <v-dialog
      :transition="
        $vuetify.breakpoint.mdAndUp ? undefined : 'dialog-bottom-transition'
      "
      :value="visible"
      @input="onClose"
      @click:outside="onClose"
      :max-width="$vuetify.breakpoint.mdAndUp ? 400 : undefined"
      inset
    >
      <v-sheet>
        <list-popup-selectable-list
          @close="onClose"
          v-model="internalValue"
          v-bind="$props"
        />
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import ListPopupSelectableList from "./components/ListPopupSelectableList.vue";

export default {
  components: {
    ListPopupSelectableList
  },
  props: {
    value: [Array, String, Number],
    multiple: Boolean,
    items: Array,
    visible: Boolean,
    title: String,
    itemTitleKey: String,
    itemSubtitleKey: String,
    "enabled-key": String,
    searchable: Boolean,
    menu: Boolean
  },
  watch: {
    value(val) {
      this.internalValue = val;
    }
  },
  data() {
    return {
      internalValue: this.$props.value
    };
  },
  methods: {
    onClose() {
      this.$emit("input", this.internalValue);
      this.$emit("close");
    }
  }
};
</script>
