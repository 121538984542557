<template>
  <dashboard :loading="loading" :item="facility" :logo="logo">
    <template v-slot:title>
      <div>
        <v-btn
          text
          class="tw-justify-start"
          :to="{
            name: ROUTE_NAMES.customerDashboard,
            query: { customerId: facility.customerId }
          }"
        >
          <h3>{{ facility.customerName }}</h3>
          <v-icon right> mdi-undo-variant </v-icon>
        </v-btn>
        <div class="tw-flex tw-flex-row tw-items-center">
          <h1 class="tw-text-3xl tw-mr-2">{{ facility.name }}</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  checkPrivileges(
                    facility.privileges,
                    PRIVILEGES.editFacilities
                  )
                "
                icon
                primary
                v-bind="attrs"
                v-on="on"
                :to="{
                  name: ROUTE_NAMES.editFacility,
                  params: { facilityId }
                }"
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <span>
              {{
                $t(
                  "admin.customer-dashboard.facility-widget.edit-facility-settings"
                )
              }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template v-slot:widgets>
      <dashboard-widget
        v-if="checkPrivileges(facility.privileges, PRIVILEGES.manageLicenses)"
        title="admin.facility-dashboard.licenses"
        v-slot="{ fullscreen, setLocalizedWidgetMessage }"
      >
        <licenses-widget
          :facilityId="facilityId"
          :fullscreen="fullscreen"
          :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
          @reload-facility="loadFacility"
        />
      </dashboard-widget>
      <users-widget
        :customerId="customerId"
        :facilityId="facilityId"
        :export-name="facility.name"
      />
      <dashboard-widget
        title="admin.facility-dashboard.facility-hierarchy"
        v-slot="{
          fullscreen,
          changeWidgetCurrentTitle,
          setLocalizedWidgetMessage,
          setWidgetFullscreen
        }"
      >
        <location-hierarchy-widget
          :fullscreen="fullscreen"
          :customerId="customerId"
          :facilityId="facilityId"
          :facility="facility"
          :hasObvLicense="hasObvLicense"
          :hasAmsLicense="hasAmsLicense"
          :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
          :changeWidgetCurrentTitle="changeWidgetCurrentTitle"
          :setWidgetFullscreen="setWidgetFullscreen"
        />
      </dashboard-widget>
      <dashboard-widget
        v-if="numberOfDevices > 0 || showDispensersAndDevices"
        title="admin.facility-dashboard.dispensers-devices"
      >
        <template v-slot:titleCustomContent>
          <v-btn
            class="tw-mt-1 tw-ml-3"
            plain
            :to="{
              name: ROUTE_NAMES.deviceMaintenance,
              query: { facilityId }
            }"
          >
            <span>
              {{
                $t(
                  "admin.facility-dashboard.dispensers-devices-widget.view-all"
                )
              }}
            </span>
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
        </template>
        <dispensers-and-devices-widget
          :customerId="customerId"
          :facilityId="facilityId"
          :licenses="facility.licenses"
          :deviceCounts="deviceCounts"
        />
      </dashboard-widget>
      <dashboard-widget
        title="admin.facility-dashboard.shifts"
        v-slot="{ setLocalizedWidgetMessage, clearLocalizedWidgetMessage }"
      >
        <shifts-widget
          :facilityId="facilityId"
          :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
          :clearLocalizedWidgetMessage="clearLocalizedWidgetMessage"
          :facilityPrivileges="facility.privileges"
        />
      </dashboard-widget>
      <dashboard-widget
        v-if="showOBVJobRoles"
        title="admin.facility-dashboard.obv-job-roles"
        v-slot="{ changeWidgetCurrentTitle, setLocalizedWidgetMessage }"
      >
        <obv-job-roles-widget
          :customerId="customerId"
          :facilityId="facilityId"
          :changeWidgetCurrentTitle="changeWidgetCurrentTitle"
          :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
        />
      </dashboard-widget>
    </template>
  </dashboard>
</template>

<script>
import customerService from "@/api/customerService";
import facilityService from "@/api/facilityService";
import deviceService from "@/api/deviceService";
import Dashboard from "@/views/admin/components/Dashboard.vue";
import DashboardWidget from "@/views/admin/components/DashboardWidget.vue";
import ObvJobRolesWidget from "@/views/admin/components/facilityWidgets/ObvJobRolesWidget.vue";
import LicensesWidget from "@/views/admin/components/facilityWidgets/LicensesWidget.vue";
import DispensersAndDevicesWidget from "@/views/admin/components/facilityWidgets/DispensersAndDevicesWidget.vue";
import LocationHierarchyWidget from "@/views/admin/components/facilityWidgets/LocationHierarchyWidget.vue";
import UsersWidget from "@/views/admin/components/customerWidgets/UsersWidget.vue";
import ShiftsWidget from "@/views/admin/components/facilityWidgets/ShiftsWidget.vue";
import i18n from "@/plugins/i18n";
import PRIVILEGES, { checkPrivileges } from "@/types/privileges";
import { ROUTE_NAMES } from "@/types/routeNames";
import { PRODUCTS } from "@/types/products";

export default {
  components: {
    Dashboard,
    DashboardWidget,
    UsersWidget,
    ShiftsWidget,
    ObvJobRolesWidget,
    LicensesWidget,
    DispensersAndDevicesWidget,
    LocationHierarchyWidget
  },
  props: {
    facilityId: { type: Number },
    customerId: { type: Number }
  },
  data() {
    return {
      deviceCounts: {},
      facility: { licenses: [], privileges: [] },
      logo: "",
      loading: true,
      hasObvLicense: false,
      hasAmsLicense: false,
      hideSwitches: false,
      numberOfDevices: 0,
      PRIVILEGES,
      ROUTE_NAMES,
      showDispensersAndDevices: false,
      showOBVJobRoles: false,
      showPendingUsers: false,
      showDeactivatedUsers: false
    };
  },
  async created() {
    await this.loadFacility();

    try {
      deviceService.getDevicesCount(this.facilityId).then((deviceCounts) => {
        this.deviceCounts = deviceCounts;
        this.numberOfDevices = Object.values(deviceCounts).reduce(
          (a, b) => a + b,
          0
        );
      });
    } catch (err) {
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t(
          "admin.facility-dashboard.dispensers-devices-widget.error-messages.get-dispensers-and-devices-count"
        )
      });
    }
  },
  methods: {
    checkPrivileges,
    async loadFacility() {
      try {
        this.facility = await facilityService.get({
          facilityId: this.facilityId
        });

        const today = new Date();
        this.hasObvLicense = this.facility?.licenses.some(
          (l) =>
            l.product?.code === PRODUCTS.OBS &&
            new Date(l.expirationDate) >= today
        );
        this.hasAmsLicense = this.facility?.licenses.some(
          (l) =>
            l.product?.code === PRODUCTS.ECM &&
            new Date(l.expirationDate) >= today
        );

        this.showOBVJobRoles =
          this.hasObvLicense &&
          this.checkPrivileges(
            this.facility.privileges,
            PRIVILEGES.manageJobRoles
          );

        this.showDispensersAndDevices = this.facility?.licenses.some((l) => {
          const dispensersAndDevicesLicenses = [
            PRODUCTS.ECM,
            PRODUCTS.SA,
            PRODUCTS.IMS
          ];

          return dispensersAndDevicesLicenses.includes(l.product?.code);
        });
      } catch (error) {
        if (error === 403) {
          this.$router.push({
            name: ROUTE_NAMES.adminCustomerList
          });
        }
      } finally {
        this.loading = false;

        if (this.facility?.mediaItemId) {
          this.logo = await customerService.getCustomerLogo(
            this.facility.customerId
          );
        }
      }
    }
  }
};
</script>
<style lang="postcss" scoped>
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0px;
}
</style>
