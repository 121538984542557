<template>
  <no-header-footer>
    <v-container
      class="
        tw-mt-20
        tw-mb-6
        tw-p-4
        tw-px-8
        tw-w-full
        tw-max-w-xl
        tw-m-auto
        tw-border-solid
        tw-border
        tw-border-purellGray
        tw-bg-white
        tw-rounded
      "
    >
      <img class="go-logo tw-block tw-h-auto tw-mx-auto tw-mb-6" :src="logo" />
      <section v-if="step !== 'loading'">
        <h1 class="tw-mb-4">
          {{ $t("alert-subscriptions.unsubscribe-from-email.header") }}
        </h1>
        <div>
          <p
            v-html="
              $t(`alert-subscriptions.unsubscribe-from-email.${step}`, {
                alert: name
              })
            "
          ></p>
          <v-btn
            v-if="step === 'confirm'"
            primary
            :loading="buttonLoading"
            @click="confirmUnsubscribe"
            color="primary"
          >
            {{ $t("ui.actions.confirm") }}
          </v-btn>
        </div>
      </section>
    </v-container>
    <div class="tw-flex tw-justify-center">
      <country-dropdown />
    </div>
  </no-header-footer>
</template>

<script>
import NoHeaderFooter from "@/layouts/NoHeaderFooter.vue";
import CountryDropdown from "@/components/ui/CountryDropdown.vue";
import alertSubscriptionService from "@/api/alertSubscriptionService";
import logo from "@/assets/images/logos/purellLogo.svg";

export default {
  components: {
    NoHeaderFooter,
    CountryDropdown
  },
  props: {
    token: {
      type: String
    }
  },
  data: () => ({
    logo,
    step: "loading",
    buttonLoading: false,
    alertSubscription: null,
    name: ""
  }),
  async mounted() {
    this.getAlertSubscriptionByToken();
  },
  methods: {
    async getAlertSubscriptionByToken() {
      try {
        const response =
          await alertSubscriptionService.getAlertSubscriptionByToken(
            this.token
          );

        this.step = "confirm";
        this.name = response.name;
      } catch {
        this.step = "no-result";
      }
    },
    async confirmUnsubscribe() {
      this.buttonLoading = true;
      try {
        await alertSubscriptionService.unsubscribeAlertSubscriptionByToken(
          this.token
        );
        this.step = "success";
      } catch {
        this.step = "failure";
      }
    }
  }
};
</script>

<style scoped lang="postcss">
.go-logo {
  width: 180px;
}
</style>
