<template>
  <div>
    <p>{{ $t("account.manage.password-requirements") }}:</p>
    <div class="tw-mb-5">
      <v-icon :color="length ? 'primary' : 'lightPurellGray'">
        mdi-check-circle
      </v-icon>
      {{ $t("account.manage.eight-characters") }}
    </div>
    <p>{{ $t("account.manage.three-of-following") }}:</p>
    <div>
      <v-icon :color="lower ? 'primary' : 'lightPurellGray'">
        mdi-check-circle
      </v-icon>
      {{ $t("account.manage.lowercase") }}
    </div>
    <div>
      <v-icon :color="upper ? 'primary' : 'lightPurellGray'">
        mdi-check-circle
      </v-icon>
      {{ $t("account.manage.uppercase") }}
    </div>
    <div>
      <v-icon :color="number ? 'primary' : 'lightPurellGray'">
        mdi-check-circle
      </v-icon>
      {{ $t("account.manage.number") }}
    </div>
    <div>
      <v-icon :color="special ? 'primary' : 'lightPurellGray'">
        mdi-check-circle
      </v-icon>
      {{ $t("account.manage.special") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    password: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    length: false,
    lower: false,
    upper: false,
    number: false,
    special: false
  }),
  watch: {
    password(value) {
      const lengthCheck = new RegExp(/^.{8,100}/);
      this.length = lengthCheck.test(value);

      const lowercaseCheck = new RegExp(/(?=.*[a-z])/);
      this.lower = lowercaseCheck.test(value);

      const uppercaseCheck = new RegExp(/(?=.*[A-Z])/);
      this.upper = uppercaseCheck.test(value);

      const numberCheck = new RegExp(/(?=.*\d)/);
      this.number = numberCheck.test(value);

      const specialCheck = new RegExp(
        /(?=.*[@#$%^&£*\-_+=[\]{}<>|\\:',.?/`~""();!])/
      );
      this.special = specialCheck.test(value);
    }
  }
};
</script>
