<template>
  <v-dialog
    :transition="
      $vuetify.breakpoint.mdAndUp ? undefined : 'dialog-bottom-transition'
    "
    :value="visible"
    persistent
    no-click-animation
    inset
    @click:outside="canCloseDialog"
    max-width="85%"
  >
    <v-card class="tw-p-6 tw-pt-2">
      <h2>
        {{ $t("admin.room-template-configuration.title") }}
      </h2>
      <ValidationObserver ref="roomTemplateForm" v-slot="{ pristine }">
        <v-form @submit.prevent="validateAndSave">
          <ValidationProvider
            :name="$t('admin.room-template-configuration.room-type')"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <v-autocomplete
              auto-select-first
              v-model="productId"
              :error-messages="errors"
              :label="$t('admin.room-template-configuration.room-type')"
              :items="products"
              item-text="text"
              item-value="id"
              class="lg:tw-w-1/2 lg:tw-pr-4"
              @change="resetForm()"
            />
          </ValidationProvider>
          <div v-if="IMSChosen" class="lg:tw-w-1/2 lg:tw-pr-4">
            <h3>{{ $t("admin.room-template-configuration.gateway") }}</h3>
            <ValidationProvider
              :name="
                $t('admin.room-template-configuration.gateway-device-model')
              "
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-autocomplete
                auto-select-first
                v-model="gatewayModelId"
                :error-messages="errors"
                :label="$t('admin.room-template-configuration.device-model')"
                :items="gatewayModels"
                item-value="id"
                item-text="name"
              />
            </ValidationProvider>
          </div>
          <h3>
            {{ $t("admin.room-template-configuration.opportunity-counter") }}
          </h3>
          <v-row>
            <v-col cols="12" sm="6">
              <ValidationProvider
                :name="$t('admin.room-template-configuration.oc-device-model')"
                :rules="{ required: IMSChosen }"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  auto-select-first
                  v-model="opportunityCounterModelId"
                  :error-messages="errors"
                  :label="$t('admin.room-template-configuration.device-model')"
                  :items="opportunityCounterModels"
                  item-value="id"
                  item-text="name"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                :name="'Include Outside Opportunity Counter'"
                v-if="IMSChosen"
              >
                <v-switch
                  v-model="includeOutsideOpportunityCounter"
                  :label="
                    includeOutsideOpportunityCounter
                      ? $t('admin.room-template-configuration.include')
                      : $t('admin.room-template-configuration.exclude')
                  "
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <h3>
            {{ $t("admin.room-template-configuration.sanitizer-dispensers") }}
          </h3>
          <v-row>
            <v-col cols="12" sm="6">
              <span>
                {{ $t("admin.room-template-configuration.how-many-inside") }}
              </span>
              <ValidationProvider :name="'Inside Room Sanitizer Count'">
                <v-slider
                  v-model="insideRoomSanitizerCount"
                  :tick-labels="deviceCountOptions"
                  :max="3"
                  step="1"
                  ticks="always"
                  tick-size="4"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                :name="
                  $t(
                    'admin.room-template-configuration.inside-sanitizer-device-model'
                  )
                "
                :rules="{ required: insideRoomSanitizerCount > 0 }"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  auto-select-first
                  v-model="insideRoomSanitizerModelId"
                  :error-messages="errors"
                  :label="$t('admin.room-template-configuration.device-model')"
                  :items="sanitizerModels"
                  item-value="id"
                  item-text="name"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <span>
                {{ $t("admin.room-template-configuration.how-many-outside") }}
              </span>
              <ValidationProvider :name="'Outside Room Sanitizer Count'">
                <v-slider
                  v-model="outsideRoomSanitizerCount"
                  :tick-labels="deviceCountOptions"
                  :max="3"
                  step="1"
                  ticks="always"
                  tick-size="4"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                :name="
                  $t(
                    'admin.room-template-configuration.outside-sanitizer-device-model'
                  )
                "
                :rules="{ required: outsideRoomSanitizerCount > 0 }"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  auto-select-first
                  v-model="outsideRoomSanitizerModelId"
                  :error-messages="errors"
                  :label="$t('admin.room-template-configuration.device-model')"
                  :items="sanitizerModels"
                  item-value="id"
                  item-text="name"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <h3>
            {{ $t("admin.room-template-configuration.soap-dispensers") }}
          </h3>
          <v-row>
            <v-col cols="12" sm="6">
              <span>
                {{ $t("admin.room-template-configuration.how-many-inside") }}
              </span>
              <ValidationProvider :name="'Inside Room Soap Count'">
                <v-slider
                  v-model="insideRoomSoapCount"
                  :tick-labels="deviceCountOptions"
                  :max="3"
                  step="1"
                  ticks="always"
                  tick-size="4"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                :name="
                  $t(
                    'admin.room-template-configuration.inside-soap-device-model'
                  )
                "
                :rules="{ required: insideRoomSoapCount > 0 }"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  auto-select-first
                  v-model="insideRoomSoapModelId"
                  :error-messages="errors"
                  :label="$t('admin.room-template-configuration.device-model')"
                  :items="soapDispenserModels"
                  item-value="id"
                  item-text="name"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <span>
                {{ $t("admin.room-template-configuration.how-many-outside") }}
              </span>
              <ValidationProvider :name="'Outside Room Soap Count'">
                <v-slider
                  v-model="outsideRoomSoapCount"
                  :tick-labels="deviceCountOptions"
                  :max="3"
                  step="1"
                  ticks="always"
                  tick-size="4"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                :name="
                  $t(
                    'admin.room-template-configuration.outside-soap-device-model'
                  )
                "
                :rules="{ required: outsideRoomSoapCount > 0 }"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  auto-select-first
                  v-model="outsideRoomSoapModelId"
                  :error-messages="errors"
                  :label="$t('admin.room-template-configuration.device-model')"
                  :items="soapDispenserModels"
                  item-value="id"
                  item-text="name"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row class="tw-justify-end">
            <v-btn
              class="tw-mr-5 tw-w-24"
              color="primary"
              type="submit"
              :loading="saving"
              :disabled="pristine"
            >
              {{ $t("ui.actions.save") }}
            </v-btn>
            <v-btn
              :disabled="saving"
              class="tw-mr-5 tw-w-24"
              color="background2"
              @click="closeForm"
            >
              {{ $t("ui.actions.cancel") }}
            </v-btn>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n";
import { PRODUCTS, PRODUCT_TRANSLATIONS } from "@/types/products";
import deviceService from "@/api/deviceService";
import productsService from "@/api/productsService";
import roomTemplateService from "@/api/roomTemplateService";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    facilityId: { type: Number },
    visible: { type: Boolean, default: false }
  },
  data() {
    return {
      deviceCountOptions: ["0", "1", "2", "3"],
      deviceModels: [],
      gatewayModelId: "",
      gatewayModels: [],
      includeOutsideOpportunityCounter: false,
      insideRoomSanitizerCount: 0,
      insideRoomSanitizerModelId: "",
      insideRoomSoapCount: 0,
      insideRoomSoapModelId: "",
      opportunityCounterModelId: "",
      opportunityCounterModels: [],
      outsideRoomSanitizerCount: 0,
      outsideRoomSanitizerModelId: "",
      outsideRoomSoapCount: 0,
      outsideRoomSoapModelId: "",
      productId: "",
      products: [],
      roomTemplate: {},
      sanitizerModels: [],
      saving: false,
      soapDispenserModels: []
    };
  },
  computed: {
    IMSChosen() {
      return (
        this.products.find((p) => p.id === this.productId)?.code ===
        PRODUCTS.IMS
      );
    }
  },
  async mounted() {
    this.getRoomTemplate();
    deviceService.getDeviceModels().then((deviceModels) => {
      this.deviceModels = deviceModels;
      this.setDeviceModels(this.deviceModels);
    });

    productsService.getAll().then((products) => {
      this.products = products
        .filter((p) => p.code === PRODUCTS.ECM || p.code === PRODUCTS.IMS)
        .map((p) => {
          return {
            ...p,
            text: PRODUCT_TRANSLATIONS[p.code]
          };
        });
    });
  },
  methods: {
    canCloseDialog(e) {
      const path = e.composedPath();
      if (
        this.visible &&
        !this.saving &&
        !document.querySelector(".menuable__content__active") &&
        !path.includes(document.querySelector(".v-dialog__content"))
      ) {
        this.closeForm();
      }
    },
    closeForm() {
      this.$emit("update:visible", false);
      this.setForm(this.roomTemplate);
      this.setDeviceModels(this.deviceModels);
      this.$refs.roomTemplateForm.reset();
    },
    getRoomTemplate() {
      roomTemplateService.get(this.facilityId).then((roomTemplate) => {
        this.roomTemplate = roomTemplate;
        this.setForm(roomTemplate);
      });
    },
    setDeviceModels(deviceModels) {
      this.gatewayModels = this.filterDeviceModels(deviceModels.gatewayModels, [
        this.gatewayModelId
      ]);
      this.opportunityCounterModels = this.filterDeviceModels(
        deviceModels.opportunityCounterModels,
        [this.opportunityCounterModelId]
      );
      this.sanitizerModels = this.filterDeviceModels(
        deviceModels.sanitizerModels,
        [this.insideRoomSanitizerModelId, this.outsideRoomSanitizerModelId]
      );
      this.soapDispenserModels = this.filterDeviceModels(
        deviceModels.soapDispenserModels,
        [this.insideRoomSoapModelId, this.outsideRoomSoapModelId]
      );
    },
    filterDeviceModels(models, activeIds) {
      return this.IMSChosen
        ? models.filter(
            (m) =>
              (!m.isArchived || activeIds.includes(m.id)) &&
              m.products.some((p) => p.code === PRODUCTS.IMS)
          )
        : models.filter(
            (m) =>
              (!m.isArchived || activeIds.includes(m.id)) &&
              m.products.some((p) => p.code === PRODUCTS.ECM)
          );
    },
    resetForm() {
      this.setDeviceModels(this.deviceModels);
      this.gatewayModelId = "";
      this.opportunityCounterModelId = "";
      this.insideRoomSanitizerModelId = "";
      this.outsideRoomSanitizerModelId = "";
      this.insideRoomSoapModelId = "";
      this.outsideRoomSoapCount = "";
      this.$refs.roomTemplateForm.reset();
    },
    setForm(roomTemplate) {
      this.gatewayModelId = roomTemplate.gatewayModelId;
      this.includeOutsideOpportunityCounter =
        roomTemplate.includeOutsideOpportunityCounter;
      this.insideRoomSanitizerCount = roomTemplate.insideRoomSanitizerCount;
      this.insideRoomSanitizerModelId = roomTemplate.insideRoomSanitizerModelId;
      this.insideRoomSoapCount = roomTemplate.insideRoomSoapCount;
      this.insideRoomSoapModelId = roomTemplate.insideRoomSoapModelId;
      this.opportunityCounterModelId = roomTemplate.opportunityCounterModelId;
      this.outsideRoomSanitizerCount = roomTemplate.outsideRoomSanitizerCount;
      this.outsideRoomSanitizerModelId =
        roomTemplate.outsideRoomSanitizerModelId;
      this.outsideRoomSoapCount = roomTemplate.outsideRoomSoapCount;
      this.outsideRoomSoapModelId = roomTemplate.outsideRoomSoapModelId;
      this.productId = roomTemplate.productId;
    },
    async validateAndSave() {
      const valid = await this.$refs.roomTemplateForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Room Template's Form Error`
        });
        return;
      }

      this.saving = true;

      try {
        const roomTemplate = {
          gatewayModelId: this.gatewayModelId,
          includeOutsideOpportunityCounter: this.IMSChosen
            ? this.includeOutsideOpportunityCounter
            : false,
          insideRoomSanitizerCount: this.insideRoomSanitizerCount,
          insideRoomSanitizerModelId: this.insideRoomSanitizerModelId,
          insideRoomSoapCount: this.insideRoomSoapCount,
          insideRoomSoapModelId: this.insideRoomSoapModelId,
          opportunityCounterModelId: this.opportunityCounterModelId,
          outsideRoomSanitizerCount: this.outsideRoomSanitizerCount,
          outsideRoomSanitizerModelId: this.outsideRoomSanitizerModelId,
          outsideRoomSoapCount: this.outsideRoomSoapCount,
          outsideRoomSoapModelId: this.outsideRoomSoapModelId,
          productId: this.productId
        };

        if (!this.roomTemplate.isDefault) {
          await roomTemplateService.edit(this.facilityId, roomTemplate);

          this.$appInsights?.trackEvent({
            name: `Room Template Edit Form Success`
          });
          this.$store.commit(
            "application/SET_SUCCESS_MESSAGE",
            i18n.t("admin.room-template-configuration.success-messages.edit")
          );
        } else {
          await roomTemplateService.create(this.facilityId, roomTemplate);

          this.$appInsights?.trackEvent({
            name: `Room Template Create Form Success`
          });
          this.$store.commit(
            "application/SET_SUCCESS_MESSAGE",
            i18n.t("admin.room-template-configuration.success-messages.create")
          );
        }

        this.$emit("onSave");
        this.$emit("update:visible", false);
        this.getRoomTemplate();
      } catch (error) {
        if (!this.roomTemplate.isDefault) {
          this.$appInsights?.trackEvent({
            name: `Room Template Edit Server Error`
          });
        } else {
          this.$appInsights?.trackEvent({
            name: `Room Template Create Server Error`
          });
        }
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.room-template-configuration.error-messages.save"
          )
        });
      }

      this.saving = false;
    }
  }
};
</script>
