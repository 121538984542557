import BrowserStorage from "./browserStorage";
import Cookie from "js-cookie";

export default class CookieStorage extends BrowserStorage {
  static store(key = "", value = "", options = {}) {
    Cookie.set(key, value, options);
  }

  static get(key = "", fallback = "") {
    return Cookie.get(key) || fallback;
  }

  static remove(key = "") {
    Cookie.remove(key);
  }
}
