import i18n from "@/plugins/i18n";

export const alertTypes = {
  1: {
    icon: require("@/assets/images/sa/OfflineDevice.png"),
    translation: i18n.t("reports.sa.alert-types.offline-device")
  },
  2: {
    icon: require("@/assets/images/sa/CloggedRefill.png"),
    translation: i18n.t("reports.sa.alert-types.clogged-refill")
  },
  3: {
    icon: require("@/assets/images/sa/NoOrDamagedRefill.png"),
    translation: i18n.t("reports.sa.alert-types.no-damaged-refill")
  },
  4: {
    icon: require("@/assets/images/sa/WrongRefill.png"),
    translation: i18n.t("reports.sa.alert-types.wrong-refill")
  },
  5: {
    icon: require("@/assets/images/sa/DeadBattery.png"),
    translation: i18n.t("reports.sa.alert-types.dead-battery")
  },
  6: {
    icon: require("@/assets/images/sa/LowBattery.png"),
    translation: i18n.t("reports.sa.alert-types.low-battery")
  },
  7: {
    icon: require("@/assets/images/sa/PumphouseFault.png"),
    translation: i18n.t("reports.sa.alert-types.pumphouse-fault")
  },
  8: {
    icon: require("@/assets/images/sa/LowRefill.png"),
    translation: i18n.t("reports.sa.alert-types.low-refill")
  },
  9: {
    icon: require("@/assets/images/sa/LowModuleBattery.png"),
    translation: i18n.t("reports.sa.alert-types.low-module-battery")
  },
  10: {
    icon: require("@/assets/images/sa/DeadModuleBattery.png"),
    translation: i18n.t("reports.sa.alert-types.dead-module-battery")
  }
};
