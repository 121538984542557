<template>
  <div>
    <report-table
      :customHeaderSort="customHeaderSort"
      :headers="headers"
      :items="items"
      :showTotalRow="true"
      :showCustomHeader="true"
    >
      <template v-slot:customHeader>
        <thead v-if="headers.length" class="v-data-table-header">
          <tr class="smartlink-report-table-preheader">
            <template v-for="(header, index) in headers">
              <th
                @click="sortColumn(header.value)"
                class="smartlink-report-tall-header"
                rowspan="2"
                v-if="index < 1"
                :key="header.text"
              >
                {{ header.text }}
                <v-icon
                  v-if="header.value === customHeaderSort.value"
                  color="black"
                  small
                >
                  {{
                    customHeaderSort.columnDescending
                      ? "mdi-arrow-down"
                      : "mdi-arrow-up"
                  }}
                </v-icon>
              </th>
            </template>
            <th
              v-for="header in preHeaders"
              :key="header.text"
              :colspan="header.colspan"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr class="smartlink-report-table-primary-header">
            <template v-for="(header, index) in headers">
              <th
                @click="sortColumn(header.value)"
                v-if="index > 0"
                :key="`${header.text}${index}`"
              >
                {{ header.text }}
                <v-icon
                  v-if="header.value === customHeaderSort.value"
                  color="black"
                  small
                >
                  {{
                    customHeaderSort.columnDescending
                      ? "mdi-arrow-down"
                      : "mdi-arrow-up"
                  }}
                </v-icon>
              </th>
            </template>
          </tr>
        </thead>
      </template>
      <template v-slot:totalRow>
        <tr class="smartlink-table-total-row">
          <td>Total</td>
          <template v-for="(column, index) in totalRowColumns">
            <td :key="`percentage${index}`">
              <display-formatter-percentage :value="column.percentage" />
            </td>
            <td :key="`number${index}`">{{ column.number }}</td>
          </template>
        </tr>
      </template>
    </report-table>
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";
import customHeaderSortMixin from "@/views/reports/mixins/customHeaderSortMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import { FILTERS } from "@/types/filters";
import { activityBy } from "@/types/activityBy";
import { abberviatedMonthIndexToString } from "@/types/monthTranslator";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import { buildTotalRow } from "@/views/reports/OBV/utils/HHActivityReportTotalBuilder";
import { variableColumnGenerator } from "@/views/reports/utils/group";
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";
import DisplayFormatterPercentage from "@/views/reports/components/reportSections/displayFormatters/DisplayFormatterPercentage.vue";

export default {
  mixins: [reportMixin, customHeaderSortMixin],
  components: {
    ReportTable,
    DisplayFormatterPercentage
  },
  data() {
    return {
      compatibleReportId: 12,
      yearIndex: 0,
      reportFilters: [
        "activityBy",
        "hygieneProtocolOpportunities",
        "obvJobRoles",
        "locations",
        "shifts",
        "months",
        "obvYears"
      ],
      hiddenReportActions: ["saveSlide"],
      customHeaderSort: {},
      items: [],
      preHeaders: [],
      headers: [],
      totalRowColumns: []
    };
  },
  computed: {
    ...mapState(`filters/${FILTERS.ACTIVITYBY}`, {
      activityBy: (state) => state.activeIds
    }),
    activeMonths() {
      const activeIds = [
        ...this.$store.state[`filters/${FILTERS.MONTHS}`].activeIds
      ];
      return activeIds.sort(function (a, b) {
        return a - b;
      });
    },
    activeObvYears() {
      const activeIds = [
        ...this.$store.state[`filters/${FILTERS.OBVYEARS}`].activeIds
      ];
      return activeIds.sort(function (a, b) {
        return a - b;
      });
    }
  },
  mounted() {
    this.setCustomHeaderSort("groupName");
  },
  methods: {
    createHeaderText(index) {
      const monthGroupIndex = index % this.activeMonths.length;
      const year = String(this.activeObvYears[this.yearIndex]).substring(2);

      this.yearIndex =
        monthGroupIndex === this.activeMonths.length - 1
          ? this.yearIndex + 1
          : this.yearIndex;

      return `${
        abberviatedMonthIndexToString[this.activeMonths[monthGroupIndex] - 1]
      } ${year}`;
    },
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.csvHeader);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    generatePreHeaders() {
      const preHeaders = [];

      for (let i = 0; i < this.reportResponse.intervalCount; i++) {
        preHeaders.push({
          text: this.createHeaderText(i),
          colspan: 2
        });
      }

      this.yearIndex = 0;

      this.preHeaders = preHeaders;
    },
    generateHeaders() {
      const activtyByText =
        this.activityBy === activityBy.unit
          ? i18n.t("locations.locationlevelunit-singular")
          : i18n.t("job-roles.singular");

      const headers = [
        {
          value: "groupName",
          text: activtyByText,
          csvHeader: activtyByText
        }
      ];

      for (let i = 0; i < this.reportResponse.intervalCount; i++) {
        const csvHeader = this.createHeaderText(i);

        headers.push(
          {
            text: "%",
            csvHeader: `${csvHeader} %`,
            value: `column${i}CompliancePercentage`,
            display: "Percentage"
          },
          {
            text: "#",
            csvHeader: `${csvHeader} #`,
            value: `column${i}Opportunities`,
            display: "Number"
          }
        );
      }

      this.yearIndex = 0;

      this.headers = headers;
    },
    sortColumn(headerValue) {
      const columnDescending = this.customHeaderSort.value === headerValue;
      const clearSort =
        this.customHeaderSort.columnDescending && columnDescending;

      this.customHeaderSort = {
        value: clearSort ? null : headerValue,
        columnDescending,
        clearSort
      };
    },
    handleReportResponse() {
      const reportResponse = this.reportResponse;

      this.items = variableColumnGenerator({
        items: reportResponse.dataPoints,
        staticColumns: ["groupName"]
      });

      this.generateHeaders();
      this.generatePreHeaders();

      this.totalRowColumns = buildTotalRow(
        this.items,
        reportResponse.intervalCount
      );
    }
  }
};
</script>

<style scoped lang="postcss">
.smartlink-report-table-preheader th {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  text-align: center !important;
}
.smartlink-report-table-primary-header th {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}
.smartlink-report-tall-header {
  border-left: 1px solid #eee;
}
</style>
