import { get, post, put, remove } from "./apiClient";

export default {
  async listCreatedAlerts(facilityId) {
    return await get(
      `alertSubscriptions/createdAlerts?facilityId=${facilityId}`
    );
  },
  async listSubscribedAlerts(facilityId) {
    return await get(
      `alertSubscriptions/subscribedAlerts?facilityId=${facilityId}`
    );
  },
  async toggleAlertStatus(viewModel) {
    await put(`alertSubscriptions/toggleStatus`, viewModel);
  },
  async deleteAlertSubscription(viewModel) {
    await remove(`alertSubscriptions/delete`, viewModel);
  },
  async unsubscribeAlertSubscription(unsubscribeKey) {
    await post(`alertSubscriptions/unsubscribe`, unsubscribeKey);
  },
  async getInstantAlertViewModel(viewModel) {
    return await post(`alertSubscriptions/instantAlert/viewModel`, viewModel);
  },
  async getScheduledAlertViewModel(viewModel) {
    return await post(`alertSubscriptions/scheduledAlert/viewModel`, viewModel);
  },
  async saveInstantAlert(viewModel) {
    await post(`alertSubscriptions/instantAlert/save`, viewModel);
  },
  async saveScheduledAlert(viewModel) {
    await post(`alertSubscriptions/scheduledAlert/save`, viewModel);
  },
  async getAlertSubscriptionByToken(token) {
    return await get(`alertSubscriptions/alertsubscriptionbytoken/${token}`);
  },
  async unsubscribeAlertSubscriptionByToken(token) {
    await remove(`alertSubscriptions/unsubscribebytoken/${token}`);
  }
};
