var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dashboard',{attrs:{"loading":_vm.loading,"item":_vm.facility,"logo":_vm.logo},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_c('v-btn',{staticClass:"tw-justify-start",attrs:{"text":"","to":{
            name: _vm.ROUTE_NAMES.customerDashboard,
            query: { customerId: _vm.facility.customerId }
          }}},[_c('h3',[_vm._v(_vm._s(_vm.facility.customerName))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-undo-variant ")])],1),_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center"},[_c('h1',{staticClass:"tw-text-3xl tw-mr-2"},[_vm._v(_vm._s(_vm.facility.name))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                  _vm.checkPrivileges(
                    _vm.facility.privileges,
                    _vm.PRIVILEGES.editFacilities
                  )
                )?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","primary":"","to":{
                  name: _vm.ROUTE_NAMES.editFacility,
                  params: { facilityId: _vm.facilityId }
                }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1):_vm._e()]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "admin.customer-dashboard.facility-widget.edit-facility-settings" ))+" ")])])],1)],1)]},proxy:true},{key:"widgets",fn:function(){return [(_vm.checkPrivileges(_vm.facility.privileges, _vm.PRIVILEGES.manageLicenses))?_c('dashboard-widget',{attrs:{"title":"admin.facility-dashboard.licenses"},scopedSlots:_vm._u([{key:"default",fn:function({ fullscreen, setLocalizedWidgetMessage }){return [_c('licenses-widget',{attrs:{"facilityId":_vm.facilityId,"fullscreen":fullscreen,"setLocalizedWidgetMessage":setLocalizedWidgetMessage},on:{"reload-facility":_vm.loadFacility}})]}}],null,false,2186029780)}):_vm._e(),_c('users-widget',{attrs:{"customerId":_vm.customerId,"facilityId":_vm.facilityId,"export-name":_vm.facility.name}}),_c('dashboard-widget',{attrs:{"title":"admin.facility-dashboard.facility-hierarchy"},scopedSlots:_vm._u([{key:"default",fn:function({
          fullscreen,
          changeWidgetCurrentTitle,
          setLocalizedWidgetMessage,
          setWidgetFullscreen
        }){return [_c('location-hierarchy-widget',{attrs:{"fullscreen":fullscreen,"customerId":_vm.customerId,"facilityId":_vm.facilityId,"facility":_vm.facility,"hasObvLicense":_vm.hasObvLicense,"hasAmsLicense":_vm.hasAmsLicense,"setLocalizedWidgetMessage":setLocalizedWidgetMessage,"changeWidgetCurrentTitle":changeWidgetCurrentTitle,"setWidgetFullscreen":setWidgetFullscreen}})]}}])}),(_vm.numberOfDevices > 0 || _vm.showDispensersAndDevices)?_c('dashboard-widget',{attrs:{"title":"admin.facility-dashboard.dispensers-devices"},scopedSlots:_vm._u([{key:"titleCustomContent",fn:function(){return [_c('v-btn',{staticClass:"tw-mt-1 tw-ml-3",attrs:{"plain":"","to":{
              name: _vm.ROUTE_NAMES.deviceMaintenance,
              query: { facilityId: _vm.facilityId }
            }}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "admin.facility-dashboard.dispensers-devices-widget.view-all" ))+" ")]),_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1)]},proxy:true}],null,false,82013885)},[_c('dispensers-and-devices-widget',{attrs:{"customerId":_vm.customerId,"facilityId":_vm.facilityId,"licenses":_vm.facility.licenses,"deviceCounts":_vm.deviceCounts}})],1):_vm._e(),_c('dashboard-widget',{attrs:{"title":"admin.facility-dashboard.shifts"},scopedSlots:_vm._u([{key:"default",fn:function({ setLocalizedWidgetMessage, clearLocalizedWidgetMessage }){return [_c('shifts-widget',{attrs:{"facilityId":_vm.facilityId,"setLocalizedWidgetMessage":setLocalizedWidgetMessage,"clearLocalizedWidgetMessage":clearLocalizedWidgetMessage,"facilityPrivileges":_vm.facility.privileges}})]}}])}),(_vm.showOBVJobRoles)?_c('dashboard-widget',{attrs:{"title":"admin.facility-dashboard.obv-job-roles"},scopedSlots:_vm._u([{key:"default",fn:function({ changeWidgetCurrentTitle, setLocalizedWidgetMessage }){return [_c('obv-job-roles-widget',{attrs:{"customerId":_vm.customerId,"facilityId":_vm.facilityId,"changeWidgetCurrentTitle":changeWidgetCurrentTitle,"setLocalizedWidgetMessage":setLocalizedWidgetMessage}})]}}],null,false,1415123452)}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }