var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ValidationObserver',{ref:"manageUnitThresholds",scopedSlots:_vm._u([{key:"default",fn:function({ pristine }){return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateAndSubmit.apply(null, arguments)}}},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('performance-thresholds',{ref:"performanceThresholds",attrs:{"locationId":_vm.locationId,"levelName":_vm.levelName}})],1)],1)],1),_c('v-row',{staticClass:"tw-mb-3 tw-justify-end tw-float-right",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"tw-mr-5 tw-w-24",attrs:{"disabled":pristine &&
                (_vm.$refs.performanceBands
                  ? !_vm.$refs.performanceBands.indicatorsChanged
                  : true) &&
                (_vm.$refs.performanceThresholds
                  ? !_vm.$refs.performanceThresholds.indicatorsChanged
                  : true),"loading":_vm.saving,"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("ui.actions.save"))+" ")]),_c('v-btn',{staticClass:"tw-mr-5 tw-w-24",attrs:{"color":"background2"},on:{"click":_vm.closeManageUnitView}},[_vm._v(" "+_vm._s(_vm.$t("ui.actions.cancel"))+" ")])],1)],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }