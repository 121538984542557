<template>
  <main class="tw-block tw-flex-grow">
    <v-container>
      <v-card>
        <section class="tw-px-4 tw-pb-4 sm:tw-px-8 tw-inline-block tw-w-full">
          <header
            class="tw-sticky tw-top-0 tw-bg-white tw-mb-5"
            style="z-index: 2"
          >
            <report-navbar />
          </header>
          <facility-selection-bar />
          <v-skeleton-loader
            v-if="loadingFilters && activeFilters.length === 0"
            type="image"
          />
          <div
            v-if="activeFilters.length > 0"
            class="tw-bg-background2 sm:tw-mx-2 tw-mb-5 tw-p-2"
          >
            <report-filters-block />
            <report-filter-details-block />
          </div>
          <slot></slot>
        </section>
      </v-card>
    </v-container>
  </main>
</template>

<script>
import ReportNavbar from "@/views/reports/components/navigation/ReportNavbar.vue";
import FacilitySelectionBar from "@/components/layout/FacilitySelectionBar.vue";
import ReportFiltersBlock from "@/views/reports/components/filters/ReportFiltersBlock.vue";
import ReportFilterDetailsBlock from "@/views/reports/components/filters/ReportFilterDetailsBlock.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    ReportNavbar,
    FacilitySelectionBar,
    ReportFiltersBlock,
    ReportFilterDetailsBlock
  },
  computed: {
    ...mapState("reports", [
      "changingReport",
      "loadingFilters",
      "reportId",
      "reportLoading"
    ]),
    ...mapGetters("reports", ["activeFilters"])
  }
};
</script>
