<template>
  <div>
    <v-data-table
      class="small-headers facility-data-table"
      :items="facilities"
      :headers="headers"
      :search="search"
      :loading="loading"
      item-key="facilityId"
      :no-data-text="$t('admin.customer-dashboard.facility-widget.empty')"
      :loading-text="$t('admin.customer-dashboard.facility-widget.loading')"
      :height="fullscreen ? '55vh' : ''"
      @update:page="setScrollPosition"
    >
      <template v-slot:top>
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
          />
          <v-btn
            v-if="checkPermission(PERMISSIONS.addFacilities)"
            class="tw-max-w-xs"
            primary
            color="primary"
            :to="{
              name: ROUTE_NAMES.createFacility,
              query: { customerId }
            }"
          >
            {{ $t("admin.customer-dashboard.facility-widget.new-facility") }}
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.facilityName`]="{ item }">
        <router-link
          :to="{
            name: ROUTE_NAMES.facilityDashboard,
            query: { customerId, facilityId: item.facilityId }
          }"
        >
          <span>{{ item.facilityName }}</span>
        </router-link>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <td>
          <div class="tw-flex tw-flex-row tw-items-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="checkPermission(PERMISSIONS.editFacilities)"
                  :to="{
                    name: ROUTE_NAMES.editFacility,
                    params: { facilityId: item.facilityId }
                  }"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  $t("admin.customer-dashboard.facility-widget.edit-facility")
                }}
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: ROUTE_NAMES.deviceMaintenance,
                    query: { facilityId: item.facilityId }
                  }"
                >
                  <v-icon>mdi-heart-pulse</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  $t(
                    "admin.customer-dashboard.facility-widget.device-maintenance"
                  )
                }}
              </span>
            </v-tooltip>
          </div>
        </td>
      </template>
      <template v-slot:[`item.archived`]="{ item }">
        <v-switch
          v-model="item.archived"
          :loading="item.isLoading"
          :true-value="false"
          :false-value="true"
          :disabled="!checkPermission(PERMISSIONS.archiveFacilities)"
          @click="
            !checkPermission(PERMISSIONS.archiveFacilities)
              ? null
              : item.archived
              ? toggleArchiveStatus(item.facilityId)
              : setModal(item.facilityId)
          "
          readonly
        />
      </template>
      <template v-slot:[`footer.prepend`]>
        <v-tooltip v-if="checkPermission(PERMISSIONS.administerCustomer)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="exportLocations()"
              :loading="exporting"
            >
              <v-icon>mdi-file-export</v-icon>
            </v-btn>
          </template>
          <span>
            {{
              $t(
                "admin.customer-dashboard.facility-widget.export-facility-hierarchies"
              )
            }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="showModal" max-width="560">
      <v-card class="modal">
        <v-container>
          <v-card-title class="text-h5">
            {{ $t("admin.customer-dashboard.facility-widget.modal-title") }}
          </v-card-title>

          <v-card-text
            ><div
              v-html="
                $t(`admin.customer-dashboard.facility-widget.modal-body`, {
                  facilityName: facilityName
                })
              "
            ></div
          ></v-card-text>

          <div class="tw-m-4">
            <v-form
              @submit.prevent="
                textMatches ? toggleArchiveStatus(facilityId) : null
              "
            >
              <v-text-field
                v-model="text"
                :label="`${$t(
                  'admin.customer-dashboard.facility-widget.please-type-yes'
                )}`"
              />
            </v-form>
          </div>
        </v-container>
        <v-card-actions>
          <v-row>
            <v-col class="tw-flex tw-flex-row tw-justify-end">
              <v-btn @click="resetArchive(facilityId)" class="tw-mr-5" text>
                {{ $t("ui.actions.cancel") }}
              </v-btn>
              <v-btn
                @click="toggleArchiveStatus(facilityId)"
                color="error"
                :disabled="!textMatches"
                :loading="modalButtonLoading"
              >
                {{
                  $t("admin.customer-dashboard.facility-widget.confirm-button")
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import customerService from "@/api/customerService";
import facilityService from "@/api/facilityService";
import { downloadAdminCsvFile } from "@/views/admin/utils/downloadAdminCsvFile";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import { ROUTE_NAMES } from "@/types/routeNames";
import { PRODUCTS } from "@/types/products";
import { TEMPLATES } from "@/types/templates";

export default {
  props: {
    customerId: { type: Number },
    customerName: { type: String },
    fullscreen: { type: Boolean, default: false }
  },
  data() {
    return {
      facilities: [],
      headers: [
        {
          text: i18n.t("admin.customer-dashboard.facility-widget.facility"),
          value: "facilityName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-dashboard.facility-widget.devices"),
          value: "deviceCount",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-dashboard.facility-widget.actions"),
          value: "actions",
          sortable: false,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-list.data-table.active"),
          value: "archived",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      loading: false,
      PERMISSIONS,
      PRODUCTS,
      ROUTE_NAMES,
      search: "",
      showModal: false,
      facilityName: "",
      facilityId: null,
      text: "",
      modalButtonLoading: false,
      exporting: false
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"]),
    textMatches() {
      return (
        this.text.toLowerCase() ===
        `${i18n.t("admin.manage-customer.deactivate-modal.yes-match")}`
      );
    }
  },
  async created() {
    await this.loadFacilities();
  },
  methods: {
    async loadFacilities() {
      this.loading = true;

      try {
        const facilities = await customerService.getListOfFacilities(
          this.customerId
        );

        facilities.forEach((f) => {
          f.isLoading = false;
        });

        this.facilities = facilities;
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.facility-widget.error-messages.get-facilities"
          )
        });
      } finally {
        this.loading = false;
      }
    },
    setScrollPosition() {
      document.querySelector(
        "div.facility-data-table div.v-data-table__wrapper"
      ).scrollTop = 0;
    },
    setModal(facilityId) {
      this.facilityId = facilityId;
      this.facilityName = this.facilities.find(
        (f) => f.facilityId === facilityId
      ).facilityName;
      this.showModal = true;
    },
    resetArchive() {
      this.text = "";
      this.showModal = false;
      this.modalButtonLoading = false;
    },
    async toggleArchiveStatus(facilityId) {
      this.modalButtonLoading = true;
      const facility = this.facilities.find((f) => f.facilityId === facilityId);
      try {
        facility.isLoading = true;

        await facilityService.setArchiveStatus(
          facility.facilityId,
          !facility.archived
        );
        facility.archived = !facility.archived;
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            `admin.customer-dashboard.facility-widget.error-messages.status-change`,
            { name: facility.facilityName }
          )
        });
      } finally {
        this.resetArchive();
        facility.isLoading = false;
      }
    },
    async exportLocations() {
      this.exporting = true;

      try {
        const activeLocationHierarchies =
          await customerService.getActiveLocationHierarchies(this.customerId);
        const activeFacilities = this.facilities.filter(
          (facility) => !facility.archived
        );
        const locationHierarchyTemplateIds = [
          ...new Set(
            activeLocationHierarchies.map(
              (locationHierarchy) => locationHierarchy.templateId
            )
          )
        ];
        const onlyBusinessTemplates =
          locationHierarchyTemplateIds[0] === TEMPLATES.BUSINESS &&
          locationHierarchyTemplateIds.length === 1;
        const headers = this.buildCsvHeaders(
          locationHierarchyTemplateIds,
          activeFacilities
        );

        let rowsData = [];
        activeLocationHierarchies.forEach((facilityLocationHierarchy) => {
          const facility = activeFacilities.find(
            (facility) =>
              facility.facilityId === facilityLocationHierarchy.facilityId
          );

          if (!facility) {
            return;
          }

          const hasObvLicense = facility.licenses.some(
            (license) => license === PRODUCTS.OBS
          );
          const isObvExclusive = facility.licenses.every(
            (license) => license === PRODUCTS.OBS
          );
          const locationHierarchy = facilityLocationHierarchy.locationHierarchy;
          const flatList = this.createFlatLocationList(
            locationHierarchy,
            hasObvLicense,
            isObvExclusive
          );

          const handleBusinessTemplateMixedWithOtherTemplates =
            facilityLocationHierarchy.templateId === TEMPLATES.BUSINESS &&
            !onlyBusinessTemplates;
          rowsData = [
            ...rowsData,
            ...this.buildCsvRowData(
              flatList,
              headers.length,
              hasObvLicense,
              handleBusinessTemplateMixedWithOtherTemplates
            )
          ];
        });

        downloadAdminCsvFile(
          headers,
          rowsData,
          `${this.customerName} ${i18n.t(
            "admin.customer-dashboard.facility-widget.facility-location-export"
          )}`
        );
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.facility-widget.error-messages.export-locations"
          )
        });
      } finally {
        this.exporting = false;
      }
    },
    buildCsvHeaders(activeLocationHierarchyTemplateIds, activeFacilities) {
      const obvLicensedFacilities = activeFacilities.filter((facility) =>
        facility.licenses.some((license) => license === PRODUCTS.OBS)
      );
      const obvExclusiveFacilities = obvLicensedFacilities.filter((facility) =>
        facility.licenses.every((license) => license === PRODUCTS.OBS)
      ).length;
      const addRoomsHeader = activeFacilities.length !== obvExclusiveFacilities;

      const hasUSTemplate = activeLocationHierarchyTemplateIds.includes(
        TEMPLATES.US_HOSPITAL
      );
      const hasUKTemplate = activeLocationHierarchyTemplateIds.includes(
        TEMPLATES.UK_HOSPITAL
      );

      const headers = [
        i18n.t("locations.levelNames.LocationLevelFacility"),
        i18n.t("locations.levelNames.LocationLevelFloor")
      ];

      const unitDisplay = i18n.t("locations.levelNames.LocationLevelUnit");
      const wardDisplay = i18n.t("locations.levelNames.LocationLevelWard");
      if (hasUSTemplate && hasUKTemplate) {
        headers.push(`${unitDisplay}/${wardDisplay}`);
      } else if (hasUSTemplate) {
        headers.push(unitDisplay);
      } else if (hasUKTemplate) {
        headers.push(wardDisplay);
      }

      const roomDisplay = i18n.t("locations.levelNames.LocationLevelRoom");
      const bayRoomDisplay = i18n.t(
        "locations.levelNames.LocationLevelBayRoom"
      );
      if (addRoomsHeader) {
        if (hasUKTemplate) {
          headers.push(bayRoomDisplay);
        } else if (hasUSTemplate) {
          headers.push(roomDisplay);
        }
      }

      const oneObvLicensedFacilityExists = obvLicensedFacilities.length > 0;
      if (oneObvLicensedFacilityExists) {
        headers.push(
          i18n.t("admin.customer-dashboard.facility-widget.is-observable")
        );
      }

      return headers;
    },
    createFlatLocationList: (
      locationHierarchy,
      hasObvLicense,
      isObvExclusive
    ) => {
      const locationsList = [];
      const facilityName = locationHierarchy.name;

      function addChildrenToList(location) {
        location.childLocations.forEach((l) => {
          let addToCsv = false;
          const hasNoChildLocations = l.childLocations.length === 0;

          if (isObvExclusive) {
            addToCsv = l.level === 3 || (hasNoChildLocations && l.level < 3);
          } else if (hasObvLicense) {
            addToCsv = l.level === 3 || hasNoChildLocations;
          } else {
            addToCsv = hasNoChildLocations;
          }

          locationsList.push({
            facilityName,
            id: l.id,
            name: l.name,
            parentId: l.parentId,
            level: l.level,
            isObservable: l.isObservable,
            addToCsv
          });

          addChildrenToList(l);
        });
      }

      addChildrenToList(locationHierarchy);

      return locationsList;
    },
    buildCsvRowData: (
      allLocations,
      numberOfColumns,
      hasObvLicense,
      handleBusinessTemplateMixedWithOtherTemplates
    ) => {
      const locationsToAddToCsv = allLocations.filter(
        (location) => location.addToCsv
      );

      const allLocationData = locationsToAddToCsv.map((location) => {
        const singleLocationData = [location.facilityName, location.name];

        function searchForParent(parentId) {
          const parent = allLocations.find(
            (location) => location.id === parentId
          );

          if (parent) {
            singleLocationData.splice(1, 0, parent.name);
            searchForParent(parent.parentId);
          }
        }

        searchForParent(location.parentId);

        if (handleBusinessTemplateMixedWithOtherTemplates) {
          singleLocationData.splice(2, 0, "");
        }

        let currentColumnsOfData = singleLocationData.length;
        for (
          currentColumnsOfData;
          currentColumnsOfData < numberOfColumns;
          currentColumnsOfData++
        ) {
          singleLocationData.push("");
        }

        if (hasObvLicense) {
          const isLocationActuallyObservable = location.level === 3;
          singleLocationData.splice(
            -1,
            1,
            isLocationActuallyObservable ? location.isObservable : ""
          );
        }

        return singleLocationData;
      });

      return allLocationData;
    }
  }
};
</script>

<style scoped lang="postcss">
.modal {
  overflow-y: hidden;
}
</style>
