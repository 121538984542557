<template>
  <div class="tw-mb-5">
    <h1>{{ $t("alert-subscriptions.manage-alert.email-addresses") }}</h1>
    <v-row v-for="index in emailListCount" :key="index">
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$tc('alert-subscriptions.delivery.recipient', index)"
          :rules="{ required: true, email: true }"
          v-slot="{ label, errors }"
        >
          <v-text-field
            :disabled="!enableRecipients"
            :label="label"
            v-model="emailList[index - 1]"
            :error-messages="errors"
          >
            <template v-slot:append-outer>
              <v-btn
                :disabled="!canAddEmail"
                icon
                @click="addSubscriberEmail()"
              >
                <v-icon :color="canAddEmail ? 'success' : ''">
                  mdi-plus-circle
                </v-icon>
              </v-btn>
              <v-btn
                :disabled="emailListCount <= 1"
                icon
                @click="removeSubscriberEmail(index - 1)"
              >
                <v-icon :color="emailListCount > 1 ? 'error' : ''">
                  mdi-minus-circle</v-icon
                >
              </v-btn>
            </template>
          </v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    emailSubscriberList: {
      type: Array
    },
    enableRecipients: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    canAddEmail: false,
    emailListCount: 1,
    emailList: []
  }),
  watch: {
    emailSubscriberList: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        this.emailListCount = value.length ? value.length : 1;
        this.emailList = value;
        this.canAddEmail = this.enableRecipients && value.length > 0;
      }
    },
    emailList(value) {
      if (value[value.length - 1]) {
        this.canAddEmail = this.enableRecipients;
      }

      this.$emit("update:emailSubscriberList", value);
    },
    emailListCount(value) {
      const lastItem = this.emailList[value - 1];

      if (!lastItem) {
        this.emailList[value - 1] = "";
      }
    }
  },
  methods: {
    addSubscriberEmail() {
      this.emailListCount = this.emailListCount + 1;
      this.canAddEmail = false;
    },
    removeSubscriberEmail(index) {
      this.emailList.splice(index, 1);
      this.emailListCount = this.emailListCount - 1;
    }
  }
};
</script>
