// Colors come from the Purell Brand standards: https://www.purellbrandstandards.com/color
// Web color guidelines for the SmartLink project can be found in the wiki: https://smartlink.visualstudio.com/SmartLink%20Portal/_wiki/wikis/SmartLink%20Portal.wiki/79/Color-Guidelines

module.exports = {
  primary: "#007BB6",
  secondary: "#D65595",
  tertiary: "#63B5CF",
  white: "#FFFFFF",
  black: "#000000",
  purellGray: "#595959",
  lightPurellGray: "#8A8D8F",
  background: "#DEDEDE",
  background2: "#F7FAFC",
  important: "#001489",
  error: "#FF5252",
  success: "#4CAF50",
  warning: "#C61C71",
  baseline: "#F4C882",
  goal: "#9BC84A"
};
