<template>
  <v-dialog
    :transition="
      $vuetify.breakpoint.mdAndUp ? undefined : 'dialog-bottom-transition'
    "
    :value="visible"
    persistent
    no-click-animation
    inset
    @click:outside="canCloseDialog"
    max-width="85%"
  >
    <v-card class="tw-p-6 tw-pt-2">
      <v-skeleton-loader
        v-if="loading"
        :loading="loading"
        type="image, actions"
        class="tw-p-6"
      />
      <ValidationObserver
        ref="deviceAndPlaceholdersForm"
        v-slot="{ pristine }"
        v-else
      >
        <v-form @submit.prevent="validateAndSave">
          <v-row>
            <v-col :lg="itemToEdit && !itemToEdit.isPlaceholder ? '6' : null">
              <div>
                <h2>
                  {{ $t(header) }}
                </h2>
                <h4>
                  {{ $t("admin.placeholders-and-devices.placeholder-options") }}
                </h4>
                <v-row class="tw-mt-1" v-if="!editing">
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="
                        $t('admin.placeholders-and-devices.placeholder-type')
                      "
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <v-select
                        v-model="placeholderType"
                        :label="
                          $t('admin.placeholders-and-devices.placeholder-type')
                        "
                        :items="placeholderTypes"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="$t('admin.device-maintenance.filters.locations')"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                      :skipIfEmpty="false"
                    >
                      <selector
                        selectionComponent="hierarchy-selection"
                        label="admin.device-maintenance.filters.locations"
                        :items="locations"
                        v-model="selectedLocations"
                        itemChildIdKey="childLocations"
                        activator="v-select"
                        :offsetX="false"
                        :menuProps="{ 'nudge-top': 180 }"
                        :singleSelect="serialNumber !== ''"
                        :minWidth="700"
                        :textFieldProps="{
                          'error-messages': errors
                        }"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </div>
              <div v-if="placeholderType == 'Device' || editing">
                <v-row>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="$t('admin.placeholders-and-devices.device-name')"
                      :rules="{ required: true, max: 50 }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="deviceName"
                        :error-messages="errors"
                        :label="
                          $t('admin.placeholders-and-devices.device-name')
                        "
                        counter="50"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="$t('admin.placeholders-and-devices.serial-number')"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        :value="serialNumber"
                        @input="(value) => (serialNumber = value.trim())"
                        :disabled="editing && !itemToEdit.isPlaceholder"
                        :error-messages="[...errors, ...serialNumberErrors]"
                        :label="
                          $t('admin.placeholders-and-devices.serial-number')
                        "
                        @blur="checkLocations"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="$t('admin.placeholders-and-devices.device-type')"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        auto-select-first
                        v-model="deviceType"
                        :error-messages="errors"
                        :label="
                          $t('admin.placeholders-and-devices.device-type')
                        "
                        :items="selectableDeviceTypes"
                        :disabled="editing"
                        item-value="id"
                        item-text="name"
                        @input="deviceModel = ''"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="deviceType === 1">
                    <ValidationProvider
                      :name="
                        $t('admin.placeholders-and-devices.formulation-class')
                      "
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        auto-select-first
                        v-model="formulationClass"
                        :error-messages="errors"
                        :label="
                          $t('admin.placeholders-and-devices.formulation-class')
                        "
                        :items="formulationClasses"
                        item-value="id"
                        item-text="name"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="
                        $t('admin.room-template-configuration.device-model')
                      "
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        auto-select-first
                        v-model="deviceModel"
                        :error-messages="errors"
                        :label="
                          $t('admin.room-template-configuration.device-model')
                        "
                        :items="selectableDeviceModels"
                        :disabled="
                          !deviceType || (deviceType === 1 && !formulationClass)
                        "
                        item-value="id"
                        item-text="name"
                        persistent-hint
                        :hint="
                          !deviceType
                            ? $t(
                                'admin.placeholders-and-devices.select-device-type'
                              )
                            : deviceType === 1 && !formulationClass
                            ? $t(
                                'admin.placeholders-and-devices.select-formulation-class'
                              )
                            : null
                        "
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <ValidationProvider>
                      <v-autocomplete
                        auto-select-first
                        v-model="devicePosition"
                        :error-messages="errors"
                        :label="
                          $t('admin.placeholders-and-devices.device-position')
                        "
                        :items="devicePositions"
                        item-value="id"
                        item-text="name"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider>
                      <v-autocomplete
                        auto-select-first
                        v-model="deviceOrientation"
                        :error-messages="errors"
                        :label="
                          $t(
                            'admin.placeholders-and-devices.device-orientation'
                          )
                        "
                        :items="deviceOrientations"
                        item-value="id"
                        item-text="name"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="
                        $t('admin.placeholders-and-devices.firmware-version')
                      "
                      :rules="{ max: 50 }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="firmwareVersion"
                        :error-messages="errors"
                        :label="
                          $t('admin.placeholders-and-devices.firmware-version')
                        "
                        counter="50"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="
                      deviceType !== gatewayDeviceId &&
                      deviceType !== repeaterDeviceId
                    "
                    cols="12"
                    sm="6"
                  >
                    <ValidationProvider>
                      <v-switch
                        v-model="complianceCapable"
                        :label="
                          $t(
                            'admin.placeholders-and-devices.compliance-capable'
                          )
                        "
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col v-if="deviceType === gatewayDeviceId" cols="12" sm="6">
                    <ValidationProvider>
                      <v-text-field
                        v-model="repeaterPath"
                        :error-messages="errors"
                        :label="
                          $t('admin.placeholders-and-devices.repeater-path')
                        "
                        counter="250"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div v-if="deviceType == '2' && serialNumber !== ''">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <ValidationProvider>
                        <v-switch
                          v-model="jumperConfiguration"
                          :false-value="2"
                          :true-value="1"
                          :label="
                            $t(
                              'admin.placeholders-and-devices.jumper-configuration'
                            )
                          "
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <ValidationProvider>
                        <v-autocomplete
                          auto-select-first
                          v-model="sensitivity"
                          :error-messages="errors"
                          :label="
                            $t('admin.placeholders-and-devices.sensitivity')
                          "
                          :items="sensitivities"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-if="
                    deviceType === gatewayDeviceId &&
                    checkPermission(PERMISSIONS.amsReports) &&
                    serialNumber !== ''
                  "
                >
                  <v-row>
                    <v-col cols="12" sm="6">
                      <ValidationProvider
                        :name="$t('admin.placeholders-and-devices.ip-address')"
                        :rules="{ required: false, max: 50 }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="ipAddress"
                          :error-messages="errors"
                          :label="
                            $t('admin.placeholders-and-devices.ip-address')
                          "
                          counter="50"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <ValidationProvider
                        :name="
                          $t('admin.placeholders-and-devices.default-gateway')
                        "
                        :rules="{ required: false, max: 50 }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="defaultGateway"
                          :error-messages="errors"
                          :label="
                            $t('admin.placeholders-and-devices.default-gateway')
                          "
                          counter="50"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <ValidationProvider
                        :name="$t('admin.placeholders-and-devices.server-port')"
                        :rules="{
                          numeric: true,
                          max_value: 65535
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="serverPort"
                          :error-messages="errors"
                          :label="
                            $t('admin.placeholders-and-devices.server-port')
                          "
                          type="number"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        auto-select-first
                        v-model="timeslot"
                        :error-messages="errors"
                        :label="$t('admin.placeholders-and-devices.timeslot')"
                        :items="timeslots"
                        item-value="id"
                        item-text="name"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <ValidationProvider
                        :name="
                          $t('admin.placeholders-and-devices.network-setup')
                        "
                        :rules="{ required: false, max: 50 }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="networkSetup"
                          :error-messages="errors"
                          :label="
                            $t('admin.placeholders-and-devices.network-setup')
                          "
                          counter="50"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <ValidationProvider
                        :name="$t('admin.placeholders-and-devices.subnet-mask')"
                        :rules="{ required: false, max: 50 }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="subnetMask"
                          :error-messages="errors"
                          :label="
                            $t('admin.placeholders-and-devices.subnet-mask')
                          "
                          counter="50"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <v-row class="tw-justify-end">
                <v-btn
                  class="tw-mr-5 tw-w-24"
                  color="primary"
                  type="submit"
                  :loading="saving"
                  :disabled="itemToEdit && pristine"
                >
                  {{ $t("ui.actions.save") }}
                </v-btn>
                <v-btn
                  v-if="!editing"
                  :disabled="saving"
                  class="tw-mr-5 tw-w-24"
                  color="background2"
                  @click="closeForm"
                >
                  {{ $t("ui.actions.cancel") }}
                </v-btn>
              </v-row>
            </v-col>
            <v-divider
              v-if="
                itemToEdit &&
                !itemToEdit.isPlaceholder &&
                $vuetify.breakpoint.lgAndUp
              "
              vertical
              class="tw-mt-4"
            />
            <v-col v-if="itemToEdit && !itemToEdit.isPlaceholder" lg="6">
              <div v-if="deviceType === 1">
                <h4>
                  {{ $t("admin.placeholders-and-devices.refill-status.title") }}
                </h4>
                <div v-if="currentRefill">
                  <v-row>
                    <v-col>
                      <label class="v-label theme--light tw-text-xs">
                        {{
                          $t(
                            "admin.placeholders-and-devices.refill-status.e-key"
                          )
                        }}
                      </label>
                      <br />
                      <span>
                        {{ currentRefill.eKey }}
                      </span>
                    </v-col>
                    <v-col>
                      <label class="v-label theme--light tw-text-xs">
                        {{
                          $t(
                            "admin.placeholders-and-devices.refill-status.install-date"
                          )
                        }}
                      </label>
                      <br />
                      <span>
                        {{ formatTimestamp(currentRefill.installDate) }}
                      </span>
                    </v-col>
                    <v-col>
                      <label class="v-label theme--light tw-text-xs">
                        {{
                          $t(
                            "admin.placeholders-and-devices.refill-status.dispenses"
                          )
                        }}
                      </label>
                      <br />
                      <span>
                        {{ currentRefill.totalDispenses }}
                      </span>
                    </v-col>
                    <v-col>
                      <label class="v-label theme--light tw-text-xs">
                        {{
                          $t(
                            "admin.placeholders-and-devices.refill-status.est-replacement"
                          )
                        }}
                      </label>
                      <br />
                      <span>
                        {{
                          formatTimestamp(
                            currentRefill.estimatedReplacementDate
                          )
                        }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label class="v-label theme--light tw-text-xs">
                        {{
                          $t(
                            "admin.placeholders-and-devices.refill-status.expiration-date"
                          )
                        }}
                      </label>
                      <br />
                      <span>
                        {{ formatTimestamp(currentRefill.expirationDate) }}
                      </span>
                    </v-col>
                    <v-col>
                      <label class="v-label theme--light tw-text-xs">
                        {{
                          $t(
                            "admin.placeholders-and-devices.refill-status.refill-batch"
                          )
                        }}
                      </label>
                      <br />
                      <span>
                        {{ currentRefill.refillBatch }}
                      </span>
                    </v-col>
                    <v-col>
                      <label class="v-label theme--light tw-text-xs">
                        {{
                          $t(
                            "admin.placeholders-and-devices.refill-status.formulation-class"
                          )
                        }}
                      </label>
                      <br />
                      <span>
                        {{ currentRefill.formulationClass }}
                      </span>
                    </v-col>
                    <v-col>
                      <label class="v-label theme--light tw-text-xs">
                        {{
                          $t(
                            "admin.placeholders-and-devices.refill-status.formulation"
                          )
                        }}
                      </label>
                      <br />
                      <span>
                        {{ currentRefill.formulation }}
                      </span>
                    </v-col>
                  </v-row>
                </div>
                <span v-else>
                  {{ $t("admin.placeholders-and-devices.refill-status.none") }}
                </span>
                <v-divider horizontal class="tw-my-2" />
              </div>
              <div>
                <h4>
                  {{ $t("admin.placeholders-and-devices.device-notes.title") }}
                </h4>
                <device-notes
                  :deviceId="itemToEdit.id"
                  :notes.sync="notes"
                  @onSave="refreshDeviceNotes(itemToEdit.id)"
                />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
      <v-btn
        top
        absolute
        right
        icon
        @click="closeForm"
        :disabled="saving"
        :style="{ top: '5px', right: '5px' }"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import Selector from "@/views/admin/components/Selector.vue";
import i18n from "@/plugins/i18n";
import deviceService from "@/api/deviceService";
import devicePlaceholderService from "@/api/devicePlaceholderService";
import DeviceNotes from "./DeviceNotes.vue";
import { PERMISSIONS } from "@/types/permissions";
import { mapGetters } from "vuex";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  components: {
    DeviceNotes,
    Selector
  },
  props: {
    facilityId: { type: Number },
    itemToEdit: {
      type: Object
    },
    locations: {
      type: Array,
      default: () => {
        return [];
      }
    },
    roomTemplate: {
      type: Object,
      default: () => {
        return {};
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      complianceCapable: true,
      currentRefill: null,
      currentDeviceModelId: "",
      defaultGateway: "",
      deviceModel: "",
      deviceModels: [],
      deviceName: "",
      deviceOrientation: undefined,
      deviceOrientations: [],
      devicePosition: undefined,
      devicePositions: [],
      deviceToEdit: {},
      deviceType: undefined,
      deviceTypes: [],
      displayNewNote: false,
      editing: false,
      errors: "",
      firmwareVersion: "",
      formulationClass: undefined,
      formulationClasses: [],
      gatewayDeviceId: 3,
      repeaterDeviceId: 5,
      ipAddress: "",
      jumperConfiguration: undefined,
      loading: false,
      subnetMask: "",
      networkSetup: "",
      notes: [],
      PERMISSIONS,
      placeholderTypes: [
        {
          value: "RoomTemplate",
          text: this.$t(
            "admin.placeholders-and-devices.room-template-placeholders"
          )
        },
        {
          value: "Device",
          text: this.$t("admin.placeholders-and-devices.device-placeholder")
        }
      ],
      placeholderType: "Device",
      repeaterPath: "",
      serverPort: undefined,
      selectedLocations: [],
      saving: false,
      sensitivity: undefined,
      sensitivities: [0, 1, 2, 3, 4, 5, 6, 7],
      serialNumber: "",
      serialNumberErrors: [],
      timeslot: undefined,
      timeslots: Array.from({ length: 20 }, (_, i) => i + 1)
    };
  },
  computed: {
    header() {
      if (!this.editing && this.serialNumber !== "") {
        return "admin.placeholders-and-devices.add-device";
      } else if (!this.editing) {
        return "admin.placeholders-and-devices.title";
      } else if (this.itemToEdit && this.itemToEdit.isPlaceholder) {
        return "admin.device-maintenance.edit-placeholder";
      } else {
        return "admin.device-maintenance.edit-device";
      }
    },
    selectableDeviceModels() {
      if (this.deviceType === 1 && this.formulationClass) {
        return this.deviceModels.filter(
          (d) =>
            d.deviceTypeId === this.deviceType &&
            d.formulationClassId === this.formulationClass &&
            (!d.isArchived || d.id === this.currentDeviceModelId)
        );
      }
      return this.deviceModels.filter(
        (d) =>
          d.deviceTypeId === this.deviceType &&
          (!d.isArchived || d.id === this.currentDeviceModelId)
      );
    },
    selectableDeviceTypes() {
      if (this.editing) {
        return this.deviceTypes;
      }

      return this.deviceTypes.filter((dt) => dt.name !== "Repeater");
    },
    ...mapGetters("user/permissions", ["checkPermission"])
  },
  async mounted() {
    deviceService.getDeviceFormulationClasses().then((formulationClasses) => {
      this.formulationClasses = formulationClasses;
    });

    deviceService.getDeviceOrientations().then((deviceOrientations) => {
      this.deviceOrientations = deviceOrientations;
    });

    deviceService.getDevicePositions().then((devicePositions) => {
      this.devicePositions = devicePositions;
    });

    deviceService.getDeviceTypes().then((deviceTypes) => {
      this.deviceTypes = deviceTypes;
    });

    deviceService.getDeviceModels().then((deviceModels) => {
      Object.keys(deviceModels).forEach((deviceType) => {
        if (deviceModels[deviceType].length) {
          this.deviceModels = [
            ...this.deviceModels,
            ...deviceModels[deviceType]
          ];
        }
      });
    });
  },
  methods: {
    canCloseDialog(e) {
      const path = e.composedPath();
      if (
        this.visible &&
        !this.saving &&
        !document.querySelector(".menuable__content__active") &&
        !path.includes(document.querySelector(".v-dialog__content"))
      ) {
        this.closeForm();
      }
    },
    closeForm() {
      this.$emit("update:visible", false);
      this.$emit("update:itemToEdit", null);
      this.editing = false;
      this.resetForm();
    },
    checkLocations() {
      if (this.serialNumber !== "" && this.selectedLocations.length > 1) {
        this.selectedLocations = [this.selectedLocations[0]];
      }
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        return new Date(`${timestamp}`).toLocaleDateString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
      }
      return "";
    },
    async getDevice(id) {
      this.loading = true;
      const device = await deviceService.getDevice(id);

      this.complianceCapable = device.isCompliance;
      this.deviceModel = device.deviceModelId === 0 ? "" : device.deviceModelId;
      this.currentDeviceModelId = this.deviceModel;
      this.deviceName = device.name;
      this.deviceOrientation = device.deviceOrientationId;
      this.devicePosition = device.devicePositionId;
      this.deviceType = device.deviceTypeId;
      this.formulationClass = device.formulationClassId;
      this.serialNumber = device.serialNumber;
      this.firmwareVersion = device.firmwareVersion;
      this.jumperConfiguration = device.jumperConfiguration;
      this.sensitivity = device.sensitivity;
      this.defaultGateway = device.defaultGateway;
      this.ipAddress = device.ipAddress;
      this.repeaterPath = device.repeaterPath;
      this.serverPort = device.serverPort;
      this.timeslot = device.timeslot;
      this.subnetMask = device.subnetMask;
      this.networkSetup = device.networkSetup;
      this.notes = device.notes;
      this.currentRefill = device.currentRefill;

      this.loading = false;
    },
    async getPlaceholder(id) {
      this.loading = true;
      const placeholder = await devicePlaceholderService.getById(id);

      this.complianceCapable = placeholder.isCompliance;
      this.deviceModel =
        placeholder.deviceModelId === 0 ? "" : placeholder.deviceModelId;
      this.deviceName = placeholder.name;
      this.deviceOrientation = placeholder.deviceOrientationId;
      this.devicePosition = placeholder.devicePositionId;
      this.deviceType = placeholder.deviceTypeId;
      this.formulationClass = placeholder.formulationClassId;
      this.firmwareVersion = placeholder.firmwareVersion;

      this.loading = false;
    },
    async refreshDeviceNotes(id) {
      const device = await deviceService.getDevice(id);
      this.notes = device.notes;
    },
    resetForm() {
      this.complianceCapable = true;
      this.defaultGateway = "";
      this.deviceModel = "";
      this.deviceName = "";
      this.deviceOrientation = "";
      this.devicePosition = "";
      this.deviceType = undefined;
      this.formulationClass = "";
      this.firmwareVersion = "";
      this.ipAddress = "";
      this.jumperConfiguration = undefined;
      this.networkSetup = "";
      this.repeaterPath = "";
      this.sensitivity = undefined;
      this.serialNumber = "";
      this.serverPort = undefined;
      this.subnetMask = "";
      this.selectedLocations = [];
      this.timeslot = undefined;
      this.$refs.deviceAndPlaceholdersForm.reset();
    },
    async validateAndSave() {
      const valid = await this.$refs.deviceAndPlaceholdersForm.validate();
      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Device and Placeholders Form Error`
        });
        return;
      }
      this.saving = true;
      this.serialNumberErrors = [];

      const isPlaceholder =
        this.serialNumber === null || this.serialNumber.match(/^ *$/) !== null;

      try {
        if (this.placeholderType === "RoomTemplate") {
          await this.createRoomTemplatePlaceholders();
        } else {
          if (isPlaceholder) {
            await this.handlePlaceholder();
            this.$appInsights?.trackEvent({
              name: `Placeholder Form Success`
            });
          } else {
            await this.handleDevice();
            this.$appInsights?.trackEvent({
              name: `Device Form Success`
            });
          }
        }
        this.$emit("onSave", {
          editing: this.editing,
          locations: this.selectedLocations,
          showPlaceholdersOnly: this.serialNumber === ""
        });
        this.closeForm();
      } catch (errorMessage) {
        if (isPlaceholder) {
          this.$appInsights?.trackEvent({
            name: `Placeholder Server Error`
          });
        } else {
          this.$appInsights?.trackEvent({
            name: `Device Server Error`
          });
        }
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(errorMessage)
        });
      } finally {
        this.saving = false;
      }
    },
    async convertAndEditPlaceholder(deviceUpdates) {
      try {
        await devicePlaceholderService.convertAndEditDevicePlaceholder(
          this.facilityId,
          this.itemToEdit.id,
          this.serialNumber,
          deviceUpdates
        );
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t("admin.device-maintenance.success-messages.convert")
        );
      } catch (error) {
        if (error === 409) {
          this.serialNumberErrors = [
            i18n.t(
              "admin.device-maintenance.error-messages.duplicate-serial-number"
            )
          ];
        }
        throw "admin.device-maintenance.error-messages.convert";
      }
    },
    async createDevice(device) {
      try {
        await deviceService.createDevice({
          ...device,
          facilityId: this.facilityId,
          locationId: this.selectedLocations[0],
          serialNumber: this.serialNumber,
          deviceTypeId: this.deviceType
        });
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t(
            "admin.placeholders-and-devices.success-messages.create-device"
          )
        );
      } catch (error) {
        if (error === 409) {
          this.serialNumberErrors = [
            i18n.t(
              "admin.device-maintenance.error-messages.duplicate-serial-number"
            )
          ];
        } else if (error === 412) {
          this.serialNumberErrors = [
            i18n.t(
              "admin.device-maintenance.error-messages.serial-number-does-not-exist"
            )
          ];
        }
        throw "admin.placeholders-and-devices.error-messages.create-device";
      }
    },
    async createPlaceholder(placeholder) {
      try {
        await devicePlaceholderService.createDevicePlaceholder(
          this.selectedLocations,
          {
            ...placeholder,
            deviceTypeId: this.deviceType,
            facilityId: this.facilityId
          }
        );
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t(
            "admin.placeholders-and-devices.success-messages.create-device-placeholder"
          )
        );
      } catch {
        throw "admin.placeholders-and-devices.error-messages.save";
      }
    },
    async createRoomTemplatePlaceholders() {
      try {
        await devicePlaceholderService.createRoomTemplatePlaceholders(
          this.facilityId,
          this.selectedLocations
        );
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t(
            "admin.placeholders-and-devices.success-messages.create-room-template-placeholders"
          )
        );
      } catch (error) {
        if (error === 404) {
          throw "admin.placeholders-and-devices.error-messages.missing-room-template";
        }

        throw "admin.placeholders-and-devices.error-messages.save";
      }
    },
    async editDevice(device) {
      try {
        await deviceService.editDevice(this.itemToEdit.id, device);
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t(
            "admin.placeholders-and-devices.success-messages.update-device"
          )
        );
      } catch (error) {
        if (error === 409) {
          throw "admin.placeholders-and-devices.error-messages.device-deactivated";
        } else {
          throw "admin.placeholders-and-devices.error-messages.update-device";
        }
      }
    },
    async editPlaceholder(placeholder) {
      try {
        await devicePlaceholderService.editDevicePlaceholder(
          this.itemToEdit.id,
          placeholder
        );
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t(
            "admin.placeholders-and-devices.success-messages.edit-device-placeholder"
          )
        );
      } catch {
        throw "admin.placeholders-and-devices.error-messages.edit-placeholder";
      }
    },
    async handleDevice() {
      const device = {
        name: this.deviceName,
        deviceModelId: this.deviceModel,
        formulationClassId: this.formulationClass,
        devicePositionId: this.devicePosition,
        deviceOrientationId: this.deviceOrientation,
        firmwareVersion: this.firmwareVersion,
        isCompliance: this.complianceCapable,
        jumperConfiguration: this.jumperConfiguration,
        sensitivity: this.sensitivity,
        defaultGateway: this.defaultGateway,
        repeaterPath: this.repeaterPath,
        ipAddress: this.ipAddress,
        serverPort: this.serverPort,
        timeslot: this.timeslot,
        subnetMask: this.subnetMask,
        networkSetup: this.networkSetup
      };

      if (this.editing) {
        if (this.itemToEdit.isPlaceholder) {
          await this.convertAndEditPlaceholder(device);
        } else {
          await this.editDevice(device);
        }
      } else {
        await this.createDevice(device);
      }
    },
    async handlePlaceholder() {
      const placeholder = {
        name: this.deviceName,
        deviceModelId: this.deviceModel,
        formulationClassId: this.formulationClass,
        devicePositionId: this.devicePosition,
        deviceOrientationId: this.deviceOrientation,
        firmwareVersion: this.firmwareVersion,
        isCompliance: this.complianceCapable
      };

      if (this.editing) {
        await this.editPlaceholder(placeholder);
      } else {
        await this.createPlaceholder(placeholder);
      }
    }
  },
  watch: {
    itemToEdit(value) {
      if (value && Object.keys(value).length) {
        this.editing = true;
        this.placeholderType = "Device";
        if (value.isPlaceholder) {
          this.getPlaceholder(value.id);
        } else {
          this.getDevice(value.id);
        }
      }
    }
  }
};
</script>
