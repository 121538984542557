<template>
  <div>
    <div v-if="displayNewEmployeeView">
      <manage-employee
        :customerId="customerId"
        :employeeId="employeeId"
        @close-create-employee-view="closeCreateEmployeeView"
      />
    </div>
    <div v-show="!displayNewEmployeeView">
      <v-data-table
        class="employees-data-table small-headers"
        item-key="employeeId"
        :items="employees"
        :headers="headers"
        :search="search"
        :loading="loading"
        :height="fullscreen ? '55vh' : ''"
        :loading-text="
          $t('admin.customer-dashboard.badge-management-widget.loading')
        "
        :no-data-text="
          $t('admin.customer-dashboard.badge-management-widget.empty')
        "
        @update:page="setScrollPosition"
      >
        <template v-slot:top>
          <div
            class="
              tw-flex
              tw-justify-between
              tw-items-center
              tw-sticky
              tw-top-0
              tw-bg-white
            "
            style="z-index: 2"
          >
            <v-text-field
              v-model="search"
              :label="`${$t('ui.input-select.search')}`"
              class="tw-mr-4"
              clearable
              prepend-inner-icon="mdi-magnify"
            />
            <v-btn
              v-if="checkPermission(PERMISSIONS.manageEmployees)"
              class="tw-max-w-xs"
              primary
              color="primary"
              @click="openCreateEmployeeView"
            >
              {{
                $t(
                  "admin.customer-dashboard.badge-management-widget.new-employee"
                )
              }}
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  primary
                  v-bind="attrs"
                  v-on="on"
                  @click="editEmployee(item.employeeId)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("admin.employee.title.edit") }} </span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import employeeService from "@/api/employeeService";
import { orderBy } from "lodash";
import ManageEmployee from "./manageWidgetViews/ManageEmployee.vue";

export default {
  components: {
    ManageEmployee
  },
  props: {
    customerId: { type: Number },
    fullscreen: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: true,
      PERMISSIONS,
      search: "",
      employees: [],
      employeeId: null,
      displayNewEmployeeView: false
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"]),
    headers() {
      const headers = [
        {
          text: i18n.t(
            "admin.customer-dashboard.badge-management-widget.employee-id"
          ),
          value: "employeeIdentifier",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-dashboard.badge-management-widget.name"),
          value: "employeeName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t(
            "admin.customer-dashboard.badge-management-widget.badge-number"
          ),
          value: "badgeNumber",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t(
            "admin.customer-dashboard.badge-management-widget.job-role"
          ),
          value: "jobRole",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t(
            "admin.customer-dashboard.badge-management-widget.department"
          ),
          value: "department",
          sortable: true,
          class: "tw-align-text-top"
        }
      ];

      if (this.checkPermission(PERMISSIONS.manageEmployees)) {
        headers.push({
          text: i18n.t("admin.customer-dashboard.badge-management-widget.edit"),
          value: "edit",
          sortable: false,
          class: "tw-align-text-top"
        });
      }

      return headers;
    }
  },
  async mounted() {
    this.loadEmployees();
  },
  methods: {
    async loadEmployees() {
      try {
        this.employees = orderBy(
          await employeeService.getEmployeesByCustomerId(this.customerId),
          ["employeeName"]
        );
        this.loading = false;
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.badge-management-widget.error-messages.get-employees"
          )
        });
      }
    },
    setScrollPosition() {
      document.querySelector(
        "div.employees-data-table div.v-data-table__wrapper"
      ).scrollTop = 0;
    },
    openCreateEmployeeView() {
      this.displayNewEmployeeView = true;
    },
    closeCreateEmployeeView(reloadEmployees) {
      this.displayNewEmployeeView = false;
      this.employeeId = null;
      if (reloadEmployees) {
        this.loadEmployees();
      }
    },
    editEmployee(employeeId) {
      this.employeeId = parseInt(employeeId);

      this.displayNewEmployeeView = true;
    }
  }
};
</script>
