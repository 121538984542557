import { get, post } from "./apiClient";

export default {
  async getObservers(facilityId) {
    return await get(`observers?facilityId=${facilityId}`);
  },
  async getObserverData(observerId, facilityId) {
    const date = new Date();
    const startOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    ).toLocaleDateString();

    return await get(
      `observation/observerData?observerId=${observerId}&facilityId=${facilityId}&observationStartDate=${startOfMonth}`
    );
  },
  async keepAlive() {
    return await get("observation");
  },
  async submitSession(session) {
    return await post(`observation/session`, session);
  }
};
