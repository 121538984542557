<template>
  <div>
    <report-graph :chartData="chartData" :repositionSpeedDial="true" />
    <report-table
      :initialSortedColumns="['quickNote']"
      :headers="headers"
      :items="tableItems"
    />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import { stringSort } from "@/views/reports/utils/sort";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportGraph,
    ReportTable
  },
  data() {
    return {
      compatibleReportId: 7,
      reportFilters: [
        "hygieneProtocolOpportunities",
        "obvJobRoles",
        "locations",
        "shifts",
        "timeframe"
      ],
      hiddenReportActions: ["saveSlide"],
      showLegendOnPrint: true,
      ratesSwitchLabel: i18n.t("reports.graph.display-count"),
      chartData: {},
      chartItems: [],
      tableItems: [],
      headers: [
        {
          text: i18n.t("reports.table.quick-note"),
          value: "quickNote",
          sortable: true
        },
        {
          text: i18n.t("reports.table.observation-pct"),
          value: "observationPct",
          sortable: true,
          display: "Percentage"
        }
      ]
    };
  },
  methods: {
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.tableItems, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      const data = this.reportResponse.dataPoints;

      this.tableItems = data;
      this.chartItems = stringSort({
        items: data,
        sortFields: ["quickNote"]
      });

      const builder = new ChartBuilder({
        displayLegend: false,
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });
      builder.chartCategorized({
        items: this.chartItems,
        valueField: "totalObservations",
        valueFieldType: GraphDataTypes.NUMBER,
        categoryField: "quickNote",
        valueScaleOptions: {
          label: i18n.t("reports.graph.observations-axis"),
          ticks: {
            format: "${value}",
            suggestedMin: 0,
            suggestedMax: 60
          }
        }
      });

      this.chartData = builder.build();
    }
  }
};
</script>
