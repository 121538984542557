import { EXPIRATION_DATES } from "@/types/expirationDates";
import i18n from "@/plugins/i18n";

export default {
  async list() {
    const expirationDateValues = [
      {
        id: EXPIRATION_DATES.EXPIRED,
        text: i18n.t("timeframes.already-expired")
      },
      { id: EXPIRATION_DATES.ONE_MONTH, text: i18n.t("timeframes.one-month") },
      {
        id: EXPIRATION_DATES.TWO_MONTHS,
        text: i18n.t("timeframes.two-months")
      },
      {
        id: EXPIRATION_DATES.THREE_MONTHS,
        text: i18n.t("timeframes.three-months")
      }
    ];
    return new Promise((res) => res(expirationDateValues));
  }
};
