import { get } from "./apiClient";

export default {
  async listDownloads(facilityId) {
    if (!facilityId) {
      throw new Error("Facility ID is required");
    }

    return await get(`downloads?facilityId=${facilityId}`);
  },

  async downloadExport(token) {
    if (!token) {
      throw new Error("A token is required");
    }

    const response = await get(`downloads/${token}`, {
      responseType: "blob"
    });

    //Internet Explorer has a built Blob save.
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(new Blob([response]), "export.csv");
      return;
    }

    const fileURL = window.URL.createObjectURL(new Blob([response]));
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", "export.csv");
    document.body.appendChild(fileLink);

    fileLink.click();
  }
};
