<template>
  <v-skeleton-loader :type="`table-heading`" v-if="reportLoading" />
  <ul
    v-else-if="items.length"
    class="
      tw-flex tw-flex-col
      md:tw-flex-row
      print:tw-flex-row print:tw-justify-center
      md:tw-justify-center
      tw-flex-wrap tw-mx-auto tw-list-none
      print:tw-text-sm
    "
  >
    <li
      v-for="(item, i) in items"
      class="tw-p-2 tw-flex tw-flex-row tw-items-center"
      :key="i"
    >
      <span class="tw-text-xl px-3">
        <v-icon :color="item.color">
          {{ item.icon }}
        </v-icon>
      </span>
      <div class="tw-flex tw-flex-col" :class="{ 'tw-text-2xl': isSlideshow }">
        <span>{{ item.text }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState("reports", ["isSlideshow", "reportLoading"])
  }
};
</script>

<style scoped lang="postcss">
ul {
  @apply tw-px-0;
}
</style>
