import isEqual from "lodash/isEqual";

export function compare(valueA, valueB) {
  if (valueA === valueB) {
    return 0;
  }

  return valueA > valueB ? 1 : -1;
}

export function dateCompare(valueA = new Date(), valueB = new Date()) {
  return compare(valueA, valueB);
}

export function stringCompare(valueA = "", valueB = "") {
  if (typeof valueA !== "string" || typeof valueB !== "string") {
    return 0;
  }

  return compare(
    valueA ? valueA.toLowerCase() : "",
    valueB ? valueB.toLowerCase() : ""
  );
}

export function arrayCompareSequenceImportant(arrayA = [], arrayB = []) {
  if (!Array.isArray(arrayA) || !Array.isArray(arrayB)) {
    return false;
  }

  if (arrayA.length !== arrayB.length) {
    return false;
  }

  return arrayA.every(function (element, index) {
    return isEqual(element, arrayB[index]);
  });
}

export function arrayCompareSequenceIrrelevant(arrayA = [], arrayB = []) {
  const arrayAClone = [...arrayA];
  const arrayBClone = [...arrayB];
  return arrayCompareSequenceImportant(arrayAClone.sort(), arrayBClone.sort());
}
