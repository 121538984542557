<template>
  <div>
    <report-summary :cells="cells" />
    <report-custom-legend :items="customLegend" />
    <report-graph :chartData="chartData" />
    <report-block-row :items="reportBlockItems" />
  </div>
</template>

<script>
import complianceReportMixin from "@/views/reports/OBV/mixins/complianceReport";

export default {
  mixins: [complianceReportMixin],
  data() {
    return {
      compatibleReportId: 18,
      reportFilters: [
        "timeframe",
        "locations",
        "shifts",
        "precautionOptions",
        "obvJobRoles"
      ],
      hiddenReportActions: ["saveSlide"]
    };
  }
};
</script>
