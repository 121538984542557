import { render, staticRenderFns } from "./ReportPerformanceBands.vue?vue&type=template&id=5250b478&scoped=true"
import script from "./ReportPerformanceBands.vue?vue&type=script&lang=js"
export * from "./ReportPerformanceBands.vue?vue&type=script&lang=js"
import style0 from "./ReportPerformanceBands.vue?vue&type=style&index=0&id=5250b478&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5250b478",
  null
  
)

export default component.exports