<template>
  <div>
    <report-table
      :headers="reportHeaders"
      :items="items"
      :initialSortedColumns="['floor']"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import reportMixin from "@/views/reports/mixins/reportMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";

export default {
  mixins: [reportMixin],
  components: { ReportTable },
  data() {
    const commonHeaders = [
      {
        text: i18n.t("reports.sa.headers.floor"),
        value: "floor",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.unit"),
        value: "unit",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.room"),
        value: "room",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-name"),
        value: "ecmDeviceName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-type"),
        value: "deviceTypeName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-model"),
        value: "deviceModelName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.serial-number"),
        value: "serialNumber",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.battery-status"),
        value: "currentBatteryStatus",
        sortable: true,
        class: "tw-align-text-top"
      }
    ];
    return {
      compatibleReportId: 23,
      reportHeaders: [...commonHeaders],
      csvHeaders: [...commonHeaders],
      items: [],
      reportFilters: ["locations"],
      hiddenReportActions: ["rawExport", "saveSlide"]
    };
  },
  created() {
    this.csvHeaders.splice(7, 0, {
      text: i18n.t("reports.sa.headers.age"),
      value: "alertAgeFormatted",
      sortable: true,
      class: "tw-align-text-top"
    });
    this.reportHeaders.splice(7, 0, {
      text: i18n.t("reports.sa.headers.age"),
      value: "alertAgeHours",
      sortable: true,
      class: "tw-align-text-top",
      displayText: "alertAgeFormatted"
    });
  },
  methods: {
    createCsvData() {
      const headers = this.csvHeaders;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      const items = this.reportResponse.dataPoints;
      this.items = items.map((item) => {
        const alertAge = item.alertAge.split(".");
        return {
          floor: item.floor,
          unit: item.unit,
          room: item.room,
          ecmDeviceName: item.ecmDeviceName,
          deviceTypeName: item.deviceTypeName,
          deviceModelName: item.deviceModelName,
          serialNumber: item.serialNumber,
          alertAge: item.alertAge,
          alertAgeHours: parseInt(alertAge[0]) * 24 + parseInt(alertAge[1]),
          alertAgeFormatted: item.alertAgeFormatted,
          currentBatteryStatus: item.currentBatteryStatus
        };
      });
    }
  }
};
</script>
