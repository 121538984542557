export default class BrowserStorage {
  static APP_KEY = "SMARTLINK-";

  static store() {
    throw new Error("Class is not to be instatiated directely.");
  }

  static get() {
    throw new Error("Class is not to be instatiated directely.");
  }

  static remove() {
    throw new Error("Class is not to be instatiated directely.");
  }
}
