import {
  stringVariableSubstitute,
  stringTranslationSubstitute
} from "@/utils/stringUtils";
import { hexToRgba } from "@/utils/colorFormatters";
import { TIMEFRAMES } from "@/types/timeframes";
import i18n from "@/plugins/i18n";
import { ChartScaleLinear } from "./chartScales";

function mapChartjsScales(scales) {
  const chartjsScales = {};
  if (scales.x !== undefined) {
    chartjsScales.xAxes = scales.x.map((scale) => scale.convertToChartScale());
  }
  if (scales.y !== undefined) {
    chartjsScales.yAxes = scales.y.map((scale) => scale.convertToChartScale());
  }
  return chartjsScales;
}

const getGraphUnitForTimeframe = (timeframe, minTimeUnit) => {
  const { activeIds, start, end } = timeframe;
  let timeframeUnit = "day";
  const unitOrder = ["hour", "day", "week", "month"];

  switch (activeIds) {
    case TIMEFRAMES.CUSTOM:
      timeframeUnit = CustomTimeframeSorter(start, end);
      break;
    case TIMEFRAMES.LAST_7_DAYS:
    case TIMEFRAMES.LAST_30_DAYS:
      timeframeUnit = "day";
      break;
    case TIMEFRAMES.LAST_24_HOURS:
    case TIMEFRAMES.TODAY:
    case TIMEFRAMES.YESTERDAY:
      timeframeUnit = "hour";
      break;
    case TIMEFRAMES.LAST_90_DAYS:
      timeframeUnit = "week";
      break;
    case TIMEFRAMES.LAST_MONTH:
    case TIMEFRAMES.LAST_6_MONTHS:
    case TIMEFRAMES.LAST_12_MONTHS:
      timeframeUnit = "month";
      break;
  }

  return unitOrder.indexOf(timeframeUnit) >= unitOrder.indexOf(minTimeUnit)
    ? timeframeUnit
    : minTimeUnit;
};

const CustomTimeframeSorter = (startTime, endTime) => {
  const hoursPerDay = 24;
  const ninetyDays = 90;
  const thirtyOneDays = 31;
  const hours = parseInt((endTime - startTime) / (1000 * 60 * 60));

  if (hours > (ninetyDays + 1) * hoursPerDay) {
    return "month";
  } else if (hours > thirtyOneDays * hoursPerDay) {
    return "week";
  } else if (hours > hoursPerDay) {
    return "day";
  } else {
    return "hour";
  }
};

export function convertToChartjsScale(
  {
    type,
    offset,
    label,
    fontColor,
    fontSize,
    fontStyle,
    ticks,
    gridLines,
    stacked,
    timeframe,
    minTimeUnit,
    customLabels = []
  } = {},
  position,
  axisId
) {
  const chartjsScale = {
    type,
    offset,
    position
  };
  if (ticks !== undefined) {
    chartjsScale.ticks = ticks;
  }
  if (gridLines !== undefined) {
    chartjsScale.gridLines = gridLines;
  }
  if (axisId !== undefined) {
    chartjsScale.id = axisId;
  }
  if (stacked !== undefined) {
    chartjsScale.stacked = stacked;
  }
  if (type === "time") {
    const unit = getGraphUnitForTimeframe(timeframe, minTimeUnit);

    chartjsScale.time = {
      unit,
      displayFormats: { hour: "HH:mm" },
      tooltipFormat: unit === "hour" ? "HH:mm" : "ll"
    };

    chartjsScale.distribution = "series";

    label = i18n.t(`reports.graph.axis.${unit}`);
  }
  if (label) {
    chartjsScale.scaleLabel = {
      display: true,
      labelString: stringTranslationSubstitute(label),
      fontColor: fontColor,
      fontSize: fontSize,
      fontStyle: fontStyle
    };
  }
  if (ticks?.format) {
    ticks.callback = function (value) {
      return stringVariableSubstitute({ data: { value }, str: ticks.format });
    };
  }
  if (customLabels.length) {
    chartjsScale.labels = customLabels;
  }
  return chartjsScale;
}

function buildLineAnnotations(value, color, id, scale) {
  return {
    type: "line",
    mode: scale.axis === "y" ? "horizontal" : "vertical",
    scaleID: scale.scaleId,
    value: value,
    borderColor: hexToRgba(color, 1),
    borderWidth: 3,
    borderDash: [10, 10],
    annotationId: id
  };
}

function setPerformanceGoalAnnotations(scale) {
  const goal = scale.goal;
  const annotations = [];

  if (goal) {
    annotations.push(
      buildLineAnnotations(goal, "9BC84A", "performanceGoal", scale)
    );
  }

  return annotations;
}

function buildBoxAnnotation(min, max, color, scale, id) {
  return {
    id,
    type: "box",
    [`${scale.axis}ScaleID`]: scale.scaleId,
    [`${scale.axis}Min`]: min,
    [`${scale.axis}Max`]: max,
    backgroundColor: hexToRgba(color, 0.2),
    borderColor: hexToRgba(color, 0.4),
    annotationId: "performanceBand"
  };
}

function setPerformanceBandAnnontations(scale) {
  const performanceBands = scale.performanceBands;
  if (!(performanceBands && performanceBands.length)) {
    return [];
  }
  const sortedPerformanceBands = [...performanceBands];
  sortedPerformanceBands.sort((a, b) => a.limit - b.limit);
  return sortedPerformanceBands.map((band, index) =>
    buildBoxAnnotation(
      index > 0 ? sortedPerformanceBands[index - 1].limit : 0,
      band.limit,
      band.color,
      scale,
      `performanceBand-${band.id}`
    )
  );
}

function setPerformanceThresholdAnnotations(scale) {
  const performanceThresholds = scale.performanceThresholds;
  if (!(performanceThresholds && performanceThresholds.length)) {
    return [];
  }
  const sortedPerformanceThresholds = [...performanceThresholds];
  sortedPerformanceThresholds.sort((a, b) => a.value - b.value);
  const annotations = [];

  sortedPerformanceThresholds.forEach((threshold, index) => {
    if (threshold.value === 0) {
      return;
    }

    annotations.push(
      buildLineAnnotations(
        threshold.value,
        threshold.color,
        `performanceThreshold-${index}`,
        scale
      )
    );
  });

  return annotations;
}

function getLinearScaleAnnotations(scales) {
  const annotations = [];
  const linearScales = [
    ...scales.y.filter(
      (scale) => scale.constructor.name === ChartScaleLinear.name
    ),
    ...scales.x.filter(
      (scale) => scale.constructor.name === ChartScaleLinear.name
    )
  ];
  linearScales
    .filter((scale) => scale.goal)
    .forEach((scale) => {
      annotations.push(...setPerformanceGoalAnnotations(scale));
    });
  linearScales
    .filter((scale) => scale.performanceBands)
    .forEach((scale) => {
      annotations.push(...setPerformanceBandAnnontations(scale));
    });
  linearScales
    .filter((scale) => scale.performanceThresholds)
    .forEach((scale) => {
      annotations.push(...setPerformanceThresholdAnnotations(scale));
    });

  return annotations;
}

function transformTimeSeriesDatasets(scales, datasets) {
  [...scales.x, ...scales.y]
    .filter((scale) => scale.scaleOptions.type === "time")
    .forEach((scale) => {
      datasets
        .filter(
          (dataset) =>
            dataset.xScaleId === scale.scaleId ||
            dataset.yScaleId === scale.scaleId
        )
        .forEach((dataset) => {
          dataset.data = dataset.data.map((d) => ({
            t: d.dateTime,
            [scale.axis === "x" ? "y" : "x"]: d.value
          }));
        });
    });
}

function transformDatasets(datasets) {
  datasets.forEach((dataset) => {
    if (dataset.xScaleId) {
      dataset.xAxisID = dataset.xScaleId;
      delete dataset.xScaleId;
    }
    if (dataset.yScaleId) {
      dataset.yAxisID = dataset.yScaleId;
      delete dataset.yScaleId;
    }
    dataset.order = dataset.type === "line" ? 0 : 1;
  });
}

export function buildChartjsChart({
  datasets = [],
  labels = [],
  scales = {},
  options = {},
  displayLegend = true,
  customLegendGenerator = false,
  isPrint = false
} = {}) {
  const chartjsScales = mapChartjsScales(scales);
  const areSomeBarsStacked = [
    ...chartjsScales.yAxes,
    ...chartjsScales.yAxes
  ].some((axis) => axis.stacked);
  const annotations = getLinearScaleAnnotations(scales);
  transformTimeSeriesDatasets(scales, datasets);
  transformDatasets(datasets);

  const legend = {
    display: displayLegend
  };

  if (customLegendGenerator) {
    legend.labels = {
      generateLabels: customLegendGenerator
    };
  }

  return {
    type: datasets.some((dataset) => dataset.type === "bar") ? "bar" : "line",
    data: {
      labels: labels,
      datasets: datasets
    },
    options: {
      ...options,
      legend,
      scales: chartjsScales,
      tooltips: {
        mode: "index",
        intersect: false
      },
      hover: {
        mode: "index",
        intersect: false
      },
      maintainAspectRatio: false,
      responsive: true,
      spanGaps: true,
      layout: {
        padding: {
          top: 30
        }
      },
      animation: isPrint
        ? false
        : {
            duration: 1500,
            easing: "easeOutBack"
          },
      annotation: {
        drawTime: "beforeDatasetsDraw",
        annotations: annotations
      },
      plugins: {
        colorschemes: {
          scheme: "brewer.Paired12"
        },
        datalabels: {
          anchor: "end",
          align: areSomeBarsStacked ? "bottom" : "top",
          formatter: function (context) {
            return context?.y;
          },
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          display: areSomeBarsStacked
            ? function (context) {
                return context.dataset.data[context.dataIndex].y > 0;
              }
            : undefined
        }
      }
    }
  };
}
