var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-snackbar',{staticClass:"tw-p-12",attrs:{"value":_vm.value,"color":_vm.reportActionsSnackbarContent.color,"top":true,"timeout":_vm.reportActionsSnackbarContent.disableTimeout ? -1 : 5000},on:{"input":function($event){return _vm.$emit('report-actions-snackbar-closed')}},scopedSlots:_vm._u([{key:"action",fn:function({ close }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":(e) => _vm.$emit('report-actions-snackbar-closed')}},'v-btn',close,false),[_c('v-icon',{attrs:{"color":_vm.reportActionsSnackbarContent.textColor
              ? `${_vm.reportActionsSnackbarContent.textColor}`
              : `purellGray`}},[_vm._v(" mdi-close-thick ")])],1)]}}])},[(_vm.reportActionsSnackbarContent.button)?_c('div',[_c('span',{class:_vm.reportActionsSnackbarContent.textColor
            ? `tw-text-${_vm.reportActionsSnackbarContent.textColor}`
            : `tw-text-purellGray`},[_vm._v(" "+_vm._s(_vm.reportActionsSnackbarContent.content)+" ")]),_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"href":_vm.reportActionsSnackbarContent.button.link,"target":_vm.reportActionsSnackbarContent.button.target || '_self',"color":"primary"}},[_vm._v(" "+_vm._s(_vm.reportActionsSnackbarContent.button.text)+" ")])],1):_c('div',[_c('span',{class:_vm.reportActionsSnackbarContent.textColor
            ? `tw-text-${_vm.reportActionsSnackbarContent.textColor}`
            : `tw-text-purellGray`},[_vm._v(" "+_vm._s(_vm.reportActionsSnackbarContent.content)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }