<template>
  <div>
    <h1>{{ $t("system-status.header") }}</h1>
    <v-data-table
      class="small-headers"
      :items="statuses"
      :items-per-page="-1"
      :headers="headers"
      :search="search"
      item-key="id"
      :loading="loading"
      :loading-text="$t('system-status.loading')"
      :no-data-text="$t('system-status.empty')"
      :item-class="highlightRow"
      multi-sort
    >
      <template v-slot:top>
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
            autofocus
          />
          <v-btn
            class="tw-max-w-xs"
            primary
            color="primary"
            @click="getFullStatus"
          >
            <v-icon> mdi-refresh </v-icon>
            {{ $t("ui.actions.refresh") }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import systemStatusService from "@/api/systemStatusService";

export default {
  data() {
    return {
      headers: [
        {
          text: i18n.t("system-status.headers.status-type"),
          value: "statusType",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.status-qualifier"),
          value: "statusQualifier",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.status-value"),
          value: "statusValue",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.min-threshold"),
          value: "minThreshold",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.max-threshold"),
          value: "maxThreshold",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      loading: true,
      search: "",
      statuses: []
    };
  },
  created() {
    this.getFullStatus();
  },
  methods: {
    async getFullStatus() {
      try {
        this.loading = true;
        const statuses = await systemStatusService.getFullStatus();
        this.statuses = statuses.sort(
          (a, b) =>
            a.isValid - b.isValid || a.statusType.localeCompare(b.statusType)
        );
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("system-status.error-messages.get-full-status")
        });
      } finally {
        this.loading = false;
      }
    },
    highlightRow(item) {
      const rowClass = "highlighted-row";
      return item.isValid ? "" : rowClass;
    }
  }
};
</script>

<style lang="postcss" scoped>
:deep(.highlighted-row) {
  background-color: rgb(250, 219, 219) !important;
}
</style>
