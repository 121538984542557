<template>
  <div class="tw-flex tw-flex-row tw-flex-wrap tw-items-center">
    <div
      v-for="type in reportTypes"
      :key="type.id"
      :class="`tw-shadow-md tw-mr-12 tw-mb-12 ${
        reportTypes.length === 1 ? '' : 'tw-cursor-pointer'
      }`"
    >
      <v-badge
        bordered
        color="green"
        icon="mdi-check"
        overlap
        :value="isSelected(type.id)"
      >
        <v-img
          :src="reportTypeImages[type.id]"
          width="300px"
          @click="
            reportTypes.length === 1 ? undefined : toggleReportType(type.id)
          "
        />
        <p class="tw-text-xl tw-font-semibold tw-px-4 tw-pt-4">
          {{ $t(`report-subscriptions.types.${type.translationKey}`) }}
        </p>
      </v-badge>
    </div>
  </div>
</template>

<script>
import { REPORT_TYPES } from "@/types/reportSubscriptionTypes";

export default {
  props: {
    reportTypes: {
      type: Array,
      default: () => []
    },
    selectedReportTypeId: {
      type: Number,
      default: undefined
    }
  },
  mounted() {
    if (this.reportTypes.length === 1) {
      this.toggleReportType(this.reportTypes[0].id);
    }
  },
  data() {
    return {
      reportTypeImages: {
        [REPORT_TYPES.JOB_ROLE]: require("@/views/reportSubscriptions/assets/ReportType-JobRole.png"),
        [REPORT_TYPES.GROUP]: require("@/views/reportSubscriptions/assets/ReportType-Group.png"),
        [REPORT_TYPES.AMS_PERFORMANCE]: require("@/views/reportSubscriptions/assets/ReportType-AmsPerformance.png"),
        [REPORT_TYPES.INDIVIDUAL_PERFORMANCE_TABLE]: require("@/views/reportSubscriptions/assets/ReportType-Individual-Table.png"),
        [REPORT_TYPES.INDIVIDUAL_PERFORMANCE_GRAPH]: require("@/views/reportSubscriptions/assets/ReportType-Individual-Graph.png")
      }
    };
  },
  methods: {
    toggleReportType(id) {
      this.$emit("update-selected-report-types", id);
    },
    isSelected(id) {
      return this.selectedReportTypeId === id;
    }
  },
  watch: {
    reportTypes(value) {
      if (value.length === 1 && !this.isSelected(value[0].id)) {
        this.toggleReportType(value[0].id);
      }
    }
  }
};
</script>
