<template>
  <div class="button-container tw-flex tw-flex-row tw-flex-wrap">
    <v-btn
      v-for="item in items"
      rounded
      :key="item[itemKey]"
      @click="select(item)"
      :class="[
        'tw-flex tw-mx-auto',
        text ? 'text-button' : '',
        selected(item) ? (text ? 'active-text-button' : 'active-button') : ''
      ]"
      :style="{
        width: `${$vuetify.breakpoint.mdAndUp ? 100 / items.length - 1 : 40}%`
      }"
      :disabled="disabled"
      :text="text"
      v-bind="buttonProps"
    >
      {{ item[itemTextKey] }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    buttonProps: {
      type: Object,
      default: () => {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemKey: {
      type: String,
      default: "id"
    },
    items: {
      type: Array,
      default: () => []
    },
    itemTextKey: {
      type: String,
      default: "text"
    },
    itemValueKey: {
      type: String,
      default: "value"
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, String, Number, Boolean]
    }
  },
  methods: {
    selected(item) {
      if (this.multiple) {
        return this.value.includes(item[this.itemValueKey]);
      }

      return this.value === item[this.itemValueKey];
    },
    select(item) {
      if (this.multiple) {
        if (this.value.includes(item[this.itemValueKey])) {
          this.$emit(
            "input",
            this.value.filter((i) => i !== item[this.itemValueKey])
          );
        } else {
          this.$emit("input", [...this.value, item[this.itemValueKey]]);
        }
      } else {
        if (this.value === item[this.itemValueKey] && !this.mandatory) {
          this.$emit("input", "");
        } else {
          this.$emit("input", item[this.itemValueKey]);
        }
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.active-button {
  color: #fff !important;
  background-color: #007bb6 !important;
}

.active-text-button {
  color: #007bb6 !important;
}

.text-button {
  font-weight: bold;
}

.button-container > * {
  margin: 0.5em auto;
}
</style>
