<template>
  <div>
    <report-summary :cells="cells" />
    <Transition name="fade">
      <report-performance-thresholds
        v-if="graphActions.displayThresholds"
        :performanceThresholds="performanceThresholds"
        :hideFirstThreshold="true"
      />
    </Transition>
    <report-graph
      :chartData="chartData"
      :showZoomReportAction="showGraphThresholdsActions"
      :showDisplayTresholdsAction="showGraphThresholdsActions"
    />
  </div>
</template>

<script>
import performanceReportsMixin from "@/views/reports/AMS/mixins/performanceReports";

export default {
  mixins: [performanceReportsMixin],
  data() {
    return {
      compatibleReportId: 2,
      chartType: "line"
    };
  }
};
</script>
