<template>
  <ValidationObserver ref="fullReportSubscriptionData">
    <v-form @submit.prevent="saveSubscription">
      <h3>
        {{ $t("report-subscriptions.details") }}
      </h3>
      <ValidationProvider
        vid="subscriptionName"
        :name="$t('report-subscriptions.name')"
        :rules="{ required: true, max: nameMax }"
        v-slot="{ errors }"
      >
        <v-text-field
          v-model="name"
          :counter="nameMax"
          :label="$t('report-subscriptions.name')"
          :error-messages="errors"
          required
        />
      </ValidationProvider>
      <ValidationProvider
        :name="$t('report-subscriptions.recipients')"
        :rules="{ required: true }"
        v-slot="{ errors }"
      >
        <v-autocomplete
          auto-select-first
          v-model="selectedRecipients"
          :error-messages="errors"
          :items="recipients"
          :label="$t('report-subscriptions.recipients')"
          :item-text="
            (item) =>
              `${item.firstName ? item.firstName : ''} ${
                item.lastName ? item.lastName : ''
              } (${item.email})`
          "
          chips
          deletable-chips
          clearable
          item-value="userId"
          multiple
        >
          <template v-slot:prepend-item>
            <filters-popup-header
              v-model="selectedRecipients"
              :allIds="recipients.map((r) => r.userId)"
            />
          </template>
        </v-autocomplete>
      </ValidationProvider>
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
        <ValidationProvider
          :name="$t('report-subscriptions.email-frequency')"
          :rules="{ required: true }"
          v-slot="{ errors }"
          class="md:tw-mr-2 tw-flex-1"
        >
          <v-select
            v-model="frequency"
            :error-messages="errors"
            :label="$t('report-subscriptions.email-frequency')"
            :items="
              availableFrequencies.map((f) => ({
                text: $t(f.text),
                value: f.value
              }))
            "
            items-text="text"
            items-value="value"
          />
        </ValidationProvider>
        <div v-if="showWeekly(frequency)" class="md:tw-mx-2 tw-flex-1">
          <ValidationProvider
            :name="$t('report-subscriptions.actions.select-day')"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <v-select
              :error-messages="errors"
              :label="$t('report-subscriptions.actions.select-day')"
              v-model="dayOfWeek"
              :items="
                daysOfWeek.map((d) => ({
                  text: $t(`days-of-week.full.${d.translationKey}`),
                  value: d.value
                }))
              "
              items-text="text"
              items-value="value"
            />
          </ValidationProvider>
        </div>
        <ValidationProvider
          vid="subscriptionTimeOfDay"
          :name="$t('report-subscriptions.time-of-day')"
          :rules="{ required: true }"
          v-slot="{ errors }"
          class="md:tw-ml-2 tw-flex-1"
        >
          <v-select
            :label="$t('report-subscriptions.time-of-day')"
            :error-messages="errors"
            v-model="timeOfDay"
            :items="hoursOfDay"
            items-text="text"
            items-value="value"
            :hint="facilityTimeZone"
            persistent-hint
          />
        </ValidationProvider>
      </div>
      <div v-if="showTimeframe">
        <h3>{{ $t("report-subscriptions.additional-report-filter") }}</h3>
        <v-select
          mandatory
          :label="$t('filters.timeframe')"
          v-model="timeframe"
          :items="
            timeframes.map((t) => ({
              text: $t(`timeframes.${t.translationKey}`),
              value: t.value
            }))
          "
          items-text="text"
          items-value="value"
        />
      </div>
    </v-form>
  </ValidationObserver>
</template>

<script>
import i18n from "@/plugins/i18n";
import { FREQUENCIES } from "@/types/frequencies";
import { REPORT_TYPES } from "@/types/reportSubscriptionTypes";
import { FILTERS } from "@/types/filters";
import reportSubscriptionService from "@/api/reportSubscriptionService";
import { reportDateRangeConverter } from "@/views/reports/utils/reportDateRangeConverter";
import { mapState } from "vuex";
import { TIMEFRAMES, TIMEFRAMES_STRINGS } from "@/types/timeframes";
import FiltersPopupHeader from "@/views/reports/components/filters/components/filtersPopup/components/FiltersPopupHeader";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    originalReportSubscription: {
      type: Object,
      default: () => {}
    },
    reportSubscriptionId: {
      type: Number,
      default: null
    },
    saving: {
      type: Boolean
    },
    selectedReportTypeId: {
      type: Number
    }
  },
  components: {
    FiltersPopupHeader
  },
  data() {
    return {
      dayOfWeek: "",
      daysOfWeek: [
        { translationKey: "monday", value: 1 },
        { translationKey: "tuesday", value: 2 },
        { translationKey: "wednesday", value: 3 },
        { translationKey: "thursday", value: 4 },
        { translationKey: "friday", value: 5 },
        { translationKey: "saturday", value: 6 },
        { translationKey: "sunday", value: 0 }
      ],
      frequency: "",
      hoursOfDay: [],
      name: "",
      nameMax: 50,
      recipients: [],
      selectedRecipients: [],
      timeframe: TIMEFRAMES.LAST_7_DAYS,
      timeframes: [
        { translationKey: "last-24-hours", value: TIMEFRAMES.LAST_24_HOURS },
        { translationKey: "last-7-days", value: TIMEFRAMES.LAST_7_DAYS }
      ],
      timeOfDay: "",
      valid: false
    };
  },
  created() {
    this.hoursOfDay = [...Array(24).keys()].map((t) => ({
      text: new Date(new Date().setHours(t, 0, 0, 0)).toLocaleTimeString(
        this.language,
        {
          hour: "2-digit",
          minute: "2-digit"
        }
      ),
      value: t
    }));
  },
  computed: {
    ...mapState("customers", ["facilityId", "customerId"]),
    ...mapState("preferences", ["language"]),
    facilityTimeZone() {
      return this.$store.state.facilities.byId[this.facilityId].timeZone;
    },
    availableFrequencies() {
      let frequencies = Object.keys(FREQUENCIES).map((k) => ({
        text: `time.${k.toLowerCase()}`,
        value: FREQUENCIES[k]
      }));
      if (this.selectedReportTypeId === REPORT_TYPES.AMS_PERFORMANCE) {
        frequencies = frequencies.filter(
          (f) => f.value !== FREQUENCIES.MONTHLY
        );
      }
      return frequencies;
    },
    showTimeframe() {
      return this.frequency === FREQUENCIES.DAILY;
    }
  },
  mounted() {
    if (this.customerId && this.facilityId) {
      this.getRecipients();
    }
  },
  methods: {
    async getRecipients() {
      try {
        this.recipients = await reportSubscriptionService.getRecipients(
          this.facilityId
        );
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("report-subscriptions.errors.get-recipients")
        });
      }
    },
    async saveSubscription() {
      this.valid = await this.$refs.fullReportSubscriptionData.validate();

      if (!this.valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);
        this.$emit("unsuccessful-save");

        this.$appInsights?.trackEvent({
          name: `Report Subscription Form Error`
        });
        return;
      }

      try {
        await reportSubscriptionService.saveSubscription({
          dayOfWeek:
            this.frequency === FREQUENCIES.WEEKLY ? this.dayOfWeek : null,
          dayOfMonth: this.frequency === FREQUENCIES.MONTHLY ? 1 : null,
          facilityId: this.facilityId,
          frequencyId: this.frequency,
          id: this.reportSubscriptionId,
          selectedEmployeeIds:
            this.$store.state[`filters/${FILTERS.EMPLOYEES}`].activeIds
              .length ===
            this.$store.state[`filters/${FILTERS.EMPLOYEES}`].allIds.length
              ? []
              : this.$store.state[`filters/${FILTERS.EMPLOYEES}`].activeIds,
          selectedJobRoleIds:
            this.$store.state[`filters/${FILTERS.IMSJOBROLES}`].activeIds,
          selectedLocationIds:
            this.$store.state[`filters/${FILTERS.LOCATIONS}`].activeIds,
          name: this.name,
          recipients: this.recipients.filter((r) =>
            this.selectedRecipients.includes(r.userId)
          ),
          selectedReportTypeId: this.selectedReportTypeId,
          selectedShiftIds:
            this.$store.state[`filters/${FILTERS.SHIFTS}`].activeIds,
          timeOfDayLocal: this.timeOfDay,
          timeframeId: reportDateRangeConverter(
            this.frequency === FREQUENCIES.DAILY
              ? this.timeframe
              : this.frequency
          )
        });

        this.$appInsights?.trackEvent({
          name: this.reportSubscriptionId
            ? `Report Subscription Successfully Updated`
            : `Report Subscription Successfully Created`
        });

        this.$router.push({
          name: "reportSubscriptions",
          params: {
            successMessage: this.reportSubscriptionId
              ? i18n.t("report-subscriptions.success-messages.update")
              : i18n.t("report-subscriptions.success-messages.create")
          }
        });
      } catch (error) {
        this.$emit("unsuccessful-save");
        this.$appInsights?.trackEvent({
          name: `Report Subscription Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("report-subscriptions.errors.save-report")
        });
      }
    },
    showWeekly(frequency) {
      return frequency === FREQUENCIES.WEEKLY;
    }
  },
  watch: {
    facilityId() {
      this.getRecipients();
    },
    originalReportSubscription(value) {
      this.dayOfWeek = value.dayOfWeek;
      this.frequency = value.frequencyId;
      this.name = value.name;
      this.selectedRecipients = value.recipients.map((r) => r.userId);
      this.timeframe = TIMEFRAMES_STRINGS[value.timeframeId];
      this.timeOfDay = value.timeOfDayLocal;
    },
    saving(value) {
      if (value) {
        this.saveSubscription();
      }
    }
  }
};
</script>
