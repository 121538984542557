import { makeCRUDModule } from "@/store/modules/makeModule";
import facilityService from "@/api/facilityService";
import normalizeFields from "@/store/utils/fields";

export function makeFacilitiesModule() {
  return makeCRUDModule({
    service: facilityService,
    normalizer: (object) =>
      normalizeFields(object, {
        id: "id",
        name: "text",
        hygieneProtocolId: "hygieneProtocolId",
        customerId: "customerId",
        canRawExport: "canRawExport",
        hasShifts: "hasShifts",
        hasRefillsWithExpirationDate: "hasRefillsWithExpirationDate",
        timeZone: "timeZone"
      })
  });
}
