import { get, post, put } from "./apiClient";

export default {
  async get(facilityId) {
    return await get(`facility/${facilityId}/roomTemplate`);
  },
  async create(facilityId, roomTemplate) {
    return await post(`facility/${facilityId}/roomTemplate`, roomTemplate);
  },
  async edit(facilityId, roomTemplate) {
    return await put(`facility/${facilityId}/roomTemplate`, roomTemplate);
  }
};
