<template>
  <div>
    <app-navigation-drawer v-show="!isPrint" @log-out="logOut()" />
    <div
      class="
        app
        tw-w-full tw-bg-background
        print:tw-bg-white
        tw-flex tw-flex-col tw-min-h-screen tw-h-full
      "
    >
      <v-progress-linear indeterminate v-if="loading.header && !isPrint" />
      <app-menu
        v-if="!$route.meta.slideshowView"
        class="tw-mb-5 tw-flex-grow-0 print:tw-hidden"
        v-show="!isPrint"
        @log-out="logOut()"
      />
      <div>
        <slot></slot>
      </div>
      <app-footer v-if="!$route.meta.slideshowView" v-show="!isPrint" />
      <notifications
        group="error"
        type="error"
        position="bottom right"
        class="print:tw-hidden tw-mb-6 tw-mr-2 tw-fixed"
        width="400"
        v-show="!isPrint"
      >
        <template slot="body" slot-scope="props">
          <v-alert type="error" prominent dismissible>
            <span>
              {{ props.item.text }}
              <v-btn
                v-if="error && error.retryAction"
                small
                dark
                outlined
                @click="
                  $store.dispatch(error.retryAction, error.retryParameter)
                "
                >{{ $t("retry") }}</v-btn
              >
            </span>
          </v-alert>
        </template>
      </notifications>
    </div>
  </div>
</template>

<script>
import AppNavigationDrawer from "@/components/layout/menu/AppNavigationDrawer.vue";
import AppMenu from "@/components/layout/menu/AppMenu.vue";
import AppFooter from "@/components/layout/AppFooter.vue";
import accountService from "../api/accountService";
import { mapState } from "vuex";
import { DISPLAY_MODES } from "../store/modules/application";

export default {
  components: {
    AppNavigationDrawer,
    AppMenu,
    AppFooter
  },
  computed: {
    ...mapState("application", ["loading", "error"]),
    ...mapState("reports", ["user"]),
    isPrint() {
      return this.$store.state.application.displayMode === DISPLAY_MODES.PRINT;
    }
  },
  methods: {
    async logOut() {
      await accountService.logOut();
      window.location.href = "/login";
    }
  },
  watch: {
    error() {
      if (this.error && this.error.message) {
        this.$notify({
          group: "error",
          text: this.error.message,
          duration: -1
        });
      } else {
        this.$notify({ group: "error", clean: true });
      }
    }
  }
};
</script>

<style>
.v-select-list {
  min-width: 200px;
}
</style>
