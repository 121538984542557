<template>
  <default-layout>
    <v-container>
      <v-card>
        <section class="tw-px-4 sm:tw-px-8 tw-inline-block tw-w-full">
          <router-view />
        </section>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/Default";
import { makeCustomersModule } from "@/store/modules/customers";
import { makeFacilitiesModule } from "@/store/modules/facilities";
import { PRODUCTS } from "@/types/products";
import PERMISSION_CHECKS from "@/types/permissions";

export default {
  components: {
    DefaultLayout
  },
  beforeCreate() {
    this.$store.registerModule("customers", makeCustomersModule());
    this.$store.registerModule("facilities", makeFacilitiesModule());

    this.$store.commit(
      "customers/setPrivilegeModel",
      PERMISSION_CHECKS.amsReports
    );
  },
  async created() {
    this.$store.commit("customers/setProductId", PRODUCTS.ECM);
    await this.$store.dispatch(
      "customers/load",
      this.$store.state.customers.privilegeModel
    );
    await this.$store.dispatch("customers/setDefaultCustomer", {
      customerId: this.$route.query?.customerId,
      facilityId: this.$route.query?.facilityId
    });
  }
};
</script>
