<template>
  <div>
    <div class="tw-flex tw-flex-row tw-my-5 tw-items-center">
      <h1 class="tw-text-3xl tw-mr-5">{{ $t("obv-system.obv-system") }}</h1>
      <facility-selection-bar />
    </div>
    <v-row>
      <v-col cols="12" lg="4">
        <v-card
          outlined
          min-height="450px"
          height="70vh"
          class="tw-flex tw-flex-col"
        >
          <v-card-title class="tw-text-2xl tw-justify-between">
            {{ $t(leftCardTitle) }}
            <div>
              <v-btn
                v-if="sessionStarted"
                color="error"
                text
                @click="resetSession(false)"
              >
                <v-icon color="error"> mdi-trash-can </v-icon>
                {{
                  $t(
                    `${
                      observations.length || observationStarted
                        ? "ui.actions.discard"
                        : "ui.actions.cancel"
                    }`
                  )
                }}
              </v-btn>
              <v-btn
                v-if="observations.length > 0"
                color="success"
                dark
                @click="submitSession(false)"
              >
                {{ $t("ui.actions.submit") }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text v-if="observations.length === 0" class="tw-text-lg">
            {{ $t(leftCardInstructions) }}
          </v-card-text>
          <div
            v-if="!sessionStarted && !resumeableSession.length"
            class="tw-flex tw-flex-row tw-items-center tw-justify-center"
          >
            <h1 class="tw-text-6xl" style="color: #007bb6">
              {{ observerObservationCount }}
            </h1>
            <v-divider vertical class="tw-mx-4" inset />
            <v-icon class="tw-mr-2" style="font-size: 70px">
              mdi-office-building-outline
            </v-icon>

            <div class="tw-text-xl">
              <p style="margin-bottom: 0px">
                {{ `${monthIndexToString[new Date().getMonth()]}` }}
              </p>
              <p style="margin-bottom: 0px">
                {{ $t("obv-system.observation.plural") }}
              </p>
            </div>
          </div>
          <v-list
            v-else-if="observations.length > 0"
            two-line
            class="tw-h-full tw-overflow-auto"
          >
            <v-list-item
              v-for="observation in observations"
              :key="observation.id"
              class="list-item tw-py-2"
              @click="editObservation(observation)"
            >
              <div
                class="
                  tw-flex tw-flex-col tw-items-center tw-justify-center tw-mr-4
                "
              >
                <v-img
                  :src="
                    observation.type === observationTypes.ppe ? ppeIcon : hhIcon
                  "
                  contain
                  width="80%"
                />
                <h3 class="tw-text-primary">
                  {{ observation.type === observationTypes.ppe ? "PPE" : "HH" }}
                </h3>
              </div>
              <v-list-item-content class="tw-p-0">
                <v-list-item-title
                  class="tw-flex tw-justify-between tw-text-primary tw-text-xl"
                >
                  {{ getRole(observation.roleId) }}
                  <span class="tw-text-black tw-text-base">{{
                    observation.dateTime
                  }}</span>
                </v-list-item-title>
                <v-list-item-subtitle class="tw-text-lg">
                  {{
                    `${getMoment(observation)}, ${getLocation(
                      observation.locationId
                    )}`
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-img
            v-else
            v-show="!resumeableSession.length"
            :src="sessionHand"
            max-width="40%"
            max-height="40%"
            class="tw-self-center tw-mt-6"
            contain
          />
          <v-card-actions
            class="tw-flex tw-px-4 tw-pb-4 tw-items-center tw-w-full tw-mt-auto"
          >
            <v-btn
              v-if="!sessionStarted"
              class="white--text"
              width="100%"
              color="primary"
              @click="startSession"
              :disabled="selectedObserverId === ''"
            >
              {{
                $t(
                  resumeableSession.length
                    ? "obv-system.resume-session"
                    : "obv-system.start-new-session"
                )
              }}
            </v-btn>
            <div class="tw-w-full" v-else>
              <v-btn
                dark
                width="100%"
                color="primary"
                @click="startObservation(observationTypes.hh)"
              >
                <v-icon dark class="tw-mr-5"> mdi-plus-circle-outline </v-icon>
                {{ $t("obv-system.hand-hygiene") }}
              </v-btn>
              <v-btn
                dark
                width="100%"
                color="tertiary"
                class="tw-mt-4"
                @click="startObservation(observationTypes.ppe)"
              >
                <v-icon dark class="tw-mr-5"> mdi-plus-circle-outline </v-icon>
                {{ $t("obv-system.ppe") }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8">
        <v-card
          v-if="!resumeableSession.length"
          outlined
          min-height="450px"
          height="70vh"
          class="tw-flex tw-flex-col"
        >
          <v-card-title
            v-if="sessionStarted"
            class="tw-pb-0 tw-bg-white tw-pb-4 tw-justify-between"
            :style="{
              'border-bottom':
                !sessionStarted || observationStarted ? '1px solid grey' : ''
            }"
          >
            <span class="tw-mr-12 tw-text-2xl">
              {{ $t(rightCardTitle) }}
            </span>
            <div v-if="observationStarted">
              <v-btn
                v-if="editingObservation"
                color="error"
                text
                @click="deleteObservation(false)"
              >
                <v-icon color="error"> mdi-trash-can </v-icon>
                {{ $t("ui.actions.delete") }}
              </v-btn>
              <v-btn
                color="primary"
                class="tw-mr-5 tw-w-24"
                text
                @click="cancelObservation('cancelObservation')"
              >
                {{ $t("ui.actions.cancel") }}
              </v-btn>
              <v-btn
                class="tw-w-24 white--text"
                color="success"
                :disabled="
                  currentObservation.momentId == '' ||
                  currentObservation.outcomeId == '' ||
                  (currentObservation.precautions &&
                    currentObservation.precautions.length === 0)
                "
                @click="saveObservation"
              >
                {{ $t("ui.actions.save") }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text
            v-if="sessionStarted && !observationStarted"
            class="tw-text-lg"
          >
            {{ $t(rightCardInstructions) }}
          </v-card-text>
          <observation
            v-if="observationStarted"
            ref="observationForm"
            v-model="currentObservation"
            :jobRoles="jobRoles"
            :locations="locations"
            :moments="
              currentObservation.type === observationTypes.ppe
                ? ppeMoments
                : selectableHHMoments
            "
            :observationTypes="observationTypes"
            :precautionOptions="precautionOptions"
            :quickNotes="quickNotes"
            :showCustomNotes="showCustomNotes"
            :showFeedback="showFeedback"
          />
          <div v-else class="tw-h-full tw-overflow-auto">
            <list
              v-if="!sessionStarted"
              v-model="selectedObserverId"
              :items="observers"
              itemTextKey="displayText"
              itemValueKey="id"
              :title="$t('obv-system.session-observer')"
              :loading="loadingObservers"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ $t(dialog.title) }}
        </v-card-title>
        <v-card-text>
          {{ $t(dialog.body) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="purellGray" text @click="showDialog = false">
            {{ $t(dialog.cancelText) }}
          </v-btn>
          <v-btn color="primary" @click="dialogAction(dialog.action)">
            {{ $t(dialog.actionText) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="submitting">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import cloneDeep from "lodash/cloneDeep";
import FacilitySelectionBar from "@/components/layout/FacilitySelectionBar.vue";
import Observation from "./components/Observation.vue";
import List from "./components/List.vue";
import { mapState } from "vuex";
import { monthIndexToString } from "@/types/monthTranslator";
import facilityService from "@/api/facilityService";
import hygieneProtocolOpportunityService from "@/api/hygieneProtocolOpportunityService";
import locationHierarchyService from "@/api/locationHierarchyService";
import observationService from "@/api/observationService";
import obvJobRoleService from "@/api/obvJobRoleService";
import quickNoteService from "@/api/quickNoteService";
import { precautionOptions } from "@/types/precautionOptions";
import { uuidv4 } from "@/utils/generators";
import sessionHand from "@/assets/images/obvSystem/session_hand@2x.png";
import hhIcon from "@/assets/images/obvSystem/observation_hh@2x.png";
import ppeIcon from "@/assets/images/obvSystem/observation_ppe@2x.png";
import { isEqual } from "lodash";

export default {
  components: {
    FacilitySelectionBar,
    List,
    Observation
  },
  data() {
    return {
      currentObservation: { type: "" },
      dialog: {},
      editingObservation: false,
      leftCardInstructions: "obv-system.session-not-started-instructions",
      leftCardTitle: "obv-system.session-not-started",
      loadingObservers: false,
      locations: [],
      hhIcon,
      jobRoles: [],
      moments: [],
      monthIndexToString,
      observationStarted: false,
      observations: [],
      observationToEdit: {},
      observationTypeToStart: "",
      observationTypes: {
        ppe: "PPE",
        hh: "HH"
      },
      observerObservationCount: "--",
      observers: [],
      outOfRoomMomentId: 2,
      ppeIcon,
      ppeMoments: [],
      precautionOptions: [
        {
          id: precautionOptions.standard,
          text: i18n.t("precautionOptions.standard")
        },
        {
          id: precautionOptions.contact,
          text: i18n.t("precautionOptions.contact")
        },
        {
          id: precautionOptions.droplet,
          text: i18n.t("precautionOptions.droplet")
        },
        {
          id: precautionOptions.airborne,
          text: i18n.t("precautionOptions.air")
        }
      ],
      quickNotes: [],
      resumeableSession: [],
      rightCardInstructions: "obv-system.observation-instructions",
      rightCardTitle: "obv-system.session-observer",
      search: "",
      selectableHHMoments: [],
      selectedObserverId: "",
      sessionHand,
      sessionStarted: false,
      sessionStartTimeUtc: "",
      showCustomNotes: false,
      showDialog: false,
      showFeedback: false,
      submitting: false
    };
  },
  computed: {
    ...mapState("preferences", ["language"]),
    ...mapState("customers", ["facilityId"])
  },
  created() {
    hygieneProtocolOpportunityService.getAll().then((opportunities) => {
      this.moments = opportunities.map((o) => ({
        id: o.id,
        hygieneProtocolId: o.hygieneProtocolId,
        name: i18n.t(`protocolOpportunities.${o.name}`)
      }));

      this.ppeMoments = this.moments.filter((m) => m.hygieneProtocolId === 1);

      if (this.facilityId) {
        this.selectableHHMoments = this.moments.filter(
          (m) =>
            m.hygieneProtocolId ===
            this.$store.state.facilities.byId[this.facilityId].hygieneProtocolId
        );
      }
    });

    this.keepAlive();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    cancelObservation(action) {
      if (
        this.editingObservation &&
        isEqual(this.currentObservation, this.observationToEdit)
      ) {
        this.resetObservation();
        return;
      }

      const dialog = {
        cancelText: "obv-system.no",
        actionText: "obv-system.yes",
        action
      };

      if (this.editingObservation) {
        dialog.title = "obv-system.cancel-edit-title";
        dialog.body = "obv-system.cancel-edit-body";
      } else {
        dialog.title = "obv-system.cancel-observation-title";
        dialog.body = "obv-system.cancel-observation-body";
      }

      this.dialog = dialog;
      this.showDialog = true;
    },
    deleteObservation(confirmed = false) {
      if (!confirmed) {
        this.dialog = {
          title: "obv-system.delete-observation-title",
          body: "obv-system.delete-observation-body",
          cancelText: "ui.actions.cancel",
          actionText: "ui.actions.delete",
          action: "deleteObservation"
        };
        this.showDialog = true;
      } else {
        const index = this.observations.findIndex(
          (observation) => observation.id === this.currentObservation.id
        );
        this.observations.splice(index, 1);
        this.resetObservation();
      }
    },
    dialogAction() {
      if (this.dialog.action === "startObservation") {
        this.startObservation(this.observationTypeToStart, true);
      } else if (this.dialog.action === "cancelObservation") {
        this.resetObservation();
      } else if (this.dialog.action === "discardSession") {
        this.resetSession(true, true);
      } else if (this.dialog.action === "editObservation") {
        this.editObservation(this.observationToEdit, true);
      } else if (this.dialog.action === "deleteObservation") {
        this.deleteObservation(true);
      } else if (this.dialog.action === "submitSession") {
        this.submitSession(true);
      }

      this.showDialog = false;
    },
    editObservation(observation, confirmed = false) {
      if (this.observationStarted && !confirmed) {
        this.observationToEdit = { ...observation, overwrite: true };
        this.cancelObservation("editObservation");
      } else {
        this.currentObservation = cloneDeep(observation);
        this.observationToEdit = cloneDeep(observation);
        this.observationStarted = true;
        this.editingObservation = true;
      }
    },
    getLocation(locationId) {
      return this.locations.find((l) => l.id === locationId)?.name;
    },
    getMoment(observation) {
      const moments =
        observation.type === this.observationTypes.ppe
          ? this.ppeMoments
          : this.selectableHHMoments;
      return moments.find((m) => m.id === observation.momentId)?.name;
    },
    getObserverData() {
      this.observerObservationCount = "--";

      observationService
        .getObserverData(this.selectedObserverId, this.facilityId)
        .then((observerData) => {
          this.observerObservationCount = observerData.observationCount;
        })
        .catch(() => {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("obv-system.error-messages.get-observer-data")
          });
        });
    },
    getRole(roleId) {
      return this.jobRoles.find((j) => j.id === roleId)?.name;
    },
    keepAlive() {
      this.interval = window.setInterval(() => {
        observationService.keepAlive();
      }, 300000);
    },
    ppeOption(ppe) {
      switch (ppe) {
        case "obv-system.eye-protection":
          return "eyeProtection";
        case "obv-system.gloves":
          return "gloves";
        case "obv-system.gown":
          return "gown";
        case "obv-system.mask":
          return "mask";
        case "obv-system.respirator":
          return "respirator";
      }
    },
    resetObservation() {
      this.observationStarted = false;
      this.editingObservation = false;
      this.currentObservation = { type: "" };
    },
    resetSession(confirmed, discard = false) {
      if (!confirmed && (this.observations.length || this.observationStarted)) {
        this.dialog = {
          title: "obv-system.discard-session-title",
          body: "obv-system.discard-session-body",
          cancelText: "ui.actions.cancel",
          actionText: "ui.actions.discard",
          action: "discardSession"
        };
        this.showDialog = true;
      } else {
        this.sessionStarted = false;
        this.observationStarted = false;
        this.leftCardTitle = "obv-system.session-not-started";
        this.leftCardInstructions =
          "obv-system.session-not-started-instructions";
        this.rightCardTitle = "obv-system.session-observer";
        this.observations = [];
        this.resetObservation();

        if (discard) {
          localStorage.removeItem(
            `SmartLink_Observation_${this.facilityId}_${this.selectedObserverId}`
          );
        }
      }
    },
    saveObservation() {
      if (this.currentObservation.customNotes?.length > 200) {
        document.getElementById("custom-notes").scrollIntoView();
        return;
      }

      this.leftCardTitle = "obv-system.session";

      const date =
        this.currentObservation.dateTime === "Now"
          ? new Date()
          : new Date(this.currentObservation.dateTime);
      const dateInfo = {
        timestampUtc: date.toISOString().split(".")[0],
        dateTime: date.toLocaleString("en-US", {
          month: "numeric",
          day: "numeric",
          year: "2-digit",
          hour: "numeric",
          minute: "2-digit"
        })
      };

      if (this.editingObservation) {
        const index = this.observations.findIndex(
          (observation) => observation.id === this.currentObservation.id
        );

        this.observations[index] = {
          ...this.currentObservation,
          ...dateInfo
        };
      } else {
        this.observations.push({
          ...this.currentObservation,
          id: uuidv4(),
          ...dateInfo
        });
      }

      localStorage.setItem(
        `SmartLink_Observation_${this.facilityId}_${this.selectedObserverId}`,
        btoa(JSON.stringify(this.observations))
      );
      this.resetObservation();
    },
    startObservation(type, confirmed = false) {
      if (this.currentObservation.type !== type || this.editingObservation) {
        if (this.observationStarted && !confirmed) {
          this.observationTypeToStart = type;
          this.cancelObservation("startObservation");
        } else {
          this.editingObservation = false;

          const newObservation = {
            type,
            feedbackProvided: false,
            locationId:
              this.observations.length > 0
                ? this.observations[this.observations.length - 1].locationId
                : "",
            roleId: "",
            dateTime: "Now",
            momentId: "",
            quickNotes: [],
            customNotes: "",
            overwrite: true
          };

          if (type === this.observationTypes.ppe) {
            this.currentObservation = {
              ...newObservation,
              precautions: [],
              ppes: [
                {
                  text: "obv-system.eye-protection",
                  used: false,
                  removed: null,
                  enabledBy: [precautionOptions.standard]
                },
                {
                  text: "obv-system.gloves",
                  used: false,
                  removed: null,
                  enabledBy: [precautionOptions.contact]
                },
                {
                  text: "obv-system.gown",
                  used: false,
                  removed: null,
                  enabledBy: [precautionOptions.contact]
                },
                {
                  text: "obv-system.mask",
                  used: false,
                  removed: null,
                  enabledBy: [
                    precautionOptions.standard,
                    precautionOptions.droplet
                  ],
                  disabledBy: precautionOptions.airborne
                },
                {
                  text: "obv-system.respirator",
                  used: false,
                  removed: null,
                  enabledBy: [precautionOptions.airborne]
                }
              ]
            };
          } else {
            this.currentObservation = { ...newObservation, outcomeId: "" };
          }

          this.observationStarted = true;
          const observationForm = document.getElementById("observationForm");
          if (observationForm) {
            observationForm.scrollTop = 0;
          }
        }
      }
    },
    startSession() {
      this.sessionStarted = true;
      this.leftCardTitle = "obv-system.session-empty";
      this.leftCardInstructions = "obv-system.session-empty-instructions";
      this.rightCardTitle = "obv-system.observation.singular";
      this.sessionStartTimeUtc = new Date().toISOString().split(".")[0];

      if (this.resumeableSession.length) {
        this.leftCardTitle = "obv-system.session";
        this.observations = this.resumeableSession;
        this.resumeableSession = [];
      }
    },
    async submitSession(confirmed = false) {
      if (!confirmed) {
        this.dialog = {
          title: "obv-system.submit-session-title",
          body: "obv-system.submit-session-body",
          cancelText: "obv-system.no",
          actionText: "obv-system.yes",
          action: "submitSession"
        };
        this.showDialog = true;
      } else {
        try {
          this.submitting = true;

          const session = {
            facilityId: this.facilityId,
            observerId: this.selectedObserverId,
            startTimeUtc: this.sessionStartTimeUtc,
            endTimeUtc: new Date().toISOString().split(".")[0],
            observations: this.observations.map((o) => {
              const formattedObservation = {
                type: o.type,
                timestampUtc: o.timestampUtc,
                locationId: o.locationId,
                roleId: o.roleId,
                momentId: o.momentId,
                feedbackProvided: o.feedbackProvided,
                customNotes: o.customNotes,
                quickNotes: o.quickNotes
              };

              if (o.type === this.observationTypes.ppe) {
                o.precautions.forEach((p) => {
                  formattedObservation[p] = true;
                });

                o.ppes.forEach((ppe) => {
                  const ppeOption = this.ppeOption(ppe.text);
                  formattedObservation[ppeOption] = ppe.used;

                  if (ppe.used && o.momentId === this.outOfRoomMomentId) {
                    formattedObservation[`${ppeOption}Removed`] = ppe.removed;
                  }
                });
              } else {
                formattedObservation.outcomeId = o.outcomeId;
              }

              return formattedObservation;
            })
          };

          await observationService.submitSession(session);

          localStorage.removeItem(
            `SmartLink_Observation_${this.facilityId}_${this.selectedObserverId}`
          );
          this.$store.commit(
            "application/SET_SUCCESS_MESSAGE",
            i18n.t("obv-system.success-messages.submit-session")
          );
          this.resetSession(true, true);
          this.getObserverData();
        } catch {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("obv-system.error-messages.submit-session")
          });
        } finally {
          this.submitting = false;
        }
      }
    }
  },
  watch: {
    facilityId: {
      immediate: true,
      handler: async function (value) {
        if (value) {
          this.loadingObservers = true;
          this.observers = [];
          this.selectedObserverId = "";
          if (this.sessionStarted) {
            this.resetSession(true);
          }

          observationService
            .getObservers(value)
            .then((observers) => {
              this.observers = observers;
              this.selectedObserverId = this.observers.length
                ? this.observers[0].id
                : "";
            })
            .catch(() => {
              this.$store.commit("application/SET_ERROR", {
                message: i18n.t("obv-system.error-messages.get-observers")
              });
            })
            .finally(() => {
              this.loadingObservers = false;
            });

          obvJobRoleService
            .getJobRolesByFacilityId(value)
            .then((jobRoles) => {
              this.jobRoles = jobRoles
                .filter((j) => !j.archived)
                .sort((a, b) => a.name.localeCompare(b.name));
            })
            .catch(() => {
              this.$store.commit("application/SET_ERROR", {
                message: i18n.t("obv-system.error-messages.get-job-roles")
              });
            });

          locationHierarchyService
            .getObservableLocations(value)
            .then((locations) => {
              this.locations = locations
                .filter((l) => l.level === 3)
                .sort((a, b) => a.name.localeCompare(b.name));
            })
            .catch(() => {
              this.$store.commit("application/SET_ERROR", {
                message: i18n.t("obv-system.error-messages.get-locations")
              });
            });

          quickNoteService
            .getByFacilityId(value)
            .then((quickNotes) => {
              this.quickNotes = quickNotes
                .filter((note) => !note.isArchived)
                .sort((a, b) => a.text.localeCompare(b.text));
            })
            .catch(() => {
              this.$store.commit("application/SET_ERROR", {
                message: i18n.t("obv-system.error-messages.get-quick-notes")
              });
            });

          facilityService
            .getObservationSettings(value)
            .then((settings) => {
              this.showFeedback = settings.enableObservationFeedback;
              this.showCustomNotes = settings.allowCustomObsNotes;
            })
            .catch(() => {
              this.$store.commit("application/SET_ERROR", {
                message: i18n.t(
                  "obv-system.error-messages.get-observation-settings"
                )
              });
            });

          this.selectableHHMoments = this.moments.filter(
            (m) =>
              m.hygieneProtocolId ===
              this.$store.state.facilities.byId[this.facilityId]
                .hygieneProtocolId
          );
        }
      }
    },
    selectedObserverId(value) {
      this.getObserverData();

      const resumeableSession = localStorage.getItem(
        `SmartLink_Observation_${this.facilityId}_${value}`
      );

      if (resumeableSession) {
        this.resumeableSession = JSON.parse(atob(resumeableSession));
        this.leftCardTitle = "obv-system.session-in-progress";
        this.leftCardInstructions =
          "obv-system.session-in-progress-instructions";
      } else if (this.resumeableSession.length) {
        this.resumeableSession = [];
        this.leftCardTitle = "obv-system.session-not-started";
        this.leftCardInstructions =
          "obv-system.session-not-started-instructions";
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.divider {
  border-color: gray;
}

.form-section {
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid gray;
}

.list-item:after {
  content: none;
}

.list-item:not(:last-child) {
  border-bottom: 1px solid gray;
}

.list-item:first-child {
  border-top: 1px solid gray;
}
</style>
