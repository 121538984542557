import { get, post, put } from "./apiClient";

export default {
  async list({ facilityId } = {}) {
    if (!facilityId) {
      throw new Error("Facility ID is required");
    }

    return await get(`device?facilityId=${facilityId}`);
  },
  async getDevicesCount(facilityId) {
    return await get(`facility/${facilityId}/deviceTypeCount`);
  },
  async getInactiveDevices() {
    return await get(`device/inactiveDeviceSerialNumbers`);
  },
  async getDevicesForMaintenance(facilityId) {
    return await get(`facility/${facilityId}/devices`);
  },
  async getDeviceFormulationClasses() {
    return await get("/device/formulationClasses");
  },
  async getDeviceModels() {
    return await get("/device/models");
  },
  async getDeviceOrientations() {
    return await get("/device/orientations");
  },
  async getDevicePositions() {
    return await get("/device/positions");
  },
  async getDeviceTypes() {
    return await get("/device/types");
  },
  async getDevice(deviceId) {
    return await get(`device/${deviceId}`);
  },
  async createDevice(device) {
    return await post(`device`, device);
  },
  async editDevice(deviceId, device) {
    return await put(`device/${deviceId}`, device);
  },
  async deactivateDevice(deviceId) {
    await put(`device/${deviceId}/deactivate`);
  },
  async activateDevice(deviceId, facilityId, locationId) {
    await put(`device/${deviceId}/activate`, {
      facilityId,
      locationId
    });
  },
  async createNote(deviceId, note) {
    await post(`device/${deviceId}/notes`, note);
  },
  async unassignDevice(deviceId) {
    await put(`device/${deviceId}/unassign`);
  },
  async assignDevice(deviceId, locationId) {
    await put(`device/${deviceId}/assign`, locationId);
  }
};
