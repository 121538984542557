<template>
  <div class="tw-mb-5">
    <template v-if="loading">
      <p>
        {{
          $t("admin.performance-thresholds.loading-directions", {
            levelName: this.levelName
          })
        }}.
      </p>
      <v-progress-linear indeterminate />
    </template>
    <template v-else>
      <chart-renderer
        class="tw-relative tw-w-3/4 tw-m-auto"
        :indicators="indicatorsToRender"
      />
      <transition-group tag="div" name="thresholds-list">
        <v-row
          class="tw-items-center tw-justify-between tw-my-2"
          v-for="(item, index) in indicators"
          :key="item.id"
        >
          <v-col cols="12" lg="6">
            <ValidationProvider
              :name="
                $tc(
                  'admin.manage-facility.report-settings.indicator',
                  index + 1
                )
              "
              :rules="{ required: true, max: 30 }"
              v-slot="{ label, errors }"
            >
              <v-text-field
                v-model="item.title"
                :error-messages="[...errors, ...item.titleErrors]"
                :label="label"
                :counter="30"
                @input="checkDuplicateIndicatorTitle(item)"
                @blur="trimTitleWhitespace(item)"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="6"
            lg="3"
            class="tw-flex tw-flex-row tw-items-center tw-justify-between"
          >
            <ValidationProvider
              v-if="index !== 0"
              :name="
                $tc(
                  'admin.manage-facility.report-settings.indicator-threshold',
                  index + 1
                )
              "
              :rules="{
                required: requiredIndicators,
                double: 1,
                max_value: 100,
                min_value: 1
              }"
              v-slot="{ label, errors }"
            >
              <v-text-field
                v-model="item.value"
                :error-messages="[...errors, ...item.valueErrors]"
                :label="label"
                suffix="%"
                @blur="roundValue(item)"
                @input="checkDuplicateIndicatorValue(item)"
              />
            </ValidationProvider>
          </v-col>
          <v-col class="tw-flex tw-justify-between" cols="6" lg="3">
            <v-menu top offset-y close-on-click>
              <template v-slot:activator="{ on }">
                <v-btn :color="item.color" dark v-on="on" min-width="36px" />
              </template>
              <ValidationProvider
                :name="
                  $tc(
                    'admin.manage-facility.report-settings.indicator-color',
                    index + 1
                  )
                "
              >
                <v-color-picker
                  v-model="item.color"
                  hide-canvas
                  hide-mode-switch
                  hide-sliders
                  hide-inputs
                  show-swatches
                  :swatches="swatches"
                />
              </ValidationProvider>
            </v-menu>
            <v-tooltip v-if="index !== 0" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteIndicator(item.id)"
                >
                  <v-icon large>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("performance-indicators.remove") }} </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </transition-group>
      <v-row class="tw-mt-1 tw-justify-self-start tw-pb-10">
        <v-btn
          v-show="indicators.length < 7"
          class="tw-mr-5"
          color="primary"
          @click="addIndicator()"
        >
          {{ $t("admin.manage-facility.report-settings.add-indicator") }}
        </v-btn>

        <v-btn
          v-if="indicators.length === 0"
          class="tw-mr-5"
          color="background2"
          @click="setDefaultIndicators(true)"
        >
          {{ $t("admin.manage-facility.report-settings.default-indicators") }}
        </v-btn>

        <v-btn
          v-if="resetState.length && indicatorsChanged"
          class="tw-mr-5"
          color="background2"
          @click="resetPerformanceIndicators(resetState)"
        >
          {{ $t("admin.manage-facility.report-settings.reset-thresholds") }}
        </v-btn>
      </v-row>
    </template>
  </div>
</template>

<script>
import performanceIndicatorsMixin from "@/views/admin/components/performanceIndicators/mixin";
import performanceThresholdService from "@/api/performanceThresholdService";
import roundingUtil from "@/utils/roundingUtil";
import { uuidv4 } from "@/utils/generators";
import { cloneDeep } from "lodash";
import i18n from "@/plugins/i18n";

export default {
  mixins: [performanceIndicatorsMixin],
  props: {
    locationId: {
      type: Number
    },
    levelName: {
      type: String,
      required: true
    }
  },
  async mounted() {
    this.loading = true;

    try {
      let indicators;
      indicators = await performanceThresholdService.get(this.locationId);

      if (indicators.length) {
        indicators = indicators.map((i) => {
          return {
            ...i,
            value: i.value ? roundingUtil(i.value) : i.value,
            id: uuidv4(),
            valueErrors: [],
            titleErrors: []
          };
        });

        this.indicators = indicators;
        this.sortIndicators();
      } else {
        this.setDefaultIndicators(false);
      }

      this.resetState = cloneDeep(this.indicators);
    } catch {
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t(
          "admin.performance-thresholds.error-messages.get-thresholds",
          {
            levelName: this.levelName
          }
        )
      });
    } finally {
      this.loading = false;
    }
  },
  computed: {
    invalidIndicators() {
      if (this.indicators.length > 1) {
        if (this.indicators.some((i) => isNaN(parseFloat(i.value)))) {
          return true;
        }

        const valueSeen = new Set();
        if (
          this.indicators.some(
            (i) => valueSeen.size === valueSeen.add(i.value).size
          )
        ) {
          return true;
        }

        const titleSeen = new Set();
        if (
          this.indicators.some(
            (i) => titleSeen.size === titleSeen.add(i.title.toLowerCase()).size
          )
        ) {
          return true;
        }
      }

      return this.indicators.some((i) => i.value === "");
    },
    requiredIndicators() {
      return this.indicators.some((i) => i.value > 0) || this.indicatorsChanged;
    },
    indicatorsToRender() {
      return this.invalidIndicators ? [] : this.indicators;
    }
  },
  methods: {
    setDefaultIndicators(triggerAppInsights) {
      const commonIndicator = {
        titleErrors: [],
        valueErrors: []
      };

      this.indicators = [
        {
          ...commonIndicator,
          id: uuidv4(),
          title: i18n.t("reports.below-baseline"),
          value: 0,
          color: this.colors[0].value
        },
        {
          ...commonIndicator,
          id: uuidv4(),
          title: i18n.t("reports.baseline"),
          value: "",
          color: this.colors[1].value
        },
        {
          ...commonIndicator,
          id: uuidv4(),
          title: i18n.t("reports.goal"),
          value: "",
          color: this.colors[2].value
        }
      ];

      if (triggerAppInsights) {
        this.$appInsights?.trackEvent({
          name: `Performance Thresholds Set Default Indicators`
        });
      }
    },
    addIndicator() {
      const indicator = {
        id: uuidv4(),
        title: "",
        value: "",
        titleErrors: [],
        valueErrors: []
      };

      if (this.indicators.length === 0) {
        this.indicators.push({
          ...indicator,
          ...{
            id: uuidv4(),
            value: 0,
            color: this.colors[0].value
          }
        });
      }

      this.indicators.push({
        ...indicator,
        ...{
          color: this.firstAvailableSwatch
        }
      });

      this.$appInsights?.trackEvent({
        name: `Performance Thresholds Add Indicator`
      });
    },
    sortIndicators() {
      if (this.invalidIndicators) {
        return;
      }

      this.indicators.sort((a, b) =>
        parseFloat(a.value) > parseFloat(b.value) ? 1 : -1
      );
    },
    roundValue(item) {
      item.value = roundingUtil(item.value);
      this.sortIndicators();
    },
    async submitPerformanceIndicators() {
      if (!this.indicatorsChanged) {
        return true;
      }

      try {
        await performanceThresholdService.put(this.locationId, this.indicators);

        this.resetState = cloneDeep(this.indicators);

        this.$appInsights?.trackEvent({
          name: `Performance Thresholds Form Success`
        });

        return true;
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Performance Thresholds Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.performance-thresholds.error-messages.update-thresholds",
            {
              levelName: this.levelName
            }
          )
        });

        return false;
      }
    }
  },
  watch: {
    indicators(value) {
      if (value.length === 1) {
        this.indicators = [];
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.thresholds-list-move {
  transition: all 1000ms ease-in-out 100ms;
}
</style>
