var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("admin.customer-list.header")))]),_c('v-data-table',{attrs:{"items":_vm.customers,"items-per-page":-1,"headers":_vm.headers,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('admin.customer-list.loading'),"no-data-text":_vm.$t('admin.customer-list.empty'),"multi-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-sticky tw-top-0 tw-bg-white",staticStyle:{"z-index":"2"}},[_c('v-text-field',{staticClass:"tw-mr-4",attrs:{"label":`${_vm.$t('ui.input-select.search')}`,"clearable":"","prepend-inner-icon":"mdi-magnify","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.checkPermission(_vm.PERMISSIONS.addCustomers))?_c('v-btn',{staticClass:"tw-max-w-xs",attrs:{"primary":"","color":"primary","to":{
              name: _vm.ROUTE_NAMES.createCustomer
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.customer-list.add-customer"))+" ")]):_vm._e()],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('tr',{key:item.id},[_c('td',[_c('router-link',{staticClass:"tw-flex tw-items-center tw-justify-items-center",attrs:{"to":{
                name: _vm.ROUTE_NAMES.customerDashboard,
                query: { customerId: item.id }
              }}},[(item.logoImage)?_c('img',{staticClass:"customer-logo",attrs:{"src":item.logoImage}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))])])],1),_c('td',[_c('p',{staticClass:"multi-address"},[_vm._v(_vm._s(item.address1))]),(item.address2)?_c('p',{staticClass:"multi-address"},[_vm._v(" "+_vm._s(item.address2)+" ")]):_vm._e(),(item.address3)?_c('p',{staticClass:"multi-address"},[_vm._v(" "+_vm._s(item.address3)+" ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(item.city))]),_c('td',[_vm._v(" "+_vm._s(item.stateName)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.countryName)+" ")]),_c('td',[(_vm.checkPermission(_vm.PERMISSIONS.activateDeactivateCustomers))?_c('v-switch',{attrs:{"input-value":item.isActive,"loading":item.isLoading,"readonly":""},on:{"click":function($event){item.isActive
                  ? _vm.setModal(item.id)
                  : _vm.toggleActiveCustomer(item.id)}}}):_c('v-switch',{attrs:{"input-value":item.isActive,"disabled":true,"readonly":""}})],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","primary":"","to":{
                    name: _vm.ROUTE_NAMES.editCustomer,
                    query: { customerId: item.id }
                  }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("admin.manage-customer.edit"))+" ")])])],1)])]}}])}),_c('v-dialog',{attrs:{"max-width":"560"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"modal"},[_c('v-container',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("admin.manage-customer.deactivate-modal.modal-title"))+" ")]),_c('v-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$t(`admin.manage-customer.deactivate-modal.modal-body`, {
                  customerName: _vm.customerToDeactivate.name
                })
              )}})]),_c('div',{staticClass:"tw-m-4"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.modalTextMatches
                  ? _vm.toggleActiveCustomer(_vm.customerToDeactivate.id)
                  : null}}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                  'admin.manage-customer.deactivate-modal.please-type-yes'
                )}`},model:{value:(_vm.modalText),callback:function ($$v) {_vm.modalText=$$v},expression:"modalText"}})],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"tw-flex tw-flex-row tw-justify-end"},[_c('v-btn',{staticClass:"tw-mr-5",attrs:{"text":""},on:{"click":_vm.resetModal}},[_vm._v(" "+_vm._s(_vm.$t("ui.actions.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"error","disabled":!_vm.modalTextMatches,"loading":_vm.modalButtonLoading},on:{"click":function($event){return _vm.toggleActiveCustomer(_vm.customerToDeactivate.id)}}},[_vm._v(" "+_vm._s(_vm.$t("admin.manage-customer.deactivate-modal.confirm-button"))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }