<template>
  <div>
    <facility-selection-bar class="tw-my-8" />
    <v-data-table
      :items="items"
      :headers="headers"
      :search="search"
      :loading="loading"
      :loading-text="$t('downloads.loading-downloads')"
      :no-data-text="$t('downloads.no-downloads')"
    >
      <template v-slot:top> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.download="{ item }">
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td
            @click="item.isDownloadable ? downloadExport(item.token) : null"
            :class="{ 'download-clickable': item.isDownloadable }"
          >
            {{ item.name }}
          </td>
          <td>
            {{
              new Date(item.requestTimestamp).toLocaleString(
                language,
                localeStringOptions
              )
            }}
          </td>
          <td>
            {{
              item.expirationDate
                ? new Date(item.expirationDate).toLocaleString(
                    language,
                    localeStringOptions
                  )
                : ""
            }}
          </td>
          <td>{{ item.status }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FacilitySelectionBar from "@/components/layout/FacilitySelectionBar.vue";
import downloadsService from "@/api/downloadsService";
import i18n from "@/plugins/i18n";
import { mapState } from "vuex";
import find from "lodash/find";

export default {
  components: {
    FacilitySelectionBar
  },
  data: () => ({
    loading: false,
    search: "",
    items: [],
    localeStringOptions: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit"
    }
  }),
  computed: {
    ...mapState("customers", ["facilityId"]),
    ...mapState("preferences", ["language"]),
    headers() {
      const headers = [
        {
          text: i18n.t("downloads.name"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("downloads.request-timestamp"),
          value: "requestTimestamp",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("downloads.expiration-date"),
          value: "expirationDate",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("downloads.status"),
          value: "status",
          sortable: true,
          class: "tw-align-text-top"
        }
      ];

      return headers;
    }
  },
  methods: {
    downloadExport(token) {
      downloadsService.downloadExport(token);
    },
    async getDownloads() {
      this.loading = true;
      this.items = await downloadsService.listDownloads(this.facilityId);
      this.loading = false;
    }
  },
  watch: {
    async facilityId() {
      this.getDownloads();
    },
    items(value) {
      if (find(value, (i) => i.status === "Pending")) {
        setTimeout(async () => {
          this.getDownloads();
        }, 10000);
      }
    }
  }
};
</script>

<style scoped lang="postcss">
.download-clickable {
  cursor: pointer;
  color: #007bb6;
  text-decoration: underline;
}
</style>
