<template>
  <div>
    <ValidationObserver ref="shiftsForm">
      <v-form @submit.prevent="editing && isChanged ? saveShifts() : null">
        <v-data-table
          class="shifts-data-table small-headers"
          :headers="headers"
          @update:page="setScrollPosition"
          :items="shifts"
          :loading="loading"
          :loading-text="$t('admin.facility-dashboard.shifts-widget.loading')"
          :no-data-text="$t('admin.facility-dashboard.shifts-widget.empty')"
          :search="search"
          :items-per-page="-1"
        >
          <template
            v-if="
              checkPrivileges(facilityPrivileges, PRIVILEGES.editFacilities)
            "
            v-slot:top
          >
            <div
              class="
                tw-flex tw-justify-between tw-items-center tw-top-0 tw-bg-white
              "
              style="z-index: 2"
            >
              <v-btn class="tw-my-5" color="secondary" @click="addShift()">
                {{ $t("admin.facility-dashboard.shifts-widget.add-shift") }}
              </v-btn>
              <v-btn
                v-if="!editing"
                v-show="shifts.length > 0"
                color="primary"
                @click="toggleEdit()"
              >
                {{ $t("admin.facility-dashboard.shifts-widget.edit") }}
              </v-btn>
              <template v-else>
                <div>
                  <v-btn class="tw-my-5 tw-mr-5" @click="cancelEdit()">
                    {{ $t("ui.actions.cancel") }}
                  </v-btn>
                  <v-btn
                    class="tw-my-5"
                    color="primary"
                    :disabled="!isChanged"
                    :loading="waiting"
                    type="submit"
                  >
                    {{ $t("ui.actions.save") }}
                  </v-btn>
                </div>
              </template>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item, index }">
            <ValidationProvider
              v-if="editing"
              :name="$t('admin.facility-dashboard.shifts-widget.shift-name')"
              :rules="{ required: true, max: 25 }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="item.name"
                :label="$t('admin.facility-dashboard.shifts-widget.shift-name')"
                :error-messages="[...errors, ...item.textErrors]"
                @input="checkDuplicateShiftName(item, index)"
                :ref="`name${index}`"
              />
            </ValidationProvider>
            <td v-else>{{ item.name }}</td>
          </template>
          <template v-slot:[`item.startTime`]="{ item, index }">
            <div v-if="editing">
              <v-menu
                v-model="item.editingStartTime"
                :return-value.sync="item.startTime"
                :close-on-content-click="false"
                :ref="`startTime${index}`"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="convertToDisplayValue(item.startTime)"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-clock-time-four-outline"
                  />
                </template>
                <v-time-picker
                  v-if="item.editingStartTime"
                  v-model="item.startTime"
                  :allowed-minutes="[0, 30]"
                  @click:minute="saveTime(index, item.startTime, true)"
                >
                  <div class="tw-flex tw-justify-between tw-w-full">
                    <v-btn
                      text
                      color="primary"
                      @click="item.editingStartTime = false"
                    >
                      {{ $t("ui.actions.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveTime(index, item.startTime, true)"
                    >
                      {{ $t("ui.actions.save") }}
                    </v-btn>
                  </div>
                </v-time-picker>
              </v-menu>
            </div>
            <td v-else>{{ convertToDisplayValue(item.startTime) }}</td>
          </template>
          <template v-slot:[`item.endTime`]="{ item, index }">
            <div v-if="editing">
              <v-menu
                v-model="item.editingEndTime"
                :return-value.sync="item.endTime"
                :close-on-content-click="false"
                :ref="`endTime${index}`"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="convertToDisplayValue(item.endTime)"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-clock-time-four-outline"
                  />
                </template>
                <v-time-picker
                  v-if="item.editingEndTime"
                  v-model="item.endTime"
                  :allowed-minutes="[0, 30]"
                  @click:minute="saveTime(index, item.endTime, false)"
                >
                  <div class="tw-flex tw-justify-between tw-w-full">
                    <v-btn
                      text
                      color="primary"
                      @click="item.editingEndTime = false"
                    >
                      {{ $t("ui.actions.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="saveTime(index, item.endTime, false)"
                    >
                      {{ $t("ui.actions.save") }}
                    </v-btn>
                  </div>
                </v-time-picker>
              </v-menu>
            </div>
            <td v-else>{{ convertToDisplayValue(item.endTime) }}</td>
          </template>
          <template v-slot:[`item.delete`]="{ item, index }">
            <div class="tw-flex tw-flex-row tw-items-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="setModal(item.name, index)"
                  >
                    <v-icon> mdi-trash-can </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("admin.facility-dashboard.shifts-widget.delete") }}
                </span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-form>
    </ValidationObserver>
    <v-dialog v-model="shiftToDeleteModal.display" max-width="400px">
      <v-card>
        <v-container>
          <v-card-title class="text-h5">
            {{ $t("admin.facility-dashboard.shifts-widget.modal-title") }}
          </v-card-title>
          <v-card-text
            ><div
              v-html="
                $t(`admin.facility-dashboard.shifts-widget.modal-body`, {
                  shiftName: shiftToDeleteModal.name
                })
              "
            ></div
          ></v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="tw-flex tw-flex-row tw-justify-end">
                <v-btn
                  text
                  @click="shiftToDeleteModal.display = null"
                  class="tw-mr-5"
                >
                  {{ $t("ui.actions.cancel") }}
                </v-btn>
                <v-btn :loading="waiting" color="error" @click="deleteShift()">
                  {{
                    $t(
                      "admin.facility-dashboard.shifts-widget.yes-delete-shift"
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import shiftsService from "@/api/shiftsService";
import i18n from "@/plugins/i18n";
import { mapState } from "vuex";
import PRIVILEGES, { checkPrivileges } from "@/types/privileges";
import { ROUTE_NAMES } from "@/types/routeNames";
import {
  convertTimeSpanToDate,
  convertDateToTimeString
} from "@/utils/dateUtils";
import { cloneDeep, isEqual } from "lodash";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    facilityId: { type: Number },
    facilityPrivileges: {
      type: Array,
      default: () => []
    },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    },
    clearLocalizedWidgetMessage: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      PRIVILEGES,
      ROUTE_NAMES,
      loading: false,
      editing: false,
      waiting: false,
      shifts: [],
      originalShifts: [],
      shiftsOverlap: false,
      search: "",
      shiftToDeleteModal: {
        display: false
      },
      headers: [
        {
          text: i18n.t("admin.facility-dashboard.shifts-widget.shift-name"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.facility-dashboard.shifts-widget.start-time"),
          value: "startTime",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.facility-dashboard.shifts-widget.end-time"),
          value: "endTime",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("ui.actions.delete"),
          value: "delete",
          sortable: false,
          class: "tw-align-text-top"
        }
      ]
    };
  },
  async created() {
    try {
      this.loading = true;
      this.setupShifts(await shiftsService.getShifts(this.facilityId));
      this.loading = false;
    } catch (err) {
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t(
          "admin.facility-dashboard.shifts-widget.error-messages.load-shifts"
        )
      });
      this.loading = false;
    }
  },
  computed: {
    ...mapState("preferences", ["language"]),
    isChanged() {
      return !isEqual(this.shifts, this.originalShifts);
    }
  },
  methods: {
    checkPrivileges,
    async setupShifts(shifts) {
      const mappedShifts = shifts.map((shift) => {
        shift.editingStartTime = false;
        shift.editingEndTime = false;
        shift.textErrors = [];

        return shift;
      });

      this.shifts = this.sortShiftsByStartTime(mappedShifts);
    },
    setScrollPosition() {
      document.querySelector(
        "div.shifts-data-table div.v-data-table__wrapper"
      ).scrollTop = 0;
    },
    async addShift() {
      if (!this.editing) {
        this.toggleEdit();
      }

      this.shifts.unshift({
        isActive: true,
        name: "",
        startTime: "0:00",
        endTime: "0:00",
        defaultStartTime: true,
        defaultEndTime: true,
        textErrors: []
      });
      await this.$nextTick();
      this.$refs.name0?.focus();
    },
    setModal(name, index) {
      this.shiftToDeleteModal = {
        display: true,
        name,
        index
      };
    },
    async deleteShift() {
      this.waiting = true;
      const shiftToDelete = this.shifts[this.shiftToDeleteModal.index];

      // If the shift has no ID that means that is a new shift and we don't want to hit the API because it doesn't exist on the server yet.
      if (shiftToDelete.id) {
        try {
          await shiftsService.deleteShift(shiftToDelete.id);

          this.setLocalizedWidgetMessage({
            message: i18n.t(
              "admin.facility-dashboard.shifts-widget.success-messages.delete-shift"
            )
          });
        } catch {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t(
              "admin.facility-dashboard.shifts-widget.error-messages.delete-shift"
            )
          });

          return;
        }
      }

      this.waiting = false;
      this.shifts.splice(this.shiftToDeleteModal.index, 1);
      this.shiftToDeleteModal = {
        display: false
      };
    },
    toggleEdit() {
      this.editing = !this.editing;

      if (this.editing) {
        this.originalShifts = cloneDeep(this.shifts);
      }
    },
    cancelEdit() {
      this.shifts = this.originalShifts;
      this.originalShifts = [];
      this.toggleEdit();
      this.clearLocalizedWidgetMessage();
      this.$refs.shiftsForm.reset();
    },
    async saveShifts() {
      this.waiting = true;

      const valid = await this.$refs.shiftsForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);
        this.waiting = false;
        return;
      }

      this.checkShiftsOverlap();
      if (this.shiftsOverlap) {
        this.waiting = false;
        return;
      }

      try {
        this.setupShifts(
          await shiftsService.updateShifts(this.facilityId, this.shifts)
        );

        this.$appInsights?.trackEvent({
          name: `Update Shifts Form Success`
        });

        this.setLocalizedWidgetMessage({
          message: i18n.t(
            "admin.facility-dashboard.shifts-widget.success-messages.save-shifts"
          )
        });
      } catch {
        this.$appInsights?.trackEvent({
          name: `Update Shifts Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.facility-dashboard.shifts-widget.error-messages.save-shifts"
          )
        });

        this.waiting = false;
        this.cancelEdit();
        return;
      }

      this.waiting = false;
      this.editing = false;
    },
    convertToDisplayValue(timeStamp) {
      const timeStampAsDate = convertTimeSpanToDate(timeStamp);
      return convertDateToTimeString(timeStampAsDate, this.language);
    },
    saveTime(index, time, isStartTime) {
      const ref = isStartTime ? `startTime${index}` : `endTime${index}`;
      this.$refs[ref].save(time);

      const isNewShift = !this.shifts[index].id;
      if (isNewShift) {
        if (isStartTime) {
          this.shifts[index].defaultStartTime = false;
        } else {
          this.shifts[index].defaultEndTime = false;
        }

        if (
          this.shifts[index].defaultStartTime ||
          this.shifts[index].defaultEndTime
        ) {
          return;
        }
      }

      this.checkShiftsOverlap();
    },
    sortShiftsByStartTime(shifts) {
      return [...shifts].sort((a, b) => {
        return (
          convertTimeSpanToDate(a.startTime) -
          convertTimeSpanToDate(b.startTime)
        );
      });
    },
    checkShiftsOverlap() {
      let shiftTimes = this.sortShiftsByStartTime(this.shifts);

      shiftTimes = shiftTimes.map((shift) => {
        return {
          startTime: convertTimeSpanToDate(shift.startTime),
          endTime: convertTimeSpanToDate(shift.endTime)
        };
      });

      const earliestStartTime = shiftTimes[0].startTime;
      let endPrior = new Date(0);
      let shiftsOverlap = false;

      shiftTimes.forEach((shift) => {
        if (shift.startTime.getTime() === shift.endTime.getTime()) {
          shiftsOverlap = true;
        }

        if (shift.startTime < endPrior) {
          shiftsOverlap = true;
        }

        const shiftOverlaps24Hours =
          shift.startTime.getHours() > shift.endTime.getHours();
        if (shiftOverlaps24Hours && shift.endTime > earliestStartTime) {
          shiftsOverlap = true;
        }

        endPrior = shift.endTime;
      });

      if (shiftsOverlap) {
        this.setLocalizedWidgetMessage({
          message: i18n.t(
            "admin.facility-dashboard.shifts-widget.error-messages.overlapping-shifts"
          ),
          showError: true
        });
      } else {
        this.clearLocalizedWidgetMessage();
      }

      this.shiftsOverlap = shiftsOverlap;
    },
    checkDuplicateShiftName(item, itemIndex) {
      const itemNewNameExistsOnOtherShift = this.shifts.find(
        (shift, index) =>
          shift.name.toLowerCase() === item.name.toLowerCase() &&
          index !== itemIndex
      );

      if (item.name.length && itemNewNameExistsOnOtherShift) {
        item.textErrors = [
          i18n.t(
            "admin.facility-dashboard.shifts-widget.error-messages.duplicate"
          )
        ];
      } else {
        item.textErrors = [];
      }
    }
  }
};
</script>
