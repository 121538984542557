<template>
  <v-list-item>
    <v-list-item-content>
      <v-text-field
        data-name="filter-popup-search"
        :label="$t('ui.input-select.search')"
        :value="value"
        @input="(e) => $emit('input', e)"
        filled
        hide-details
        dense
        append-icon="mdi-magnify"
        ref="searchInput"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    }
  }
};
</script>
