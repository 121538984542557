import { REPLACEMENT_DATES } from "@/types/replacementDates";
import i18n from "@/plugins/i18n";

export default {
  async list() {
    const replacementDateValues = [
      { id: REPLACEMENT_DATES.ALL, text: i18n.t("timeframes.all") },
      {
        id: REPLACEMENT_DATES.TODAY,
        text: i18n.t("timeframes.today")
      },
      {
        id: REPLACEMENT_DATES.TOMORROW,
        text: i18n.t("timeframes.tomorrow")
      },
      {
        id: REPLACEMENT_DATES.NEXT_7_DAYS,
        text: i18n.t("timeframes.next-7-days")
      }
    ];
    return new Promise((res) => res(replacementDateValues));
  }
};
