<template>
  <ValidationObserver ref="fullSlideShowData">
    <v-form @submit.prevent="validateAndSubmitSlideshow">
      <h3>
        {{ $t("slideshows.slideshow-basics") }}
      </h3>
      <v-row>
        <v-col cols="8">
          <ValidationProvider
            vid="slideshowName"
            :name="$t('slideshows.slideshow-name')"
            :rules="{ required: true, max: nameMaxLength }"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="name"
              :error-messages="errors"
              :counter="nameMaxLength"
              :label="$t('slideshows.slideshow-name')"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="4">
          <ValidationProvider
            :name="$t('slideshows.interval')"
            :rules="{
              required: true,
              min_value: intervalMin,
              max_value: intervalMax
            }"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="interval"
              :error-messages="errors"
              type="number"
              :label="$t('slideshows.interval')"
              :hint="$t('slideshows.interval-hint')"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <h3>
        {{ $t("slideshows.customize-slide-titles") }}
      </h3>
      <v-row align="center">
        <v-col cols="6">
          <strong>{{ $t("slideshows.slide-description") }}</strong>
        </v-col>
        <v-col cols="6">
          <strong>{{ $t("slideshows.custom-slide-title") }}</strong>
        </v-col>
      </v-row>
      <v-row
        v-for="(slide, index) in selectedSlides"
        :key="`form${slide.id}`"
        align="center"
        style="border-top: 1px solid #000"
      >
        <v-col cols="6">
          {{ slide.name }}
        </v-col>
        <v-col cols="6">
          <ValidationProvider
            :name="$t('slideshows.custom-slide-title')"
            :rules="{ max: nameMaxLength }"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="customSlideTitles[index]"
              :error-messages="errors"
              :counter="nameMaxLength"
              :placeholder="slide.name"
              :hint="$t('slideshows.custom-slide-name-hint')"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
import slideshowService from "@/api/slideshowsService";
import { isEmpty } from "lodash";
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    facilityId: {
      type: Number
    },
    slideshowId: {
      type: Number
    },
    slideshowResponse: {
      type: Object
    },
    selectedSlides: {
      type: Array
    },
    savingSlideshow: {
      type: Boolean,
      default: false
    },
    customizationStepIsActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const intervalMin = 30;

    return {
      name: "",
      nameMaxLength: 100,
      intervalMin: intervalMin,
      interval: intervalMin,
      customSlideTitles: []
    };
  },
  computed: {
    ...mapState("user", ["idleTimeout"]),
    intervalMax() {
      return Math.floor(this.idleTimeout / 1000) - 10;
    }
  },
  methods: {
    async validateAndSubmitSlideshow() {
      const valid = await this.$refs.fullSlideShowData.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);
        this.$emit("unsuccessful-save");

        this.$appInsights?.trackEvent({
          name: `Save Slideshow Form Error`
        });
        return;
      }

      this.sendSlideshowData();
    },
    async sendSlideshowData() {
      const slidesWithCustomNames = this.selectedSlides.map((slide, index) => {
        return {
          id: slide.id,
          name: slide.name,
          customSlideTitle: this.customSlideTitles[index] || slide.name,
          order: index
        };
      });

      try {
        const response = await slideshowService.saveSlideshow({
          id: this.slideshowId,
          name: this.name,
          interval: this.interval,
          facilityId: this.facilityId,
          isActive: true,
          reportSlides: slidesWithCustomNames
        });

        this.handleSlideshowResponse(response);
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Save Slideshow Server Error`
        });
        this.$emit("unsuccessful-save");
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("slideshows.error-messages.saving-slideshow")
        });
      }
    },
    handleSlideshowResponse(response) {
      if (response === 409) {
        this.$appInsights?.trackEvent({
          name: `Save Slideshow Server Error`
        });
        this.$emit("unsuccessful-save");
        this.$refs.fullSlideShowData.setErrors({
          slideshowName: [
            i18n.t("slideshows.error-messages.duplicate-slideshow-name")
          ]
        });

        return;
      }

      this.$appInsights?.trackEvent({
        name: `Successful Save Slideshow`
      });

      this.$store.commit(
        "application/SET_SUCCESS_MESSAGE",
        this.slideshowId
          ? i18n.t("slideshows.success-messages.update-slideshow")
          : i18n.t("slideshows.success-messages.create-slideshow")
      );

      this.$router.push({
        name: "slideshowsList"
      });
    }
  },
  watch: {
    selectedSlides() {
      this.customSlideTitles = [];
    },
    slideshowResponse(value) {
      this.name = value.name;
      this.interval = value.interval;
    },
    customizationStepIsActive(value) {
      if (!value || isEmpty(this.slideshowResponse)) {
        return;
      }

      this.customSlideTitles = this.selectedSlides.map((slide) => {
        const filteredSlide = this.slideshowResponse.slides
          .filter((selectedSlide) => selectedSlide.id === slide.id)
          .pop();

        return filteredSlide ? filteredSlide.name : "";
      });
    },
    savingSlideshow(value) {
      if (value) {
        this.validateAndSubmitSlideshow();
      }
    }
  }
};
</script>
