var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('confirmation-dialog',{attrs:{"confirmationDialogData":_vm.confirmationDialogData},on:{"action-confirmed":_vm.deleteAlertSubscription,"toggle-confirmation-dialog":_vm.toggleConfirmationDialog}}),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading-text":_vm.$t('alert-subscriptions.gathering-alert-subscription-data'),"no-data-text":_vm.$t('alert-subscriptions.no-alert-subscription-in-facility')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-sticky tw-top-0 tw-bg-white",staticStyle:{"z-index":"2"}},[_c('v-text-field',{staticClass:"tw-mr-4",attrs:{"label":`${_vm.$t('ui.input-select.search')}`,"clearable":"","prepend-inner-icon":"mdi-magnify"},on:{"focus":function($event){return _vm.trackSearchAnalytics()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.isActive",fn:function({ item }){return [_c('v-switch',{on:{"change":function($event){return _vm.toggleAlertStatus(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.edit",fn:function({ item }){return [_c('v-btn',{attrs:{"to":{
            name: 'editAlertSubscription',
            query: {
              alertSubscriptionId: item.id,
              isInstantAlert: item.isAlarm
            }
          },"icon":""}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.delete",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleConfirmationDialog(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }