<template>
  <v-sparkline
    v-if="events.length"
    :value="events"
    line-width="16"
    padding="16"
    height="55"
    width="400"
    style="width: 60px; height: 8.25px"
    :color="
      item.indicator && item.indicator.color ? item.indicator.color : 'black'
    "
  />
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    events() {
      return this.value.map((v) =>
        Number.parseInt(v.performance === null ? 0 : v.performance)
      );
    }
  }
};
</script>
