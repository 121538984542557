<script>
import Chart from "chart.js";
import { generateChart } from "vue-chartjs";
import "chartjs-plugin-annotation";
import "chartjs-plugin-colorschemes";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { mapState } from "vuex";

export default {
  extends: generateChart("chartjs-chart", "__ignored__"),
  props: {
    chartData: Object,
    default: () => {}
  },
  computed: {
    ...mapState("reports", ["isSlideshow", "graphActions"]),
    displayDataLabels() {
      return this.graphActions.displayDataLabels;
    }
  },
  watch: {
    chartData() {
      this.update();
    },
    displayDataLabels() {
      this.update();
    }
  },
  mounted() {
    Chart.plugins.unregister(ChartDataLabels); //the datalabels is registered globally soooooo if we want to have it toggleable... we have to unregister it 🤦‍♂️
    this.addPlugin({
      id: "vertCompareLine",
      beforeDraw(chart) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.tooltip._active[0],
            ctx = chart.ctx,
            y_axis = findYAxes(chart),
            x = activePoint.tooltipPosition().x,
            topY = y_axis.top,
            bottomY = y_axis.bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "#aaa";
          ctx.stroke();
          ctx.restore();
        }
      }
    });
    if (this.chartData.options && this.chartData.options.legend.display) {
      this.addPlugin({
        id: "paddingBelowLegends",
        beforeInit: function (chart) {
          chart.legend.afterFit = function () {
            this.height = this.height + 25;
          };
        }
      });
    }
    this.update();
  },
  methods: {
    update() {
      const plugins = [...this.$data._plugins];

      if (this.displayDataLabels) {
        plugins.push(ChartDataLabels);
      }
      // This method is copied from generateChart so we can replace the chartType literal with value from chartType prop
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }
      this.$data._chart = new Chart(this.$refs.canvas.getContext("2d"), {
        type: this.chartData.type,
        data: this.chartData.data,
        options: this.chartData.options,
        plugins: plugins
      });
    }
  }
};

const findYAxes = (chart) => {
  const name = Object.keys(chart.scales).find((key) => key.includes("y-axis"));
  if (name) {
    return chart.scales[name];
  } else {
    return undefined;
  }
};
</script>
