<template>
  <div>
    <ValidationObserver ref="manageUnitThresholds" v-slot="{ pristine }">
      <v-form @submit.prevent="validateAndSubmit">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <performance-thresholds
                ref="performanceThresholds"
                :locationId="locationId"
                :levelName="levelName"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-row dense class="tw-mb-3 tw-justify-end tw-float-right">
          <v-col cols="12">
            <v-btn
              class="tw-mr-5 tw-w-24"
              :disabled="
                pristine &&
                ($refs.performanceBands
                  ? !$refs.performanceBands.indicatorsChanged
                  : true) &&
                ($refs.performanceThresholds
                  ? !$refs.performanceThresholds.indicatorsChanged
                  : true)
              "
              :loading="saving"
              color="primary"
              type="submit"
            >
              {{ $t("ui.actions.save") }}
            </v-btn>
            <v-btn
              class="tw-mr-5 tw-w-24"
              color="background2"
              @click="closeManageUnitView"
            >
              {{ $t("ui.actions.cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import PerformanceThresholds from "@/views/admin/components/performanceIndicators/PerformanceThresholds.vue";
import { scrollToFirstError } from "@/utils/scrollToFirstError";
import { PRODUCTS } from "@/types/products";
import { ROUTE_NAMES } from "@/types/routeNames";
import i18n from "@/plugins/i18n";

export default {
  components: {
    PerformanceThresholds
  },
  props: {
    locationId: { type: Number },
    levelName: { type: String },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      PRODUCTS,
      ROUTE_NAMES,
      loading: true,
      saving: false
    };
  },
  created() {
    this.$appInsights?.trackEvent({
      name: `Manage Unit Thresholds View Loaded`
    });
  },
  methods: {
    async validateAndSubmit() {
      const valid = await this.$refs.manageUnitThresholds.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);
        return;
      }

      this.saving = true;
      const response =
        await this.$refs.performanceThresholds.submitPerformanceIndicators();

      if (response) {
        this.setLocalizedWidgetMessage({
          message: i18n.t("admin.performance-thresholds.success-message", {
            levelName: this.levelName
          })
        });

        this.closeManageUnitView();
      }

      this.saving = false;
    },
    closeManageUnitView() {
      this.$emit("close-manage-unit-view");
    }
  }
};
</script>
