<template>
  <div>
    <v-dialog
      :transition="
        $vuetify.breakpoint.mdAndUp ? undefined : 'dialog-bottom-transition'
      "
      :value="value"
      @click:outside="$emit('save')"
      @keydown.esc="$emit('cancel')"
      :max-width="filterWidth"
      inset
      persistent
    >
      <v-card>
        <slot></slot>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "responsive-popup",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filterWidth() {
      if (this.$vuetify.breakpoint.xl) {
        return "30%";
      }
      if (this.$vuetify.breakpoint.lg) {
        return "40%";
      }
      if (this.$vuetify.breakpoint.md) {
        return "50%";
      } else {
        return undefined;
      }
    }
  }
};
</script>
