export function skipAuthentication(to) {
  let skip = undefined;
  for (const match of to.matched) {
    if (match.meta?.skipAuthentication !== undefined) {
      skip = match.meta.skipAuthentication;
      if (skip === false) {
        break;
      }
    }
  }
  return skip ?? false;
}
