import { groupBy, merge } from "lodash";
import {
  ChartScaleLinear,
  ChartScaleCategory,
  ChartScaleTimeSeries
} from "./chartScales";
import { buildChartjsChart as buildChart } from "./chartjsBuilder.js";
import colors from "@/colors/colors";
import i18n from "@/plugins/i18n";

export const GraphDataTypes = {
  NUMBER: "number",
  ROUNDED: "rounded",
  FIXED: "fixed",
  TIME: "time"
};

export const chartColorChoices = [
  [colors.primary, "#005983"],
  ["#63B5CF", "#3692B0"],
  ["#7D5580", "#503752"],
  ["#00BDFD", "#007FAA"],
  ["#F4C882", "#BB9A65"]
];

const DEFAULT_DATASET_CONFIG = {
  bar: {
    borderWidth: 2,
    barPercentage: 0.5
  },
  line: {
    fill: "false",
    borderColor: colors.primary,
    pointBackgroundColor: colors.tertiary,
    pointBorderColor: colors.tertiary,
    lineTension: 0
  }
};

export default class ChartBuilder {
  datasets = [];
  labels = [];
  scales = {};
  isSlideshow = false;
  isPrint = false;
  displayLegend = true;
  customLegendGenerator = false;
  backgroundColorPickerIndex = 0;

  constructor({
    isSlideshow = false,
    isPrint = false,
    displayLegend = true,
    customLegendGenerator = false
  } = {}) {
    this.isSlideshow = isSlideshow;
    this.isPrint = isPrint;
    this.displayLegend = displayLegend;
    this.customLegendGenerator = customLegendGenerator;
  }

  chartCategorized({
    type = "bar",
    typeOptions = {
      stacked: false
    },
    items,
    valueScale = undefined,
    valueField = "",
    valueFieldType = GraphDataTypes.NUMBER,
    valueScaleOptions = {},
    datasetField = "",
    datasetOptions = {},
    categoryScale = undefined,
    categoryField = "",
    categoryScaleOptions = {},
    orderedCategories = undefined
  } = {}) {
    const groupedByCategory = groupBy(items, categoryField);
    const categoryLabels =
      Array.isArray(orderedCategories) && orderedCategories.length
        ? [...orderedCategories]
        : Object.keys(groupedByCategory);
    this.labels = categoryLabels;

    const groupedByDataset = groupBy(items, datasetField);
    const datasets = Object.entries(groupedByDataset).map((entry) => {
      const [datasetLabel, datasetValues] = entry;
      const data = categoryLabels.map((category) => {
        const match = datasetValues.find(
          (item) => item[categoryField] === category
        );
        return match
          ? this.formatData(match[valueField], valueFieldType)
          : null;
      });
      let datasetConfig = {
        type,
        label: datasetLabel,
        data
      };
      Object.assign(datasetConfig, DEFAULT_DATASET_CONFIG[type]);
      datasetConfig.label = datasetLabel;
      if (typeOptions.stacked) {
        datasetConfig.stack = "single_stack_id";
      }
      datasetConfig = { ...datasetConfig, ...datasetOptions };
      this.pickAvailableBackgroundColor(datasetConfig);
      return datasetConfig;
    });
    this.datasets.push(...datasets);

    const valueScaleAxis = valueScale === undefined ? "y" : valueScale.axis;
    if (valueScale === undefined) {
      valueScale = new ChartScaleLinear(
        valueScaleOptions,
        this.isSlideshow,
        valueScaleAxis,
        valueScaleOptions.position || "left"
      );
      this.addScale(valueScale);
    }
    const scaleOptions = typeOptions.stacked
      ? { ...categoryScaleOptions, ...{ stacked: true } }
      : categoryScaleOptions;
    const categoryScaleAxis =
      categoryScale === undefined ? "x" : categoryScale.axis;
    if (categoryScale === undefined) {
      categoryScale = new ChartScaleCategory(
        scaleOptions,
        this.isSlideshow,
        categoryScaleAxis
      );
      this.addScale(categoryScale);
    }
    return {
      valueScale,
      categoryScale
    };
  }

  chartTimeSeries({
    type = "bar",
    typeOptions = {
      stacked: false
    },
    items,
    valueScale = undefined,
    valueField = "",
    valueFieldType = GraphDataTypes.NUMBER,
    valueScaleOptions = {},
    datasetField = "",
    datasetOptions = {},
    dateTimeScale = undefined,
    dateTimeField = "",
    timeframe = {},
    dateTimeScaleOptions = {}
  } = {}) {
    const singleDataSetLabel =
      valueScaleOptions.label || i18n.t("reports.graph.value");
    const groupedByDataset = datasetField
      ? groupBy(items, datasetField)
      : { [singleDataSetLabel]: items };
    const datasets = Object.entries(groupedByDataset)?.map((entry) => {
      const [datasetLabel, datasetItems] = entry;
      const data = datasetItems.map((item) => ({
        value: this.formatData(item[valueField], valueFieldType),
        dateTime: new Date(item[dateTimeField])
      }));
      let datasetConfig = {
        type,
        data
      };
      Object.assign(datasetConfig, DEFAULT_DATASET_CONFIG[type]);
      datasetConfig.label = datasetLabel;
      if (typeOptions.stacked) {
        datasetConfig.stack = "single_stack_id";
      }
      datasetConfig = { ...datasetConfig, ...datasetOptions };
      this.pickAvailableBackgroundColor(datasetConfig);
      return datasetConfig;
    });
    this.datasets.push(...datasets);

    const valueScaleAxis = valueScale === undefined ? "y" : valueScale.axis;
    if (valueScale === undefined) {
      valueScale = new ChartScaleLinear(
        valueScaleOptions,
        this.isSlideshow,
        valueScaleAxis,
        valueScaleOptions.position || "left"
      );
      this.addScale(valueScale);
    }

    const dateTimeScaleAxis =
      dateTimeScale === undefined ? "x" : dateTimeScale.axis;
    if (dateTimeScale === undefined) {
      const scaleOptions = merge(
        {
          stacked: !!typeOptions.stacked,
          ticks: { source: "data" }
        },
        dateTimeScaleOptions
      );
      dateTimeScale = new ChartScaleTimeSeries(
        scaleOptions,
        this.isSlideshow,
        dateTimeScaleAxis,
        dateTimeScaleOptions.position || "bottom",
        timeframe
      );
      this.addScale(dateTimeScale);
    }

    if (type === "bar") {
      dateTimeScale.scaleOptions.offset = true;
    }

    datasets.forEach((dataset) => {
      dataset[`${dateTimeScale.axis}ScaleId`] = dateTimeScale.scaleId;
      dataset[`${valueScale.axis}ScaleId`] = valueScale.scaleId;
    });

    return {
      valueScale,
      dateTimeScale
    };
  }

  deviceMetrics({
    type = "bar",
    items,
    valueScale = undefined,
    valueField = "",
    valueFieldType = GraphDataTypes.NUMBER,
    valueScaleOptions = {},
    datasetField = "",
    categoryScale = undefined,
    categoryField = "",
    categoryScaleOptions = {}
  } = {}) {
    const groupedByDataset = groupBy(items, datasetField);
    const data = [];
    const xAxisLabels = [];
    items.forEach((item) => {
      data.push({
        x: `${item[datasetField]}_${item[categoryField]}`,
        y: this.formatData(item[valueField], valueFieldType)
      });

      xAxisLabels.push(item[categoryField]);
    });
    categoryScaleOptions.customLabels = xAxisLabels;

    const backgroundColor = [];
    let colorGroupingIndex = 0;

    for (const group of Object.values(groupedByDataset)) {
      group.forEach((entry) => {
        const colorIndex = entry.usesPrimaryColor ? 0 : 1;
        backgroundColor.push(chartColorChoices[colorGroupingIndex][colorIndex]);
      });

      colorGroupingIndex =
        colorGroupingIndex + 1 < chartColorChoices.length
          ? colorGroupingIndex + 1
          : 0;
    }

    this.datasets.push({
      ...DEFAULT_DATASET_CONFIG[type],
      type,
      data,
      backgroundColor
    });

    const valueScaleAxis = valueScale === undefined ? "y" : valueScale.axis;
    if (valueScale === undefined) {
      valueScale = new ChartScaleLinear(
        valueScaleOptions,
        this.isSlideshow,
        valueScaleAxis,
        valueScaleOptions.position || "left"
      );
      this.addScale(valueScale);
    }
    const categoryScaleAxis =
      categoryScale === undefined ? "x" : categoryScale.axis;
    if (categoryScale === undefined) {
      categoryScale = new ChartScaleCategory(
        categoryScaleOptions,
        this.isSlideshow,
        categoryScaleAxis,
        categoryScaleOptions.position || "bottom"
      );

      this.addScale(categoryScale);
    }

    return {
      valueScale,
      categoryScale
    };
  }

  build() {
    this.setBarColors(this.datasets, this.scales);

    return buildChart({
      datasets: this.datasets,
      labels: this.labels,
      scales: this.scales,
      displayLegend: this.displayLegend,
      customLegendGenerator: this.customLegendGenerator,
      isPrint: this.isPrint
    });
  }

  addScale(scale) {
    if (Array.isArray(this.scales[scale.axis])) {
      this.scales[scale.axis].push(scale);
    } else {
      this.scales[scale.axis] = [scale];
    }
  }

  setBarColors(datasets, scales) {
    datasets
      .filter((dataset) => dataset.type === "bar")
      .forEach((dataset) => {
        const valueScale = scales.y.find(
          (scale) => scale.scaleId === dataset.yScaleId
        );

        if (valueScale?.performanceBands?.length) {
          const sortedPerformanceBands = [...valueScale.performanceBands].sort(
            (a, b) => a.limit - b.limit
          );
          dataset.backgroundColor = [];
          dataset.data.forEach((datum) => {
            dataset.backgroundColor.push(
              sortedPerformanceBands.find((band) => datum.value <= band.limit)
                .color
            );
          });
        } else if (valueScale?.performanceThresholds?.length) {
          const backgroundColors = [];

          const sortedPerformanceThresholds = [
            ...valueScale.performanceThresholds
          ].sort((a, b) => a.value - b.value);
          const values = dataset.data.map((datum) => datum.value);

          values.forEach((value) => {
            let backgroundColor = colors.primary;
            for (let i = 0; i < sortedPerformanceThresholds.length; i++) {
              if (value >= sortedPerformanceThresholds[i].value) {
                backgroundColor = sortedPerformanceThresholds[i].color;
              }
            }
            backgroundColors.push(backgroundColor);
          });

          dataset.backgroundColor = backgroundColors;
        } else if (valueScale?.goal && valueScale.goal > 0) {
          dataset.backgroundColor = [];
          dataset.data.forEach((datum) => {
            if (valueScale?.goal && datum.value >= valueScale.goal) {
              dataset.backgroundColor.push(colors.success);
            } else {
              dataset.backgroundColor.push(colors.primary);
            }
          });
        }
      });
  }
  pickAvailableBackgroundColor(config) {
    const backgroundColor = ChartBuilder.pickColor(
      this.backgroundColorPickerIndex
    );

    if (backgroundColor) {
      this.backgroundColorPickerIndex++;

      // Line and bar graphs use different pieces of data to set the color. That's a chartJS thing.
      // when unset, chartjs picks a color from its list of colors
      if (config.type === "line") {
        config.borderColor = backgroundColor;
        config.pointBackgroundColor = backgroundColor;
        config.pointBorderColor = backgroundColor;
        config.backgroundColor = backgroundColor;
      } else {
        config.backgroundColor = backgroundColor;
      }
    }
  }

  static pickColor(index, useSecondaryColor) {
    if (index < 0 || index >= chartColorChoices.length) {
      return null;
    }
    return chartColorChoices[index % chartColorChoices.length][
      useSecondaryColor ? 1 : 0
    ];
  }

  formatData(data, dataType) {
    switch (dataType) {
      case GraphDataTypes.ROUNDED:
        return data === null ? null : Math.round(data);
      case GraphDataTypes.FIXED:
        return data === null
          ? null
          : typeof data === "number"
          ? data.toFixed(1)
          : data;
      case GraphDataTypes.TIME:
        return ChartBuilder.formatDate(data);
      default:
        return data;
    }
  }

  static formatDate = (date) => {
    return new Date(date);
  };
}
