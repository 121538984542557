import { ROUTE_NAMES } from "@/types/routeNames";
import { PERMISSIONS } from "@/types/permissions";
import { checkRoutePermission } from "@/middleware/auth";

export function menuItems(store, router) {
  const checkPermission = store.getters["user/permissions/checkPermission"];
  const getRoute = (routeName) =>
    router.getRoutes().find((r) => r.name === routeName).path;
  const hasRoutePermission = (routeName) =>
    checkRoutePermission(router.resolve({ name: routeName }).resolved, store);

  const items = {
    primaryMenuItems: [
      {
        routeName: ROUTE_NAMES.amsReports,
        translationKey: "report-types.ProductNameECM"
      },
      {
        routeName: ROUTE_NAMES.imsReports,
        translationKey: "report-types.ProductNameIMS"
      },
      {
        routeName: ROUTE_NAMES.obvReports,
        translationKey: "report-types.ProductNameOBV"
      },
      {
        routeName: ROUTE_NAMES.saReports,
        translationKey: "report-types.ProductNameSA"
      },
      {
        legacy: true,
        name: "go-manage-slideshows-btn",
        href: hasRoutePermission(ROUTE_NAMES.slideshowsList)
          ? getRoute(ROUTE_NAMES.slideshowsList)
          : "/slideshows",
        isVisible: () =>
          hasRoutePermission(ROUTE_NAMES.slideshowsList) ||
          checkPermission(PERMISSIONS.legacySlideshows),
        translationKey: "navigation.slideshows"
      },
      {
        legacy: true,
        name: "go-admin-btn",
        href: "/admin",
        isVisible: () => checkPermission(PERMISSIONS.administerCustomer),
        translationKey: "navigation.admin"
      }
    ],
    secondaryMenuItems: [
      {
        routeName: ROUTE_NAMES.downloads,
        translationKey: "reports.raw-export.downloads",
        target: "_blank"
      },
      {
        routeName: ROUTE_NAMES.manageAccount,
        translationKey: "navigation.manage-account"
      },
      {
        routeName: ROUTE_NAMES.alertSubscriptions,
        translationKey: "navigation.alert-subscriptions"
      },
      {
        legacy: true,
        href: "/Observation",
        isVisible: () => checkPermission(PERMISSIONS.obvSystem),
        translationKey: "navigation.obv-system"
      },
      {
        routeName: ROUTE_NAMES.reportSubscriptions,
        translationKey: "report-subscriptions.report-subscriptions"
      },
      {
        legacy: true,
        href: "/SystemStatus",
        isVisible: () => checkPermission(PERMISSIONS.systemStatus),
        translationKey: "navigation.system-status"
      },
      {
        legacy: true,
        href: "/SystemStatus/History",
        isVisible: () => checkPermission(PERMISSIONS.systemStatusHistory),
        translationKey: "navigation.system-status-history"
      }
    ]
  };

  const transformMenuItems = (menuItems) => {
    const accessibleMenus = menuItems.filter((m) => {
      return (
        (m.legacy && m.isVisible && m.isVisible()) ||
        (m.routeName && hasRoutePermission(m.routeName))
      );
    });
    const transformedItems = accessibleMenus.map((m) => ({
      name: m.name ?? undefined,
      target: m.target ?? "_self",
      href: m.legacy ? m.href : getRoute(m.routeName),
      translationKey: m.translationKey
    }));
    return transformedItems;
  };

  return {
    primaryMenuItems: transformMenuItems(items.primaryMenuItems),
    secondaryMenuItems: transformMenuItems(items.secondaryMenuItems)
  };
}
