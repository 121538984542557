<template>
  <div class="tw-flex tw-flex-wrap tw-justify-between tw-mt-5">
    <v-col
      v-for="(item, index) in dispensersAndDevices"
      :key="index"
      cols="12"
      md="4"
      class="tw-text-center"
    >
      <v-card elevation="2" class="tw-h-40 tw-py-3 tw-px-2">
        <v-list-item-title class="font-weight-bold tw-mb-3">
          {{ item.heading }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-h3">
          {{ item.value }}
        </v-list-item-subtitle>
        <v-btn
          v-if="item.canViewAll"
          class="tw-mt-3"
          text
          :to="{
            name: ROUTE_NAMES.deviceMaintenance,
            query: { facilityId, deviceType: item.deviceType }
          }"
        >
          {{
            $t("admin.facility-dashboard.dispensers-devices-widget.view-all")
          }}
          <v-icon class="tw-mt-1" small> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { ROUTE_NAMES } from "@/types/routeNames";
import { PRODUCTS } from "@/types/products";

export default {
  props: {
    customerId: { type: Number },
    facilityId: { type: Number },
    licenses: { type: Array },
    deviceCounts: {
      type: Object,
      default: () => {
        return {
          dispensers: 0,
          soapDispensers: 0,
          sanitizerDispensers: 0,
          gateways: 0,
          opportunityCounters: 0,
          repeaters: 0
        };
      }
    }
  },
  data() {
    return {
      ROUTE_NAMES,
      deviceTypes: {}
    };
  },
  computed: {
    dispensersAndDevices() {
      return [
        {
          heading: i18n.t("devices.types.dispensers"),
          value: this.deviceCounts["dispensers"],
          deviceType: "Dispenser",
          canViewAll: true
        },
        {
          heading: i18n.t("devices.types.soap-dispensers"),
          value: this.deviceCounts["soapDispensers"],
          deviceType: "Dispenser",
          canViewAll: false
        },
        {
          heading: i18n.t("devices.types.sanitizer-dispensers"),
          value: this.deviceCounts["sanitizerDispensers"],
          deviceType: "Dispenser",
          canViewAll: false
        },
        {
          heading: i18n.t("devices.types.gateways"),
          value: this.deviceCounts["gateways"],
          deviceType: "Gateway",
          canViewAll: true
        },
        {
          heading: this.getTranslatedOpportunityCounterName(),
          value: this.deviceCounts["opportunityCounters"],
          deviceType: "Opportunity Counter",
          canViewAll: true
        },
        {
          heading: i18n.t("devices.types.repeaters"),
          value: this.deviceCounts["repeaters"],
          deviceType: "Repeater",
          canViewAll: true
        }
      ];
    }
  },
  methods: {
    getTranslatedOpportunityCounterName() {
      const today = new Date();
      if (
        this.licenses?.some(
          (l) =>
            l.product?.code === PRODUCTS.ECM &&
            new Date(l.expirationDate) >= today
        )
      ) {
        return i18n.t("devices.types.activity-counters");
      } else if (
        this.licenses?.some(
          (l) =>
            l.product?.code === PRODUCTS.IMS &&
            new Date(l.expirationDate) >= today
        )
      ) {
        return i18n.t("devices.types.room-sensors");
      } else {
        return i18n.t("devices.types.opportunity-counters");
      }
    }
  }
};
</script>
