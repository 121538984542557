<template>
  <responsive-popup
    :value="value"
    v-if="filterId"
    @save="onSave"
    @cancel="onCancel"
  >
    <v-subheader>
      {{ $t(`filters.${filterId}`) }}
    </v-subheader>
    <filters-popup-search v-model="search" v-if="showSearch" />
    <filters-popup-header
      ref="my-filter-popup-header"
      v-if="selectAll"
      v-model="internalActiveIds"
      :hierarchyItems="hierarchyItems"
      :allIds="$store.getters[`filters/${filterId}/selectableIds`]"
      :allOpen.sync="allOpen"
    />
    <div class="limit-height">
      <component
        :is="selectionComponent"
        :orderedItems="orderedItems"
        v-model="internalActiveIds"
        class="hide-scrollbar"
        :hierarchyItems="hierarchyItems"
        :allOpen="allOpen"
        :search="search"
        :filterId="filterId"
        :selectionType="selectionType"
        :itemChildrenKey="itemChildrenKey"
        @singleSelectionChanged="onSingleSelectionChanged"
        :showOtherOption="showOtherOption"
        :otherOptionText="otherOptionText"
      />
      <v-snackbar
        @input="showForceSelectionMessage = false"
        :value="showForceSelectionMessage"
        color="error"
        :centered="true"
        :timeout="5000"
      >
        <div class="tw-text-lg" v-if="showForceSelectionMessage">
          {{ $t(`filters.messages.${filterId}-force-selection`) }}
        </div>
      </v-snackbar>
    </div>
    <filters-popup-custom-timeframes
      v-if="
        filterId === FILTERS.TIMEFRAME &&
        internalActiveIds === TIMEFRAMES.CUSTOM &&
        productId !== PRODUCTS.OBS
      "
      v-model="internalTimeframe"
    />
    <filters-popup-custom-dates
      v-if="
        filterId === FILTERS.TIMEFRAME &&
        internalActiveIds === TIMEFRAMES.CUSTOM &&
        productId === PRODUCTS.OBS
      "
      v-model="internalTimeframe"
    />
    <v-divider />
    <v-card-actions class="tw-w-full tw-justify-between">
      <v-btn name="cancelBtn" text @click="onCancel" color="purellGray">
        {{ this.$t("ui.actions.cancel") }}
      </v-btn>
      <v-btn ref="save-filters-button" text @click="onSave" color="primary">
        {{ this.$t("ui.actions.save") }}
      </v-btn>
    </v-card-actions>
  </responsive-popup>
</template>

<script>
import { FILTERS } from "@/types/filters";
import { TIMEFRAMES } from "@/types/timeframes";
import { isTimeframeValid } from "./utils/isTimeframeValid";
import ResponsivePopup from "@/views/reports/components/ResponsivePopup.vue";
import FiltersPopupSearch from "./components/FiltersPopupSearch.vue";
import FiltersPopupHeader from "./components/FiltersPopupHeader.vue";
import FiltersPopupSingleSelectableItems from "./components/FiltersPopupSingleSelectableItems.vue";
import FiltersPopupMultiSelectableItems from "./components/FiltersPopupMultiSelectableItems.vue";
import FiltersPopupHierarchyItems from "./components/FiltersPopupHierarchyItems.vue";
import FiltersPopupCustomTimeframes from "./components/FiltersPopupCustomTimeframes.vue";
import FiltersPopupCustomDates from "./components/FiltersPopupCustomDates.vue";
import { arrayCompareSequenceIrrelevant } from "@/utils/comparers";
import { PRODUCTS } from "@/types/products";
import { mapState } from "vuex";

export default {
  name: "filter-popup",
  components: {
    ResponsivePopup,
    FiltersPopupSearch,
    FiltersPopupHeader,
    FiltersPopupSingleSelectableItems,
    FiltersPopupMultiSelectableItems,
    FiltersPopupHierarchyItems,
    FiltersPopupCustomTimeframes,
    FiltersPopupCustomDates
  },
  data: () => ({
    search: "",
    TIMEFRAMES,
    FILTERS,
    internalActiveIds: [],
    internalTimeframe: {},
    showForceSelectionMessage: false,
    allOpen: false,
    PRODUCTS
  }),
  props: {
    value: {
      type: Boolean,
      default: false
    },
    filterId: {
      type: String
    },
    filterState: {
      type: Object,
      required: true
    },
    forceSelection: {
      type: Boolean,
      default: false
    },
    orderedItems: {
      type: Array,
      default: () => []
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    selectionComponent: {
      type: String,
      default: "filters-popup-multi-selectable-items"
    },
    showOtherOption: {
      type: Boolean,
      default: false
    },
    otherOptionText: String,
    timeframes: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("customers", ["productId"]),
    hierarchy() {
      return this.filterState?.hierarchy;
    },
    hierarchyItems() {
      return this.hierarchy && this.hierarchy.items;
    },
    selectionType() {
      return this.filterState.selectionType;
    },
    itemChildrenKey() {
      return this.filterState.itemChildrenKey;
    }
  },
  watch: {
    filterId(value) {
      if (!value) {
        return;
      }
      this.internalActiveIds = this.filterState.activeIds;

      this.internalTimeframe = {
        start: this.filterState.start,
        end: this.filterState.end
      };
    },
    value(value) {
      if (value) {
        window.addEventListener("keydown", this.onKeydown);
      } else {
        window.removeEventListener("keydown", this.onKeydown);
      }
    },
    hierarchyItems: {
      immediate: true,
      handler(value) {
        if (!value?.length) {
          return;
        }

        let hierarchyHasMoreThanTwoChildren = false;
        value.forEach((item) => {
          if (hierarchyHasMoreThanTwoChildren) {
            return;
          }

          if (item[this.itemChildrenKey] && item[this.itemChildrenKey].length) {
            hierarchyHasMoreThanTwoChildren = item[this.itemChildrenKey].some(
              (child) =>
                child[this.itemChildrenKey] &&
                child[this.itemChildrenKey].length
            );
          }
        });

        this.allOpen = !hierarchyHasMoreThanTwoChildren;
      }
    }
  },
  mounted() {
    if (this.filterId) {
      this.internalActiveIds = this.filterState.activeIds;

      this.internalTimeframe = {
        start: this.filterState.start,
        end: this.filterState.end
      };
    }
  },
  methods: {
    onSave() {
      if (this.isFilterUnchanged()) {
        this.onCancel();
        return;
      }

      this.$appInsights?.trackEvent({ name: "Save Individual Filter" });

      if (this.isStateValid() && !this.needsForcedSelection()) {
        this.search = "";
        const changedFilter = { ...this.filterState };
        changedFilter.activeIds = this.internalActiveIds;
        if (this.filterId === FILTERS.TIMEFRAME) {
          if (changedFilter.activeIds !== TIMEFRAMES.CUSTOM) {
            this.internalTimeframe.start = undefined;
            this.internalTimeframe.end = undefined;
          }

          changedFilter.start = this.internalTimeframe.start;
          changedFilter.end = this.internalTimeframe.end;
        }
        this.$emit("filterChanged", {
          filterId: this.filterId,
          filter: changedFilter
        });

        return;
      }

      this.$appInsights?.trackEvent({ name: "Invalid Individual Filter" });
    },
    onCancel() {
      this.$appInsights?.trackEvent({
        name: `Cancel Individual Filter`
      });
      this.search = "";
      this.showForceSelectionMessage = false;
      this.$emit("input", false);
    },
    isStateValid() {
      let valid = true;
      if (this.filterId === FILTERS.TIMEFRAME) {
        valid = isTimeframeValid({
          activeIds: this.internalActiveIds,
          timeframe: this.internalTimeframe,
          $notify: this.$notify,
          isObv: this.productId === PRODUCTS.OBS
        });
      }

      return valid;
    },
    onKeydown(event) {
      const { code } = event;
      if (code === "Escape" && this.value) {
        this.$emit("input", false);
      }
    },
    onSingleSelectionChanged(value) {
      if (this.filterId === FILTERS.TIMEFRAME && value === TIMEFRAMES.CUSTOM) {
        return;
      }

      this.onSave();
    },
    needsForcedSelection() {
      if (
        this.forceSelection &&
        (this.internalActiveIds === undefined ||
          (Array.isArray(this.internalActiveIds) &&
            this.internalActiveIds.length === 0))
      ) {
        this.showForceSelectionMessage = true;
        return true;
      }
      this.showForceSelectionMessage = false;
      return false;
    },
    isFilterUnchanged() {
      const newActiveIds = this.internalActiveIds;
      const isCustomTimeFrame =
        this.filterId === FILTERS.TIMEFRAME &&
        newActiveIds === TIMEFRAMES.CUSTOM;

      if (isCustomTimeFrame) {
        return false;
      }

      const currentActiveIds = this.filterState.activeIds;

      const isArrayFilter = Array.isArray(currentActiveIds);
      const isIdentical = isArrayFilter
        ? arrayCompareSequenceIrrelevant(currentActiveIds, newActiveIds)
        : currentActiveIds === newActiveIds;

      return isIdentical;
    }
  }
};
</script>

<style scoped lang="postcss">
.limit-height {
  @apply tw-overflow-y-auto;
  max-height: 50vh;
}

.hide-scrollbar {
  @apply tw-overflow-hidden;
}
</style>
