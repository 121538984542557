import i18n from "@/plugins/i18n";
import { activityBy } from "@/types/activityBy";

export default {
  async list() {
    const activityByValues = [
      {
        id: activityBy.unit,
        text: i18n.t("locations.locationlevelunit-singular")
      },
      {
        id: activityBy.jobRole,
        text: i18n.t("job-roles.singular")
      }
    ];

    return new Promise((res) => res(activityByValues));
  }
};
