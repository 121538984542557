<template>
  <div>
    <h3 class="tw-mb-5">
      {{ $t("admin.ims-job-role.create") }}
    </h3>
    <div v-if="loading">
      <p>
        {{ $t("admin.ims-job-role.loading") }}
      </p>
      <v-progress-linear indeterminate />
    </div>
    <div v-else>
      <ValidationObserver ref="createImsJobRoleForm">
        <v-form @submit.prevent="validateAndSubmit">
          <v-row dense>
            <v-col cols="12" lg="4">
              <ValidationProvider
                vid="jobRoleTitle"
                :name="$t('admin.ims-job-role.title')"
                :rules="{ required: true, max: 50 }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="title"
                  :error-messages="errors"
                  :label="$t('admin.ims-job-role.title')"
                  :counter="50"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="6">
              <ValidationProvider
                :name="$t('admin.ims-job-role.role-type')"
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  auto-select-first
                  v-model="jobRoleTypeId"
                  :error-messages="errors"
                  :label="$t('admin.ims-job-role.role-type')"
                  :items="
                    jobRoleTypes.map((f) => ({
                      text: f.title,
                      value: f.id
                    }))
                  "
                  items-text="text"
                  items-value="value"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="tw-mb-3">
            <v-col cols="12" lg="6">
              <v-btn
                class="tw-mr-5 tw-w-24"
                :loading="waiting"
                color="primary"
                type="submit"
              >
                {{ $t("ui.actions.save") }}
              </v-btn>
              <v-btn
                class="tw-mr-5 tw-w-24"
                color="background2"
                @click="closeCreateJobRoleView"
              >
                {{ $t("ui.actions.cancel") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import imsJobRoleService from "@/api/imsJobRoleService";
import { orderBy } from "lodash";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    customerId: { type: Number },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      loading: true,
      jobRoleTypes: [],
      jobRoleTypeId: undefined,
      title: "",
      waiting: false
    };
  },
  async created() {
    this.$appInsights?.trackEvent({
      name: `Manage IMS Job Role View Loaded`
    });
    try {
      this.jobRoleTypes = orderBy(
        await imsJobRoleService.getJobRoleTypes(this.customerId),
        ["title"]
      );
    } catch (err) {
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t("admin.employee.error-messages.load-job-roles")
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async validateAndSubmit() {
      const valid = await this.$refs.createImsJobRoleForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Create IMS Job Role Form Error`
        });
        return;
      }

      this.waiting = true;

      try {
        await imsJobRoleService.saveJobRole({
          customerId: this.customerId,
          title: this.title,
          jobRoleTypeId: this.jobRoleTypeId
        });
        this.$appInsights?.trackEvent({
          name: `Manage IMS Job Role Success`
        });
      } catch (error) {
        const duplicateNameError = error === 409;

        if (duplicateNameError) {
          this.$refs.createImsJobRoleForm.setErrors({
            jobRoleTitle: [
              i18n.t("admin.ims-job-role.error-messages.duplicate")
            ]
          });
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("admin.ims-job-role.error-messages.duplicate")
          });

          this.$appInsights?.trackEvent({
            name: `Update IMS Job Role Name Conflict Error`
          });
        } else {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("admin.ims-job-role.error-messages.save")
          });

          this.$appInsights?.trackEvent({
            name: `Update IMS Job Role Name Server Error`
          });
        }
        return;
      } finally {
        this.waiting = false;
      }

      this.setLocalizedWidgetMessage({
        message: i18n.t("admin.ims-job-role.success-message")
      });

      this.closeCreateJobRoleView();
    },
    closeCreateJobRoleView() {
      this.$emit("close-create-job-role-view");
    }
  }
};
</script>
