export const AMS_REPORTS = {
  PERFORMANCE_HISTORY: 2,
  ROOM_LEVEL_METRICS: 3,
  DEVICE_METRICS: 6,
  PERFORMANCE_SUMMARY: 19,
  PERFORMANCE_BAR_GRAPH: 33,
  DISPENSER_EVENTS: 29,
  SHIFT_COMPARISON: 30,
  IMAGE_SLIDE: 20
};

export const AMS_REPORT_COMPONENT_NAMES = {
  [AMS_REPORTS.PERFORMANCE_HISTORY]: "PerformanceHistory",
  [AMS_REPORTS.ROOM_LEVEL_METRICS]: "RoomLevelMetrics",
  [AMS_REPORTS.DEVICE_METRICS]: "DeviceMetrics",
  [AMS_REPORTS.PERFORMANCE_SUMMARY]: "PerformanceSummary",
  [AMS_REPORTS.PERFORMANCE_BAR_GRAPH]: "PerformanceBarGraph",
  [AMS_REPORTS.DISPENSER_EVENTS]: "DispenserEvents",
  [AMS_REPORTS.SHIFT_COMPARISON]: "ShiftComparison"
};

export const AMS_REPORT_DISPLAY_NAMES = {
  [AMS_REPORTS.PERFORMANCE_HISTORY]: "reports.ams.performance-history",
  [AMS_REPORTS.ROOM_LEVEL_METRICS]: "reports.ams.room-level-metrics",
  [AMS_REPORTS.DEVICE_METRICS]: "reports.ams.device-metrics",
  [AMS_REPORTS.PERFORMANCE_SUMMARY]: "reports.ams.performance-summary",
  [AMS_REPORTS.PERFORMANCE_BAR_GRAPH]: "reports.ams.performance-bar-graph",
  [AMS_REPORTS.DISPENSER_EVENTS]: "reports.ams.dispenser-events",
  [AMS_REPORTS.SHIFT_COMPARISON]: "reports.ams.shift-comparison",
  [AMS_REPORTS.IMAGE_SLIDE]: "slideshows.image-slide-base-name"
};

// These values MUST match the reportName in the `dbo.Reports.sql` file
export const AMS_REPORT_RAW_EXPORT_NAMES = {
  [AMS_REPORTS.PERFORMANCE_HISTORY]: "ECM Performance Report",
  [AMS_REPORTS.ROOM_LEVEL_METRICS]: "ECM Activity Report",
  [AMS_REPORTS.DEVICE_METRICS]: "ECM Devices Report",
  [AMS_REPORTS.PERFORMANCE_SUMMARY]: "AMS Performance Summary Report",
  [AMS_REPORTS.PERFORMANCE_BAR_GRAPH]: "ECM Performance Bar Graph Report",
  [AMS_REPORTS.DISPENSER_EVENTS]: "ECM Dispenser Events Report",
  [AMS_REPORTS.SHIFT_COMPARISON]: "ECM Shift Comparison Report",
  [AMS_REPORTS.IMAGE_SLIDE]: "Image Slide Report"
};
