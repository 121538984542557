var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-flex tw-flex-row tw-w-full"},[_c('div',{staticClass:"tw-items-start tw-p-0 tw-sticky tw-self-start",staticStyle:{"top":"10px"}},[(_vm.facility.customerName)?_c('v-btn',{staticClass:"tw-justify-start",attrs:{"small":"","text":"","block":"","to":{
          name: _vm.ROUTE_NAMES.customerDashboard,
          query: { customerId: _vm.facility.customerId }
        }}},[_vm._v(_vm._s(_vm.facility.customerName.toUpperCase())+" ")]):_vm._e(),(_vm.facility.name)?_c('v-btn',{staticClass:"tw-justify-start tw-mb-2",attrs:{"small":"","text":"","block":"","to":{
          name: _vm.ROUTE_NAMES.facilityDashboard,
          query: {
            customerId: _vm.facility.customerId,
            facilityId: _vm.facility.id
          }
        }}},[_vm._v(_vm._s(_vm.facility.name.toUpperCase())+" ")]):_vm._e(),_c('v-divider',{staticClass:"tw-py-1"}),_vm._l((_vm.settings),function(setting){return _c('v-btn',{key:setting,staticClass:"tw-justify-start",style:(_vm.chosenSetting === setting
            ? {
                backgroundColor: _vm.colors.background,
                color: '#000 !important'
              }
            : {}),attrs:{"text":"","block":"","disabled":_vm.chosenSetting === setting},on:{"click":function($event){return _vm.chooseSetting(setting)}}},[_vm._v(_vm._s(_vm.$t(`admin.manage-facility.settings.${setting}`))+" ")])})],2),_c('v-divider',{staticClass:"tw-py-0 tw-mx-4",attrs:{"vertical":""}}),_c('div',{staticClass:"tw-mb-5 tw-w-full"},[_c(_vm.chosenSetting,{tag:"component",attrs:{"facility":_vm.facility,"loading":_vm.loading},on:{"update:facility":function($event){_vm.facility=$event},"update:loading":function($event){_vm.loading=$event},"onSave":_vm.loadFacility}})],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }