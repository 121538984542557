import { ROUTE_NAMES } from "@/types/routeNames";
import store from "@/store/appStore";
import { determineDefaultRoute } from "./determineDefaultRoute";

export const auth = async (to, from, next) => {
  if (!store.state?.user.id) {
    await store.dispatch("user/load");
  }

  if (to.meta?.redirectToDefaultRoute) {
    redirectToDefaultRoute(to, next);
  } else {
    verifyRoutePermission(to, next);
  }
};

export const checkRoutePermission = (location) => {
  const checkPermission = store.getters["user/permissions/checkPermission"];
  return location?.matched?.every((route) =>
    route.meta?.permission ? checkPermission(route.meta.permission) : true
  );
};

export const verifyRoutePermission = (to, next) => {
  if (checkRoutePermission(to)) {
    next();
    return;
  }
  redirectToDefaultRoute(to, next);
};

function redirectToDefaultRoute(to, next) {
  const defaultRoute = determineDefaultRoute();

  const nextOptions = {
    name: defaultRoute.name,
    replace: to.name === ROUTE_NAMES.redirectionRoute
  };
  next(nextOptions);
}
