import {
  CookieStorage,
  LocalStorage,
  StorageKeys
} from "@/utils/browserStorage";

const preferences = {
  namespaced: true,
  state: () => ({
    language: LocalStorage.get(
      StorageKeys.preferences.language,
      CookieStorage.get("CultureCookie", "en-US")
    )
  }),
  mutations: {
    SET_LANGUAGE(state, value) {
      state.language = value;
    }
  },
  actions: {
    changeLanguage({ commit }, { language, i18n }) {
      commit("SET_LANGUAGE", language);
      i18n.locale = language;
      LocalStorage.store(StorageKeys.preferences.language, language);
      CookieStorage.store("CultureCookie", language);
    }
  }
};

export default preferences;
