<template>
  <div>
    <div
      class="
        app
        tw-w-full tw-bg-background
        print:tw-bg-white
        tw-flex tw-flex-col tw-min-h-screen
      "
    >
      <div>
        <slot></slot>
      </div>
      <notifications
        group="error"
        type="error"
        position="bottom right"
        class="print:tw-hidden tw-mb-6 tw-mr-2 tw-fixed"
        width="400"
        v-show="!isPrint"
      >
        <template slot="body" slot-scope="props">
          <v-alert type="error" prominent dismissible>
            <span>
              {{ props.item.text }}
              <v-btn
                v-if="error && error.retryAction"
                small
                dark
                outlined
                @click="
                  $store.dispatch(error.retryAction, error.retryParameter)
                "
                >{{ $t("retry") }}</v-btn
              >
            </span>
          </v-alert>
        </template>
      </notifications>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DISPLAY_MODES } from "../store/modules/application";

export default {
  computed: {
    ...mapState("application", ["error"]),
    isPrint() {
      return this.$store.state.application.displayMode === DISPLAY_MODES.PRINT;
    }
  },
  watch: {
    error() {
      if (this.error && this.error.message) {
        this.$notify({
          group: "error",
          text: this.error.message,
          duration: -1
        });
      } else {
        this.$notify({ group: "error", clean: true });
      }
    }
  }
};
</script>
