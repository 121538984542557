<template>
  <v-data-table
    class="device-notes-data-table small-headers"
    item-key="id"
    :search="search"
    :items="notes"
    :headers="headers"
    :no-data-text="$t('admin.placeholders-and-devices.device-notes.empty')"
    :items-per-page="5"
  >
    <template v-slot:top>
      <div
        class="
          tw-flex
          tw-justify-between
          tw-items-center
          tw-sticky
          tw-top-0
          tw-bg-white
        "
        style="z-index: 2"
      >
        <v-text-field
          v-model="search"
          :label="`${$t('ui.input-select.search')}`"
          class="tw-mr-4"
          clearable
          prepend-inner-icon="mdi-magnify"
        />
        <v-btn
          class="tw-max-w-xs"
          :disabled="notes.length > 0 && notes[notes.length - 1].creating"
          primary
          color="primary"
          @click="addNote"
        >
          <v-icon left> mdi-plus </v-icon>
          {{ $t("admin.placeholders-and-devices.device-notes.add-note") }}
        </v-btn>
      </div>
    </template>
    <template v-slot:item="{ item }">
      <tr v-if="item.creating" class="tw-justify-center tw-items-center">
        <td colspan="3">
          <ValidationProvider
            :name="$t('admin.placeholders-and-devices.device-notes.new-note')"
            :rules="{ required: true, max: 255 }"
            v-slot="{ errors }"
            class="
              tw-w-full tw-flex tw-flex-row tw-justify-center tw-items-center
            "
            tag="div"
          >
            <v-textarea
              v-model="item.noteText"
              :label="
                $t('admin.placeholders-and-devices.device-notes.new-note')
              "
              full-width
              auto-grow
              clearable
              counter="255"
              rows="2"
              :error-messages="errors"
            />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  primary
                  v-bind="attrs"
                  v-on="on"
                  :loading="item.isSaving"
                  :disabled="item.noteText.length === 0"
                  @click="saveNote(item)"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("ui.actions.save") }} </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  primary
                  v-bind="attrs"
                  v-on="on"
                  @click="removeNote"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("ui.actions.cancel") }} </span>
            </v-tooltip>
          </ValidationProvider>
        </td>
      </tr>
      <tr v-else>
        <td class="tw-break-all">{{ item.noteText }}</td>
        <td>{{ formatTimestamp(item.createdDate) }}</td>
        <td>{{ item.addedBy }}</td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import i18n from "@/plugins/i18n";
import deviceService from "@/api/deviceService";
import { uuidv4 } from "@/utils/generators";
import { cloneDeep } from "lodash";

export default {
  components: {
    ValidationProvider
  },
  props: {
    deviceId: {
      type: Number
    },
    notes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headers: [
        {
          text: i18n.t(
            "admin.placeholders-and-devices.device-notes.notes-header"
          ),
          value: "noteText",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t(
            "admin.placeholders-and-devices.device-notes.date-header"
          ),
          value: "createdDate",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t(
            "admin.placeholders-and-devices.device-notes.added-by-header"
          ),
          value: "addedBy",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      search: ""
    };
  },
  methods: {
    addNote() {
      this.$emit("update:notes", [
        ...this.notes,
        { id: uuidv4(), noteText: "", creating: true, isSaving: false }
      ]);
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        return new Date(`${timestamp}`).toLocaleDateString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
      }
      return "";
    },
    removeNote() {
      const notesCopy = cloneDeep(this.notes);
      notesCopy.pop();

      this.$emit("update:notes", notesCopy);
    },
    async saveNote(item) {
      try {
        item.isSaving = true;

        await deviceService.createNote(this.deviceId, item.noteText);
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t(
            "admin.placeholders-and-devices.success-messages.create-device-note"
          )
        );

        item.creating = false;
        this.$emit("onSave");
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.placeholders-and-devices.error-messages.save-note"
          )
        });
      } finally {
        item.isSaving = false;
      }
    }
  }
};
</script>
