<template>
  <div class="tw-mb-5">
    <template v-if="loading">
      <p>{{ $t("admin.performance-bands.loading-directions") }}.</p>
      <v-progress-linear indeterminate />
    </template>
    <template v-else>
      <chart-renderer
        class="tw-relative tw-w-3/4 tw-m-auto"
        :indicators="indicators"
        :isThresholds="false"
      />
      <v-row
        class="tw-items-center tw-space-between tw-my-2"
        v-for="(item, index) in indicators"
        :key="item.id"
      >
        <v-col cols="12" lg="6">
          <ValidationProvider
            :name="
              $tc('admin.manage-facility.report-settings.indicator', index + 1)
            "
            :rules="{ required: true, max: 30 }"
            v-slot="{ label, errors }"
          >
            <v-text-field
              v-model="item.title"
              :error-messages="[...errors, ...item.titleErrors]"
              :label="label"
              :counter="30"
              @input="checkDuplicateIndicatorTitle(item)"
              @blur="trimTitleWhitespace(item)"
            />
          </ValidationProvider>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          sm="8"
          class="tw-flex tw-flex-row tw-items-center tw-justify-between"
        >
          <div
            class="
              tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-40
            "
          >
            <template>
              <span>{{ item.minimum }}%</span>
              <v-icon>mdi-arrow-left-right-bold</v-icon>
            </template>
            <ValidationProvider
              :name="
                $tc(
                  'admin.manage-facility.report-settings.indicator-limit',
                  index + 1
                )
              "
              :rules="{
                required: true,
                numeric: true,
                max_value: 100,
                min_value: item.minimum
              }"
              v-slot="{ label, errors }"
              class="tw-w-20"
            >
              <v-text-field
                v-model.number="item.value"
                :error-messages="
                  index === indicators.length - 1
                    ? [...errors, ...item.valueErrors, ...lastValueError]
                    : [...errors, ...item.valueErrors]
                "
                :label="label"
                suffix="%"
                @blur="sortIndicators()"
                @input="checkDuplicateIndicatorValue(item)"
              />
            </ValidationProvider>
          </div>
          <v-menu top offset-y close-on-click>
            <template v-slot:activator="{ on }">
              <v-btn :color="item.color" dark v-on="on" min-width="36px" />
            </template>
            <ValidationProvider
              :name="
                $tc(
                  'admin.manage-facility.report-settings.indicator-color',
                  index + 1
                )
              "
            >
              <v-color-picker
                v-model="item.color"
                hide-canvas
                hide-mode-switch
                hide-sliders
                hide-inputs
                show-swatches
                :swatches="swatches"
              />
            </ValidationProvider>
          </v-menu>
          <v-menu top offset-y close-on-click>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                :color="item.color"
                v-on="on"
                min-width="36px"
                width="36px"
              >
                <v-icon v-model="item.symbol" :color="item.color">
                  mdi-{{ item.symbol }}
                </v-icon>
              </v-btn>
            </template>
            <div class="tw-bg-white tw-overflow-hidden">
              <v-btn
                v-for="symbol in symbols"
                :key="symbol.id"
                icon
                tile
                :outlined="item.symbolId === symbol.id"
                @click="setSymbol(item, symbol)"
              >
                <v-icon :color="item.color" class="tw-m-2">
                  mdi-{{ symbol.symbol }}
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="deleteIndicator(item.id)"
              >
                <v-icon large>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span> {{ $t("performance-indicators.remove") }} </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="tw-mt-1 tw-justify-self-start">
        <v-btn
          v-show="indicators.length < 6"
          class="tw-mr-5"
          color="primary"
          @click="addIndicator()"
        >
          {{ $t("admin.manage-facility.report-settings.add-indicator") }}
        </v-btn>

        <v-btn
          v-if="indicators.length === 0"
          class="tw-mr-5"
          color="background2"
          @click="setDefaultIndicators()"
        >
          {{ $t("admin.manage-facility.report-settings.default-indicators") }}
        </v-btn>

        <v-btn
          v-if="resetState.length && indicatorsChanged"
          class="tw-mr-5"
          color="background2"
          @click="resetPerformanceIndicators(resetState)"
        >
          {{ $t("admin.manage-facility.report-settings.reset-indicators") }}
        </v-btn>
      </v-row>
    </template>
  </div>
</template>

<script>
import performanceIndicatorsMixin from "@/views/admin/components/performanceIndicators/mixin";
import ChartRenderer from "@/views/admin/components/performanceIndicators/ChartRenderer.vue";
import performanceBandService from "@/api/performanceBandService";
import { uuidv4 } from "@/utils/generators";
import { cloneDeep } from "lodash";
import i18n from "@/plugins/i18n";

export default {
  mixins: [performanceIndicatorsMixin],
  components: {
    ChartRenderer
  },
  props: {
    facilityId: {
      type: Number
    },
    saving: {
      type: Boolean
    }
  },
  data: () => ({
    symbols: []
  }),
  async mounted() {
    this.loading = true;

    try {
      const response = await performanceBandService.get(this.facilityId);

      this.symbols = response.symbols;
      const indicators = response.performanceBands;

      this.indicators = indicators.map((i, index) => {
        const data = {
          id: uuidv4(),
          title: i.title,
          color: i.color,
          value: i.limit,
          valueErrors: [],
          lastValueError: [],
          titleErrors: [],
          symbol: i.symbol.symbol,
          symbolId: i.symbol.id,
          minimum: 0
        };

        data.minimum = index === 0 ? 0 : indicators[index - 1].limit + 1;

        return data;
      });

      this.sortIndicators();
      this.resetState = cloneDeep(this.indicators);
    } catch {
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t("admin.performance-bands.error-messages.get-bands")
      });
    } finally {
      this.loading = false;
    }
  },
  computed: {
    invalidIndicators() {
      if (this.indicators.length > 1) {
        if (!this.indicators.find((i) => i.value === 100)) {
          return true;
        }
        const valueSeen = new Set();
        if (
          this.indicators.some(
            (i) => valueSeen.size === valueSeen.add(i.value).size
          )
        ) {
          return true;
        }

        const titleSeen = new Set();
        if (
          this.indicators.some(
            (i) => titleSeen.size === titleSeen.add(i.title.toLowerCase()).size
          )
        ) {
          return true;
        }
      }

      return this.indicators.some((i) => i.value === "");
    },
    lastValueError() {
      if (
        this.indicators.length > 1 &&
        !this.indicators.find((i) => i.value === 100)
      ) {
        return [
          i18n.t(
            "admin.manage-facility.report-settings.error-messages.last-indicator"
          )
        ];
      }

      return [];
    }
  },
  methods: {
    setDefaultIndicators() {
      const commonIndicator = {
        titleErrors: [],
        valueErrors: []
      };

      this.indicators = [
        {
          ...commonIndicator,
          id: uuidv4(),
          title: "Below Baseline",
          minimum: 0,
          value: 40,
          color: this.colors[0].value,
          symbol: "thumb-down",
          symbolId: 6
        },
        {
          ...commonIndicator,
          id: uuidv4(),
          title: "Between Baseline and Goal",
          minimum: 41,
          value: 70,
          color: this.colors[4].value,
          symbol: "circle",
          symbolId: 1
        },
        {
          ...commonIndicator,
          id: uuidv4(),
          title: "Above Goal",
          minimum: 71,
          value: 100,
          color: this.colors[1].value,
          symbol: "thumb-up",
          symbolId: 5
        }
      ];
    },
    addIndicator() {
      const indicator = {
        id: uuidv4(),
        title: "",
        minimum:
          this.indicators.length === 1
            ? this.indicators[this.indicators.length - 1].value + 1
            : 0,
        value: this.indicators.length === 1 ? 100 : "",
        color: this.firstAvailableSwatch,
        symbol: "square",
        symbolId: 2,
        titleErrors: [],
        valueErrors: [],
        lastValueError: []
      };

      if (this.indicators.length > 1) {
        this.indicators.splice(this.indicators.length - 1, 0, indicator);
      } else {
        this.indicators.push(indicator);
      }
    },
    sortIndicators() {
      if (this.invalidIndicators) {
        return;
      }

      this.indicators.sort((a, b) => (a.value > b.value ? 1 : -1));

      this.indicators.forEach((indicator, index) => {
        indicator.minimum =
          index === 0 ? 0 : this.indicators[index - 1].value + 1;
      });
    },
    setSymbol(item, symbol) {
      item.symbol = symbol.symbol;
      item.symbolId = symbol.id;
    },
    async submitPerformanceIndicators() {
      if (!this.indicatorsChanged) {
        return true;
      }

      const formattedPerformanceIndicators = this.indicators.map((i) => {
        return {
          facilityId: this.facilityId,
          title: i.title,
          limit: i.value,
          color: i.color,
          symbolId: i.symbolId
        };
      });

      try {
        await performanceBandService.put(
          this.facilityId,
          formattedPerformanceIndicators
        );
        this.resetState = cloneDeep(this.indicators);

        return true;
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.performance-bands.error-messages.update-bands")
        });

        return false;
      }
    }
  }
};
</script>
