<template>
  <div>
    <div v-if="loading">
      <p>{{ $t("admin.user.loading-directions") }}.</p>
      <v-progress-linear indeterminate />
    </div>
    <ValidationObserver v-else ref="userForm" v-slot="{ pristine }">
      <v-form @submit.prevent="validateAndSubmit">
        <h3>{{ $t("admin.user.basic-info") }}</h3>
        <v-row dense>
          <v-col cols="12" lg="8">
            <ValidationProvider
              ref="emailValidator"
              vid="emailValidator"
              :name="$t('admin.user.email-address')"
              :rules="{ required: true, email: true, max: 100 }"
              v-slot="{ errors, validate }"
            >
              <v-text-field
                v-model="emailAddress"
                :disabled="username != null"
                :error-messages="[...emailErrors, ...errors]"
                :label="$t('admin.user.email-address')"
                :counter="100"
                @blur="validateEmailAddress"
                @validate="validate"
                ref="emailField"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="4">
            <ValidationProvider
              :name="$t('admin.user.department')"
              :rules="{ max: 20 }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="department"
                :error-messages="errors"
                :label="$t('admin.user.department')"
                :counter="20"
                :disabled="addingExistingUser"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" lg="6">
            <ValidationProvider
              :name="$t('admin.user.first-name')"
              :rules="{ required: true, max: 50 }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="firstName"
                :error-messages="errors"
                :label="$t('admin.user.first-name')"
                :counter="50"
                :disabled="addingExistingUser"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="6">
            <ValidationProvider
              :name="$t('admin.user.last-name')"
              :rules="{ required: true, max: 50 }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="lastName"
                :error-messages="errors"
                :label="$t('admin.user.last-name')"
                :counter="50"
                :disabled="addingExistingUser"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-row justify="space-between" align="center" class="tw-px-2">
              <h3
                :class="{
                  'tw-text-red-500': showRoleError
                }"
              >
                {{ $t("admin.user.roles") }}*
              </h3>
              <v-col cols="6" class="tw-p-0" v-if="userRoleSelected">
                <h4>{{ $t("admin.customer-dashboard.facilities") }}</h4>
              </v-col>
            </v-row>
            <div
              v-if="showRoleError"
              class="tw-pt-3 tw-text-xs tw-text-red-500"
            >
              {{
                $t(
                  "admin.customer-dashboard.users-widget.error-messages.empty-roles"
                )
              }}
            </div>
            <v-row
              dense
              v-for="(item, index) in userRoles"
              :key="index"
              class="tw-items-center tw-justify-between"
              style="height: 56px"
            >
              <div class="tw-flex tw-flex-row tw-items-center">
                <ValidationProvider>
                  <v-checkbox
                    v-model="item.selected"
                    dense
                    :label="item.name"
                    :hide-details="true"
                  />
                </ValidationProvider>
                <v-tooltip top open-delay="500" content-class="tooltip-opacity">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon dense v-bind="attrs" v-on="on" class="tw-ml-1">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t(item.info) }}</span>
                </v-tooltip>
              </div>
              <v-col cols="6" dense v-if="item.selected">
                <ValidationProvider
                  :name="$t('admin.user.facilities', { role: item.name })"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <selector
                    v-model="item.selectedFacilities"
                    :items="facilities"
                    activator="v-select"
                    :offsetX="false"
                    :menuProps="{ 'nudge-top': 180 }"
                    :textFieldProps="{
                      dense: true,
                      'error-messages': errors
                    }"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-col>
          <v-divider v-if="adminRoles.length" vertical />
          <v-col cols="12" lg="6" class="tw-pt-0" v-if="adminRoles.length">
            <h3
              :class="{
                'tw-text-red-500': showRoleError
              }"
            >
              {{ $t("admin.user.admin-roles") }}
            </h3>
            <v-row
              dense
              v-for="(item, index) in adminRoles"
              :key="index"
              style="height: 56px"
              class="tw-items-center"
            >
              <ValidationProvider>
                <v-checkbox
                  v-model="item.selected"
                  dense
                  :label="item.name"
                  :hide-details="true"
                />
              </ValidationProvider>
              <v-tooltip top open-delay="500" content-class="tooltip-opacity">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dense v-bind="attrs" v-on="on" class="tw-ml-1 tw">
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t(item.info) }}</span>
              </v-tooltip>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense class="tw-mb-3">
          <v-col cols="12" lg="6">
            <v-btn
              class="tw-mr-5 tw-w-24"
              :disabled="(username && pristine) || emailErrors.length > 0"
              :loading="saving"
              color="primary"
              type="submit"
            >
              {{ $t("ui.actions.save") }}
            </v-btn>
            <v-btn
              class="tw-mr-5 tw-w-24"
              color="background2"
              @click="closeCreateEditView(false)"
            >
              {{ $t("ui.actions.cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-dialog v-model="showModal" max-width="460">
      <v-card class="tw-overflow-y-hidden">
        <v-card-title class="text-h5">{{
          $t("admin.customer-dashboard.users-widget.user-exists")
        }}</v-card-title>
        <v-card-text>{{
          $t("admin.customer-dashboard.users-widget.add-to-customer")
        }}</v-card-text>
        <v-card-actions class="tw-mb-2">
          <v-row>
            <v-col class="tw-flex tw-flex-row tw-justify-end">
              <v-btn class="tw-mr-5" @click="handleModalClose">
                {{ $t("ui.actions.cancel") }}</v-btn
              >
              <v-btn color="primary" @click="handleModalAffirm">
                {{
                  $t("admin.customer-dashboard.users-widget.add-user")
                }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import customerService from "@/api/customerService";
import roleService from "@/api/roleService";
import userService from "@/api/userService";
import { mapState } from "vuex";
import { orderBy } from "lodash";
import Selector from "@/views/admin/components/Selector.vue";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  components: {
    Selector
  },
  props: {
    customerId: { type: Number },
    facilityId: { type: Number },
    username: { type: String }
  },
  data() {
    return {
      addingExistingUser: false,
      adminRoles: [],
      userRoles: [],
      emailErrors: [],
      loading: true,
      saving: false,
      emailAddress: "",
      firstName: "",
      lastName: "",
      department: "",
      existingUser: [],
      isActive: true,
      facilities: [],
      showModal: false,
      saveClicked: false
    };
  },
  async mounted() {
    this.$appInsights?.trackEvent({
      name: `Manage User View Loaded`
    });
    await this.loadFacilities();
    await this.loadRoles();

    if (this.username) {
      await this.loadUser();
    }

    this.loading = false;
  },
  computed: {
    ...mapState("preferences", ["language"]),
    adminRoleSelected() {
      return this.adminRoles.some((r) => r.selected === true);
    },
    showRoleError() {
      return (
        this.saveClicked && !this.userRoleSelected && !this.adminRoleSelected
      );
    },
    userRoleSelected() {
      return this.userRoles.some((r) => r.selected === true);
    }
  },
  methods: {
    closeCreateEditView(reloadUsers, widgetMessage) {
      this.$emit("close-create-edit-view", {
        reloadUsers,
        widgetMessage
      });
    },
    async loadFacilities() {
      try {
        this.facilities = orderBy(
          await customerService.getListOfFacilities(this.customerId),
          ["facilityName"]
        ).map((f) => {
          return { text: f.facilityName, id: f.facilityId };
        });
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.user.error-messages.load-facilities")
        });
      }
    },
    async loadRoles() {
      try {
        const roles = orderBy(await roleService.list(), ["name"]).map(
          (role) => {
            return {
              ...role,
              selected: false,
              info: `admin.user.role-details.${role.name
                .replaceAll(" ", "-")
                .toLowerCase()}`
            };
          }
        );
        const allFacilityIds = this.facilities.map((f) => f.id);
        this.userRoles = roles
          .filter((r) => !r.isAdmin)
          .map((role) => {
            return {
              ...role,
              selectedFacilities: allFacilityIds
            };
          });
        this.adminRoles = roles.filter(
          (r) => r.isAdmin && !r.name.includes("Support")
        );
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.user.error-messages.load-roles")
        });
      }
    },
    async loadUser() {
      try {
        const user = await userService.getExistingUser(
          this.username,
          this.customerId
        );
        this.emailAddress = user.email;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.department = user.department;
        this.isActive = user.isActive;
        this.setRoles(user.userRoleAssociations);
      } catch (error) {
        if (error === 403) {
          this.closeCreateEditView(false);
        } else {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("admin.user.error-messages.load-user")
          });
        }
      }
    },
    setRoles(userRoleAssociations) {
      const allRoles = [...this.userRoles, ...this.adminRoles];
      const allFacilityIds = this.facilities.map((f) => f.id);

      userRoleAssociations.forEach((roleAssocation) => {
        const role = allRoles.find((r) => r.id === roleAssocation.roleId);
        if (role) {
          if (!role.selected) {
            role.selected = true;
            role.selectedFacilities = [];
          }

          if (roleAssocation.facilityId) {
            role.selectedFacilities.push(roleAssocation.facilityId);
          } else {
            role.selectedFacilities = allFacilityIds;
          }
        }
      });
    },
    async validateAndSubmit() {
      this.saveClicked = true;
      const valid = await this.$refs.userForm.validate();

      if (
        this.emailErrors.length > 0 ||
        !valid ||
        (!this.adminRoleSelected && !this.userRoleSelected)
      ) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Employee Form Error`
        });
        return;
      }

      this.saving = true;

      try {
        const userRoles = [
          ...this.userRoles.filter((r) => r.selected),
          ...this.adminRoles.filter((r) => r.selected)
        ];
        const userRoleAssociations = [];
        userRoles.forEach((role) => {
          const userRoleAssociation = {
            customerId: this.customerId,
            roleId: role.id
          };
          if (
            role.selectedFacilities &&
            role.selectedFacilities.length !== this.facilities.length
          ) {
            role.selectedFacilities.forEach((facilityId) => {
              userRoleAssociations.push({
                ...userRoleAssociation,
                facilityId
              });
            });
          } else {
            userRoleAssociations.push({
              ...userRoleAssociation,
              facilityId: undefined
            });
          }
        });
        await userService.saveUser({
          customerId: this.customerId,
          userName: this.username,
          email: this.emailAddress,
          firstName: this.firstName,
          lastName: this.lastName,
          department: this.department,
          isActive: this.isActive,
          userRoleAssociations
        });

        this.$appInsights?.trackEvent({
          name: `Manage User Form Success`
        });

        this.closeCreateEditView(
          true,
          this.username
            ? i18n.t("admin.user.success-messages.edit")
            : i18n.t("admin.user.success-messages.create")
        );
      } catch (err) {
        this.$appInsights?.trackEvent({
          name: `Manage User Server Erro`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.user.error-messages.save")
        });
      } finally {
        this.saving = false;
      }
    },
    async validateEmailAddress() {
      this.resetUser();
      const emailValid = (await this.$refs.emailValidator.validate()).valid;
      if (emailValid) {
        const result = await userService.validateEmailAddress(
          this.emailAddress,
          this.customerId
        );
        if (result.exists) {
          if (result.user) {
            this.existingUser = result.user;
            this.showModal = true;
          } else {
            this.emailErrors = [
              i18n.t("admin.user.error-messages.existing-user")
            ];
          }
        } else {
          this.emailErrors = [];
        }
      }
    },
    resetUser() {
      this.addingExistingUser = false;
      this.department = "";
      this.firstName = "";
      this.lastName = "";
    },
    async handleModalClose() {
      this.showModal = false;
      await this.$nextTick();
      this.$refs.emailField?.focus();
      this.emailAddress = "";
    },
    async handleModalAffirm() {
      this.department = this.existingUser.department;
      this.firstName = this.existingUser.firstName;
      this.lastName = this.existingUser.lastName;
      this.emailErrors = [];
      this.addingExistingUser = true;
      this.showModal = false;
    }
  }
};
</script>

<style scoped>
:deep(.v-messages__message) {
  white-space: pre-wrap;
}

.tooltip-opacity {
  opacity: 1 !important;
  background: rgba(97, 97, 97, 1);
}
</style>
