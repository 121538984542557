<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader type="list-item-avatar-three-line" />
      <v-skeleton-loader type="button" />
      <v-skeleton-loader type="image@3" />
    </div>
    <header v-else class="tw-flex tw-flex-col tw-mb-3">
      <div class="tw-flex tw-flex-row tw-justify-between">
        <div class="tw-flex tw-flex-row tw-mb-5 tw-items-center">
          <v-img v-if="logo" :src="logo" class="logo" />
          <slot name="title"></slot>
        </div>
      </div>
      <v-card outlined>
        <div
          id="properties"
          class="tw-flex tw-flex-row tw-justify-between tw-px-10 tw-py-2"
        >
          <div>
            <p class="property-title">
              {{ $t("admin.customer-list.data-table.address") }}
            </p>
            <div class="property-text">
              <p class="multi-address">{{ item.address1 }}</p>
              <p class="multi-address" v-if="item.address2">
                {{ item.address2 }}
              </p>
              <p class="multi-address" v-if="item.address3">
                {{ item.address3 }}
              </p>
            </div>
          </div>
          <div>
            <p class="property-title">
              {{ $t("admin.customer-list.data-table.city") }}
            </p>
            <p class="property-text">{{ item.city }}</p>
          </div>
          <div>
            <p class="property-title">
              {{ $t("admin.customer-list.data-table.state") }}
            </p>
            <p class="property-text">{{ item.stateName }}</p>
          </div>
          <div>
            <p class="property-title">
              {{ $t("admin.customer-list.data-table.country") }}
            </p>
            <p class="property-text">{{ item.countryName }}</p>
          </div>
        </div>
      </v-card>
    </header>
    <div v-if="!loading" class="tw-flex tw-flex-wrap tw-justify-between">
      <slot name="widgets"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
    logo: { type: String, default: "" }
  }
};
</script>

<style scoped lang="postcss">
.property-title {
  font-size: 12px;
  color: #8a8d8f;
}

.property-text {
  font-size: 16px;
}

#properties p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.logo {
  max-width: 160px;
  max-height: 120px;
  margin-right: 20px;
  width: auto;
  height: auto;
}

.multi-address {
  margin-top: 0px;
  margin-bottom: 0px;
}

:deep(.v-data-table__wrapper) {
  height: 300px;
  overflow-y: auto;
}

:deep(.v-data-table.autoheight .v-data-table__wrapper) {
  height: auto;
}

:deep(.v-data-table__wrapper thead th) {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

:v-deep(.v-skeleton-loader__list-item-avatar-three-line) {
  padding-left: 0px;
  margin-bottom: 15px;
}

:v-deep(.v-skeleton-loader__avatar) {
  height: 80px;
  width: 120px;
  border-radius: 5px;
}

:v-deep(.v-skeleton-loader__text) {
  height: 15px;
}

:v-deep(.v-skeleton-loader__button) {
  width: 100%;
  height: 60px;
  margin-bottom: 20px;
}
</style>
