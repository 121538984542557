var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"reportBlock",staticClass:"tw-flex tw-flex-col tw-min-h-200px tw-text-center tw-w-full rounded tw-mb-6 lg:tw-mx-15px",class:[
      `${_vm.bgColor}`,
      'lg:tw-max-w-sm',
      { 'lg:tw-max-w-2xl tw-min-h-300px': _vm.isSlideshow }
    ],style:(_vm.blockStyleObject),on:{"click":function($event){_vm.reportId ? _vm.loadReport(_vm.reportId) : null}}},[_c('h3',{staticClass:"tw-text-white tw-font-black tw-px-1.5 tw-py-5 rounded-tl rounded-tr tw-w tw-text-2xl",class:[
        `${_vm.headerColor}`,
        _vm.bodyIsSingleValue || _vm.isSlideshow || _vm.reportId
          ? ''
          : 'lg:tw-px-4 xl:tw-px-1 lg:tw-text-xl',
        { 'tw-text-4xl': _vm.isSlideshow }
      ]},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.bodyIsSingleValue)?_c('div',{ref:"valueRow",staticClass:"tw-flex tw-text-white tw-text-5xl tw-font-bold tw-justify-center tw-h-full tw-items-center tw-pb-5",class:{ 'text-7xl': _vm.isSlideshow }},[_c(_vm.display || 'div',{tag:"component",attrs:{"value":_vm.body}})],1):_c('div',{staticClass:"tw-w-full tw-h-full",staticStyle:{"padding":"10px 20px"}},_vm._l((_vm.body),function(row){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(row.value >= 0),expression:"row.value >= 0"}],key:row.text,ref:"valueRow",refInFor:true,staticClass:"tw-flex tw-text-white tw-text-lg lg:tw-text-sm xl:tw-text-lg tw-font-bold tw-w-full",class:{ 'xl:tw-text-3xl': _vm.isSlideshow }},[_c('div',{staticClass:"tw-uppercase tw-text-right tw-pr-2.5 tw-w-3/5"},[_vm._v(" "+_vm._s(row.text)+" ")]),_c('div',{staticClass:"tw-text-right tw-pr-2.5 tw-w-2/5"},[_c(row.display || 'td',{tag:"component",attrs:{"value":row.value != null ? row.value : '0.0'}})],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }