<template>
  <div>
    <h1>{{ $t("admin.customer-list.header") }}</h1>
    <v-data-table
      :items="customers"
      :items-per-page="-1"
      :headers="headers"
      :search="search"
      :loading="loading"
      :loading-text="$t('admin.customer-list.loading')"
      :no-data-text="$t('admin.customer-list.empty')"
      multi-sort
    >
      <template v-slot:top>
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
            autofocus
          />
          <v-btn
            v-if="checkPermission(PERMISSIONS.addCustomers)"
            class="tw-max-w-xs"
            primary
            color="primary"
            :to="{
              name: ROUTE_NAMES.createCustomer
            }"
          >
            {{ $t("admin.customer-list.add-customer") }}
          </v-btn>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <tr :key="item.id">
          <td>
            <router-link
              :to="{
                name: ROUTE_NAMES.customerDashboard,
                query: { customerId: item.id }
              }"
              class="tw-flex tw-items-center tw-justify-items-center"
            >
              <img
                v-if="item.logoImage"
                :src="item.logoImage"
                class="customer-logo"
              />
              <span>{{ item.name }}</span>
            </router-link>
          </td>
          <td>
            <p class="multi-address">{{ item.address1 }}</p>
            <p class="multi-address" v-if="item.address2">
              {{ item.address2 }}
            </p>
            <p class="multi-address" v-if="item.address3">
              {{ item.address3 }}
            </p>
          </td>
          <td>{{ item.city }}</td>
          <td>
            {{ item.stateName }}
          </td>
          <td>
            {{ item.countryName }}
          </td>
          <td>
            <v-switch
              v-if="checkPermission(PERMISSIONS.activateDeactivateCustomers)"
              :input-value="item.isActive"
              :loading="item.isLoading"
              @click="
                item.isActive
                  ? setModal(item.id)
                  : toggleActiveCustomer(item.id)
              "
              readonly
            />
            <v-switch
              v-else
              :input-value="item.isActive"
              :disabled="true"
              readonly
            />
          </td>
          <td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  primary
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: ROUTE_NAMES.editCustomer,
                    query: { customerId: item.id }
                  }"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("admin.manage-customer.edit") }}
              </span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="showModal" max-width="560">
      <v-card class="modal">
        <v-container>
          <v-card-title class="text-h5">
            {{ $t("admin.manage-customer.deactivate-modal.modal-title") }}
          </v-card-title>
          <v-card-text
            ><div
              v-html="
                $t(`admin.manage-customer.deactivate-modal.modal-body`, {
                  customerName: customerToDeactivate.name
                })
              "
            ></div
          ></v-card-text>

          <div class="tw-m-4">
            <v-form
              @submit.prevent="
                modalTextMatches
                  ? toggleActiveCustomer(customerToDeactivate.id)
                  : null
              "
            >
              <v-text-field
                v-model="modalText"
                :label="`${$t(
                  'admin.manage-customer.deactivate-modal.please-type-yes'
                )}`"
              />
            </v-form>
          </div>
        </v-container>
        <v-card-actions>
          <v-row>
            <v-col class="tw-flex tw-flex-row tw-justify-end">
              <v-btn @click="resetModal" class="tw-mr-5" text>
                {{ $t("ui.actions.cancel") }}
              </v-btn>
              <v-btn
                @click="toggleActiveCustomer(customerToDeactivate.id)"
                color="error"
                :disabled="!modalTextMatches"
                :loading="modalButtonLoading"
              >
                {{
                  $t("admin.manage-customer.deactivate-modal.confirm-button")
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import customerService from "@/api/customerService";
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import { ROUTE_NAMES } from "@/types/routeNames";
import { find, orderBy } from "lodash";

export default {
  data() {
    return {
      customers: [],
      headers: [
        {
          text: i18n.t("admin.customer-list.data-table.customer"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-list.data-table.address"),
          value: "fullAddress",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-list.data-table.city"),
          value: "city",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-list.data-table.state"),
          value: "stateName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-list.data-table.country"),
          value: "countryName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-list.data-table.active"),
          value: "isActive",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-list.data-table.edit"),
          sortable: false,
          class: "tw-align-text-top"
        },
        {
          value: "facilities",
          sortable: false,
          align: " d-none"
        }
      ],
      loading: true,
      PERMISSIONS,
      ROUTE_NAMES,
      search: "",
      showModal: false,
      modalText: "",
      modalButtonLoading: false,
      customerToDeactivate: {}
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"]),
    modalTextMatches() {
      return (
        this.modalText.toLowerCase() ===
        `${i18n.t("admin.manage-customer.deactivate-modal.yes-match")}`
      );
    }
  },
  async created() {
    const customers = await customerService.getAdminCustomerList();

    if (customers.length === 1) {
      this.$router.push({
        name: ROUTE_NAMES.customerDashboard,
        query: { customerId: customers[0].id }
      });
    }

    this.customers = orderBy(
      customers,
      ["isActive", (customer) => customer.name.toLowerCase()],
      ["desc", "asc"]
    );

    this.loading = false;

    const logos = await customerService.getCustomerLogos();
    this.customers = this.customers.map((c) => {
      if (c.mediaItemId) {
        return {
          ...c,
          logoImage: logos.find((l) => l.mediaItemId === c.mediaItemId)?.logo
        };
      } else {
        return c;
      }
    });
  },
  methods: {
    setModal(customerId) {
      this.showModal = true;
      this.customerToDeactivate = find(
        this.customers,
        (c) => c.id === customerId
      );
    },
    resetModal() {
      this.showModal = false;
      this.modalText = "";
      this.modalButtonLoading = false;
    },
    async toggleActiveCustomer(customerId) {
      const customer = find(this.customers, (c) => c.id === customerId);
      customer.isLoading = true;
      this.modalButtonLoading = true;

      try {
        await customerService.setCustomerActiveStatus(
          customer.id,
          !customer.isActive
        );
        customer.isActive = !customer.isActive;
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.customer-list.status-change-error")
        });
      } finally {
        this.resetModal();
        customer.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.multi-address {
  margin-top: 0px;
  margin-bottom: 0px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 110px;
}

.customer-logo {
  max-width: 120px;
  max-height: 90px;
  margin-right: 10px;
  width: auto;
  height: auto;
}

.modal {
  overflow-y: hidden;
}
</style>
