<template>
  <v-dialog
    :value="deleteDialogData.active"
    @input="$emit('toggle-delete-dialog')"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ deleteDialogData.title }}
      </v-card-title>
      <v-card-text>
        <p>
          {{ deleteDialogData.content }}
        </p>
        <strong>{{ deleteDialogData.name }}</strong>
        <slot name="extraContent"></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="purellGray" text @click="$emit('toggle-delete-dialog')">
          {{ $t("ui.actions.cancel") }}
        </v-btn>
        <v-btn
          color="error"
          @click="$emit('deletion-confirmed')"
          :loading="loading"
        >
          {{ $t("slideshows.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    deleteDialogData: {
      type: Object
    },
    loading: {
      type: Boolean,
      defualt: false
    }
  }
};
</script>
