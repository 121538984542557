import { get } from "./apiClient";
import { downloadPDFFile } from "@/utils/downloadFile";

export default {
  async get(reportTitle, reportObj) {
    const params = new URLSearchParams([
      ["reportUrl", reportObj.url],
      ["reportId", reportObj.reportId],
      ["facilityId", reportObj.facilityId],
      ["pdfTitle", reportTitle]
    ]);

    const response = await get(`pdf`, {
      params,
      responseType: "blob",
      headers: {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      }
    });

    downloadPDFFile(response, reportTitle.concat(".pdf"));
  }
};
