<template>
  <div>
    <report-summary :cells="summary" />
    <report-table
      :headers="headers"
      :items="items"
      :initialSortedColumns="['floor']"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import reportMixin from "@/views/reports/mixins/reportMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";

export default {
  mixins: [reportMixin],
  components: { ReportTable, ReportSummary },
  data() {
    return {
      compatibleReportId: 24,
      headers: [
        {
          text: i18n.t("reports.sa.headers.floor"),
          value: "floor",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.unit"),
          value: "unit",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.room"),
          value: "room",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.device-name"),
          value: "deviceName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.device-model"),
          value: "deviceModel",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.serial-number"),
          value: "serialNumber",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      items: [],
      reportFilters: ["locations"],
      hiddenReportActions: ["rawExport", "saveSlide"],
      summary: []
    };
  },

  methods: {
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      this.items = this.reportResponse.dataPoints;
      this.getDeviceSummary();
    },
    getDeviceSummary() {
      this.summary = [];
      const deviceModels = [];
      this.items.forEach((item) => {
        deviceModels.push(item.deviceModel);
      });

      for (let i = 0; i < deviceModels.length; i++) {
        const currentItem = deviceModels[i];
        if (
          this.summary.length &&
          this.summary.some((d) => d.text === currentItem)
        ) {
          const index = this.summary.findIndex(
            (item) => item.text === currentItem
          );
          this.summary[index].value++;
        } else {
          this.summary.push({
            text: currentItem,
            value: 1,
            display: "Locale"
          });
        }
      }
    }
  }
};
</script>
