<template>
  <div>
    <report-performance-bands :performanceBands="performanceBands" />
    <report-graph
      :chartData="chartData"
      :repositionSpeedDial="true"
      @on-clear-graph="onClearGraph"
    />
    <report-table
      :headers="tableData.headers"
      :items="tableData.items"
      :initialSortedColumns="['lastName']"
      :hasSelectableItems="true"
      :rowKey="rowKey"
      :defaultSelectedRows="defaultSelectedRows"
      :clearSelectedItems="clearSelectedItems"
      @reset-clear-selected-items="resetClearSelectedItems"
    />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportPerformanceBands from "@/views/reports/components/reportSections/ReportPerformanceBands.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import {
  sumEvents,
  setEventsIndicator,
  totalPercentage
} from "@/views/reports/utils/calculate";
import { sortEventsByDate } from "@/views/reports/utils/sort";
import { group } from "@/views/reports/utils/group";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import i18n from "@/plugins/i18n";
import { mapState } from "vuex";

export default {
  mixins: [reportMixin],
  components: {
    ReportPerformanceBands,
    ReportGraph,
    ReportTable
  },
  data() {
    return {
      compatibleReportId: 41,
      reportFilters: ["shifts", "imsJobRoles", "locations", "timeframe"],
      hiddenReportActions: ["saveSlide"],
      performanceBands: [],
      defaultSelectedRows: 5,
      rowKey: "employeeId",
      chartData: {},
      tableData: {
        headers: [
          {
            text: i18n.t("reports.table.last-name"),
            value: "lastName",
            sortable: true
          },
          {
            text: i18n.t("reports.table.first-name"),
            value: "firstName",
            sortable: true
          },
          {
            text: i18n.t("reports.table.badge"),
            value: "badgeNumber",
            sortable: true
          },
          {
            text: i18n.t("reports.table.role"),
            value: "jobRole",
            sortable: true
          },
          {
            text: i18n.t("reports.table.total-opportunities"),
            value: "totalOpportunities",
            sortable: true,
            display: "Locale"
          },
          {
            text: i18n.t("reports.table.performance"),
            value: "overallPerformance",
            sortable: true,
            display: "Percentage"
          },
          {
            text: i18n.t("reports.table.trend"),
            value: "entries",
            sortable: false,
            display: "SparkLine"
          }
        ],
        items: []
      },
      clearSelectedItems: false
    };
  },
  mounted() {
    this.$store.commit("reports/setRowKey", this.rowKey);
  },
  beforeDestroy() {
    this.$store.commit("reports/setRowKey", null);
  },
  computed: {
    ...mapState("reports", ["selectedData"])
  },
  watch: {
    selectedData() {
      this.updateGraphItems(this.selectedData);
    }
  },
  methods: {
    createCsvData() {
      let headers = this.tableData.headers;
      headers = headers.filter((header) => {
        const hiddenHeaders = ["indicator", "entries"];
        return !hiddenHeaders.includes(header.value);
      });

      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.tableData.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    createPerformanceBands() {
      this.performanceBands = this.reportResponse.performanceBands;

      if (this.performanceBands.length) {
        const headers = this.tableData.headers;

        headers.push({
          text: i18n.t("reports.table.indicator"),
          value: "indicator",
          sortable: false,
          display: "Indicator"
        });

        this.tableData.headers = headers;
      }
    },
    createTable() {
      let items = this.reportResponse.individualPerformanceDataPoints;

      items = group({
        items,
        key: "badgeNumber",
        commonFields: [
          "badgeNumber",
          "employeeId",
          "jobRole",
          "firstName",
          "lastName"
        ]
      });

      items = sortEventsByDate({ items });

      items = items.map((item) => {
        item.entries = item.entries.map((entry) => {
          entry.performance =
            entry.opportunities === 0 ? null : entry.performance;

          return entry;
        });

        return item;
      });

      items = sumEvents({ items, inputField: "opportunities" });
      items = sumEvents({ items, inputField: "compliantOpportunities" });
      items = totalPercentage({ items });
      items = setEventsIndicator({
        items,
        performanceBands: this.performanceBands
      });

      this.tableData.items = items;
    },
    handleReportResponse() {
      this.createPerformanceBands();
      this.createTable();
    },
    updateGraphItems(selectedItems) {
      const chartSetup = {
        type: "line",
        valueField: "performance",
        datasetField: "fullName",
        valueFieldType: GraphDataTypes.ROUNDED,
        valueScaleOptions: {
          label: i18n.t("reports.graph.performance-axis"),
          position: "left",
          ticks: {
            format: "${value}%"
          }
        },
        dateTimeField: "startDateTime",
        timeframe: this.$store.state["filters/timeframe"]
      };

      const builder = new ChartBuilder({
        displayLegend: true,
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });

      if (!selectedItems?.length) {
        builder.chartTimeSeries({ items: [], ...chartSetup });
      } else {
        const items = selectedItems
          .map((item) => {
            return item.entries.map((entry) => {
              entry.fullName = `${entry.firstName} ${entry.lastName}`;

              return entry;
            });
          })
          .flat(1);
        builder.chartTimeSeries({ items, ...chartSetup });
      }

      this.chartData = builder.build();
    },
    onClearGraph() {
      this.updateGraphItems([]);
      this.clearSelectedItems = true;
    },
    resetClearSelectedItems() {
      this.clearSelectedItems = false;
    }
  }
};
</script>
