export const MONTHS = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12
};

export const MONTHS_STRINGS = {
  january: MONTHS.JANUARY,
  february: MONTHS.FEBRUARY,
  march: MONTHS.MARCH,
  april: MONTHS.APRIL,
  may: MONTHS.MAY,
  june: MONTHS.JUNE,
  july: MONTHS.JULY,
  august: MONTHS.AUGUST,
  september: MONTHS.SEPTEMBER,
  october: MONTHS.OCTOBER,
  november: MONTHS.NOVEMBER,
  december: MONTHS.DECEMBER
};
