<template>
  <div class="tw-flex tw-flex-row tw-w-full">
    <div
      style="top: 10px"
      class="tw-items-start tw-p-0 tw-sticky tw-self-start"
    >
      <v-btn
        v-if="facility.customerName"
        small
        text
        block
        class="tw-justify-start"
        :to="{
          name: ROUTE_NAMES.customerDashboard,
          query: { customerId: facility.customerId }
        }"
        >{{ facility.customerName.toUpperCase() }}
      </v-btn>
      <v-btn
        v-if="facility.name"
        small
        text
        block
        class="tw-justify-start tw-mb-2"
        :to="{
          name: ROUTE_NAMES.facilityDashboard,
          query: {
            customerId: facility.customerId,
            facilityId: facility.id
          }
        }"
        >{{ facility.name.toUpperCase() }}
      </v-btn>
      <v-divider class="tw-py-1" />
      <v-btn
        v-for="setting in settings"
        :key="setting"
        text
        block
        class="tw-justify-start"
        :disabled="chosenSetting === setting"
        :style="
          chosenSetting === setting
            ? {
                backgroundColor: colors.background,
                color: '#000 !important'
              }
            : {}
        "
        @click="chooseSetting(setting)"
        >{{ $t(`admin.manage-facility.settings.${setting}`) }}
      </v-btn>
    </div>
    <v-divider vertical class="tw-py-0 tw-mx-4" />
    <div class="tw-mb-5 tw-w-full">
      <component
        :is="chosenSetting"
        :facility.sync="facility"
        :loading.sync="loading"
        @onSave="loadFacility"
      />
    </div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import facilityService from "@/api/facilityService";
import FacilityBasicInfo from "@/views/admin/components/manageFacility/FacilityBasicInfo.vue";
import ReportSettings from "@/views/admin/components/manageFacility/ReportSettings.vue";
import ObservationSettings from "@/views/admin/components/manageFacility/ObservationSettings.vue";
import ServiceAlertSettings from "@/views/admin/components/manageFacility/ServiceAlertSettings.vue";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import { PRODUCTS } from "@/types/products";
import { ROUTE_NAMES } from "@/types/routeNames";
import colors from "@/colors/colors";

export default {
  components: {
    "basic-info": FacilityBasicInfo,
    "report-settings": ReportSettings,
    "observation-settings": ObservationSettings,
    "service-alert-settings": ServiceAlertSettings
  },
  props: {
    facilityId: { type: Number },
    setting: { type: String, default: "basic-info" }
  },
  data() {
    return {
      chosenSetting: this.setting,
      colors,
      facility: { licenses: [] },
      loading: true,
      ROUTE_NAMES
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"]),
    settings() {
      const settings = [
        "basic-info",
        "report-settings",
        "service-alert-settings"
      ];

      if (
        this.facility?.licenses.includes(PRODUCTS.OBS) ||
        this.checkPermission(PERMISSIONS.manageLicenses)
      ) {
        settings.splice(2, 0, "observation-settings");
      }

      return settings;
    }
  },
  async created() {
    await this.loadFacility();
  },
  methods: {
    chooseSetting(setting) {
      this.chosenSetting = setting;
      this.loadFacility();

      history.replaceState({}, null, `${this.$route.path}?setting=${setting}`);
    },
    async loadFacility() {
      try {
        this.loading = true;

        this.facility = await facilityService.getSettings(this.facilityId);
      } catch (error) {
        if (error === 403) {
          this.$router.go(-1);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
