<template>
  <div class="tw-w-full" v-if="items.length">
    <app-list-popup
      :items="items"
      :value="value"
      @input="(e) => $emit('input', e)"
      @close="visible = false"
      :visible="visible"
      :multiple="false"
      :itemTitleKey="itemTitleKey"
      :itemSubtitleKey="itemSubtitleKey"
      :title="title"
      :searchable="searchable"
    />
    <div :data-disabled="disabled">
      <label class="tw-uppercase tw-font-bold tw-text-purellGray tw-text-xs">
        {{ label }}
      </label>
      <button
        class="
          tw-flex tw-flex-row tw-items-center tw-w-full
          md:tw-w-auto
          tw-justify-between
          md:tw-justify-start
        "
        @click="visible = !visible"
      >
        <span class="tw-font-semibold tw-text-xl tw-truncate tw-mr-2">
          {{ selected ? selected[itemTitleKey] : $t("ui.make-selection") }}
        </span>
        <v-icon v-if="!disabled"> mdi-chevron-down </v-icon>
      </button>
    </div>
  </div>
</template>

<script>
import AppListPopup from "@/components/listPopup/AppListPopup.vue";

export default {
  components: {
    AppListPopup
  },
  data: () => ({
    visible: false
  }),
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    value: Number,
    label: String,
    itemTitleKey: {
      type: String,
      default: "text"
    },
    itemSubtitleKey: {
      type: String,
      default: "subtitle"
    },
    title: String,
    searchable: Boolean
  },
  computed: {
    selected() {
      if (this.items.length > 0) {
        return this.items.find((item) =>
          item?.id ? item.id === this.value : false
        );
      }
      return undefined;
    }
  }
};
</script>

<style lang="postcss" scoped>
div[data-disabled] {
  @apply tw-pointer-events-none tw-cursor-not-allowed;
}
</style>
