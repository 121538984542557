<template>
  <div>
    <report-table
      :headers="reportHeaders"
      :items="items"
      :initialSortedColumns="['floor']"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import reportMixin from "@/views/reports/mixins/reportMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import { alertTypes } from "@/views/reports/SA/alertTypes.js";

export default {
  mixins: [reportMixin],
  components: {
    ReportTable
  },
  data() {
    const commonHeaders = [
      {
        text: i18n.t("reports.sa.headers.floor"),
        value: "floor",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.unit"),
        value: "unit",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.room"),
        value: "room",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-name"),
        value: "deviceName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-type"),
        value: "deviceTypeName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-model"),
        value: "deviceModelName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.serial-number"),
        value: "serialNumber",
        sortable: true,
        class: "tw-align-text-top"
      }
    ];
    return {
      compatibleReportId: 22,
      reportHeaders: [
        ...commonHeaders,
        {
          text: i18n.t("reports.sa.headers.age"),
          value: "alarmAgeHours",
          sortable: true,
          class: "tw-align-text-top",
          displayText: "alarmAgeFormatted"
        },
        {
          text: i18n.t("reports.sa.headers.alert-type"),
          value: "icon",
          sortable: true,
          filterable: false,
          class: "tw-align-text-top",
          display: "TableImage"
        }
      ],
      csvHeaders: [
        ...commonHeaders,
        {
          text: i18n.t("reports.sa.headers.age"),
          value: "alarmAgeFormatted",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.alert-type"),
          value: "alarmTypeName",
          sortable: false,
          class: "tw-align-text-top",
          display: "TableImage"
        }
      ],
      items: [],
      reportFilters: ["locations"],
      hiddenReportActions: ["rawExport", "saveSlide"]
    };
  },
  methods: {
    createCsvData() {
      const headers = this.csvHeaders;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      const items = this.reportResponse.dataPoints;
      this.items = items.map((item) => {
        const alarmAge = item.alarmAge.split(".");
        return {
          floor: item.floor,
          unit: item.unit,
          room: item.room,
          deviceName: item.deviceName,
          deviceTypeName: item.deviceTypeName,
          deviceModelName: item.deviceModelName,
          serialNumber: item.serialNumber,
          alarmAge: item.alarmAge,
          alarmAgeHours: parseInt(alarmAge[0]) * 24 + parseInt(alarmAge[1]),
          alarmAgeFormatted: item.alarmAgeFormatted,
          deviceAlarmTypeId: item.deviceAlarmTypeId,
          alarmTypeName: item.alarmTypeName,
          icon: alertTypes[item.deviceAlarmTypeId].icon,
          translation: alertTypes[item.deviceAlarmTypeId].translation
        };
      });
    }
  }
};
</script>
