import { mapState } from "vuex";
import { ALL_REPORT_DISPLAY_NAMES } from "@/types/allReports";
import { DISPLAY_MODES } from "@/store/modules/application";
import { FILTERS } from "@/types/filters";
import i18n from "@/plugins/i18n";

export default {
  data: () => ({
    compatibleReportId: undefined, // must be set by the component
    refreshInterval: 0
  }),
  props: {
    reportResponse: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState("reports", [
      "isSlideshow",
      "reportId",
      "reportLoading",
      "graphActions",
      "changingReport"
    ]),
    ...mapState("application", ["displayMode"]),
    ...mapState("customers", ["productId"]),
    isPrint() {
      return this.displayMode === DISPLAY_MODES.PRINT;
    }
  },
  created() {
    if (this.isSlideshow) {
      return;
    }

    this.$appInsights?.trackPageView({
      name: `Report View: ${this.productId} ${i18n.t(
        ALL_REPORT_DISPLAY_NAMES[this.reportId]
      )}`
    });

    if (this.reportFilters && this.reportFilters.length) {
      this.$store.commit("reports/setReportFilters", this.reportFilters);
    } else {
      throw Error(
        "This report needs `reportFilters` data to properly function."
      );
    }

    this.$store.commit(
      "reports/setHiddenReportActions",
      this.hiddenReportActions || []
    );

    this.$store.commit(
      `filters/${FILTERS.TIMEFRAME}/setHiddenIds`,
      this.hiddenTimeframes || []
    );

    this.$store.commit(
      "reports/setForcedReportFilters",
      this.forcedReportFilters || []
    );

    this.$store.commit("reports/setChangingReport", false);
  },
  beforeDestroy() {
    if (this.nextReloadTimer) {
      clearTimeout(this.nextReloadTimer);
    }
  },
  watch: {
    reportResponse: {
      immediate: true,
      handler(value) {
        const isEmptyResponse = Object.keys(value).length === 0;

        if (
          this.reportLoading ||
          isEmptyResponse ||
          value.reportId !== this.compatibleReportId
        ) {
          return;
        }
        this.handleReportResponse();

        const hiddenReportActions = this.hiddenReportActions || [];
        if (hiddenReportActions.includes("csvExport")) {
          return;
        }
        this.createCsvData();
      }
    },
    reportLoading(value) {
      if (value && this.nextReloadTimer) {
        clearTimeout(this.nextReloadTimer);
        delete this.nextReloadTimer;
      } else if (!value && this.refreshInterval && !this.isSlideshow) {
        this.nextReloadTimer = setTimeout(() => {
          this.$emit("load-report");
        }, this.refreshInterval);
      }
    }
  }
};
