import store from "@/store/appStore";
import { downloadCSVFile } from "@/utils/downloadFile";

export function downloadAdminCsvFile(headers, rowsData, fileName = "") {
  const language = store ? store.state.preferences.language : "en-US";
  const timestamp = new Date()
    .toLocaleDateString(language, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    })
    .replace(/\//g, "-");

  const sanitizedRowsData = rowsData
    .map((row) => {
      return row.map((data) => {
        return data ? `"${data}"` : data;
      });
    })
    .join("\n");

  downloadCSVFile(headers, sanitizedRowsData, `${timestamp} - ${fileName}`);
}
