<template>
  <v-treeview
    selectable
    selected-color="primary"
    :items="hierarchyItems"
    item-text="text"
    :value="value"
    :open.sync="openItems"
    :open-all="false"
    hoverable
    :selection-type="selectionType"
    :item-children="itemChildrenKey"
    :search="search"
    ref="treeview"
    @input="onSet"
  >
    <template v-slot:append="{ item, selected }">
      <v-btn
        class="toggle-button"
        text
        small
        @click="toggleParent(item, selected)"
        v-if="
          Array.isArray(item[itemChildrenKey]) && item[itemChildrenKey].length
        "
      >
        {{ $t("ui.checkbox-popup.select-all").toUpperCase() }}
      </v-btn>
    </template>
  </v-treeview>
</template>

<script>
import filtersPopupItemsMixin from "../mixins/filtersPopupItemsMixin";
import intersection from "lodash/intersection";

export default {
  mixins: [filtersPopupItemsMixin],
  props: {
    search: {
      type: String,
      default: ""
    },
    hierarchyItems: {
      type: Array,
      default: () => []
    },
    selectionType: {
      type: String,
      default: "independent"
    },
    itemChildrenKey: {
      type: String
    },
    allOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    openItems: []
  }),
  mounted() {
    const topLevelIds = this.hierarchyItems.map((item) => {
      return item.id;
    });

    this.openItems = topLevelIds;
  },
  methods: {
    getLeafIds(location, ids = []) {
      if (location[this.itemChildrenKey]) {
        location[this.itemChildrenKey].forEach((child) =>
          this.getLeafIds(child, ids)
        );
      } else {
        ids.push(location.id);
      }

      return ids;
    },
    getNodeIds(location, ids = []) {
      ids.push(location.id);

      if (location[this.itemChildrenKey]) {
        location[this.itemChildrenKey].forEach((child) =>
          this.getNodeIds(child, ids)
        );
      }

      return ids;
    },
    async toggleParent(location, selected) {
      await this.$nextTick();
      if (this.selectionType === "independent") {
        const nodeIds = this.getNodeIds(location);

        if (intersection(this.value, nodeIds).length !== nodeIds.length) {
          selected = false;
        }

        this.$emit(
          "input",
          selected
            ? this.value.filter((id) => !nodeIds.includes(id))
            : [...this.value, ...nodeIds]
        );
      } else {
        if (!selected) {
          this.$emit("input", [...this.value, location.id]);
        } else {
          const leafIds = this.getLeafIds(location);
          this.$emit(
            "input",
            this.value.filter((id) => !leafIds.includes(id))
          );
        }
      }
    }
  },
  watch: {
    allOpen(value) {
      this.$refs.treeview.updateAll(value);
    }
  }
};
</script>

<style scoped lang="postcss">
.v-treeview-node__content .v-treeview-node__label {
  overflow: visible;
  text-overflow: initial;
  white-space: initial;
}

:v-deep(.v-treeview-node__toggle),
:v-deep(.v-treeview-node__level) {
  height: 35px;
  width: 35px;
  font-size: 28px;
}
</style>
