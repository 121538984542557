<template>
  <div>
    <confirmation-dialog
      @action-confirmed="deleteAlertSubscription"
      @toggle-confirmation-dialog="toggleConfirmationDialog"
      :confirmationDialogData="confirmationDialogData"
    />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      :loading-text="
        $t('alert-subscriptions.gathering-alert-subscription-data')
      "
      :no-data-text="
        $t('alert-subscriptions.no-alert-subscription-in-facility')
      "
    >
      <template v-slot:top>
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            @focus="trackSearchAnalytics()"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.isActive="{ item }">
        <v-switch v-model="item.isActive" @change="toggleAlertStatus(item)" />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.edit="{ item }">
        <v-btn
          :to="{
            name: 'editAlertSubscription',
            query: {
              alertSubscriptionId: item.id,
              isInstantAlert: item.isAlarm
            }
          }"
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.delete="{ item }">
        <v-btn icon @click="toggleConfirmationDialog(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ConfirmationDialog from "@/views/alertSubscriptions/components/ConfirmationDialog.vue";
import alertSubscriptionService from "@/api/alertSubscriptionService";
import i18n from "@/plugins/i18n";
import { mapGetters, mapState } from "vuex";
import { PERMISSIONS } from "@/types/permissions";

export default {
  components: {
    ConfirmationDialog
  },
  data: () => ({
    loading: true,
    items: [],
    confirmationDialogData: {
      isActive: false,
      id: null,
      name: "",
      title: i18n.t("alert-subscriptions.confirmation-dialog.delete-title"),
      content: i18n.t("alert-subscriptions.confirmation-dialog.delete-content"),
      isInstantAlert: false,
      confirmButton: i18n.t("alert-subscriptions.delete")
    },
    localeStringOptions: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit"
    },
    search: ""
  }),
  computed: {
    ...mapState("customers", ["facilityId"]),
    ...mapState("preferences", ["language"]),
    ...mapGetters("user/permissions", ["checkPermission"]),
    headers() {
      const headers = [
        {
          text: i18n.t("alert-subscriptions.name"),
          value: "name",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.type"),
          value: "type",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.last-alert"),
          value: "lastAlertTimeStamp",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.frequency"),
          value: "frequency",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.recipient"),
          value: "recipient",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.active"),
          value: "isActive",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.edit"),
          value: "edit",
          sortable: false
        },
        {
          text: i18n.t("alert-subscriptions.delete"),
          value: "delete",
          sortable: false
        }
      ];

      if (this.checkPermission(PERMISSIONS.viewAllAlertSubscriptions)) {
        headers.splice(1, 0, {
          text: i18n.t("alert-subscriptions.creator"),
          value: "creator",
          sortable: true
        });
      }

      return headers;
    }
  },
  mounted() {
    if (this.facilityId) {
      this.gatherCreatedAlertSubscriptions(this.facilityId);
    }
  },
  methods: {
    async gatherCreatedAlertSubscriptions(facilityId) {
      this.loading = true;

      try {
        const response = await alertSubscriptionService.listCreatedAlerts(
          facilityId
        );

        const language = this.language;
        const localeStringOptions = this.localeStringOptions;

        this.items = response.map(function (item) {
          item.frequency = item.isAlarm
            ? i18n.t("alert-subscriptions.instant")
            : item.frequency;

          item.lastAlertTimeStamp = item.lastAlertTimeStamp
            ? new Date(item.lastAlertTimeStamp).toLocaleString(
                language,
                localeStringOptions
              )
            : "";

          return item;
        });
      } finally {
        this.loading = false;
      }
    },
    async toggleAlertStatus(item) {
      const viewModel = {
        alertSubscriptionId: item.id,
        isActive: item.isActive,
        isInstantAlert: item.isAlarm,
        facilityId: this.facilityId
      };

      try {
        await alertSubscriptionService.toggleAlertStatus(viewModel);
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "alert-subscriptions.error-messages.toggle-alert-status"
          )
        });
      }
    },
    toggleConfirmationDialog(item) {
      this.confirmationDialogData.isActive =
        !this.confirmationDialogData.isActive;
      this.confirmationDialogData.id = this.confirmationDialogData.id
        ? null
        : item.id;
      this.confirmationDialogData.name = this.confirmationDialogData.name
        ? ""
        : item.name;
      this.confirmationDialogData.isInstantAlert = item?.isAlarm;

      const deleteDialogStatus = this.confirmationDialogData.isActive
        ? "Open"
        : "Close";

      this.$appInsights?.trackEvent({
        name: `${deleteDialogStatus} Alert Subscription Delete Dialog`
      });
    },
    async deleteAlertSubscription() {
      const alertSubscription = this.confirmationDialogData;
      const itemId = alertSubscription.id;

      const viewModel = {
        alertSubscriptionId: itemId,
        isInstantAlert: alertSubscription.isInstantAlert,
        facilityId: this.facilityId
      };

      try {
        await alertSubscriptionService.deleteAlertSubscription(viewModel);
        this.$appInsights?.trackEvent({
          name: `Alert Subscription Successfully Deleted`
        });
        this.removeItemById(itemId);
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "alert-subscriptions.error-messages.delete-alert-subscription"
          )
        });
      }
      this.toggleConfirmationDialog();
    },
    removeItemById(id) {
      const index = this.items.findIndex((item) => item.id === id);

      this.items.splice(index, 1);
    },
    trackSearchAnalytics() {
      this.$appInsights?.trackEvent({
        name: "Alert Subscription Search Interaction"
      });
    }
  },
  watch: {
    async facilityId(value) {
      this.gatherCreatedAlertSubscriptions(value);
    }
  }
};
</script>
