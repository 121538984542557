<template>
  <div>
    <report-table :headers="headers" :items="items" />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportTable
  },
  data() {
    return {
      compatibleReportId: 40,
      reportFilters: ["shifts", "imsJobRoles", "locations", "timeframe"],
      hiddenReportActions: ["saveSlide"],
      items: [],
      headers: [
        {
          text: i18n.t("locations.locationlevellocation-singular"),
          value: "locationName",
          sortable: true
        },
        {
          text: i18n.t("reports.table.soap-events"),
          value: "soapEvents",
          sortable: true,
          display: "Locale"
        },
        {
          text: i18n.t("reports.table.soap-in-performance"),
          value: "soapInPerformance",
          sortable: true
        },
        {
          text: i18n.t("reports.table.soap-out-performance"),
          value: "soapOutPerformance",
          sortable: true
        },
        {
          text: i18n.t("reports.table.sanitizer-events"),
          value: "sanitizerEvents",
          sortable: true,
          display: "Locale"
        },
        {
          text: i18n.t("reports.table.sanitizer-in-performance"),
          value: "sanitizerInPerformance",
          sortable: true
        },
        {
          text: i18n.t("reports.table.sanitizer-out-performance"),
          value: "sanitizerOutPerformance",
          sortable: true
        }
      ]
    };
  },
  methods: {
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      this.items = this.reportResponse.dataPoints;
    }
  }
};
</script>
