<template>
  <div>
    <ValidationObserver
      ref="manageObservationSettingsForm"
      v-slot="{ pristine, invalid }"
    >
      <v-form @submit.prevent="validateAndSave">
        <div v-if="checkPermission(PERMISSIONS.toggleCustomObservationNotes)">
          <h3>
            {{
              $t(
                "admin.manage-facility.observation-settings.custom-observation-notes"
              )
            }}
          </h3>
          <p>
            {{
              $t(
                "admin.manage-facility.observation-settings.custom-notes-description"
              )
            }}
          </p>
          <ValidationProvider>
            <v-checkbox
              v-model="allowCustomObsNotes"
              :label="
                $t(
                  'admin.manage-facility.observation-settings.enable-custom-notes'
                )
              "
            />
          </ValidationProvider>
        </div>
        <h3>
          {{ $t("admin.manage-facility.observation-settings.quick-notes") }}
        </h3>
        <v-data-table
          :items="observationNotes"
          :headers="headers"
          :search="search"
          item-key="id"
          :no-data-text="$t('admin.manage-facility.observation-settings.empty')"
          :items-per-page="-1"
        >
          <template v-slot:top>
            <div
              class="
                tw-flex tw-justify-between tw-items-center tw-top-0 tw-bg-white
              "
              style="z-index: 2"
            >
              <v-text-field
                v-model="search"
                :label="`${$t('ui.input-select.search')}`"
                class="tw-mr-4"
                clearable
                prepend-inner-icon="mdi-magnify"
              />
              <v-btn
                class="tw-max-w-xs"
                primary
                color="primary"
                @click="addNewNote"
              >
                {{ $t("admin.manage-facility.observation-settings.new-note") }}
              </v-btn>
              <v-btn
                v-if="checkPermission(PERMISSIONS.cloneObservationNotes)"
                :disabled="quickNoteAdded || observationNotes.length === 0"
                class="tw-max-w-xs tw-ml-2"
                primary
                color="primary"
                @click="openCloneObservationNotesDialog"
              >
                {{ $t("admin.manage-facility.clone-observation-notes.button") }}
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.text`]="{ item }">
            <ValidationProvider
              v-if="item.newlyAdded"
              :name="
                $tc('admin.manage-facility.observation-settings.new-note-text')
              "
              :rules="{
                required: true,
                max: 25,
                isNotInList: allQuickNotes
              }"
              v-slot="{ errors }"
              tag="div"
              class="lg:tw-w-3/4"
            >
              <v-text-field
                v-model="item.text"
                :label="
                  $t('admin.manage-facility.observation-settings.new-note-text')
                "
                :error-messages="errors"
                :counter="25"
              />
            </ValidationProvider>
            <span v-else>{{ item.text }}</span>
          </template>
          <template v-slot:[`item.isArchived`]="{ item }">
            <v-btn
              v-if="item.newlyAdded"
              color="error"
              @click="removeNote(item.id)"
              >{{
                $t("admin.manage-facility.observation-settings.remove")
              }}</v-btn
            >
            <ValidationProvider v-else>
              <v-switch
                v-model="item.isArchived"
                :true-value="false"
                :false-value="true"
              />
            </ValidationProvider>
          </template>
        </v-data-table>
        <h3>
          {{
            $t("admin.manage-facility.observation-settings.feedback-provided")
          }}
        </h3>
        <p>
          {{
            $t(
              "admin.manage-facility.observation-settings.feedback-provided-description"
            )
          }}
        </p>
        <ValidationProvider>
          <v-checkbox
            v-model="allowFeedback"
            :label="
              $t('admin.manage-facility.observation-settings.enable-feedback')
            "
          />
        </ValidationProvider>
        <v-row class="tw-justify-end">
          <v-btn
            class="tw-mr-5 tw-w-24"
            :disabled="(pristine && !quickNoteAdded) || invalid"
            color="primary"
            type="submit"
          >
            {{ $t("ui.actions.save") }}
          </v-btn>
          <v-btn
            :disabled="pristine && !quickNoteAdded"
            class="tw-mr-5 tw-w-24"
            color="background2"
            @click="setSettings(facility)"
          >
            {{ $t("ui.actions.reset") }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-dialog
      v-model="showCloneObservationNotesDialog"
      max-width="80%"
      class="tw-overflow-hidden"
    >
      <v-card>
        <v-card-title>
          {{
            $t("admin.manage-facility.clone-observation-notes.title", {
              facilityName: facility.name
            })
          }}
        </v-card-title>
        <v-card-text style="padding-bottom: 0">
          <template v-if="loadingObvLicensedFacilities">
            <p>
              {{ $t("admin.obv-job-role.loading-facilities") }}
            </p>
            <v-progress-linear indeterminate />
          </template>
          <template v-else>
            <p>
              {{
                $t("admin.manage-facility.clone-observation-notes.directions", {
                  facilityName: facility.name
                })
              }}
            </p>

            <ValidationObserver ref="cloneObservationNotesForm">
              <v-form @submit.prevent="cloneObservationNotes">
                <ValidationProvider
                  :name="$t('admin.customer-dashboard.facilities')"
                  :rules="{ required: true }"
                  v-slot="{ label, errors }"
                >
                  <v-select
                    v-model="selectedFacilityIds"
                    :error-messages="errors"
                    :label="label"
                    :placeholder="$t('admin.obv-job-role.select-facilities')"
                    :items="allFacilities"
                    deletable-chips
                    clearable
                    chips
                    multiple
                  />
                </ValidationProvider>
              </v-form>
            </ValidationObserver>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col class="tw-flex tw-flex-row tw-justify-end">
                <v-btn
                  @click="closeCloneObservationNotesDialog"
                  class="tw-mr-5"
                  text
                >
                  {{ $t("ui.actions.cancel") }}
                </v-btn>
                <v-btn
                  @click="cloneObservationNotes()"
                  color="primary"
                  :disabled="selectedFacilityIds.length === 0"
                  :loading="waiting"
                >
                  {{
                    $t("admin.manage-facility.clone-observation-notes.button")
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import facilityService from "@/api/facilityService";
import customerService from "@/api/customerService";
import { mapGetters } from "vuex";
import { orderBy, cloneDeep } from "lodash";
import i18n from "@/plugins/i18n";
import { uuidv4 } from "@/utils/generators";
import { PERMISSIONS } from "@/types/permissions";
import { PRODUCTS } from "@/types/products";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    facility: {
      type: Object,
      default: () => {
        return {};
      }
    },
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      allowCustomObsNotes: this.facility.allowCustomObsNotes,
      allowFeedback: this.facility.enableObservationFeedback,
      headers: [
        {
          text: i18n.t("admin.manage-facility.observation-settings.note-text"),
          value: "text",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-list.data-table.active"),
          value: "isArchived",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      observationNotes: [],
      PERMISSIONS,
      search: "",
      showCloneObservationNotesDialog: false,
      loadingObvLicensedFacilities: true,
      allFacilities: [],
      selectedFacilityIds: [],
      waiting: false
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"]),
    quickNoteAdded() {
      return (
        this.observationNotes.length !== this.facility.observationNotes?.length
      );
    },
    allQuickNotes() {
      return this.observationNotes.map((note) => note.text);
    }
  },
  methods: {
    async openCloneObservationNotesDialog() {
      this.$appInsights?.trackEvent({
        name: `Open Clone Observation Notes Dialog`
      });

      this.showCloneObservationNotesDialog = true;

      if (!this.loadingObvLicensedFacilities) {
        return;
      }

      try {
        const facilities = await customerService.getListOfFacilities(
          this.facility.customerId
        );
        const obvFacilities = facilities.filter(
          (facility) =>
            facility.licenses.some((license) => license === PRODUCTS.OBS) &&
            facility.facilityId !== this.facility.id
        );
        this.allFacilities = obvFacilities.map((facility) => {
          return {
            text: facility.facilityName,
            value: facility.facilityId
          };
        });
        this.loadingObvLicensedFacilities = false;
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.manage-facility.clone-observation-notes.error-message"
          )
        });
      }
    },
    closeCloneObservationNotesDialog() {
      this.showCloneObservationNotesDialog = false;
      this.selectedFacilityIds = [];
      this.$refs.cloneObservationNotesForm?.reset();
    },
    async cloneObservationNotes() {
      const valid = await this.$refs.cloneObservationNotesForm.validate();

      if (!valid) {
        this.$appInsights?.trackEvent({
          name: `Clone Observation Notes Form Error`
        });
        return;
      }

      this.waiting = true;
      try {
        await facilityService.cloneObservationNotes(
          this.facility.id,
          this.selectedFacilityIds
        );
        this.$appInsights?.trackEvent({
          name: `Clone Observation Notes Form Success`
        });
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t(
            "admin.manage-facility.clone-observation-notes.success-message"
          )
        );
        this.closeCloneObservationNotesDialog();
      } catch {
        this.$appInsights?.trackEvent({
          name: `Clone Observation Notes Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.manage-facility.clone-observation-notes.error-message"
          )
        });
      } finally {
        this.waiting = false;
      }
    },
    addNewNote() {
      this.observationNotes.unshift({
        id: uuidv4(),
        isArchived: false,
        newlyAdded: true,
        text: ""
      });
    },
    removeNote(id) {
      this.observationNotes = this.observationNotes.filter((n) => n.id !== id);
    },
    setSettings(settings) {
      this.allowCustomObsNotes = settings.allowCustomObsNotes;
      this.allowFeedback = settings.enableObservationFeedback;
      this.observationNotes = orderBy(
        cloneDeep(settings.observationNotes),
        ["isArchived", (item) => item.text.toLowerCase()],
        ["asc", "asc"]
      );

      if (this.$refs.manageObservationSettingsForm) {
        this.$refs.manageObservationSettingsForm.reset();
      }
    },
    async validateAndSave() {
      const valid = await this.$refs.manageObservationSettingsForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Facility Report Setting's Form Error`
        });
        return;
      }

      this.$emit("update:loading", true);

      try {
        const settings = {
          allowCustomObsNotes: this.allowCustomObsNotes,
          enableObservationFeedback: this.allowFeedback,
          observationNotes: this.observationNotes.map((n) => {
            return {
              id: n.newlyAdded ? 0 : n.id,
              text: n.text,
              facilityId: this.facility.id,
              isArchived: n.isArchived
            };
          })
        };

        await facilityService.updateObservationSettings(
          this.facility.id,
          settings
        );

        this.observationNotes.forEach((n) => {
          if (n.newlyAdded) {
            n.newlyAdded = false;
          }
        });

        this.$refs.manageObservationSettingsForm.reset();

        this.$appInsights?.trackEvent({
          name: `Observation Settings Form Success`
        });
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t("admin.manage-facility.success-messages.update")
        );
        this.$emit("onSave");
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Observation Settings Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.manage-facility.error-messages.save-facility")
        });
      }

      this.$emit("update:loading", false);
    }
  },
  watch: {
    facility: {
      immediate: true,
      handler(value) {
        if (Object.keys(value).length) {
          this.setSettings(value);
        }
      }
    }
  }
};
</script>
