<template>
  <dashboard-widget
    ref="userWidget"
    :title="
      facilityId
        ? 'admin.facility-dashboard.facility-users'
        : 'admin.customer-dashboard.users'
    "
    @fullscreen="(value) => (fullscreen = value)"
  >
    <template>
      <div>
        <div v-if="showCreateView || showEditView">
          <manage-user
            :customerId="customerId"
            :facilityId="facilityId"
            :username="showEditView ? userToEdit : null"
            @close-create-edit-view="closeCreateEditView"
          />
        </div>
        <div v-show="!showCreateView && !showEditView">
          <v-data-table
            class="users-data-table small-headers"
            :items="showPendingUsers ? pendingUsers : filteredUsers"
            :headers="headers"
            :search="search"
            :no-data-text="$t('admin.customer-dashboard.users-widget.empty')"
            item-key="id"
            :loading="loading"
            :loading-text="$t('admin.customer-dashboard.users-widget.loading')"
            :height="fullscreen ? '55vh' : ''"
            @update:page="setScrollPosition"
          >
            <template v-slot:top>
              <div
                class="
                  tw-flex
                  tw-justify-between
                  tw-items-center
                  tw-sticky
                  tw-top-0
                  tw-bg-white
                "
                style="z-index: 2"
              >
                <v-text-field
                  v-model="search"
                  :label="`${$t('ui.input-select.search')}`"
                  class="tw-mr-4"
                  clearable
                  prepend-inner-icon="mdi-magnify"
                />
                <v-btn
                  class="tw-max-w-xs"
                  primary
                  color="primary"
                  @click="showCreateView = true"
                >
                  {{ $t("admin.customer-dashboard.users-widget.new-user") }}
                </v-btn>
              </div>
            </template>
            <template
              v-if="fullscreen"
              v-slot:[`item.lastLoginTimestamp`]="{ item }"
            >
              <td>
                {{
                  item.lastLoginTimestamp
                    ? formatTimestamp(item.lastLoginTimestamp)
                    : ""
                }}
              </td>
            </template>
            <template v-slot:[`item.isActive`]="{ item }">
              <v-switch
                :input-value="item.isActive"
                :loading="item.isLoading"
                :disabled="!item.canToggleActiveStatus"
                :readonly="item.canToggleActiveStatus"
                @click="toggleUserStatus(item)"
              />
            </template>
            <template v-slot:[`item.invitationSent`]="{ item }">
              <td class="tw-mr-4">
                {{ formatTimestamp(item.invitationSent) }}
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item, index }">
              <td>
                <div class="tw-flex tw-flex-row tw-items-center">
                  <v-tooltip :open-delay="500" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="openEditView(item.userName)"
                        :disabled="!item.canBeEdited"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("users-list.actions.edit-user") }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="item.lockoutEndDateUtc"
                    :open-delay="500"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="resetAccountLockout(item)"
                        :loading="item.unlocking"
                      >
                        <v-icon>mdi-lock-open</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("admin.user.account-lockout") }}
                    </span>
                  </v-tooltip>
                  <v-tooltip v-if="showPendingUsers" :open-delay="500" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="resendEmail(item.userName, index)"
                        :loading="item.resending"
                        :disabled="item.resending"
                      >
                        <v-icon>mdi-email-sync-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("users-list.actions.resend-email") }}
                    </span>
                  </v-tooltip>
                  <v-tooltip v-if="showPendingUsers" :open-delay="500" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="revokePendingUser(item, index)"
                        :loading="item.canceling"
                      >
                        <v-icon>mdi-cancel</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("users-list.actions.cancel-user") }}
                    </span>
                  </v-tooltip>
                </div>
              </td>
            </template>
            <template v-slot:[`footer.prepend`]>
              <div class="tw-flex tw-flex-row tw-items-center">
                <v-tooltip v-if="showExportButton" :open-delay="500" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        showPendingUsers ? exportPendingUsers() : exportUsers()
                      "
                      :loading="exporting"
                    >
                      <v-icon>mdi-file-export</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      $t("admin.customer-dashboard.users-widget.export-users")
                    }}
                  </span>
                </v-tooltip>
                <v-checkbox
                  v-if="!showPendingUsers && (!loading || users.length)"
                  v-model="showDeactivatedUsers"
                  :label="
                    $t(
                      'admin.customer-dashboard.users-widget.show-deactivated-users'
                    )
                  "
                  class="tw-ml-4"
                  style="margin-top: 0px"
                  dense
                  hide-details
                />
              </div>
            </template>
          </v-data-table>
        </div>
        <v-dialog v-model="showModal" max-width="500">
          <v-card>
            <v-card-title>
              {{
                $t(
                  "admin.customer-dashboard.users-widget.deactivate-user-header",
                  {
                    name: conflictedUser.fullName
                  }
                )
              }}
            </v-card-title>
            <v-card-text>
              <div
                v-if="conflictedUser.subscriptions.recipientOf.length"
                class="tw-mb-5"
              >
                <p>
                  {{ $t("admin.customer-dashboard.users-widget.recipient-of") }}
                </p>
                <ul>
                  <li
                    v-for="item in conflictedUser.subscriptions.recipientOf"
                    :key="item"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div v-if="conflictedUser.subscriptions.lastRecipientOf.length">
                <p>
                  {{
                    $t(
                      "admin.customer-dashboard.users-widget.last-recipient-of"
                    )
                  }}
                </p>
                <ul>
                  <li
                    v-for="item in conflictedUser.subscriptions.lastRecipientOf"
                    :key="item"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="purellGray" text @click="showModal = false">
                {{ $t("ui.actions.cancel") }}
              </v-btn>
              <v-btn
                color="error"
                @click="toggleUserStatus(conflictedUser, true)"
                :loading="conflictedUser.isLoading"
              >
                {{ $t("admin.customer-dashboard.users-widget.deactivate") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template
      v-if="!showCreateView && !showEditView"
      v-slot:additional-widget-action
    >
      <v-btn-toggle v-model="showPendingUsers" mandatory>
        <v-btn small>
          {{ $t("admin.customer-dashboard.users-widget.users") }}
        </v-btn>
        <v-btn small>
          {{ $t("admin.customer-dashboard.users-widget.pending-users") }}
        </v-btn>
      </v-btn-toggle>
    </template>
  </dashboard-widget>
</template>

<script>
import DashboardWidget from "@/views/admin/components/DashboardWidget.vue";
import ManageUser from "@/views/admin/components/customerWidgets/manageWidgetViews/ManageUser.vue";
import userService from "@/api/userService";
import { mapState } from "vuex";
import { orderBy } from "lodash";
import { downloadAdminCsvFile } from "@/views/admin/utils/downloadAdminCsvFile";
import i18n from "@/plugins/i18n";
import { convertDateForExport } from "@/utils/dateUtils";

export default {
  components: {
    DashboardWidget,
    ManageUser
  },
  props: {
    customerId: { type: Number },
    facilityId: { type: Number },
    exportName: { type: String }
  },
  data() {
    return {
      conflictedUser: {
        subscriptions: { recipientOf: [], lastRecipientOf: [] }
      },
      exporting: false,
      fullscreen: false,
      hideAction: false,
      loading: true,
      pendingUsers: [],
      search: "",
      showCreateView: false,
      showDeactivatedUsers: false,
      showEditView: false,
      showModal: false,
      showPendingUsers: 0,
      users: [],
      userToEdit: ""
    };
  },
  computed: {
    ...mapState("preferences", ["language"]),
    headers() {
      const commonHeaders = [
        {
          text: i18n.t("admin.customer-dashboard.users-widget.department"),
          value: "department",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-dashboard.users-widget.name"),
          value: "fullName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-dashboard.users-widget.email"),
          value: "email",
          sortable: true,
          class: "tw-align-text-top"
        }
      ];

      if (this.showPendingUsers) {
        return [
          ...commonHeaders,
          {
            text: i18n.t("user.invitation-sent"),
            value: "invitationSent",
            sortable: true,
            class: "tw-align-text-top"
          },
          {
            text: i18n.t("admin.customer-dashboard.facility-widget.actions"),
            value: "actions",
            sortable: false,
            class: "tw-align-text-top"
          }
        ];
      } else {
        return [
          ...commonHeaders,
          {
            text: i18n.t("admin.customer-dashboard.users-widget.role"),
            value: "roles",
            sortable: true,
            class: "tw-align-text-top"
          },
          {
            text: i18n.t("admin.customer-dashboard.users-widget.last-login"),
            value: "lastLoginTimestamp",
            sortable: true,
            class: "tw-align-text-top",
            align: this.fullscreen ? undefined : " d-none"
          },
          {
            text: i18n.t("admin.customer-dashboard.users-widget.last-30-days"),
            value: "last30DaysLogins",
            sortable: true,
            class: "tw-align-text-top",
            align: this.fullscreen ? undefined : " d-none"
          },
          {
            text: i18n.t("admin.customer-list.data-table.active"),
            value: "isActive",
            sortable: true,
            class: "tw-align-text-top"
          },
          {
            text: i18n.t("admin.customer-dashboard.facility-widget.actions"),
            value: "actions",
            sortable: false,
            class: "tw-align-text-top"
          }
        ];
      }
    },
    showExportButton() {
      if (this.showPendingUsers) {
        return this.pendingUsers.length > 0;
      } else {
        return this.users.length > 0;
      }
    },
    filteredUsers() {
      return this.showDeactivatedUsers
        ? this.users
        : this.users.filter((u) => u.isActive === true);
    }
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    async loadUsers() {
      this.loading = true;
      try {
        userService.getUsersByCustomerId(this.customerId).then((foundUsers) => {
          foundUsers.forEach((user) => {
            user.unlocking = false;
          });
          const users = orderBy(
            foundUsers,
            [
              "isActive",
              (user) => user.roles?.toLowerCase(),
              (user) => user.fullName?.toLowerCase()
            ],
            ["desc", "asc", "asc"]
          ).map((u) => {
            return { ...u, isLoading: false };
          });

          this.users = this.facilityId
            ? this.filterUsersByFacilityId(users)
            : users;
          this.loading = false;
        });
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.users-widget.error-messages.get-users"
          )
        });
        this.loading = false;
      }
      this.getPendingUsers();
    },
    async revokePendingUser(user, index) {
      this.pendingUsers[index].canceling = true;
      user.customerId = this.customerId;

      try {
        await userService.revokePendingUser(user);
        this.$refs.userWidget.setLocalizedWidgetMessage({
          message: i18n.t(
            "admin.customer-dashboard.users-widget.success-messages.revoke-invitation"
          )
        });
        this.getPendingUsers();
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.users-widget.error-messages.revoke-invitation"
          )
        });

        this.pendingUsers[index].canceling = false;
      }
    },
    exportPendingUsers() {
      this.exporting = true;
      const headers = [
        i18n.t("admin.user.first-name"),
        i18n.t("admin.user.last-name"),
        i18n.t("admin.user.user-name"),
        i18n.t("admin.customer-dashboard.users-widget.department"),
        i18n.t("user.invitation-sent")
      ];

      const rowsData = this.pendingUsers.map((user) => {
        const invitationDate = this.formatTimestamp(user.invitationSent);

        return [
          user.firstName,
          user.lastName,
          user.userName,
          user.department,
          invitationDate
        ];
      });

      downloadAdminCsvFile(
        headers,
        rowsData,
        `${this.exportName} ${i18n.t("admin.customer-dashboard.pending-users")}`
      );
      this.exporting = false;
    },
    exportUsers() {
      this.exporting = true;
      const headers = [
        i18n.t("admin.user.first-name"),
        i18n.t("admin.user.last-name"),
        i18n.t("admin.user.user-name"),
        i18n.t("admin.customer-list.data-table.active"),
        i18n.t("admin.customer-dashboard.users-widget.last-login"),
        i18n.t("admin.customer-dashboard.users-widget.last-30-days"),
        i18n.t("admin.customer-dashboard.users-widget.role"),
        i18n.t("admin.customer-dashboard.users-widget.department"),
        i18n.t("admin.user.facility")
      ];

      const rowsData = [];

      this.users.forEach((user) => {
        const userData = [
          user.firstName,
          user.lastName,
          user.userName,
          user.isActive,
          convertDateForExport(user.lastLoginTimestamp, this.language),
          user.last30DaysLogins
        ];

        user.userRoleAssociations.forEach((ra) => {
          const roleData = [
            ra.roleName,
            user.department,
            ra.facilityName
              ? ra.facilityName
              : i18n.t("admin.user.all-facilities")
          ];

          rowsData.push([...userData, ...roleData]);
        });
      });

      downloadAdminCsvFile(
        headers,
        rowsData,
        `${this.exportName} ${i18n.t("admin.customer-dashboard.users")}`
      );
      this.exporting = false;
    },
    async getPendingUsers() {
      try {
        userService
          .getPendingUsersByCustomerId(this.customerId)
          .then((foundUsers) => {
            foundUsers.forEach((user) => {
              user.resending = false;
              user.canceling = false;
            });

            this.pendingUsers = orderBy(foundUsers, (user) => user.fullName);
            if (this.facilityId) {
              this.pendingUsers = this.filterUsersByFacilityId(
                this.pendingUsers
              );
            }
          });
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.users-widget.error-messages.get-pending-users"
          )
        });
      }
    },
    filterUsersByFacilityId(users) {
      return users.filter((u) =>
        u.userRoleAssociations.some(
          (r) => r.facilityId === this.facilityId || r.facilityId === null
        )
      );
    },
    async resendEmail(username, index) {
      this.pendingUsers[index].resending = true;

      try {
        await userService.resendEmail(this.customerId, username);
        this.$refs.userWidget.setLocalizedWidgetMessage({
          message: i18n.t(
            "admin.customer-dashboard.users-widget.success-messages.resend-email"
          )
        });
        await this.getPendingUsers();
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.users-widget.error-messages.resend-email"
          )
        });
      } finally {
        this.pendingUsers[index].resending = false;
      }
    },
    async resetAccountLockout(user) {
      try {
        user.unlocking = true;
        await userService.resetAccountLockout(user.username, this.customerId);
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.user.error-messages.reset-account-lockout")
        });
      } finally {
        user.unlocking = false;
      }
    },
    setScrollPosition() {
      document.querySelector(
        "div.users-data-table div.v-data-table__wrapper"
      ).scrollTop = 0;
    },
    async toggleUserStatus(user, force = false) {
      if (user.canToggleActiveStatus) {
        user.isLoading = true;

        try {
          if (user.isActive && !force) {
            const subscriptions = await userService.getUserReportSubscriptions(
              user.userName
            );

            if (
              subscriptions.lastRecipientOf.length ||
              subscriptions.recipientOf.length
            ) {
              this.conflictedUser = user;
              this.conflictedUser.subscriptions = subscriptions;
              this.showModal = true;
              return;
            }
          }

          await userService.setUserActiveStatus(
            user.userName,
            !user.isActive,
            force
          );

          user.isActive = !user.isActive;
          this.showModal = false;
          this.conflictedUser = {
            subscriptions: { recipientOf: [], lastRecipientOf: [] }
          };
        } catch (err) {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t(
              "admin.customer-dashboard.users-widget.error-messages.status-change",
              { username: user.userName }
            )
          });
        } finally {
          user.isLoading = false;
        }
      }
    },
    formatTimestamp(timestamp) {
      return new Date(`${timestamp}Z`).toLocaleString(this.language, {
        dateStyle: "short",
        timeStyle: "short"
      });
    },
    openEditView(username) {
      this.userToEdit = username;
      this.showEditView = true;
    },
    closeCreateEditView(data = {}) {
      this.showCreateView = false;
      this.showEditView = false;

      if (data.reloadUsers) {
        this.loadUsers();
      }

      if (data.widgetMessage) {
        this.$refs.userWidget.setLocalizedWidgetMessage({
          message: data.widgetMessage
        });
      }
    }
  }
};
</script>

<style scoped lang="postcss">
:v-deep(.v-input--switch > .v-input__control > .v-input__slot > .v-label) {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
}

:deep(.v-data-footer) {
  position: sticky;
  bottom: 0;
  background: #fff;
}
</style>
