<template>
  <v-app-bar flat>
    <div
      class="tw-flex-1 tw-flex tw-flex-row tw-justify-start xl:tw-flex-grow-0"
    >
      <v-app-bar-nav-icon
        ref="app-drawer-button"
        id="header__hamburger-button"
        class="tw-visible xl:tw-hidden"
        @click.prevent="$store.commit('application/TOGGLE_NAV_DRAWER')"
      />
    </div>
    <a id="logo-anchor" :href="redirectionRoutePath">
      <img :src="logo" style="max-width: 78px" class="tw-mr-6" />
    </a>
    <div
      id="toolbar-menu-div"
      v-if="$vuetify.breakpoint.lgAndUp"
      class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-h-full"
      name="go-desktop-nav"
    >
      <v-toolbar-items name="go-header-toolbar">
        <v-btn
          v-for="item in menuItems.primaryMenuItems"
          :key="item.href"
          text
          :name="item.name"
          :href="item.href"
          :target="item.target"
          :color="getColor(item.href)"
        >
          {{ $t(item.translationKey) }}
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-if="$store.state.user.userName">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              ref="username-menu"
              text
              style="height: inherit"
              v-on="on"
              :color="getColor(menuItems.secondaryMenuItems.map((m) => m.href))"
              name="go-desktop-menu-btn"
            >
              {{ $store.state.user.userName }}
              <v-icon class="tw-ml-1"> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list name="go-desktop-menu">
            <v-list-item
              v-for="item in menuItems.secondaryMenuItems"
              :key="item.href"
              :href="item.href"
              :target="item.target"
            >
              <v-list-item-title>
                {{ $t(item.translationKey) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              ref="logout-menu"
              @click="$emit('log-out')"
              name="go-logout-btn"
            >
              <v-list-item-title>
                {{ $t("navigation.log-out") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-skeleton-loader
        ref="user-menu-skeleton"
        type="button"
        v-else
        class="tw-self-center tw-flex tw-flex-row"
      />
    </div>
  </v-app-bar>
</template>

<script>
import logo from "@/assets/images/logos/purellLogo.svg";
import { menuItems } from "./menuItems";
import { ROUTE_NAMES } from "@/types/routeNames";

export default {
  data: () => ({
    logo: logo
  }),
  computed: {
    menuItems() {
      return menuItems(this.$store, this.$router);
    },
    redirectionRoutePath() {
      return this.$router.resolve({ name: ROUTE_NAMES.redirectionRoute })
        .resolved.path;
    }
  },
  methods: {
    getColor(hrefs) {
      return this.checkActive(hrefs) ? "primary" : "purellGray";
    },
    checkActive(hrefs) {
      hrefs = [].concat(hrefs);
      return this.$router.currentRoute?.matched?.some((r) =>
        hrefs.some((href) => r.path === href.replace(/\/$/, ""))
      );
    }
  }
};
</script>

<style scoped lang="postcss">
:deep(.v-skeleton-loader__button) {
  @apply tw-mx-1 tw-w-20;
}
</style>
