<template>
  <div class="sm:tw-table print:tw-table">
    <div
      v-for="filterText in filterTexts"
      v-bind:key="filterText.title"
      :class="[
        filterText.disabled
          ? 'tw-text-lightPurellGray'
          : 'hover:tw-underline tw-cursor-pointer',
        'smartlink-filter-row sm:tw-table-row print:tw-table-row'
      ]"
      @click="updateOutsideSelectedFilter(filterText)"
    >
      <div
        class="
          sm:tw-table-cell
          print:tw-table-cell
          tw-whitespace-no-wrap
          tw-font-bold
          tw-pl-2
          tw-w-1
          tw-pr-4
          tw-pb-1
          tw-align-text-top
          tw-text-right
        "
      >
        {{ filterText.title }}
      </div>
      <div
        class="
          smartlink-filter-row-text
          sm:tw-table-cell
          print:tw-table-cell
          tw-pl-2 tw-pb-1
        "
      >
        {{ filterText.text }}
        <v-icon
          v-if="!filterText.disabled"
          class="smartlink-filter-row-icon"
          style="margin-top: -3px"
          small
        >
          mdi-pencil
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterTexts: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateOutsideSelectedFilter(filterText) {
      if (filterText.disabled) {
        return;
      }

      this.$emit("update-outside-selected-filter", filterText.id);
    }
  }
};
</script>

<style scoped>
.smartlink-filter-row-icon {
  display: none;
}
.smartlink-filter-row:hover .smartlink-filter-row-icon {
  display: inline-flex;
}
@media only screen and (max-width: 750px) {
  .smartlink-filter-row-icon {
    display: inline-flex;
  }
}
</style>
