<template>
  <div>
    <h1 class="tw-mb-5">
      {{ $t("unlicensed-account.header") }}
    </h1>
    <p>
      {{ $t("unlicensed-account.body") }}
    </p>
    <v-card elevation="1" color="background2" class="tw-p-5">
      {{ $t("unlicensed-account.footer1") }}
      <a
        target="_blank"
        href="https://www.gojo.com/en/Electronic-Monitoring-Systems/Healthcare-EMS"
      >
        https://www.gojo.com/en/Electronic-Monitoring-Systems/Healthcare-EMS</a
      >
      {{ $t("unlicensed-account.footer2") }}
      <a href="mailto:smartlinksupport@gojo.com"> smartlinksupport@gojo.com</a>.
    </v-card>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>
