import { get, post, put, remove } from "./apiClient";

export default {
  async getLocationHierarchy(facilityId) {
    if (!facilityId) {
      throw new Error("Facility ID is required");
    }

    return await get(`location/${facilityId}/locationHierarchy`);
  },
  async addNewLocation(parentId, locationName) {
    return await post(
      `location/${parentId}/locations`,
      JSON.stringify(locationName)
    );
  },
  async getObservableLocations(facilityId) {
    return await get(`location/${facilityId}/observableLocations`);
  },
  async updateLocationName(locationId, locationName) {
    await put(`location/${locationId}/name`, JSON.stringify(locationName));
  },
  async updateLocationObservationStatus(locationId, isObservable) {
    await put(`location/${locationId}/observationStatus`, isObservable);
  },
  async deleteLocation(locationId) {
    await remove(`location/${locationId}`);
  }
};
