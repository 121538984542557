<template>
  <div>
    <h2>{{ $t("alert-subscriptions.manage-alert.configuration") }}</h2>
    <v-row>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('alert-subscriptions.configuration.name')"
          :rules="{ required: true, max: 100 }"
          v-slot="{ label, errors }"
        >
          <v-text-field
            :value="name"
            :error-messages="errors"
            :label="label"
            @input="(value) => $emit('update:name', value)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('alert-subscriptions.configuration.type')"
          :rules="{ required: true, is_not: 0 }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="type"
            :items="typeItems"
            :disabled="isEdit"
            item-text="displayName"
            item-value="id"
            :label="label"
            :error-messages="errors"
            @change="(value) => $emit('update:type', value)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row v-if="type === 1">
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('alert-subscriptions.configuration.device-type-class')"
          :rules="{ required: true, max: 100 }"
          v-slot="{ label, errors }"
          class="tw-mr-5"
        >
          <v-select
            :value="deviceTypeClassId"
            :items="deviceTypeItems"
            :error-messages="errors"
            item-text="displayName"
            item-value="id"
            :label="label"
            @input="(value) => $emit('update:deviceTypeClassId', value)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row v-if="type === 4">
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="
            $t(
              'alert-subscriptions.configuration.activity-monitoring-threshold'
            )
          "
          :rules="{ required: true, is_not: 0 }"
          v-slot="{ label, errors }"
          class="tw-mr-5"
        >
          <v-select
            :value="inThreshold"
            :items="inThresholdItems"
            :error-messages="errors"
            :label="label"
            @input="(value) => $emit('update:inThreshold', value)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('alert-subscriptions.configuration.time-frame-hours')"
          :rules="{ required: true, is_not: 0 }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="thresholdHours"
            :items="thresholdHoursItems"
            :label="label"
            :error-messages="errors"
            @input="(value) => $emit('update:thresholdHours', value)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    isEdit: {
      type: Boolean
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: Number
    },
    typeItems: {
      type: Array
    },
    deviceTypeClassId: {
      type: Number,
      default: 0
    },
    deviceTypeItems: {
      type: Array
    },
    inThreshold: {
      type: Number
    },
    thresholdHours: {
      type: Number
    }
  },
  data: () => ({
    inThresholdItems: [25, 50, 100, 150, 200],
    thresholdHoursItems: [1, 4, 8, 12, 24]
  })
};
</script>
