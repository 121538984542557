<template>
  <div
    class="
      app
      tw-w-full tw-bg-background
      print:tw-bg-white
      tw-flex tw-flex-col tw-min-h-screen tw-h-full
    "
  >
    <v-container>
      <v-card
        v-if="showInvalidInviteMessage"
        class="tw-my-20 login-form tw-p-4 tw-w-full tw-m-auto tw-max-w-lg"
      >
        <login-logo />
        <section class="tw-p-2 tw-inline-block tw-w-full">
          <h3 class="tw-mb-5">
            {{ $t("account.manage.results.invalid-invite") }}
          </h3>
          <p>
            {{ $t("account.manage.results.contact-admin") }}
          </p>
          <p>
            {{ $t("account.manage.results.already-activated") }}
            <router-link
              class="tw-no-underline"
              :to="{
                name: 'login'
              }"
            >
              <span> {{ $t("account.manage.results.click-to-login") }}</span>
            </router-link>
          </p>
          <p v-html="$t(`account.manage.results.observer-log-in`)"></p>
        </section>
      </v-card>
      <v-card
        v-else
        class="
          tw-my-10
          login-form
          tw-border-solid
          tw-border
          tw-border-purellGray
          tw-p-4
          tw-bg-white
          tw-rounded
          tw-w-full
          tw-max-w-3xl
          tw-m-auto
        "
      >
        <section class="tw-inline-block tw-w-full">
          <login-logo />
          <h2 class="tw-mb-5">{{ $t("account.manage.register-account") }}</h2>
          <h3 class="tw-mb-6">{{ $t("account.manage.basic-info") }}</h3>
          <ValidationObserver ref="registerAccountForm" v-slot="{ pristine }">
            <v-form @submit.prevent="validateAndSubmit">
              <v-row>
                <v-col class="tw-pt-0 tw-mt-0">
                  <ValidationProvider
                    :name="$t('account.manage.first-name')"
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                    class="tw-flex tw-flex-row"
                  >
                    <v-text-field
                      v-model="firstName"
                      name="firstName"
                      id="firstName"
                      :error-messages="errors"
                      :label="$t('account.manage.first-name')"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col class="tw-pt-0 tw-mt-0">
                  <ValidationProvider
                    :name="$t('account.manage.last-name')"
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                    class="tw-flex tw-flex-row"
                  >
                    <v-text-field
                      v-model="lastName"
                      name="lastName"
                      id="lastName"
                      :error-messages="errors"
                      :label="$t('account.manage.last-name')"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <h3>
                {{ $t("account.manage.enter-password") }}
              </h3>
              <v-row>
                <v-col cols="12" lg="8">
                  <ValidationProvider
                    vid="newPassword"
                    :name="$t('account.manage.password')"
                    skipIfEmpty
                    :rules="{ required: true, password: true }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="newPassword"
                      :error-messages="errors"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                      :label="$t('account.manage.password')"
                      autocomplete="new-password"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    :name="$t('account.manage.confirm-password')"
                    skipIfEmpty
                    :rules="{ required: true, confirmed: 'newPassword' }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="confirmPassword"
                      :error-messages="errors"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      :label="$t('account.manage.confirm-password')"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="4" class="tw-pl-5">
                  <password-checker
                    class="tw-mb-5 md:tw-mb-0"
                    :password="newPassword"
                  />
                </v-col>
              </v-row>
              <v-btn
                :disabled="pristine"
                :loading="loading"
                color="primary"
                class="tw-w-24 tw-mt-5"
                type="submit"
              >
                {{ $t("account.manage.register") }}
              </v-btn>
            </v-form>
          </ValidationObserver>
        </section>
      </v-card>
    </v-container>
    <app-footer />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import PasswordChecker from "@/components/layout/PasswordChecker.vue";
import LoginLogo from "@/views/login/components/Logo.vue";
import AppFooter from "@/components/layout/AppFooter.vue";
import { scrollToFirstError } from "@/utils/scrollToFirstError";
import { ROUTE_NAMES } from "@/types/routeNames";
import accountService from "@/api/accountService";

export default {
  props: {
    guid: {
      type: String
    }
  },
  components: { PasswordChecker, LoginLogo, AppFooter },
  data: () => ({
    firstName: "",
    lastName: "",
    loading: false,
    newPassword: "",
    showPassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
    showInvalidInviteMessage: false
  }),
  async mounted() {
    try {
      const user = await accountService.getUnregisteredUser(this.guid);
      this.firstName = user.firstName;
      this.lastName = user.lastName;
    } catch (error) {
      if (error === 404 || error === 400) {
        this.showInvalidInviteMessage = true;
      }
    }
  },
  methods: {
    async validateAndSubmit() {
      const valid = await this.$refs.registerAccountForm.validate();

      if (valid) {
        this.sendRegisterAccountForm();
      } else {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Register Account Form Error`
        });
      }
    },
    async sendRegisterAccountForm() {
      this.loading = true;
      try {
        await accountService.registerAccount({
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.newPassword,
          confirmPassword: this.confirmPassword,
          activationGuid: this.guid
        });
        this.handleSuccess();
      } catch {
        this.handleError();
      }
    },
    handleSuccess() {
      this.loading = false;
      this.$router.push({ name: ROUTE_NAMES.login });

      this.$store.commit(
        "application/SET_SUCCESS_MESSAGE",
        i18n.t("account.manage.results.account-registered")
      );
    },
    handleError() {
      this.loading = false;
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t("account.manage.results.something-went-wrong")
      });
    }
  }
};
</script>
