import { IMS_REPORTS } from "@/types/imsReports";
import { TIMEFRAMES_STRINGS } from "@/types/timeframes";
import { FILTERS } from "@/types/filters";
import { cloneDeep, isEqual, sortBy } from "lodash";
import {
  initialTableColumnSort,
  initialGraphActions
} from "@/views/reports/store/modules/reports";

export function generateReportUrl() {
  try {
    const filterModelClone = cloneDeep(
      this.$store.getters["reports/filterModel"]
    );
    const activeFilters = this.$store.getters["reports/activeFilters"];
    const filterParams = {
      [FILTERS.DEVICES]: "deviceIds",
      [FILTERS.LOCATIONS]: "locationIds",
      [FILTERS.IMSJOBROLES]: "jobRoleIds",
      [FILTERS.OBVJOBROLES]: "obvJobRoleIds",
      [FILTERS.SHIFTS]: "shiftIds",
      [FILTERS.OBVYEARS]: "obvYears",
      [FILTERS.MONTHS]: "months",
      [FILTERS.HYGIENEPROTOCOLOPPORTUNITIES]: "hygieneProtocolOpportunityIds",
      [FILTERS.PRECAUTIONOPTIONS]: "precautionOption",
      [FILTERS.REPLACEMENT_DATE]: "daysToReplacement",
      [FILTERS.EXPIRATION_DATE]: "monthsToExpiration",
      [FILTERS.ACTIVITYBY]: "activityBy",
      [FILTERS.OBSERVER_OPTIONS]: "activeObserversOnly"
    };
    // Revisit when refactor of filterModel getter in reports store.
    delete filterModelClone["facilityId"];
    Object.keys(filterParams).forEach((filterKey) => {
      if (!activeFilters.includes(filterKey)) {
        delete filterModelClone[filterParams[filterKey]];
      }
    });
    if (activeFilters.includes(FILTERS.TIMEFRAME)) {
      filterModelClone["reportDateRange"] =
        TIMEFRAMES_STRINGS[filterModelClone["reportDateRange"]];
    } else {
      ["reportDateRange", "startDate", "endDate"].forEach((key) => {
        delete filterModelClone[key];
      });
    }
    Object.keys(filterModelClone).forEach((key) =>
      filterModelClone[key] === undefined ||
      filterModelClone[key] === null ||
      filterModelClone[key]?.length === 0 ||
      allSelected(key, filterModelClone[key], this.$store.state)
        ? delete filterModelClone[key]
        : {}
    );

    const filtersString = JSON.stringify(filterModelClone);

    const baseUrl = window.location.href.split("?")[0];
    let queryParams = `?reportId=${this.$store.state.reports.reportId}&customerId=${this.$store.state.customers.customerId}&facilityId=${this.$store.state.customers.facilityId}&filters=${filtersString}`;

    const reportsState = this.$store.state.reports;

    const tableColumnSort = reportsState.tableColumnSort;
    if (!isEqual(tableColumnSort, initialTableColumnSort)) {
      const tableColumnSortStringfy = JSON.stringify(tableColumnSort);
      queryParams = `${queryParams}&sortBy=${tableColumnSortStringfy}`;
    }

    const graphActions = reportsState.graphActions;
    if (!isEqual(graphActions, initialGraphActions)) {
      const graphActionsStringfy = JSON.stringify(graphActions);
      queryParams = `${queryParams}&graphActions=${graphActionsStringfy}`;
    }

    let selectedData = reportsState.selectedData;
    if (
      selectedData.length &&
      reportsState.reportId === IMS_REPORTS.INDIVIDUAL_TRENDS &&
      reportsState.rowKey
    ) {
      selectedData = selectedData.map((item) => {
        return item[reportsState.rowKey];
      });
      const selectedDataStringify = JSON.stringify(selectedData);
      queryParams = `${queryParams}&selectedData=${selectedDataStringify}`;
    }

    const urlObj = {
      url: `${baseUrl}${queryParams}`,
      queryParams: queryParams,
      baseUrl: baseUrl,
      reportId: this.$store.state.reports.reportId,
      facilityId: this.$store.state.customers.facilityId,
      customerId: this.$store.state.customers.customerId,
      filters: filtersString
    };
    return urlObj;
  } catch (err) {
    return false;
  }
}

function allSelected(filterKey, selectedValues, state) {
  const key = filterKey.replace("Ids", "s");

  return (
    Object.values(FILTERS).includes(key) &&
    key !== FILTERS.DEVICES &&
    isEqual(sortBy(state[`filters/${key}`].allIds), sortBy(selectedValues))
  );
}
