<template>
  <div>
    <confirmation-dialog
      @action-confirmed="deleteAlertSubscription"
      @toggle-confirmation-dialog="toggleConfirmationDialog"
      :confirmationDialogData="confirmationDialogData"
    />
    <div class="tw-flex tw-flex-wrap tw-justify-between tw-mb-5">
      <h1 v-if="isEdit">
        <span v-if="isInstantAlert">
          {{ $t("alert-subscriptions.manage-alert.edit-instant-alert") }}
        </span>
        <span v-else>
          {{ $t("alert-subscriptions.manage-alert.edit-scheduled-alert") }}
        </span>
      </h1>
      <h1 v-else>
        <span v-if="isInstantAlert">
          {{ $t("alert-subscriptions.manage-alert.create-instant-alert") }}
        </span>
        <span v-else>
          {{ $t("alert-subscriptions.manage-alert.create-scheduled-alert") }}
        </span>
      </h1>
      <v-btn
        v-if="isEdit && !loading"
        class="tw-mr-5"
        :loading="deleting"
        color="error"
        @click="toggleConfirmationDialog"
      >
        {{ $t("ui.actions.delete") }}
      </v-btn>
    </div>
    <h3 v-if="viewModelResponse" class="tw-mb-5">
      {{ viewModelResponse.facilityName }}
    </h3>
    <div v-if="loading">
      <v-skeleton-loader type="heading" />
      <v-skeleton-loader class="tw-my-10" type="image" />
      <v-skeleton-loader type="actions" />
    </div>
    <div v-else>
      <div
        v-if="checkPermission(PERMISSIONS.viewAllAlertSubscriptions) && isEdit"
        class="lg:tw-w-1/2 tw-w-full lg:tw-pr-3"
      >
        <v-text-field
          :value="creator"
          :label="$t('alert-subscriptions.creator')"
          disabled
        />
      </div>
      <loading-overlay v-if="submitting" />
      <ValidationObserver ref="alertSubscriptionForm" v-slot="{ pristine }">
        <v-form @submit.prevent="validateAndSubmitForm">
          <div v-if="isInstantAlert">
            <instant-configuration-form
              :isEdit="isEdit"
              :name.sync="form.name"
              :type.sync="form.alertTypeId"
              :typeItems="viewModelResponse.alertTypes"
              :deviceTypeClassId.sync="form.deviceTypeClassId"
              :deviceTypeItems="deviceTypeItems"
              :inThreshold.sync="form.inThreshold"
              :thresholdHours.sync="form.thresholdHours"
            />
          </div>
          <div v-else>
            <scheduled-configuration-form
              :isEdit="isEdit"
              :name.sync="form.name"
              :type.sync="form.alertTypeId"
              :typeItems="viewModelResponse.alertTypes"
              :batteryStatus.sync="form.batteryStatusThreshold"
              :badgeBatteryStatus.sync="form.badgeBatteryStatusThreshold"
              :lastCommThreshold.sync="form.lastCommThreshold"
              :lastCommThresholdUnits.sync="form.lastCommThresholdUnits"
              :deviceTypeClassId.sync="form.deviceTypeClassId"
              :deviceTypeItems="deviceTypeItems"
              :estimatedReplacementDateThreshold.sync="
                form.estimatedReplacementDateThreshold
              "
            />
            <scheduling-form
              v-if="form.schedule"
              :frequency.sync="form.schedule.frequencyId"
              :frequencyItems="viewModelResponse.frequencies"
              :timeOfDay.sync="form.schedule.timeOfDay"
              :daysOfWeek.sync="form.schedule.daysOfWeek"
              :dayOfMonth.sync="form.schedule.dayOfMonth"
              :facilityTimeZone="facilityTimeZone"
            />
          </div>
          <delivery-form
            v-if="form.alertTypeId !== alertTypes.summary"
            :emailSubscriberList.sync="emailSubscriberList"
          />
          <delivery-form
            v-else
            :emailSubscriberList="userEmailOnly"
            :enableRecipients="false"
          />
          <v-btn
            class="tw-mr-5"
            :loading="submitting"
            :disabled="pristine && !subscriberListChanged"
            color="primary"
            type="submit"
          >
            {{ $t("ui.actions.save") }}
          </v-btn>
          <v-btn
            class="tw-mr-5"
            color="purrelGrey"
            :to="{
              name: 'alertSubscriptions'
            }"
          >
            {{ $t("ui.actions.cancel") }}
          </v-btn>
        </v-form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "@/components/ui/LoadingOverlay.vue";
import ConfirmationDialog from "@/views/alertSubscriptions/components/ConfirmationDialog.vue";
import InstantConfigurationForm from "@/views/alertSubscriptions/components/InstantAlertConfiguration.vue";
import ScheduledConfigurationForm from "@/views/alertSubscriptions/components/ScheduledAlertConfiguration.vue";
import SchedulingForm from "@/views/alertSubscriptions/components/AlertScheduling.vue";
import DeliveryForm from "@/views/alertSubscriptions/components/Delivery.vue";
import alertSubscriptionService from "@/api/alertSubscriptionService";
import i18n from "@/plugins/i18n";
import { mapState, mapGetters } from "vuex";
import { scrollToFirstError } from "@/utils/scrollToFirstError";
import alertTypes from "@/views/alertSubscriptions/alertTypes";
import { isEqual } from "lodash";
import { PERMISSIONS } from "@/types/permissions";

export default {
  components: {
    LoadingOverlay,
    ConfirmationDialog,
    InstantConfigurationForm,
    ScheduledConfigurationForm,
    SchedulingForm,
    DeliveryForm
  },
  props: {
    isInstantAlert: {
      type: Boolean,
      default: false
    },
    alertSubscriptionId: {
      type: Number
    }
  },
  data: () => ({
    loading: true,
    deleting: false,
    submitting: false,
    isEdit: false,
    confirmationDialogData: {
      isActive: false,
      id: null,
      name: "",
      title: i18n.t("alert-subscriptions.confirmation-dialog.delete-title"),
      content: i18n.t("alert-subscriptions.confirmation-dialog.delete-content"),
      isInstantAlert: false,
      confirmButton: i18n.t("alert-subscriptions.delete")
    },
    creator: null,
    form: {
      recipients: [],
      schedule: {}
    },
    viewModelResponse: {},
    emailSubscriberList: [],
    startingEmailSubscriberList: [],
    userEmailOnly: [],
    enableRecipients: true,
    alertTypes,
    PERMISSIONS
  }),
  mounted() {
    const facilityId = this.facilityId;
    if (facilityId) {
      this.getManageAlertViewModel(facilityId);
    }

    const routeName = this.$route.name;
    this.isEdit = routeName === "editAlertSubscription";
    this.userEmailOnly = [this.$store.state.user.userName];
  },
  computed: {
    ...mapState("customers", ["facilityId"]),
    ...mapGetters("user/permissions", ["checkPermission"]),
    facilityTimeZone() {
      return this.$store.state.facilities.byId[this.facilityId].timeZone;
    },
    subscriberListChanged() {
      return !isEqual(
        this.emailSubscriberList,
        this.startingEmailSubscriberList
      );
    }
  },
  methods: {
    async getManageAlertViewModel(facilityId) {
      this.loading = true;

      const viewModel = {
        alertSubscriptionId: this.alertSubscriptionId || null,
        facilityId: facilityId
      };

      let response;
      try {
        response = this.isInstantAlert
          ? await alertSubscriptionService.getInstantAlertViewModel(viewModel)
          : await alertSubscriptionService.getScheduledAlertViewModel(
              viewModel
            );
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "alert-subscriptions.error-messages.get-manage-alert-viewmodel"
          )
        });
      }

      this.viewModelResponse = response;
      this.setupViewModel();
    },
    setupViewModel() {
      if (!this.viewModelResponse) {
        return;
      }

      this.form = this.viewModelResponse.alert;
      this.creator = this.viewModelResponse.alert.creator;

      // Adds an `All` option to the device type select input since we don't get one in the viewModel response.
      this.deviceTypeItems = this.viewModelResponse.deviceTypeClasses;
      if (this.deviceTypeItems.length > 1) {
        this.deviceTypeItems.unshift({
          id: 0,
          displayName: i18n.t("alert-subscriptions.manage-alert.all"),
          name: "all"
        });
      }

      const deviceTypeClassId = this.form.deviceTypeClassId;
      this.form.deviceTypeClassId = deviceTypeClassId
        ? deviceTypeClassId
        : this.deviceTypeItems[0]?.id;

      // Takes the emails in the `recipients` array of objects and creates a new array of email strings.
      const recipientsArray = this.form.recipients;
      if (recipientsArray.length) {
        this.emailSubscriberList = recipientsArray.map(function (recipient) {
          return recipient.deliveryAddress;
        });
        this.startingEmailSubscriberList = [...this.emailSubscriberList];
      }

      if (!this.isInstantAlert) {
        this.form.lastCommThreshold = this.form.lastCommThreshold
          ? this.form.lastCommThreshold
          : 30;
        this.form.lastCommThresholdUnits = this.form.lastCommThresholdUnits
          ? this.form.lastCommThresholdUnits
          : "MINUTE";
      }

      this.loading = false;
    },
    toggleConfirmationDialog() {
      this.confirmationDialogData.isActive =
        !this.confirmationDialogData.isActive;
      this.confirmationDialogData.id = this.confirmationDialogData.id
        ? null
        : this.alertSubscriptionId;
      this.confirmationDialogData.name = this.confirmationDialogData.name
        ? ""
        : this.form.name;
      this.confirmationDialogData.isInstantAlert = this.isInstantAlert;

      const deleteDialogStatus = this.confirmationDialogData.isActive
        ? "Open"
        : "Close";

      this.$appInsights?.trackEvent({
        name: `${deleteDialogStatus} Alert Subscription Delete Dialog`
      });
    },
    async deleteAlertSubscription() {
      this.deleting = true;
      const viewModel = {
        alertSubscriptionId: this.alertSubscriptionId,
        isInstantAlert: this.isInstantAlert,
        facilityId: this.facilityId
      };

      try {
        await alertSubscriptionService.deleteAlertSubscription(viewModel);
        this.$appInsights?.trackEvent({
          name: `Alert Subscription Successfully Deleted`
        });
        this.$router.push({
          name: "alertSubscriptions"
        });
      } catch {
        this.toggleConfirmationDialog();
        this.deleting = false;
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "alert-subscriptions.error-messages.delete-alert-subscription"
          )
        });
      }
    },
    async validateAndSubmitForm() {
      const valid = await this.$refs.alertSubscriptionForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Alert Subscription Form Error`
        });
        return;
      }

      this.saveAlertSubscriptionForm();
    },
    async saveAlertSubscriptionForm() {
      this.submitting = true;

      this.form.facilityId = this.facilityId;

      // Rebuilds the recipients array of objects for the endpoint.
      const previousRecipients = this.form.recipients;
      const alertSubscriptionId = this.alertSubscriptionId;

      const recipientEmails =
        this.form.alertTypeId === alertTypes.summary
          ? this.userEmailOnly
          : this.emailSubscriberList;
      this.form.recipients = recipientEmails.map(function (email) {
        const previousRecipient = previousRecipients.find(
          (r) => r.deliveryAddress.toLowerCase() === email.toLowerCase()
        );
        const recipientId = previousRecipient?.id;
        const recipientUnsubscribeKey = previousRecipient?.unsubscribeKey;

        return {
          id: recipientId ? recipientId : 0,
          subscriptionId: alertSubscriptionId ? alertSubscriptionId : 0,
          deliveryMethodId: 1,
          deliveryAddress: email,
          unsubscribeKey: recipientUnsubscribeKey
        };
      });

      try {
        this.isInstantAlert
          ? await alertSubscriptionService.saveInstantAlert(this.form)
          : await alertSubscriptionService.saveScheduledAlert(this.form);
        this.handleResult(true);
      } catch {
        this.handleResult(false);
      }

      this.submitting = false;
    },
    handleResult(result) {
      if (result) {
        this.$appInsights?.trackEvent({
          name: this.isEdit
            ? `Alert Subscription Successfully Updated`
            : `Alert Subscription Successfully Created`
        });
        this.$router.push({
          name: "alertSubscriptions",
          params: {
            successMessage: this.isEdit
              ? i18n.t(
                  "alert-subscriptions.success-messages.update-alert-subscription"
                )
              : i18n.t(
                  "alert-subscriptions.success-messages.create-alert-subscription"
                )
          }
        });
      } else {
        this.$appInsights?.trackEvent({
          name: `Alert Subscription Form Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "alert-subscriptions.error-messages.save-alert-subscription"
          )
        });
      }
    }
  },
  watch: {
    facilityId(value) {
      this.getManageAlertViewModel(value);
    }
  }
};
</script>
