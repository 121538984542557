<template>
  <default-layout>
    <v-container style="max-width: 100%">
      <v-card>
        <section class="tw-p-4 sm:tw-px-8 tw-inline-block tw-w-full">
          <router-view />
        </section>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/Default";

export default {
  components: {
    DefaultLayout
  }
};
</script>
