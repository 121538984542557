<template>
  <v-app id="app">
    <v-snackbar
      color="primary"
      top
      :value="showSuccessMessage"
      timeout="5000"
      @input="$store.commit('application/SET_SHOW_SUCCESS_MESSAGE', false)"
    >
      {{ successMessage }}
      <template v-slot:action="{ close }">
        <v-btn
          icon
          @click="$store.commit('application/SET_SHOW_SUCCESS_MESSAGE', false)"
          v-bind="close"
        >
          <v-icon> mdi-close-thick </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <idle-timeout v-if="userId" />
    <router-view />
  </v-app>
</template>

<script>
import Vue from "vue";
import appInsights from "./plugins/applicationInsights";
import { ValidationObserver } from "vee-validate";
import ValidationProvider from "@/components/ui/ValidationProvider.vue";
import IdleTimeout from "./components/ui/IdleTimeout.vue";
import { mapActions, mapState } from "vuex";
import { setInteractionMode } from "vee-validate";
import vuetifyOverrides from "@/vuetifyOverrides";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

vuetifyOverrides();

export default {
  name: "App",
  components: {
    IdleTimeout
  },
  computed: {
    ...mapState("application", [
      "applicationInsightsLoaded",
      "showSuccessMessage",
      "successMessage"
    ]),
    ...mapState("user", {
      userId: (state) => state.id
    }),
    ...mapState("preferences", ["language"])
  },
  async beforeMount() {
    this.changeLanguage({ language: this.language, i18n: this.$i18n });
    setInteractionMode("eager");
  },
  methods: {
    ...mapActions("preferences", ["changeLanguage"])
  },
  watch: {
    userId() {
      Vue.use(appInsights, this.$router, this.$store);
    },
    applicationInsightsLoaded() {
      this.$appInsights?.trackPageView({
        name: this.$route.meta?.pageTagName
      });
    }
  }
};
</script>

<style>
#app {
  font-family: "Open Sans", sans-serif;
}
.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  line-height: inherit; /* resolves issue where text/character descenders are clipped at the bottom */
}

.v-data-table
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  font-size: 1rem;
}

.v-data-table.small-headers
  > .v-data-table__wrapper
  > table
  > .v-data-table-header
  > tr
  > th {
  font-size: 0.75rem;
}

body.lock-screen {
  height: 100vh;
  overflow: hidden;
}

.smartlink-flag-icon {
  width: 20px;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
