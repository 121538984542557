import { performanceBandForLimit } from "@/utils/performanceBandCalculator";

const formatPercentage = (fixedPlaces = 1, appendSign, percentage) => {
  if (fixedPlaces) {
    percentage = percentage.toFixed(fixedPlaces);
  }
  if (appendSign) {
    percentage += "%";
  }

  return percentage;
};

const calculatePercentage = (
  divisor,
  dividend = {},
  max100PctCompliance = false
) => {
  const percentage = divisor === 0 ? null : (dividend / divisor) * 100;

  if (max100PctCompliance && percentage > 100) {
    return 100;
  }

  return percentage;
};

export const sumEntries = ({ entries = [], inputField = "" } = {}) =>
  entries.length ? entries.reduce((acc, i) => (acc += i[inputField]), 0) : 0;

export const sumEvents = ({
  items = [],
  eventsField = "entries",
  inputField = "",
  outputField = `total${
    inputField.charAt(0).toUpperCase() + inputField.slice(1)
  }`
} = {}) => {
  return items.map((item) => ({
    ...item,
    [outputField]: sumEntries({ entries: item[eventsField], inputField })
  }));
};

export const createPercentage = ({ dividend = 0, divisor = 0 } = {}) => {
  return divisor ? ((dividend / divisor) * 100).toFixed(1) : 0;
};

export const totalPercentage = ({
  items = [],
  eventsField = "entries",
  dividendField = "compliantOpportunities",
  divisorField = "opportunities",
  outputField = "overallPerformance",
  fixedPlaces = 1,
  appendSign = false,
  dataKey = null
} = {}) =>
  items.map((item) => {
    const dividendTotal =
      dataKey === null
        ? sumEntries({
            entries: item[eventsField],
            inputField: dividendField
          })
        : item[dataKey][dividendField];

    const divisorTotal =
      dataKey === null
        ? sumEntries({
            entries: item[eventsField],
            inputField: divisorField
          })
        : item[dataKey][divisorField];

    let percentage = -1;
    if (divisorTotal >= 0 && dividendTotal >= 0) {
      percentage = calculatePercentage(
        divisorTotal,
        dividendTotal,
        dataKey === null
          ? item[eventsField].length
            ? item[eventsField][0].max100PctCompliance
            : null
          : item[dataKey].max100PctCompliance
      );
      if (percentage !== null) {
        percentage = formatPercentage(fixedPlaces, appendSign, percentage);
      }
    }

    if (dataKey == null) {
      item[outputField] = percentage;
    } else {
      item[dataKey][outputField] = percentage;
    }

    return item;
  });

export const setEventsIndicator = ({
  items = [],
  performanceBands = [],
  performanceField = "overallPerformance",
  outputField = "indicator"
} = {}) => {
  if (!performanceBands.length) {
    return items;
  }
  const sortedIndicators = performanceBands.sort((a, b) => a.limit - b.limit);

  return items.map((item) => {
    if (!item[performanceField]) {
      return {
        [outputField]: null,
        ...item
      };
    }
    const limit =
      typeof item[performanceField] === "number"
        ? item[performanceField]
        : Number.parseFloat(item[performanceField]);

    return {
      [outputField]: performanceBandForLimit({
        indicators: sortedIndicators,
        limit
      }),
      ...item
    };
  });
};
