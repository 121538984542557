import application from "./modules/application";
import preferences from "./modules/preferences";
import user from "./modules/user";

const appStoreConfig = {
  modules: {
    application,
    preferences,
    user
  },
  state: () => ({}),
  mutations: {},
  actions: {}
};

export default appStoreConfig;
