<template>
  <div class="tw-flex tw-flex-col">
    <v-btn
      v-if="displayMode !== DISPLAY_MODES.PRINT && detailRows.length"
      text
      color="primary"
      @click="toggleLocationDetails()"
      class="tw-self-center"
    >
      <v-icon v-if="showLocationDetails" class="tw-mr-1">
        mdi-chevron-up
      </v-icon>
      <v-icon v-else class="tw-mr-1"> mdi-chevron-down </v-icon>
      <div v-if="showLocationDetails">
        {{ $t("ui.actions.hide-location-details") }}
      </div>
      <div v-else>
        {{ $t("ui.actions.expand-location-details") }}
      </div>
    </v-btn>

    <div
      v-if="this.showLocationDetails || displayMode === DISPLAY_MODES.PRINT"
      class="
        tw-flex tw-flex-col
        sm:tw-table
        print:tw-table
        sm:tw-ml-8
        print:tw-ml-8
      "
    >
      <div
        class="sm:tw-table-row print:tw-table-row"
        v-for="(detail, index) in detailRows"
        :key="index"
      >
        <div
          class="
            sm:tw-table-cell
            print:tw-table-cell
            tw-align-text-top
            tw-text-right
            tw-whitespace-no-wrap
            tw-font-bold
            tw-pl-2
            tw-pr-4
            tw-pb-1
            tw-w-1
          "
        >
          {{ detail.label }}
        </div>
        <div class="sm:tw-table-cell print:tw-table-cell tw-pl-2 tw-pb-1">
          {{ detail.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DISPLAY_MODES } from "@/store/modules/application.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "report-filter-details-block",
  data: () => ({
    showLocationDetails: false,
    DISPLAY_MODES
  }),
  computed: {
    ...mapState("application", ["displayMode"]),
    ...mapGetters("reports", ["activeFilters"]),
    detailRows() {
      const activeFilterIds = this.activeFilters;
      const filterDetails = [];

      activeFilterIds.forEach((filterId) => {
        const details = this.getSelectedTextDetails(filterId);
        if (!details) {
          return;
        }
        details.forEach((detailRow) => {
          filterDetails.push(detailRow);
        });
      });

      return filterDetails;
    }
  },
  methods: {
    getSelectedTextDetails(filterId) {
      return this.$store.getters[`filters/${filterId}/selectedTextDetails`]();
    },
    toggleLocationDetails() {
      if (!this.showLocationDetails) {
        this.$appInsights?.trackEvent({ name: "Open Location Details" });
      } else {
        this.$appInsights?.trackEvent({ name: "Close Location Details" });
      }
      this.showLocationDetails = !this.showLocationDetails;
    }
  }
};
</script>
