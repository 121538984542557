var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.filterId)?_c('responsive-popup',{attrs:{"value":_vm.value},on:{"save":_vm.onSave,"cancel":_vm.onCancel}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t(`filters.${_vm.filterId}`))+" ")]),(_vm.showSearch)?_c('filters-popup-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(_vm.selectAll)?_c('filters-popup-header',{ref:"my-filter-popup-header",attrs:{"hierarchyItems":_vm.hierarchyItems,"allIds":_vm.$store.getters[`filters/${_vm.filterId}/selectableIds`],"allOpen":_vm.allOpen},on:{"update:allOpen":function($event){_vm.allOpen=$event},"update:all-open":function($event){_vm.allOpen=$event}},model:{value:(_vm.internalActiveIds),callback:function ($$v) {_vm.internalActiveIds=$$v},expression:"internalActiveIds"}}):_vm._e(),_c('div',{staticClass:"limit-height"},[_c(_vm.selectionComponent,{tag:"component",staticClass:"hide-scrollbar",attrs:{"orderedItems":_vm.orderedItems,"hierarchyItems":_vm.hierarchyItems,"allOpen":_vm.allOpen,"search":_vm.search,"filterId":_vm.filterId,"selectionType":_vm.selectionType,"itemChildrenKey":_vm.itemChildrenKey,"showOtherOption":_vm.showOtherOption,"otherOptionText":_vm.otherOptionText},on:{"singleSelectionChanged":_vm.onSingleSelectionChanged},model:{value:(_vm.internalActiveIds),callback:function ($$v) {_vm.internalActiveIds=$$v},expression:"internalActiveIds"}}),_c('v-snackbar',{attrs:{"value":_vm.showForceSelectionMessage,"color":"error","centered":true,"timeout":5000},on:{"input":function($event){_vm.showForceSelectionMessage = false}}},[(_vm.showForceSelectionMessage)?_c('div',{staticClass:"tw-text-lg"},[_vm._v(" "+_vm._s(_vm.$t(`filters.messages.${_vm.filterId}-force-selection`))+" ")]):_vm._e()])],1),(
        _vm.filterId === _vm.FILTERS.TIMEFRAME &&
        _vm.internalActiveIds === _vm.TIMEFRAMES.CUSTOM &&
        _vm.productId !== _vm.PRODUCTS.OBS
      )?_c('filters-popup-custom-timeframes',{model:{value:(_vm.internalTimeframe),callback:function ($$v) {_vm.internalTimeframe=$$v},expression:"internalTimeframe"}}):_vm._e(),(
        _vm.filterId === _vm.FILTERS.TIMEFRAME &&
        _vm.internalActiveIds === _vm.TIMEFRAMES.CUSTOM &&
        _vm.productId === _vm.PRODUCTS.OBS
      )?_c('filters-popup-custom-dates',{model:{value:(_vm.internalTimeframe),callback:function ($$v) {_vm.internalTimeframe=$$v},expression:"internalTimeframe"}}):_vm._e(),_c('v-divider'),_c('v-card-actions',{staticClass:"tw-w-full tw-justify-between"},[_c('v-btn',{attrs:{"name":"cancelBtn","text":"","color":"purellGray"},on:{"click":_vm.onCancel}},[_vm._v(" "+_vm._s(this.$t("ui.actions.cancel"))+" ")]),_c('v-btn',{ref:"save-filters-button",attrs:{"text":"","color":"primary"},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(this.$t("ui.actions.save"))+" ")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }