import { render, staticRenderFns } from "./LicensesWidget.vue?vue&type=template&id=a07b192a&scoped=true"
import script from "./LicensesWidget.vue?vue&type=script&lang=js"
export * from "./LicensesWidget.vue?vue&type=script&lang=js"
import style0 from "./LicensesWidget.vue?vue&type=style&index=0&id=a07b192a&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a07b192a",
  null
  
)

export default component.exports