import { post, get } from "./apiClient";

export default {
  async post({ slideViewModel }) {
    return await post(`slide`, slideViewModel);
  },

  async postImage(facilityId, file, slideName) {
    const data = new FormData();

    data.append(slideName, file);

    return await post(`slide/image?facilityId=${facilityId}`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  async get(facilityId) {
    return await get(`slide?facilityId=${facilityId}`);
  }
};
