/* eslint-disable no-unreachable */
import customerService from "@/api/customerService";
import normalizeFields from "@/store/utils/fields";
import { makeCRUDModule } from "@/store/modules/makeModule";
import { stringCompare } from "@/utils/comparers";
import i18n from "@/plugins/i18n";
import storageKeys from "@/utils/browserStorage/storageKeys";

function showError({
  commit,
  message,
  retryAction = null,
  retryParameter = null
} = {}) {
  commit(
    "application/SET_ERROR",
    {
      message,
      retryAction,
      retryParameter
    },
    { root: true }
  );
}

function determineDefaultId(array, queryParam, localStorageItem) {
  if (queryParam) {
    const queryParamId = parseInt(queryParam);

    if (array.indexOf(queryParamId) >= 0) {
      return queryParamId;
    }
  }

  if (localStorageItem) {
    const localStorageReportId = parseInt(localStorageItem);

    if (array.indexOf(localStorageReportId) >= 0) {
      return localStorageReportId;
    }
  }

  return array[0];
}

const customerStore = {
  namespaced: true,
  state: {
    productId: undefined,
    licensedFacilityIds: [],
    customerId: undefined,
    customerName: "",
    facilityId: undefined,
    privilegeModel: {}
  },
  mutations: {
    setLicensedFacilityIds: (state, ids) => (state.licensedFacilityIds = ids),
    setProductId: (state, id) => (state.productId = id),
    setCustomer: (state, id) => {
      if (id === state.customerId) {
        return;
      }

      localStorage.setItem(storageKeys.customers.id, id);
      state.customerId = id;
      state.customerName = state.byId[id]?.text;
    },
    setFacility: (state, id) => {
      const facilityCookieKey = "SmartLink_Portal_FacilityId";

      document.cookie = `${facilityCookieKey}=${id};path=/`;
      localStorage.setItem(storageKeys.facilities.id, id);

      state.facilityId = id;
    },
    setPrivilegeModel: (state, privilegeModel) =>
      (state.privilegeModel = privilegeModel)
  },
  getters: {
    licensedCustomerIds(state, getters) {
      let customers = getters.list;

      if (state.productId) {
        customers = customers.filter((customer) =>
          customer.products.includes(state.productId)
        );
      }
      return customers
        .sort((a, b) => stringCompare(a.text, b.text))
        .map((customer) => customer.id);
    },
    hasCustomerFeatureByName: (state) => (featureName) => {
      return Object.values(state.byId).some((c) =>
        c.features.some((f) => f.name === featureName)
      );
    }
  },
  actions: {
    async changeCustomer({ state, commit, dispatch, rootGetters }, payload) {
      const { customerId, facilityId } = payload;
      if (!customerId) {
        return;
      }
      commit("setCustomer", customerId);

      try {
        commit("application/SET_ERROR", null, { root: true });
        await dispatch(
          "facilities/load",
          {
            customerId: customerId,
            ...state.privilegeModel
          },
          { root: true }
        );
        const facilities = rootGetters["facilities/list"];
        const organizedFacilities = facilities
          .sort((a, b) => stringCompare(a.text, b.text))
          .map((facility) => facility.id);

        commit("setLicensedFacilityIds", organizedFacilities);
        dispatch("setDefaultFacility", {
          facilityId
        });
      } catch (error) {
        showError({
          commit,
          message: error,
          retryAction: "customers/changeCustomer"
        });
      }
    },
    setDefaultCustomer({ dispatch, getters, commit }, options = {}) {
      const licensedCustomerIds = getters.licensedCustomerIds;

      if (!licensedCustomerIds.length) {
        commit("setCustomer", undefined);

        showError({
          commit,
          message: i18n.t("reports.errors.customers-matching-product")
        });
        return;
      }

      const payload = {
        customerId: determineDefaultId(
          licensedCustomerIds,
          options.customerId,
          localStorage.getItem(storageKeys.customers.id)
        ),
        facilityId: options.facilityId
      };

      dispatch("changeCustomer", payload);
    },
    setDefaultFacility({ state, commit }, options = {}) {
      const licensedFacilityIds = state.licensedFacilityIds;

      if (!licensedFacilityIds.length) {
        commit("setFacility", undefined);

        showError({
          commit,
          message: i18n.t("reports.errors.facilities-matching-product")
        });
        return;
      }

      commit(
        "setFacility",
        determineDefaultId(
          licensedFacilityIds,
          options.facilityId,
          localStorage.getItem(storageKeys.facilities.id)
        )
      );
    }
  }
};

export function makeCustomersModule() {
  const baseStore = makeCRUDModule({
    service: customerService,
    normalizer: (object) =>
      normalizeFields(object, {
        id: "id",
        name: "text",
        licensedProductCodes: "products",
        features: "features"
      })
  });

  const mergedStore = {
    namespaced: true,
    actions: Object.assign(baseStore.actions, customerStore.actions),
    getters: Object.assign(baseStore.getters, customerStore.getters),
    mutations: Object.assign(baseStore.mutations, customerStore.mutations),
    state: Object.assign(baseStore.state, customerStore.state)
  };

  return mergedStore;
}
