<template>
  <div>
    <report-summary :cells="cells" />
    <report-fixed-legend :goal="goal" :hideComplianceLegend="true" />
    <report-graph :chartData="chartData" />
    <report-table
      :showSearch="false"
      :showTotalRow="true"
      :headers="tableData.headers"
      :items="tableData.items"
      :goal="goal"
    >
      <template v-slot:totalRow>
        <tr class="smartlink-table-total-row">
          <td>{{ $t("reports.total") }}</td>
          <td v-for="(column, index) in tableData.totalRowColumns" :key="index">
            <display-formatter-percentage :value="column.complianceIndex" />
          </td>
        </tr>
      </template>
    </report-table>
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportFixedLegend from "@/views/reports/components/reportSections/ReportFixedLegend.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import DisplayFormatterPercentage from "@/views/reports/components/reportSections/displayFormatters/DisplayFormatterPercentage.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";

import i18n from "@/plugins/i18n";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import { mapState } from "vuex";
import groupBy from "lodash/groupBy";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportFixedLegend,
    ReportTable,
    ReportGraph,
    DisplayFormatterPercentage
  },
  data() {
    return {
      compatibleReportId: 11,
      reportFilters: ["timeframe", "locations", "obvJobRoles"],
      hiddenReportActions: ["saveSlide"],
      cells: [],
      goal: 0,
      chartData: {},
      tableData: {}
    };
  },
  computed: {
    ...mapState("reports", ["isSlideshow"])
  },
  methods: {
    handleReportResponse() {
      if (
        !this.reportResponse?.dataPoints ||
        !Array.isArray(this.reportResponse.dataPoints) ||
        !Array.isArray(this.reportResponse.unitTotals)
      ) {
        return;
      }
      this.goal = this.reportResponse.performanceGoal;
      this.createSummary();
      this.createGraph();
      this.createTableData();
    },
    createSummary() {
      this.cells = [
        {
          text: i18n.t("reports.compliant"),
          value: this.reportResponse.compliantObservations,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total"),
          value: this.reportResponse.totalObservations,
          display: "Locale"
        },
        {
          text: i18n.t("reports.performance-rate"),
          value: this.reportResponse.complianceIndex,
          display: "Percentage"
        }
      ];
    },
    createGraph() {
      const data = this.reportResponse.dataPoints;
      const builder = new ChartBuilder({
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });
      builder
        .chartCategorized({
          items: data,
          valueField: "complianceIndex",
          valueFieldType: GraphDataTypes.FIXED,
          datasetField: "jobRole",
          categoryField: "unit",
          valueScaleOptions: {
            label: i18n.t("reports.compliance"),
            ticks: {
              format: "${value}%"
            }
          }
        })
        .valueScale.showGoal(this.goal);
      this.chartData = builder.build();
    },
    createTableData() {
      const headers = [];
      headers.push({ text: i18n.t("job-roles.singular"), value: "jobRole" });

      const unitData = groupBy(this.reportResponse.dataPoints, "unit");
      Object.keys(unitData).forEach((unit) => {
        headers.push({
          text: unit,
          value: unit,
          display: "Percentage"
        });
      });
      this.tableData.headers = headers;
      const jobRoleData = groupBy(this.reportResponse.dataPoints, "jobRole");
      const tableDataItems = [];
      Object.keys(jobRoleData).forEach((jobRole) => {
        const rowData = {};
        rowData.jobRole = jobRole;
        Object.entries(unitData).forEach((entry) => {
          const [unit, unitJobRoles] = entry;
          const match = unitJobRoles.find((item) => item.jobRole === jobRole);
          rowData[unit] = match?.complianceIndex;
          rowData[`${unit}TotalObservations`] = match?.totalObservations;
        });
        tableDataItems.push(rowData);
      });
      this.tableData.items = tableDataItems;
      this.tableData.totalRowColumns = Object.keys(unitData).map((unit) => {
        const unitTotal = this.reportResponse.unitTotals.find(
          (unitTotal) => unitTotal.unit === unit
        );
        return { complianceIndex: unitTotal?.complianceIndex };
      });
    },
    createCsvData() {
      if (!this.tableData.items) {
        return;
      }

      const headers = [];
      headers.push({ text: i18n.t("job-roles.singular"), value: "jobRole" });

      const unitData = groupBy(this.reportResponse.dataPoints, "unit");
      Object.keys(unitData).forEach((unit) => {
        headers.push(
          {
            text: unit.concat(" %"),
            value: unit,
            display: "Percentage"
          },
          {
            text: unit.concat(" #"),
            value: `${unit}TotalObservations`,
            display: "Locale"
          }
        );
      });

      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.tableData.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    }
  }
};
</script>
