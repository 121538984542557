<template>
  <v-tabs
    background-color="transparent"
    show-arrows
    :hide-slider="true"
    class="tw-max-w-xl md:tw-max-w-full"
    v-model="activeTab"
  >
    <v-tab
      class="report-navbar-tab"
      v-for="id in sortedReports"
      :key="id"
      @click.prevent="navigateToReport(id)"
    >
      {{ $t(reportNames[id]) }}
    </v-tab>
  </v-tabs>
</template>

<script>
import storageKeys from "@/utils/browserStorage/storageKeys";
import { PRODUCTS } from "@/types/products";
import { ALL_REPORTS, ALL_REPORT_DISPLAY_NAMES } from "@/types/allReports";
import { mapMutations, mapState } from "vuex";
import { filter } from "lodash";
import { navigateToReport } from "@/router";

export default {
  data: () => ({
    activeTab: 0,
    sortedReports: [],
    navigateToReport
  }),
  computed: {
    ...mapState("customers", ["productId", "facilityId"]),
    ...mapState("reports", ["loadingFilters", "reportId"]),
    hasRefillsWithExpirationDate() {
      return this.$store.state.facilities?.byId[this.facilityId]
        ?.hasRefillsWithExpirationDate;
    },
    hasShifts() {
      return this.$store.state.facilities?.byId[this.facilityId]?.hasShifts;
    },
    reportNames() {
      return ALL_REPORT_DISPLAY_NAMES;
    },
    readyToSetActiveTab() {
      return (
        this.sortedReports?.length > 0 && !this.loadingFilters && this.reportId
      );
    }
  },
  methods: {
    ...mapMutations("reports", ["setReportId"]),
    createSortedReports() {
      let sortedReports = [];
      switch (this.productId) {
        case PRODUCTS.ECM:
          sortedReports = [
            ALL_REPORTS.PERFORMANCE_HISTORY,
            ALL_REPORTS.PERFORMANCE_BAR_GRAPH,
            ALL_REPORTS.PERFORMANCE_SUMMARY,
            ALL_REPORTS.ROOM_LEVEL_METRICS,
            ALL_REPORTS.DISPENSER_EVENTS,
            ALL_REPORTS.DEVICE_METRICS,
            ALL_REPORTS.SHIFT_COMPARISON
          ];
          break;
        case PRODUCTS.OBS:
          sortedReports = [
            ALL_REPORTS.HH_ACTIVITY_HISTORY_MONTHLY,
            ALL_REPORTS.HH_ACTIVITY_HISTORY_QUARTERLY,
            ALL_REPORTS.HH_COMPLIANCE,
            ALL_REPORTS.HH_JOB_ROLE_AND_MOMENTS,
            ALL_REPORTS.HH_JOB_ROLE_AND_OUTCOME,
            ALL_REPORTS.HH_JOB_ROLE_AND_UNIT,
            ALL_REPORTS.HH_MOMENTS,
            ALL_REPORTS.HH_OBSERVER,
            ALL_REPORTS.HH_QUICK_NOTES,
            ALL_REPORTS.PPE_COMPLIANCE
          ];
          break;
        case PRODUCTS.IMS:
          sortedReports = [
            ALL_REPORTS.INDIVIDUAL_PERFORMANCE,
            ALL_REPORTS.INDIVIDUAL_TRENDS,
            ALL_REPORTS.JOB_ROLE_PERFORMANCE,
            ALL_REPORTS.GROUP_PERFORMANCE,
            ALL_REPORTS.PRODUCT_USAGE
          ];
          break;
        case PRODUCTS.SA:
          sortedReports = [
            ALL_REPORTS.FACILITY_SUMMARY,
            ALL_REPORTS.ALERT_STATUS,
            ALL_REPORTS.DEVICE_ALERTS,
            ALL_REPORTS.REFILL_ALERTS,
            ALL_REPORTS.OFFLINE_DEVICES,
            ALL_REPORTS.BATTERY_ALERTS,
            ALL_REPORTS.EXPIRATION_ALERTS,
            ALL_REPORTS.DISPENSER_USAGE
          ];
          break;
        default:
          sortedReports = [];
      }
      if (this.productId === PRODUCTS.ECM && !this.hasShifts) {
        sortedReports = filter(
          sortedReports,
          (report) => report !== ALL_REPORTS.SHIFT_COMPARISON
        );
      }
      if (
        this.productId === PRODUCTS.SA &&
        !this.hasRefillsWithExpirationDate
      ) {
        sortedReports = filter(
          sortedReports,
          (report) => report !== ALL_REPORTS.EXPIRATION_ALERTS
        );
      }

      this.sortedReports = sortedReports;
    },
    setInitialReportId() {
      const queryReportId = this.$route.query?.reportId;

      if (queryReportId) {
        const queryId = parseInt(queryReportId);
        const queryReportIdExists = this.sortedReports.indexOf(queryId) >= 0;

        if (queryReportIdExists) {
          return;
        }
      }

      const localStorageReportId = parseInt(
        localStorage.getItem(storageKeys.report.id)
      );
      const localStorageReportIdExists =
        this.sortedReports.indexOf(localStorageReportId) >= 0;

      const defaultReportId = localStorageReportIdExists
        ? localStorageReportId
        : this.sortedReports[0];

      this.$router.replace({
        name: this.$route.name,
        query: { reportId: defaultReportId }
      });
    }
  },
  watch: {
    loadingFilters: {
      immediate: true,
      handler(value) {
        if (!value) {
          this.createSortedReports();
          this.setInitialReportId();
        }
      }
    },
    readyToSetActiveTab: {
      immediate: true,
      handler(value) {
        if (value) {
          this.activeTab = this.sortedReports.indexOf(this.reportId);
        }
      }
    }
  }
};
</script>

<style language="scss" scoped>
.v-tabs {
  overflow: hidden; /* resolve issue with overhanging "Export" button */
}
.report-navbar-tab.v-tab--active {
  border-bottom: 2px solid #007bb6;
}
</style>
