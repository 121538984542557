<template>
  <v-dialog
    :transition="
      $vuetify.breakpoint.mdAndUp ? undefined : 'dialog-bottom-transition'
    "
    :value="visible"
    persistent
    no-click-animation
    inset
    @click:outside="canCloseDialog"
    max-width="85%"
  >
    <v-card class="tw-p-6 tw-pt-2">
      <h2>
        {{ $t("admin.device-maintenance.reactivate-device") }}
      </h2>
      <ValidationObserver ref="activateDeviceForm">
        <v-form @submit.prevent="validateAndSave">
          <v-row>
            <v-col>
              <div>
                <v-row class="tw-mt-1">
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="$t('admin.placeholders-and-devices.serial-number')"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        auto-select-first
                        v-model="deviceId"
                        :error-messages="errors"
                        :label="
                          $t('admin.placeholders-and-devices.serial-number')
                        "
                        :items="devices"
                        item-value="id"
                        item-text="serialNumber"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      :name="$t('admin.device-maintenance.filters.locations')"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                      :skipIfEmpty="false"
                    >
                      <selector
                        selectionComponent="hierarchy-selection"
                        label="admin.device-maintenance.headers.location"
                        :items="locations"
                        v-model="selectedLocations"
                        itemChildIdKey="childLocations"
                        activator="v-select"
                        :offsetX="false"
                        :minWidth="700"
                        :menuProps="{ 'nudge-top': 180 }"
                        singleSelect
                        :textFieldProps="{
                          'error-messages': errors
                        }"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row class="tw-justify-end">
            <v-btn
              class="tw-mr-5 tw-w-24"
              color="primary"
              type="submit"
              :loading="saving"
            >
              {{ $t("ui.actions.save") }}
            </v-btn>
            <v-btn
              :disabled="saving"
              class="tw-mr-5 tw-w-24"
              color="background2"
              @click="closeForm"
            >
              {{ $t("ui.actions.cancel") }}
            </v-btn>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import Selector from "@/views/admin/components/Selector.vue";
import i18n from "@/plugins/i18n";
import deviceService from "@/api/deviceService";

export default {
  components: {
    Selector
  },
  props: {
    facilityId: { type: Number },
    locations: {
      type: Array,
      default: () => {
        return [];
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deviceId: undefined,
      devices: [],
      selectedLocations: [],
      saving: false
    };
  },
  async mounted() {
    deviceService.getInactiveDevices().then((devices) => {
      this.devices = devices;
    });
  },
  methods: {
    canCloseDialog(e) {
      const path = e.composedPath();
      if (
        this.visible &&
        !this.saving &&
        !document.querySelector(".menuable__content__active") &&
        !path.includes(document.querySelector(".v-dialog__content"))
      ) {
        this.closeForm();
      }
    },
    closeForm() {
      this.$emit("update:visible", false);
      this.resetForm();
    },
    resetForm() {
      this.deviceId = undefined;
      this.selectedLocations = [];
      this.$refs.activateDeviceForm.reset();
    },
    async validateAndSave() {
      const valid = await this.$refs.activateDeviceForm.validate();
      if (!valid) {
        this.$appInsights?.trackEvent({
          name: `Activate Device Form Error`
        });
        return;
      }
      this.saving = true;
      try {
        await deviceService.activateDevice(
          this.deviceId,
          this.facilityId,
          this.selectedLocations[0]
        );
        this.$appInsights?.trackEvent({
          name: `Activate Device Form Success`
        });
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t("admin.device-maintenance.success-messages.activate-device")
        );

        this.$emit("onSave", {
          locations: this.selectedLocations
        });
        this.closeForm();
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Activate Device Server Success`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.device-maintenance.error-messages.activate-device"
          )
        });
      }
      this.saving = false;
    }
  }
};
</script>
