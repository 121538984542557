export default {
  props: {
    value: {
      type: [Array, Number, String],
      default: () => []
    },
    orderedItems: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: ""
    }
  },
  methods: {
    onSet(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    searchResult() {
      if (!this.search) {
        return this.orderedItems;
      }

      const normalizeText = (str = "") => str.trim().toLowerCase();
      const normalizedQuery = normalizeText(this.search);

      return this.orderedItems.filter((item) =>
        normalizeText(item.text).includes(normalizedQuery)
      );
    }
  }
};
