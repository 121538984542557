import {
  AMS_REPORTS,
  AMS_REPORT_COMPONENT_NAMES,
  AMS_REPORT_DISPLAY_NAMES,
  AMS_REPORT_RAW_EXPORT_NAMES
} from "@/types/amsReports";
import {
  IMS_REPORTS,
  IMS_REPORT_COMPONENT_NAMES,
  IMS_REPORT_DISPLAY_NAMES,
  IMS_REPORT_RAW_EXPORT_NAMES
} from "@/types/imsReports";
import {
  OBV_REPORTS,
  OBV_REPORT_COMPONENT_NAMES,
  OBV_REPORT_DISPLAY_NAMES,
  OBV_REPORT_RAW_EXPORT_NAMES
} from "@/types/obvReports";
import {
  SA_REPORTS,
  SA_REPORT_COMPONENT_NAMES,
  SA_REPORT_DISPLAY_NAMES,
  SA_RAW_EXPORT_NAMES
} from "@/types/saReports";

export const ALL_REPORTS = {
  ...AMS_REPORTS,
  ...IMS_REPORTS,
  ...OBV_REPORTS,
  ...SA_REPORTS
};

export const ALL_REPORT_COMPONENT_NAMES = {
  ...AMS_REPORT_COMPONENT_NAMES,
  ...IMS_REPORT_COMPONENT_NAMES,
  ...OBV_REPORT_COMPONENT_NAMES,
  ...SA_REPORT_COMPONENT_NAMES
};

export const ALL_REPORT_DISPLAY_NAMES = {
  ...AMS_REPORT_DISPLAY_NAMES,
  ...IMS_REPORT_DISPLAY_NAMES,
  ...OBV_REPORT_DISPLAY_NAMES,
  ...SA_REPORT_DISPLAY_NAMES
};

export const ALL_REPORT_RAW_EXPORT_NAMES = {
  ...AMS_REPORT_RAW_EXPORT_NAMES,
  ...IMS_REPORT_RAW_EXPORT_NAMES,
  ...OBV_REPORT_RAW_EXPORT_NAMES,
  ...SA_RAW_EXPORT_NAMES
};

import { AMS_VUE_COMPONENTS } from "@/views/reports/AMS/amsVueComponents";
import { IMS_VUE_COMPONENTS } from "@/views/reports/IMS/imsVueComponents";
import { OBV_VUE_COMPONENTS } from "@/views/reports/OBV/obvVueComponents";
import { SA_VUE_COMPONENTS } from "@/views/reports/SA/saVueComponent";

export const ALL_REPORT_VUE_COMPONENTS = {
  ...AMS_VUE_COMPONENTS,
  ...IMS_VUE_COMPONENTS,
  ...OBV_VUE_COMPONENTS,
  ...SA_VUE_COMPONENTS
};
