import Vue from "vue";
import i18n from "vue-i18n";
import enUSValidationMessages from "vee-validate/dist/locale/en";
import frCAValidationMessages from "vee-validate/dist/locale/fr";
import enUS from "@/assets/localization/enUS.json";
import frCA from "@/assets/localization/frCA.json";

Vue.use(i18n);

const locale = window.$locale || "en-US";

const customEnglishMessages = {
  password: "The {_field_} field is not a valid password.",
  is_not: "The {_field_} field is not valid.",
  isNotInList: "This value is already in use."
};

const customFrenchMessages = {
  password: "La {_field_} le champ n'est pas un mot de passe valide.",
  is_not: "Le champ {_field_} n'est pas valide.",
  isNotInList: "Cette valeur est déjà utilisée."
};

export default new i18n({
  locale: locale,
  messages: {
    "en-US": {
      ...enUS,
      validations: Object.assign(
        {},
        enUSValidationMessages.messages,
        customEnglishMessages
      )
    },
    "fr-CA": {
      ...frCA,
      validations: Object.assign(
        {},
        frCAValidationMessages.messages,
        customFrenchMessages
      )
    }
  }
});
