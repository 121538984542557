<template>
  <div>
    <manage-unit-thresholds
      v-if="manageUnitView.display"
      :locationId="manageUnitView.locationId"
      :levelName="manageUnitView.levelName"
      :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
      @close-manage-unit-view="closeManageUnitView"
    />
    <div v-show="!manageUnitView.display">
      <div
        class="
          tw-flex
          tw-justify-between
          tw-items-center
          tw-sticky
          tw-top-0
          tw-bg-white
        "
        style="z-index: 2"
      >
        <v-text-field
          v-model="search"
          :label="`${$t('ui.input-select.search')}`"
          class="tw-mr-4"
          clearable
          prepend-inner-icon="mdi-magnify"
          @input="handleSearch"
        />
        <v-btn text color="purellGray" @click="toggleAllOpen">
          <v-icon left v-if="!allOpen"> mdi-expand-all </v-icon>
          <v-icon left v-else> mdi-collapse-all </v-icon>
          {{ allOpen ? $t("ui.collapse-all") : $t("ui.expand-all") }}
        </v-btn>
      </div>
      <div>
        <v-btn
          v-if="!loading"
          class="tw-max-w-xs tw-mb-5"
          primary
          color="primary"
          @click="addLocationToTreeview()"
        >
          {{ $t("ui.actions.add") }}
          {{ createLevelName(2) }}
        </v-btn>
      </div>
      <v-skeleton-loader v-if="loading" type="list-item-three-line" />
      <v-treeview
        v-else
        class="tw-overflow-y-auto"
        :style="{ height: widgetHeight }"
        :items="locations"
        :search="search"
        :all-open="allOpen"
        item-children="childLocations"
        ref="treeview"
        hoverable
        :open.sync="openItems"
        item-key="ref"
      >
        <template v-slot:label="{ item }">
          <ValidationObserver
            v-if="item.isEditing || item.isNew"
            :ref="item.ref"
          >
            <v-form
              @submit.prevent="
                item.isNew ? addNewLocation(item) : updateLocationName(item)
              "
            >
              <ValidationProvider
                v-slot="{ errors }"
                :name="$t(`${translationObject}.name`)"
                :rules="{ required: true, max: maxLocationNameLength }"
              >
                <v-text-field
                  v-model="item.newName"
                  :ref="`${item.ref}Input`"
                  :label="$t(`${translationObject}.name`)"
                  :error-messages="errors"
                  :counter="maxLocationNameLength"
                />
              </ValidationProvider>
            </v-form>
          </ValidationObserver>
          <template v-else>
            {{ item.name }}
          </template>
        </template>
        <template v-slot:append="{ item }">
          <div v-if="item.isEditing || item.isNew">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  type="submit"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :loading="item.isWaiting"
                  @click="
                    item.isNew ? addNewLocation(item) : updateLocationName(item)
                  "
                >
                  <v-icon> mdi-content-save </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("ui.actions.save") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="handleCancelButton(item)"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("ui.actions.cancel") }} </span>
            </v-tooltip>
          </div>
          <div v-else class="tw-items-center tw-flex tw-flex-row">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-if="locationIsUnitOrWard(item) && hasObvLicense"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-switch
                    v-model="item.isObservable"
                    @click="updateObservationStatus(item)"
                    :loading="item.isWaiting"
                  />
                </div>
              </template>
              <span>{{
                $t(
                  "admin.facility-dashboard.hierarchy-widget.observation-app-checkbox"
                )
              }}</span>
            </v-tooltip>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon :loading="item.isWaiting">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="enableEditingState(item)">
                  <v-icon class="tw-mr-2"> mdi-pencil </v-icon>
                  {{ $t("ui.actions.edit") }}
                  {{ $t(`${translationObject}.name`) }}
                </v-list-item>
                <v-list-item
                  v-if="item.childLocations.length === 0 && canDeleteLocation"
                  @click="deleteLocation(item)"
                >
                  <v-icon class="tw-mr-2"> mdi-trash-can </v-icon>
                  {{ $t("ui.actions.delete") }}
                </v-list-item>
                <v-list-item
                  v-if="locationIsNotLowestLevel(item)"
                  @click="addLocationToTreeview(item)"
                >
                  <v-icon class="tw-mr-2"> mdi-plus </v-icon>
                  {{ determineAddCTA(item) }}
                </v-list-item>
                <v-list-item
                  v-if="locationIsUnitOrWard(item) && hasAmsLicense"
                  @click="openManageUnitView(item)"
                >
                  <v-icon class="tw-mr-2">mdi-vector-polyline-edit</v-icon>
                  {{ createLevelName(item.level) }}
                  {{ $t(`${translationObject}.thresholds`) }}
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-treeview>
    </div>
    <v-dialog v-model="showDeviceMaintenanceRedirect" max-width="500">
      <v-card>
        <v-container>
          <v-card-title>
            {{
              $t(
                "admin.facility-dashboard.locations-widget.error-messages.cannot-delete"
              )
            }}</v-card-title
          >
          <v-card-text>
            {{
              $t(
                "admin.facility-dashboard.locations-widget.error-messages.remove-devices"
              )
            }}
          </v-card-text>
          <v-card-actions class="tw-justify-end">
            <v-btn @click="showDeviceMaintenanceRedirect = false" text>
              {{ $t("ui.actions.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              :to="{
                name: ROUTE_NAMES.deviceMaintenance,
                query: { facilityId, locationId: selectedLocationId }
              }"
            >
              {{
                $t("admin.facility-dashboard.locations-widget.manage-devices")
              }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import locationHierarchyService from "@/api/locationHierarchyService";
import ManageUnitThresholds from "@/views/admin/components/facilityWidgets/manageWidgetViews/ManageUnitThresholds.vue";
import { ROUTE_NAMES } from "@/types/routeNames";
import PRIVILEGES, { checkPrivileges } from "@/types/privileges";

import i18n from "@/plugins/i18n";
export default {
  components: {
    ManageUnitThresholds
  },
  props: {
    fullscreen: { type: Boolean },
    customerId: { type: Number },
    facilityId: { type: Number },
    facility: { licenses: [], privileges: [] },
    hasObvLicense: { type: Boolean },
    hasAmsLicense: { type: Boolean },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    },
    changeWidgetCurrentTitle: {
      type: Function,
      default() {}
    },
    setWidgetFullscreen: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      ROUTE_NAMES,
      translationObject: "admin.facility-dashboard.hierarchy-widget",
      loading: true,
      manageUnitView: {
        display: false,
        locationId: 0,
        levelName: "",
        fullScreenStateBefore: false
      },
      allOpen: false,
      maxLocationNameLength: 50,
      search: "",
      facilityLocationId: null,
      locations: [],
      levelInfo: {},
      formRefNumber: 0,
      openItems: [],
      closeMenu: null,
      canDeleteLocation: false,
      showDeviceMaintenanceRedirect: false,
      selectedLocationId: 0
    };
  },
  computed: {
    widgetHeight() {
      return this.$vuetify.breakpoint.lgAndUp ? "310px" : "auto";
    }
  },
  async mounted() {
    try {
      const response = await locationHierarchyService.getLocationHierarchy(
        this.facilityId
      );

      this.facilityLocationId = response.locationHierarchy.id;
      this.levelInfo = response.levelInfo;

      const locationViewModel = this.setupLocations(
        response.locationHierarchy.childLocations,
        this.levelInfo,
        this.formRefNumber
      );

      this.locations = locationViewModel.locations;
      this.formRefNumber = locationViewModel.formRefNumber;
      this.loading = false;

      this.canDeleteLocation = checkPrivileges(
        this.facility.privileges,
        PRIVILEGES.deleteLocations
      );
    } catch (err) {
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t(
          `${this.translationObject}.error-messages.get-locations`
        )
      });
    }
  },
  methods: {
    setupLocations: (locations, levelInfo, formRefNumber) => {
      function setupLocationViewModel(location) {
        location.levelNameResourceKey = levelInfo[location.level.toString()];
        location.ref = `locationNameForm${formRefNumber}`;
        location.newName = location.name;
        location.isEditing = false;
        location.isNew = false;
        location.isWaiting = false;
        formRefNumber = formRefNumber + 1;

        if (location.childLocations) {
          location.childLocations.forEach((location) =>
            setupLocationViewModel(location)
          );
        }
      }

      locations.forEach((location) => setupLocationViewModel(location));

      return {
        locations,
        formRefNumber
      };
    },
    handleSearch(input) {
      if (input) {
        this.$refs.treeview.updateAll(true);
      } else {
        this.$refs.treeview.updateAll(false);
      }
    },
    async addNewLocation(location) {
      const valid = await this.$refs[location.ref].validate();

      if (!valid) {
        return;
      }

      location.isWaiting = true;
      const levelName = this.createLevelName(location.level);

      try {
        const response = await locationHierarchyService.addNewLocation(
          location.parentId,
          location.newName
        );

        this.$appInsights?.trackEvent({
          name: `Add New ${levelName} Form Success`
        });

        this.setLocalizedWidgetMessage({
          message: i18n.t(
            `${this.translationObject}.success-messages.add-new-location`,
            {
              levelName
            }
          )
        });

        location.id = response;
        location.name = location.newName;
        location.isNew = false;
        location.isWaiting = false;
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Add New ${levelName} Server Error`
        });
        const translationString =
          error === 409
            ? `${this.translationObject}.error-messages.duplicate-location-name`
            : `${this.translationObject}.error-messages.add-new-location`;

        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(translationString, { levelName })
        });
      }
    },
    async deleteLocation(location) {
      try {
        location.isWaiting = true;

        await locationHierarchyService.deleteLocation(location.id);

        this.locations = this.removeLocationFromHierarchy(
          [...this.locations],
          location.ref
        );

        this.setLocalizedWidgetMessage({
          message: i18n.t(
            "admin.facility-dashboard.locations-widget.location-deleted"
          )
        });
      } catch (error) {
        location.isWaiting = false;
        this.$appInsights?.trackEvent({
          name: `Delete LocationId: ${location.id} | Delete Location Error`
        });

        if (error === 412) {
          this.selectedLocationId = location.id;
          this.showDeviceMaintenanceRedirect = true;
        } else if (error === 409) {
          this.setLocalizedWidgetMessage({
            message: i18n.t(
              "admin.facility-dashboard.locations-widget.error-messages.location-has-events"
            ),
            showError: true
          });
        } else {
          this.setLocalizedWidgetMessage({
            message: i18n.t(
              "admin.facility-dashboard.locations-widget.error-messages.delete-location-generic"
            ),
            showError: true
          });
        }
      }
    },
    async updateLocationName(location) {
      const valid = await this.$refs[location.ref].validate();

      if (!valid) {
        return;
      }

      location.isWaiting = true;
      const levelName = this.createLevelName(location.level);

      try {
        await locationHierarchyService.updateLocationName(
          location.id,
          location.newName
        );
        this.$appInsights?.trackEvent({
          name: `Edit ${levelName} Name Form Success`
        });

        this.setLocalizedWidgetMessage({
          message: i18n.t(
            `${this.translationObject}.success-messages.update-location-name`,
            {
              levelName
            }
          )
        });

        location.name = location.newName;
        location.isEditing = false;
        location.isWaiting = false;
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Edit ${levelName} Name Server Error`
        });
        const translationString =
          error === 409
            ? `${this.translationObject}.error-messages.duplicate-location-name`
            : `${this.translationObject}.error-messages.update-location-name`;

        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(translationString, { levelName })
        });
      }
    },
    async addLocationToTreeview(location) {
      const addingFloor = !location;
      const parentId = addingFloor ? this.facilityLocationId : location.id;
      const level = addingFloor ? 2 : location.level + 1;

      const newLocation = {
        parentId,
        name: "",
        newName: "",
        isObservable: false,
        level,
        levelNameResourceKey: this.levelInfo[level],
        childLocations: [],
        ref: `locationNameForm${this.formRefNumber}`,
        isNew: true,
        isEditing: false,
        isWaiting: false
      };

      newLocation.isObservable = !!(
        this.locationIsUnitOrWard(newLocation) && this.hasObvLicense
      );

      this.formRefNumber = this.formRefNumber + 1;

      if (addingFloor) {
        this.locations.unshift(newLocation);
      } else {
        location.childLocations.unshift(newLocation);
        this.openItems.push(location.ref);
      }

      await this.$nextTick();
      this.$refs[`${newLocation.ref}Input`]?.focus();
    },
    async enableEditingState(item) {
      item.isEditing = true;
      await this.$nextTick();
      this.$refs[`${item.ref}Input`]?.focus();
    },
    async updateObservationStatus(location) {
      location.isWaiting = true;
      const levelName = this.createLevelName(location.level);

      try {
        await locationHierarchyService.updateLocationObservationStatus(
          location.id,
          location.isObservable
        );

        this.setLocalizedWidgetMessage({
          message: i18n.t(
            `${this.translationObject}.success-messages.update-location-observation-status`,
            {
              levelName
            }
          )
        });
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            `${this.translationObject}.error-messages.update-location-observation-status`,
            { levelName }
          )
        });
      }
      location.isWaiting = false;
    },
    handleCancelButton(location) {
      if (location.isEditing) {
        location.newName = location.name;
        location.isEditing = false;
      } else {
        this.locations = this.removeLocationFromHierarchy(
          [...this.locations],
          location.ref
        );
      }
    },
    removeLocationFromHierarchy: (locations, ref) => {
      function filterLocation(locations) {
        return locations.filter((location) => {
          if (location.childLocations) {
            location.childLocations = filterLocation(location.childLocations);
          }

          return location.ref !== ref;
        });
      }

      return filterLocation(locations);
    },
    openManageUnitView(location) {
      this.manageUnitView = {
        display: true,
        locationId: location.id,
        levelName: this.createLevelName(location.level),
        fullScreenStateBefore: this.fullscreen
      };

      this.setWidgetFullscreen(true);
      this.changeWidgetCurrentTitle(
        `${location.name} - ${i18n.t(
          "admin.manage-facility.settings.report-settings"
        )}`
      );
    },
    closeManageUnitView() {
      if (this.fullscreen) {
        this.setWidgetFullscreen(this.manageUnitView.fullScreenStateBefore);
      }
      this.changeWidgetCurrentTitle(
        i18n.t("admin.facility-dashboard.facility-hierarchy")
      );

      this.manageUnitView = {
        display: false
      };
    },
    toggleAllOpen() {
      this.allOpen = !this.allOpen;
      this.$refs.treeview.updateAll(this.allOpen);
    },
    createLevelName(level) {
      return i18n.t(`locations.levelNames.${this.levelInfo[level]}`);
    },
    locationIsNotLowestLevel(location) {
      const levelIds = Object.keys(this.levelInfo);
      return location.level !== parseInt(levelIds[levelIds.length - 1]);
    },
    locationIsUnitOrWard(location) {
      return (
        location.levelNameResourceKey === "LocationLevelUnit" ||
        location.levelNameResourceKey === "LocationLevelWard"
      );
    },
    determineAddCTA(location) {
      return `${i18n.t("ui.actions.add")} ${this.createLevelName(
        location.level + 1
      )}`;
    }
  }
};
</script>

<style scoped lang="postcss">
:v-deep(.v-treeview-node__toggle),
:v-deep(.v-treeview-node__level) {
  height: 35px;
  width: 35px;
  font-size: 28px;
}
</style>
