<template>
  <v-skeleton-loader :type="`image`" v-if="reportLoading" />
  <div
    v-else-if="cells.length"
    class="
      tw-flex tw-flex-col tw-mb-5 tw-flex-wrap
      md:tw-flex-row
      print:tw-flex-row
      tw-bg-primary tw-rounded-lg tw-shadow-md tw-justify-around tw-w-full
      md:tw-w-3/4
      tw-mx-auto tw-border-solid tw-border-primary
    "
    :class="{
      'tw-max-w-6xl': isSlideshow,
      'single-cell': cells.length === 1,
      'tw-max-w-2xl': cells.length > 1 && cells.length < 10,
      'tw-max-w-none': cells.length > 10
    }"
  >
    <div
      v-for="cell in cells"
      :key="cell.text"
      class="tw-flex tw-flex-col tw-p-3 tw-text-center"
    >
      <div
        class="tw-text-white tw-text-3xl tw-font-bold"
        :class="{ 'tw-text-5xl': isSlideshow }"
      >
        <component :is="cell.display || 'div'" :value="cell.value">
          {{ cell.value }}
        </component>
      </div>
      <h3
        class="tw-text-xs tw-text-white"
        :class="{ 'tw-text-2xl': isSlideshow }"
      >
        {{ cell.text }}
      </h3>
    </div>
  </div>
</template>

<script>
import DisplayFormatterSparkline from "./displayFormatters/DisplayFormatterSparkline.vue";
import DisplayFormatterPercentage from "./displayFormatters/DisplayFormatterPercentage.vue";
import DisplayFormatterIndicator from "./displayFormatters/DisplayFormatterIndicator.vue";
import DisplayFormatterLocale from "./displayFormatters/DisplayFormatterLocale.vue";
import { mapState } from "vuex";

export default {
  components: {
    SparkLine: DisplayFormatterSparkline,
    Percentage: DisplayFormatterPercentage,
    Indicator: DisplayFormatterIndicator,
    Locale: DisplayFormatterLocale
  },
  props: {
    cells: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState("reports", ["isSlideshow", "reportLoading"])
  }
};
</script>
<style>
.single-cell {
  max-width: 240px;
}
</style>
