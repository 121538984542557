<template>
  <div>
    <facility-selection-bar :interactable="false" class="tw-my-8" />
    <header class="tw-my-5 tw-flex tw-justify-between">
      <h1>
        {{
          reportSubscriptionId
            ? $t("report-subscriptions.actions.edit")
            : $t("report-subscriptions.actions.create")
        }}
      </h1>
      <v-btn @click="cancel()" icon primary>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </header>
    <v-stepper style="box-shadow: none" v-model="step">
      <v-stepper-header class="tw-shadow-none">
        <v-stepper-step
          class="tw-cursor-pointer"
          @click="jumpToStep(1)"
          :complete="step > 1"
          step="1"
        >
          {{ $t("report-subscriptions.step-names.select-report") }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :class="selectedReportTypeId ? 'tw-cursor-pointer' : ''"
          @click="jumpToStep(2)"
          :complete="step > 2"
          step="2"
        >
          {{ $t("report-subscriptions.step-names.report-filters") }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :class="selectedReportTypeId ? 'tw-cursor-pointer' : ''"
          @click="jumpToStep(3)"
          step="3"
        >
          {{ $t("report-subscriptions.step-names.customization") }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <sticky-actions>
            <span></span>
            <v-btn
              @click="jumpToStep(2)"
              color="primary"
              :disabled="!selectedReportTypeId"
            >
              {{ $t("ui.actions.continue") }}
            </v-btn>
          </sticky-actions>
          <report-types
            :reportTypes="reportTypes"
            :selectedReportTypeId="selectedReportTypeId"
            v-on:update-selected-report-types="updateSelectedReportTypes"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <sticky-actions>
            <v-btn @click="jumpToStep(1)" color="secondary">
              {{ $t("ui.actions.previous") }}
            </v-btn>
            <v-btn @click="jumpToStep(3)" color="primary">
              {{ $t("ui.actions.continue") }}
            </v-btn>
          </sticky-actions>
          <report-filters :selectedReportTypeId="selectedReportTypeId" />
        </v-stepper-content>
        <v-stepper-content step="3">
          <sticky-actions>
            <v-btn @click="jumpToStep(2)" color="secondary">
              {{ $t("ui.actions.previous") }}
            </v-btn>
            <v-btn type="submit" @click="setSaving(true)" color="primary">
              <span v-if="reportSubscriptionId">
                {{ $t("report-subscriptions.actions.save") }}
              </span>
              <span v-else>{{
                $t("report-subscriptions.actions.create")
              }}</span>
            </v-btn>
          </sticky-actions>
          <customization
            :originalReportSubscription="originalReportSubscription"
            :reportSubscriptionId="reportSubscriptionId"
            :saving="saving"
            :selectedReportTypeId="selectedReportTypeId"
            v-on:unsuccessful-save="() => setSaving(false)"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-overlay opacity="0.7" v-if="saving">
      <v-progress-circular size="70" width="7" indeterminate color="primary" />
    </v-overlay>
  </div>
</template>

<script>
import StickyActions from "./components/StickyActions";
import ReportTypes from "./components/ReportTypes";
import ReportFilters from "./components/ReportFilters";
import Customization from "./components/Customization";
import { FILTERS } from "../../types/filters";
import { mapState } from "vuex";
import reportSubscriptionService from "@/api/reportSubscriptionService";
import FacilitySelectionBar from "@/components/layout/FacilitySelectionBar.vue";
import { PRODUCTS } from "@/types/products";

export default {
  props: {
    facilityId: {
      type: Number
    },
    reportTypes: {
      type: Array,
      default: () => []
    },
    reportSubscriptionId: {
      type: Number
    }
  },
  components: {
    Customization,
    ReportFilters,
    ReportTypes,
    StickyActions,
    FacilitySelectionBar
  },
  data() {
    return {
      filtersPopulated: false,
      filtersToPopulate: [
        ...Object.values(FILTERS).filter(
          (f) => f !== FILTERS.DEVICES && f !== FILTERS.TIMEFRAME
        )
      ],
      originalReportSubscription: {},
      saving: false,
      selectedReportTypeId: undefined,
      step: 1
    };
  },
  computed: {
    ...mapState("customers", ["customerId"])
  },
  async mounted() {
    if (this.facilityId) {
      this.populateFilters();
    }
    if (this.reportSubscriptionId) {
      this.originalReportSubscription =
        await reportSubscriptionService.getReportSubscription(
          this.reportSubscriptionId
        );

      this.selectedReportTypeId =
        this.originalReportSubscription.selectedReportTypeId;

      this.setActiveFilters();
    }
  },
  methods: {
    cancel() {
      this.$router.push({
        name: "reportSubscriptions"
      });
    },
    jumpToStep(step) {
      if (!this.selectedReportTypeId) {
        return;
      }

      this.$appInsights?.trackEvent({
        name: `User Enters Report Subscription Step ${step}`
      });

      this.step = step;
    },
    async populateFilters() {
      await Promise.all(
        this.filtersToPopulate.map((filter) =>
          this.$store.dispatch(`filters/${filter}/load`, {
            customerId: this.customerId,
            facilityId: this.facilityId,
            locationLevel: 3,
            productId: PRODUCTS.IMS // hard coded for now until filter update completed task 7691
          })
        )
      );

      this.filtersPopulated = true;
    },
    setActiveFilters() {
      const reportSubscriptionFilters = {
        [FILTERS.SHIFTS]: "selectedShiftIds",
        [FILTERS.IMSJOBROLES]: "selectedJobRoleIds",
        [FILTERS.LOCATIONS]: "selectedLocationIds",
        [FILTERS.EMPLOYEES]: "selectedEmployeeIds"
      };

      this.filtersToPopulate.forEach((filterId) => {
        this.$store.commit(`filters/${filterId}/set`, {
          field: "activeIds",
          value:
            this.originalReportSubscription[reportSubscriptionFilters[filterId]]
        });
      });
    },
    setSaving(value) {
      this.saving = value;
    },
    updateSelectedReportTypes(id) {
      if (this.selectedReportTypeId === id) {
        this.selectedReportTypeId = undefined;
      } else {
        this.selectedReportTypeId = id;
      }
    }
  },
  watch: {
    facilityId() {
      this.populateFilters();
    },
    filtersPopulated() {
      if (Object.keys(this.originalReportSubscription).length) {
        this.setActiveFilters();
      }
    }
  }
};
</script>
