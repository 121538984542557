<template>
  <div>
    <header class="tw-my-5 tw-flex tw-justify-between">
      <h1 v-if="slideshowId">
        {{ $t("slideshows.edit-slideshow") }}
      </h1>
      <h1 v-else>{{ $t("slideshows.create-slideshow") }}</h1>
      <v-btn @click="cancelEditSlideshow()" icon primary>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </header>
    <v-stepper style="box-shadow: none" v-model="step">
      <v-stepper-header class="tw-shadow-none">
        <v-stepper-step
          class="tw-cursor-pointer"
          @click="jumpToStep(1)"
          :complete="step > 1"
          step="1"
        >
          {{ $t("slideshows.step-names.select-slides") }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :class="hasSelectedSlides ? 'tw-cursor-pointer' : ''"
          @click="jumpToStep(2)"
          :complete="step > 2"
          step="2"
        >
          {{ $t("slideshows.step-names.slide-order") }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          :class="hasSelectedSlides ? 'tw-cursor-pointer' : ''"
          @click="jumpToStep(3)"
          step="3"
        >
          {{ $t("slideshows.step-names.customization") }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <sticky-actions>
            <v-btn
              v-if="checkPermission(PERMISSIONS.createImageSlides)"
              secondary
              color="secondary"
              @click="toggleAddImagesOverlay()"
            >
              {{ $t("ui.actions.add-image") }}
            </v-btn>
            <v-btn
              @click="jumpToStep(2)"
              :disabled="!hasSelectedSlides"
              color="primary"
            >
              {{ $t("ui.actions.continue") }}
            </v-btn>
          </sticky-actions>
          <add-images-dialog
            :showDialog="addImageDialogIsOpen"
            @close-add-images-dialog="toggleAddImagesOverlay"
            @add-images-successful="addImagesToSlides"
          />
          <slides-table
            :facilityId="facilityId"
            :slideshowSlides="slideshowSlides"
            :addedSlide="addedSlide"
            v-on:update-selected-slides="updateSelectedSlides"
            v-on:add-selected-slide="addSelectedSlide"
            v-on:remove-selected-slide="removeSelectedSlide"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <sticky-actions>
            <v-btn @click="jumpToStep(1)" color="secondary">
              {{ $t("ui.actions.previous") }}
            </v-btn>
            <v-btn
              @click="jumpToStep(3)"
              :disabled="!hasSelectedSlides"
              color="primary"
            >
              {{ $t("ui.actions.continue") }}
            </v-btn>
          </sticky-actions>
          <slides-order
            :selectedSlides="selectedSlides"
            v-on:update-selected-slides="updateSelectedSlides"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <sticky-actions>
            <v-btn @click="jumpToStep(2)" color="secondary">
              {{ $t("ui.actions.previous") }}
            </v-btn>
            <v-btn
              @click="toggleSaveSlideshow"
              :disabled="!hasSelectedSlides"
              color="primary"
              type="submit"
            >
              <span v-if="slideshowId">
                {{ $t("slideshows.save-slideshow") }}
              </span>
              <span v-else>{{ $t("slideshows.create-slideshow") }}</span>
            </v-btn>
          </sticky-actions>
          <slideshow-customization-form
            :facilityId="facilityId"
            :slideshowId="slideshowId"
            :slideshowResponse="slideshowResponse"
            :selectedSlides="selectedSlides"
            :customizationStepIsActive="step === 3"
            :savingSlideshow="savingSlideshow"
            v-on:unsuccessful-save="toggleSaveSlideshow"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <loading-overlay v-if="savingSlideshow" />
  </div>
</template>

<script>
import slideshowService from "@/api/slideshowsService";
import LoadingOverlay from "@/components/ui/LoadingOverlay.vue";
import StickyActions from "./components/StickyActions.vue";
import SlidesTable from "./components/SlidesTable.vue";
import AddImagesDialog from "./components/AddImagesDialog.vue";
import SlidesOrder from "./components/SlidesOrder.vue";
import SlideshowCustomizationForm from "./components/SlideshowCustomizationForm.vue";
import { PERMISSIONS } from "@/types/permissions";
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  props: {
    facilityId: {
      type: Number
    },
    slideshowId: {
      type: Number
    }
  },
  components: {
    LoadingOverlay,
    StickyActions,
    AddImagesDialog,
    SlidesTable,
    SlidesOrder,
    SlideshowCustomizationForm
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"])
  },
  data() {
    return {
      PERMISSIONS,
      slideshowResponse: {},
      slideshowSlides: [],
      addImageDialogIsOpen: false,
      step: 1,
      selectedSlides: [],
      hasSelectedSlides: false,
      savingSlideshow: false,
      addedSlide: {}
    };
  },
  methods: {
    toggleSaveSlideshow() {
      this.savingSlideshow = !this.savingSlideshow;

      if (this.savingSlideshow) {
        this.$appInsights?.trackEvent({
          name: `Save Slideshow`
        });
      }
    },
    updateSelectedSlides(slides) {
      this.selectedSlides = slides;
    },
    addSelectedSlide(slide) {
      this.selectedSlides.push(slide);
    },
    removeSelectedSlide(slide) {
      const filtered = this.selectedSlides.filter(function (selectedSlide) {
        return selectedSlide.id !== slide.id;
      });
      this.selectedSlides = filtered;
    },
    jumpToStep(step) {
      if (step === 1) {
        this.step = step;
        this.$appInsights?.trackEvent({
          name: `User Enters Slideshow Step ${step}`
        });
        return;
      }

      if (!this.selectedSlides.length) {
        return;
      }

      this.step = step;
      this.$appInsights?.trackEvent({
        name: `User Enters Step ${step}`
      });
    },
    toggleAddImagesOverlay() {
      this.addImageDialogIsOpen = !this.addImageDialogIsOpen;

      const addImageDialogStatus = this.addImageDialogIsOpen ? "Open" : "Close";
      this.$appInsights?.trackEvent({
        name: `${addImageDialogStatus} Add Image Dialog`
      });
    },
    addImagesToSlides(data) {
      this.addedSlide = data;
      this.toggleAddImagesOverlay();
    },
    cancelEditSlideshow() {
      this.$router.push({
        name: "slideshowsList"
      });
    }
  },
  watch: {
    slideshowId: {
      immediate: true,
      async handler(slideshowId) {
        if (!slideshowId) {
          return;
        }
        try {
          this.slideshowResponse = await slideshowService.getSlideshow(
            slideshowId
          );
          this.slideshowSlides = this.slideshowResponse.slides
            ? this.slideshowResponse.slides
            : [];
        } catch (error) {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("slideshows.error-messages.get-slideshow")
          });
        }
      }
    },
    selectedSlides(value) {
      this.hasSelectedSlides = !!value.length;
    }
  }
};
</script>
