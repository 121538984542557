<template>
  <default-layout>
    <v-container :style="style">
      <v-card>
        <section class="tw-p-4 sm:tw-px-8 tw-inline-block tw-w-full">
          <router-view />
        </section>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/Default";
import { ROUTE_NAMES } from "@/types/routeNames";

export default {
  components: {
    DefaultLayout
  },
  computed: {
    style() {
      const maxWidths = {
        [ROUTE_NAMES.customerDashboard]: "100%",
        [ROUTE_NAMES.facilityDashboard]: "100%",
        [ROUTE_NAMES.deviceMaintenance]: "100%",
        [ROUTE_NAMES.editFacility]: this.$vuetify.breakpoint.mdAndDown
          ? "100%"
          : "70%",
        [ROUTE_NAMES.createFacility]: this.$vuetify.breakpoint.mdAndDown
          ? "100%"
          : "70%"
      };

      return maxWidths[this.$route.name]
        ? {
            "max-width": maxWidths[this.$route.name]
          }
        : null;
    }
  }
};
</script>
