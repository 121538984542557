<template>
  <div>
    <filter-popup
      v-if="selectedFilterId"
      name="filterPopup"
      :value="changeFilterPopupVisible"
      :filterId="selectedFilterId"
      :filterState="selectedFilterState"
      @input="onCloseFilterPopup"
      @filterChanged="onFilterChanged"
      :forceSelection="filterFeatures[selectedFilterId].forceSelection"
      :orderedItems="$store.getters[`filters/${selectedFilterId}/orderedItems`]"
      :showSearch="filterFeatures[selectedFilterId].showSearch"
      :selectAll="filterFeatures[selectedFilterId].selectAll"
      :selectionComponent="filterFeatures[selectedFilterId].selectionComponent"
      :timeframes="filterFeatures[selectedFilterId].timeframes"
      :showOtherOption="filterFeatures[selectedFilterId].showOtherOption"
      :otherOptionText="filterFeatures[selectedFilterId].otherOptionText"
    />
    <responsive-popup
      ref="filters-list-popup"
      :value="reportFiltersPopupVisible"
      @save="onApply"
      @cancel="onCancel"
    >
      <v-subheader class="tw-font-bold tw-text-xl">
        {{ $t("ui.edit-filters") }}
      </v-subheader>
      <div class="limit-height">
        <filters-entry
          v-for="(filterState, filterId) in reportFiltersState"
          :key="filterId"
          :filterText="getFilterTextAbbreviated(filterId, filterState)"
          :disabled="filterFeatures[filterId].disabled"
          @click="(e) => onFilterSelection(filterId)"
        >
          {{ $t(`filters.${filterId}`) }}
        </filters-entry>
      </div>
      <v-divider />
      <v-card-actions class="tw-w-full tw-justify-between">
        <v-btn name="cancelBtn" text @click="onCancel" color="purellGray">
          {{ this.$t("ui.actions.cancel") }}
        </v-btn>
        <v-btn name="applyBtn" text @click="onApply" color="primary">
          {{ this.$t("ui.actions.apply") }}
        </v-btn>
      </v-card-actions>
    </responsive-popup>
    <div class="tw-my-2 tw-w-full tw-flex tw-justify-left tw-flex-wrap">
      <v-btn
        ref="edit-filters-button"
        depressed
        color="primary"
        @click="onEditFilters()"
        class="tw-mx-2"
      >
        <v-icon class="tw-mr-2"> mdi-filter </v-icon>
        {{ $t("ui.edit-filters") }}
      </v-btn>
      <save-report-button class="tw-mx-2" />
      <report-actions class="tw-mx-2" />
      <v-btn
        depressed
        text
        color="primary"
        @click="refreshReport()"
        class="tw-mx-2"
      >
        <v-icon class="tw-mr-2"> mdi-refresh </v-icon>
        {{ $t("ui.actions.refresh-report") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import FiltersEntry from "./components/FiltersEntry.vue";
import SaveReportButton from "./components/SaveReportButton.vue";
import ResponsivePopup from "@/views/reports/components/ResponsivePopup.vue";
import FilterPopup from "./components/filtersPopup/FilterPopup.vue";
import ReportActions from "@/views/reports/components/reportActions/Menu.vue";
import DisabledFiltersMixin from "@/views/reports/components/filters/DisabledFiltersMixin";
import { FILTERS } from "@/types/filters";
import { mapState, mapGetters } from "vuex";
import { includes, merge } from "lodash";
import i18n from "@/plugins/i18n";

export default {
  mixins: [DisabledFiltersMixin],
  components: {
    FiltersEntry,
    SaveReportButton,
    ResponsivePopup,
    FilterPopup,
    ReportActions
  },
  props: {
    outsideSelectedFilter: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    reportFiltersPopupVisible: false,
    changeFilterPopupVisible: false,
    selectedFilterId: undefined,
    reportFiltersState: {},
    changedFilters: {},
    selectedFilterState: {}
  }),
  computed: {
    ...mapState("reports", ["reportId", "forcedReportFilters"]),
    ...mapGetters("reports", ["activeFilters"]),
    filterFeatures() {
      const filterFeatures = {
        [FILTERS.IMSJOBROLES]: {
          showSearch: true,
          selectAll: true,
          selectionComponent: "filters-popup-multi-selectable-items",
          forceSelection: true
        },
        [FILTERS.OBVJOBROLES]: {
          showSearch: true,
          selectAll: true,
          selectionComponent: "filters-popup-multi-selectable-items",
          forceSelection: true
        },
        [FILTERS.SHIFTS]: {
          showSearch: true,
          selectAll: true,
          selectionComponent: "filters-popup-multi-selectable-items",
          forceSelection: includes(this.forcedReportFilters, FILTERS.SHIFTS),
          showOtherOption: !includes(this.forcedReportFilters, FILTERS.SHIFTS),
          otherOptionText: this.$t("shifts.all-24-hours")
        },
        [FILTERS.TIMEFRAME]: {
          showSearch: false,
          selectAll: false,
          selectionComponent: "filters-popup-single-selectable-items",
          forceSelection: true
        },
        [FILTERS.REPLACEMENT_DATE]: {
          showSearch: false,
          selectAll: false,
          selectionComponent: "filters-popup-single-selectable-items",
          forceSelection: true
        },
        [FILTERS.EXPIRATION_DATE]: {
          showSearch: false,
          selectAll: false,
          selectionComponent: "filters-popup-single-selectable-items",
          forceSelection: true
        },
        [FILTERS.LOCATIONS]: {
          showSearch: true,
          selectAll: true,
          selectionComponent: "filters-popup-hierarchy-items",
          forceSelection: true
        },
        [FILTERS.DEVICES]: {
          showSearch: true,
          selectAll: true,
          selectionComponent: "filters-popup-hierarchy-items",
          forceSelection: includes(this.forcedReportFilters, FILTERS.DEVICES)
        },
        [FILTERS.HYGIENEPROTOCOLOPPORTUNITIES]: {
          showSearch: true,
          selectAll: true,
          selectionComponent: "filters-popup-multi-selectable-items",
          forceSelection: true
        },
        [FILTERS.PRECAUTIONOPTIONS]: {
          showSearch: false,
          selectAll: false,
          selectionComponent: "filters-popup-single-selectable-items",
          forceSelection: true
        },
        [FILTERS.ACTIVITYBY]: {
          showSearch: false,
          selectAll: false,
          selectionComponent: "filters-popup-single-selectable-items",
          forceSelection: true
        },
        [FILTERS.OBVYEARS]: {
          showSearch: false,
          selectAll: true,
          selectionComponent: "filters-popup-multi-selectable-items",
          forceSelection: true
        },
        [FILTERS.MONTHS]: {
          showSearch: false,
          selectAll: true,
          selectionComponent: "filters-popup-multi-selectable-items",
          forceSelection: true
        },
        [FILTERS.OBSERVER_OPTIONS]: {
          showSearch: false,
          selectAll: false,
          selectionComponent: "filters-popup-single-selectable-items",
          forceSelection: true
        }
      };

      return merge(filterFeatures, this.filterDisabledStates);
    },
    activeFiltersState() {
      return this.reportFiltersState;
    }
  },
  methods: {
    onEditFilters() {
      this.$appInsights?.trackEvent({ name: "Open Filters" });
      this.reportFiltersState = Object.assign(
        {},
        ...this.activeFilters.map((filterId) => ({
          [filterId]: this.$store.state[`filters/${filterId}`]
        }))
      );
      this.reportFiltersPopupVisible = true;
    },
    onFilterSelection(id) {
      this.$appInsights?.trackEvent({ name: `Open ${id} Filter` });
      this.reportFiltersPopupVisible = false;
      this.selectedFilterState = this.reportFiltersState[id];
      this.selectedFilterId = id;
      this.changeFilterPopupVisible = true;
    },
    onCloseFilterPopup() {
      this.changeFilterPopupVisible = false;
      this.selectedFilterId = undefined;

      if (!this.outsideSelectedFilter?.filterId) {
        this.reportFiltersPopupVisible = true;
      } else {
        this.updateFilters();

        if (
          this.outsideSelectedFilter.filterId === FILTERS.TIMEFRAME &&
          this.activeFilters.includes(FILTERS.SHIFTS) &&
          this.$store.state[`filters/${FILTERS.SHIFTS}`].activeIds.length &&
          this.areShiftsDisabled === true
        ) {
          this.$store.commit(
            "application/SET_SUCCESS_MESSAGE",
            i18n.t("filters.messages.shift-filter-limited")
          );
        }
        this.$emit("clear-outside-selected-filter");
      }
    },
    onFilterChanged({ filterId, filter }) {
      this.changedFilters = { ...this.changedFilters, [filterId]: filter };
      this.reportFiltersState = {
        ...this.reportFiltersState,
        [filterId]: filter
      };
      this.onCloseFilterPopup();
    },
    onApply() {
      this.$appInsights?.trackEvent({ name: "Apply Filters" });
      this.reportFiltersPopupVisible = false;
      this.updateFilters();
    },
    onCancel() {
      this.$appInsights?.trackEvent({ name: "Cancel Filters" });
      this.reportFiltersPopupVisible = false;
    },
    getFilterTextAbbreviated(filterId, filterState) {
      const filterFeature = this.filterFeatures[filterId];
      return filterFeature.disabled
        ? filterFeature.disabledText
        : this.$store.getters[`filters/${filterId}/filterTextAbbreviated`](
            filterState
          );
    },
    updateFilters() {
      if (Object.keys(this.changedFilters).length) {
        this.$store.commit("reports/setLoadingFilters", true);
        for (const [filterId, filterState] of Object.entries(
          this.changedFilters
        )) {
          this.$store.commit(`filters/${filterId}/set`, {
            field: "activeIds",
            value: filterState.activeIds
          });
          if (filterId === FILTERS.TIMEFRAME) {
            this.$store.commit("filters/timeframe/set", {
              field: "start",
              value: filterState.start
            });
            this.$store.commit("filters/timeframe/set", {
              field: "end",
              value: filterState.end
            });
          }
        }
        this.$store.commit("reports/setLoadingFilters", false);
      }
    },
    clearActiveShifts() {
      if (this.changedFilters.shifts) {
        this.changedFilters.shifts.activeIds = [];
      } else if (this.activeFilters.includes(FILTERS.SHIFTS)) {
        const shifts = Object.assign({}, this.$store.state[`filters/shifts`]);
        shifts.activeIds = [];
        this.changedFilters.shifts = shifts;
      }
    },
    refreshReport() {
      this.$appInsights?.trackEvent({ name: "Refresh Report" });
      this.$store.commit("reports/setReloadReportFlag", true);
    }
  },
  watch: {
    outsideSelectedFilter(value) {
      if (value.filterId) {
        this.reportFiltersState[value.filterId] = value.filterState;
        this.onFilterSelection(value.filterId);
      }
    }
  }
};
</script>
