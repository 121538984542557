import { get, post, put } from "./apiClient";

export default {
  async getJobRolesByFacilityId(facilityId) {
    return await get(`ObvJobRole?facilityId=${facilityId}`);
  },
  async saveJobRole(jobRole) {
    return await post(`ObvJobRole`, jobRole);
  },
  async setActiveStatus(jobRole) {
    return await put(`ObvJobRole/ActiveStatus`, jobRole);
  },
  async updateJobRoleName(jobRole) {
    return await put(`ObvJobRole/JobRoleName`, jobRole);
  },
  async getJobRoleTypes(facilityId) {
    return await get(`ObvJobRole/Types?facilityId=${facilityId}`);
  },
  async cloneJobRolesToFacilities(requestModel) {
    return await post(`ObvJobRole/Copy`, requestModel);
  }
};
