<template>
  <div>
    <h3 class="tw-mb-5">
      {{ $t("slideshows.slide-order-directions") }}
    </h3>
    <draggable
      v-model="orderedSlides"
      v-bind="dragOptions"
      @end="updateSelectedSlides()"
    >
      <transition-group type="transition" name="slide-list">
        <v-list-item
          class="tw-mb-2"
          v-for="slide in orderedSlides"
          :key="`order${slide.id}`"
        >
          <div
            class="
              tw-flex
              tw-px-5
              tw-w-full
              tw-bg-background
              tw-rounded
              tw-cursor-pointer
            "
          >
            <v-list-item-icon>
              <v-icon>{{ getReportType(slide.reportId) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="tw-text-left">
              <v-list-item-title>{{ slide.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon class="tw-justify-self-end">
              <v-icon>mdi-drag-vertical</v-icon>
            </v-list-item-icon>
          </div>
        </v-list-item>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { AMS_REPORTS } from "@/types/amsReports";

export default {
  props: {
    selectedSlides: {
      type: Array
    }
  },
  components: {
    draggable
  },
  data: () => ({
    drag: false,
    showDialog: false,
    orderedSlides: []
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  watch: {
    selectedSlides(value) {
      this.orderedSlides = [...value];
    }
  },
  methods: {
    getReportType(reportId) {
      const barGraphs = [
        AMS_REPORTS.PERFORMANCE_BAR_GRAPH,
        AMS_REPORTS.DISPENSER_EVENTS,
        AMS_REPORTS.DEVICE_METRICS
      ];
      const lineGraphs = [AMS_REPORTS.PERFORMANCE_HISTORY];
      const tables = [
        AMS_REPORTS.ROOM_LEVEL_METRICS,
        AMS_REPORTS.PERFORMANCE_SUMMARY,
        AMS_REPORTS.SHIFT_COMPARISON
      ];

      if (barGraphs.includes(reportId)) {
        return "mdi-chart-bar";
      } else if (lineGraphs.includes(reportId)) {
        return "mdi-chart-line-variant";
      } else if (tables.includes(reportId)) {
        return "mdi-table";
      } else {
        return "mdi-image";
      }
    },
    setData(dataTransfer) {
      dataTransfer.setDragImage(document.createElement("div"), 0, 0);
    },
    updateSelectedSlides() {
      this.$emit("update-selected-slides", this.orderedSlides);
    }
  }
};
</script>

<style scoped>
.sortable-chosen button {
  background: #999 !important;
}
</style>
