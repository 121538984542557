import i18n from "@/plugins/i18n";
import { precautionOptions } from "@/types/precautionOptions";

export default {
  async list() {
    const precautionOptionValues = [
      {
        id: precautionOptions.all,
        text: i18n.t("precautionOptions.all")
      },
      {
        id: precautionOptions.airborne,
        text: i18n.t("precautionOptions.air")
      },
      {
        id: precautionOptions.contact,
        text: i18n.t("precautionOptions.contact")
      },
      {
        id: precautionOptions.droplet,
        text: i18n.t("precautionOptions.droplet")
      },
      {
        id: precautionOptions.standard,
        text: i18n.t("precautionOptions.standard")
      }
    ];

    return new Promise((res) => res(precautionOptionValues));
  }
};
