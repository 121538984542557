<template>
  <div>
    <report-summary :cells="cells" />
    <report-performance-bands :performanceBands="performanceBands" />
    <report-table
      :headers="headers"
      :items="items"
      :initialSortedColumns="['lastName']"
    />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportPerformanceBands from "@/views/reports/components/reportSections/ReportPerformanceBands.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import { group } from "@/views/reports/utils/group";
import { sortEventsByDate } from "@/views/reports/utils/sort";
import {
  sumEvents,
  sumEntries,
  setEventsIndicator,
  createPercentage,
  totalPercentage
} from "@/views/reports/utils/calculate";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportPerformanceBands,
    ReportTable
  },
  data() {
    return {
      compatibleReportId: 37,
      reportFilters: ["shifts", "imsJobRoles", "locations", "timeframe"],
      hiddenReportActions: ["saveSlide"],
      cells: [],
      performanceBands: [],
      headers: [
        {
          text: i18n.t("reports.table.last-name"),
          value: "lastName",
          sortable: true
        },
        {
          text: i18n.t("reports.table.first-name"),
          value: "firstName",
          sortable: true
        },
        {
          text: i18n.t("reports.table.badge"),
          value: "badgeNumber",
          sortable: true
        },
        {
          text: i18n.t("reports.table.role"),
          value: "jobRole",
          sortable: true
        },
        {
          text: i18n.t("reports.table.total-dispenses"),
          value: "totalDispenses",
          sortable: true,
          display: "Locale"
        },
        {
          text: i18n.t("reports.table.total-opportunities"),
          value: "totalOpportunities",
          sortable: true,
          display: "Locale"
        },
        {
          text: i18n.t("reports.table.performance-rate-ins"),
          value: "insPerformance",
          sortable: true,
          display: "Percentage"
        },
        {
          text: i18n.t("reports.table.performance-rate-outs"),
          value: "outsPerformance",
          sortable: true,
          display: "Percentage"
        },
        {
          text: i18n.t("reports.table.performance"),
          value: "overallPerformance",
          sortable: true,
          display: "Percentage"
        }
      ],
      items: []
    };
  },
  methods: {
    createCsvData() {
      let headers = this.headers;
      headers = headers.filter((header) => header.value !== "indicator");
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    createPerformanceBands() {
      this.performanceBands = this.reportResponse.performanceBands;

      if (this.performanceBands.length) {
        const headers = this.headers;

        headers.push({
          text: i18n.t("reports.table.indicator"),
          value: "indicator",
          sortable: false,
          display: "Indicator"
        });

        this.headers = headers;
      }
    },
    createSummary() {
      const data = this.reportResponse.individualPerformanceDataPoints;

      const totalCompliant = sumEntries({
        entries: data,
        inputField: "compliantOpportunities"
      });
      const totalOpportunities = sumEntries({
        entries: data,
        inputField: "opportunities"
      });
      const totalPercentage = createPercentage({
        dividend: totalCompliant,
        divisor: totalOpportunities
      });

      this.cells = [
        {
          text: i18n.t("reports.compliant-opportunities"),
          value: totalCompliant,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total-opportunities"),
          value: totalOpportunities,
          display: "Locale"
        },
        {
          text: i18n.t("reports.overall-performance-rate"),
          value: totalPercentage,
          display: "Percentage"
        }
      ];
    },
    createTable() {
      let items = this.reportResponse.individualPerformanceDataPoints;

      items = group({
        items,
        key: "badgeNumber",
        commonFields: [
          "badgeNumber",
          "employeeId",
          "jobRole",
          "firstName",
          "lastName"
        ]
      });
      items = sortEventsByDate({ items });
      items = sumEvents({ items, inputField: "opportunities" });
      items = sumEvents({ items, inputField: "compliantOpportunities" });
      items = sumEvents({ items, inputField: "dispenses" });
      items = totalPercentage({ items });
      items = totalPercentage({
        items,
        dividendField: "compliantIns",
        divisorField: "ins",
        outputField: "insPerformance"
      });
      items = totalPercentage({
        items,
        dividendField: "compliantOuts",
        divisorField: "outs",
        outputField: "outsPerformance"
      });
      items = setEventsIndicator({
        items,
        performanceBands: this.performanceBands
      });

      this.items = items;
    },
    handleReportResponse() {
      this.createSummary();
      this.createPerformanceBands();
      this.createTable();
    }
  }
};
</script>
