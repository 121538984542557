<template>
  <div>
    <h1 v-if="!loading && !Object.keys(facility).length" class="tw-mb-5">
      {{ $t("admin.manage-facility.basic-info.add") }}
    </h1>
    <ValidationObserver ref="manageFacilityForm" v-slot="{ pristine }">
      <v-form @submit.prevent="validateAndSubmit">
        <h3>{{ $t("admin.manage-facility.settings.basic-info") }}</h3>
        <ValidationProvider
          :name="$t('admin.manage-facility.basic-info.name')"
          :rules="{ required: true, max: 50 }"
          v-slot="{ label, errors }"
        >
          <v-text-field
            v-model="name"
            :error-messages="errors"
            :label="label"
            :counter="50"
          />
        </ValidationProvider>
        <ValidationProvider
          :name="$t('admin.manage-facility.basic-info.region')"
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-autocomplete
            auto-select-first
            v-model="countryId"
            :error-messages="errors"
            :label="label"
            :items="
              countries.map((f) => ({
                text: f.name,
                value: f.id
              }))
            "
            :loading="countries.length === 0"
          />
        </ValidationProvider>
        <ValidationProvider
          :name="$t('admin.manage-facility.basic-info.timezone')"
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-autocomplete
            auto-select-first
            v-model="timeZone"
            :error-messages="errors"
            :label="label"
            :items="timeZones"
            :loading="timeZones.length === 0"
          />
        </ValidationProvider>
        <v-row>
          <v-col cols="12" lg="4">
            <ValidationProvider
              :name="$t('admin.manage-facility.basic-info.location-template')"
              :rules="{ required: true }"
              v-slot="{ label, errors }"
            >
              <v-autocomplete
                auto-select-first
                v-model="locationTemplateId"
                :error-messages="errors"
                :label="label"
                :items="
                  locationTemplates.map((l) => ({
                    text: $t(
                      `location-template.${l.name
                        .replace(/\s+/g, '-')
                        .toLowerCase()}`
                    ),
                    value: l.id
                  }))
                "
                :loading="locationTemplates.length === 0"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="4">
            <ValidationProvider
              :name="$t('admin.manage-facility.basic-info.hygiene-protocol')"
              :rules="{ required: true }"
              v-slot="{ label, errors }"
            >
              <v-autocomplete
                auto-select-first
                v-model="hygieneProtocolId"
                :error-messages="errors"
                :label="label"
                :items="
                  hygieneProtocols.map((h) => ({
                    text: $t(
                      `hygiene-protocol.${h.name
                        .replace(/\s+/g, '-')
                        .toLowerCase()}`
                    ),
                    value: h.id
                  }))
                "
                :loading="hygieneProtocols.length === 0"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="4">
            <ValidationProvider
              :name="$t('admin.manage-facility.basic-info.occupancy')"
              :rules="{ numeric: true }"
              v-slot="{ label, errors }"
            >
              <v-text-field
                v-model="occupancy"
                :error-messages="errors"
                :label="label"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <h3>{{ $t("admin.manage-facility.basic-info.address-info") }}</h3>
        <ValidationProvider
          :name="$t('admin.manage-facility.basic-info.address')"
          :rules="{ required: true, max: 100 }"
          v-slot="{ label, errors }"
        >
          <v-text-field
            v-model="address1"
            :error-messages="errors"
            :label="label"
            :counter="100"
          />
        </ValidationProvider>
        <ValidationProvider
          :name="$t('admin.manage-facility.basic-info.address2')"
          :rules="{ max: 100 }"
          v-slot="{ label, errors }"
        >
          <v-text-field
            v-model="address2"
            :error-messages="errors"
            :label="label"
            :counter="100"
          />
        </ValidationProvider>
        <ValidationProvider
          :name="$t('admin.manage-facility.basic-info.address3')"
          :rules="{ max: 100 }"
          v-slot="{ label, errors }"
        >
          <v-text-field
            v-model="address3"
            :error-messages="errors"
            :label="label"
            :counter="100"
          />
        </ValidationProvider>
        <v-row>
          <v-col cols="12" lg="4">
            <ValidationProvider
              :name="$t('admin.manage-facility.basic-info.city')"
              :rules="{ required: true, max: 50 }"
              v-slot="{ label, errors }"
            >
              <v-text-field
                v-model="city"
                :error-messages="errors"
                :label="label"
                :counter="50"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="4">
            <ValidationProvider
              :name="$t('admin.manage-facility.basic-info.state-province')"
              :rules="{ required: true, max: 100 }"
              v-slot="{ label, errors }"
            >
              <v-autocomplete
                auto-select-first
                v-if="states.length"
                v-model="stateOrProvinceId"
                :error-messages="errors"
                :label="label"
                :items="
                  states.map((f) => ({
                    text: f.name,
                    value: f.id
                  }))
                "
              />
              <v-text-field
                v-else
                v-model="stateName"
                :error-messages="errors"
                :label="$t('admin.manage-facility.basic-info.state-province')"
                :counter="100"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="4">
            <ValidationProvider
              :name="$t('admin.manage-facility.basic-info.postal-code')"
              :rules="{ required: true, max: 10 }"
              v-slot="{ label, errors }"
            >
              <v-text-field
                v-model="postalCode"
                :error-messages="errors"
                :label="label"
                :counter="10"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="tw-justify-end tw-mb-3">
          <v-btn
            class="tw-mr-5 tw-w-24"
            :disabled="Object.keys(facility).length !== 0 && pristine"
            color="primary"
            type="submit"
          >
            {{ $t("ui.actions.save") }}
          </v-btn>
          <v-btn
            v-if="$route.name === ROUTE_NAMES.editFacility"
            :disabled="pristine"
            class="tw-mr-5 tw-w-24"
            color="background2"
            @click="setSettings(facility)"
          >
            {{ $t("ui.actions.reset") }}
          </v-btn>
          <v-btn
            v-else
            class="tw-mr-5 tw-w-24"
            color="background2"
            :to="{
              name: ROUTE_NAMES.customerDashboard,
              query: { customerId }
            }"
          >
            {{ $t("ui.actions.cancel") }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-overlay :value="saving">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import { ROUTE_NAMES } from "@/types/routeNames";
import { PERMISSIONS } from "@/types/permissions";
import facilityService from "@/api/facilityService";
import countryService from "@/api/countryService";
import stateOrProvinceService from "@/api/stateOrProvinceService";
import timeZoneService from "@/api/timeZoneService";
import hygieneProtocolService from "@/api/hygieneProtocolService";
import locationTemplateService from "@/api/locationTemplateService";
import i18n from "@/plugins/i18n";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    customerId: { type: Number },
    facility: {
      type: Object,
      default: () => {
        return {};
      }
    },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    address1: "",
    address2: "",
    address3: "",
    city: "",
    countries: [],
    countryId: undefined,
    hygieneProtocolId: undefined,
    hygieneProtocols: [],
    locationTemplateId: undefined,
    locationTemplates: [],
    name: "",
    occupancy: "",
    postalCode: "",
    PERMISSIONS,
    reseting: false,
    ROUTE_NAMES,
    stateName: "",
    stateOrProvinceId: undefined,
    states: [],
    saving: false,
    timeZone: undefined,
    timeZones: []
  }),
  created() {
    this.loadCountries();
    this.loadHygieneProtocols();
    this.loadLocationTemplates();
    this.loadTimeZones();
  },
  methods: {
    async loadCountries() {
      this.countries = await countryService.list();

      if (Object.keys(this.facility).length === 0) {
        this.countryId = this.countries.length ? this.countries[0].id : null;
      }
    },
    async loadHygieneProtocols() {
      this.hygieneProtocols = await hygieneProtocolService.getList();
    },
    async loadLocationTemplates() {
      this.locationTemplates = await locationTemplateService.getList();
    },
    async loadStateOrProvince() {
      if (!this.loading && !this.reseting) {
        this.stateOrProvinceId = undefined;
        this.stateName = "";
      }

      this.reseting = false;
      this.states = await stateOrProvinceService.list(this.countryId);
    },
    async loadTimeZones() {
      this.timeZones = await timeZoneService.getList();

      if (Object.keys(this.facility).length === 0) {
        this.timeZone = "Eastern Standard Time";
      }
    },
    setSettings(facility) {
      if (this.$refs.manageFacilityForm) {
        this.$refs.manageFacilityForm.reset();
      }
      this.reseting = true;

      this.address1 = facility.address1;
      this.address2 = facility.address2;
      this.address3 = facility.address3;
      this.city = facility.city;
      this.countryId = facility.countryId;
      this.hygieneProtocolId = facility.hygieneProtocolId;
      this.locationTemplateId = facility.templateId;
      this.name = facility.name;
      this.occupancy = facility.occupancy;
      this.postalCode = facility.postalCode;
      this.stateName = facility.stateName;
      this.stateOrProvinceId = facility.stateId;
      this.timeZone = facility.timeZone;
    },
    async submit() {
      try {
        const facility = {
          address1: this.address1,
          address2: this.address2,
          address3: this.address3,
          city: this.city,
          countryId: this.countryId,
          customerId: this.facility?.customerId ?? this.customerId,
          hygieneProtocolId: this.hygieneProtocolId,
          name: this.name,
          occupancy: this.occupancy,
          postalCode: this.postalCode,
          stateId: this.stateOrProvinceId,
          stateName: this.stateName,
          templateId: this.locationTemplateId,
          timeZone: this.timeZone
        };

        if (this.facility.id) {
          await facilityService.updateBasicInfoSettings(
            this.facility.id,
            facility
          );

          this.$appInsights?.trackEvent({
            name: `Update Facility Form Success`
          });

          this.$emit("update:facility", { ...this.facility, ...facility });

          this.$store.commit(
            "application/SET_SUCCESS_MESSAGE",
            i18n.t("admin.manage-facility.success-messages.update")
          );

          this.$refs.manageFacilityForm.reset();
        } else {
          await facilityService.createFacility(facility);
          this.$appInsights?.trackEvent({
            name: `Create Facility Form Success`
          });

          this.$store.commit(
            "application/SET_SUCCESS_MESSAGE",
            i18n.t("admin.manage-facility.basic-info.create")
          );

          this.$router.push({
            name: ROUTE_NAMES.customerDashboard,
            query: { customerId: this.customerId }
          });
        }
      } catch (error) {
        if (this.facility.id) {
          this.$appInsights?.trackEvent({
            name: `Update Facility Server Error`
          });
        } else {
          this.$appInsights?.trackEvent({
            name: `Add Facility Server Error`
          });
        }
        this.$store.commit("application/SET_ERROR", {
          message:
            error === 409
              ? i18n.t(
                  "admin.manage-facility.basic-info.error-messages.duplicate-facility-name"
                )
              : i18n.t("admin.manage-facility.error-messages.save-facility")
        });
      } finally {
        this.saving = false;
      }
    },
    async validateAndSubmit() {
      const valid = await this.$refs.manageFacilityForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Facility Basic Info Form Error`
        });
        return;
      }

      this.saving = true;

      await this.submit();
    }
  },
  watch: {
    countryId(value) {
      this.loadStateOrProvince(value);
    },
    facility: {
      immediate: true,
      handler(value) {
        if (Object.keys(value).length) {
          this.setSettings(value);
        }
      }
    }
  }
};
</script>
