<template>
  <div>
    <report-summary :cells="cells" />
    <report-performance-bands :performanceBands="performanceBands" />
    <report-graph :chartData="chartData" />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportPerformanceBands from "@/views/reports/components/reportSections/ReportPerformanceBands.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";

import { sumEntries, createPercentage } from "@/views/reports/utils/calculate";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportPerformanceBands,
    ReportGraph
  },
  data() {
    return {
      compatibleReportId: 39,
      reportFilters: ["shifts", "imsJobRoles", "locations", "timeframe"],
      hiddenReportActions: ["saveSlide"],
      cells: [],
      performanceBands: [],
      showAnnotations: ["performanceBands", "baselineAndGoal"],
      items: [],
      chartData: {},
      headers: [
        {
          text: i18n.t("reports.date"),
          value: "reportDate",
          display: "Date"
        },
        {
          text: i18n.t("reports.compliant-opportunities"),
          value: "compliantOpportunities"
        },
        {
          text: i18n.t("reports.total-opportunities"),
          value: "opportunities"
        },
        {
          text: i18n.t("reports.perf-rate"),
          value: "performance",
          display: "Percentage"
        }
      ]
    };
  },
  methods: {
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    createSummary() {
      const data = this.reportResponse.groupPerformanceDataPoints;

      const totalCompliant = sumEntries({
        entries: data,
        inputField: "compliantOpportunities"
      });
      const totalOpportunities = sumEntries({
        entries: data,
        inputField: "opportunities"
      });
      const totalPercentage = createPercentage({
        dividend: totalCompliant,
        divisor: totalOpportunities
      });

      this.cells = [
        {
          text: i18n.t("reports.compliant-opportunities"),
          value: totalCompliant,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total-opportunities"),
          value: totalOpportunities,
          display: "Locale"
        },
        {
          text: i18n.t("reports.overall-performance-rate"),
          value: totalPercentage,
          display: "Percentage"
        }
      ];
    },
    createPerformanceBands() {
      this.performanceBands = this.reportResponse.performanceBands;
    },
    createChartData() {
      this.items = this.reportResponse.groupPerformanceDataPoints;

      const builder = new ChartBuilder({
        displayLegend: false,
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });
      builder
        .chartTimeSeries({
          type: "bar",
          items: this.items,
          valueField: "performance",
          valueFieldType: GraphDataTypes.ROUNDED,
          valueScaleOptions: {
            label: i18n.t("reports.graph.performance-axis"),
            position: "left",
            ticks: {
              format: "${value}%"
            }
          },
          dateTimeField: "reportDate",
          timeframe: this.$store.state["filters/timeframe"]
        })
        .valueScale.showPerformanceBands(this.performanceBands);
      this.chartData = builder.build();
    },
    handleReportResponse() {
      this.createSummary();
      this.createPerformanceBands();
      this.createChartData();
    }
  }
};
</script>
