<template>
  <v-skeleton-loader type="image" v-if="reportLoading" />
  <div
    v-else
    class="
      tw-flex tw-flex-col
      lg:tw-flex-row
      print:tw-flex-row
      tw-justify-center tw-items-stretch
    "
    ref="reportBlockRow"
  >
    <report-block
      v-for="(item, index) in items"
      :key="item.title + index"
      :bgColor="item.bgColor"
      :headerColor="item.headerColor"
      :thresholdColor="item.thresholdColor"
      :title="item.title"
      :body="item.body"
      :display="item.display"
      :isSlideshow="isSlideshow"
      :reportId="item.reportId"
    />
  </div>
</template>

<script>
import ReportBlock from "./ReportBlock.vue";
import { mapState } from "vuex";

export default {
  components: {
    ReportBlock
  },
  props: {
    items: {
      type: [Array],
      default: () => []
    }
  },
  computed: {
    ...mapState("reports", ["reportLoading", "isSlideshow"])
  }
};
</script>
