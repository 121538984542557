var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"small-headers facility-data-table",attrs:{"items":_vm.facilities,"headers":_vm.headers,"search":_vm.search,"loading":_vm.loading,"item-key":"facilityId","no-data-text":_vm.$t('admin.customer-dashboard.facility-widget.empty'),"loading-text":_vm.$t('admin.customer-dashboard.facility-widget.loading'),"height":_vm.fullscreen ? '55vh' : ''},on:{"update:page":_vm.setScrollPosition},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-sticky tw-top-0 tw-bg-white",staticStyle:{"z-index":"2"}},[_c('v-text-field',{staticClass:"tw-mr-4",attrs:{"label":`${_vm.$t('ui.input-select.search')}`,"clearable":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.checkPermission(_vm.PERMISSIONS.addFacilities))?_c('v-btn',{staticClass:"tw-max-w-xs",attrs:{"primary":"","color":"primary","to":{
              name: _vm.ROUTE_NAMES.createFacility,
              query: { customerId: _vm.customerId }
            }}},[_vm._v(" "+_vm._s(_vm.$t("admin.customer-dashboard.facility-widget.new-facility"))+" ")]):_vm._e()],1)]},proxy:true},{key:`item.facilityName`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: _vm.ROUTE_NAMES.facilityDashboard,
            query: { customerId: _vm.customerId, facilityId: item.facilityId }
          }}},[_c('span',[_vm._v(_vm._s(item.facilityName))])])]}},{key:`item.actions`,fn:function({ item }){return [_c('td',[_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.checkPermission(_vm.PERMISSIONS.editFacilities))?_c('v-btn',_vm._g(_vm._b({attrs:{"to":{
                    name: _vm.ROUTE_NAMES.editFacility,
                    params: { facilityId: item.facilityId }
                  },"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("admin.customer-dashboard.facility-widget.edit-facility"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":{
                    name: _vm.ROUTE_NAMES.deviceMaintenance,
                    query: { facilityId: item.facilityId }
                  }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-heart-pulse")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "admin.customer-dashboard.facility-widget.device-maintenance" ))+" ")])])],1)])]}},{key:`item.archived`,fn:function({ item }){return [_c('v-switch',{attrs:{"loading":item.isLoading,"true-value":false,"false-value":true,"disabled":!_vm.checkPermission(_vm.PERMISSIONS.archiveFacilities),"readonly":""},on:{"click":function($event){!_vm.checkPermission(_vm.PERMISSIONS.archiveFacilities)
              ? null
              : item.archived
              ? _vm.toggleArchiveStatus(item.facilityId)
              : _vm.setModal(item.facilityId)}},model:{value:(item.archived),callback:function ($$v) {_vm.$set(item, "archived", $$v)},expression:"item.archived"}})]}},{key:`footer.prepend`,fn:function(){return [(_vm.checkPermission(_vm.PERMISSIONS.administerCustomer))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.exporting},on:{"click":function($event){return _vm.exportLocations()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-export")])],1)]}}],null,false,3206363959)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "admin.customer-dashboard.facility-widget.export-facility-hierarchies" ))+" ")])]):_vm._e()]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"max-width":"560"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"modal"},[_c('v-container',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("admin.customer-dashboard.facility-widget.modal-title"))+" ")]),_c('v-card-text',[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.$t(`admin.customer-dashboard.facility-widget.modal-body`, {
                  facilityName: _vm.facilityName
                })
              )}})]),_c('div',{staticClass:"tw-m-4"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.textMatches ? _vm.toggleArchiveStatus(_vm.facilityId) : null}}},[_c('v-text-field',{attrs:{"label":`${_vm.$t(
                  'admin.customer-dashboard.facility-widget.please-type-yes'
                )}`},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"tw-flex tw-flex-row tw-justify-end"},[_c('v-btn',{staticClass:"tw-mr-5",attrs:{"text":""},on:{"click":function($event){return _vm.resetArchive(_vm.facilityId)}}},[_vm._v(" "+_vm._s(_vm.$t("ui.actions.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"error","disabled":!_vm.textMatches,"loading":_vm.modalButtonLoading},on:{"click":function($event){return _vm.toggleArchiveStatus(_vm.facilityId)}}},[_vm._v(" "+_vm._s(_vm.$t("admin.customer-dashboard.facility-widget.confirm-button"))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }