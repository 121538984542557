<template>
  <div>
    <h3 class="tw-mb-5">
      {{ $t("admin.obv-job-role.create") }}
    </h3>
    <div>
      <ValidationObserver ref="createObvJobRoleForm">
        <v-form @submit.prevent="validateAndSubmit">
          <v-row dense>
            <v-col cols="12" lg="4">
              <ValidationProvider
                vid="jobRoleTitle"
                :name="$t('admin.obv-job-role.title')"
                :rules="{ required: true, max: 50 }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="name"
                  :error-messages="errors"
                  :label="$t('admin.obv-job-role.title')"
                  :counter="50"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row dense class="tw-mb-3">
            <v-col cols="12" lg="6">
              <v-btn
                class="tw-mr-5 tw-w-24"
                :loading="loading"
                color="primary"
                type="submit"
              >
                {{ $t("ui.actions.save") }}
              </v-btn>
              <v-btn
                class="tw-mr-5 tw-w-24"
                color="background2"
                @click="closeCreateJobRoleView"
              >
                {{ $t("ui.actions.cancel") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import obvJobRoleService from "@/api/obvJobRoleService";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    customerId: { type: Number },
    facilityId: { type: Number },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      loading: false,
      name: ""
    };
  },
  methods: {
    async validateAndSubmit() {
      const valid = await this.$refs.createObvJobRoleForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Create IMS Job Role Form Error`
        });
        return;
      }

      this.loading = true;

      try {
        await obvJobRoleService.saveJobRole({
          facilityId: this.facilityId,
          name: this.name
        });
        this.$appInsights?.trackEvent({
          name: `Update OBV Job Role Form Success`
        });
      } catch (error) {
        const duplicateNameError = error === 409;

        if (duplicateNameError) {
          this.$refs.createObvJobRoleForm.setErrors({
            jobRoleTitle: [
              i18n.t("admin.obv-job-role.error-messages.duplicate")
            ]
          });

          this.$appInsights?.trackEvent({
            name: `Update OBV Job Role Name Conflict Error`
          });
        } else {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("admin.obv-job-role.error-messages.save")
          });

          this.$appInsights?.trackEvent({
            name: `Update OBV Job Role Name Server Error`
          });
        }
        return;
      } finally {
        this.loading = false;
      }

      this.setLocalizedWidgetMessage({
        message: i18n.t("admin.obv-job-role.success-messages.create")
      });

      this.closeCreateJobRoleView();
    },
    closeCreateJobRoleView() {
      this.$emit("close-create-job-role-view");
    }
  }
};
</script>
