var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-w-full tw-px-4 tw-py-2",attrs:{"data-name":"custom-timeframe-selection"}},[_c('div',{staticClass:"tw-my-4 tw-w-full"},[_c('v-datetime-picker',{attrs:{"label":_vm.$t('reports.report-start'),"timePickerProps":{
          allowedMinutes: [0, 30]
        },"datePickerProps":{
          min: _vm.minDate
        },"textFieldProps":{
          filled: true,
          'hide-details': true,
          'append-icon': 'mdi-calendar'
        },"datetime":_vm.start},on:{"input":(e) => _vm.onInput({ start: e })},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v(" mdi-calendar ")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v(" mdi-clock ")])]},proxy:true}])})],1),_c('div',{staticClass:"tw-my-4 tw-w-full"},[_c('v-datetime-picker',{attrs:{"label":_vm.$t('reports.report-end'),"timePickerProps":{
          allowedMinutes: [0, 30]
        },"textFieldProps":{
          filled: true,
          'hide-details': true,
          'append-icon': 'mdi-calendar'
        },"datetime":_vm.end},on:{"input":(e) => _vm.onInput({ end: e })},scopedSlots:_vm._u([{key:"dateIcon",fn:function(){return [_c('v-icon',[_vm._v(" mdi-calendar ")])]},proxy:true},{key:"timeIcon",fn:function(){return [_c('v-icon',[_vm._v(" mdi-clock ")])]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }