import { post } from "./apiClient";
import i18n from "@/plugins/i18n";

export default {
  async get({ reportId, filterViewModel }) {
    if (!reportId) {
      throw Error(i18n.t("reports.errors.reportid-missing"));
    }

    return await post(
      `report/${reportId}`,
      filterViewModel,
      null,
      "reportService"
    );
  }
};
