import { get, put, post } from "@/api/apiClient";

export default {
  async list(requestModel) {
    if (!requestModel?.privilege) {
      throw new Error("Privilege is required");
    }

    return await post("user/customers", requestModel);
  },
  async get({ customerid } = {}) {
    if (!customerid) {
      throw new Error("Customer ID is required");
    }

    return await get(`customer/${customerid}`);
  },
  async getAdminCustomerList() {
    return await get(`customer/list`);
  },
  async getCustomerLogos() {
    return await get(`customer/logos`);
  },
  async getCustomerLogo(customerId) {
    return await get(`customer/${customerId}/logo`);
  },
  async getListOfFacilities(id) {
    return await get(`customer/${id}/facilities`);
  },
  async getActiveLocationHierarchies(id) {
    return await get(`customer/${id}/locationHierarchies`);
  },
  async postCustomerLogo(customerId, file) {
    const data = new FormData();

    data.append("logo", file);

    return await post(`customer/${customerId}/logo`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  async saveCustomer(customer) {
    if (customer.id) {
      return await put(`customer/${customer.id}`, customer);
    } else {
      return await post(`customer`, customer);
    }
  },
  async setCustomerActiveStatus(id, status) {
    return await put(`customer/${id}/ActiveStatus/?status=${status}`);
  }
};
