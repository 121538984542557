import { get, post, put } from "./apiClient";
import { filter } from "lodash";

export default {
  async getJobRolesByCustomerId(customerId) {
    return await get(`ImsJobRole?customerId=${customerId}`);
  },
  async getActiveJobRolesByCustomerId(customerId) {
    return filter(
      await this.getJobRolesByCustomerId(customerId),
      (c) => c.isActive
    );
  },
  async setActiveStatus(jobRole) {
    return await put(`ImsJobRole/ActiveStatus`, jobRole);
  },
  async saveJobRole(jobRole) {
    return await post(`ImsJobRole`, jobRole);
  },
  async getJobRoleTypes(customerId) {
    return await get(`ImsJobRole/Types?customerId=${customerId}`);
  }
};
