import { get, post, put } from "./apiClient";

export default {
  async getByFacilityId(facilityId) {
    return await get(`facility/${facilityId}/licenses`);
  },
  async getByCustomerId(customerId) {
    return await get(`customer/${customerId}/licenses`);
  },
  async create(facilityId, license) {
    return await post(`facility/${facilityId}/licenses`, license);
  },
  async edit(license) {
    return await put(`licenses/${license.id}`, license);
  }
};
