<template>
  <div>
    <report-table
      :headers="reportHeaders"
      :items="items"
      :initialSortedColumns="['clearedTimestampLocal']"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import reportMixin from "@/views/reports/mixins/reportMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import { alertTypes } from "@/views/reports/SA/alertTypes.js";

export default {
  mixins: [reportMixin],
  components: {
    ReportTable
  },
  data() {
    const commonHeaders = [
      {
        text: i18n.t("reports.sa.headers.timestamp"),
        value: "rawAlarmTimestampLocal",
        displayText: "alarmTimestampLocal",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.cleared"),
        value: "rawClearedTimestampLocal",
        displayText: "clearedTimestampLocal",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.floor"),
        value: "floor",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.unit"),
        value: "unit",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.room"),
        value: "room",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-name"),
        value: "deviceName",
        sortable: true,
        class: "tw-align-text-top"
      }
    ];
    return {
      compatibleReportId: 32,
      reportHeaders: [
        ...commonHeaders,
        {
          text: i18n.t("reports.sa.headers.alert-type"),
          value: "icon",
          sortable: true,
          filterable: false,
          class: "tw-align-text-top",
          display: "TableImage"
        }
      ],
      csvHeaders: [
        ...commonHeaders,
        {
          text: i18n.t("reports.sa.headers.alert-type"),
          value: "alarmTypeName",
          sortable: false,
          class: "tw-align-text-top",
          display: "TableImage"
        }
      ],
      items: [],
      reportFilters: ["locations", "timeframe"],
      hiddenReportActions: ["rawExport", "saveSlide"]
    };
  },
  created() {
    this.csvHeaders.splice(2, 0, {
      text: i18n.t("reports.sa.headers.age"),
      value: "alarmAgeFormatted",
      sortable: true,
      class: "tw-align-text-top"
    });
    this.reportHeaders.splice(2, 0, {
      text: i18n.t("reports.sa.headers.age"),
      value: "alarmAgeHours",
      sortable: true,
      class: "tw-align-text-top",
      displayText: "alarmAgeFormatted"
    });
  },
  methods: {
    createCsvData() {
      const headers = this.csvHeaders;
      const headersText = headers.map((header) => header.text);

      const data = this.items.map((item) => {
        return [
          this.formatTimestamp(item.rawAlarmTimestampLocal), // Ensure timestamps are formatted here
          this.formatTimestamp(item.rawClearedTimestampLocal), // Ensure timestamps are formatted here
          item.alarmAgeFormatted,
          item.floor,
          item.unit,
          item.room,
          item.deviceName,
          item.alarmTypeName
        ];
      });

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      const items = this.reportResponse.dataPoints;
      this.items = items.map((item) => {
        const alarmAge = item.alarmAge.split(".");
        return {
          alarmTimestampLocal: this.formatTimestamp(item.alarmTimestampLocal),
          clearedTimestampLocal: this.formatTimestamp(
            item.clearedTimestampLocal
          ),
          rawAlarmTimestampLocal: new Date(item.alarmTimestampLocal),
          rawClearedTimestampLocal: new Date(item.clearedTimestampLocal),
          alarmAge: item.alarmAge,
          alarmAgeHours: parseInt(alarmAge[0]) * 24 + parseInt(alarmAge[1]),
          alarmAgeFormatted: item.alarmAgeFormatted,
          floor: item.floor,
          unit: item.unit,
          room: item.room,
          deviceName: item.deviceName,
          deviceAlarmTypeId: item.deviceAlarmTypeId,
          alarmTypeName: item.alarmTypeName,
          icon: alertTypes[item.deviceAlarmTypeId].icon,
          translation: alertTypes[item.deviceAlarmTypeId].translation
        };
      });
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        //ignoring timezone information because local time from backend
        return new Date(`${timestamp}`).toLocaleDateString("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "2-digit",
          hourCycle: "h12"
        });
      }
      return "";
    }
  }
};
</script>
