let windowLocation = 0;

export function lockScreen() {
  windowLocation = window.pageYOffset;

  const topPx = windowLocation ? `-${windowLocation}px` : 0;
  document.getElementById("app").style.setProperty("top", topPx);
  document.getElementById("app").style.setProperty("position", "relative");
  document.body.classList.add("lock-screen");
}

export function unlockScreen() {
  document.getElementById("app").style.removeProperty("top");
  document.getElementById("app").style.removeProperty("position");
  document.body.classList.remove("lock-screen");
  window.scrollTo(0, windowLocation);
  windowLocation = 0;
}
