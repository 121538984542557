<template>
  <v-list class="tw-overflow-x-hidden">
    <v-subheader v-if="title">
      {{ title }}
    </v-subheader>
    <v-list-item
      v-if="searchable && items.length > searchItemMinimum"
      class="tw-mb-0"
    >
      <v-list-item-content>
        <v-text-field
          :label="$t('ui.input-select.search')"
          filled
          hide-details
          dense
          v-model="search"
          append-icon="mdi-magnify"
          ref="searchBox"
        />
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      ripple
      @click="onToggle"
      v-if="multiple"
      data-name="select-all"
    >
      <v-list-item-action>
        <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">
          {{ icon }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Select All</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="tw-mt-2" v-if="multiple" />

    <component
      v-bind:is="multiple ? 'v-list-item-group' : 'div'"
      :multiple="multiple"
      :value="value"
      @change="onChange"
      class="tw-my-3"
    >
      <template v-for="item in searchResults">
        <v-list-item
          v-if="item !== undefined && (!enabledKey || item[enabledKey])"
          :value="item.id"
          :key="item.id"
          @click="() => onChange(item)"
          data-name="list-entry"
        >
          <template v-slot:default="{ active, toggle }">
            <v-list-item-action v-if="multiple">
              <v-checkbox
                :input-value="active"
                :true-value="item.id"
                color="primary"
                @click="toggle"
              />
            </v-list-item-action>

            <slot name="item">
              <v-list-item-content>
                <v-list-item-title>{{ item[itemTitleKey] }}</v-list-item-title>
                <v-list-item-subtitle v-if="item[itemSubtitleKey]">
                  {{ item[itemSubtitleKey] }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon v-if="!multiple">
                <v-icon
                  v-if="!multiple && !menu && item.id === value"
                  color="primary"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon v-else-if="menu"> mdi-chevron-right </v-icon>
              </v-list-item-icon>
            </slot>
          </template>
        </v-list-item>
      </template>
    </component>
    <v-divider v-if="multiple" />
    <v-list-item v-if="multiple">
      <v-list-item-content class="tw-relative">
        <v-btn text @click="$emit('close')" right absolute color="primary">
          Close
        </v-btn>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
const ALL_SELECTED_ICON = "mdi-close-box";
const SOME_SELECTED_ICON = "mdi-minus-box";
const NONE_SELECTED_ICON = "mdi-checkbox-blank-outline";

export default {
  data: () => ({
    search: ""
  }),
  props: {
    value: [Array, String, Number],
    multiple: Boolean,
    items: {
      type: Array,
      default: () => []
    },
    title: String,
    itemTitleKey: {
      type: String,
      default: "title"
    },
    itemSubtitleKey: {
      type: String,
      default: "subtitle"
    },
    "enabled-key": String,
    searchable: Boolean,
    visible: Boolean,
    menu: Boolean,
    searchItemMinimum: {
      type: Number,
      default: 3
    }
  },
  mounted() {
    this.focusSearch();
  },
  watch: {
    visible(val) {
      if (val && this.searchable) {
        this.focusSearch();
      }
    }
  },
  computed: {
    allSelected() {
      return this.value.length === this.items.length;
    },
    someSelected() {
      return this.value.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) {
        return ALL_SELECTED_ICON;
      }
      if (this.someSelected) {
        return SOME_SELECTED_ICON;
      }
      return NONE_SELECTED_ICON;
    },
    searchResults() {
      if (this.search.trim() !== "") {
        return this.items.filter(
          (item) =>
            item[this.itemTitleKey]
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
        );
      } else {
        return this.items;
      }
    }
  },
  methods: {
    onChange(e) {
      this.$emit("input", e?.id);
      if (!this.multiple) {
        this.$emit("close");
      }
    },
    onToggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.$emit("input", []);
        } else {
          this.$emit(
            "input",
            this.items.map((i) => i.id)
          );
        }
      });
    },
    focusSearch() {
      if (this.searchable && this.items.length > this.searchItemMinimum) {
        this.$nextTick(() => this.$refs.searchBox.focus());
      }
    }
  }
};
</script>

<style scoped>
.v-btn {
  padding: 0 !important;
}
</style>
