export function convertTimeSpanToDate(timeSpan) {
  const regex =
    /^([0-9]{1}|(?:0[0-9]|1[0-9]|2[0-3])+):([0-5]?[0-9])(?::([0-5]?[0-9])(?:\.(\d{1,9}))?)?$/;

  if (!regex.test(timeSpan)) {
    console.error("The provided timeSpan is not in a valid format.");
    return;
  }

  const splitTime = timeSpan.match(regex);

  return new Date(
    new Date().setHours(
      parseInt(splitTime[1]) || 0,
      parseInt(splitTime[2]) || 0,
      parseInt(splitTime[3]) || 0,
      parseInt(splitTime[4]) || 0
    )
  );
}

export function convertDateToTimeString(date, language) {
  const isValidDate = date instanceof Date;
  if (!isValidDate) {
    console.error("A valid JS Date object must be provided.");
    return;
  }

  if (language !== "en-US" && language !== "fr-CA") {
    console.error("A valid language must be provided.");
    return;
  }

  return date.toLocaleTimeString(language, {
    hour: "2-digit",
    minute: "2-digit"
  });
}

export function convertDateForExport(date, language) {
  if (!language || !date) {
    return "";
  }

  return new Date(date)
    .toLocaleDateString(language, {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hourCycle: "h23"
    })
    .replace(",", "");
}
