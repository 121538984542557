import { get, post, put } from "@/api/apiClient";

export default {
  async list(requestModel) {
    if (!requestModel?.customerId) {
      throw new Error("Customer ID is required");
    }

    if (!requestModel?.products) {
      throw new Error("Products are required");
    }

    if (!requestModel?.privilege) {
      throw new Error("Privilege is required");
    }

    return await post("user/facilities", requestModel);
  },
  async get({ facilityId } = {}) {
    if (!facilityId) {
      throw new Error("Facility ID is required");
    }

    return await get(`facility/${facilityId}`);
  },
  async createFacility(facility) {
    return await post(`facility/create`, facility);
  },
  async getObservationSettings(facilityId) {
    return await get(`facility/${facilityId}/observationSettings`);
  },
  async getSettings(id) {
    return await get(`facility/${id}/settings`);
  },
  async setArchiveStatus(id, status) {
    await put(`facility/${id}/archivestatus`, status);
  },
  async updateBasicInfoSettings(facilityId, settingsUpdates) {
    return await put(
      `facility/${facilityId}/settings/basicInfo`,
      settingsUpdates
    );
  },
  async updateObservationSettings(facilityId, settingsUpdates) {
    return await put(
      `facility/${facilityId}/settings/observation`,
      settingsUpdates
    );
  },
  async cloneObservationNotes(facilityId, request) {
    return await post(
      `facility/${facilityId}/settings/cloneObservationNotes`,
      request
    );
  },
  async updateReportSettings(facilityId, settingsUpdates) {
    await put(`facility/${facilityId}/settings/report`, settingsUpdates);
  },
  async updateServiceAlertSettings(facilityId, settingsUpdates) {
    return await put(
      `facility/${facilityId}/settings/serviceAlerts`,
      settingsUpdates
    );
  }
};
