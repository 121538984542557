import { TIMEFRAMES } from "@/types/timeframes";
import { FILTERS } from "@/types/filters";
import i18n from "@/plugins/i18n";

export default {
  computed: {
    filterDisabledStates() {
      return {
        [FILTERS.SHIFTS]: {
          disabled: this.areShiftsDisabled,
          disabledText: i18n.t("filters.messages.24-hour-shift-filter-limited")
        }
      };
    },
    areShiftsDisabled() {
      const timeframeFilterState = this.activeFiltersState?.timeframe || {};
      if (
        timeframeFilterState.activeIds === TIMEFRAMES.LAST_6_MONTHS ||
        timeframeFilterState.activeIds === TIMEFRAMES.LAST_12_MONTHS
      ) {
        return true;
      }

      if (timeframeFilterState.activeIds === TIMEFRAMES.CUSTOM) {
        const ninetyDaysInMilliseconds = 90 * 60 * 60 * 24 * 1000;
        if (
          timeframeFilterState.end - timeframeFilterState.start >
          ninetyDaysInMilliseconds
        ) {
          return true;
        }
      }

      return false;
    }
  }
};
