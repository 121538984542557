<template>
  <div>
    <ValidationObserver ref="manageCustomerForm" v-slot="{ pristine }">
      <v-form @submit.prevent="submit">
        <h3>
          {{ $t("admin.manage-facility.service-alert-settings.refill-alerts") }}
        </h3>
        <p>
          {{
            $t(
              "admin.manage-facility.service-alert-settings.refill-remaining-threshold"
            )
          }}:
        </p>
        <v-col class="ma-0 pa-0 tw-flex-row" cols="2">
          <ValidationProvider>
            <v-select
              v-model="refillAlarmThreshold"
              :items="refillThresholdPcts"
            />
          </ValidationProvider>
        </v-col>
        <v-row class="tw-justify-end">
          <v-btn
            class="tw-mr-5 tw-w-32"
            :disabled="pristine"
            color="primary"
            type="submit"
          >
            {{ $t("ui.actions.save") }}
          </v-btn>
          <v-btn
            class="tw-mr-5 tw-w-32"
            :disabled="pristine"
            color="background2"
            @click="setSettings()"
          >
            {{ $t("ui.actions.reset") }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import facilityService from "@/api/facilityService";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";

export default {
  props: {
    facility: {
      type: Object,
      default: () => {
        return {};
      }
    },
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      PERMISSIONS,
      refillAlarmThreshold: this.facility.refillAlarmThreshold,
      refillThresholdPcts: []
    };
  },
  created() {
    this.setRefillThresholdPcts();
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"])
  },
  methods: {
    setRefillThresholdPcts() {
      for (let i = 0; i <= 10; i++) {
        this.refillThresholdPcts.push({ text: `${i}%`, value: i });
      }
    },
    setSettings() {
      this.refillAlarmThreshold = this.facility.refillAlarmThreshold;
    },
    async submit() {
      this.$emit("update:loading", true);
      try {
        const facilityUpdates = {
          refillAlarmThreshold: this.refillAlarmThreshold
        };

        await facilityService.updateServiceAlertSettings(
          this.facility.id,
          facilityUpdates
        );

        this.$appInsights?.trackEvent({
          name: `Service Alert Settings Form Success`
        });

        this.$emit("update:facility", { ...this.facility, ...facilityUpdates });

        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t("admin.manage-facility.success-messages.update")
        );
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Service Alert Settings Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.manage-facility.error-messages.save-facility")
        });
      } finally {
        this.$emit("update:loading", false);
      }
    }
  },
  watch: {
    facility: {
      immediate: true,
      handler(value) {
        if (Object.keys(value).length) {
          this.setSettings(value);
        }
      }
    }
  }
};
</script>
