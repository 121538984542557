<template>
  <default-layout>
    <v-container>
      <v-card>
        <section class="tw-px-4 sm:tw-px-8 tw-inline-block tw-w-full tw-py-10">
          <v-snackbar
            @input="results.display = false"
            :value="results.display"
            :color="results.color"
            :top="true"
            :timeout="5000"
            class="tw-p-12"
          >
            {{ results.content }}
          </v-snackbar>
          <h1 class="tw-mb-5">{{ $t("navigation.manage-account") }}</h1>
          <h3>{{ $t("account.manage.basic-info") }}</h3>
          <ValidationObserver ref="manageAccountForm" v-slot="{ pristine }">
            <v-form @submit.prevent="validateAndSubmit">
              <v-row>
                <v-col cols="12" lg="4">
                  <ValidationProvider
                    :name="$t('account.manage.first-name')"
                    :rules="{ required: true, max: 50 }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="firstNameModel"
                      :error-messages="errors"
                      :label="$t('account.manage.first-name')"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="4">
                  <ValidationProvider
                    :name="$t('account.manage.last-name')"
                    :rules="{ required: true, max: 50 }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="lastNameModel"
                      :error-messages="errors"
                      :label="$t('account.manage.last-name')"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <h3>
                {{ $t("account.manage.change-password") }} ({{
                  $t("ui.optional")
                }})
              </h3>
              <v-row>
                <v-col cols="12" lg="8">
                  <ValidationProvider
                    vid="currentPassword"
                    :name="$t('account.manage.current-password')"
                    skipIfEmpty
                    :rules="{ required_if: 'newPassword' }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="currentPassword"
                      :error-messages="errors"
                      :type="showCurrentPassword ? 'text' : 'password'"
                      :append-icon="
                        showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="showCurrentPassword = !showCurrentPassword"
                      :label="$t('account.manage.current-password')"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    vid="newPassword"
                    :name="$t('account.manage.new-password')"
                    skipIfEmpty
                    :rules="{ required_if: 'currentPassword', password: true }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="newPassword"
                      :error-messages="errors"
                      :type="showNewPassword ? 'text' : 'password'"
                      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showNewPassword = !showNewPassword"
                      :label="$t('account.manage.new-password')"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    :name="$t('account.manage.confirm-password')"
                    skipIfEmpty
                    :rules="{
                      required_if: 'newPassword',
                      confirmed: 'newPassword'
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="confirmPassword"
                      :error-messages="errors"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      :label="$t('account.manage.confirm-password')"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="4" class="tw-pl-5">
                  <password-checker
                    class="tw-mb-5 md:tw-mb-0"
                    :password="newPassword"
                  />
                </v-col>
              </v-row>
              <v-btn
                class="tw-w-24"
                :disabled="pristine"
                :loading="loading"
                color="primary"
                type="submit"
              >
                {{ $t("ui.actions.save") }}
              </v-btn>
            </v-form>
          </ValidationObserver>
        </section>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
import PasswordChecker from "@/components/layout/PasswordChecker.vue";
import accountService from "@/api/accountService";
import i18n from "@/plugins/i18n";
import { mapGetters, mapState } from "vuex";
import { scrollToFirstError } from "@/utils/scrollToFirstError";
import DefaultLayout from "@/layouts/Default";

export default {
  components: {
    PasswordChecker,
    DefaultLayout
  },
  data: () => ({
    results: {
      display: false,
      content: "",
      color: ""
    },
    loading: false,
    firstNameModel: "",
    lastNameModel: "",
    currentPassword: "",
    showCurrentPassword: false,
    newPassword: "",
    showNewPassword: false,
    confirmPassword: "",
    showConfirmPassword: false
  }),
  computed: {
    ...mapState("user", ["firstName", "lastName"]),
    ...mapGetters("user/permissions", ["checkPermission"])
  },
  mounted() {
    this.firstNameModel = this.firstName;
    this.lastNameModel = this.lastName;
  },
  methods: {
    async validateAndSubmit() {
      const valid = await this.$refs.manageAccountForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Manage Account Form Error`
        });
        return;
      }

      this.sendManageAccountForm();
      this.$refs.manageAccountForm.reset();
    },
    async sendManageAccountForm() {
      this.loading = true;

      try {
        const result = await accountService.manageAccount({
          firstName: this.firstNameModel,
          lastName: this.lastNameModel,
          currentPassword: this.currentPassword || null,
          newPassword: this.newPassword || null,
          confirmPassword: this.confirmPassword || null
        });
        this.handleResult(result);
      } catch {
        this.handleResult({ invalidForm: true });
      }
    },
    handleResult(result) {
      this.loading = false;
      this.results.display = true;

      // Error States
      this.results.color = "error";

      if (result.currentPasswordIncorrect) {
        this.results.content = i18n.t(
          "account.manage.results.current-password-incorrect"
        );

        this.$refs.manageAccountForm.setErrors({
          currentPassword: [
            i18n.t("account.manage.results.current-password-incorrect")
          ]
        });
        return;
      }

      if (
        result.invalidForm ||
        (result.attemptedPasswordUpdate &&
          !result.passwordSuccessfullyUpdated) ||
        (result.attemptedAccountUpdate && !result.accountSuccessfullyUpdated)
      ) {
        this.results.content = i18n.t(
          "account.manage.results.something-went-wrong"
        );
        return;
      }

      // Success states
      this.results.color = "primary";
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";

      if (
        result.passwordSuccessfullyUpdated &&
        !result.attemptedAccountUpdate
      ) {
        this.results.content = i18n.t(
          "account.manage.results.password-updated"
        );
        return;
      }

      if (
        result.accountSuccessfullyUpdated &&
        !result.attemptedPasswordUpdate
      ) {
        this.results.content = i18n.t("account.manage.results.info-updated");
        return;
      }

      this.results.content = i18n.t("account.manage.results.account-updated");
    }
  }
};
</script>
