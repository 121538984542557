var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('facility-selection-bar',{staticClass:"tw-my-8"}),_c('header',[_c('h1',[_vm._v(_vm._s(_vm.$t("slideshows.manage-slideshows")))])]),_c('v-data-table',{attrs:{"items":_vm.slideshows,"headers":_vm.headers,"search":_vm.search,"sort-by":_vm.sortedColumns,"loading":_vm.loading,"loading-text":_vm.$t('slideshows.gathering-slideshow-data'),"no-data-text":_vm.$t('slideshows.no-slideshows-in-facility')},on:{"update:sortBy":function($event){_vm.sortedColumns=$event},"update:sort-by":[function($event){_vm.sortedColumns=$event},function($event){return _vm.trackHeaderAnalytics()}]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-sticky tw-top-0 tw-bg-white",staticStyle:{"z-index":"2"}},[_c('v-text-field',{staticClass:"tw-mr-4",attrs:{"label":`${_vm.$t('ui.input-select.search')}`,"clearable":"","prepend-inner-icon":"mdi-magnify"},on:{"focus":function($event){return _vm.trackSearchAnalytics()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"tw-max-w-xs",attrs:{"primary":"","color":"primary","to":{
              name: 'slideshowCreate',
              query: { facilityId: _vm.facilityId }
            }}},[_vm._v(" "+_vm._s(_vm.$t("slideshows.create-slideshow"))+" ")])],1)]},proxy:true},{key:"item.name",fn:function({ item }){return [(item.isActive)?_c('router-link',{attrs:{"target":"_blank","to":{
            name: 'slideshowView',
            query: { slideshowId: item.id, facilityId: _vm.facilityId }
          }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.isActive",fn:function({ item }){return [_c('v-switch',{attrs:{"loading":item.isLoading,"readonly":""},on:{"click":function($event){return _vm.toggleSlideshowStatus(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"tw-no-underline",attrs:{"to":{
            name: 'slideshowEdit',
            query: { slideshowId: item.id, facilityId: _vm.facilityId }
          }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},(_vm.checkPermission(_vm.PERMISSIONS.administerCustomer))?{key:"item.delete",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleDeleteDialog({ id: item.id, name: item.name })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}:null],null,true)}),_c('deleteDialog',{attrs:{"deleteDialogData":_vm.deleteDialogData},on:{"deletion-confirmed":_vm.deleteSlideshow,"toggle-delete-dialog":_vm.toggleDeleteDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }