<template>
  <v-dialog :value="value" @input="(e) => $emit('input', e)" max-width="500">
    <v-card>
      <v-card-title>
        {{ reportActionsDialogContent.dialogTitle }}
      </v-card-title>
      <v-card-text>
        <ValidationObserver ref="form">
          <v-form @submit.prevent="onConfirm">
            <ValidationProvider
              vid="dialogInput"
              :name="reportActionsDialogContent.dialogTextLabel"
              :rules="reportActionsDialogContent.inputRules"
              v-slot="{ errors }"
            >
              <v-text-field
                :label="reportActionsDialogContent.dialogTextLabel"
                :hint="reportActionsDialogContent.inputHint"
                :counter="reportActionsDialogContent.inputCounter"
                :error-messages="errors"
                v-model="title"
              />
            </ValidationProvider>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="loading" color="purellGray" text @click="onCancel">
          {{ $t("ui.actions.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          :loading="loading"
          @click="onConfirm"
        >
          {{ reportActionsDialogContent.dialogAction }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean
    },
    value: {
      type: Boolean
    },
    reportTitle: {
      type: String,
      default: ""
    },
    reportActionsDialogContent: {
      type: Object,
      default: () => ({})
    },
    serverErrorMessage: {
      type: Array
    }
  },
  data() {
    return {
      valid: true,
      title: "",
      dialogTitle: "",
      dialogAction: "",
      dialogTextLabel: ""
    };
  },
  watch: {
    reportTitle: {
      immediate: true,
      handler(value) {
        if (!this.title || this.title !== value) {
          this.title = value;
        }
      }
    },
    serverErrorMessage(value) {
      this.$refs.form.setErrors({
        dialogInput: value
      });
    }
  },
  methods: {
    async onConfirm() {
      const valid = await this.$refs.form.validate();

      if (valid) {
        this.$emit("close-report-actions-dialog", this.title);
      }
    },
    async onCancel() {
      this.$emit("cancel");
    }
  }
};
</script>
