<template>
  <v-list-item-group
    class="tw-my-3 tw-overflow-y-scroll"
    mandatory
    :value="value"
  >
    <v-list-item
      v-for="item in searchResult"
      :key="item.id"
      :value="item.id"
      @click="onClick(item.id)"
    >
      <template v-slot:default>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
          <v-list-item-subtitle v-if="item.subtext">
            {{ item.subtext }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon v-if="value === item.id" color="primary">
            mdi-check-circle
          </v-icon>
        </v-list-item-icon>
      </template>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
import filtersPopupItemsMixin from "../mixins/filtersPopupItemsMixin";

export default {
  mixins: [filtersPopupItemsMixin],

  methods: {
    onClick(id) {
      this.onSet(id);
      this.$emit("singleSelectionChanged", id);
    }
  }
};
</script>
