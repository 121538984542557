import { dateCompare, stringCompare } from "@/utils/comparers";

export const sortEventsByDate = ({
  items = [],
  sortField = "startDateTime",
  eventsField = "entries"
} = {}) =>
  items.map((item) => ({
    entries: item[eventsField].sort((a, b) =>
      dateCompare(a[sortField], b[sortField])
    ),
    ...item
  }));

export const stringSort = ({ items = [], sortFields = [] } = {}) => {
  return items.sort((a, b) => {
    const result = 0;
    for (const sortField of sortFields) {
      const result = stringCompare(a[sortField], b[sortField]);
      if (result !== 0) {
        return result;
      }
    }
    return result;
  });
};
