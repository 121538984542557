export const group = ({ items = [], key = "", commonFields = [] } = {}) => {
  if (!key) {
    throw new Error("Key must be provided");
  }

  const uniqueKeys = Array.from(new Set(items.map((i) => i[key])));

  return uniqueKeys.map((uniqueKey) => {
    const singleEntry = items.find((i) => i[key] === uniqueKey);

    const group = commonFields.reduce(
      (arr, i) => {
        arr[i] = singleEntry[i];
        return arr;
      },
      { entries: [] }
    );

    group.entries = items.filter((i) => i[key] === uniqueKey);

    return group;
  });
};

export const variableColumnGenerator = ({
  items = [],
  staticColumns = []
} = {}) => {
  const newItems = items.map((item) => {
    let formattedData = {};

    staticColumns.forEach((column) => {
      formattedData = {
        ...formattedData,
        ...{
          [column]: item[column]
        }
      };
    });

    item.intervalRecords.forEach((record, recordIndex) => {
      const dataKeys = Object.keys(record);

      dataKeys.forEach((key) => {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);

        formattedData = {
          ...formattedData,
          ...{
            [`column${recordIndex}${capitalizedKey}`]: record[key]
          }
        };
      });
    });

    return formattedData;
  });

  return newItems;
};
