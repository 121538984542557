<template>
  <div>
    <ValidationObserver
      v-if="facility.licenses.length"
      ref="manageReportSettingsForm"
      v-slot="{ pristine }"
    >
      <v-form @submit.prevent="validateAndSave">
        <h2>{{ $t("admin.manage-facility.settings.report-settings") }}</h2>
        <ValidationProvider v-if="hasObvLicense || hasAmsLicense">
          <p class="tw-mt-2">
            {{
              $t(
                "admin.manage-facility.report-settings.max-compliance-description"
              )
            }}
          </p>
          <v-checkbox
            v-model="maxCompliance"
            :label="$t('admin.manage-facility.report-settings.max-compliance')"
          />
        </ValidationProvider>
        <template v-if="hasAmsLicense">
          <v-divider class="tw-mb-4" />
          <h2>
            {{ $t("admin.manage-facility.report-settings.ams-settings") }}
          </h2>
          <p class="tw-mt-2">
            {{
              $t(
                "admin.manage-facility.report-settings.outbound-opportunities-description"
              )
            }}
          </p>
          <ValidationProvider>
            <v-checkbox
              v-model="outboundOnly"
              :label="
                $t(
                  'admin.manage-facility.report-settings.outbound-opportunities'
                )
              "
            />
          </ValidationProvider>
          <h4>
            {{ $t("admin.manage-facility.report-settings.opportunity-offset") }}
          </h4>
          <p class="tw-mt-2">
            {{
              $t(
                "admin.manage-facility.report-settings.opportunity-offset-description"
              )
            }}
          </p>
          <ValidationProvider
            :name="
              $t('admin.manage-facility.report-settings.opportunity-offset')
            "
            :rules="{ numeric: true, min_value: 1, max_value: 100 }"
            v-slot="{ errors }"
            tag="div"
          >
            <v-text-field
              class="tw-w-20 tw-pt-0"
              type="number"
              suffix="%"
              hide-spin-buttons
              v-model="opportunityOffset"
              :error-messages="errors"
              :placeholder="
                $t(
                  'admin.manage-facility.report-settings.opportunity-offset-helper-text'
                )
              "
            />
          </ValidationProvider>
          <v-container>
            <v-row>
              <h4>{{ $t("reports.performance-thresholds") }}</h4>
              <v-col cols="12">
                <performance-thresholds
                  ref="performanceThresholds"
                  :locationId="locationId"
                  :levelName="$t('locations.levelNames.LocationLevelFacility')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-if="hasImsLicense">
          <v-divider class="tw-mb-4 tw-mt-2" />
          <h2>
            {{ $t("admin.manage-facility.report-settings.ims-settings") }}
          </h2>
          <v-container>
            <v-row>
              <v-col cols="12">
                <performance-bands
                  ref="performanceBands"
                  :facilityId="facility.id"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <ValidationProvider
                :name="
                  $t(
                    'admin.manage-facility.report-settings.look-behind-seconds'
                  )
                "
                :rules="{ numeric: true, max_value: 300, min_value: 0 }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="lookBehindSeconds"
                  :error-messages="errors"
                  :label="
                    $t(
                      'admin.manage-facility.report-settings.look-behind-seconds'
                    )
                  "
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" lg="6">
              <ValidationProvider
                :name="
                  $t('admin.manage-facility.report-settings.look-ahead-seconds')
                "
                :rules="{ numeric: true, max_value: 300, min_value: 0 }"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="lookAheadSeconds"
                  :error-messages="errors"
                  :label="
                    $t(
                      'admin.manage-facility.report-settings.look-ahead-seconds'
                    )
                  "
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <ValidationProvider
            :name="
              $t('admin.manage-facility.report-settings.pop-in-window-seconds')
            "
            :rules="{ numeric: true, max_value: 60, min_value: 0 }"
            v-slot="{ errors }"
            tag="div"
            class="xl:tw-w-1/2 xl:tw-pr-3"
          >
            <v-text-field
              v-model="popInWindowSeconds"
              :error-messages="errors"
              :label="
                $t(
                  'admin.manage-facility.report-settings.pop-in-window-seconds'
                )
              "
            />
          </ValidationProvider>
        </template>
        <template v-if="hasObvLicense">
          <v-divider class="tw-mb-4 tw-mt-2" />
          <h2>
            {{ $t("admin.manage-facility.report-settings.obv-settings") }}
          </h2>
          <ValidationProvider
            :name="$t('admin.manage-facility.report-settings.goal')"
            :rules="{ numeric: true }"
            v-slot="{ errors }"
            tag="div"
            class="xl:tw-w-1/2 xl:tw-pr-3"
          >
            <v-text-field
              v-model="obvGoal"
              :error-messages="errors"
              :label="$t('admin.manage-facility.report-settings.goal')"
            />
          </ValidationProvider>
        </template>
        <v-row class="tw-justify-end">
          <v-btn
            class="tw-mr-5 tw-w-24"
            color="primary"
            type="submit"
            :disabled="
              pristine &&
              ($refs.performanceBands
                ? !$refs.performanceBands.indicatorsChanged
                : true) &&
              ($refs.performanceThresholds
                ? !$refs.performanceThresholds.indicatorsChanged
                : true)
            "
          >
            {{ $t("ui.actions.save") }}
          </v-btn>
          <v-btn
            class="tw-mr-5 tw-w-24"
            color="background2"
            @click="setSettings(facility)"
            :disabled="
              pristine &&
              ($refs.performanceBands
                ? !$refs.performanceBands.indicatorsChanged
                : true) &&
              ($refs.performanceThresholds
                ? !$refs.performanceThresholds.indicatorsChanged
                : true)
            "
          >
            {{ $t("ui.actions.reset") }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
    <div v-else-if="!loading">
      <h3>
        {{ $t("admin.manage-facility.report-settings.no-settings-title") }}
      </h3>
      <p
        v-html="
          $t('admin.manage-facility.report-settings.no-settings-body', {
            facilityName: facility.name
          })
        "
      ></p>
    </div>
  </div>
</template>

<script>
import PerformanceBands from "@/views/admin/components/performanceIndicators/PerformanceBands.vue";
import PerformanceThresholds from "@/views/admin/components/performanceIndicators/PerformanceThresholds.vue";
import facilityService from "@/api/facilityService";
import { scrollToFirstError } from "@/utils/scrollToFirstError";
import { PRODUCTS } from "@/types/products";
import { ROUTE_NAMES } from "@/types/routeNames";
import i18n from "@/plugins/i18n";

export default {
  components: {
    PerformanceBands,
    PerformanceThresholds
  },
  props: {
    facility: {
      type: Object,
      default: () => {
        return { licenses: [] };
      }
    },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    PRODUCTS,
    ROUTE_NAMES,
    lookAheadSeconds: "0",
    lookBehindSeconds: "0",
    maxCompliance: false,
    obvGoal: "",
    opportunityOffset: "",
    outboundOnly: false,
    popInWindowSeconds: "0",
    locationId: 0
  }),
  computed: {
    hasObvLicense() {
      return this.facility.licenses.includes(PRODUCTS.OBS);
    },
    hasAmsLicense() {
      return this.facility.licenses.includes(PRODUCTS.ECM);
    },
    hasImsLicense() {
      return this.facility.licenses.includes(PRODUCTS.IMS);
    }
  },
  methods: {
    setSettings(state) {
      if (this.$refs.manageReportSettingsForm) {
        this.$refs.manageReportSettingsForm.reset();
      }

      this.lookAheadSeconds = state.lookAheadSeconds;
      this.lookBehindSeconds = state.lookBehindSeconds;
      this.maxCompliance = state.max100PctCompliance;
      this.obvGoal = state.observationGoal;
      this.opportunityOffset = state.opportunityOffset;
      this.outboundOnly = state.outboundOnly;
      this.popInWindowSeconds = state.popInWindowSeconds;
      this.locationId = state.locationHierarchyId;

      if (this.$refs.performanceBands || this.$refs.performanceThresholds) {
        this.resetPerformanceIndicators();
      }
    },
    resetPerformanceIndicators() {
      if (this.hasAmsLicense) {
        this.$refs.performanceThresholds.resetPerformanceIndicators();
      }
      if (this.hasImsLicense) {
        this.$refs.performanceBands.resetPerformanceIndicators();
      }
    },
    async validateAndSave() {
      const valid = await this.$refs.manageReportSettingsForm.validate();
      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Facility Report Settings Form Error`
        });
        return;
      }

      this.$emit("update:loading", true);
      this.prepareForApiSubmission();
    },
    async updateFacilityReportSettings(settings) {
      try {
        await facilityService.updateReportSettings(this.facility.id, settings);
        return true;
      } catch {
        return false;
      }
    },
    prepareForApiSubmission() {
      const settings = {
        customerId: this.facility.customerId,
        lookAheadSeconds: this.lookAheadSeconds ? this.lookAheadSeconds : 0,
        lookBehindSeconds: this.lookBehindSeconds ? this.lookBehindSeconds : 0,
        max100PctCompliance: this.maxCompliance,
        observationGoal: this.obvGoal,
        opportunityOffset: this.opportunityOffset,
        outboundOnly: this.outboundOnly,
        popInWindowSeconds: this.popInWindowSeconds
          ? this.popInWindowSeconds
          : 0,
        locationHierarchyId: this.locationId
      };

      const promises = [this.updateFacilityReportSettings(settings)];

      if (this.hasAmsLicense) {
        promises.push(
          this.$refs.performanceThresholds.submitPerformanceIndicators()
        );
      }

      if (this.hasImsLicense) {
        promises.push(
          this.$refs.performanceBands.submitPerformanceIndicators()
        );
      }

      Promise.allSettled(promises).then((results) =>
        this.handlePromisesAllSettled(results, settings)
      );
    },
    async handlePromisesAllSettled(results, settings) {
      if (results[0].value) {
        this.setSettings(settings);

        this.$appInsights?.trackEvent({
          name: `Report Settings Form Success`
        });
      } else {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.manage-facility.error-messages.save-facility")
        });

        this.$appInsights?.trackEvent({
          name: `Report Settings Server Error`
        });
      }

      if (results.every((result) => result.value)) {
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t("admin.manage-facility.success-messages.update")
        );
        this.$refs.manageReportSettingsForm.reset();
      }

      this.$emit("update:loading", false);
    }
  },
  watch: {
    facility: {
      immediate: true,
      handler(value) {
        if (Object.keys(value).length > 2) {
          this.setSettings(value);
        }
      }
    }
  }
};
</script>
