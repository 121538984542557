export default {
  methods: {
    setCustomHeaderSort(headerValue) {
      const urlQuerySortBy = this.$route?.query?.sortBy;
      if (urlQuerySortBy) {
        const urlJsonSortBy = urlQuerySortBy ? JSON.parse(urlQuerySortBy) : {};
        this.customHeaderSort = {
          value: urlJsonSortBy.sortBy,
          columnDescending: urlJsonSortBy.sortDesc,
          clearSort: false
        };
      } else {
        this.customHeaderSort = {
          value: headerValue,
          columnDescending: false,
          clearSort: false
        };
      }
    }
  }
};
