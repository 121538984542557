import { get, post, put, remove } from "./apiClient";

export default {
  async deleteReportSubscription(id) {
    await remove(`reportSubscriptions?subscriptionId=${id}`);
  },
  async getRecipients(facilityId) {
    return await get(`reportSubscriptions/recipients?facilityId=${facilityId}`);
  },
  async getReportTypes(facilityId) {
    return await get(`reportSubscriptionTypes?facilityId=${facilityId}`);
  },
  async getReportSubscription(reportSubscriptionId) {
    return await get(
      `reportSubscriptions?subscriptionId=${reportSubscriptionId}`
    );
  },
  async getReportSubscriptionByToken(token) {
    return await get(`reportSubscriptions?unsubscribeToken=${token}`);
  },
  async getSubscriptionsForFacility(facilityId) {
    return await get(`reportSubscriptions?facilityId=${facilityId}`);
  },
  async saveSubscription(reportSubscription) {
    const url = `reportSubscriptions`;

    if (reportSubscription.id) {
      return await put(url, reportSubscription);
    } else {
      return await post(url, reportSubscription);
    }
  },
  async unsubscribe(token) {
    await remove(`reportSubscriptions/unsubscribe/${token}`);
  }
};
