export const PRODUCTS = {
  ECM: "ECM",
  SA: "SA",
  TM: "TM",
  IMS: "IMS",
  OBS: "OBS"
};

export const PRODUCT_TRANSLATIONS = {
  ECM: "AMS",
  SA: "SA",
  TM: "TM",
  IMS: "IMS",
  OBS: "OBV"
};
