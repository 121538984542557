export default {
  activateDeactivateCustomers: "Activate or Deactivate Customers",
  addCustomers: "Add Customers",
  addFacilities: "Add Facilities",
  administerCustomers: "Administer Customers",
  administerServiceAlerts: "Administer Service Alerts",
  archiveFacilities: "Archive Facilities",
  crudDevices: "CRUD Devices",
  crudImageSlides: "CRUD Image Slides",
  crudSlideshows: "CRUD Slideshows",
  crudUsers: "CRUD Users",
  crudAdminUsers: "CRUD Admin Users",
  diagnostics: "Diagnostics",
  editFacilities: "Edit Facilities",
  manageBadges: "Manage Badges",
  manageJobRoles: "Manage Job Roles",
  manageLicenses: "Manage Licenses",
  manageReportSubscriptions: "Manage Report Subscriptions",
  manageShifts: "Manage Shifts",
  cloneJobRoles: "Clone Job Roles",
  cloneObservationNotes: "Clone Observation Notes",
  observeCompliance: "Observe compliance",
  rawExportReport: "Raw Export Report",
  runReports: "Run Reports",
  support_ImsBadgeAssignment: "Support: IMS Badge Assignment",
  support_ImsValidation: "Support: IMS Validation",
  systemStatus: "System Status",
  systemStatusHistory: "System Status History",
  toggleCustomObservationNotes: "Toggle Custom Observation Notes",
  viewDeviceStatuses: "View Device Statuses",
  deleteLocations: "Delete Locations",
  viewAllAlertSubscriptions: "View All Alert Subscriptions"
};

export function checkPrivileges(privileges, privilege) {
  return Array.isArray(privileges) && privileges.includes(privilege);
}
