import router from "@/router";
import { determineProductFromReportId } from "@/middleware/determineProductFromReportId";
import storageKeys from "@/utils/browserStorage/storageKeys";
import { ROUTE_NAMES } from "@/types/routeNames";
import store from "@/store/appStore";
import i18n from "@/plugins/i18n";

const orderedReportRouteNames = [
  ROUTE_NAMES.amsReports,
  ROUTE_NAMES.imsReports,
  ROUTE_NAMES.obvReports,
  ROUTE_NAMES.saReports
];

function getFirstPermissableReportRouteName() {
  for (const routeName of orderedReportRouteNames) {
    const resolvedRoute = router.resolve({ name: routeName });
    const hasPermission = store.getters["user/permissions/checkPermission"](
      resolvedRoute.route.meta?.permission
    );
    if (hasPermission) {
      return resolvedRoute.route.name;
    }
  }
  return null;
}

export function determineReportRoute(reportId) {
  let verifiedReportId;
  let productId;
  productId = determineProductFromReportId(reportId);
  if (productId) {
    verifiedReportId = reportId;
  } else {
    const localStorageReportId = parseInt(
      localStorage.getItem(storageKeys.report.id)
    );
    productId = determineProductFromReportId(localStorageReportId);
    if (productId) {
      verifiedReportId = localStorageReportId;
    }
  }
  if (!productId) {
    const permissableReportRouteName = getFirstPermissableReportRouteName();
    if (!permissableReportRouteName) {
      throw new Error(i18n.t("errors.no-permissable-reporting-products"));
    }
    return { name: permissableReportRouteName };
  } else {
    const productRouteName = router.getRoutes().find((route) => {
      return route.meta?.product === productId;
    })?.name;
    if (productRouteName) {
      const location = {
        name: productRouteName
      };
      if (verifiedReportId) {
        location.query = {
          reportId: verifiedReportId
        };
      }
      return location;
    } else {
      throw new Error(
        `${i18n.t("errors.route-missing-for-product")}: ${productId}`
      );
    }
  }
}
