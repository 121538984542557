<template>
  <div>
    <v-skeleton-loader :type="`table-heading`" v-if="reportLoading" />
    <div v-else-if="sortedPerformanceThresholds.length">
      <p class="tw-font-bold tw-text-center" style="margin-bottom: 0">
        {{
          $t("reports.performance-thresholds-level", {
            levelName: translatedLevelName
          })
        }}
      </p>
      <ul
        class="
          tw-flex tw-flex-col
          md:tw-flex-row
          print:tw-flex-row print:tw-justify-center
          md:tw-justify-center
          tw-flex-wrap tw-mx-auto tw-list-none
          print:tw-text-sm
        "
      >
        <li
          v-for="(threshold, i) in sortedPerformanceThresholds"
          class="tw-p-2 tw-flex tw-flex-row tw-items-center"
          :key="i"
        >
          <template v-if="hideFirstThreshold && i === 0"></template>
          <template v-else>
            <span class="tw-text-xl px-3">
              <v-icon :color="threshold.color"> mdi-square </v-icon>
            </span>
            <div
              :class="[
                isSlideshow ? 'tw-text-2xl' : '',
                'tw-flex tw-flex-row tw-items-center'
              ]"
            >
              <span>{{ threshold.title }}</span>
              <span v-if="threshold.value != 0" class="tw-text-sm tw-ml-2">
                ({{ threshold.value }}%)
              </span>
            </div></template
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { compare } from "@/utils/comparers";
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  props: {
    performanceThresholds: {
      type: Object,
      default: () => {}
    },
    hideFirstThreshold: {
      type: Boolean
    }
  },
  data() {
    return {
      sortedPerformanceThresholds: []
    };
  },
  computed: {
    ...mapState("reports", ["isSlideshow", "reportLoading"]),
    translatedLevelName() {
      return i18n.t(
        `locations.levelNames.${this.performanceThresholds.levelName}`
      );
    }
  },
  watch: {
    performanceThresholds: {
      immediate: true,
      handler(value) {
        const performanceThresholds = value.thresholds;
        if (!performanceThresholds || !performanceThresholds.length) {
          this.sortedPerformanceThresholds = [];
          return;
        }

        const sortedPerformanceThresholds = [...performanceThresholds];
        this.sortedPerformanceThresholds = sortedPerformanceThresholds.sort(
          (a, b) => compare(a.value, b.value)
        );
      }
    }
  }
};
</script>

<style scoped lang="postcss">
ul {
  @apply tw-px-0;
}
</style>
