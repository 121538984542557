<template>
  <div>
    <h1>{{ $t("admin.device-maintenance.title") }}</h1>
    <div class="tw-ml-2 tw-flex tw-flex-row tw-justify-self-end">
      <div class="tw-flex tw-flex-row tw-items-center">
        <span class="tw-text-sm">{{ $t("customer.customer") }}:</span>
        <v-btn
          text
          class="tw-justify-start"
          :to="{
            name: ROUTE_NAMES.customerDashboard,
            query: { customerId: facility.customerId }
          }"
        >
          <h4>{{ facility.customerName }}</h4>
          <v-icon right> mdi-undo-variant </v-icon>
        </v-btn>
      </div>
      <div class="tw-ml-2 tw-flex tw-flex-row tw-items-center">
        <span class="tw-text-sm">{{ $t("facility.facility") }}:</span>
        <v-btn
          text
          class="tw-justify-start"
          :to="{
            name: ROUTE_NAMES.facilityDashboard,
            query: { customerId: facility.customerId, facilityId: facilityId }
          }"
        >
          <h4>{{ facility.name }}</h4>
          <v-icon right> mdi-undo-variant </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="tw-flex tw-relative tw-flex-row">
      <div
        v-if="showFilters"
        ref="filtersContainer"
        class="
          tw-sticky tw-top-0 tw-pr-2 tw-h-full tw-bg-white tw-pb-4
          filters-container
        "
        style="z-index: 2"
      >
        <div class="tw-flex tw-flex-row tw-items-center">
          <h3>{{ $t("admin.device-maintenance.filters-header") }}</h3>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="showFilters = false">
                <v-icon>mdi-eye-off</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("admin.device-maintenance.hide-filters") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="filtersSelected"
                icon
                v-bind="attrs"
                v-on="on"
                @click="clearFilters"
              >
                <v-icon color="secondary">mdi-filter-remove</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("admin.device-maintenance.clear-filters") }}</span>
          </v-tooltip>
        </div>
        <div
          class="
            tw-flex
            tw-flex-col
            tw-w-full
            tw-justify-between
            tw-items-center
            tw-flex-wrap
          "
        >
          <selector
            selectionComponent="hierarchy-selection"
            label="admin.device-maintenance.filters.locations"
            :items="locations"
            v-model="selectedLocations"
            itemChildIdKey="childLocations"
          />
          <selector
            label="admin.device-maintenance.filters.assignment"
            :items="assignmentOptions"
            v-model="selectedAssignmentOptions"
          />
          <selector
            label="admin.device-maintenance.filters.type"
            :items="deviceTypes"
            v-model="selectedDeviceTypes"
          />
          <selector
            label="admin.device-maintenance.filters.model"
            :items="deviceModels"
            v-model="selectedDeviceModels"
          />
          <selector
            label="admin.device-maintenance.filters.battery"
            :items="batteryStatuses"
            v-model="selectedBatteryStatuses"
          />
          <selector
            label="admin.device-maintenance.filters.online-status"
            :items="onlineStatuses"
            v-model="selectedOnlineStatuses"
          />
          <v-switch
            v-if="checkPrivileges(facility.privileges, PRIVILEGES.crudDevices)"
            v-model="placeholdersOnly"
            :label="$t('admin.device-maintenance.placeholders-only')"
            class="tw-self-end tw-mb-2"
            hide-details
          />
        </div>
      </div>
      <div
        :class="[
          'tw-flex tw-flex-row tw-w-full',
          { 'table-container': showFilters }
        ]"
      >
        <v-divider
          v-if="showFilters"
          vertical
          class="tw-py-0 tw-ml-1 tw-mr-2"
        />
        <v-data-table
          class="small-headers tw-w-full"
          item-key="id"
          :items="filteredDevices"
          :headers="headers"
          :search="search"
          :loading="loading"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{
            'items-per-page-options': itemsPerPageOptions
          }"
          :loading-text="$t('admin.device-maintenance.loading')"
          :no-data-text="$t('admin.device-maintenance.empty')"
          :height="'75vh'"
        >
          <template v-slot:top>
            <div
              class="
                tw-relative
                tw-flex
                tw-justify-between
                tw-items-center
                tw-sticky
                tw-bg-white
                tw-top-0
              "
              :style="{
                'z-index': 2
              }"
            >
              <v-btn
                v-if="!showFilters"
                @click="showFilters = true"
                fab
                small
                color="primary"
                absolute
                :style="{ left: '-40px', top: '60px' }"
              >
                <v-icon> mdi-filter </v-icon>
              </v-btn>
              <v-text-field
                v-model="search"
                :label="`${$t('ui.input-select.search')}`"
                class="tw-mr-4"
                clearable
                prepend-inner-icon="mdi-magnify"
              />
              <v-btn
                class="tw-max-w-xs tw-mr-2"
                color="primary"
                @click="getDevices"
              >
                <v-icon class="tw-mr-1"> mdi-refresh </v-icon>
                {{ $t("admin.device-maintenance.refresh-devices") }}
              </v-btn>
              <v-btn
                v-if="
                  checkPrivileges(facility.privileges, PRIVILEGES.crudDevices)
                "
                class="tw-max-w-xs tw-mr-2"
                color="primary"
                @click="openEdit(null)"
              >
                <v-icon class="tw-mr-1"> mdi-plus-circle-outline </v-icon>
                {{ $t("admin.device-maintenance.add-placeholders") }}
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.currentOnlineStatus`]="{ item }">
            <td
              :style="{
                color:
                  item.currentOnlineStatus === 'Online' ? '#4CAF50' : '#FF5252'
              }"
            >
              {{ item.currentOnlineStatus }}
            </td>
          </template>
          <template v-slot:[`item.lastCommunicationTimestamp`]="{ item }">
            <td>
              {{ formatTimestamp(item.lastCommunicationTimestamp) }}
            </td>
          </template>
          <template v-slot:[`item.lastEventTimestamp`]="{ item }">
            <td>
              {{ formatTimestamp(item.lastEventTimestamp) }}
            </td>
          </template>
          <template v-slot:[`item.currentBatteryStatus`]="{ item }">
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="item.battery.color" v-bind="attrs" v-on="on">
                    {{ `mdi-${item.battery.icon}` }}
                  </v-icon>
                </template>
                <span>{{ $t(item.battery.tooltip) }}</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <td>
              <div
                v-if="item.isPlaceholder"
                class="tw-flex tw-flex-row tw-items-center"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openEdit(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t("admin.device-maintenance.edit-placeholder") }}
                  </span>
                </v-tooltip>
                <v-menu
                  :close-on-content-click="false"
                  :ref="`menu-${item.id}`"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                          @click="item.showMenu = false"
                        >
                          <v-icon>mdi-tag-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t("admin.device-maintenance.convert-to-device") }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-title class="text-md-subtitle-1 tw-pb-2 tw-pl-3"
                      ><div
                        v-html="
                          $t(
                            `admin.device-maintenance.convert-to-device-menu`,
                            {
                              name: item.name
                            }
                          )
                        "
                      ></div
                    ></v-card-title>

                    <ValidationProvider
                      :name="$t('admin.placeholders-and-devices.serial-number')"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        class="tw-mx-3 tw-pt-0"
                        :error-messages="[...errors, ...serialNumberErrors]"
                        v-model="serialNumberForConvert"
                        :label="
                          $t('admin.placeholders-and-devices.serial-number')
                        "
                      />
                    </ValidationProvider>
                    <v-card-actions class="tw-justify-end tw-pt-0">
                      <v-btn
                        class="tw-mr-1"
                        color="primary"
                        :disabled="serialNumberForConvert === ''"
                        @click="convertPlaceholder(item.id)"
                      >
                        {{ $t("admin.device-maintenance.convert") }}
                      </v-btn>
                      <v-btn color="background2" @click="closeMenu(item.id)">
                        {{ $t("ui.actions.cancel") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :loading="item.isLoading"
                      @click="deletePlaceholder(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("admin.device-maintenance.delete") }}</span>
                </v-tooltip>
              </div>
              <div v-else class="tw-flex tw-flex-row tw-items-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="openEdit(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("admin.device-maintenance.configure") }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      icon
                      v-on="{ ...tooltip }"
                      @click="setAssignModal(item)"
                    >
                      <v-icon>
                        {{
                          item.isAssigned
                            ? "mdi-domain-remove"
                            : "mdi-domain-plus"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{
                      item.isAssigned
                        ? $t("admin.device-maintenance.unassign")
                        : $t("admin.device-maintenance.assign")
                    }}
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      icon
                      v-on="{ ...tooltip }"
                      @click="setDeactivateModal(item)"
                    >
                      <v-icon>{{ "mdi-delete" }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("admin.device-maintenance.deactivate") }}</span>
                </v-tooltip>
              </div>
            </td>
          </template>
          <template
            v-if="checkPrivileges(facility.privileges, PRIVILEGES.crudDevices)"
            v-slot:[`footer.prepend`]
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  v-on="on"
                  @click="showActivateDevice = true"
                  class="tw-mr-2"
                >
                  <v-icon>mdi-delete-restore</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("admin.device-maintenance.reactivate-device") }}
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  icon
                  v-on="on"
                  @click="showRoomTemplate = true"
                >
                  <v-icon>mdi-file-edit</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t("admin.device-maintenance.edit-room-template") }}
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog v-model="showAssignModal" max-width="500">
          <v-card>
            <v-container v-if="selectedDevice.isAssigned">
              <v-card-title
                ><div
                  v-html="
                    $t(`admin.device-maintenance.unassign-device`, {
                      location: modalDeviceLocation,
                      name: modalDeviceName
                    })
                  "
                ></div
              ></v-card-title>

              <v-card-actions>
                <v-row>
                  <v-col class="tw-flex tw-flex-row tw-justify-end">
                    <v-btn
                      @click="showAssignModal = false"
                      :disabled="modalLoading"
                      class="tw-mr-5"
                      text
                      >{{ $t("ui.actions.cancel") }}</v-btn
                    >
                    <v-btn
                      color="error"
                      @click="toggleAssignedStatus()"
                      :loading="modalLoading"
                    >
                      {{ $t("admin.device-maintenance.unassign") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-container>
            <v-container v-else>
              <v-card-title
                ><div
                  v-html="
                    $t(`admin.device-maintenance.assign-device-title`, {
                      name: modalDeviceName,
                      location: assignLocation
                    })
                  "
                ></div
              ></v-card-title>

              <v-card-text>
                {{ $t("admin.device-maintenance.assign-device-body") }}
              </v-card-text>
              <v-col cols="12" lg="6">
                <ValidationProvider
                  :name="$t('admin.device-maintenance.filters.locations')"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <selector
                    selectionComponent="hierarchy-selection"
                    label="admin.device-maintenance.headers.location"
                    :items="locations"
                    v-model="assignLocation"
                    itemChildIdKey="childLocations"
                    activator="v-select"
                    :offsetX="false"
                    :minWidth="700"
                    :menuProps="{ 'nudge-top': 180 }"
                    singleSelect
                    :textFieldProps="{
                      'error-messages': errors
                    }"
                  />
                </ValidationProvider>
              </v-col>
              <v-card-actions>
                <v-row>
                  <v-col class="tw-flex tw-flex-row tw-justify-end">
                    <v-btn
                      @click="closeAssignDialog"
                      class="tw-mr-5"
                      text
                      :disabled="modalLoading"
                    >
                      {{ $t("ui.actions.cancel") }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="assignLocation.length === 0"
                      :loading="modalLoading"
                      @click="toggleAssignedStatus()"
                      >{{ $t("admin.device-maintenance.assign") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDeactivateModal" max-width="500">
          <v-card>
            <v-container>
              <v-card-title
                ><div
                  v-html="
                    $t(`admin.device-maintenance.deactivate-modal-title`, {
                      name: selectedDevice.name
                    })
                  "
                ></div
              ></v-card-title>

              <v-card-actions>
                <v-row>
                  <v-col class="tw-flex tw-flex-row tw-justify-end">
                    <v-btn
                      @click="showDeactivateModal = false"
                      :disabled="modalLoading"
                      class="tw-mr-5"
                      text
                      >Cancel</v-btn
                    >
                    <v-btn
                      @click="deactivateDevice(selectedDevice.id)"
                      :loading="modalLoading"
                      color="error"
                    >
                      {{ $t("admin.device-maintenance.deactivate") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div v-if="checkPrivileges(facility.privileges, PRIVILEGES.crudDevices)">
      <placeholders-and-devices
        :visible.sync="showPlaceholdersAndDevices"
        :facilityId="facilityId"
        :itemToEdit.sync="itemToEdit"
        :locations="locations"
        @onSave="onDeviceSave"
      />
      <room-template
        :visible.sync="showRoomTemplate"
        :facilityId="facilityId"
      />
      <activate-device
        v-if="showActivateDevice"
        :visible.sync="showActivateDevice"
        :facilityId="facilityId"
        :locations="locations"
        @onSave="onDeviceSave"
      />
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { ValidationProvider } from "vee-validate";
import PRIVILEGES, { checkPrivileges } from "@/types/privileges";
import { PRODUCTS } from "@/types/products";
import { ROUTE_NAMES } from "@/types/routeNames";
import facilityService from "@/api/facilityService";
import deviceService from "@/api/deviceService";
import devicePlaceholderService from "@/api/devicePlaceholderService";
import Selector from "@/views/admin/components/Selector.vue";
import PlaceholdersAndDevices from "@/views/admin/components/deviceMaintenance/PlaceholdersAndDevices.vue";
import RoomTemplate from "@/views/admin/components/deviceMaintenance/RoomTemplate.vue";
import ActivateDevice from "@/views/admin/components/deviceMaintenance/ActivateDevice.vue";

export default {
  components: {
    ActivateDevice,
    Selector,
    PlaceholdersAndDevices,
    RoomTemplate,
    ValidationProvider
  },
  props: {
    facilityId: { type: Number },
    deviceType: { type: String },
    locationId: { type: Number }
  },
  data() {
    const baseSelectedAssignmentOptions = [true];

    return {
      assignmentOptions: [
        { id: true, text: "Assigned" },
        { id: false, text: "Unassigned" }
      ],
      assignLocation: [],
      batteryStatuses: [],
      devices: [],
      deviceModels: [],
      deviceTypes: [],
      facility: { privileges: [] },
      itemToEdit: null,
      itemsPerPage: 50,
      itemsPerPageOptions: [10, 25, 50, 100],
      loading: true,
      locations: [],
      modalDeviceName: "",
      modalDeviceLocation: "",
      modalLoading: false,
      onlineStatuses: [],
      PRIVILEGES,
      placeholdersOnly: false,
      PRODUCTS,
      ROUTE_NAMES,
      search: "",
      baseSelectedAssignmentOptions,
      selectedAssignmentOptions: baseSelectedAssignmentOptions,
      selectedBatteryStatuses: [],
      selectedDevice: {},
      selectedDeviceModels: [],
      selectedDeviceTypes: [],
      selectedLocations: [],
      selectedOnlineStatuses: [],
      serialNumberErrors: [],
      serialNumberForConvert: "",
      showAssignModal: false,
      showDeactivateModal: false,
      showFilters: true,
      showPlaceholdersAndDevices: false,
      showRoomTemplate: false,
      showActivateDevice: false
    };
  },
  computed: {
    filteredDevices() {
      return this.devices.filter((d) => {
        return (
          (!this.placeholdersOnly || d.isPlaceholder) &&
          (!this.selectedAssignmentOptions.length ||
            this.selectedAssignmentOptions.includes(d.isAssigned)) &&
          (!this.selectedBatteryStatuses.length ||
            this.selectedBatteryStatuses.includes(d.currentBatteryStatus)) &&
          (!this.selectedDeviceTypes.length ||
            this.selectedDeviceTypes.includes(d.deviceType)) &&
          (!this.selectedDeviceModels.length ||
            this.selectedDeviceModels.includes(d.deviceModel)) &&
          (!this.selectedLocations.length ||
            this.selectedLocations.includes(d.locationId)) &&
          (!this.selectedOnlineStatuses.length ||
            this.selectedOnlineStatuses.includes(d.currentOnlineStatus))
        );
      });
    },
    filtersSelected() {
      return (
        this.placeholdersOnly > 0 ||
        this.selectedLocations.length > 0 ||
        this.selectedAssignmentOptions !== this.baseSelectedAssignmentOptions ||
        this.selectedBatteryStatuses.length > 0 ||
        this.selectedDeviceTypes.length > 0 ||
        this.selectedDeviceModels.length > 0 ||
        this.selectedOnlineStatuses.length > 0
      );
    },
    headers() {
      const headers = [
        {
          text: i18n.t("admin.device-maintenance.headers.serial-number"),
          value: "serialNumber",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.device-maintenance.headers.name"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.device-maintenance.headers.device-type"),
          value: "deviceType",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.device-maintenance.headers.model"),
          value: "deviceModel",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.device-maintenance.headers.location"),
          value: "location",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.device-maintenance.headers.last-communication"),
          value: "lastCommunicationTimestamp",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.device-maintenance.headers.last-event"),
          value: "lastEventTimestamp",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.device-maintenance.headers.status"),
          value: "currentOnlineStatus",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.device-maintenance.headers.battery"),
          value: "currentBatteryStatus",
          sortable: true,
          class: "tw-align-text-top"
        }
      ];

      if (
        this.checkPrivileges(this.facility.privileges, PRIVILEGES.crudDevices)
      ) {
        headers.push({
          text: i18n.t("admin.device-maintenance.headers.actions"),
          value: "actions",
          sortable: false,
          class: "tw-align-text-top"
        });
      }
      return headers;
    }
  },
  async created() {
    try {
      facilityService
        .get({
          facilityId: this.facilityId
        })
        .then((facility) => {
          this.facility = facility;

          this.locations = facility.facilityHierarchy.childLocations;
        });

      await this.getDevices();

      if (this.deviceType) {
        this.selectedDeviceTypes = [this.deviceType];
      }

      if (this.locationId) {
        this.selectedLocations = [this.locationId];
      }
    } catch (err) {
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t("admin.device-maintenance.error-messages.get")
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    checkPrivileges,
    clearFilters() {
      this.selectedAssignmentOptions = this.baseSelectedAssignmentOptions;
      this.selectedBatteryStatuses = [];
      this.selectedDeviceTypes = [];
      this.selectedDeviceModels = [];
      this.selectedLocations = [];
      this.selectedOnlineStatuses = [];
      this.placeholdersOnly = false;
    },
    closeAssignDialog() {
      this.assignLocation = [];
      this.showAssignModal = false;
    },
    closeMenu(id) {
      this.$refs[`menu-${id}`].save();
      this.serialNumberForConvert = "";
    },
    async convertPlaceholder(placeholderId) {
      this.loading = true;
      this.serialNumberErrors = [];

      try {
        await devicePlaceholderService.convertDevicePlaceholder(
          this.facilityId,
          placeholderId,
          this.serialNumberForConvert.trim()
        );
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t("admin.device-maintenance.success-messages.convert")
        );
        this.closeMenu(placeholderId);
        this.getDevices();
      } catch (error) {
        if (error === 409) {
          this.serialNumberErrors = [
            i18n.t(
              "admin.device-maintenance.error-messages.duplicate-serial-number"
            )
          ];
        } else if (error === 412) {
          this.serialNumberErrors = [
            i18n.t(
              "admin.device-maintenance.error-messages.serial-number-does-not-exist"
            )
          ];
        }
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.device-maintenance.error-messages.convert")
        });
      } finally {
        this.loading = false;
      }
    },
    async deactivateDevice(deviceId) {
      try {
        this.modalLoading = true;

        await deviceService.deactivateDevice(deviceId);
        this.showDeactivateModal = false;
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t("admin.device-maintenance.success-messages.deactivate")
        );

        this.devices = this.devices.filter((d) => d.id !== deviceId);
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.device-maintenance.error-messages.deactivate")
        });
      } finally {
        this.modalLoading = false;
      }
    },
    async deletePlaceholder(placeholder) {
      try {
        placeholder.isLoading = true;
        await devicePlaceholderService.deletePlaceholder(
          this.facilityId,
          placeholder.id
        );
        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          i18n.t("admin.device-maintenance.success-messages.delete")
        );
        this.devices = this.devices.filter((d) => d.id !== placeholder.id);
      } catch (err) {
        placeholder.isLoading = false;
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.device-maintenance.error-messages.delete")
        });
      } finally {
        this.loading = false;
      }
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        return new Date(`${timestamp}`).toLocaleDateString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "2-digit",
          hourCycle: "h12"
        });
      }
      return "";
    },
    getBatteryIcon(batteryStatus) {
      switch (batteryStatus) {
        case "Good":
          return {
            icon: "battery-high",
            color: "success",
            tooltip: "admin.device-maintenance.battery-status.good"
          };
        case "Low":
          return {
            icon: "battery-low",
            color: "warning",
            tooltip: "admin.device-maintenance.battery-status.low"
          };
        case "Dead":
          return {
            icon: "battery-alert",
            color: "error",
            tooltip: "admin.device-maintenance.battery-status.dead"
          };
        default:
          return {
            icon: "battery-unknown",
            color: "",
            tooltip: "admin.device-maintenance.battery-status.unknown"
          };
      }
    },
    async getDevices() {
      this.loading = true;
      const devices = await deviceService.getDevicesForMaintenance(
        this.facilityId
      );

      this.devices = devices.map((device) => {
        return {
          ...device,
          currentBatteryStatus: device.currentBatteryStatus
            ? device.currentBatteryStatus
            : "Unknown",
          battery: this.getBatteryIcon(device.currentBatteryStatus),
          serialNumber: device.serialNumber
            ? device.serialNumber
            : "Placeholder",
          isPlaceholder: device.serialNumber === null,
          isLoading: false,
          location: device.locationNames.length
            ? device.locationNames.join(" > ")
            : "Unassigned",
          deviceModel: device.deviceModel ? device.deviceModel : "None"
        };
      });

      this.loading = false;

      this.populateFilters();
    },
    onDeviceSave(settings) {
      this.getDevices();

      if (!settings.editing) {
        this.placeholdersOnly = settings.showPlaceholdersOnly;
        this.selectedLocations = settings.locations;
      }
    },
    openEdit(item) {
      if (item) {
        this.itemToEdit = item;
      }

      this.showPlaceholdersAndDevices = true;
    },
    populateFilters() {
      this.batteryStatuses = [
        ...new Set(
          this.devices
            .map((d) => d.currentBatteryStatus)
            .filter((b) => b !== null && b.length > 0)
        )
      ].sort((a, b) => a.localeCompare(b));

      this.deviceModels = [
        ...new Set(this.devices.map((d) => d.deviceModel))
      ].sort((a, b) => a.localeCompare(b));

      this.deviceTypes = [
        ...new Set(this.devices.map((d) => d.deviceType))
      ].sort((a, b) => a.localeCompare(b));

      this.onlineStatuses = [
        ...new Set(
          this.devices
            .map((d) => d.currentOnlineStatus)
            .filter((s) => s !== null && s.length > 0)
        )
      ].sort((a, b) => a.localeCompare(b));
    },
    setAssignModal(device) {
      this.modalDeviceName = device.name;
      this.modalDeviceLocation = device.location;
      this.selectedDevice = device;
      this.showAssignModal = true;
    },
    setDeactivateModal(device) {
      this.selectedDevice = device;
      this.showDeactivateModal = true;
    },
    async toggleAssignedStatus() {
      const deviceIsAssigned = this.selectedDevice.isAssigned;

      try {
        this.modalLoading = true;

        if (deviceIsAssigned) {
          await deviceService.unassignDevice(this.selectedDevice.id);
        } else {
          await deviceService.assignDevice(
            this.selectedDevice.id,
            this.assignLocation[0]
          );
        }

        this.$store.commit(
          "application/SET_SUCCESS_MESSAGE",
          deviceIsAssigned
            ? i18n.t("admin.device-maintenance.success-messages.unassign")
            : i18n.t("admin.device-maintenance.success-messages.assign")
        );
        this.closeAssignDialog();
        this.getDevices();
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: deviceIsAssigned
            ? i18n.t("admin.device-maintenance.error-messages.unassign")
            : i18n.t("admin.device-maintenance.error-messages.assign")
        });
      } finally {
        this.modalLoading = false;
      }
    }
  }
};
</script>

<style scoped lang="postcss">
@media (max-width: 1400px) {
  .table-container {
    width: 85%;
  }
}

@media (max-width: 1100px) {
  .table-container {
    width: 80%;
  }
}

@media (max-width: 790px) {
  .table-container {
    width: 75%;
  }
}

.filters-container {
  max-width: 180px;
}

:deep(.v-data-footer) {
  position: sticky;
  bottom: 0;
  background: #fff;
}
</style>
