import { makeCRUDModule } from "@/store/modules/makeModule";
import filterUtils from "@/store/modules/filters/filterUtils";
import cloneDeep from "lodash/cloneDeep";

export function makeFilterModule({
  service,
  normalizer,
  stateMerge,
  transformer,
  allSelected = false,
  defaultValue,
  getHierarchy = null,
  getOrderedItems = filterUtils.sortByText,
  getSelectedText = filterUtils.defaultSelectedText,
  getFilterText = filterUtils.defaultFilterText,
  getFilterTextAbbreviated = filterUtils.defaultFilterText,
  getSelectedTextDetails = null,
  getSelectableIds = filterUtils.defaultGetSelectableIds,
  selectionType = null,
  itemChildrenKey = null,
  activeIds = []
} = {}) {
  const store = makeCRUDModule({
    service,
    normalizer,
    stateMerge,
    transformer
  });

  const baseCall = store.actions.load;
  store.actions.load = async (
    { state, commit, rootGetters },
    servicePayload
  ) => {
    const response = await baseCall({ commit }, servicePayload);

    if (getHierarchy) {
      commit(
        "setHierarchy",
        getHierarchy(
          {
            ...state,
            locationLevel: servicePayload?.locationLevel,
            facilityHierarchy: response?.locationHierarchy
          },
          rootGetters
        )
      );
    }
    commit("reset");
  };

  Object.assign(store.getters, {
    listActive: (state) => {
      if (state.activeIds === undefined) {
        return [];
      }
      if (state.activeIds instanceof Array) {
        return state.activeIds.map((id) => ({ ...state.byId[id], id }));
      }
      return [{ ...state.byId[state.activeIds], id: state.activeIds }];
    },
    orderedItems: (state, getters, rootState) => {
      return getOrderedItems(state, getters, rootState);
    },
    selectedText: (state, getters) => (replacementState) => {
      const modifiedState = { ...state, ...replacementState };
      return getSelectedText(modifiedState, getters);
    },
    filterText: (state, getters) => (replacementState) => {
      const modifiedState = { ...state, ...replacementState };
      return getFilterText(modifiedState, getters);
    },
    filterTextAbbreviated: (state, getters) => (replacementState) => {
      const modifiedState = { ...state, ...replacementState };
      return getFilterTextAbbreviated(modifiedState, getters);
    },
    selectedTextDetails: (state, getters) => (replacementState) => {
      if (!getSelectedTextDetails) {
        return null;
      }
      const modifiedState = { ...state, ...replacementState };
      return getSelectedTextDetails(modifiedState, getters);
    },
    selectableIds: (state) => {
      return getSelectableIds(state);
    }
  });

  Object.assign(store.state, {
    activeIds,
    allSelected,
    hiddenIds: []
  });

  if (getHierarchy) {
    Object.assign(store.state, {
      hierarchy: {
        items: [],
        levels: []
      }
    });
    Object.assign(store.mutations, {
      setHierarchy: (state, hierarchy) => {
        Object.assign(state.hierarchy, hierarchy);
      }
    });
    Object.assign(store.getters, {
      getHierarchy: (state) => cloneDeep(state.hierarchy)
    });
  }

  if (selectionType) {
    Object.assign(store.state, { selectionType });
  }

  if (itemChildrenKey) {
    Object.assign(store.state, { itemChildrenKey });
  }

  const baseClear = store.mutations.clear;
  Object.assign(store.mutations, {
    setHiddenIds: (state, hiddenIds) => (state.hiddenIds = hiddenIds),
    toggle: (state, id) => {
      if (state.activeIds.includes(id)) {
        state.activeIds.splice(state.activeIds.indexOf(id), 1);
      } else {
        state.activeIds.push(id);
      }
    },
    clear: (state) => {
      baseClear(state);
      Object.assign(state, {
        activeIds: []
      });
      if (getHierarchy) {
        Object.assign(store.state, {
          hierarchy: {
            items: [],
            levels: []
          }
        });
      }
    },
    reset: (state) => {
      if (defaultValue !== undefined) {
        Object.assign(state, {
          activeIds: defaultValue
        });
      } else if (allSelected) {
        Object.assign(state, {
          activeIds: getSelectableIds({ ...state })
        });
      } else {
        Object.assign(state, {
          activeIds: []
        });
      }
    }
  });

  return store;
}
