<template>
  <ul
    v-if="sortedPerformanceBands.length"
    class="
      tw-flex tw-flex-col
      md:tw-flex-row
      print:tw-flex-row print:tw-justify-center
      md:tw-justify-center
      tw-flex-wrap tw-mx-auto tw-list-none
      print:tw-text-sm
    "
  >
    <li
      v-for="(band, i) in sortedPerformanceBands"
      class="tw-p-2 tw-flex tw-flex-row tw-items-center"
      :key="i"
    >
      <span class="tw-text-xl px-3">
        <v-icon :color="band.color" class="tw-m-2">
          mdi-{{ band.symbol.symbol }}
        </v-icon>
      </span>
      <div class="tw-flex tw-flex-col" :class="{ 'tw-text-2xl': isSlideshow }">
        <span>{{ band.title }}</span>
        <span v-if="!hideLimitPercentages" class="tw-text-sm">
          {{ performanceBandLowEnd(i) }}% - {{ band.limit }}%
        </span>
      </div>
    </li>
  </ul>
</template>

<script>
import { compare } from "@/utils/comparers";
import { mapState } from "vuex";

export default {
  props: {
    performanceBands: {
      type: Array,
      default: () => []
    },
    hideLimitPercentages: {
      type: Boolean
    }
  },
  data() {
    return {
      sortedPerformanceBands: []
    };
  },
  computed: {
    ...mapState("reports", ["isSlideshow"])
  },
  methods: {
    performanceBandLowEnd(index) {
      return index > 0 ? this.sortedPerformanceBands[index - 1].limit + 1 : 0;
    }
  },
  watch: {
    performanceBands: {
      immediate: true,
      handler(value) {
        if (!value.length) {
          return;
        }

        const sortedPerformanceBands = [...value];
        this.sortedPerformanceBands = sortedPerformanceBands.sort((a, b) =>
          compare(a.limit, b.limit)
        );
      }
    }
  }
};
</script>

<style scoped lang="postcss">
ul {
  @apply tw-px-0;
}
</style>
