<template>
  <v-skeleton-loader :type="`table`" v-if="reportLoading" />
  <v-data-table
    v-else
    :items="items"
    :headers="headers"
    :itemKey="rowKey"
    :show-select="showSelect"
    :value="selectedItems"
    :dense="dense"
    :search="search"
    :items-per-page="isSlideshow ? 10 : showAllRows"
    :disable-pagination="disablePagination"
    :hide-default-footer="disablePagination"
    @input="onInput"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :class="{
      'slideshow-view': isSlideshow,
      'print-view': isPrint,
      'has-selectable-items': hasSelectableItems
    }"
    :hide-default-header="showCustomHeader ? true : false"
  >
    <template v-if="showSearch && !isSlideshow" v-slot:top>
      <v-text-field
        v-show="!isPrint"
        v-model="search"
        :label="`${$t('ui.input-select.search')}`"
        @focus="trackSearchAnalytics()"
        class="tw-mx-4"
        clearable
        prepend-inner-icon="mdi-magnify"
      />
    </template>
    <template v-if="showCustomHeader" slot="header">
      <slot name="customHeader"></slot>
    </template>
    <template
      v-for="header in headers"
      v-slot:[`item.${header.value}`]="{ item, value }"
    >
      <component
        v-if="header.display"
        :is="header.display"
        :value="value"
        :item="item"
        :key="header.value"
      />
      <div :key="header.value" v-else-if="header.displayText">
        {{ item[header.displayText] }}
      </div>
      <div :key="header.value" v-else>
        {{ value }}
      </div>
    </template>
    <template v-if="showTotalRow" slot="body.append">
      <slot name="totalRow"></slot>
    </template>
  </v-data-table>
</template>

<script>
import DisplayFormatterImage from "../displayFormatters/DisplayFormatterImage.vue";
import DisplayFormatterSparkline from "../displayFormatters/DisplayFormatterSparkline.vue";
import DisplayFormatterPercentage from "../displayFormatters/DisplayFormatterPercentage.vue";
import DisplayFormatterNumber from "../displayFormatters/DisplayFormatterNumber.vue";
import DisplayFormatterIndicator from "../displayFormatters/DisplayFormatterIndicator.vue";
import DisplayFormatterLocale from "../displayFormatters/DisplayFormatterLocale.vue";
import DisplayFormatterTrend from "../displayFormatters/DisplayFormatterTrend.vue";

import { DISPLAY_MODES } from "@/store/modules/application";
import { mapState } from "vuex";

export default {
  components: {
    TableImage: DisplayFormatterImage,
    SparkLine: DisplayFormatterSparkline,
    Percentage: DisplayFormatterPercentage,
    Number: DisplayFormatterNumber,
    Indicator: DisplayFormatterIndicator,
    Locale: DisplayFormatterLocale,
    Trend: DisplayFormatterTrend
  },
  props: {
    initialSortedColumns: {
      type: Array,
      default: () => []
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      default: () => []
    },
    hasSelectableItems: {
      type: Boolean,
      default: false
    },
    clearSelectedItems: {
      type: Boolean,
      default: false
    },
    rowKey: {
      type: String
    },
    defaultSelectedRows: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    showCustomHeader: {
      type: Boolean
    },
    showTotalRow: {
      type: Boolean
    },
    customHeaderSort: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    isFirstLoad: true,
    showAllRows: -1,
    search: "",
    sortBy: "",
    sortDesc: null,
    selectedItems: [],
    currentItems: []
  }),
  computed: {
    ...mapState("application", ["displayMode"]),
    ...mapState("reports", [
      "isSlideshow",
      "reportLoading",
      "selectedData",
      "tableColumnSort"
    ]),
    isPrint() {
      return this.displayMode === DISPLAY_MODES.PRINT;
    },
    disablePagination() {
      return this.isPrint || this.isSlideshow;
    },
    dense() {
      return this.isPrint || this.isSlideshow;
    },
    showSelect() {
      return this.hasSelectableItems && !this.isPrint;
    },
    tableColumnSortState() {
      return {
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      };
    }
  },
  mounted() {
    const urlQuerySortBy = this.$route?.query?.sortBy;
    const urlJsonSortBy = urlQuerySortBy ? JSON.parse(urlQuerySortBy) : {};
    if (urlQuerySortBy) {
      this.sortBy = urlJsonSortBy.sortBy;
      this.sortDesc = urlJsonSortBy.sortDesc;
    } else if (this.initialSortedColumns.length) {
      this.sortBy = this.initialSortedColumns;
    }
  },
  methods: {
    onInput(selectedItems) {
      this.selectedItems = selectedItems;
      this.$store.commit("reports/setSelectedData", selectedItems);
      this.$appInsights?.trackEvent({
        name: "Report Table Selected Items Changed"
      });
    },
    trackSearchAnalytics() {
      this.$appInsights?.trackEvent({ name: "Report Search Interaction" });
    },
    trackHeaderAnalytics() {
      if (!this.sortBy) {
        return;
      }

      this.$appInsights?.trackEvent({
        name: `Table Column Sorting: ${this.sortBy}`
      });
    }
  },
  watch: {
    items(value) {
      if (!this.hasSelectableItems) {
        return;
      }

      const routeSelectedData = this.$route?.query?.selectedData;
      if (routeSelectedData) {
        const parsedSelectedData = JSON.parse(routeSelectedData);

        this.selectedItems = value.filter(
          (item) => parsedSelectedData.indexOf(item[this.rowKey]) >= 0
        );
      } else {
        this.selectedItems = value.slice(0, this.defaultSelectedRows);
      }

      this.$store.commit("reports/setSelectedData", this.selectedItems);
    },
    customHeaderSort(headerOptions) {
      this.sortBy = null;
      this.sortDesc = null;
      if (headerOptions.clearSort) {
        return;
      }
      this.sortBy = headerOptions.value;
      headerOptions.columnDescending
        ? (this.sortDesc = true)
        : (this.sortDesc = false);
    },
    clearSelectedItems(value) {
      if (value) {
        this.selectedItems = [];
        this.$emit("reset-clear-selected-items");
      }
    },
    tableColumnSortState(value) {
      this.$store.commit("reports/setTableColumnSort", value);
    }
  }
};
</script>

<style lang="postcss">
/* target IE 10+ */
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* help "rows per page" number display properly in IE 11 */
  .v-data-footer .v-select__selections {
    flex-basis: auto;
  }
  .v-data-footer .v-select__selection {
    min-width: 20px;
  }
}

.v-data-table__wrapper > table > tbody > tr:nth-of-type(odd) {
  background-color: #f7fafc;
}

.v-data-table__wrapper > table > tbody > tr.smartlink-table-total-row {
  font-weight: bold;
  background-color: #dedede;
}

.slideshow-view > .v-data-table__wrapper > table > tbody > tr > th,
.slideshow-view > .v-data-table__wrapper > table > tfoot > tr > th,
.slideshow-view > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 30px !important;
}

.slideshow-view > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 26px !important;
}

@media print {
  :deep(thead) {
    display: table-header-group;
  }
  :deep(tr) {
    page-break-inside: avoid;
  }
  :deep(table) {
    table-layout: fixed;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.v-data-table__selected) {
    background: inherit;
  }

  .theme--light.v-data-table.print-view.has-selectable-items
    tbody
    tr.v-data-table__selected {
    display: table-row;
  }
  .theme--light.v-data-table.print-view.has-selectable-items tbody tr {
    display: none;
  }
}
</style>
