import store from "@/store/appStore";
import { convertDateForExport } from "@/utils/dateUtils";

export function csvDataMapper(data, headers) {
  const language = store.state.preferences.language;

  return data.map((rows) => {
    const values = headers.map((h) => {
      let value = rows[h.value];
      if (typeof value === "number" && h.display === "Percentage") {
        value = value.toFixed(1);
      } else if (h.display === "Date") {
        value = convertDateForExport(value, language);
      }

      return value;
    });
    return values;
  });
}
