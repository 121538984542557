<template>
  <div class="tw-flex md:tw-w-1/2 tw-flex-col md:tw-flex-row tw-mb-5">
    <div class="tw-flex-1 tw-px-2 sm:tw-px-4" v-if="customers.length > 1">
      <app-text-dropdown
        :disabled="customers.length <= 1 || interactable === false"
        data-name="customer-selector"
        :items="customers"
        :label="$t('customer.customer')"
        :value="$store.state.customers.customerId"
        @input="
          (e) =>
            $store.dispatch('customers/changeCustomer', {
              customerId: e
            })
        "
        searchable
      />
    </div>
    <div class="tw-flex-1 tw-px-2 sm:tw-px-4">
      <app-text-dropdown
        :disabled="facilities.length <= 1 || interactable === false"
        data-name="facility-selector"
        :items="facilities"
        :label="$t('facility.facility')"
        :value="$store.state.customers.facilityId"
        @input="(e) => $store.commit('customers/setFacility', e)"
        searchable
      />
    </div>
  </div>
</template>

<script>
import AppTextDropdown from "@/components/ui/AppTextDropdown.vue";
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    interactable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    AppTextDropdown
  },
  data: () => ({
    customers: [],
    facilities: []
  }),
  computed: {
    ...mapState("customers", ["licensedFacilityIds"]),
    ...mapGetters("customers", ["licensedCustomerIds"])
  },
  watch: {
    licensedFacilityIds: {
      immediate: true,
      handler(value) {
        this.facilities = value.map(
          (id) => this.$store.state.facilities.byId[id]
        );
      }
    },
    licensedCustomerIds: {
      immediate: true,
      handler(value) {
        this.customers = value.map(
          (id) => this.$store.state.customers.byId[id]
        );
      }
    }
  }
};
</script>
