import { ROUTE_NAMES } from "@/types/routeNames";
import { checkRoutePermission } from "./auth";
import store from "@/store/appStore";
import router from "@/router";

const orderedDefaultRoutes = [
  ROUTE_NAMES.adminCustomerList,
  ROUTE_NAMES.amsReports,
  ROUTE_NAMES.imsReports,
  ROUTE_NAMES.obvReports,
  ROUTE_NAMES.saReports,
  ROUTE_NAMES.obvSystem
];

export function determineDefaultRoute() {
  for (const r of orderedDefaultRoutes) {
    const resolvedRoute = router.resolve({ name: r });
    if (checkRoutePermission(resolvedRoute.resolved, store)) {
      return { name: r };
    }
  }
  return {
    name: ROUTE_NAMES.allProductsUnlicensed
  };
}
