<template>
  <KeepAlive include="DefaultLayout">
    <component :is="layout" @print="onPrint">
      <component
        :is="reportComponent"
        :reportResponse="reportResponse"
        @load-report="loadReport()"
      />
    </component>
  </KeepAlive>
</template>

<script>
import { DISPLAY_MODES } from "@/store/modules/application";
import DefaultLayout from "@/views/reports/layouts/Default.vue";
import PrintLayout from "@/views/reports/layouts/Print.vue";
import reportService from "@/api/reportService";
import {
  ALL_REPORT_COMPONENT_NAMES,
  ALL_REPORT_VUE_COMPONENTS
} from "@/types/allReports";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import i18n from "@/plugins/i18n";
import storageKeys from "@/utils/browserStorage/storageKeys";

export default {
  components: {
    DefaultLayout,
    PrintLayout,
    ...ALL_REPORT_VUE_COMPONENTS
  },
  props: {
    reportId: {
      type: Number
    }
  },
  data() {
    return {
      reportLoadCount: 0,
      reportResponse: {}
    };
  },
  async beforeMount() {
    this.setExportPdfTitle(this.$route.query?.pdfTitle);

    this.SET_PDF_SERVICE_INVOCATION(
      this.$route.query?.pdf?.toLowerCase() === "true"
    );

    this.SET_DISPLAY_MODE(
      this.$route.query?.pdf ? DISPLAY_MODES.PRINT : DISPLAY_MODES.NORMAL
    );
  },
  async mounted() {
    window.addEventListener("afterprint", this.onAfterPrint);
  },
  async destroyed() {
    window.removeEventListener("afterprint", this.onAfterPrint);
  },
  computed: {
    ...mapState("reports", [
      "changingReport",
      "reportLoading",
      "loadingFilters",
      "reloadReportFlag",
      "pdfServiceInvocation"
    ]),
    ...mapState("customers", ["facilityId"]),
    ...mapState("application", ["displayMode", "error"]),
    ...mapGetters("reports", ["filterModel"]),
    layout() {
      return this.displayMode === DISPLAY_MODES.NORMAL
        ? "DefaultLayout"
        : "PrintLayout";
    },
    reportComponent() {
      return ALL_REPORT_COMPONENT_NAMES[this.reportId];
    },
    changingReportOrFacility() {
      return this.changingReport || this.loadingFilters;
    }
  },
  methods: {
    ...mapMutations("application", [
      "SET_PDF_SERVICE_INVOCATION",
      "SET_DISPLAY_MODE",
      "SET_ERROR"
    ]),
    ...mapMutations("reports", [
      "setReportId",
      "setExportPdfTitle",
      "setReportLoading",
      "setReloadReportFlag",
      "setReportDateRange",
      "resetTableColumnSort",
      "resetGraphActions",
      "setSelectedData"
    ]),
    ...mapActions("reports", ["loadFilters"]),
    onAfterPrint() {
      this.SET_DISPLAY_MODE(DISPLAY_MODES.NORMAL);
    },
    onPrint({ title }) {
      this.SET_DISPLAY_MODE(DISPLAY_MODES.PRINT);
      this.setExportPdfTitle(title);
    },
    getDateTimeWithoutTimeZone(dateTime) {
      return new Date(dateTime - dateTime.getTimezoneOffset() * 60000);
    },
    async loadReport() {
      if (
        this.displayMode === DISPLAY_MODES.PRINT &&
        this.reportLoadCount > 0
      ) {
        return;
      }

      this.setReportLoading(true);

      try {
        const filterModel = { ...this.filterModel };

        if (
          filterModel.reportDateRange &&
          filterModel.reportDateRange === "custom"
        ) {
          filterModel.startDate = this.getDateTimeWithoutTimeZone(
            filterModel.startDate
          );
          filterModel.endDate = this.getDateTimeWithoutTimeZone(
            filterModel.endDate
          );
        }

        this.reportResponse = await reportService.get({
          reportId: this.reportId,
          filterViewModel: filterModel
        });
        this.reportResponse.reportId = this.reportId;
        this.setReportDateRange(this.reportResponse.formattedReportDateRange);
        this.reportLoadCount = this.reportLoadCount + 1;
        this.setReportLoading(false);
      } catch (err) {
        if (err !== "Request canceled") {
          this.setReportLoading(false);
          this.SET_ERROR(
            {
              message: i18n.t("reports.errors.something-went-wrong")
            },
            { root: true }
          );
        }
      }
    },
    resetReportInteractables() {
      this.resetTableColumnSort();
      this.resetGraphActions();
      this.setSelectedData([]);
    },
    clearRoute() {
      if (this.reportLoadCount === 0) {
        return;
      }
      const { reportId } = this.$route.query;
      this.$router
        .push({
          query: { reportId }
        })
        .catch(() => {});
    }
  },
  watch: {
    facilityId: {
      immediate: true,
      handler() {
        if (this.facilityId) {
          this.loadFilters();
        }
      }
    },
    changingReportOrFacility() {
      if (this.changingReportOrFacility) {
        this.clearRoute();
        this.resetReportInteractables();
      } else {
        this.loadReport();
      }
    },
    filterModel() {
      if (!this.changingReportOrFacility) {
        this.clearRoute();
        this.loadReport();
      }
    },
    reloadReportFlag(value) {
      if (value) {
        this.loadReport();
        this.setReloadReportFlag(false);
      }
    },
    reportId: {
      immediate: true,
      handler(value) {
        if (value) {
          localStorage.setItem(storageKeys.report.id, value);
          this.setReportId(value);
        }
      }
    }
  }
};
</script>
