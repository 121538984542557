export default {
  preferences: {
    language: "preferred-language"
  },
  customers: {
    id: "customer-id"
  },
  facilities: {
    id: "facility-id"
  },
  user: {
    id: "user-id"
  },
  report: {
    id: "report-id"
  },
  expiration: {
    lastCallTime: "lastCallTime"
  }
};
