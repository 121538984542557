import IndividualPerformance from "@/views/reports/IMS/IndividualPerformance.vue";
import JobRolePerformance from "@/views/reports/IMS/JobRolePerformance.vue";
import GroupPerformance from "@/views/reports/IMS/GroupPerformance.vue";
import ProductUsage from "@/views/reports/IMS/ProductUsage.vue";
import IndividualTrends from "@/views/reports/IMS/IndividualTrends.vue";

export const IMS_VUE_COMPONENTS = {
  IndividualPerformance,
  JobRolePerformance,
  GroupPerformance,
  ProductUsage,
  IndividualTrends
};
