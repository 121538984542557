<template>
  <div>
    <report-summary :cells="cells" />
    <report-table :headers="headers" :items="items" />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import { sumEntries, createPercentage } from "@/views/reports/utils/calculate";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportTable
  },
  data() {
    return {
      compatibleReportId: 10,
      reportFilters: [
        "hygieneProtocolOpportunities",
        "obvJobRoles",
        "locations",
        "shifts",
        "timeframe",
        "activeObserversOnly"
      ],
      hiddenReportActions: ["saveSlide"],
      items: [],
      headers: [
        {
          text: i18n.t("user.department"),
          value: "department",
          sortable: true
        },
        {
          text: i18n.t("reports.table.observer"),
          value: "fullName",
          sortable: true
        },
        {
          text: i18n.t("reports.compliant"),
          value: "compliantObservations",
          sortable: true
        },
        {
          text: i18n.t("reports.table.non-compliant"),
          value: "nonCompliantObservations",
          sortable: true
        },
        {
          text: i18n.t("reports.total"),
          value: "totalObservations",
          sortable: true
        },
        {
          text: i18n.t("reports.table.rate"),
          value: "complianceIndex",
          sortable: true,
          display: "Percentage"
        }
      ],
      cells: []
    };
  },
  methods: {
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      const data = this.reportResponse.dataPoints;

      this.items = data;

      const totalCompliant = sumEntries({
        entries: this.items,
        inputField: "compliantObservations"
      });
      const totalNonCompliant = sumEntries({
        entries: this.items,
        inputField: "nonCompliantObservations"
      });
      const totalObservations = sumEntries({
        entries: this.items,
        inputField: "totalObservations"
      });
      const totalPercentage = createPercentage({
        dividend: totalCompliant,
        divisor: totalObservations
      });

      this.cells = [
        {
          text: i18n.t("reports.total-compliant"),
          value: totalCompliant,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total-non-compliant"),
          value: totalNonCompliant,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total-observations"),
          value: totalObservations,
          display: "Locale"
        },
        {
          text: i18n.t("reports.performance-rate"),
          value: totalPercentage,
          display: "Percentage"
        }
      ];
    }
  }
};
</script>
