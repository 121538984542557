<template>
  <div>
    <report-summary :cells="cells" />
    <report-custom-legend :items="customLegend" />
    <report-fixed-legend :goal="goal" :hideComplianceLegend="true" />
    <report-graph :chartData="chartData" />
    <report-table
      :showSearch="false"
      :headers="tableData.headers"
      :items="tableData.items"
    />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportCustomLegend from "@/views/reports/components/reportSections/ReportCustomLegend.vue";
import ReportFixedLegend from "@/views/reports/components/reportSections/ReportFixedLegend.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import { sumEntries, createPercentage } from "@/views/reports/utils/calculate";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportCustomLegend,
    ReportFixedLegend,
    ReportGraph,
    ReportTable
  },
  data() {
    return {
      compatibleReportId: 9,
      reportFilters: [
        "hygieneProtocolOpportunities",
        "obvJobRoles",
        "locations",
        "shifts",
        "timeframe"
      ],
      hiddenReportActions: ["saveSlide", "csvExport"],
      cells: [],
      customRatesSwitch: i18n.t("reports.graph.display-percentage"),
      customLegend: [
        {
          text: i18n.t("reports.outcomes.rub"),
          color: "primary",
          icon: "mdi-square"
        },
        {
          text: i18n.t("reports.outcomes.wash"),
          color: "tertiary",
          icon: "mdi-square"
        }
      ],
      goal: 0,
      items: [],
      chartData: {},
      tableData: {
        headers: [
          {
            text: i18n.t("filters.hygieneProtocolOpportunities"),
            value: "name",
            sortable: true
          },
          {
            text: i18n.t("reports.outcomes.wash"),
            value: "wash",
            sortable: true
          },
          {
            text: i18n.t("reports.outcomes.rub"),
            value: "rub",
            sortable: true
          },
          {
            text: i18n.t("reports.table.none"),
            value: "none",
            sortable: true
          },
          {
            text: i18n.t("reports.total"),
            value: "totalEventCount",
            sortable: true
          }
        ],
        items: []
      }
    };
  },
  methods: {
    createSummary() {
      const data = this.reportResponse.moments;
      const totalCompliant = sumEntries({
        entries: data,
        inputField: "totalCompliantCount"
      });
      const totalEvents = sumEntries({
        entries: data,
        inputField: "totalEventCount"
      });
      const totalPercentage = createPercentage({
        dividend: totalCompliant,
        divisor: totalEvents
      });

      this.cells = [
        {
          text: i18n.t("reports.compliant"),
          value: totalCompliant,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total"),
          value: totalEvents,
          display: "Locale"
        },
        {
          text: i18n.t("reports.performance-rate"),
          value: totalPercentage,
          display: "Percentage"
        }
      ];
    },
    createGraph() {
      const items = this.reportResponse.moments.map((item) => {
        return {
          name: item.name,
          washComplianceIndex: createPercentage({
            dividend: item.wash,
            divisor: item.totalEventCount
          }),
          rubComplianceIndex: createPercentage({
            dividend: item.rub,
            divisor: item.totalEventCount
          })
        };
      });

      const data = [];
      items.forEach((item) => {
        data.push({
          name: item.name,
          outcome: i18n.t("reports.outcomes.rub"),
          complianceIndex: item.rubComplianceIndex
        });
        data.push({
          name: item.name,
          outcome: i18n.t("reports.outcomes.wash"),
          complianceIndex: item.washComplianceIndex
        });
      });

      this.items = data;

      const builder = new ChartBuilder({
        displayLegend: false,
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });
      builder
        .chartCategorized({
          type: "bar",
          typeOptions: {
            stacked: true
          },
          items: this.items,
          valueField: "complianceIndex",
          valueFieldType: GraphDataTypes.FIXED,
          datasetField: "outcome",
          categoryField: "name",
          valueScaleOptions: {
            label: i18n.t("reports.compliance"),
            ticks: {
              format: "${value}%"
            }
          }
        })
        .valueScale.showGoal(this.goal);
      this.chartData = builder.build();
    },
    handleReportResponse() {
      const data = this.reportResponse.moments;

      if (!data) {
        return;
      }

      this.createSummary();
      this.goal = this.reportResponse.performanceGoal;
      this.createGraph();
      this.tableData.items = data;
    }
  }
};
</script>
