import Vue from "vue";

export function makeCRUDModule({
  service,
  normalizer,
  stateMerge = {},
  transformer
} = {}) {
  return {
    actions: {
      load: async ({ commit }, servicePayload) => {
        if (!service) {
          throw new Error("Service required");
        }

        let items = servicePayload
          ? await service.list(servicePayload)
          : await service.list();
        const response = items;

        commit("clear");

        if (transformer && typeof transformer === "function") {
          items = transformer(items);
        }
        items.forEach((item) => {
          commit("add", normalizer ? normalizer(item) : item);
        });

        return response;
      }
    },
    getters: {
      list: (state) => {
        return state.allIds.map((id) => ({ id, ...state.byId[id] }));
      },
      find: (state, getters) => (query) => {
        if (!query) {
          return getters.orderedItems;
        }
        const normalizeText = (str = "") => str.trim().toLowerCase();
        const normalizedQuery = normalizeText(query);

        return getters.orderedItems.filter((item) =>
          normalizeText(item.text).includes(normalizedQuery)
        );
      }
    },
    mutations: {
      set: (state, { field, value }) => {
        Vue.set(state, field, value);
      },
      add: (state, item) => {
        Vue.set(state.byId, item.id, item);
        if (state.allIds.includes(item.id)) {
          return;
        }
        state.allIds.push(item.id);
      },
      clear: (state) => {
        Object.assign(state, {
          ...stateMerge,
          byId: {},
          allIds: []
        });
      }
    },
    namespaced: true,
    state: {
      byId: {},
      allIds: [],
      ...stateMerge
    }
  };
}

export function makeStaticResourceModule({
  service,
  normalizer,
  getters
} = {}) {
  return {
    actions: {
      load: async ({ commit }, options = {}) => {
        if (!service) {
          throw new Error("Service required");
        }

        const resource = await service.get(options);
        commit("set", normalizer ? normalizer(resource) : resource);
      }
    },
    mutations: {
      set: (state, resource) => {
        Object.assign(state, resource);
      }
    },
    namespaced: true,
    state: () => ({}),
    getters: getters
  };
}
