<template>
  <div>
    <report-table
      :customHeaderSort="customHeaderSort"
      :headers="headers"
      :items="items"
      :showTotalRow="true"
      :showCustomHeader="true"
    >
      <template v-slot:customHeader>
        <thead v-if="headers.length" class="v-data-table-header">
          <tr class="smartlink-report-table-preheader">
            <template v-for="(header, index) in headers">
              <th
                @click="sortColumn(header.value)"
                class="smartlink-report-tall-header"
                rowspan="2"
                v-if="index < 2"
                :key="header.text"
              >
                {{ header.text }}
                <v-icon
                  v-if="header.value === customHeaderSort.value"
                  color="black"
                  small
                >
                  {{
                    customHeaderSort.columnDescending
                      ? "mdi-arrow-down"
                      : "mdi-arrow-up"
                  }}
                </v-icon>
              </th>
            </template>
            <th
              v-for="header in preHeaders"
              :key="header.text"
              :colspan="header.colspan"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr class="smartlink-report-table-primary-header">
            <template v-for="(header, index) in headers">
              <th
                @click="sortColumn(header.value)"
                v-if="index > 1"
                :key="`${header.text}${index}`"
              >
                {{ header.text }}
                <v-icon
                  v-if="header.value === customHeaderSort.value"
                  color="black"
                  small
                >
                  {{
                    customHeaderSort.columnDescending
                      ? "mdi-arrow-down"
                      : "mdi-arrow-up"
                  }}
                </v-icon>
              </th>
            </template>
          </tr>
        </thead>
      </template>
      <template v-slot:totalRow>
        <tr class="smartlink-table-total-row">
          <td>{{ $t("reports.total") }}</td>
          <td></td>
          <template v-for="(column, index) in totalRowColumns">
            <td :key="`percentage${index}`">
              <display-formatter-percentage :value="column.percentage" />
            </td>
            <td :key="`number${index}`">{{ column.number }}</td>
          </template>
        </tr>
      </template>
    </report-table>
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";
import customHeaderSortMixin from "@/views/reports/mixins/customHeaderSortMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import { FILTERS } from "@/types/filters";
import { activityBy } from "@/types/activityBy";
import { monthIndexToString } from "@/types/monthTranslator";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import { buildTotalRow } from "@/views/reports/OBV/utils/HHActivityReportTotalBuilder";
import { variableColumnGenerator } from "@/views/reports/utils/group";
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";
import DisplayFormatterPercentage from "@/views/reports/components/reportSections/displayFormatters/DisplayFormatterPercentage.vue";

export default {
  mixins: [reportMixin, customHeaderSortMixin],
  components: {
    ReportTable,
    DisplayFormatterPercentage
  },
  data() {
    return {
      compatibleReportId: 17,
      reportFilters: [
        "activityBy",
        "hygieneProtocolOpportunities",
        "obvJobRoles",
        "locations",
        "shifts"
      ],
      hiddenReportActions: ["saveSlide"],
      customHeaderSort: {},
      items: [],
      preHeaders: [],
      headers: [],
      totalRowColumns: []
    };
  },
  computed: {
    ...mapState(`filters/${FILTERS.ACTIVITYBY}`, {
      activityBy: (state) => state.activeIds
    })
  },
  mounted() {
    this.setCustomHeaderSort("groupName");
  },
  methods: {
    getResponseDate() {
      const currentMonth = this.reportResponse.facilityMonth - 1;

      return {
        currentMonth,
        currentYear: this.reportResponse.facilityYear,
        currentQuarter: Math.floor(currentMonth / 3 + 1)
      };
    },
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.csvHeader);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    generatePreHeaders() {
      const responseDate = this.getResponseDate();

      const preHeaders = [
        {
          text: monthIndexToString[responseDate.currentMonth],
          colspan: 2
        }
      ];

      for (let i = 0; i < this.reportResponse.intervalCount; i++) {
        let text;
        if (i === 0) {
          text = `Q${responseDate.currentQuarter} ${i18n.t(
            "reports.table.to-date"
          )}`;
        } else if (responseDate.currentQuarter - i > 0) {
          text = `Q${responseDate.currentQuarter - i} ${
            responseDate.currentYear
          }`;
        } else {
          text = `Q${responseDate.currentQuarter - i + 4} ${
            responseDate.currentYear - 1
          }`;
        }

        preHeaders.push({
          text: text,
          colspan: 2
        });
      }

      preHeaders.push({
        text: i18n.t("reports.table.year-to-date"),
        colspan: 2
      });

      this.preHeaders = preHeaders;
    },
    generateHeaders() {
      const responseDate = this.getResponseDate();

      const activtyByText =
        this.activityBy === activityBy.unit
          ? i18n.t("locations.locationlevelunit-singular")
          : i18n.t("job-roles.singular");
      const monthText = monthIndexToString[responseDate.currentMonth];

      const headers = [
        {
          value: "groupName",
          text: activtyByText,
          csvHeader: activtyByText
        },
        {
          text: i18n.t("reports.table.quarterly-trend"),
          csvHeader: i18n.t("reports.table.quarterly-trend"),
          value: "trend",
          display: "Trend"
        },
        {
          text: "%",
          csvHeader: `${monthText} %`,
          value: "column0CompliancePercentage",
          display: "Percentage"
        },
        {
          text: "#",
          csvHeader: `${monthText} #`,
          value: "column0Opportunities",
          display: "Number"
        }
      ];

      for (let i = 0; i < this.reportResponse.intervalCount; i++) {
        let csvHeader;
        if (responseDate.currentQuarter - i > 0) {
          csvHeader = `Q${responseDate.currentQuarter - i} ${
            responseDate.currentYear
          }`;
        } else {
          csvHeader = `Q${responseDate.currentQuarter - i + 4} ${
            responseDate.currentYear - 1
          }`;
        }

        headers.push(
          {
            text: "%",
            csvHeader: `${csvHeader} %`,
            value: `column${i + 1}CompliancePercentage`,
            display: "Percentage"
          },
          {
            text: "#",
            csvHeader: `${csvHeader} #`,
            display: "Number",
            value: `column${i + 1}Opportunities`
          }
        );
      }

      headers.push(
        {
          text: "%",
          csvHeader: `${i18n.t("reports.table.year-to-date")} %`,
          value: `column${
            this.reportResponse.intervalCount + 1
          }CompliancePercentage`,
          display: "Percentage"
        },
        {
          text: "#",
          display: "Number",
          csvHeader: `${i18n.t("reports.table.year-to-date")} #`,
          value: `column${this.reportResponse.intervalCount + 1}Opportunities`
        }
      );

      this.headers = headers;
    },
    sortColumn(headerValue) {
      const columnDescending = this.customHeaderSort.value === headerValue;
      const clearSort =
        this.customHeaderSort.columnDescending && columnDescending;

      this.customHeaderSort = {
        value: clearSort ? null : headerValue,
        columnDescending,
        clearSort
      };
    },
    handleReportResponse() {
      const reportResponse = this.reportResponse;

      this.items = variableColumnGenerator({
        items: reportResponse.dataPoints,
        staticColumns: ["groupName", "trend"]
      });

      this.generateHeaders();
      this.generatePreHeaders();

      const numberOfColumns = reportResponse.intervalCount + 2;

      this.totalRowColumns = buildTotalRow(this.items, numberOfColumns);
    }
  }
};
</script>

<style scoped lang="postcss">
.smartlink-report-table-preheader th {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  text-align: center !important;
}
.smartlink-report-table-primary-header th {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}
.smartlink-report-tall-header {
  border-left: 1px solid #eee;
}
</style>
