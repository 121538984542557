import i18n from "@/plugins/i18n";

export const stringVariableSubstitute = ({ data = {}, str = "" } = {}) => {
  const regEx = new RegExp(/\${([^{}]*)}/, "g");
  let match;

  while ((match = regEx.exec(str)) !== null) {
    regEx.lastIndex = 0;
    str = str.replace(match[0], data[match[1]]);
  }

  return str;
};

export const stringTranslationSubstitute = (str = "") => {
  const regEx = new RegExp(/\$t{([^{}]*)}/, "g");
  let match;

  while ((match = regEx.exec(str)) !== null) {
    regEx.lastIndex = 0;
    str = str.replace(match[0], i18n.t(match[1]));
  }

  return str;
};
