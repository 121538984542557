export const SA_REPORTS = {
  FACILITY_SUMMARY: 43,
  ALERT_STATUS: 32,
  DEVICE_ALERTS: 22,
  REFILL_ALERTS: 24,
  OFFLINE_DEVICES: 42,
  BATTERY_ALERTS: 23,
  EXPIRATION_ALERTS: 34,
  DISPENSER_USAGE: 31
};
export const SA_REPORT_COMPONENT_NAMES = {
  [SA_REPORTS.FACILITY_SUMMARY]: "FacilitySummary",
  [SA_REPORTS.ALERT_STATUS]: "AlertStatus",
  [SA_REPORTS.DEVICE_ALERTS]: "DeviceAlerts",
  [SA_REPORTS.REFILL_ALERTS]: "RefillAlerts",
  [SA_REPORTS.OFFLINE_DEVICES]: "OfflineDevices",
  [SA_REPORTS.BATTERY_ALERTS]: "BatteryAlerts",
  [SA_REPORTS.EXPIRATION_ALERTS]: "ExpirationAlerts",
  [SA_REPORTS.DISPENSER_USAGE]: "DispenserUsage"
};
export const SA_REPORT_DISPLAY_NAMES = {
  [SA_REPORTS.FACILITY_SUMMARY]: "reports.sa.facility-summary",
  [SA_REPORTS.ALERT_STATUS]: "reports.sa.alert-status",
  [SA_REPORTS.DEVICE_ALERTS]: "reports.sa.device-alerts",
  [SA_REPORTS.REFILL_ALERTS]: "reports.sa.refill-alerts",
  [SA_REPORTS.OFFLINE_DEVICES]: "reports.sa.offline-devices",
  [SA_REPORTS.BATTERY_ALERTS]: "reports.sa.battery-alerts",
  [SA_REPORTS.EXPIRATION_ALERTS]: "reports.sa.expiration-alerts",
  [SA_REPORTS.DISPENSER_USAGE]: "reports.sa.dispenser-usage"
};
export const SA_RAW_EXPORT_NAMES = {
  [SA_REPORTS.FACILITY_SUMMARY]: "SA Facility Summary Report",
  [SA_REPORTS.ALERT_STATUS]: "SA Alert Status Report",
  [SA_REPORTS.DEVICE_ALERTS]: "SA Device Alerts Report",
  [SA_REPORTS.REFILL_ALERTS]: "SA Refill Alerts Report",
  [SA_REPORTS.OFFLINE_DEVICES]: "SA Offline Devices Report",
  [SA_REPORTS.BATTERY_ALERTS]: "SA Battery Status",
  [SA_REPORTS.EXPIRATION_ALERTS]: "SA Expiration Alerts Report",
  [SA_REPORTS.DISPENSER_USAGE]: "SA Dispenser Usage Report"
};
