<template>
  <div>
    <h1>{{ $t("system-status.history-header") }}</h1>
    <div>
      <v-data-table
        class="small-headers"
        :items="history"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :headers="headers"
        :search="search"
        item-key="id"
        :loading="loading"
        :loading-text="$t('system-status.loading')"
        :no-data-text="$t('system-status.empty')"
        :item-class="highlightRow"
        multi-sort
      >
        <template v-slot:top>
          <div
            class="
              tw-flex
              tw-justify-between
              tw-items-center
              tw-sticky
              tw-top-0
              tw-bg-white
            "
            style="z-index: 2"
          >
            <v-text-field
              v-model="search"
              :label="`${$t('ui.input-select.search')}`"
              class="tw-mr-4"
              clearable
              prepend-inner-icon="mdi-magnify"
              autofocus
            />
            <v-btn
              class="tw-max-w-xs"
              primary
              color="primary"
              @click="getHistory"
            >
              <v-icon> mdi-refresh </v-icon>
              {{ $t("ui.actions.refresh") }}
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.insertTimestampUtc`]="{ item }">
          <td>
            {{ formatTimestamp(item.insertTimestampUtc) }}
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import systemStatusService from "@/api/systemStatusService";
import i18n from "@/plugins/i18n";
export default {
  data() {
    return {
      headers: [
        {
          text: i18n.t("system-status.headers.status-type"),
          value: "statusType",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.status-qualifier"),
          value: "statusQualifier",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.status-value"),
          value: "statusValue",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.min-threshold"),
          value: "minThreshold",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.max-threshold"),
          value: "maxThreshold",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("system-status.headers.inserted"),
          value: "insertTimestampUtc",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      loading: true,
      search: "",
      history: [],
      itemsPerPage: 500,
      itemsPerPageOptions: [50, 100, 500, -1]
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      try {
        this.loading = true;
        const history = await systemStatusService.getHistory();
        this.history = history.sort(
          (a, b) => a.insertTimestampUtc - b.insertTimestampUtc
        );
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("system-status.error-messages.get-history")
        });
      } finally {
        this.loading = false;
      }
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        return new Date(`${timestamp}Z`).toLocaleDateString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "2-digit",
          hourCycle: "h12"
        });
      }
      return "";
    },
    highlightRow(item) {
      const rowClass = "highlighted-row";
      const today = new Date();
      const fifteenMinutesAgo = new Date(
        today.setMinutes(today.getMinutes() - 15)
      );
      const itemTimeStamp = new Date(`${item.insertTimestampUtc}Z`);
      return itemTimeStamp.getTime() > fifteenMinutesAgo.getTime()
        ? rowClass
        : "";
    }
  }
};
</script>

<style lang="postcss" scoped>
:deep(.highlighted-row) {
  background-color: rgb(250, 219, 219) !important;
}
</style>
