<template>
  <div>
    <confirmation-dialog
      @toggle-confirmation-dialog="toggleConfirmationDialog"
      @action-confirmed="unsubscribeAlertSubscription"
      :confirmationDialogData="confirmationDialogData"
    />
    <v-data-table
      :loading="loading"
      :items="items"
      :headers="headers"
      :loading-text="$t('alert-subscriptions.gathering-subscribed-alerts-data')"
      :no-data-text="
        $t('alert-subscriptions.no-subscribed-alert-subscriptions')
      "
    >
      <template v-slot:top>
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            @focus="trackSearchAnalytics()"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.unsubscribe="{ item }">
        <v-btn @click="toggleConfirmationDialog(item)" color="warning">{{
          $t("alert-subscriptions.unsubscribe")
        }}</v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ConfirmationDialog from "@/views/alertSubscriptions/components/ConfirmationDialog.vue";
import alertSubscriptionService from "@/api/alertSubscriptionService";
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  components: {
    ConfirmationDialog
  },
  data: () => ({
    loading: true,
    confirmationDialogData: {
      isActive: false,
      name: "",
      title: i18n.t(
        "alert-subscriptions.confirmation-dialog.unsubscribe-title"
      ),
      content: i18n.t(
        "alert-subscriptions.confirmation-dialog.unsubscribe-content"
      ),
      isInstantAlert: false,
      confirmButton: i18n.t("alert-subscriptions.unsubscribe")
    },
    localeStringOptions: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit"
    },
    items: [],
    search: ""
  }),
  computed: {
    ...mapState("customers", ["facilityId"]),
    ...mapState("preferences", ["language"]),
    headers() {
      return [
        {
          text: i18n.t("alert-subscriptions.name"),
          value: "name",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.type"),
          value: "type",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.last-alert"),
          value: "lastAlertTimeStamp",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.frequency"),
          value: "frequency",
          sortable: true
        },
        {
          text: i18n.t("alert-subscriptions.unsubscribe"),
          value: "unsubscribe",
          sortable: false
        }
      ];
    }
  },
  mounted() {
    if (this.facilityId) {
      this.gatherSubscribedAlertSubscriptions(this.facilityId);
    }
  },
  methods: {
    async gatherSubscribedAlertSubscriptions(facilityId) {
      this.loading = true;

      const response = await alertSubscriptionService.listSubscribedAlerts(
        facilityId
      );

      const language = this.language;
      const localeStringOptions = this.localeStringOptions;

      this.items = response.map(function (item) {
        item.frequency = item.isAlarm
          ? i18n.t("alert-subscriptions.instant")
          : item.frequency;

        item.lastAlertTimeStamp = item.lastAlertTimeStamp
          ? new Date(item.lastAlertTimeStamp).toLocaleString(
              language,
              localeStringOptions
            )
          : "";

        return item;
      });

      this.loading = false;
    },
    toggleConfirmationDialog(item) {
      this.confirmationDialogData.isActive =
        !this.confirmationDialogData.isActive;
      this.confirmationDialogData.id = this.confirmationDialogData.id
        ? null
        : item.unsubscribeKey;
      this.confirmationDialogData.name = this.confirmationDialogData.name
        ? ""
        : item.name;
      this.confirmationDialogData.isInstantAlert = item?.isAlarm;

      const deleteDialogStatus = this.confirmationDialogData.isActive
        ? "Open"
        : "Close";

      this.$appInsights?.trackEvent({
        name: `${deleteDialogStatus} Alert Subscription Unsubscribe Dialog`
      });
    },
    async unsubscribeAlertSubscription() {
      const item = this.confirmationDialogData;
      const viewModel = {
        unsubscribeKey: item.id,
        isInstantAlert: item.isInstantAlert,
        facilityId: this.facilityId
      };

      try {
        await alertSubscriptionService.unsubscribeAlertSubscription(viewModel);
        this.removeItemById(item.id);
        this.$appInsights?.trackEvent({
          name: `Alert Subscription Successfully Unsubscribed`
        });
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "alert-subscriptions.error-messages.unsubscribe-alert-subscription"
          )
        });
      }

      this.toggleConfirmationDialog();
    },
    removeItemById(id) {
      const index = this.items.findIndex((item) => item.id === id);

      this.items.splice(index, 1);
    },
    trackSearchAnalytics() {
      this.$appInsights?.trackEvent({
        name: "Alert Subscription Search Interaction"
      });
    }
  },
  watch: {
    async facilityId(value) {
      this.gatherSubscribedAlertSubscriptions(value);
    }
  }
};
</script>
