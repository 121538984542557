import { post } from "./apiClient";
import PERMISSION_CHECKS from "@/types/permissions";

const permissionsWithName = Object.fromEntries(
  Object.entries(PERMISSION_CHECKS).map((e) => [e[0], { name: e[0], ...e[1] }])
);

const permissionRequests = [
  permissionsWithName.activateDeactivateCustomers,
  permissionsWithName.addCustomers,
  permissionsWithName.addFacilities,
  permissionsWithName.administerCustomer,
  permissionsWithName.administerServiceAlerts,
  permissionsWithName.amsReports,
  permissionsWithName.archiveFacilities,
  permissionsWithName.cloneJobRoles,
  permissionsWithName.cloneObservationNotes,
  permissionsWithName.createImageSlides,
  permissionsWithName.diagnostics,
  permissionsWithName.downloads,
  permissionsWithName.editFacilities,
  permissionsWithName.imsReports,
  permissionsWithName.legacySlideshows,
  permissionsWithName.manageAdminUsers,
  permissionsWithName.manageAlerts,
  permissionsWithName.manageDevices,
  permissionsWithName.manageEmployees,
  permissionsWithName.manageJobRoles,
  permissionsWithName.manageLicenses,
  permissionsWithName.manageShifts,
  permissionsWithName.manageUsers,
  permissionsWithName.obvReports,
  permissionsWithName.obvSystem,
  permissionsWithName.reportSubscriptions,
  permissionsWithName.saReports,
  permissionsWithName.slideshows,
  permissionsWithName.systemStatus,
  permissionsWithName.systemStatusHistory,
  permissionsWithName.toggleCustomObservationNotes,
  permissionsWithName.deleteLocations,
  permissionsWithName.viewAllAlertSubscriptions
];

export default {
  async get() {
    return await post(
      "user/permissions",
      JSON.stringify({ permissionRequests })
    );
  }
};
