import usFlag from "@/assets/images/flags/us.svg";
import caFlag from "@/assets/images/flags/ca.svg";
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    languages: [
      {
        text: "United States",
        value: "en-US",
        icon: usFlag
      },
      {
        text: "Canada",
        value: "fr-CA",
        icon: caFlag
      }
    ]
  }),
  computed: {
    currentLanguage: {
      get() {
        return this.languages.find((i) => i.value === this.language);
      },
      set(lang) {
        this.changeLanguage({ language: lang, i18n: this.$i18n });
        location.reload();
      }
    },
    ...mapState("preferences", ["language", "loading"])
  },
  methods: {
    ...mapActions("preferences", ["changeLanguage"])
  }
};
