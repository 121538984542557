<template>
  <component
    :is="$route.meta.component"
    :facility="facility"
    :facilityId="facilityId"
    :reportTypes="reportTypes"
    :reportSubscriptionId="reportSubscriptionId"
  />
</template>

<script>
import List from "@/views/reportSubscriptions/List";
import Edit from "@/views/reportSubscriptions/Edit";
import reportSubscriptionService from "@/api/reportSubscriptionService";
import { FILTERS } from "@/types/filters";
import { mapState } from "vuex";

export default {
  components: {
    Edit,
    List
  },
  props: {
    reportSubscriptionId: {
      type: Number
    }
  },
  data: () => ({
    facility: {},
    reportTypes: []
  }),
  computed: {
    ...mapState("customers", ["facilityId"])
  },
  mounted() {
    if (this.facilityId) {
      this.setupFacility(this.facilityId);
    }
  },
  methods: {
    async getFacilityLocations(facilityId) {
      await this.$store.dispatch(`filters/${FILTERS.LOCATIONS}/load`, {
        facilityId
      });
    },
    async getReportTypes(facilityId) {
      this.reportTypes = await reportSubscriptionService.getReportTypes(
        facilityId
      );
    },
    async setupFacility(id) {
      await Promise.all([
        this.getFacilityLocations(id),
        this.getReportTypes(id)
      ]);

      this.facility = {
        id,
        reportTypes: this.reportTypes
      };
    }
  },
  watch: {
    async facilityId(value) {
      this.setupFacility(value);
    }
  }
};
</script>
