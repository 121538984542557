import { MONTHS } from "@/types/months";
import i18n from "@/plugins/i18n";

export default {
  list() {
    const monthValues = [
      {
        id: MONTHS.JANUARY,
        text: i18n.t("months.full.january")
      },
      {
        id: MONTHS.FEBRUARY,
        text: i18n.t("months.full.february")
      },
      {
        id: MONTHS.MARCH,
        text: i18n.t("months.full.march")
      },
      {
        id: MONTHS.APRIL,
        text: i18n.t("months.full.april")
      },
      {
        id: MONTHS.MAY,
        text: i18n.t("months.full.may")
      },
      {
        id: MONTHS.JUNE,
        text: i18n.t("months.full.june")
      },
      {
        id: MONTHS.JULY,
        text: i18n.t("months.full.july")
      },
      {
        id: MONTHS.AUGUST,
        text: i18n.t("months.full.august")
      },
      {
        id: MONTHS.SEPTEMBER,
        text: i18n.t("months.full.september")
      },
      {
        id: MONTHS.OCTOBER,
        text: i18n.t("months.full.october")
      },
      {
        id: MONTHS.NOVEMBER,
        text: i18n.t("months.full.november")
      },
      {
        id: MONTHS.DECEMBER,
        text: i18n.t("months.full.december")
      }
    ];

    return new Promise((res) => res(monthValues));
  }
};
