import i18n from "@/plugins/i18n";
import cloneDeep from "lodash/cloneDeep";

export default {
  getLocationsHierarchy({ byId, locationLevel, facilityHierarchy }) {
    const clonedHierarchy = cloneDeep(facilityHierarchy);

    if (locationLevel) {
      removeChildrenFromLocationAtLevel(clonedHierarchy, locationLevel);
    }

    const levels = {};
    for (const item of Object.values(byId)) {
      const location = cloneDeep(item);
      if (levels[location.level]) {
        levels[location.level].push(location);
      } else {
        levels[location.level] = [location];
      }
    }

    return {
      items: clonedHierarchy.childLocations,
      levels
    };
  },
  getLocationsSelectedText(state) {
    const { activeIds, byId, hierarchy } = state;

    if (!hierarchy || !hierarchy.levels) {
      return;
    }
    if (!activeIds || (activeIds instanceof Array && activeIds.length === 0)) {
      return i18n.t("ui.none-selected");
    }

    const levelInfo = {};
    for (const [levelNum, locations] of Object.entries(hierarchy.levels)) {
      if (levelNum > 1 && locations && locations.length) {
        levelInfo[levelNum] = {
          count: 0,
          totalCount: locations.length,
          resourceKey: locations[0].levelNameResourceKey
        };
      }
    }
    activeIds.forEach((id) => {
      levelInfo[byId[id].level].count++;
    });

    const levelText = [];
    Object.keys(levelInfo)
      .sort()
      .forEach((level) => {
        const levelCount = levelInfo[level].count;
        const pluralizedName =
          levelCount === 1
            ? i18n.t(
                `locations.${levelInfo[
                  level
                ].resourceKey.toLocaleLowerCase()}-singular`
              )
            : i18n.t(
                `locations.${levelInfo[
                  level
                ].resourceKey.toLocaleLowerCase()}-plural`
              );
        levelText.push(`${levelCount} ${pluralizedName}`);
      });
    return levelText.join(", ");
  },
  getLocationsFilterText(state, getters) {
    const { activeIds, byId, hierarchy } = state;
    const { selectableIds } = getters;
    if (!hierarchy || !hierarchy.levels) {
      return null;
    }
    if (!activeIds || (activeIds instanceof Array && activeIds.length === 0)) {
      return i18n.t("ui.none-selected");
    }
    if (selectableIds.length === activeIds.length) {
      return i18n.t("ui.all-selected");
    }

    const levelInfo = {};
    for (const [levelNum, locations] of Object.entries(hierarchy.levels)) {
      if (levelNum > 1 && locations && locations.length) {
        levelInfo[levelNum] = {
          count: 0,
          totalCount: locations.length,
          name: locations[0].levelName,
          resourceKey: locations[0].levelNameResourceKey
        };
      }
    }

    activeIds.forEach((id) => {
      levelInfo[byId[id].level].count++;
    });

    const levelText = [];
    Object.keys(levelInfo)
      .sort()
      .forEach((level) => {
        const totalLevelCount = levelInfo[level].totalCount;
        const countOf = i18n.t("count.count-of-total", {
          count: levelInfo[level].count,
          total: totalLevelCount
        });
        const pluralizedName =
          totalLevelCount === 1
            ? i18n.t(
                `locations.${levelInfo[
                  level
                ].resourceKey.toLocaleLowerCase()}-singular`
              )
            : i18n.t(
                `locations.${levelInfo[
                  level
                ].resourceKey.toLocaleLowerCase()}-plural`
              );
        levelText.push(`${countOf} ${pluralizedName}`);
      });
    const displayText = levelText.join(", ");
    return displayText;
  },
  getLocationsFilterTextAbbreviated(state, getters) {
    const { activeIds } = state;
    const { selectableIds } = getters;
    if (!selectableIds || !selectableIds.length) {
      return null;
    }
    if (!activeIds || (activeIds instanceof Array && activeIds.length === 0)) {
      return i18n.t("ui.none-selected");
    }
    if (selectableIds.length === activeIds.length) {
      return i18n.t("ui.all-selected");
    }
    const totalCount = selectableIds.length;
    const selectedCount = activeIds.length;
    const locationList = firstNlocations(state, 10)
      .map((location) => location.text)
      .join(", ");
    return `${i18n.t("count.count-of-total", {
      count: selectedCount,
      total: totalCount
    })}: ${locationList}`;
  },
  getLocationsSelectedTextDetails(state) {
    const { activeIds, hierarchy } = state;
    if (!hierarchy || !hierarchy.levels) {
      return [];
    }
    if (!activeIds || (activeIds instanceof Array && activeIds.length === 0)) {
      return [];
    }

    const levelInfo = {};
    for (const [levelNum, locations] of Object.entries(hierarchy.levels)) {
      if (levelNum > 1 && locations && locations.length) {
        levelInfo[levelNum] = {
          totalCount: locations.length,
          name: locations[0].levelName,
          resourceKey: locations[0].levelNameResourceKey,
          selectedLocations: []
        };
        locations.forEach((location) => {
          if (activeIds.includes(location.id)) {
            levelInfo[levelNum].selectedLocations.push(location);
          }
        });
      }
    }

    const detailsText = [];
    Object.keys(levelInfo)
      .sort()
      .forEach((level) => {
        const label = i18n.t(
          `locations.${levelInfo[level].resourceKey.toLocaleLowerCase()}-plural`
        );
        let text;
        if (levelInfo[level].selectedLocations.length > 0) {
          text = levelInfo[level].selectedLocations
            .map((l) => l.text)
            .sort((a, b) => a.localeCompare(b))
            .join(", ");
        } else {
          text = i18n.t("ui.none-selected");
        }
        detailsText.push({ label, text });
      });
    return detailsText;
  }
};

export function createFlatList({ levelInfo, locationHierarchy }) {
  const locationsList = [];

  function addChildrenToList(location) {
    location.childLocations.forEach((l) => {
      l.text = l.name;
      const levelNameResourceKey = levelInfo[l.level.toString()];

      locationsList.push({
        id: l.id,
        name: l.name,
        parentId: l.parentId,
        level: l.level,
        levelNameResourceKey: levelNameResourceKey,
        levelName: i18n.t(`locations.levelNames.${levelNameResourceKey}`)
      });

      addChildrenToList(l);
    });
  }

  addChildrenToList(locationHierarchy);

  return locationsList;
}

function firstNlocations(state, count) {
  const { activeIds, hierarchy } = state;
  let counter = 0;
  const firstN = [];
  const traverse = (locations) => {
    if (counter >= count) {
      return;
    }
    locations.forEach((location) => {
      if (counter >= count) {
        return;
      }
      if (activeIds.includes(location.id)) {
        firstN.push(location);
        counter++;
      }
      if (location.childLocations) {
        traverse(location.childLocations);
      }
    });
  };
  traverse(hierarchy.items);
  return firstN;
}

function removeChildrenFromLocationAtLevel(location, level) {
  if (location.level === level) {
    delete location.childLocations;
  } else {
    location.childLocations.forEach((l) => {
      removeChildrenFromLocationAtLevel(l, level);
    });
  }
}
