<template>
  <div class="tw-flex tw-flex-wrap lg:tw-flex-row">
    <filter-popup
      v-if="selectedFilterId"
      :value="changeFilterPopupVisible"
      :filterId="selectedFilterId"
      :filterState="$store.state[`filters/${selectedFilterId}`]"
      @input="onCloseFilterPopup"
      @filterChanged="onFilterChanged"
      :forceSelection="filterFeatures[selectedFilterId].forceSelection"
      :orderedItems="$store.getters[`filters/${selectedFilterId}/orderedItems`]"
      :showSearch="filterFeatures[selectedFilterId].showSearch"
      :selectAll="filterFeatures[selectedFilterId].selectAll"
      :selectionComponent="filterFeatures[selectedFilterId].selectionComponent"
      :showOtherOption="filterFeatures[selectedFilterId].showOtherOption"
      :otherOptionText="
        filterFeatures[selectedFilterId].showOtherOption
          ? $t(filterFeatures[selectedFilterId].otherOptionText)
          : ''
      "
    />
    <div
      v-for="filterId in activeFilters"
      :key="filterId"
      :class="`tw-w-full lg:tw-w-1/${
        activeFilters.length > 1 ? activeFilters.length : 2
      } tw-self-center`"
    >
      <filters-entry
        :filterText="getFilterTextAbbreviated(filterId)"
        :disableShifts="disableShifts"
        @click="(e) => onFilterSelection(filterId)"
      >
        {{ $t(`filters.${filterId}`) }}
      </filters-entry>
    </div>
  </div>
</template>

<script>
import FiltersEntry from "@/views/reports/components/filters/components/FiltersEntry";
import FilterPopup from "@/views/reports/components/filters/components/filtersPopup/FilterPopup";
import { FILTERS } from "@/types/filters";
import { REPORT_TYPES } from "@/types/reportSubscriptionTypes";
import { uniq } from "lodash";

export default {
  props: {
    selectedReportTypeId: {
      type: Number
    }
  },
  components: {
    FiltersEntry,
    FilterPopup
  },
  data: () => ({
    changeFilterPopupVisible: false,
    disableShifts: false,
    selectedFilterId: undefined,
    filterFeatures: {
      [FILTERS.EMPLOYEES]: {
        showSearch: true,
        selectAll: true,
        selectionComponent: "filters-popup-multi-selectable-items",
        forceSelection: true
      },
      [FILTERS.IMSJOBROLES]: {
        showSearch: true,
        selectAll: true,
        selectionComponent: "filters-popup-multi-selectable-items",
        forceSelection: true
      },
      [FILTERS.SHIFTS]: {
        showSearch: true,
        selectAll: true,
        selectionComponent: "filters-popup-multi-selectable-items",
        forceSelection: false,
        showOtherOption: true,
        otherOptionText: "shifts.all-24-hours"
      },
      [FILTERS.LOCATIONS]: {
        showSearch: true,
        selectAll: true,
        selectionComponent: "filters-popup-hierarchy-items",
        forceSelection: true
      }
    }
  }),
  computed: {
    activeFilters() {
      const activeFilterIds = [];
      if (this.selectedReportTypeId === REPORT_TYPES.JOB_ROLE) {
        activeFilterIds.push(
          ...[FILTERS.IMSJOBROLES, FILTERS.SHIFTS, FILTERS.LOCATIONS]
        );
      } else if (
        this.selectedReportTypeId ===
          REPORT_TYPES.INDIVIDUAL_PERFORMANCE_TABLE ||
        this.selectedReportTypeId === REPORT_TYPES.INDIVIDUAL_PERFORMANCE_GRAPH
      ) {
        activeFilterIds.push(...Object.keys(this.filterFeatures));
      } else {
        activeFilterIds.push(...[FILTERS.LOCATIONS, FILTERS.SHIFTS]);
      }

      if (!this.showShifts) {
        return activeFilterIds.filter((id) => id !== FILTERS.SHIFTS);
      }

      return activeFilterIds;
    },
    showShifts() {
      return this.$store.state[`filters/${FILTERS.SHIFTS}`].allIds.length;
    }
  },
  methods: {
    getFilterTextAbbreviated(filterId) {
      return this.$store.getters[`filters/${filterId}/filterTextAbbreviated`](
        this.$store.state[`filters/${filterId}`]
      );
    },
    onCloseFilterPopup() {
      this.changeFilterPopupVisible = false;
      this.selectedFilterId = undefined;
    },
    onFilterChanged({ filterId, filter }) {
      this.$store.commit(`filters/${filterId}/set`, {
        field: "activeIds",
        value: filter.activeIds
      });

      if (
        (this.selectedReportTypeId ===
          REPORT_TYPES.INDIVIDUAL_PERFORMANCE_TABLE ||
          this.selectedReportTypeId ===
            REPORT_TYPES.INDIVIDUAL_PERFORMANCE_GRAPH) &&
        filterId === FILTERS.EMPLOYEES
      ) {
        const activeJobRoleIds = [
          ...this.$store.state[`filters/${FILTERS.IMSJOBROLES}`].activeIds
        ];
        const activeEmployeeJobRoles = uniq(
          [
            ...Object.values(
              this.$store.state[`filters/${FILTERS.EMPLOYEES}`].byId
            )
          ]
            .filter((e) => filter.activeIds.includes(e.id))
            .map((e) => e.jobRoleId)
        );

        const newActiveJobRoleIds = [
          ...Object.values(
            this.$store.state[`filters/${FILTERS.IMSJOBROLES}`].byId
          )
        ]
          .filter(
            (jr) =>
              activeJobRoleIds.includes(jr.id) ||
              activeEmployeeJobRoles.includes(jr.id)
          )
          .map((jr) => jr.id);

        if (newActiveJobRoleIds.length > activeJobRoleIds.length) {
          this.$store.commit(`filters/${FILTERS.IMSJOBROLES}/set`, {
            field: "activeIds",
            value: newActiveJobRoleIds
          });
        }
      }

      this.onCloseFilterPopup();
    },
    onFilterSelection(filterId) {
      this.$appInsights?.trackEvent({
        name: `Report Subscription: Open ${filterId} Filter`
      });
      this.selectedFilterId = filterId;
      this.changeFilterPopupVisible = true;
    }
  }
};
</script>
