import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportCustomLegend from "@/views/reports/components/reportSections/ReportCustomLegend.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";
import ReportBlockRow from "@/views/reports/components/reportSections/reportBlock/ReportBlockRow.vue";

import { sumEntries, createPercentage } from "@/views/reports/utils/calculate";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import i18n from "@/plugins/i18n";
import { TIMEFRAMES } from "@/types/timeframes";
import { organizeRow } from "../../components/reportSections/reportBlock/reportBlockUtils";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportCustomLegend,
    ReportGraph,
    ReportBlockRow
  },
  data() {
    return {
      cells: [],
      headers: [
        {
          text: i18n.t("reports.date"),
          value: "reportDate",
          display: "Date"
        },
        {
          text: i18n.t("reports.total-successful-outcomes"),
          value: "compliantObservations"
        },
        {
          text: i18n.t("reports.opportunities"),
          value: "totalObservations"
        },
        {
          text: i18n.t("reports.compliance"),
          value: "complianceIndex",
          display: "Percentage"
        }
      ],
      customLegend: [
        {
          text: i18n.t("reports.compliance"),
          color: "tertiary",
          icon: "mdi-chart-timeline-variant"
        },
        {
          text: i18n.t("reports.observations"),
          color: "primary",
          icon: "mdi-chart-bar"
        }
      ],
      sortedData: [],
      chartData: {},
      reportBlockDefinitions: [
        {
          title: "$t{reports.compliant-observations}",
          color: "purple",
          rows: [
            {
              value: "compliantObservations",
              display: "Locale",
              dataKey: "sevenDayData",
              text: "$t{reports.events-week}"
            },
            {
              value: "compliantObservations",
              display: "Locale",
              dataKey: "thirtyDayData",
              text: "$t{reports.events-month}"
            },
            {
              value: "compliantObservations",
              display: "Locale",
              dataKey: "yearData",
              text: "$t{reports.events-year}"
            },
            {
              value: "compliantObservations",
              display: "Locale",
              dataKey: "totalData",
              text: "$t{reports.total}"
            }
          ]
        },
        {
          title: "$t{reports.total-observations}",
          color: "green",
          rows: [
            {
              value: "totalObservations",
              display: "Locale",
              dataKey: "sevenDayData",
              text: "$t{reports.events-week}"
            },
            {
              value: "totalObservations",
              display: "Locale",
              dataKey: "thirtyDayData",
              text: "$t{reports.events-month}"
            },
            {
              value: "totalObservations",
              display: "Locale",
              dataKey: "yearData",
              text: "$t{reports.events-year}"
            },
            {
              value: "totalObservations",
              display: "Locale",
              dataKey: "totalData",
              text: "$t{reports.total}"
            }
          ]
        },
        {
          title: "$t{reports.hand-hygiene-perf}",
          color: "sky-blue",
          rows: [
            {
              value: "complianceIndex",
              display: "Percentage",
              dataKey: "sevenDayData",
              text: "$t{reports.events-week}"
            },
            {
              value: "complianceIndex",
              display: "Percentage",
              dataKey: "thirtyDayData",
              text: "$t{reports.events-month}"
            },
            {
              value: "complianceIndex",
              display: "Percentage",
              dataKey: "yearData",
              text: "$t{reports.events-year}",
              options: {}
            },
            {
              value: "complianceIndex",
              display: "Percentage",
              dataKey: "totalData",
              text: "$t{reports.total}"
            }
          ]
        }
      ],
      reportBlockItems: [],
      suggestedMax: 60,
      hiddenTimeframes: [TIMEFRAMES.LAST_24_HOURS]
    };
  },
  methods: {
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.sortedData, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    createSummary() {
      const data = this.reportResponse.dataPoints;
      const totalCompliantObservations = sumEntries({
        entries: data,
        inputField: "compliantObservations"
      });
      const totalObservations = sumEntries({
        entries: data,
        inputField: "totalObservations"
      });
      const totalPercentage = createPercentage({
        dividend: totalCompliantObservations,
        divisor: totalObservations
      });

      this.cells = [
        {
          text: i18n.t("reports.compliant"),
          value: totalCompliantObservations,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total"),
          value: totalObservations,
          display: "Locale"
        },
        {
          text: i18n.t("reports.performance-rate"),
          value: totalPercentage,
          display: "Percentage"
        }
      ];
    },
    createGraph() {
      const allTotalObservations = this.sortedData.map(
        (item) => item.totalObservations
      );
      this.suggestedMax = Math.max.apply(0, allTotalObservations) + 10;

      const chartBuilder = new ChartBuilder({
        isSlideshow: this.isSlideshow,
        displayLegend: false,
        isPrint: this.isPrint
      });
      const { dateTimeScale } = chartBuilder.chartTimeSeries({
        type: "bar",
        items: this.sortedData,
        valueField: "totalObservations",
        valueFieldType: GraphDataTypes.NUMBER,
        valueScaleOptions: {
          label: i18n.t("reports.observations"),
          position: "right",
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: this.suggestedMax
          }
        },
        dateTimeField: "reportDate",
        timeframe: this.$store.state["filters/timeframe"],
        dateTimeScaleOptions: {
          minTimeUnit: "day"
        }
      });
      chartBuilder.chartTimeSeries({
        dateTimeScale,
        type: "line",
        items: this.sortedData,
        valueField: "complianceIndex",
        valueFieldType: GraphDataTypes.FIXED,
        valueScaleOptions: {
          label: i18n.t("reports.compliance"),
          position: "left",
          ticks: {
            format: "${value}%"
          }
        },
        dateTimeField: "reportDate"
      });
      this.chartData = chartBuilder.build();
    },
    handleReportResponse() {
      const data = this.reportResponse.dataPoints;
      const reportBlockData = this.reportResponse.summaryData;

      if (!data || !reportBlockData) {
        return;
      }

      data.sort((a, b) => new Date(a.reportDate) - new Date(b.reportDate));

      this.sortedData = data;
      this.createSummary();
      this.createGraph();
      this.reportBlockItems = organizeRow(
        this.reportBlockDefinitions,
        reportBlockData
      );
    }
  }
};
