var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-mb-5"},[(_vm.loading)?[_c('p',[_vm._v(" "+_vm._s(_vm.$t("admin.performance-thresholds.loading-directions", { levelName: this.levelName }))+". ")]),_c('v-progress-linear',{attrs:{"indeterminate":""}})]:[_c('chart-renderer',{staticClass:"tw-relative tw-w-3/4 tw-m-auto",attrs:{"indicators":_vm.indicatorsToRender}}),_c('transition-group',{attrs:{"tag":"div","name":"thresholds-list"}},_vm._l((_vm.indicators),function(item,index){return _c('v-row',{key:item.id,staticClass:"tw-items-center tw-justify-between tw-my-2"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$tc(
                  'admin.manage-facility.report-settings.indicator',
                  index + 1
                ),"rules":{ required: true, max: 30 }},scopedSlots:_vm._u([{key:"default",fn:function({ label, errors }){return [_c('v-text-field',{attrs:{"error-messages":[...errors, ...item.titleErrors],"label":label,"counter":30},on:{"input":function($event){return _vm.checkDuplicateIndicatorTitle(item)},"blur":function($event){return _vm.trimTitleWhitespace(item)}},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})]}}],null,true)})],1),_c('v-col',{staticClass:"tw-flex tw-flex-row tw-items-center tw-justify-between",attrs:{"cols":"6","lg":"3"}},[(index !== 0)?_c('ValidationProvider',{attrs:{"name":_vm.$tc(
                  'admin.manage-facility.report-settings.indicator-threshold',
                  index + 1
                ),"rules":{
                required: _vm.requiredIndicators,
                double: 1,
                max_value: 100,
                min_value: 1
              }},scopedSlots:_vm._u([{key:"default",fn:function({ label, errors }){return [_c('v-text-field',{attrs:{"error-messages":[...errors, ...item.valueErrors],"label":label,"suffix":"%"},on:{"blur":function($event){return _vm.roundValue(item)},"input":function($event){return _vm.checkDuplicateIndicatorValue(item)}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)}):_vm._e()],1),_c('v-col',{staticClass:"tw-flex tw-justify-between",attrs:{"cols":"6","lg":"3"}},[_c('v-menu',{attrs:{"top":"","offset-y":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":item.color,"dark":"","min-width":"36px"}},on))]}}],null,true)},[_c('ValidationProvider',{attrs:{"name":_vm.$tc(
                    'admin.manage-facility.report-settings.indicator-color',
                    index + 1
                  )}},[_c('v-color-picker',{attrs:{"hide-canvas":"","hide-mode-switch":"","hide-sliders":"","hide-inputs":"","show-swatches":"","swatches":_vm.swatches},model:{value:(item.color),callback:function ($$v) {_vm.$set(item, "color", $$v)},expression:"item.color"}})],1)],1),(index !== 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteIndicator(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("performance-indicators.remove"))+" ")])]):_vm._e()],1)],1)}),1),_c('v-row',{staticClass:"tw-mt-1 tw-justify-self-start tw-pb-10"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.indicators.length < 7),expression:"indicators.length < 7"}],staticClass:"tw-mr-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.addIndicator()}}},[_vm._v(" "+_vm._s(_vm.$t("admin.manage-facility.report-settings.add-indicator"))+" ")]),(_vm.indicators.length === 0)?_c('v-btn',{staticClass:"tw-mr-5",attrs:{"color":"background2"},on:{"click":function($event){return _vm.setDefaultIndicators(true)}}},[_vm._v(" "+_vm._s(_vm.$t("admin.manage-facility.report-settings.default-indicators"))+" ")]):_vm._e(),(_vm.resetState.length && _vm.indicatorsChanged)?_c('v-btn',{staticClass:"tw-mr-5",attrs:{"color":"background2"},on:{"click":function($event){return _vm.resetPerformanceIndicators(_vm.resetState)}}},[_vm._v(" "+_vm._s(_vm.$t("admin.manage-facility.report-settings.reset-thresholds"))+" ")]):_vm._e()],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }