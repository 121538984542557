<template>
  <div>
    <report-summary :cells="summary" />
    <report-table
      :headers="reportHeaders"
      :items="items"
      :initialSortedColumns="['expirationDate']"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import reportMixin from "@/views/reports/mixins/reportMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";

export default {
  mixins: [reportMixin],
  components: { ReportTable, ReportSummary },
  data() {
    const commonHeaders = [
      {
        text: i18n.t("reports.sa.headers.floor"),
        value: "floor",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.unit"),
        value: "unit",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.room"),
        value: "room",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-name"),
        value: "ecmDeviceName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.device-model"),
        value: "deviceModelName",
        sortable: true,
        class: "tw-align-text-top"
      },
      {
        text: i18n.t("reports.sa.headers.serial-number"),
        value: "serialNumber",
        sortable: true,
        class: "tw-align-text-top"
      }
    ];
    return {
      compatibleReportId: 34,
      reportHeaders: [
        ...commonHeaders,
        {
          text: i18n.t("reports.sa.headers.expiration-date"),
          value: "expirationDate",
          sortable: true,
          class: "tw-align-text-top",
          displayText: "expirationDateFormatted"
        },
        {
          text: i18n.t("reports.sa.headers.est-replacement-date"),
          value: "estimatedReplacementDate",
          sortable: true,
          class: "tw-align-text-top",
          displayText: "estimatedReplacementDateFormatted"
        }
      ],
      csvHeaders: [
        ...commonHeaders,
        {
          text: i18n.t("reports.sa.headers.expiration-date"),
          value: "expirationDateFormatted",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.est-replacement-date"),
          value: "estimatedReplacementDateFormatted",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      items: [],
      reportFilters: ["locations", "monthsToExpiration"],
      hiddenReportActions: ["rawExport", "saveSlide"],
      summary: []
    };
  },

  methods: {
    createCsvData() {
      const headers = this.csvHeaders;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      const items = this.reportResponse.dataPoints;
      this.items = items.map((item) => {
        return {
          ...item,
          expirationDate: new Date(item.expirationDate),
          estimatedReplacementDate: new Date(item.estimatedReplacementDate)
        };
      });
      this.getExpirationSummary();
    },
    getExpirationSummary() {
      this.summary = [];
      const deviceModels = [];
      this.items.forEach((item) => {
        deviceModels.push(item.deviceModelName);
      });

      for (let i = 0; i < deviceModels.length; i++) {
        const currentItem = deviceModels[i];
        if (
          this.summary.length &&
          this.summary.some((d) => d.text === currentItem)
        ) {
          const index = this.summary.findIndex(
            (item) => item.text === currentItem
          );
          this.summary[index].value++;
        } else {
          this.summary.push({
            text: currentItem,
            value: 1,
            display: "Locale"
          });
        }
      }
    }
  }
};
</script>
