import BrowserStorage from "./browserStorage";

export default class LocalStorage extends BrowserStorage {
  static store(key = "", value = "") {
    window.localStorage.setItem(this.APP_KEY + key, value);
  }

  static get(key = "", fallback = "") {
    const value = window.localStorage.getItem(this.APP_KEY + key);
    if (value == null || value.trim() === "") {
      return fallback;
    }
    return value;
  }

  static remove(key = "") {
    window.localStorage.removeItem(this.APP_KEY + key);
  }
}
