<template>
  <div id="observationForm" class="tw-flex tw-flex-col tw-overflow-hidden">
    <v-row
      class="tw-bg-background2 tw-m-0"
      style="border-bottom: 1px solid grey"
    >
      <v-col cols="3" class="tw-cursor-pointer" @click="showList('location')">
        <p>{{ $t("obv-system.location").toUpperCase() }}</p>
        <p class="tw-font-bold">{{ selectedLocation }}</p>
      </v-col>
      <v-divider vertical />
      <v-col
        cols="3"
        :class="observation.locationId ? 'tw-cursor-pointer' : ''"
        @click="showList('role')"
      >
        <p>{{ $t("obv-system.role").toUpperCase() }}</p>
        <p class="tw-font-bold">{{ selectedRole }}</p>
      </v-col>
      <v-divider vertical />
      <v-col cols="3" class="tw-cursor-pointer tw-p-0">
        <v-menu
          ref="dateTimeMenu"
          nudge-bottom="105"
          :close-on-content-click="false"
          @input="openDateTime"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="tw-p-3">
              <p class="tw-flex tw-items-center">
                {{ $t("obv-system.dateTime") }}
                <v-icon color="tertiary" class="tw-ml-2">mdi-pencil</v-icon>
              </p>
              <p class="tw-font-bold tw-mr-4">{{ observation.dateTime }}</p>
            </div>
          </template>
          <div class="tw-bg-white tw-flex tw-flex-col tw-p-4">
            <div class="tw-flex tw-flex-row">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="customDate"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    append-icon="mdi-calendar"
                    readonly
                    style="width: 150px; margin-right: 0.5em"
                    @click:append="on.click"
                  />
                </template>
                <v-date-picker
                  v-model="customPickerDate"
                  @input="customDateChosen"
                  :max="maxPickerDate"
                />
              </v-menu>
              <v-select
                v-model="hour"
                :items="hours"
                dense
                outlined
                style="width: 100px"
              />
              <v-select
                v-model="minute"
                :items="minutes"
                dense
                outlined
                style="width: 100px"
              />
              <v-select
                v-model="timeOfDay"
                :items="timeOfDayOptions"
                dense
                outlined
                style="width: 100px"
              />
            </div>
            <div class="tw-self-end">
              <v-btn text @click="$refs.dateTimeMenu.save()">
                {{ $t("ui.actions.cancel") }}
              </v-btn>
              <v-btn color="primary" @click="applyDateTime">
                {{ $t("ui.actions.apply") }}
              </v-btn>
            </div>
          </div>
        </v-menu>
      </v-col>
      <v-divider vertical />
      <v-col cols="3" @click="showObservationForm" class="tw-cursor-pointer">
        <p>{{ $t("obv-system.observation.singular-uppercase") }}</p>
        <p class="tw-font-bold">
          {{ observation.type ? observation.type.toUpperCase() : "" }}
        </p>
      </v-col>
    </v-row>
    <div class="tw-h-full tw-overflow-auto">
      <list
        v-show="selectingLocation"
        id="locationList"
        clearSearchOnSelect
        v-model="observation.locationId"
        :items="locations"
        itemTextKey="name"
        itemValueKey="id"
        @input="closeLocationList"
      />
      <list
        v-show="selectingRole"
        id="roleList"
        clearSearchOnSelect
        v-model="observation.roleId"
        :items="jobRoles"
        itemTextKey="name"
        itemValueKey="id"
        @input="selectingRole = false"
      />
      <div v-if="!selectingLocation && !selectingRole">
        <div class="form-section tw-pb-6">
          <h3 class="tw-mt-4 tw-mb-2">{{ $t("obv-system.moment") }}</h3>
          <button-toggle
            v-model="observation.momentId"
            :items="moments"
            itemTextKey="name"
            itemValueKey="id"
          />
        </div>
        <div
          v-if="observation.type === observationTypes.hh"
          class="form-section tw-pb-6"
        >
          <h3 class="tw-mt-4 tw-mb-2">{{ $t("obv-system.outcome") }}</h3>
          <button-toggle
            v-model="observation.outcomeId"
            :items="outcomes"
            itemValueKey="id"
          />
        </div>
        <div v-else class="form-section">
          <h3 class="tw-mt-4 tw-mb-2">{{ $t("obv-system.precaution") }}</h3>
          <button-toggle
            v-model="observation.precautions"
            multiple
            :items="precautionOptions"
            itemValueKey="id"
          />
          <list class="tw-mt-4" :showSearch="false">
            <v-list-item
              v-for="ppe in observation.ppes"
              :key="ppe.text"
              class="tw-items-center tw-py-2 list-item tw-flex-col"
              :disabled="ppeDisabled(ppe)"
            >
              <div class="tw-flex tw-w-full">
                <v-list-item-title class="tw-text-lg">
                  {{ $t(ppe.text) }}
                </v-list-item-title>
                <button-toggle
                  v-model="ppe.used"
                  style="width: 200px"
                  :items="[
                    {
                      value: true,
                      text: $t('obv-system.yes'),
                      id: `${ppe.text}-yes`
                    },
                    {
                      value: false,
                      text: $t('obv-system.no'),
                      id: `${ppe.text}-no`
                    }
                  ]"
                  :disabled="ppeDisabled(ppe)"
                  text
                  :buttonProps="{ rounded: false }"
                  mandatory
                />
              </div>
              <div
                v-if="
                  observation.momentId === outOfRoomMomentId &&
                  ppe.used &&
                  !ppeDisabled(ppe)
                "
                class="tw-flex tw-w-full tw-flex-col"
              >
                <v-divider class="tw-w-full tw-my-1 divider" />
                <div class="tw-flex tw-w-full">
                  <v-list-item-title class="tw-text-lg tw-ml-4">
                    {{ $t("obv-system.removed-correctly") }}
                  </v-list-item-title>
                  <button-toggle
                    v-model="ppe.removed"
                    style="width: 200px"
                    :items="[
                      {
                        value: true,
                        text: $t('obv-system.yes'),
                        id: `${ppe.text}-yes`
                      },
                      {
                        value: false,
                        text: $t('obv-system.no'),
                        id: `${ppe.text}-no`
                      }
                    ]"
                    text
                    :buttonProps="{ rounded: false }"
                  />
                </div>
              </div>
            </v-list-item>
          </list>
        </div>
        <v-list-item
          v-if="showFeedback"
          class="tw-items-center tw-py-4"
          :style="{
            'border-bottom': '1px solid grey'
          }"
        >
          <v-list-item-title class="tw-text-lg">
            {{ $t("obv-system.feedback") }}
          </v-list-item-title>
          <button-toggle
            class="tw-mr-4"
            style="width: 200px"
            v-model="observation.feedbackProvided"
            :items="[
              {
                value: true,
                text: $t('obv-system.yes'),
                id: `feedback-yes`
              },
              {
                value: false,
                text: $t('obv-system.no'),
                id: `feedback-no`
              }
            ]"
            text
            mandatory
            :buttonProps="{ rounded: false }"
          />
        </v-list-item>
        <v-list-item
          v-if="showCustomNotes"
          class="tw-items-center tw-py-2 tw-w-full tw-pt-4"
          :style="{
            'border-bottom': '1px solid grey'
          }"
        >
          <v-list-item-title
            class="tw-text-lg tw-flex-none tw-mr-8 tw-self-start"
          >
            {{ $t("obv-system.custom-notes") }}
          </v-list-item-title>
          <v-textarea
            id="custom-notes"
            v-model="observation.customNotes"
            full-width
            clearable
            counter="200"
            rows="2"
            outlined
            no-resize
            class="tw-w-full"
            :error="observation.customNotes.length > 200"
            :error-messages="
              observation.customNotes.length > 200
                ? [$t('obv-system.error-messages.custom-notes')]
                : []
            "
          />
        </v-list-item>
        <div v-if="quickNotes.length" class="tw-px-4">
          <h3 class="tw-mt-4">
            {{ $t("obv-system.quick-notes") }}
          </h3>
          <list
            v-model="observation.quickNotes"
            :showSearch="false"
            :items="quickNotes"
            itemValueKey="id"
            multiple
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import List from "./List.vue";
import ButtonToggle from "./ButtonToggle.vue";

export default {
  components: {
    ButtonToggle,
    List
  },
  props: {
    jobRoles: {
      type: Array,
      default: () => []
    },
    locations: {
      type: Array,
      default: () => []
    },
    moments: {
      type: Array,
      default: () => []
    },
    observationTypes: {
      type: Object,
      default: () => {
        return {
          ppe: "PPE",
          hh: "HH"
        };
      }
    },
    precautionOptions: {
      type: Array,
      default: () => []
    },
    quickNotes: {
      type: Array,
      default: () => []
    },
    showCustomNotes: {
      type: Boolean,
      default: false
    },
    showFeedback: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      customDate: "",
      customPickerDate: undefined,
      hour: "",
      hours: Array.from({ length: 12 }, (_, i) => {
        return i === 0
          ? { text: 12, value: 0 }
          : { text: i < 10 ? `0${i}` : `${i}`, value: i };
      }),
      maxPickerDate: undefined,
      minute: "",
      minutes: Array.from({ length: 60 }, (_, i) => {
        return { text: i < 10 ? `0${i}` : `${i}`, value: i };
      }),
      observation: {},
      outcomes: [
        { id: 2, text: i18n.t("obv-system.outcomes.wash") },
        { id: 1, text: i18n.t("obv-system.outcomes.rub") },
        { id: 3, text: i18n.t("obv-system.outcomes.none") }
      ],
      outOfRoomMomentId: 2,
      selectingLocation: true,
      selectingRole: false,
      timeOfDay: "",
      timeOfDayOptions: ["AM", "PM"]
    };
  },
  computed: {
    selectedLocation() {
      if (this.observation.locationId) {
        return this.locations.find((l) => l.id === this.observation.locationId)
          .name;
      }

      return i18n.t("obv-system.select-below");
    },
    selectedRole() {
      if (this.observation.roleId) {
        return this.jobRoles.find((j) => j.id === this.observation.roleId).name;
      }
      if (this.selectingRole) {
        return i18n.t("obv-system.select-below");
      }

      return "--";
    }
  },
  methods: {
    applyDateTime() {
      this.observation.dateTime = new Date(
        new Date(this.customDate).setHours(
          this.timeOfDay === "AM" ? this.hour : this.hour + 12,
          this.minute,
          0,
          0
        )
      ).toLocaleString("en-US", {
        month: "numeric",
        day: "numeric",
        year: "2-digit",
        hour: "numeric",
        minute: "2-digit"
      });

      this.$refs.dateTimeMenu.save();
    },
    closeLocationList() {
      this.selectingLocation = false;
      if (!this.observation.roleId) {
        this.selectingRole = true;
      }
    },
    customDateChosen() {
      this.customDate = new Date(this.customPickerDate).toLocaleDateString(
        "en-US",
        { timeZone: "UTC" }
      );
    },
    openDateTime(open) {
      if (open) {
        let date;

        if (this.observation.dateTime === "Now") {
          date = new Date();
          this.customPickerDate = undefined;
        } else {
          date = new Date(this.observation.dateTime);
          this.customPickerDate = new Date(this.observation.dateTime)
            .toISOString()
            .substr(0, 10);
        }

        const hours = date.getHours();
        this.hour = hours >= 12 ? hours - 12 : hours;
        this.minute = date.getMinutes();
        this.timeOfDay = hours >= 12 ? "PM" : "AM";
        this.customDate = date.toLocaleDateString("en-US");
        this.maxPickerDate = new Date().toISOString();
      }
    },
    ppeDisabled(ppe) {
      if (
        ppe.disabledBy &&
        this.observation.precautions.some(
          (precaution) => precaution === ppe.disabledBy
        )
      ) {
        return true;
      }

      return (
        this.observation.precautions.filter((precaution) =>
          ppe.enabledBy.includes(precaution)
        ).length === 0
      );
    },
    showList(listType) {
      this.search = "";
      if (listType === "location") {
        this.selectingRole = false;
        this.selectingLocation = true;
      } else if (listType === "role" && this.observation.locationId) {
        this.selectingLocation = false;
        this.selectingRole = true;
      }
    },
    showObservationForm() {
      if (this.observation.locationId && this.observation.roleId) {
        this.selectingLocation = false;
        this.selectingRole = false;
      }
    }
  },
  watch: {
    observation(value) {
      this.$emit("input", value);
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (Object.keys(this.observation).length === 0 || newValue.overwrite) {
          this.observation = { ...newValue, overwrite: false };
          if (this.observation.locationId !== "") {
            this.selectingLocation = false;
            this.selectingRole = this.observation.roleId === "";
          } else {
            this.selectingLocation = true;
          }
        }
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.divider {
  border-color: gray;
}

.form-section {
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid gray;
}

.list-item:after {
  content: none;
}

.list-item:not(:last-child) {
  border-bottom: 1px solid gray;
}

.list-item:first-child {
  border-top: 1px solid gray;
}
</style>
