<template>
  <v-list class="tw-pt-0">
    <v-list-item
      v-if="showSearch"
      class="tw-py-2 tw-sticky tw-top-0 tw-bg-white list-item"
      style="z-index: 2"
    >
      <span v-if="title" class="tw-mr-12 tw-text-2xl">
        {{ title }}
      </span>
      <v-text-field
        v-model="search"
        :label="$t('ui.input-select.search')"
        hide-details
        dense
        append-icon="mdi-magnify"
        outlined
      />
    </v-list-item>
    <v-progress-linear v-if="loading" indeterminate />
    <slot>
      <v-list-item
        v-for="item in filteredItems"
        :key="item[itemKey]"
        class="tw-items-center tw-py-2 list-item"
        @click="select(item)"
      >
        <v-list-item-title class="tw-text-lg">
          {{ item[itemTextKey] }}
        </v-list-item-title>
        <v-list-item-icon v-if="selected(item)" class="tw-m-0 tw-self-center">
          <v-icon color="primary" large> mdi-check </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </slot>
  </v-list>
</template>

<script>
export default {
  props: {
    clearSearchOnSelect: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: "list"
    },
    itemKey: {
      type: String,
      default: "id"
    },
    items: {
      type: Array,
      default: () => []
    },
    itemTextKey: {
      type: String,
      default: "text"
    },
    itemValueKey: {
      type: String,
      default: "value"
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: [Array, Number, String]
    }
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) =>
          item[this.itemValueKey] === this.value ||
          item[this.itemTextKey]
            .toLowerCase()
            .includes(this.search.toLowerCase())
      );
    }
  },
  methods: {
    selected(item) {
      if (this.multiple) {
        return this.value.includes(item[this.itemValueKey]);
      }

      return this.value === item[this.itemValueKey];
    },
    select(item) {
      if (this.multiple) {
        if (this.value.includes(item[this.itemValueKey])) {
          this.$emit(
            "input",
            this.value.filter((i) => i !== item[this.itemValueKey])
          );
        } else {
          this.$emit("input", [...this.value, item[this.itemValueKey]]);
        }
      } else {
        if (this.clearSearchOnSelect) {
          this.search = "";
        }
        this.$emit("input", item[this.itemValueKey]);
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.list-item:not(:last-child) {
  border-bottom: 1px solid gray;
}
</style>
