<template>
  <div>
    <report-table
      :headers="headers"
      :items="items"
      :initialSortedColumns="['floor']"
    />
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import reportMixin from "@/views/reports/mixins/reportMixin";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";

export default {
  mixins: [reportMixin],
  components: { ReportTable },
  data() {
    return {
      compatibleReportId: 42,
      headers: [
        {
          text: i18n.t("reports.sa.headers.floor"),
          value: "floor",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.unit"),
          value: "unit",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.room"),
          value: "room",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.device-name"),
          value: "ecmDeviceName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.device-type"),
          value: "deviceTypeName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.device-model"),
          value: "deviceModelName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.serial-number"),
          value: "serialNumber",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("reports.sa.headers.last-communication"),
          value: "lastCommunication",
          sortable: true,
          class: "tw-align-text-top"
        }
      ],
      items: [],
      reportFilters: ["locations"],
      hiddenReportActions: ["rawExport", "saveSlide"]
    };
  },
  methods: {
    createCsvData() {
      const headers = this.headers;
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    handleReportResponse() {
      const items = this.reportResponse.dataPoints;
      this.items = items.map((item) => {
        return {
          ...item,
          lastCommunication: this.formatTimestamp(item.lastCommunication)
        };
      });
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        //ignoring timezone information because local time from backend
        return new Date(`${timestamp}`).toLocaleDateString("en-us", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "numeric",
          minute: "2-digit",
          hourCycle: "h12"
        });
      }
      return "";
    }
  }
};
</script>
