<template>
  <div>
    <h2>{{ $t("alert-subscriptions.manage-alert.configuration") }}</h2>
    <v-row>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('alert-subscriptions.configuration.name')"
          :rules="{ required: true, max: 100 }"
          v-slot="{ label, errors }"
        >
          <v-text-field
            :value="name"
            :error-messages="errors"
            :label="label"
            @input="(value) => $emit('update:name', value)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('alert-subscriptions.configuration.type')"
          :rules="{ required: true, is_not: 0 }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="type"
            :items="typeItems"
            :disabled="isEdit"
            item-text="displayName"
            item-value="id"
            :label="label"
            :error-messages="errors"
            @change="(value) => $emit('update:type', value)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row v-if="type === alertTypes.nonReportingDevices">
      <v-col cols="12" md="3">
        <ValidationProvider
          :name="$t('alert-subscriptions.configuration.unit-of-time')"
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="lastCommThresholdUnits"
            :items="lastCommThresholdUnitItems"
            :label="label"
            item-text="displayName"
            item-value="value"
            :error-messages="errors"
            @input="(value) => $emit('update:lastCommThresholdUnits', value)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="3">
        <ValidationProvider
          :name="
            $t(
              'alert-subscriptions.configuration.time-since-last-communication'
            )
          "
          :rules="{ required: true, max_value: 100, min_value: 1 }"
          v-slot="{ label, errors }"
          class="tw-mr-5"
        >
          <v-text-field
            :value="lastCommThreshold"
            :error-messages="errors"
            :label="label"
            type="number"
            @input="(value) => $emit('update:lastCommThreshold', value)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('alert-subscriptions.configuration.device-type-class')"
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="deviceTypeClassId"
            :items="deviceTypeItems"
            item-text="displayName"
            item-value="id"
            :label="label"
            :error-messages="errors"
            @input="(value) => $emit('update:deviceTypeClassId', value)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row v-if="type === alertTypes.batteryStatus">
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="
            $t('alert-subscriptions.configuration.battery-status-threshold')
          "
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="batteryStatus"
            :items="batteryStatusItems"
            item-text="displayName"
            item-value="value"
            :label="label"
            :error-messages="errors"
            @input="(value) => $emit('update:batteryStatus', value)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row v-if="type === alertTypes.badgeBatteryStatus">
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="
            $t('alert-subscriptions.configuration.battery-status-threshold')
          "
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="badgeBatteryStatus"
            :items="batteryStatusItems"
            item-text="displayName"
            item-value="value"
            :label="label"
            :error-messages="errors"
            @input="(value) => $emit('update:badgeBatteryStatus', value)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row v-if="type === alertTypes.lowRefill">
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="
            $t(
              'alert-subscriptions.configuration.estimated-replacement-date-within'
            )
          "
          :rules="{ required: true }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="estimatedReplacementDateThreshold"
            :items="replacementDateItems"
            item-text="displayName"
            item-value="value"
            :label="label"
            :error-messages="errors"
            @change="
              (value) =>
                $emit('update:estimatedReplacementDateThreshold', value)
            "
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import alertTypes from "@/views/alertSubscriptions/alertTypes";

export default {
  props: {
    isEdit: {
      type: Boolean
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: Number
    },
    typeItems: {
      type: Array
    },
    batteryStatus: {
      type: String,
      default: ""
    },
    badgeBatteryStatus: {
      type: String,
      default: ""
    },
    lastCommThreshold: {
      type: [Number, String]
    },
    lastCommThresholdUnits: {
      type: String,
      default: ""
    },
    deviceTypeClassId: {
      type: Number,
      default: 0
    },
    deviceTypeItems: {
      type: Array
    },
    estimatedReplacementDateThreshold: {
      type: Number
    }
  },
  data: () => ({
    batteryStatusItems: [
      {
        displayName: i18n.t("alert-subscriptions.configuration.low"),
        value: "Low"
      },
      {
        displayName: i18n.t("alert-subscriptions.configuration.dead"),
        value: "Dead"
      }
    ],
    lastCommThresholdUnitItems: [
      {
        displayName: i18n.t("alert-subscriptions.configuration.minutes"),
        value: "MINUTE"
      },
      {
        displayName: i18n.t("alert-subscriptions.configuration.hours"),
        value: "HOUR"
      },
      {
        displayName: i18n.t("alert-subscriptions.configuration.days"),
        value: "DAY"
      }
    ],
    replacementDateItems: [
      {
        displayName: "1 Day",
        value: 1
      },
      {
        displayName: "2 Days",
        value: 2
      },
      {
        displayName: "3 Days",
        value: 3
      },
      {
        displayName: "4 Days",
        value: 4
      },
      {
        displayName: "5 Days",
        value: 5
      },
      {
        displayName: "6 Days",
        value: 6
      },
      {
        displayName: "7 Days",
        value: 7
      }
    ],
    alertTypes: alertTypes
  })
};
</script>
