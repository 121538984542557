<template>
  <div>
    <h1 class="tw-mb-5">
      {{
        isEdit
          ? $t("admin.manage-customer.edit")
          : $t("admin.manage-customer.add")
      }}
    </h1>
    <ValidationObserver ref="manageCustomerForm" v-slot="{ pristine }">
      <v-form @submit.prevent="validateAndSubmit">
        <v-row
          :style="
            $vuetify.breakpoint.mdAndDown
              ? 'flex-direction: column-reverse'
              : ''
          "
        >
          <v-col cols="12" lg="6" class="xl:tw-pb-10">
            <h3>{{ $t("admin.manage-customer.basic-info") }}</h3>
            <ValidationProvider
              :name="$t('admin.manage-customer.name')"
              :rules="{ required: true, max: 100 }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="name"
                :error-messages="errors"
                :label="$t('admin.manage-customer.name')"
                :counter="100"
              />
            </ValidationProvider>
            <ValidationProvider
              :name="$t('admin.manage-customer.country')"
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-autocomplete
                auto-select-first
                v-model="countryId"
                :error-messages="errors"
                :label="$t('admin.manage-customer.country')"
                :items="
                  countries.map((f) => ({
                    text: f.name,
                    value: f.id
                  }))
                "
              />
            </ValidationProvider>
            <h3 class="tw-mt-2">
              {{ $t("admin.manage-customer.address-info") }}
            </h3>
            <ValidationProvider
              :name="$t('admin.manage-customer.address-line-1')"
              :rules="{ required: true, max: 100 }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="addressLine1"
                :error-messages="errors"
                :label="$t('admin.manage-customer.address-line-1')"
                :counter="100"
              />
            </ValidationProvider>
            <ValidationProvider
              :name="$t('admin.manage-customer.address-line-2')"
              :rules="{ max: 100 }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="addressLine2"
                :error-messages="errors"
                :label="$t('admin.manage-customer.address-line-2')"
                :counter="100"
              />
            </ValidationProvider>

            <ValidationProvider
              :name="$t('admin.manage-customer.address-line-3')"
              :rules="{ max: 100 }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="addressLine3"
                :error-messages="errors"
                :label="$t('admin.manage-customer.address-line-3')"
                :counter="100"
              />
            </ValidationProvider>
            <v-row>
              <v-col cols="12" lg="4">
                <ValidationProvider
                  :name="$t('admin.manage-customer.city')"
                  :rules="{ required: true, max: 50 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="city"
                    :error-messages="errors"
                    :label="$t('admin.manage-customer.city')"
                    :counter="50"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="4">
                <ValidationProvider
                  :name="$t('admin.manage-customer.state-or-province')"
                  :rules="{ required: true, max: 100 }"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    auto-select-first
                    v-model="stateOrProvinceId"
                    :error-messages="errors"
                    :label="$t('admin.manage-customer.state-or-province')"
                    :items="
                      states.map((f) => ({
                        text: f.name,
                        value: f.id
                      }))
                    "
                    v-if="states.length"
                  />
                  <v-text-field
                    v-else
                    v-model="stateName"
                    :error-messages="errors"
                    :label="$t('admin.manage-customer.state-or-province')"
                    :counter="100"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="4">
                <ValidationProvider
                  :name="$t('admin.manage-customer.postal-code')"
                  :rules="{ required: true, max: 10 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="postalCode"
                    :error-messages="errors"
                    :label="$t('admin.manage-customer.postal-code')"
                    :counter="10"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <h3 class="tw-mt-2">
              {{ $t("admin.manage-customer.security-policies") }}
            </h3>
            <v-row>
              <v-col cols="12" lg="6">
                <ValidationProvider>
                  <v-select
                    v-model="accountLockout"
                    :label="$t('admin.manage-customer.account-lockout')"
                    :items="
                      accountLockoutOptions.map((f) => ({
                        text: $tc(f.text, f.value),
                        value: f.value
                      }))
                    "
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="6">
                <ValidationProvider>
                  <v-select
                    v-model="lockoutDuration"
                    :label="$t('admin.manage-customer.lockout-duration')"
                    :items="
                      accountLockout === ''
                        ? [
                            {
                              text: $t('admin.manage-customer.duration.na'),
                              value: ''
                            }
                          ]
                        : lockoutDurationOptions.map((f) => ({
                            text: $tc(f.text, f.count),
                            value: f.value
                          }))
                    "
                    :disabled="accountLockout === ''"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <ValidationProvider>
              <v-select
                v-model="passwordExpiration"
                :label="$t('admin.manage-customer.password-expiration')"
                :items="
                  passwordExpirationOptions.map((f) => ({
                    text: $tc(f.text, f.value),
                    value: f.value
                  }))
                "
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="6" class="xl:tw-flex tw-pl-1">
            <ValidationProvider
              :name="$t('admin.manage-customer.customer-logo')"
              :rules="{
                mimes: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
                size: 1024 // 1 MB
              }"
              v-slot="{ errors }"
              tag="div"
            >
              <v-file-input
                v-show="filePreviewUrl === '' || errors.length !== 0"
                v-model="file"
                prepend-icon=""
                :accept="['image/png', 'image/jpg', 'image/jpeg', 'image/gif']"
                :error-messages="errors"
                @change="setFilePreview"
                hide-input
                ref="imageInput"
              >
                <template v-slot:append-outer>
                  <v-btn
                    class="tw-text-purellGray"
                    style="outline: 1px dashed #8a8d8f"
                    @click="$refs.imageInput.$refs.input.click()"
                    height="100px"
                    depressed
                  >
                    <v-icon x-large color="primary" class="tw-pl-0">
                      mdi-image-plus
                    </v-icon>
                    <div>
                      {{ $t("admin.manage-customer.add-logo") }}
                    </div>
                  </v-btn>
                </template>
              </v-file-input>
              <span
                v-if="errors.length !== 0"
                class="tw-pt-6 tw-pl-2 tw-text-xs tw-w-full tw-error"
              >
                {{ errors[0] }}
              </span>
              <v-img
                contain
                :src="filePreviewUrl"
                max-width="300px"
                max-height="300px"
                v-if="filePreviewUrl != '' && errors.length === 0"
                class="tw-relative tw-overflow-visible"
              >
                <div
                  class="tw-w-4 tw-absolute tw-right-0"
                  :style="{ top: '-10px' }"
                >
                  <v-btn
                    fab
                    depressed
                    small
                    height="35px"
                    width="35px"
                    color="primary"
                    class="tw-mb-1"
                    @click="$refs.imageInput.$refs.input.click()"
                  >
                    <v-icon color="white">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    depressed
                    small
                    height="35px"
                    width="35px"
                    color="red"
                    @click="clearLogo"
                  >
                    <v-icon color="white">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </v-img>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="tw-justify-end tw-w-full tw-self-end tw-pb-4">
          <v-btn
            class="tw-mr-5 tw-w-24"
            :disabled="customerId && pristine && !logoRemoved"
            :loading="saving"
            color="primary"
            type="submit"
          >
            {{ $t("ui.actions.save") }}
          </v-btn>
          <v-btn
            class="tw-mr-5 tw-w-24"
            color="background2"
            :to="{ name: ROUTE_NAMES.adminCustomerList }"
          >
            {{ $t("ui.actions.cancel") }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import customerService from "@/api/customerService";
import countryService from "@/api/countryService";
import stateOrProvinceService from "@/api/stateOrProvinceService";
import { ROUTE_NAMES } from "@/types/routeNames";
import i18n from "@/plugins/i18n";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    customerId: { type: Number }
  },
  data: () => ({
    ROUTE_NAMES,
    loading: true,
    saving: false,
    isEdit: false,
    newCustomerId: null,
    name: "",
    countryId: undefined,
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    stateOrProvinceId: undefined,
    stateName: "",
    postalCode: "",
    accountLockout: "",
    lockoutDuration: "",
    logoRemoved: false,
    passwordExpiration: "",
    mediaItemId: undefined,
    file: null,
    filePreviewUrl: "",
    countries: [],
    states: [],
    accountLockoutOptions: [
      {
        text: "admin.manage-customer.disabled",
        value: ""
      },
      {
        text: "admin.manage-customer.attempts",
        value: 3
      },
      {
        text: "admin.manage-customer.attempts",
        value: 4
      },
      {
        text: "admin.manage-customer.attempts",
        value: 5
      },
      {
        text: "admin.manage-customer.attempts",
        value: 6
      },
      {
        text: "admin.manage-customer.attempts",
        value: 7
      },
      {
        text: "admin.manage-customer.attempts",
        value: 8
      }
    ],
    lockoutDurationOptions: [
      {
        text: "admin.manage-customer.duration.minutes",
        value: "00:15:00",
        count: "15"
      },
      {
        text: "admin.manage-customer.duration.minutes",
        value: "00:30:00",
        count: "30"
      },
      {
        text: "admin.manage-customer.duration.hour",
        value: "01:00:00",
        count: "1"
      },
      {
        text: "admin.manage-customer.duration.hours",
        value: "02:00:00",
        count: "2"
      },
      {
        text: "admin.manage-customer.duration.hours",
        value: "12:00:00",
        count: "12"
      },
      {
        text: "admin.manage-customer.duration.hours",
        value: "1.00:00:00",
        count: "24"
      }
    ],
    passwordExpirationOptions: [
      {
        text: "admin.manage-customer.disabled",
        value: ""
      },
      {
        text: "admin.manage-customer.duration.days",
        value: 30
      },
      {
        text: "admin.manage-customer.duration.days",
        value: 60
      },
      {
        text: "admin.manage-customer.duration.days",
        value: 90
      },
      {
        text: "admin.manage-customer.duration.days",
        value: 180
      }
    ]
  }),
  async created() {
    this.countries = await countryService.list();

    if (this.customerId) {
      this.isEdit = true;
      await this.loadCustomer();
    } else {
      this.countryId = this.countries.length ? this.countries[0].id : null;
    }

    this.loading = false;
  },
  methods: {
    async loadCustomer() {
      try {
        const customer = await customerService.get({
          customerid: this.customerId
        });
        this.name = customer.name;
        this.countryId = customer.countryId;
        this.addressLine1 = customer.address1;
        this.addressLine2 = customer.address2;
        this.addressLine3 = customer.address3;
        this.city = customer.city;
        this.stateOrProvinceId = customer.stateOrProvinceId;
        this.stateName = customer.stateOrProvinceId ? "" : customer.stateName;
        this.postalCode = customer.postalCode;
        this.accountLockout = customer.maxFailedLoginAttempts ?? "";
        this.lockoutDuration = customer.accountLockoutDuration ?? "";
        this.passwordExpiration = customer.passwordExpirationDays ?? "";
        this.mediaItemId = customer.mediaItemId;

        if (customer.mediaItemId && customer.mediaItemId !== undefined) {
          this.filePreviewUrl = await customerService.getCustomerLogo(
            this.customerId
          );
        }
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.manage-customer.error-messages.get-customer")
        });
      }
    },
    async validateAndSubmit() {
      const valid = await this.$refs.manageCustomerForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Manage Customer Form Error`
        });
        return;
      }

      this.saving = true;

      const customerSubmitResult = await this.submit();
      if (!customerSubmitResult) {
        this.saving = false;
        return;
      }

      const uploadLogoResult = await this.uploadLogo();
      if (!uploadLogoResult) {
        this.saving = false;
        return;
      }

      const customerId = this.isEdit ? this.customerId : this.newCustomerId;
      this.$router.push({
        name: ROUTE_NAMES.customerDashboard,
        query: { customerId }
      });

      this.$store.commit(
        "application/SET_SUCCESS_MESSAGE",
        this.isEdit
          ? i18n.t("admin.manage-customer.success-messages.update")
          : i18n.t("admin.manage-customer.success-messages.create")
      );
    },
    async submit() {
      try {
        const result = await customerService.saveCustomer({
          id: this.customerId,
          name: this.name,
          countryId: this.countryId,
          address1: this.addressLine1,
          address2: this.addressLine2,
          address3: this.addressLine3,
          city: this.city,
          stateOrProvinceId: this.stateOrProvinceId,
          stateName: this.stateName,
          postalCode: this.postalCode,
          maxFailedLoginAttempts: this.accountLockout,
          accountLockoutDuration: this.lockoutDuration,
          passwordExpirationDays: this.passwordExpiration,
          mediaItemId: this.mediaItemId
        });
        if (!this.isEdit) {
          this.newCustomerId = result.id;
        }
        this.$appInsights?.trackEvent({
          name: `Manage Customer Form Success`
        });
        return true;
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Manage Customer Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.manage-customer.error-messages.save-customer")
        });
        return false;
      }
    },
    async uploadLogo() {
      const customerId = this.isEdit ? this.customerId : this.newCustomerId;
      try {
        if (this.file) {
          await customerService.postCustomerLogo(customerId, this.file);
          this.$appInsights?.trackEvent({
            name: `Manage Customer Logo Upload Success`
          });
        }
        return true;
      } catch (error) {
        this.$appInsights?.trackEvent({
          name: `Manage Customer Logo Upload Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.manage-customer.error-messages.upload-customer-logo"
          )
        });
        return false;
      }
    },
    async loadStateOrProvince() {
      this.states = await stateOrProvinceService.list(this.countryId);
    },
    setFilePreview(file) {
      this.filePreviewUrl = file ? URL.createObjectURL(file) : "";
    },
    clearLogo() {
      this.mediaItemId = null;
      this.file = null;
      this.filePreviewUrl = "";
      this.logoRemoved = true;
    }
  },
  watch: {
    countryId(value) {
      if (!this.loading) {
        this.stateOrProvinceId = undefined;
        this.stateName = "";
      }
      this.loadStateOrProvince(value);
    },
    accountLockout(value) {
      if (value === "") {
        this.lockoutDuration = "";
      } else if (this.lockoutDuration === "") {
        this.lockoutDuration = this.lockoutDurationOptions[0].value;
      }
    }
  }
};
</script>
