<template>
  <div>
    <report-summary :cells="cells" />
    <report-custom-legend :items="customLegend" />
    <report-fixed-legend :goal="goal" :hideComplianceLegend="true" />
    <report-graph :chartData="chartData" />
    <report-table
      :showSearch="false"
      :showTotalRow="true"
      :headers="tableData.headers"
      :items="tableData.items"
    >
      <template v-slot:totalRow>
        <tr class="smartlink-table-total-row">
          <td>{{ $t("reports.total") }}</td>
          <td v-for="(column, index) in tableData.totalRowColumns" :key="index">
            <component
              :is="displayFormatterComponent(column.type)"
              :value="column.value"
            />
          </td>
        </tr>
      </template>
    </report-table>
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportCustomLegend from "@/views/reports/components/reportSections/ReportCustomLegend.vue";
import ReportFixedLegend from "@/views/reports/components/reportSections/ReportFixedLegend.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import DisplayFormatterPercentage from "@/views/reports/components/reportSections/displayFormatters/DisplayFormatterPercentage.vue";
import DisplayFormatterLocale from "@/views/reports/components/reportSections/displayFormatters/DisplayFormatterLocale.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";

import i18n from "@/plugins/i18n";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import { mapState } from "vuex";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportCustomLegend,
    ReportFixedLegend,
    ReportTable,
    ReportGraph,
    DisplayFormatterPercentage,
    DisplayFormatterLocale
  },
  data() {
    return {
      compatibleReportId: 36,
      reportFilters: ["timeframe", "locations", "obvJobRoles"],
      hiddenReportActions: ["saveSlide"],
      customLegend: [
        {
          text: i18n.t("reports.outcomes.rub"),
          color: "primary",
          icon: "mdi-square"
        },
        {
          text: i18n.t("reports.outcomes.wash"),
          color: "tertiary",
          icon: "mdi-square"
        }
      ],
      cells: [],
      goal: 0,
      chartData: {},
      tableData: {}
    };
  },
  computed: {
    ...mapState("reports", ["isSlideshow"])
  },
  methods: {
    handleReportResponse() {
      if (
        !this.reportResponse?.dataPoints ||
        !Array.isArray(this.reportResponse.dataPoints) ||
        !this.reportResponse.outcomeTotals
      ) {
        return;
      }
      this.goal = this.reportResponse.performanceGoal;
      this.createSummary();
      this.createGraph();
      this.createTableData();
    },
    createSummary() {
      this.cells = [
        {
          text: i18n.t("reports.compliant"),
          value:
            this.reportResponse.outcomeTotals.wash +
            this.reportResponse.outcomeTotals.rub,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total"),
          value: this.reportResponse.outcomeTotals.total,
          display: "Locale"
        },
        {
          text: i18n.t("reports.performance-rate"),
          value: this.reportResponse.outcomeTotals.complianceIndex,
          display: "Percentage"
        }
      ];
    },
    createGraph() {
      const data = [];
      this.reportResponse.dataPoints.forEach((dp) => {
        data.push({
          jobRole: dp.jobRole,
          outcome: i18n.t("reports.outcomes.rub"),
          complianceIndex: dp.rubPercent.toFixed(1)
        });
        data.push({
          jobRole: dp.jobRole,
          outcome: i18n.t("reports.outcomes.wash"),
          complianceIndex: dp.washPercent.toFixed(1)
        });
      });
      const builder = new ChartBuilder({
        displayLegend: false,
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });
      builder
        .chartCategorized({
          type: "bar",
          typeOptions: {
            stacked: true
          },
          items: data,
          valueField: "complianceIndex",
          valueFieldType: GraphDataTypes.FIXED,
          datasetField: "outcome",
          categoryField: "jobRole",
          valueScaleOptions: {
            label: i18n.t("reports.compliance"),
            ticks: {
              format: "${value}%"
            }
          }
        })
        .valueScale.showGoal(this.goal);
      this.chartData = builder.build();
    },
    createTableData() {
      this.tableData.headers = [
        {
          text: i18n.t("job-roles.singular"),
          value: "jobRole",
          display: "Locale"
        },
        {
          text: i18n.t("reports.outcomes.wash"),
          value: "wash",
          display: "Locale"
        },
        {
          text: i18n.t("reports.outcomes.rub"),
          value: "rub",
          display: "Locale"
        },
        {
          text: i18n.t("reports.outcomes.none"),
          value: "none",
          display: "Locale"
        },
        { text: i18n.t("reports.total"), value: "total", display: "Locale" },
        {
          text: `${i18n.t("reports.outcomes.wash")} %`,
          value: "washPercent",
          display: "Percentage"
        },
        {
          text: `${i18n.t("reports.outcomes.rub")} %`,
          value: "rubPercent",
          display: "Percentage"
        },
        {
          text: `${i18n.t("reports.total")} %`,
          value: "complianceIndex",
          display: "Percentage"
        }
      ];

      this.tableData.items = this.reportResponse.dataPoints;
      this.tableData.totalRowColumns = [
        { value: this.reportResponse.outcomeTotals.wash, type: "number" },
        { value: this.reportResponse.outcomeTotals.rub, type: "number" },
        { value: this.reportResponse.outcomeTotals.none, type: "number" },
        { value: this.reportResponse.outcomeTotals.total, type: "number" },
        {
          value: this.reportResponse.outcomeTotals.washPercent,
          type: "percentage"
        },
        {
          value: this.reportResponse.outcomeTotals.rubPercent,
          type: "percentage"
        },
        {
          value: this.reportResponse.outcomeTotals.complianceIndex,
          type: "percentage"
        }
      ];
    },
    displayFormatterComponent(type) {
      return type === "percentage"
        ? "DisplayFormatterPercentage"
        : "DisplayFormatterLocale";
    },
    createCsvData() {
      const headers = this.tableData.headers;
      if (!Array.isArray(headers)) {
        return;
      }
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.tableData.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    }
  }
};
</script>
