import PRIVILEGES from "./privileges";
import { PRODUCTS } from "./products";

const PERMISSION_CHECKS = {
  activateDeactivateCustomers: {
    privilege: PRIVILEGES.activateDeactivateCustomers
  },
  addCustomers: {
    privilege: PRIVILEGES.addCustomers
  },
  addFacilities: {
    privilege: PRIVILEGES.addFacilities
  },
  administerCustomer: {
    privilege: PRIVILEGES.administerCustomers
  },
  administerServiceAlerts: {
    privilege: PRIVILEGES.administerServiceAlerts
  },
  amsReports: {
    privilege: PRIVILEGES.runReports,
    products: [PRODUCTS.ECM]
  },
  archiveFacilities: {
    privilege: PRIVILEGES.archiveFacilities
  },
  cloneJobRoles: {
    privilege: PRIVILEGES.cloneJobRoles
  },
  cloneObservationNotes: {
    privilege: PRIVILEGES.cloneObservationNotes
  },
  createImageSlides: {
    privilege: PRIVILEGES.crudImageSlides
  },
  diagnostics: {
    privilege: PRIVILEGES.diagnostics
  },
  downloads: {
    privilege: PRIVILEGES.rawExportReport,
    products: [PRODUCTS.ECM, PRODUCTS.IMS, PRODUCTS.OBS]
  },
  editFacilities: {
    privilege: PRIVILEGES.editFacilities
  },
  imsReports: {
    privilege: PRIVILEGES.runReports,
    products: [PRODUCTS.IMS]
  },
  legacySlideshows: {
    privilege: PRIVILEGES.crudSlideshows,
    products: [PRODUCTS.OBS]
  },
  manageAdminUsers: {
    privilege: PRIVILEGES.crudAdminUsers
  },
  manageAlerts: {
    privilege: PRIVILEGES.runReports,
    products: [PRODUCTS.IMS, PRODUCTS.ECM, PRODUCTS.SA]
  },
  manageDevices: {
    privilege: PRIVILEGES.crudDevices
  },
  manageEmployees: {
    privilege: PRIVILEGES.manageBadges,
    products: [PRODUCTS.IMS]
  },
  manageJobRoles: {
    privilege: PRIVILEGES.manageJobRoles,
    products: [PRODUCTS.IMS, PRODUCTS.OBS]
  },
  manageLicenses: {
    privilege: PRIVILEGES.manageLicenses
  },
  manageShifts: {
    privilege: PRIVILEGES.manageShifts
  },
  manageUsers: {
    privilege: PRIVILEGES.crudUsers
  },
  obvReports: {
    privilege: PRIVILEGES.runReports,
    products: [PRODUCTS.OBS]
  },
  obvSystem: {
    privilege: PRIVILEGES.observeCompliance,
    products: [PRODUCTS.OBS]
  },
  reportSubscriptions: {
    privilege: PRIVILEGES.manageReportSubscriptions,
    products: [PRODUCTS.ECM, PRODUCTS.IMS]
  },
  saReports: {
    privilege: PRIVILEGES.runReports,
    products: [PRODUCTS.SA]
  },
  slideshows: {
    privilege: PRIVILEGES.crudSlideshows,
    products: [PRODUCTS.ECM]
  },
  systemStatus: {
    privilege: PRIVILEGES.systemStatus
  },
  systemStatusHistory: {
    privilege: PRIVILEGES.systemStatusHistory
  },
  toggleCustomObservationNotes: {
    privilege: PRIVILEGES.toggleCustomObservationNotes
  },
  deleteLocations: {
    privilege: PRIVILEGES.deleteLocations
  },
  viewAllAlertSubscriptions: {
    privilege: PRIVILEGES.viewAllAlertSubscriptions
  }
};

export default PERMISSION_CHECKS;

export const PERMISSIONS = Object.fromEntries(
  Object.entries(PERMISSION_CHECKS).map((e) => [e[0], e[0]])
);
