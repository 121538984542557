import ChartRenderer from "@/views/admin/components/performanceIndicators/ChartRenderer.vue";
import colors from "@/colors/colors";
import { PRODUCTS } from "@/types/products";
import { cloneDeep, isEqual } from "lodash";
import i18n from "@/plugins/i18n";

export default {
  components: {
    ChartRenderer
  },
  data: () => ({
    PRODUCTS,
    colors: [
      { text: "red", value: "#E23838" },
      { text: "blue", value: colors.primary },
      { text: "green", value: "#5EBD3E" },
      { text: "orange", value: "#F78200" },
      { text: "yellow", value: "#FFB900" },
      { text: "purple", value: "#973999" },
      { text: "pink", value: colors.secondary },
      { text: "lightblue", value: colors.tertiary },
      { text: "turquoise", value: "#0E967D" },
      { text: "brown", value: "#A13400" },
      { text: "silver", value: colors.lightPurellGray },
      { text: "black", value: "#000000" }
    ],
    loading: true,
    indicators: [],
    resetState: []
  }),
  computed: {
    swatches() {
      const swatches = [[], [], [], []];

      for (let i = 0; i < this.colors.length; i++) {
        const remainder = i % swatches.length;
        swatches[remainder] = [...swatches[remainder], this.colors[i].value];
      }

      return swatches;
    },
    firstAvailableSwatch() {
      const selectedColors = this.indicators.map((i) => i.color);
      const unSelectedColors = this.colors.filter(
        (c) => !selectedColors.includes(c.value)
      );
      return unSelectedColors[0].value;
    },
    indicatorsChanged() {
      return !isEqual(this.indicators, this.resetState);
    }
  },
  methods: {
    checkDuplicateIndicatorValue(indicator) {
      if (
        indicator.value &&
        this.indicators.find(
          (i) => i.value === indicator.value && i.id !== indicator.id
        )
      ) {
        indicator.valueErrors = [
          i18n.t(
            "admin.manage-facility.report-settings.error-messages.duplicate-value"
          )
        ];
      } else {
        indicator.valueErrors = [];
      }
    },
    checkDuplicateIndicatorTitle(indicator) {
      if (
        indicator.title.length &&
        this.indicators.find(
          (i) =>
            i.title.toLowerCase() === indicator.title.toLowerCase() &&
            i.id !== indicator.id
        )
      ) {
        indicator.titleErrors = [
          i18n.t(
            "admin.manage-facility.report-settings.error-messages.duplicate-title"
          )
        ];
      } else {
        indicator.titleErrors = [];
      }
    },
    trimTitleWhitespace(indicator) {
      indicator.title = indicator.title.trim();
    },
    deleteIndicator(indicatorId) {
      const indicatorCopy = [...this.indicators];
      indicatorCopy.splice(
        indicatorCopy.findIndex((i) => i.id === indicatorId),
        1
      );

      this.indicators = indicatorCopy;

      this.sortIndicators();

      this.$appInsights?.trackEvent({
        name: `Performance Thresholds Delete Indicator`
      });
    },
    resetPerformanceIndicators() {
      this.indicators = cloneDeep(this.resetState);
      this.$appInsights?.trackEvent({
        name: `Performance Thresholds Reset Indicators`
      });
    }
  }
};
