import i18n from "@/plugins/i18n";

export const monthIndexToString = {
  0: i18n.t("months.full.january"),
  1: i18n.t("months.full.february"),
  2: i18n.t("months.full.march"),
  3: i18n.t("months.full.april"),
  4: i18n.t("months.full.may"),
  5: i18n.t("months.full.june"),
  6: i18n.t("months.full.july"),
  7: i18n.t("months.full.august"),
  8: i18n.t("months.full.september"),
  9: i18n.t("months.full.october"),
  10: i18n.t("months.full.november"),
  11: i18n.t("months.full.december")
};

export const abberviatedMonthIndexToString = {
  0: i18n.t("months.abbreviation.january"),
  1: i18n.t("months.abbreviation.february"),
  2: i18n.t("months.abbreviation.march"),
  3: i18n.t("months.abbreviation.april"),
  4: i18n.t("months.abbreviation.may"),
  5: i18n.t("months.abbreviation.june"),
  6: i18n.t("months.abbreviation.july"),
  7: i18n.t("months.abbreviation.august"),
  8: i18n.t("months.abbreviation.september"),
  9: i18n.t("months.abbreviation.october"),
  10: i18n.t("months.abbreviation.november"),
  11: i18n.t("months.abbreviation.december")
};
