import filterService from "@/api/filterService";
import timeframeService from "@/api/timeframeService";
import precautionOptionsService from "@/api/precautionOptionsService";
import activityByService from "@/api/activityByService";
import monthService from "@/api/monthService";
import replacementDateService from "@/api/replacementDateService";
import { REPLACEMENT_DATES } from "@/types/replacementDates";
import expirationDateService from "@/api/expirationDateService";
import { EXPIRATION_DATES } from "@/types/expirationDates";
import normalizeFields from "../../utils/fields";
import { FILTERS } from "@/types/filters";
import { TIMEFRAMES } from "@/types/timeframes";
import {
  precautionOptions,
  precautionOptionStrings
} from "@/types/precautionOptions";
import { activityBy } from "@/types/activityBy";
import { OBSERVER_OPTIONS } from "@/types/observerOptions";
import locationsFilterUtils, { createFlatList } from "./locationsFilterUtils";
import devicesFilterUtils from "./devicesFilterUtils";
import { makeFilterModule } from "./makeFilterModule";
import filterUtils from "@/store/modules/filters/filterUtils";
import i18n from "@/plugins/i18n";
import observerOptionsService from "@/api/observerOptionsService";

function determineTimeframeActiveIds(activeId) {
  if (activeId === undefined) {
    return TIMEFRAMES.LAST_7_DAYS;
  } else if (Array.isArray(activeId)) {
    return activeId[0];
  } else {
    return activeId;
  }
}

export default function (store, options = {}) {
  store.registerModule(
    `filters/${FILTERS.SHIFTS}`,
    makeFilterModule({
      service: filterService(FILTERS.SHIFTS),
      normalizer: (object) => {
        const normalizedFields = normalizeFields(object, {
          id: "id",
          name: "name",
          startTime: "startTime",
          endTime: "endTime"
        });

        return normalizedFields;
      },
      allSelected: false,
      defaultValue: options.defaultFilters?.shiftIds,
      getOrderedItems: (state, getters, rootState) =>
        filterUtils.getShiftsOrderedItems(state, getters, rootState),
      getSelectedText: (state, getters) =>
        filterUtils.defaultSelectedText(state, getters, {
          emptyText: "shifts.all-24-hours",
          textKey: "name"
        }),
      getFilterText: (state, getters) => getters.selectedText(state, getters),
      getFilterTextAbbreviated: (state, getters) =>
        getters.selectedText(state, getters)
    })
  );

  store.registerModule(
    `filters/${FILTERS.IMSJOBROLES}`,
    makeFilterModule({
      service: filterService(FILTERS.IMSJOBROLES),
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          title: "text"
        }),
      allSelected: true,
      defaultValue: options.defaultFilters?.jobRoleIds
    })
  );

  store.registerModule(
    `filters/${FILTERS.OBVJOBROLES}`,
    makeFilterModule({
      service: filterService(FILTERS.OBVJOBROLES),
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          title: "text"
        }),
      allSelected: true,
      defaultValue: options.defaultFilters?.obvJobRoleIds
    })
  );

  store.registerModule(
    `filters/${FILTERS.LOCATIONS}`,
    makeFilterModule({
      service: filterService(FILTERS.LOCATIONS),
      transformer: (object) => createFlatList(object),
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          name: "text",
          parentId: "parentId",
          level: "level",
          levelName: "levelName",
          levelNameResourceKey: "levelNameResourceKey"
        }),
      allSelected: true,
      defaultValue: options.defaultFilters?.locationIds,
      getHierarchy: locationsFilterUtils.getLocationsHierarchy,
      getSelectedText: locationsFilterUtils.getLocationsSelectedText,
      getFilterText: locationsFilterUtils.getLocationsFilterText,
      getFilterTextAbbreviated:
        locationsFilterUtils.getLocationsFilterTextAbbreviated,
      getSelectedTextDetails:
        locationsFilterUtils.getLocationsSelectedTextDetails,
      selectionType: options.locationLevel ? "leaf" : undefined,
      itemChildrenKey: "childLocations",
      getSelectableIds: options.locationLevel
        ? ({ byId }) => {
            return Object.values(byId)
              .filter((i) => i.level === options.locationLevel)
              .map((i) => i.id);
          }
        : undefined
    })
  );
  const timeframeState = timeframeService.getState();
  store.registerModule(
    `filters/${FILTERS.TIMEFRAME}`,
    makeFilterModule({
      service: timeframeService,
      stateMerge: {
        ...timeframeState,
        start: options.defaultFilters?.startDate
          ? new Date(options.defaultFilters?.startDate)
          : undefined,
        end: options.defaultFilters?.endDate
          ? new Date(options.defaultFilters?.endDate)
          : undefined
      },
      activeIds: determineTimeframeActiveIds(
        options.defaultFilters?.reportDateRange
      ),
      defaultValue: TIMEFRAMES.LAST_7_DAYS,
      getOrderedItems: (state) =>
        [...state.allIds]
          .filter((id) => !state.hiddenIds.includes(id))
          .map((id) => state.byId[id]),
      getFilterText: (state, getters) => getters.selectedText(state, getters),
      getFilterTextAbbreviated: (state, getters) =>
        getters.selectedText(state, getters)
    })
  );

  store.registerModule(
    `filters/${FILTERS.REPLACEMENT_DATE}`,
    makeFilterModule({
      service: replacementDateService,
      defaultValue:
        options.defaultFilters?.daysToReplacement != null
          ? options.defaultFilters?.daysToReplacement
          : REPLACEMENT_DATES.ALL,
      getOrderedItems: (state) =>
        [...state.allIds]
          .filter((id) => !state.hiddenIds.includes(id))
          .map((id) => state.byId[id]),
      getFilterText: (state, getters) => getters.selectedText(state, getters),
      getFilterTextAbbreviated: (state, getters) =>
        getters.selectedText(state, getters)
    })
  );

  store.registerModule(
    `filters/${FILTERS.EXPIRATION_DATE}`,
    makeFilterModule({
      service: expirationDateService,
      defaultValue:
        options.defaultFilters?.monthsToExpiration != null
          ? options.defaultFilters?.monthsToExpiration
          : EXPIRATION_DATES.THREE_MONTHS,
      getOrderedItems: (state) =>
        [...state.allIds]
          .filter((id) => !state.hiddenIds.includes(id))
          .map((id) => state.byId[id]),
      getFilterText: (state, getters) => getters.selectedText(state, getters),
      getFilterTextAbbreviated: (state, getters) =>
        getters.selectedText(state, getters)
    })
  );

  store.registerModule(
    `filters/${FILTERS.DEVICES}`,
    makeFilterModule({
      service: filterService(FILTERS.DEVICES),
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          deviceName: "text",
          deviceType: "ecmDeviceType",
          location: "location",
          locationId: "locationId"
        }),
      defaultValue: options.defaultFilters?.deviceIds,
      getHierarchy: devicesFilterUtils.getDevicesHierarchy,
      getSelectedText: devicesFilterUtils.getDevicesSelectedText,
      getFilterText: devicesFilterUtils.getDevicesFilterText,
      itemChildrenKey: "childLocations",
      selectionType: "leaf"
    })
  );

  store.registerModule(
    `filters/${FILTERS.HYGIENEPROTOCOLOPPORTUNITIES}`,
    makeFilterModule({
      service: filterService(FILTERS.HYGIENEPROTOCOLOPPORTUNITIES),
      transformer: (object) =>
        object.map((po) => {
          po.text = i18n.t(`protocolOpportunities.${po.name}`);
          return po;
        }),
      allSelected: true,
      defaultValue: options.defaultFilters?.hygieneProtocolOpportunityIds
    })
  );

  store.registerModule(
    `filters/${FILTERS.PRECAUTIONOPTIONS}`,
    makeFilterModule({
      service: precautionOptionsService,
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          text: "text"
        }),
      defaultValue: options.defaultFilters?.precautionOption
        ? options.defaultFilters?.precautionOption
        : precautionOptions.all,
      getOrderedItems: (state) => state.allIds.map((id) => state.byId[id]),
      getFilterText: (state, getters) => getters.selectedText(state, getters),
      getFilterTextAbbreviated: (state, getters) =>
        getters.selectedText(state, getters),
      getSelectedText: (state) => {
        const selectedId = state.activeIds;
        if (!selectedId || selectedId === precautionOptions.all) {
          return i18n.t("ui.all-selected");
        } else {
          return precautionOptionStrings[selectedId];
        }
      }
    })
  );

  store.registerModule(
    `filters/${FILTERS.ACTIVITYBY}`,
    makeFilterModule({
      service: activityByService,
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          text: "text"
        }),
      defaultValue: options.defaultFilters?.activityBy
        ? options.defaultFilters?.activityBy
        : activityBy.unit,
      getOrderedItems: (state) => state.allIds.map((id) => state.byId[id]),
      getFilterText: (state, getters) => getters.selectedText(state, getters),
      getFilterTextAbbreviated: (state, getters) =>
        getters.selectedText(state, getters)
    })
  );

  store.registerModule(
    `filters/${FILTERS.OBVYEARS}`,
    makeFilterModule({
      service: filterService(FILTERS.OBVYEARS),
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          text: "text"
        }),
      allSelected: false,
      defaultValue: options.defaultFilters?.obvYears || [
        new Date().getFullYear()
      ],
      getOrderedItems: (state) => filterUtils.sortById(state)
    })
  );

  store.registerModule(
    `filters/${FILTERS.OBSERVER_OPTIONS}`,
    makeFilterModule({
      service: observerOptionsService,
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          text: "text"
        }),
      allSelected: false,
      defaultValue: options.defaultFilters?.activeObserversOnly
        ? options.defaultFilters?.activeObserversOnly
        : OBSERVER_OPTIONS.ALL,
      getOrderedItems: (state) => state.allIds.map((id) => state.byId[id]),
      getFilterText: (state, getters) => getters.selectedText(state, getters),
      getFilterTextAbbreviated: (state, getters) =>
        getters.selectedText(state, getters)
    })
  );
  store.registerModule(
    `filters/${FILTERS.MONTHS}`,
    makeFilterModule({
      service: monthService,
      normalizer: (object) =>
        normalizeFields(object, {
          id: "id",
          text: "text"
        }),
      allSelected: true,
      defaultValue: options.defaultFilters?.months,
      getOrderedItems: (state) => filterUtils.sortById(state)
    })
  );

  if (options.createEmployeeFilter) {
    store.registerModule(
      `filters/${FILTERS.EMPLOYEES}`,
      makeFilterModule({
        service: filterService(FILTERS.EMPLOYEES),
        allSelected: true,
        transformer: (object) => {
          return object.map((e) => ({
            id: e.id,
            employeeName: [e.lastName, e.firstName].filter((n) => n).join(", "),
            jobRoleId: e.jobRoleId
          }));
        },
        normalizer: (object) =>
          normalizeFields(object, {
            id: "id",
            employeeName: "text",
            jobRoleId: "jobRoleId"
          })
      })
    );
  }

  return store;
}
