<template>
  <div>
    <h2>{{ $t("alert-subscriptions.manage-alert.scheduling") }}</h2>
    <v-row>
      <v-col cols="12" md="6">
        <ValidationProvider
          :name="$t('alert-subscriptions.scheduling.frequency')"
          :rules="{ required: true, is_not: 0 }"
          v-slot="{ label, errors }"
        >
          <v-select
            :value="frequency"
            :items="frequencyItems"
            item-text="displayName"
            item-value="id"
            :label="label"
            :error-messages="errors"
            @change="(value) => $emit('update:frequency', value)"
          />
        </ValidationProvider>
        <div v-if="frequency === 2">
          <ValidationProvider
            :name="$t('alert-subscriptions.scheduling.days-of-week')"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <span class="tw-text-error" v-if="errors.length">
              {{ errors[0] }}
            </span>
            <div
              class="
                tw-grid tw-grid-cols-2 tw-gap-2
                md:tw-grid-cols-3
                xl:tw-grid-cols-4
              "
            >
              <ValidationProvider>
                <v-checkbox
                  v-model="daysOfWeekModel"
                  :label="$t('days-of-week.full.monday')"
                  value="MON"
                />
              </ValidationProvider>
              <ValidationProvider>
                <v-checkbox
                  v-model="daysOfWeekModel"
                  :label="$t('days-of-week.full.tuesday')"
                  value="TUE"
                />
              </ValidationProvider>
              <ValidationProvider>
                <v-checkbox
                  v-model="daysOfWeekModel"
                  :label="$t('days-of-week.full.wednesday')"
                  value="WED"
                />
              </ValidationProvider>
              <ValidationProvider>
                <v-checkbox
                  v-model="daysOfWeekModel"
                  :label="$t('days-of-week.full.thursday')"
                  value="THU"
                />
              </ValidationProvider>
              <ValidationProvider>
                <v-checkbox
                  v-model="daysOfWeekModel"
                  :label="$t('days-of-week.full.friday')"
                  value="FRI"
                />
              </ValidationProvider>
              <ValidationProvider>
                <v-checkbox
                  v-model="daysOfWeekModel"
                  :label="$t('days-of-week.full.saturday')"
                  value="SAT"
                />
              </ValidationProvider>
              <ValidationProvider>
                <v-checkbox
                  v-model="daysOfWeekModel"
                  :label="$t('days-of-week.full.sunday')"
                  value="SUN"
                />
              </ValidationProvider>
            </div>
          </ValidationProvider>
        </div>
        <div v-if="frequency === 3">
          <ValidationProvider
            :name="$t('alert-subscriptions.scheduling.day-of-month')"
            :rules="{ required: true, is_not: 0 }"
            v-slot="{ label, errors }"
          >
            <v-select
              :value="dayOfMonth"
              :items="dayOfMonthItems"
              item-text="displayName"
              item-value="value"
              :label="label"
              :error-messages="errors"
              @change="(value) => $emit('update:dayOfMonth', value)"
            />
          </ValidationProvider>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="tw-text-center">
        <ValidationProvider>
          <v-time-picker
            v-if="frequency > 0"
            :value="timeOfDay"
            format="ampm"
            ampm-in-title
            @input="(value) => $emit('update:timeOfDay', value)"
          >
            <span style="margin: auto">
              {{ facilityTimeZone }}
            </span></v-time-picker
          >
        </ValidationProvider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    frequency: {
      type: Number
    },
    frequencyItems: {
      type: Array
    },
    timeOfDay: {
      type: String
    },
    daysOfWeek: {
      type: Array
    },
    dayOfMonth: {
      type: Number
    },
    facilityTimeZone: {
      type: String
    }
  },
  data: () => ({
    daysOfWeekModel: [],
    dayOfMonthItems: [
      { displayName: "1", value: 1 },
      { displayName: "2", value: 2 },
      { displayName: "3", value: 3 },
      { displayName: "4", value: 4 },
      { displayName: "5", value: 5 },
      { displayName: "6", value: 6 },
      { displayName: "7", value: 7 },
      { displayName: "8", value: 8 },
      { displayName: "9", value: 9 },
      { displayName: "10", value: 10 },
      { displayName: "11", value: 11 },
      { displayName: "12", value: 12 },
      { displayName: "13", value: 13 },
      { displayName: "14", value: 14 },
      { displayName: "15", value: 15 },
      { displayName: "16", value: 16 },
      { displayName: "17", value: 17 },
      { displayName: "18", value: 18 },
      { displayName: "19", value: 19 },
      { displayName: "20", value: 20 },
      { displayName: "21", value: 21 },
      { displayName: "22", value: 22 },
      { displayName: "23", value: 23 },
      { displayName: "24", value: 24 },
      { displayName: "25", value: 25 },
      { displayName: "26", value: 26 },
      { displayName: "27", value: 27 },
      { displayName: "28", value: 28 },
      { displayName: "EOM", value: 31 }
    ]
  }),
  watch: {
    daysOfWeek: {
      immediate: true,
      handler(value) {
        this.daysOfWeekModel = value;
      }
    },
    daysOfWeekModel(value) {
      this.$emit("update:daysOfWeek", value);
    }
  }
};
</script>

<style>
.v-time-picker-clock__ampm .v-picker__title__btn--active {
  text-decoration: underline;
  font-weight: bold;
}
</style>
