<template>
  <div>
    <facility-selection-bar class="tw-my-8" />
    <header>
      <h1>{{ $t("slideshows.manage-slideshows") }}</h1>
    </header>
    <v-data-table
      :items="slideshows"
      :headers="headers"
      :search="search"
      :sort-by.sync="sortedColumns"
      @update:sort-by="trackHeaderAnalytics()"
      :loading="loading"
      :loading-text="$t('slideshows.gathering-slideshow-data')"
      :no-data-text="$t('slideshows.no-slideshows-in-facility')"
    >
      <template v-slot:top>
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
            @focus="trackSearchAnalytics()"
          />
          <v-btn
            class="tw-max-w-xs"
            primary
            color="primary"
            :to="{
              name: 'slideshowCreate',
              query: { facilityId }
            }"
          >
            {{ $t("slideshows.create-slideshow") }}
          </v-btn>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <router-link
          v-if="item.isActive"
          target="_blank"
          :to="{
            name: 'slideshowView',
            query: { slideshowId: item.id, facilityId: facilityId }
          }"
        >
          {{ item.name }}
        </router-link>
        <span v-else>{{ item.name }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.isActive="{ item }">
        <v-switch
          v-model="item.isActive"
          :loading="item.isLoading"
          @click="toggleSlideshowStatus(item)"
          readonly
        />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.edit="{ item }">
        <router-link
          class="tw-no-underline"
          :to="{
            name: 'slideshowEdit',
            query: { slideshowId: item.id, facilityId }
          }"
        >
          <v-icon>mdi-pencil</v-icon>
        </router-link>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-if="checkPermission(PERMISSIONS.administerCustomer)" v-slot:item.delete="{ item }">
        <v-btn
          icon
          @click="toggleDeleteDialog({ id: item.id, name: item.name })"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <deleteDialog
      @deletion-confirmed="deleteSlideshow"
      @toggle-delete-dialog="toggleDeleteDialog"
      :deleteDialogData="deleteDialogData"
    />
  </div>
</template>

<script>
import DeleteDialog from "@/views/slideshows/components/DeleteDialog.vue";
import FacilitySelectionBar from "@/components/layout/FacilitySelectionBar.vue";
import slideshowService from "@/api/slideshowsService";
import i18n from "@/plugins/i18n";
import { mapGetters, mapState } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import { orderBy } from "lodash";

export default {
  components: {
    DeleteDialog,
    FacilitySelectionBar
  },
  data: () => ({
    deleteDialogData: {
      active: false,
      id: null,
      name: "",
      title: i18n.t("slideshows.delete-slideshow"),
      content: i18n.t("slideshows.overlay-content.delete-slideshow")
    },
    error: {},
    loading: true,
    search: "",
    slideshows: [],
    sortedColumns: [],
    PERMISSIONS
  }),
  computed: {
    ...mapState("customers", ["facilityId"]),
    ...mapGetters("user/permissions", ["checkPermission"]),
    headers() {
      const headers = [
        {
          text: i18n.t("slideshows.name"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("slideshows.interval"),
          value: "interval",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("slideshows.slides"),
          value: "slideCount",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("slideshows.active"),
          value: "isActive",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("slideshows.edit"),
          value: "edit",
          sortable: false,
          class: "tw-align-text-top"
        }
      ];

      if (this.checkPermission(PERMISSIONS.administerCustomer)) {
        headers.push({
          text: i18n.t("slideshows.delete"),
          value: "delete",
          sortable: false,
          class: "tw-align-text-top"
        });
      }

      return headers;
    }
  },
  created() {
    if (this.facilityId) {
      this.getSlideshows(this.facilityId);
    }
  },
  methods: {
    async deleteSlideshow() {
      const id = this.deleteDialogData.id;
      try {
        await slideshowService.deleteSlideshow(id);
        this.toggleDeleteDialog();
        this.slideshows = this.slideshows.filter((item) => item.id !== id);
        this.$appInsights?.trackEvent({ name: `Successful Slideshow Delete` });
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("slideshows.error-messages.delete-slideshow")
        });
      }
    },
    async getSlideshows(facilityId) {
      try {
        const slideshows = await slideshowService.getSlideshowsForFacility(
          facilityId
        );

        slideshows.forEach((s) => {
          s.isLoading = false;
        });

        this.slideshows = orderBy(
          slideshows,
          ["isActive", (item) => item.name.toLowerCase()],
          ["desc", "asc"]
        );

        this.loading = false;
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "slideshows.error-messages.get-slideshows-for-facility"
          )
        });
      }
    },
    toggleDeleteDialog(data) {
      this.deleteDialogData.active = !this.deleteDialogData.active;
      this.deleteDialogData.id = this.deleteDialogData.id ? null : data.id;
      this.deleteDialogData.name = this.deleteDialogData.name ? "" : data.name;

      const slideshowStatus = this.deleteDialogData.active ? "Open" : "Close";
      this.$appInsights?.trackEvent({
        name: `${slideshowStatus} Slideshow Delete Dialog`
      });
    },
    async toggleSlideshowStatus(item) {
      try {
        item.isLoading = true;

        await slideshowService.setSlideshowActiveStatus(
          item.id,
          !item.isActive
        );

        item.isActive = !item.isActive;
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("slideshows.error-messages.toggle-slideshows")
        });
      } finally {
        item.isLoading = false;
      }
    },
    trackHeaderAnalytics() {
      this.$appInsights?.trackEvent({
        name: `Manage Slideshow Column Sorting: ${this.sortedColumns}`
      });
    },
    trackSearchAnalytics() {
      this.$appInsights?.trackEvent({
        name: "Manage Slideshow Search Interaction"
      });
    }
  },
  watch: {
    facilityId(value) {
      this.getSlideshows(value);
    }
  }
};
</script>
