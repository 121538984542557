import userService from "@/api/userService";
import permissionsModule from "./permissions";

const user = {
  namespaced: true,
  state: () => ({
    id: null,
    loadingPermissions: false
  }),
  modules: {
    permissions: permissionsModule
  },
  mutations: {
    SET_STATE: (state, resource) => {
      Object.assign(state, resource);
    },
    SET_LOADING_PERMISSIONS: (state, value) => {
      state.loadingPermissions = value;
    }
  },
  getters: {
    loadingPermissions: (state) => state.loadingPermissions
  },
  actions: {
    load: async ({ commit, dispatch }, payload = {}) => {
      const userResponse = await userService.get(payload);
      commit("SET_STATE", userResponse);
      commit("application/SET_IDLE_TIMEOUT", userResponse.idleTimeout, {
        root: true
      });
      commit("SET_LOADING_PERMISSIONS", true);
      await dispatch("permissions/load");
      commit("SET_LOADING_PERMISSIONS", false);
    }
  }
};

export default user;
