<template>
  <div>
    <div v-if="displayNewJobRoleView">
      <manage-obv-job-role
        :facilityId="facilityId"
        :customerId="customerId"
        :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
        @close-create-job-role-view="closeCreateJobRoleView"
      />
    </div>
    <div v-else-if="displayCloneView">
      <clone-obv-job-roles
        :facilityId="facilityId"
        :customerId="customerId"
        :jobRoles="jobRoles"
        :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
        @close-clone-job-role-view="toggleCloneJobRoleView"
      />
    </div>
    <div v-show="!displayNewJobRoleView && !displayCloneView">
      <v-data-table
        class="obv-job-roles-data-table small-headers"
        item-key="id"
        :items="jobRoles"
        :headers="headers"
        :search="search"
        :loading="loading"
        :loading-text="$t('admin.customer-dashboard.job-roles-widget.loading')"
        :no-data-text="$t('admin.customer-dashboard.job-roles-widget.empty')"
        :height="fullscreen ? '55vh' : ''"
        @update:page="setScrollPosition"
      >
        <template v-slot:top>
          <div
            class="
              tw-flex
              tw-justify-between
              tw-items-center
              tw-sticky
              tw-top-0
              tw-bg-white
            "
            style="z-index: 2"
          >
            <v-text-field
              v-model="search"
              :label="`${$t('ui.input-select.search')}`"
              class="tw-mr-4"
              clearable
              prepend-inner-icon="mdi-magnify"
            />
            <v-btn
              v-if="checkPermission(PERMISSIONS.manageJobRoles)"
              class="tw-max-w-xs"
              primary
              color="primary"
              @click="openCreateJobRoleView"
            >
              {{ $t("admin.customer-dashboard.job-roles-widget.new-job-role") }}
            </v-btn>
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item, index }">
          <span v-if="!item.isEditing">{{ item.name }}</span>
          <ValidationObserver v-else :ref="`changeJobRoleName${index}`">
            <v-form @submit.prevent="saveJobRoleName">
              <ValidationProvider
                :vid="`jobRoleTitle`"
                :name="$t('admin.obv-job-role.title')"
                :rules="{ required: true, max: 50 }"
                v-slot="{ errors }"
              >
                <v-text-field
                  :ref="`changeJobRoleNameInput${index}`"
                  v-model="item.newName"
                  :error-messages="[...errors, ...item.textErrors]"
                  :label="$t('admin.obv-job-role.title')"
                  :counter="50"
                  @input="checkDuplicateJobRole(item)"
                />
              </ValidationProvider>
            </v-form>
          </ValidationObserver>
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-switch
            v-model="item.isActive"
            :loading="item.isLoading"
            :disabled="!checkPermission(PERMISSIONS.manageJobRoles)"
            @change="toggleActiveStatus(item)"
          />
        </template>
        <template v-slot:[`item.edit`]="{ item, index }">
          <div class="tw-flex">
            <v-tooltip
              v-if="
                checkPermission(PERMISSIONS.manageJobRoles) && !item.isEditing
              "
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  primary
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleEditing(index)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  $t(
                    "admin.customer-dashboard.job-roles-widget.edit-job-role-name"
                  )
                }}
              </span>
            </v-tooltip>
            <template v-else>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    primary
                    v-bind="attrs"
                    v-on="on"
                    :loading="item.isSaving"
                    @click="saveJobRoleName(item, index)"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t("ui.actions.save") }}
                </span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    primary
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleEditing(index)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span> {{ $t("ui.actions.cancel") }} </span>
              </v-tooltip>
            </template>
          </div>
        </template>
        <template v-slot:[`footer.prepend`]>
          <v-tooltip v-if="checkPermission(PERMISSIONS.cloneJobRoles)" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="toggleCloneJobRoleView()"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t("admin.obv-job-role.clone") }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ManageObvJobRole from "@/views/admin/components/facilityWidgets/manageWidgetViews/ManageObvJobRole.vue";
import CloneObvJobRoles from "@/views/admin/components/facilityWidgets/manageWidgetViews/CloneObvJobRoles.vue";
import obvJobRoleService from "@/api/obvJobRoleService";
import { mapGetters } from "vuex";
import { PERMISSIONS } from "@/types/permissions";
import { orderBy } from "lodash";
import i18n from "@/plugins/i18n";

export default {
  components: {
    ManageObvJobRole,
    CloneObvJobRoles
  },
  props: {
    customerId: { type: Number },
    facilityId: { type: Number },
    fullscreen: { type: Boolean, default: false },
    changeWidgetCurrentTitle: {
      type: Function,
      default() {}
    },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      loading: true,
      PERMISSIONS,
      search: "",
      jobRoles: [],
      headers: [
        {
          text: i18n.t("admin.customer-dashboard.job-roles-widget.job-role"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("admin.customer-dashboard.job-roles-widget.active"),
          value: "isActive",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t(
            "admin.customer-dashboard.job-roles-widget.edit-job-role-name"
          ),
          value: "edit",
          sortable: false,
          class: "tw-align-text-top"
        }
      ],
      displayCloneView: false,
      displayNewJobRoleView: false
    };
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"])
  },
  async mounted() {
    this.loadJobRoles();
  },
  methods: {
    async loadJobRoles() {
      try {
        const jobRoles = orderBy(
          await obvJobRoleService.getJobRolesByFacilityId(this.facilityId),
          ["name"]
        );
        this.jobRoles = jobRoles.map((jobRole) => {
          jobRole.isLoading = false;
          jobRole.isEditing = false;
          jobRole.isSaving = false;
          jobRole.isActive = jobRole.archived === false;
          jobRole.newName = jobRole.name;
          jobRole.textErrors = [];

          return jobRole;
        });
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.job-roles-widget.error-messages.get-job-roles"
          )
        });
      } finally {
        this.loading = false;
      }
    },
    checkDuplicateJobRole(item) {
      const itemNewNameExistsOnOtherJobRole = this.jobRoles.find(
        (i) =>
          i.name.toLowerCase() === item.newName.toLowerCase() &&
          i.id !== item.id
      );

      if (item.newName.length && itemNewNameExistsOnOtherJobRole) {
        item.textErrors = [
          i18n.t("admin.obv-job-role.error-messages.duplicate")
        ];
      } else {
        item.textErrors = [];
      }
    },
    async toggleActiveStatus(item) {
      try {
        item.isLoading = true;
        await obvJobRoleService.setActiveStatus({
          id: item.id,
          facilityId: item.facilityId,
          archived: !item.isActive
        });
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.customer-dashboard.job-roles-widget.error-messages.set-active-status"
          )
        });
        item.isActive = !item.isActive;
      } finally {
        item.isLoading = false;
      }
    },
    async saveJobRoleName(item, index) {
      item.isSaving = true;
      const formRef = [`changeJobRoleName${index}`];
      const valid = await this.$refs[formRef].validate();
      if (!valid) {
        this.$appInsights?.trackEvent({
          name: `Update OBV Job Role Name Form Error`
        });
        return;
      }

      const newJobRole = {
        ...item,
        ...{ name: item.newName, isEditing: false, isSaving: false }
      };
      try {
        await obvJobRoleService.updateJobRoleName(newJobRole);
        this.$appInsights?.trackEvent({
          name: `Update OBV Job Role Name Form Success`
        });
      } catch (error) {
        const duplicateNameError = error === 409;

        if (duplicateNameError) {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("admin.obv-job-role.error-messages.duplicate")
          });

          this.$appInsights?.trackEvent({
            name: `Update OBV Job Role Name Conflict Error`
          });
        } else {
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t("admin.obv-job-role.error-messages.save")
          });

          this.$appInsights?.trackEvent({
            name: `Update OBV Job Role Name Server Error`
          });
        }

        item.isSaving = false;
        return;
      }

      const newJobRoles = [...this.jobRoles];
      newJobRoles[index] = newJobRole;
      this.jobRoles = newJobRoles;
    },
    async toggleEditing(index) {
      const newJobRoles = [...this.jobRoles];
      newJobRoles[index].isEditing = !newJobRoles[index].isEditing;
      newJobRoles[index].newName = newJobRoles[index].name;
      this.jobRoles = newJobRoles;

      if (this.jobRoles[index].isEditing) {
        await this.$nextTick();
        this.$refs[`changeJobRoleNameInput${index}`].focus();
      }
    },
    setScrollPosition() {
      document.querySelector(
        "div.obv-job-roles-data-table div.v-data-table__wrapper"
      ).scrollTop = 0;
    },
    openCreateJobRoleView() {
      this.displayNewJobRoleView = true;
    },
    closeCreateJobRoleView() {
      this.displayNewJobRoleView = false;
      this.loadJobRoles();
    },
    toggleCloneJobRoleView() {
      this.displayCloneView = !this.displayCloneView;
      const title = this.displayCloneView
        ? "admin.facility-dashboard.clone-obv-job-roles"
        : "admin.facility-dashboard.obv-job-roles";

      this.changeWidgetCurrentTitle(title);
    }
  }
};
</script>
