import { defaultsDeep } from "lodash";
import { convertToChartjsScale } from "./chartjsBuilder";

const SCALE_DEFAULTS = {
  fontColor: "black",
  fontStyle: "bold",
  fontSize: 16
};

const LINEAR_DEFAULTS = {
  type: "linear",
  offset: false,
  ticks: {
    beginAtZero: true,
    suggestedMin: 0,
    suggestedMax: 100
  }
};

const CATEGORY_DEFAULTS = {
  type: "category",
  offset: true
};

const DATETIME_DEFAULTS = {
  type: "time",
  offset: false
};

const SLIDESHOW_SCALE_DEFAULTS = {
  fontSize: 34,
  ticks: {
    fontSize: 26,
    fontColor: "black"
  },
  gridLines: {
    lineWidth: 2,
    tickMarkLength: 20
  }
};

function getBaseScaleOptions(scaleOptions, isSlideshow) {
  const options = defaultsDeep({}, scaleOptions);
  if (isSlideshow) {
    defaultsDeep(options, SLIDESHOW_SCALE_DEFAULTS);
  }
  return options;
}

class ChartScale {
  scaleOptions;
  #position;
  #axis;
  static #scaleIndex = 0;
  #scaleId;

  constructor(scaleOptions, isSlideshow, axis, position) {
    this.#position = position;
    this.#axis = axis;
    this.#scaleId = `${this.#axis}-axis-${ChartScale.#scaleIndex}`;
    this.scaleOptions = getBaseScaleOptions(scaleOptions, isSlideshow);
    ChartScale.#scaleIndex++;
  }

  get scaleId() {
    return this.#scaleId;
  }

  get axis() {
    return this.#axis;
  }

  convertToChartScale() {
    return convertToChartjsScale(
      this.scaleOptions,
      this.#position,
      this.#scaleId
    );
  }
}

export class ChartScaleLinear extends ChartScale {
  #goal;
  #performanceBands;
  #performanceThresholds;

  constructor(scaleOptions, isSlideshow, axis, position) {
    super(scaleOptions, isSlideshow, axis, position);
    defaultsDeep(this.scaleOptions, LINEAR_DEFAULTS, SCALE_DEFAULTS);
  }

  get goal() {
    return this.#goal;
  }

  get performanceBands() {
    return this.#performanceBands;
  }

  get performanceThresholds() {
    return this.#performanceThresholds;
  }

  showGoal(goal) {
    this.#goal = goal;
    return this;
  }

  showPerformanceBands(performanceBands = []) {
    this.#performanceBands = performanceBands;
    return this;
  }

  showPerformanceThresholds(performanceThresholds = []) {
    this.#performanceThresholds = performanceThresholds;
    return this;
  }
}

export class ChartScaleCategory extends ChartScale {
  constructor(scaleOptions, isSlideshow, axis, position) {
    super(scaleOptions, isSlideshow, axis, position);
    defaultsDeep(this.scaleOptions, CATEGORY_DEFAULTS, SCALE_DEFAULTS);
  }
}

export class ChartScaleTimeSeries extends ChartScale {
  constructor(scaleOptions, isSlideshow, axis, position, timeframe) {
    super(scaleOptions, isSlideshow, axis, position);
    defaultsDeep(this.scaleOptions, DATETIME_DEFAULTS, SCALE_DEFAULTS);
    this.scaleOptions.timeframe = timeframe;
  }
}
