<template>
  <default-layout>
    <v-skeleton-loader v-if="!requiredRouteMetaAvailable" />
    <router-view v-else />
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/Default";
import { registerReportsModules } from "@/views/reports/store/registerReportsModules";
import { makeCustomersModule } from "@/store/modules/customers";
import { makeFacilitiesModule } from "@/store/modules/facilities";
import addFiltersStore from "@/store/modules/filters/filters";
import PERMISSION_CHECKS from "@/types/permissions";

export default {
  components: {
    DefaultLayout
  },

  computed: {
    requiredRouteMetaAvailable() {
      return (
        this.$route.meta?.permission !== undefined &&
        this.$route.meta?.product !== undefined
      );
    }
  },
  beforeCreate() {
    this.$store.registerModule("customers", makeCustomersModule());
    this.$store.registerModule("facilities", makeFacilitiesModule());
  },
  watch: {
    requiredRouteMetaAvailable: {
      immediate: true,
      async handler(value) {
        if (value) {
          const defaultFilters = this.$route?.query?.filters
            ? JSON.parse(this.$route?.query?.filters)
            : {};
          addFiltersStore(this.$store, { defaultFilters });
          registerReportsModules(this.$store);
          this.$store.commit(
            "customers/setPrivilegeModel",
            PERMISSION_CHECKS[this.$route.meta?.permission]
          );
          this.$store.commit(
            "customers/setProductId",
            this.$route.meta?.product
          );
          await this.$store.dispatch(
            "customers/load",
            this.$store.state.customers.privilegeModel
          );
          await this.$store.dispatch("customers/setDefaultCustomer", {
            customerId: this.$route.query?.customerId,
            facilityId: this.$route.query?.facilityId
          });
        }
      }
    }
  }
};
</script>
