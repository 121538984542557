<template>
  <no-header-footer>
    <router-view />
  </no-header-footer>
</template>

<script>
import NoHeaderFooter from "@/layouts/NoHeaderFooter.vue";

export default {
  components: { NoHeaderFooter }
};
</script>
