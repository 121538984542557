<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="error" v-if="value < -3" v-bind="attrs" v-on="on">
        mdi-arrow-down-bold
      </v-icon>
      <v-icon color="success" v-else-if="value > 3" v-bind="attrs" v-on="on">
        mdi-arrow-up-bold
      </v-icon>
      <v-icon v-else v-bind="attrs" v-on="on">mdi-minus</v-icon>
    </template>
    <span>{{ value.toFixed(1) }}%</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>
.v-icon {
  left: 50%;
  transform: translateX(-50%);
}
</style>
