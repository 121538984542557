var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"fullReportSubscriptionData"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveSubscription.apply(null, arguments)}}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("report-subscriptions.details"))+" ")]),_c('ValidationProvider',{attrs:{"vid":"subscriptionName","name":_vm.$t('report-subscriptions.name'),"rules":{ required: true, max: _vm.nameMax }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"counter":_vm.nameMax,"label":_vm.$t('report-subscriptions.name'),"error-messages":errors,"required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('report-subscriptions.recipients'),"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"auto-select-first":"","error-messages":errors,"items":_vm.recipients,"label":_vm.$t('report-subscriptions.recipients'),"item-text":(item) =>
              `${item.firstName ? item.firstName : ''} ${
                item.lastName ? item.lastName : ''
              } (${item.email})`,"chips":"","deletable-chips":"","clearable":"","item-value":"userId","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('filters-popup-header',{attrs:{"allIds":_vm.recipients.map((r) => r.userId)},model:{value:(_vm.selectedRecipients),callback:function ($$v) {_vm.selectedRecipients=$$v},expression:"selectedRecipients"}})]},proxy:true}],null,true),model:{value:(_vm.selectedRecipients),callback:function ($$v) {_vm.selectedRecipients=$$v},expression:"selectedRecipients"}})]}}])}),_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-justify-between"},[_c('ValidationProvider',{staticClass:"md:tw-mr-2 tw-flex-1",attrs:{"name":_vm.$t('report-subscriptions.email-frequency'),"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"label":_vm.$t('report-subscriptions.email-frequency'),"items":_vm.availableFrequencies.map((f) => ({
                text: _vm.$t(f.text),
                value: f.value
              })),"items-text":"text","items-value":"value"},model:{value:(_vm.frequency),callback:function ($$v) {_vm.frequency=$$v},expression:"frequency"}})]}}])}),(_vm.showWeekly(_vm.frequency))?_c('div',{staticClass:"md:tw-mx-2 tw-flex-1"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('report-subscriptions.actions.select-day'),"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"label":_vm.$t('report-subscriptions.actions.select-day'),"items":_vm.daysOfWeek.map((d) => ({
                  text: _vm.$t(`days-of-week.full.${d.translationKey}`),
                  value: d.value
                })),"items-text":"text","items-value":"value"},model:{value:(_vm.dayOfWeek),callback:function ($$v) {_vm.dayOfWeek=$$v},expression:"dayOfWeek"}})]}}],null,false,2424183408)})],1):_vm._e(),_c('ValidationProvider',{staticClass:"md:tw-ml-2 tw-flex-1",attrs:{"vid":"subscriptionTimeOfDay","name":_vm.$t('report-subscriptions.time-of-day'),"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"label":_vm.$t('report-subscriptions.time-of-day'),"error-messages":errors,"items":_vm.hoursOfDay,"items-text":"text","items-value":"value","hint":_vm.facilityTimeZone,"persistent-hint":""},model:{value:(_vm.timeOfDay),callback:function ($$v) {_vm.timeOfDay=$$v},expression:"timeOfDay"}})]}}])})],1),(_vm.showTimeframe)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("report-subscriptions.additional-report-filter")))]),_c('v-select',{attrs:{"mandatory":"","label":_vm.$t('filters.timeframe'),"items":_vm.timeframes.map((t) => ({
              text: _vm.$t(`timeframes.${t.translationKey}`),
              value: t.value
            })),"items-text":"text","items-value":"value"},model:{value:(_vm.timeframe),callback:function ($$v) {_vm.timeframe=$$v},expression:"timeframe"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }