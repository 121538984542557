import { remove, get, post, put } from "./apiClient";

export default {
  async get() {
    return await get(`user`);
  },
  async resetAuthCookie() {
    await post("user/resetAuthCookie");
  },
  async getUsersByCustomerId(customerId) {
    return await get(
      `userMaintenance/registeredUsers?customerId=${customerId}`
    );
  },
  async getPendingUsersByCustomerId(customerId) {
    return await get(`userMaintenance/pendingUsers?customerId=${customerId}`);
  },
  async validateEmailAddress(email, customerId) {
    try {
      const user = await get(
        `userMaintenance/FindUser?email=${email}&customerId=${customerId}`
      );
      return {
        exists: true,
        user
      };
    } catch (statusCode) {
      if (statusCode === 404) {
        return {
          exists: false
        };
      } else if (statusCode === 409) {
        return {
          exists: true
        };
      }
    }
  },
  async getExistingUser(username, customerId) {
    return await get(
      `userMaintenance?username=${encodeURIComponent(
        username
      )}&customerId=${customerId}`
    );
  },
  async saveUser(user) {
    if (user.userName) {
      return await put(`userMaintenance`, user);
    } else {
      return await post(`userMaintenance`, user);
    }
  },
  async getUserReportSubscriptions(username) {
    return await get(
      `userMaintenance/getReportSubscriptions?username=${username}`
    );
  },
  async setUserActiveStatus(username, status, force) {
    await put(
      `userMaintenance/setActiveStatus?username=${username}&isActive=${status}&force=${force}`
    );
  },
  async resetAccountLockout(username, customerId) {
    return await put(
      `userMaintenance/Reset?username=${username}&customerId=${customerId}`
    );
  },
  async resendEmail(customerId, userName) {
    await put(`userMaintenance/resend`, { customerId, userName });
  },
  async revokePendingUser(user) {
    await remove(`userMaintenance`, user);
  }
};
