<template>
  <div>
    <v-snackbar color="primary" top v-model="showSuccessMessage" timeout="5000">
      {{ successMessage }}
    </v-snackbar>
    <facility-selection-bar class="tw-my-8" />
    <header>
      <h1>{{ $t("report-subscriptions.manage") }}</h1>
    </header>
    <deleteDialog
      @deletion-confirmed="deleteSubscription"
      @toggle-delete-dialog="toggleDeleteDialog"
      :deleteDialogData="deleteDialogData"
      :loading="deleteLoading"
    />
    <v-data-table
      :items="items"
      :headers="headers"
      :search="search"
      :loading="loading"
      :loading-text="$t('report-subscriptions.loading')"
      :no-data-text="$t('report-subscriptions.empty')"
    >
      <template v-slot:top>
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            @focus="trackSearchAnalytics()"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
          />
          <v-btn
            class="tw-max-w-xs"
            primary
            color="primary"
            :to="{
              name: 'reportSubscriptionCreate',
              query: { facilityId }
            }"
          >
            {{ $t("report-subscriptions.actions.new") }}
          </v-btn>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td v-if="checkPermission(PERMISSIONS.administerCustomer)">
            {{ item.creator }}
          </td>
          <td>
            {{
              item.recipients.remaining === 0
                ? item.recipients.name
                : `${item.recipients.name} (+${$tc(
                    "count.other",
                    item.recipients.remaining
                  )})`
            }}
          </td>
          <td>{{ $t(item.frequency) }}</td>
          <td>
            {{
              item.locations.remaining === 0
                ? item.locations.name
                : `${item.locations.name} (+${$tc(
                    "count.other",
                    item.locations.remaining
                  )})`
            }}
          </td>
          <td>{{ $t(item.reportType) }}</td>
          <td>
            <router-link
              v-if="
                userId === item.userId ||
                checkPermission(PERMISSIONS.administerCustomer)
              "
              class="tw-no-underline"
              :to="{
                name: 'reportSubscriptionEdit',
                query: { reportSubscriptionId: item.id, facilityId }
              }"
            >
              <v-icon>mdi-pencil</v-icon>
            </router-link>
          </td>
          <td>
            <v-btn
              v-if="
                userId === item.userId ||
                checkPermission(PERMISSIONS.administerCustomer)
              "
              icon
              @click="toggleDeleteDialog(item.id, item.name)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DeleteDialog from "@/views/slideshows/components/DeleteDialog.vue";
import FacilitySelectionBar from "@/components/layout/FacilitySelectionBar.vue";
import reportSubscriptionService from "@/api/reportSubscriptionService";
import { FREQUENCIES } from "@/types/frequencies";
import i18n from "@/plugins/i18n";
import { mapGetters, mapState } from "vuex";
import { FILTERS } from "../../types/filters";
import find from "lodash/find";
import { PERMISSIONS } from "@/types/permissions";

const frequencies = {
  [FREQUENCIES.DAILY]: "time.daily",
  [FREQUENCIES.WEEKLY]: "time.weekly",
  [FREQUENCIES.MONTHLY]: "time.monthly"
};

export default {
  props: {
    facility: {
      type: Object
    },
    facilityId: {
      type: Number
    }
  },
  components: {
    FacilitySelectionBar,
    DeleteDialog
  },
  data: () => ({
    deleteDialogData: {
      active: false,
      content: i18n.t("report-subscriptions.delete-dialog.body"),
      id: null,
      name: "",
      title: i18n.t("report-subscriptions.delete-dialog.title")
    },
    deleteLoading: false,
    items: [],
    loading: true,
    search: "",
    showSuccessMessage: false,
    success: false,
    successMessage: "",
    PERMISSIONS
  }),
  mounted() {
    if (this.facility?.id) {
      this.getReportSubscriptions(this.facility.id);
    }

    if (this.$route.params.successMessage) {
      this.showSuccessMessage = true;
      this.successMessage = this.$route.params.successMessage;
    }
  },
  watch: {
    facilityId() {
      this.loading = true;
      this.items = [];
    },
    facility() {
      this.getReportSubscriptions(this.facilityId);
    }
  },
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"]),
    ...mapState("user", {
      userId: (state) => state.id
    }),
    headers() {
      const headers = [
        {
          text: i18n.t("report-subscriptions.list-table-columns.name"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("report-subscriptions.list-table-columns.recipients"),
          value: "recipients",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("report-subscriptions.list-table-columns.frequency"),
          value: "frequency",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("report-subscriptions.list-table-columns.locations"),
          value: "locations",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("report-subscriptions.list-table-columns.type"),
          value: "reportType",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("slideshows.edit"),
          sortable: false,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("slideshows.delete"),
          sortable: false,
          class: "tw-align-text-top"
        }
      ];

      if (this.checkPermission(PERMISSIONS.administerCustomer)) {
        headers.splice(1, 0, {
          text: i18n.t("report-subscriptions.list-table-columns.creator"),
          value: "creator",
          sortable: true,
          class: "tw-align-text-top"
        });
      }

      return headers;
    }
  },
  methods: {
    async deleteSubscription() {
      const id = this.deleteDialogData.id;
      try {
        this.deleteLoading = true;
        await reportSubscriptionService.deleteReportSubscription(id);
        this.items = this.items.filter((item) => item.id !== id);

        this.showSuccessMessage = true;
        this.successMessage = `${this.deleteDialogData.name} ${i18n.t(
          "report-subscriptions.delete-dialog.success"
        )}`;
        this.$appInsights?.trackEvent({
          name: `Report Subscription Successfully Deleted`
        });
        this.toggleDeleteDialog();
      } catch (err) {
        this.deleteLoading = false;
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("report-subscriptions.errors.delete-report")
        });
      }
    },
    async getReportSubscriptions(facilityId) {
      try {
        this.loading = true;
        const reportSubscriptions =
          await reportSubscriptionService.getSubscriptionsForFacility(
            facilityId
          );

        this.createSubscriptionItems(reportSubscriptions);
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("report-subscriptions.errors.report-subscriptions")
        });
      } finally {
        this.loading = false;
      }
    },
    createSubscriptionItems(reportSubscriptions) {
      this.items = reportSubscriptions.map((subscription) => {
        return {
          id: subscription.id,
          name: subscription.name,
          userId: subscription.userId,
          creator: `${subscription.userFirstName} ${subscription.userLastName}`,
          recipients: this.subscriptionRecipientsToString(
            subscription.recipients
          ),
          frequency: frequencies[subscription.frequencyId],
          locations: this.subscriptionLocationsToString(
            subscription.selectedLocationIds
          ),
          reportType: this.subscriptionReportTypeToString(
            subscription.selectedReportTypeId
          )
        };
      });
    },
    subscriptionRecipientsToString(recipients) {
      if (recipients.length) {
        return {
          name: `${recipients[0].firstName} ${recipients[0].lastName}`,
          remaining: recipients.length - 1
        };
      }

      return { name: "", remaining: 0 };
    },
    subscriptionLocationsToString(locations) {
      if (locations.length) {
        return locations.length ===
          this.$store.state[`filters/${FILTERS.LOCATIONS}`].allIds.length
          ? { name: i18n.t("ui.all-selected"), remaining: 0 }
          : {
              name: find(
                this.$store.state[`filters/${FILTERS.LOCATIONS}`].byId,
                (l) => l.id === locations[0]
              )?.text,
              remaining: locations.length - 1
            };
      }

      return { name: "", remaining: 0 };
    },
    subscriptionReportTypeToString(id) {
      const reportType = find(this.facility.reportTypes, (rt) => rt.id === id);

      if (reportType) {
        return `report-subscriptions.types.${reportType.translationKey}`;
      } else {
        return "";
      }
    },
    toggleDeleteDialog(id, name) {
      this.deleteDialogData.active = !this.deleteDialogData.active;
      this.deleteDialogData.id = id;
      this.deleteDialogData.name = name;
      this.deleteLoading = false;

      const deleteDialogStatus = this.deleteDialogData.active
        ? "Open"
        : "Close";

      this.$appInsights?.trackEvent({
        name: `${deleteDialogStatus} Report Subscription Delete Dialog`
      });
    },
    trackSearchAnalytics() {
      this.$appInsights?.trackEvent({
        name: "Report Subscription Search Interaction"
      });
    }
  }
};
</script>

<style scoped lang="postcss">
:deep(.v-snack__wrapper) {
  min-width: 0px;
}

:deep(.v-data-table__wrapper) {
  overflow-x: hidden;
}
</style>
