<template>
  <v-dialog @input="resetAuthCookie" v-model="userIsTimingOut" width="500">
    <v-card>
      <v-card-title>
        {{ $t("idle-timeout.warning") }}
      </v-card-title>
      <v-card-text>
        {{ $t("idle-timeout.directions", { timer: this.countdownSeconds }) }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="resetAuthCookie">
          {{ $t("idle-timeout.continue") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userService from "@/api/userService";
import storageKeys from "@/utils/browserStorage/storageKeys";
import { mapState } from "vuex";
import { debounce } from "lodash";

const idleDebounceTime = 10000;

export default {
  data: () => ({
    oneSecond: 1000,
    oneMinute: 60000,
    showIdleTimeoutDialogTimerId: null,
    secondsCountdownTimerId: null,
    userIsTimingOut: false,
    countdownSeconds: 0
  }),
  computed: {
    ...mapState("user", ["idleTimeout"]),
    timeoutGracePeriod() {
      return this.oneSecond * 20; // smaller values can be too short depending on call timings
    },
    userWarningPeriod() {
      return this.oneMinute;
    },
    frontendIdleTimeout() {
      return (
        this.idleTimeout - (this.userWarningPeriod + this.timeoutGracePeriod)
      );
    }
  },
  beforeMount() {
    const debouncedIdleTimeoutReset = debounce(
      this.handleIdleTimeoutResetEvent,
      idleDebounceTime,
      { leading: true, trailing: true }
    );
    window.addEventListener("apiRequestTime", (event) => {
      debouncedIdleTimeoutReset(event);
    });
    window.addEventListener("storage", (event) => {
      this.storageIdleTimeoutChanged(event);
    });
  },
  methods: {
    notifyOtherTabsOfNewCallTime(lastCallTime) {
      localStorage.setItem(storageKeys.expiration.lastCallTime, lastCallTime);
    },
    getLatestStoredApiCallTime() {
      return parseInt(
        localStorage.getItem(storageKeys.expiration.lastCallTime) ?? 0
      );
    },
    handleIdleTimeoutResetEvent(event) {
      const requestTime = event.detail.requestTime;
      this.clearTimers();
      const storedLastCallTime = this.getLatestStoredApiCallTime();
      if (storedLastCallTime < requestTime) {
        this.notifyOtherTabsOfNewCallTime(requestTime);
      }
      this.setIdleTimeout(Math.max(requestTime, storedLastCallTime));
    },
    setIdleTimeout(lastCallTime) {
      const millisecondsUntilTimeoutNotice =
        this.frontendIdleTimeout - (Date.now() - lastCallTime);
      this.showIdleTimeoutDialogTimerId = setTimeout(
        this.showTimeoutDialog,
        millisecondsUntilTimeoutNotice
      );
    },
    storageIdleTimeoutChanged(event) {
      if (
        event.storageArea === localStorage &&
        event.key === storageKeys.expiration.lastCallTime
      ) {
        this.clearTimers();
        this.setIdleTimeout(event.newValue);
      }
    },
    clearTimers() {
      this.userIsTimingOut = false;
      clearTimeout(this.showIdleTimeoutDialogTimerId);
      this.showIdleTimeoutDialogTimerId = null;
      clearInterval(this.secondsCountdownTimerId);
      this.secondsCountdownTimerId = null;
    },
    showTimeoutDialog() {
      const currentTimeStamp = Date.now();
      const lastCallTime = this.getLatestStoredApiCallTime();
      const userHasTimedOut =
        lastCallTime + this.idleTimeout - this.timeoutGracePeriod <
        currentTimeStamp;

      if (userHasTimedOut) {
        this.$appInsights?.trackEvent({
          name: `User Timed Out With No Timeout Dialog`
        });
        this.logOut();
      }

      this.$appInsights?.trackEvent({
        name: `Timeout Dialog Shown`
      });

      this.adjustCountdownSeconds();
      this.secondsCountdownTimerId = setInterval(
        this.adjustCountdownSeconds,
        this.oneSecond
      );
    },
    async logOut() {
      const returnUrl = `${window.location.pathname}${window.location.search}`;
      window.location.assign(`/Login?timeout=true&returnUrl=${returnUrl}`);
    },
    adjustCountdownSeconds() {
      const lastCallTime = this.getLatestStoredApiCallTime();
      this.countdownSeconds = this.secondsUntilTimeout(lastCallTime);
      if (this.countdownSeconds > 0) {
        this.userIsTimingOut = true;
      } else {
        this.clearTimers();
        this.logOut();
      }
    },
    async resetAuthCookie() {
      this.$appInsights?.trackEvent({
        name: `User Reset Auth Cookie`
      });

      try {
        await userService.resetAuthCookie();
      } catch {
        this.logOut();
      }
    },
    secondsUntilTimeout(lastCallTime) {
      return Math.max(
        0,
        Math.floor(
          (lastCallTime +
            this.idleTimeout -
            this.timeoutGracePeriod -
            Date.now()) /
            1000.0
        )
      );
    }
  }
};
</script>
