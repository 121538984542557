export const ROUTE_NAMES = {
  adminCustomerList: "adminCustomerList",
  alertSubscriptions: "alertSubscriptions",
  allProductsUnlicensed: "allProductsUnlicensed",
  amsReports: "amsReports",
  createAlertSubscription: "createAlertSubscription",
  createCustomer: "createCustomer",
  createFacility: "createFacility",
  customerDashboard: "customerDashboard",
  deviceMaintenance: "deviceMaintenance",
  downloads: "downloads",
  editAlertSubscription: "editAlertSubscription",
  editCustomer: "editCustomer",
  editFacility: "editFacility",
  facilityDashboard: "facilityDashboard",
  forgotPassword: "forgotPassword",
  imsReports: "imsReports",
  login: "login",
  manageAccount: "manageAccount",
  obvReports: "obvReports",
  obvSystem: "obvSystem",
  redirectionRoute: "redirectionRoute",
  registerAccount: "registerAccount",
  reports: "reports",
  reportSubscriptionCreate: "reportSubscriptionCreate",
  reportSubscriptionEdit: "reportSubscriptionEdit",
  reportSubscriptions: "reportSubscriptions",
  resetPassword: "resetPassword",
  saReports: "saReports",
  slideshowCreate: "slideshowCreate",
  slideshowEdit: "slideshowEdit",
  slideshowsList: "slideshowsList",
  slideshowView: "slideshowView",
  systemStatus: "systemStatus",
  systemStatusHistory: "systemStatusHistory"
};
