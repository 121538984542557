import { get, put, post, remove } from "./apiClient";

export default {
  async getSlideshowsForFacility(facilityId) {
    return await get(`reportSlideshow?facilityId=${facilityId}`);
  },

  async getSlideshowsForFacilityBySlideId(slideId, facilityId) {
    return await get(`slide/${slideId}/facility/${facilityId}`);
  },

  async getSlideshow(id) {
    return await get(`reportSlideshow/${id}`);
  },

  async setSlideshowActiveStatus(id, activeStatus) {
    return await put(`reportSlideshow/${id}?isActive=${activeStatus}`);
  },

  async deleteSlideshow(id) {
    return await remove(`reportSlideshow/${id}`);
  },

  async deleteSlide(id) {
    return await remove(`slide/${id}`);
  },

  async getSlide(id) {
    return await get(`slide/${id}`);
  },

  async saveSlideshow(data) {
    const url = `reportSlideshow/save`;

    if (data.id) {
      return await put(url, data);
    } else {
      return await post(url, data);
    }
  }
};
