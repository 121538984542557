<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          name="createReportUrl"
          depressed
          color="secondary"
          @click="createReportUrl"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="tw-mr-2"> mdi-content-save </v-icon>
          {{ $t("save-report.button") }}
        </v-btn>
      </template>
      <span>
        {{ $t("save-report.tooltip") }}
      </span>
    </v-tooltip>
    <v-snackbar
      @input="showDirections = false"
      :value="showDirections"
      color="primary"
      :top="true"
      :timeout="10000"
      class="tw-p-12"
    >
      {{ $t("save-report.directions") }}
    </v-snackbar>
  </div>
</template>

<script>
import { generateReportUrl } from "@/views/reports/utils/urlGenerator";
export default {
  data: () => ({
    showDirections: false,
    queryParams: ""
  }),
  methods: {
    generateReportUrl,
    async createReportUrl() {
      this.$appInsights?.trackEvent({ name: "Save Report Button Clicked" });
      const urlObj = this.generateReportUrl();
      if (!urlObj) {
        this.showCopyFailed = true;
      }

      this.queryParams = urlObj.queryParams;
      this.$router.push({ path: this.queryParams }).catch(() => {});
      this.showDirections = true;
    }
  }
};
</script>
