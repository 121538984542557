export const IMS_REPORTS = {
  INDIVIDUAL_PERFORMANCE: 37,
  JOB_ROLE_PERFORMANCE: 38,
  GROUP_PERFORMANCE: 39,
  PRODUCT_USAGE: 40,
  INDIVIDUAL_TRENDS: 41
};

export const IMS_REPORT_COMPONENT_NAMES = {
  [IMS_REPORTS.INDIVIDUAL_PERFORMANCE]: "IndividualPerformance",
  [IMS_REPORTS.JOB_ROLE_PERFORMANCE]: "JobRolePerformance",
  [IMS_REPORTS.GROUP_PERFORMANCE]: "GroupPerformance",
  [IMS_REPORTS.PRODUCT_USAGE]: "ProductUsage",
  [IMS_REPORTS.INDIVIDUAL_TRENDS]: "IndividualTrends"
};

export const IMS_REPORT_DISPLAY_NAMES = {
  [IMS_REPORTS.INDIVIDUAL_PERFORMANCE]: "reports.ims.individual-performance",
  [IMS_REPORTS.JOB_ROLE_PERFORMANCE]: "reports.ims.job-role-performance",
  [IMS_REPORTS.GROUP_PERFORMANCE]: "reports.ims.group-performance",
  [IMS_REPORTS.PRODUCT_USAGE]: "reports.ims.product-usage",
  [IMS_REPORTS.INDIVIDUAL_TRENDS]: "reports.ims.individual-trends"
};

// These values MUST match the reportName in the `dbo.Reports.sql` file
export const IMS_REPORT_RAW_EXPORT_NAMES = {
  [IMS_REPORTS.INDIVIDUAL_PERFORMANCE]: "IMS Individual Performance Report",
  [IMS_REPORTS.JOB_ROLE_PERFORMANCE]: "IMS Job Role Performance Report",
  [IMS_REPORTS.GROUP_PERFORMANCE]: "IMS Group Performance Report",
  [IMS_REPORTS.PRODUCT_USAGE]: "IMS Product Usage Report",
  [IMS_REPORTS.INDIVIDUAL_TRENDS]: "IMS Individual Trends Report"
};
