import { TIMEFRAMES } from "@/types/timeframes";

export function reportDateRangeConverter(timeframe = TIMEFRAMES.LAST_7_DAYS) {
  switch (timeframe) {
    case TIMEFRAMES.CUSTOM:
      return "custom";
    case TIMEFRAMES.LAST_24_HOURS:
      return "last24Hours";
    case TIMEFRAMES.TODAY:
      return "today";
    case TIMEFRAMES.YESTERDAY:
      return "yesterday";
    case TIMEFRAMES.LAST_7_DAYS:
      return "lastSevenDays";
    case TIMEFRAMES.LAST_30_DAYS:
      return "lastThirtyDays";
    case TIMEFRAMES.LAST_90_DAYS:
      return "lastNinetyDays";
    case TIMEFRAMES.LAST_6_MONTHS:
      return "lastSixMonths";
    case TIMEFRAMES.LAST_12_MONTHS:
      return "lastTwelveMonths";
  }
}
