<template>
  <report-graph
    :chartData="chartData"
    :ratesSwitchLabel="ratesSwitchLabel"
    :instructionsCard="instructionsCard"
    :repositionSpeedDial="true"
  />
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";

import { stringSort } from "@/views/reports/utils/sort";
import { mapState } from "vuex";
import ChartBuilder, {
  chartColorChoices
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import { groupBy } from "lodash";
import i18n from "@/plugins/i18n";
import { TIMEFRAMES } from "@/types/timeframes";

export default {
  mixins: [reportMixin],
  components: {
    ReportGraph
  },
  data() {
    return {
      compatibleReportId: 6,
      reportFilters: ["shifts", "timeframe", "devices"],
      hiddenTimeframes: [
        TIMEFRAMES.LAST_90_DAYS,
        TIMEFRAMES.LAST_6_MONTHS,
        TIMEFRAMES.LAST_12_MONTHS
      ],
      hiddenReportActions: ["csvExport", "rawExport", "saveSlide"],
      items: [],
      chartData: {},
      ratesSwitchLabel: i18n.t("reports.graph.display-count"),
      suggestedMax: 60
    };
  },
  computed: {
    ...mapState("filters/devices", {
      activeDeviceIds: (state) => state.activeIds
    }),
    instructionsCard() {
      const instructionsCard = {
        heading: i18n.t("reports.device-metrics.directions-header"),
        body: i18n.t("reports.device-metrics.directions-body")
      };

      return this.activeDeviceIds.length ? null : instructionsCard;
    }
  },
  methods: {
    handleReportResponse() {
      this.items = stringSort({
        items: this.reportResponse.dataPoints,
        sortFields: ["locationName"]
      }).map((item) => {
        return {
          ...item,
          usesPrimaryColor: item.ecmDeviceType === "Dispenser"
        };
      });

      if (this.items.length) {
        const allEventsOpps = this.items.map((item) => item.eventsOpps);
        this.suggestedMax = Math.max.apply(0, allEventsOpps) + 10;
      }

      const builder = new ChartBuilder({
        isSlideshow: this.isSlideshow,
        customLegendGenerator: this.customLegendGenerator,
        isPrint: this.isPrint
      });

      builder.deviceMetrics({
        items: this.items,
        valueField: "eventsOpps",
        datasetField: "locationName",
        categoryField: "ecmDeviceName",
        valueScaleOptions: {
          label: i18n.t("reports.graph.metrics-axis"),
          ticks: {
            format: "${value}",
            suggestedMin: 0,
            suggestedMax: this.suggestedMax
          }
        }
      });

      this.chartData = builder.build();
    },
    customLegendGenerator() {
      const groupedEntries = this.items.length
        ? groupBy(this.items, "locationName")
        : {};
      let colorGroupingIndex = 0;

      const labels = [];
      for (const label of Object.keys(groupedEntries)) {
        labels.push({
          text: label,
          fillStyle: chartColorChoices[colorGroupingIndex][0]
        });

        colorGroupingIndex =
          colorGroupingIndex + 1 < chartColorChoices.length
            ? colorGroupingIndex + 1
            : 0;
      }

      return labels;
    }
  }
};
</script>
