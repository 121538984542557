<template>
  <div class="tw-mr-4 lg:tw-mr-0 xxl:tw-w-full">
    <v-subheader v-if="activator === 'v-btn'">
      {{ $t(label) }}
    </v-subheader>
    <v-menu
      v-model="opened"
      :offset-x="offsetX"
      close-on-click
      :close-on-content-click="false"
      content-class="tw-bg-white"
      @input="toggleClosed"
      v-bind="menuProps"
      ref="filterMenu"
      :min-width="minWidth"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="activator === 'v-btn'"
          outlined
          block
          v-on="on"
          @click="$emit('click')"
          :color="color"
          class="edit-filter text-capitalize tw-block tw-max-w-full"
          width="175"
        >
          <span class="tw-font-bold tw-truncate">{{ filterText }}</span>
        </v-btn>
        <template v-else>
          <v-text-field
            v-on="on"
            :label="$t(label)"
            :value="filterText"
            append-icon="mdi-menu-down"
            type="button"
            readonly
            @click="$emit('click')"
            @click:append="toggleClosed(true)"
            v-bind="textFieldProps"
            class="tw-truncate"
          />
        </template>
      </template>
      <v-list-item>
        <v-list-item-content>
          <v-text-field
            :label="$t('ui.input-select.search')"
            v-model="search"
            filled
            hide-details
            dense
            append-icon="mdi-magnify"
            autofocus
          />
        </v-list-item-content>
      </v-list-item>
      <filters-popup-header
        ref="my-filter-popup-header"
        v-model="internalSelected"
        :hierarchyItems="
          selectionComponent === 'hierarchy-selection' ? items : []
        "
        :allIds="allIds"
        :allOpen.sync="allOpen"
        :showSelectAll="!singleSelect"
      />
      <div class="limit-height">
        <v-treeview
          v-if="selectionComponent === 'hierarchy-selection'"
          ref="treeview"
          v-model="internalSelected"
          :selectable="!singleSelect"
          :activatable="singleSelect"
          selected-color="primary"
          class="hide-scrollbar tw-bg-white"
          :item-children="itemChildIdKey"
          :items="items"
          :active="items"
          :search="search"
          selection-type="independent"
          hoverable
          @update:active="selectActiveValue"
        >
          <template v-if="!singleSelect" v-slot:append="{ item, selected }">
            <v-btn
              class="toggle-button"
              text
              small
              @click="toggleParent(item, selected)"
              v-if="item[itemChildIdKey] && item[itemChildIdKey].length"
            >
              {{ $t("ui.checkbox-popup.select-all").toUpperCase() }}
            </v-btn>
          </template>
        </v-treeview>
        <filters-popup-multi-selectable-items
          v-else
          v-model="internalSelected"
          :orderedItems="internalItems"
          :search="search"
        />
      </div>
      <v-divider class="tw-mt-2" />
      <div v-if="!singleSelect" class="tw-flex tw-justify-between tw-m-2">
        <v-btn text @click="onCancel()">
          {{ $t("ui.actions.cancel") }}
        </v-btn>
        <v-btn text @click="$refs.filterMenu.save()" color="primary">
          {{ $t("ui.actions.save") }}
        </v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import intersection from "lodash/intersection";
import FiltersPopupHeader from "@/views/reports/components/filters/components/filtersPopup/components/FiltersPopupHeader.vue";
import FiltersPopupMultiSelectableItems from "@/views/reports/components/filters/components/filtersPopup/components/FiltersPopupMultiSelectableItems.vue";

export default {
  components: {
    FiltersPopupHeader,
    FiltersPopupMultiSelectableItems
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    activator: {
      type: String,
      default: "v-btn"
    },
    color: {
      type: String,
      default: "primary"
    },
    items: {
      type: Array,
      default: () => []
    },
    itemChildIdKey: {
      type: String,
      default: "children"
    },
    itemChildTextKey: {
      type: String,
      default: "name"
    },
    label: {
      type: String
    },
    offsetX: {
      type: Boolean,
      default: true
    },
    minWidth: {
      type: Number,
      default: 450
    },
    menuProps: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selectionComponent: {
      type: String,
      default: "multi-selection"
    },
    singleSelect: {
      type: Boolean,
      default: false
    },
    textFieldProps: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      allIds: [],
      allOpen: false,
      internalItems: [],
      internalSelected: [],
      opened: false,
      search: ""
    };
  },
  computed: {
    filterText() {
      if (this.opened) {
        return "...";
      } else {
        if (this.internalSelected.length === 0) {
          return i18n.t("ui.none-selected");
        } else if (this.internalSelected.length === this.allIds.length) {
          return i18n.t("ui.all-selected");
        } else if (
          this.internalSelected.length === 1 &&
          this.items.length > 0
        ) {
          return this.internalItems.find(
            (i) => i.id === this.internalSelected[0]
          ).text;
        } else {
          return i18n.t("count.count-of-total", {
            count: this.internalSelected.length,
            total: this.allIds.length
          });
        }
      }
    }
  },
  methods: {
    createFlatListandAllIds(items) {
      const allIds = [];
      const flatList = [];

      const childIdKey = this.itemChildIdKey;
      const childTextKey = this.itemChildTextKey;
      function addChildrenToList(item) {
        item[childIdKey]?.forEach((child) => {
          allIds.push(child.id);
          flatList.push({ id: child.id, text: child[childTextKey] });

          addChildrenToList(child);
        });
      }

      addChildrenToList({ [this.itemChildIdKey]: items });

      this.allIds = allIds;
      this.internalItems = flatList;
    },
    getNodeIds(location, ids = []) {
      ids.push(location.id);

      if (location[this.itemChildIdKey]) {
        location[this.itemChildIdKey].forEach((child) =>
          this.getNodeIds(child, ids)
        );
      }

      return ids;
    },
    selectActiveValue(value) {
      this.internalSelected = value;
      this.$refs.filterMenu.save();
    },
    toggleClosed(open) {
      this.opened = open;
      this.$emit("input", this.internalSelected);
      this.$emit("change");

      if (!open) {
        this.$emit("click-outside");
      }
    },
    onCancel() {
      this.internalSelected = this.value;
      this.$refs.filterMenu.save();
    },
    async toggleParent(location, selected) {
      await this.$nextTick();
      const nodeIds = this.getNodeIds(location);

      if (
        intersection(this.internalSelected, nodeIds).length !== nodeIds.length
      ) {
        selected = false;
      }

      this.internalSelected = selected
        ? this.internalSelected.filter((id) => !nodeIds.includes(id))
        : [...this.internalSelected, ...nodeIds];
    }
  },
  watch: {
    allOpen(value) {
      this.$refs.treeview.updateAll(value);
    },
    value: {
      immediate: true,
      handler: function (v) {
        this.internalSelected = v;
      }
    },
    search() {
      this.$refs.treeview.updateAll(true);
    },
    items: {
      immediate: true,
      handler: function (value) {
        if (this.selectionComponent === "multi-selection") {
          if (value.length && typeof value[0] !== "object") {
            this.allIds = value;
            this.internalItems = value.map((item) => {
              return { id: item, text: item };
            });
          } else {
            this.allIds = value.map((item) => item.id);
            this.internalItems = value;
          }
        } else {
          this.createFlatListandAllIds(value);
        }
      }
    }
  }
};
</script>

<style scoped lang="postcss">
.limit-height {
  @apply tw-overflow-y-auto;
  max-height: 50vh;
}

.hide-scrollbar {
  @apply tw-overflow-hidden;
}

:deep(input) {
  text-align: left;
}

:v-deep(.v-treeview-node__toggle),
:v-deep(.v-treeview-node__level) {
  height: 35px;
  width: 35px;
  font-size: 28px;
}
</style>
