import i18n from "@/plugins/i18n";
import { OBSERVER_OPTIONS } from "@/types/observerOptions";

export default {
  async list() {
    const observerOptionsValues = [
      {
        id: OBSERVER_OPTIONS.ALL,
        text: i18n.t("filters.all-observers")
      },
      {
        id: OBSERVER_OPTIONS.ACTIVE_ONLY,
        text: i18n.t("filters.active-observers-only")
      }
    ];

    return new Promise((res) => res(observerOptionsValues));
  }
};
