import appInsightsKey from "@/api/applicationInsights";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

async function install(Vue, router, store) {
  // If the Vue Dev Tools are enabled then the user is on a local environment and we don't want to track App Insights Data.
  // Instead we setup a dummy version of the plugin that logs any App Insight data to the console to assist with validation.
  // Full validation of tags should take place in 49696 and any published environment.
  if (Vue.config.devtools) {
    Object.defineProperty(Vue.prototype, "$appInsights", {
      get: () => ({
        trackPageView(data) {
          console.log("App Insights: trackPageView");
          console.log(data);
        },
        trackEvent(data) {
          console.log("App Insights: trackEvent");
          console.log(data);
        }
      })
    });
    return;
  }

  const instrumentationKey = await appInsightsKey.getKey();

  // If no instrumentation key is returned than the user is doing something possibly malicious or they aren't logged in.
  // Therefore we don't want to track their data or let them know we are even tracking data.
  // A dummy version of the plugin is setup to prevent unnecessary console errors that could provide any unnecessary information to the user.
  if (!instrumentationKey || instrumentationKey === "") {
    Object.defineProperty(Vue.prototype, "$appInsights", {
      get: () => ({
        trackPageView() {},
        trackEvent() {}
      })
    });

    return;
  }

  const config = {
    instrumentationKey: instrumentationKey,
    trackInitialPageView: true,
    disableAjaxTracking: true,
    autoTrackPageVisitTime: true
    /* Other Configuration Options:
     * https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#configuration...
     */
  };

  Vue.appInsights = new ApplicationInsights({ config });
  Vue.appInsights.loadAppInsights();

  setupRouterPageTracking(Vue, router);

  Object.defineProperty(Vue.prototype, "$appInsights", {
    get: () => ({
      trackPageView(data) {
        Vue.appInsights.trackPageView({
          ...data,
          properties: getCustomProperties(store.state)
        });
      },
      trackEvent(data) {
        Vue.appInsights.trackEvent({
          ...data,
          properties: getCustomProperties(store.state)
        });
      }
    })
  });

  store.commit("application/SET_APPLICATION_INSIGHTS_LOADED");
}

function setupRouterPageTracking(Vue, router) {
  router.beforeEach((route, from, next) => {
    Vue.appInsights.trackPageView({ name: route.meta.pageTagName });
    next();
  });
}

function getCustomProperties(state) {
  return {
    customerName: state.customers?.customerName
  };
}

export default install;
