<template>
  <div>
    <report-summary :cells="cells" />
    <report-graph :chartData="chartData" :ratesSwitchLabel="ratesSwitchLabel" />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";
import { FILTERS } from "@/types/filters";
import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";

import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportGraph
  },
  data() {
    return {
      compatibleReportId: 29,
      reportFilters: ["shifts", "locations", "timeframe"],
      hiddenReportActions: ["csvExport", "rawExport"],
      cells: [],
      items: [],
      chartData: {},
      ratesSwitchLabel: i18n.t("reports.graph.display-count")
    };
  },
  methods: {
    buildSummary() {
      const summaryData = this.reportResponse.summaryData;
      const cells = [
        {
          text: i18n.t("reports.events-hour"),
          value: summaryData.hourEvents,
          display: "Locale"
        },
        {
          text: i18n.t("reports.events-today"),
          value: summaryData.eventsToday,
          display: "Locale"
        },
        {
          text: i18n.t("reports.events-week"),
          value: summaryData.weekEvents,
          display: "Locale"
        },
        {
          text: i18n.t("reports.events-month"),
          value: summaryData.monthEvents,
          display: "Locale"
        }
      ];

      if (this.$store.state[`filters/${FILTERS.SHIFTS}`].allIds.length) {
        cells.splice(1, 0, {
          text: i18n.t("reports.events-shift"),
          value: summaryData.shiftEvents,
          display: "Locale"
        });
      }

      this.cells = cells;
    },
    handleReportResponse() {
      this.buildSummary();
      this.items = this.reportResponse.dataPoints;
      const builder = new ChartBuilder({
        displayLegend: false,
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });
      builder.chartTimeSeries({
        type: "bar",
        items: this.items,
        valueField: "numberOfEvents",
        valueFieldType: GraphDataTypes.ROUNDED,
        valueScaleOptions: {
          label: i18n.t("reports.graph.dispenser-events-axis"),
          position: "left",
          ticks: {
            format: "${value}"
          }
        },
        dateTimeField: "reportDate",
        timeframe: this.$store.state["filters/timeframe"]
      });
      this.chartData = builder.build();
    }
  }
};
</script>
