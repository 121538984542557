<template>
  <div>
    <deleteDialog
      @deletion-confirmed="deleteSlide"
      @toggle-delete-dialog="toggleDeleteDialog"
      :deleteDialogData="deleteDialogData"
    >
      <template v-slot:extraContent>
        <div class="tw-mt-5" v-if="deleteDialogData.extraContent.list.length">
          <p>{{ deleteDialogData.extraContent.header }}</p>
          <ul>
            <li
              v-for="item in deleteDialogData.extraContent.list"
              :key="item.id"
            >
              {{ item.name }}: <strong v-if="item.isActive">Active</strong>
              <strong v-else>Inactive</strong>
            </li>
          </ul>
        </div>
      </template>
    </deleteDialog>
    <v-data-table
      :items="items"
      :headers="headers"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @update:sort-by="trackHeaderAnalytics()"
      :loading="!slideViewModelReady"
      :loading-text="$t('slideshows.gathering-slide-data')"
      :no-data-text="$t('slideshows.no-slides-in-facility')"
    >
      <template v-slot:top>
        <div
          class="
            tw-flex
            tw-justify-between
            tw-items-center
            tw-sticky
            tw-top-0
            tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
            @focus="trackSearchAnalytics()"
          />
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.reportName="{item}">
        <span>{{ $t(item.reportName) }}</span>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.isActive="{ item }">
        <v-checkbox
          color="primary"
          @change="toggleSelectedSlide(item.id)"
          :input-value="item.isActive"
        />
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.delete="{ item }">
        <v-btn icon @click="openDeleteDialog({ id: item.id, name: item.name })">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DeleteDialog from "./DeleteDialog.vue";
import slideshowService from "@/api/slideshowsService";
import slideService from "@/api/slideService";
import filterService from "@/api/filterService";
import { FILTERS } from "@/types/filters";
import i18n from "@/plugins/i18n";
import { mapGetters } from "vuex";
import { AMS_REPORTS, AMS_REPORT_DISPLAY_NAMES } from "@/types/amsReports";
import { PERMISSIONS } from "@/types/permissions";
import { find, orderBy } from "lodash";

export default {
  components: {
    DeleteDialog
  },
  props: {
    facilityId: {
      type: Number
    },
    slideshowSlides: {
      type: Array,
      default: () => {
        return [];
      }
    },
    addedSlide: {
      type: Object
    }
  },
  data: () => ({
    deleteDialogData: {
      active: false,
      id: null,
      name: "",
      title: i18n.t("slideshows.delete-slide"),
      content: i18n.t("slideshows.overlay-content.delete-slide"),
      extraContent: {
        list: []
      }
    },
    search: "",
    shifts: [],
    items: [],
    slideViewModelReady: false,
    sortBy: [],
    sortDesc: []
  }),
  computed: {
    ...mapGetters("user/permissions", ["checkPermission"]),
    headers() {
      const headers = [
        {
          text: i18n.t("slideshows.slide-description"),
          value: "name",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("slideshows.report-type"),
          value: "reportName",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("filters.timeframe"),
          value: "timeframeValue",
          sortable: true,
          class: "tw-align-text-top"
        },
        {
          text: i18n.t("slideshows.active-slides"),
          value: "isActive",
          sortable: true,
          class: "tw-align-text-top",
          sort: (a, b) => (a === b ? 0 : a ? -1 : 1)
        }
      ];

      if (this.shifts.length) {
        headers.splice(3, 0, {
          text: i18n.t("filters.shifts"),
          value: "shiftValues",
          sortable: true,
          class: "tw-align-text-top"
        });
      }

      if (this.checkPermission(PERMISSIONS.administerCustomer)) {
        headers.push({
          text: i18n.t("slideshows.delete"),
          value: "delete",
          sortable: false,
          class: "tw-align-text-top"
        });
      }

      return headers;
    }
  },
  methods: {
    async deleteSlide() {
      const id = this.deleteDialogData.id;
      try {
        await slideshowService.deleteSlide(id);

        const slideDeletedIndex = this.items.findIndex(
          (item) => item.id === id
        );
        if (this.items[slideDeletedIndex].isActive) {
          this.$emit("remove-selected-slide", this.items[slideDeletedIndex]);
        }

        this.items = this.items.filter((item) => item.id !== id);
        this.toggleDeleteDialog();
        this.$appInsights?.trackEvent({ name: `Successful Slide Delete` });
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("slideshows.error-messages.delete-slide")
        });
      }
    },
    async getSlidesForFacility(facilityId) {
      let slides = [];
      try {
        this.shifts = await filterService(FILTERS.SHIFTS).list({ facilityId });
        slides = await slideService.get(facilityId);
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("slideshows.error-messages.get-slides-for-facility")
        });
      }

      this.setupSlidesViewModel(slides);
    },
    setupSlidesViewModel(slides) {
      const unorderedSlides = slides.map((slide) => {
        slide.reportName = AMS_REPORT_DISPLAY_NAMES[slide.reportId];
        const slideParameters = JSON.parse(slide.parametersJson);
        if (slideParameters.ImageUrl) {
          return slide;
        }

        const slideFilters = slideParameters.Filters
          ? slideParameters.Filters
          : slideParameters.GraphsFilter;

        if (
          slide.reportId !== AMS_REPORTS.PERFORMANCE_SUMMARY &&
          slideFilters?.ReportDateRange
        ) {
          slide.timeframeValue = i18n.t(
            `slideshows.timeframes.${slideFilters.ReportDateRange}`
          );
        }

        slide.shiftValues = slideFilters.ShiftIds
          ? slideFilters.ShiftIds.map((id) => {
              return find(this.shifts, (s) => s.id === id).name;
            }).join(", ")
          : [];

        return slide;
      });

      this.orderAndSetSelectedSlides(unorderedSlides);
      this.slideViewModelReady = true;
    },
    orderAndSetSelectedSlides(slides = this.items) {
      const activeSlideIds = this.slideshowSlides.map((slide) => {
        return slide.id;
      });

      const activeSlides = [];
      slides.forEach((slide) => {
        slide.isActive = activeSlideIds.includes(slide.id);
        activeSlides.push(slide);
      });
      this.items = this.sortSlides(slides);

      const activeSlideOrder = orderBy(
        this.slideshowSlides,
        (slide) => slide.order
      );

      const selectedSlides = activeSlideOrder.reduce((acc, slide) => {
        const selectedSlide = activeSlides.find(
          (selectedSlide) => selectedSlide.id === slide.id
        );
        if (selectedSlide) {
          acc.push(selectedSlide);
        }
        return acc;
      }, []);

      this.$emit("update-selected-slides", selectedSlides);
    },
    sortSlides(slides, isActiveOrder = "desc") {
      return orderBy(
        slides,
        ["isActive", (slide) => slide.name.toLowerCase()],
        [isActiveOrder, "asc"]
      );
    },
    toggleSelectedSlide(id) {
      if (this.sortBy.includes("isActive")) {
        if (this.sortDesc.includes(true)) {
          this.items = this.sortSlides(this.items, "asc");
        }

        this.sortBy = [];
      }

      let index;

      this.items.some(function (item, i) {
        if (item.id === id) {
          index = i;
        }
      });

      this.items[index].isActive = !this.items[index].isActive;

      this.items[index].isActive
        ? this.$emit("add-selected-slide", this.items[index])
        : this.$emit("remove-selected-slide", this.items[index]);
    },
    async openDeleteDialog(data) {
      try {
        const slideshowList =
          await slideshowService.getSlideshowsForFacilityBySlideId(
            data.id,
            this.facilityId
          );
        this.deleteDialogData.extraContent = {
          header: i18n.t("slideshows.overlay-content.associated-slideshow"),
          list: slideshowList
        };
        this.toggleDeleteDialog(data);
      } catch {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("slideshows.error-messages.delete-slide")
        });
      }
    },
    toggleDeleteDialog(data) {
      this.deleteDialogData.active = !this.deleteDialogData.active;
      this.deleteDialogData.id = this.deleteDialogData.id ? null : data.id;
      this.deleteDialogData.name = this.deleteDialogData.name ? "" : data.name;

      const slideshowStatus = this.deleteDialogData.active ? "Open" : "Close";
      this.$appInsights?.trackEvent({
        name: `${slideshowStatus} Slide Delete Dialog`
      });
    },
    trackSearchAnalytics() {
      this.$appInsights?.trackEvent({
        name: "Edit Slides Search Interaction"
      });
    },
    trackHeaderAnalytics() {
      if (this.sortBy.length) {
        this.$appInsights?.trackEvent({
          name: `Edit Slides Column Sorting: ${this.sortBy}`
        });

        if (this.sortBy.includes("isActive")) {
          this.items = this.sortSlides(this.items);
        }
      }
    }
  },
  watch: {
    facilityId: {
      immediate: true,
      async handler(facilityId) {
        if (facilityId) {
          this.getSlidesForFacility(facilityId);
        }
      }
    },
    slideshowSlides() {
      this.orderAndSetSelectedSlides();
    },
    addedSlide(slide) {
      this.items.unshift(slide);
      this.toggleSelectedSlide(this.items[0].id);
    }
  }
};
</script>
