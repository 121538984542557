export function downloadCSVFile(headerData, rowsData, fileName = "export") {
  const csvBlobData = new Blob([`${headerData}\n${rowsData}`], {
    type: "text/csv"
  });

  if (!fileName.endsWith(".csv")) {
    // explicitly adding the .csv extension avoids the browser leaving it off when the filename contains periods
    fileName = `${fileName}.csv`;
  }
  downloadFile(csvBlobData, fileName);
}

export function downloadPDFFile(data, fileName) {
  const file = new Blob([data], { type: "application/pdf" });
  const nonChromiumBlob = new Blob([file], { type: "application/pdf" });

  downloadFile(nonChromiumBlob, fileName);
}

function downloadFile(data, fileName) {
  if (window.navigator && window.navigator.msSaveBlob) {
    navigator.msSaveBlob(data, fileName);
  } else {
    const a = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.setAttribute("href", a);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
}
