<template>
  <img class="go-logo tw-block tw-h-auto tw-mx-auto tw-mb-6" :src="logo" />
</template>

<script>
import logo from "@/assets/images/logos/purellLogo.svg";
export default {
  data() {
    return {
      logo
    };
  }
};
</script>

<style scoped lang="postcss">
.go-logo {
  width: 180px;
}
</style>
