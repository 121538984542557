<template>
  <div>
    <ValidationObserver
      ref="licenseForm"
      v-if="adding || editing"
      v-slot="{ pristine }"
    >
      <v-form @submit.prevent="validateAndSave">
        <v-row dense>
          <v-col cols="12" lg="6">
            <ValidationProvider
              :name="
                $t('admin.facility-dashboard.licenses-widget.headers.license')
              "
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-select
                v-model="selectedProductIds"
                :error-messages="errors"
                :label="
                  $t('admin.facility-dashboard.licenses-widget.headers.license')
                "
                :placeholder="
                  $t('admin.facility-dashboard.licenses-widget.select-license')
                "
                :disabled="editing"
                :items="
                  availableProducts.map((p) => ({
                    text: p.text,
                    value: p.id
                  }))
                "
                deletable-chips
                clearable
                chips
                multiple
              >
                <template v-slot:prepend-item>
                  <filters-popup-header
                    v-model="selectedProductIds"
                    :allIds="availableProducts.map((p) => p.id)"
                  />
                </template>
              </v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="6" v-if="facilities">
            <ValidationProvider
              :name="
                $t('admin.facility-dashboard.licenses-widget.headers.facility')
              "
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-select
                v-model="selectedFacilityIds"
                :error-messages="errors"
                :label="
                  $t(
                    'admin.facility-dashboard.licenses-widget.headers.facility'
                  )
                "
                :placeholder="
                  $t(
                    'admin.facility-dashboard.licenses-widget.headers.facility'
                  )
                "
                :items="
                  facilities.map((f) => ({
                    text: f.name,
                    value: f.id
                  }))
                "
                :disabled="editing"
                deletable-chips
                clearable
                chips
                multiple
                @blur="removeUnavailableSelectedProducts"
              >
                <template v-slot:prepend-item>
                  <filters-popup-header
                    v-model="selectedFacilityIds"
                    :allIds="facilities.map((f) => f.id)"
                  />
                </template>
              </v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" lg="6">
            <ValidationProvider
              :name="
                $t(
                  'admin.facility-dashboard.licenses-widget.headers.survey-date'
                )
              "
              :rules="{
                regex: dateFormatRegex
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="license.siteSurveyDate"
                :label="
                  $t(
                    'admin.facility-dashboard.licenses-widget.headers.survey-date'
                  )
                "
                :error-messages="errors"
                :hint="dateFormat"
                :placeholder="dateFormat"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="6">
            <ValidationProvider
              :name="
                $t(
                  'admin.facility-dashboard.licenses-widget.headers.install-date'
                )
              "
              :rules="{
                required: true,
                regex: dateFormatRegex
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="license.installationDate"
                :label="
                  $t(
                    'admin.facility-dashboard.licenses-widget.headers.install-date'
                  )
                "
                :error-messages="errors"
                :hint="dateFormat"
                :placeholder="dateFormat"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" lg="6">
            <ValidationProvider
              :name="
                $t(
                  'admin.facility-dashboard.licenses-widget.headers.purchase-date'
                )
              "
              :rules="{
                required: true,
                regex: dateFormatRegex
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="license.purchaseOrderDate"
                :label="
                  $t(
                    'admin.facility-dashboard.licenses-widget.headers.purchase-date'
                  )
                "
                :error-messages="errors"
                :hint="dateFormat"
                :placeholder="dateFormat"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="6">
            <ValidationProvider
              :name="
                $t(
                  'admin.facility-dashboard.licenses-widget.headers.expiration-date'
                )
              "
              :rules="{
                required: true,
                regex: dateFormatRegex
              }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="license.expirationDate"
                :label="
                  $t(
                    'admin.facility-dashboard.licenses-widget.headers.expiration-date'
                  )
                "
                :error-messages="
                  formErrors
                    ? $t(
                        'admin.facility-dashboard.licenses-widget.error-messages.expiration-date'
                      )
                    : errors
                "
                :hint="dateFormat"
                :placeholder="dateFormat"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense class="tw-justify-end tw-mb-5">
          <v-btn
            class="tw-mr-5 tw-w-24"
            :disabled="editing && pristine"
            color="primary"
            type="submit"
            :loading="saving"
          >
            {{ $t("ui.actions.save") }}
          </v-btn>
          <v-btn class="tw-mr-5 tw-w-24" color="background2" @click="cancel">
            {{ $t("ui.actions.cancel") }}
          </v-btn>
        </v-row>
      </v-form>
    </ValidationObserver>
    <v-data-table
      v-show="!adding && !editing"
      class="licenses-data-table small-headers"
      :items="licenses"
      :headers="headers"
      :search="search"
      :no-data-text="$t('admin.facility-dashboard.licenses-widget.empty')"
      item-key="id"
      :loading="loading"
      :loading-text="$t('admin.facility-dashboard.licenses-widget.loading')"
      :height="fullscreen ? '55vh' : ''"
      @update:page="setScrollPosition"
      v-model="selected"
      show-select
      :show-group-by="customerId !== undefined"
      :group-by="customerId !== undefined ? 'facilityName' : undefined"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <div
          class="
            tw-flex tw-justify-between tw-items-center tw-sticky tw-bg-white
          "
          style="z-index: 2"
        >
          <v-text-field
            v-model="search"
            :label="`${$t('ui.input-select.search')}`"
            class="tw-mr-4"
            clearable
            prepend-inner-icon="mdi-magnify"
          />
          <v-btn
            class="tw-max-w-xs tw-mr-2"
            primary
            color="primary"
            @click="addNewLicense"
          >
            {{ $t("admin.facility-dashboard.licenses-widget.new-license") }}
          </v-btn>
          <v-btn
            class="tw-max-w-xs"
            primary
            color="primary"
            :disabled="selected.length === 0"
            @click="editLicense(selected[0], true)"
          >
            {{
              $tc(
                "admin.facility-dashboard.licenses-widget.edit-license",
                selected.length
              )
            }}
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.product`]="{ item }">
        <td>
          {{ item.product }}
        </td>
      </template>
      <template v-slot:[`item.siteSurveyDate`]="{ item }">
        <td>
          {{ formatTimestamp(item.siteSurveyDate) }}
        </td>
      </template>
      <template v-slot:[`item.installationDate`]="{ item }">
        <td>
          {{ formatTimestamp(item.installationDate) }}
        </td>
      </template>
      <template v-slot:[`item.purchaseOrderDate`]="{ item }">
        <td>
          {{ formatTimestamp(item.purchaseOrderDate) }}
        </td>
      </template>
      <template v-slot:[`item.expirationDate`]="{ item }">
        <td>
          {{ formatTimestamp(item.expirationDate) }}
        </td>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <td>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :color="item.expired ? null : 'success'"
                >{{
                  item.expired ? "mdi-alert-decagram" : "mdi-check-decagram"
                }}</v-icon
              >
            </template>
            <span>
              {{
                $t(
                  `admin.facility-dashboard.licenses-widget.license-${
                    item.expired ? "expired" : "up-to-date"
                  }`
                )
              }}
            </span>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:[`item.extensionCount`]="{ item }">
        <td>
          {{ item.extensionCount }}
        </td>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="editLicense(item, false)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t("admin.customer-dashboard.facility-widget.edit-facility") }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { PRODUCT_TRANSLATIONS } from "@/types/products";
import licenseService from "@/api/licenseService";
import productsService from "@/api/productsService";
import FiltersPopupHeader from "@/views/reports/components/filters/components/filtersPopup/components/FiltersPopupHeader";
const dateFormatRegex =
  /^(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/[0-9]{4}$/;
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  components: {
    FiltersPopupHeader
  },
  props: {
    customerId: { type: Number },
    facilities: { type: Array },
    facilityId: { type: Number },
    fullscreen: { type: Boolean, default: false },
    setLocalizedWidgetMessage: {
      type: Function,
      default() {}
    }
  },
  data() {
    return {
      adding: false,
      availableProducts: [],
      dateFormatRegex,
      editing: false,
      license: {
        productId: "",
        siteSurveyDate: "",
        installationDate: "",
        purchaseOrderDate: "",
        expirationDate: ""
      },
      licenses: [],
      loading: false,
      products: [],
      saving: false,
      search: "",
      selected: [],
      selectedFacilityIds: [],
      selectedLicenses: [],
      selectedProductIds: [],
      dateFormat: "mm/dd/yyyy"
    };
  },
  computed: {
    formErrors() {
      if (
        Date.parse(this.license.expirationDate) &&
        Date.parse(this.license.purchaseOrderDate)
      ) {
        return (
          new Date(this.license.expirationDate) <
          new Date(this.license.purchaseOrderDate)
        );
      } else {
        return false;
      }
    },
    headers() {
      const headers = [
        {
          text: i18n.t(
            "admin.facility-dashboard.licenses-widget.headers.license"
          ),
          value: "product",
          sortable: true,
          class: "tw-align-text-top",
          groupable: false
        },
        {
          text: i18n.t(
            "admin.facility-dashboard.licenses-widget.headers.survey-date"
          ),
          value: "siteSurveyDate",
          sortable: true,
          class: "tw-align-text-top",
          groupable: false
        },
        {
          text: i18n.t(
            "admin.facility-dashboard.licenses-widget.headers.install-date"
          ),
          value: "installationDate",
          sortable: true,
          class: "tw-align-text-top",
          groupable: false
        },
        {
          text: i18n.t(
            "admin.facility-dashboard.licenses-widget.headers.purchase-date"
          ),
          value: "purchaseOrderDate",
          sortable: true,
          class: "tw-align-text-top",
          groupable: false
        },
        {
          text: i18n.t(
            "admin.facility-dashboard.licenses-widget.headers.expiration-date"
          ),
          value: "expirationDate",
          sortable: true,
          class: "tw-align-text-top",
          groupable: false
        },
        {
          text: i18n.t(
            "admin.facility-dashboard.licenses-widget.headers.status"
          ),
          value: "status",
          sortable: false,
          class: "tw-align-text-top",
          groupable: false
        },
        {
          text: i18n.t(
            "admin.facility-dashboard.licenses-widget.headers.extensions"
          ),
          value: "extensionCount",
          sortable: false,
          class: "tw-align-text-top",
          groupable: false
        },
        {
          text: i18n.t("ui.actions.edit"),
          value: "edit",
          sortable: false,
          class: "tw-align-text-top",
          groupable: false
        }
      ];

      if (this.customerId) {
        headers.unshift({
          text: i18n.t(
            "admin.facility-dashboard.licenses-widget.headers.facility"
          ),
          value: "facilityName",
          sortable: true,
          class: "tw-align-text-top"
        });
      }

      return headers;
    }
  },
  async mounted() {
    const products = await productsService.getAll();
    this.products = products.map((p) => {
      return {
        ...p,
        text: PRODUCT_TRANSLATIONS[p.code]
      };
    });
    this.availableProducts = this.products;
    this.getLicenses();
  },
  methods: {
    addNewLicense() {
      if (this.facilityId) {
        this.selectedFacilityIds = [this.facilityId];
        this.removeUnavailableSelectedProducts();
      } else {
        this.selectedFacilityIds = [];
      }

      this.license = {
        siteSurveyDate: "",
        installationDate: "",
        purchaseOrderDate: "",
        expirationDate: ""
      };
      this.selectedProductIds = [];
      this.adding = true;

      this.$appInsights?.trackEvent({
        name: `Manage License View Loaded`
      });
    },
    cancel() {
      this.adding = false;
      this.editing = false;
    },
    editLicense(license, multiple) {
      this.license = {
        siteSurveyDate: this.formatTimestamp(license.siteSurveyDate),
        installationDate: this.formatTimestamp(license.installationDate),
        purchaseOrderDate: this.formatTimestamp(license.purchaseOrderDate),
        expirationDate: this.formatTimestamp(license.expirationDate)
      };
      this.availableProducts = [...this.products];

      if (multiple) {
        this.selectedLicenses = this.selected.map((l) => {
          return { id: l.id, productId: l.productId };
        });
        this.selectedProductIds = this.selected.map((l) => l.productId);
        this.selectedFacilityIds = [
          ...new Set(this.selected.map((l) => l.facilityId))
        ];
      } else {
        this.selectedLicenses = [
          { id: license.id, productId: license.productId }
        ];
        this.selectedProductIds = [license.productId];
        this.selectedFacilityIds = [license.facilityId];
      }

      this.editing = true;
      this.$appInsights?.trackEvent({
        name: `Manage License View Loaded`
      });
    },
    async getLicenses() {
      try {
        this.loading = true;

        let licenses = [];
        if (this.facilityId) {
          licenses = await licenseService.getByFacilityId(this.facilityId);
        } else {
          licenses = await licenseService.getByCustomerId(this.customerId);
        }
        const today = new Date().setHours(0, 0, 0, 0);

        this.licenses = licenses
          .map((l) => {
            return {
              ...l,
              expired: new Date(l.expirationDate) < today,
              product: this.products.find((p) => p.id === l.productId)?.text,
              expireDate: new Date(l.expirationDate)
            };
          })
          .sort((a, b) => {
            if (a.expired === b.expired) {
              return a.expireDate < b.expireDate ? -1 : 1;
            } else {
              return a.expired ? 1 : -1;
            }
          });
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t(
            "admin.facility-dashboard.licenses-widget.error-messages.get-licenses"
          )
        });
      } finally {
        this.loading = false;
      }
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        return new Date(`${timestamp}`).toLocaleDateString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
      }
      return "";
    },
    removeUnavailableSelectedProducts() {
      this.availableProducts = [...this.products].filter(
        (p) =>
          !this.licenses.some(
            (l) =>
              !l.expired &&
              l.productId === p.id &&
              this.selectedFacilityIds.includes(l.facilityId)
          )
      );

      this.selectedProductIds = this.selectedProductIds.filter((productId) =>
        this.availableProducts.some((p) => p.id === productId)
      );
    },
    setScrollPosition() {
      document.querySelector(
        "div.licenses-data-table div.v-data-table__wrapper"
      ).scrollTop = 0;
    },
    async validateAndSave() {
      const valid = await this.$refs.licenseForm.validate();
      if (valid) {
        this.saving = true;

        if (!valid) {
          await this.$nextTick();
          scrollToFirstError(this.$el);

          this.$appInsights?.trackEvent({
            name: `License Form Error`
          });
          return;
        }

        try {
          if (this.adding) {
            await Promise.all(
              this.selectedProductIds.map(async (productId) => {
                await Promise.all(
                  this.selectedFacilityIds.map(async (facilityId) => {
                    await licenseService.create(facilityId, {
                      ...this.license,
                      productId
                    });
                  })
                );
              })
            );
            this.adding = false;

            this.$appInsights?.trackEvent({
              name: `Add Licenses Form Success`
            });
            this.setLocalizedWidgetMessage({
              message: i18n.t(
                "admin.facility-dashboard.licenses-widget.success-messages.create"
              )
            });

            this.getLicenses();
            this.$emit("reload-facility");
          } else {
            await Promise.all(
              this.selectedLicenses.map(async (l) => {
                await licenseService.edit({ ...this.license, ...l });
              })
            );
            this.editing = false;
            this.selectedLicenses = [];
            this.selected = [];

            this.$appInsights?.trackEvent({
              name: `Edit Licenses Form Success`
            });
            this.setLocalizedWidgetMessage({
              message: i18n.t(
                "admin.facility-dashboard.licenses-widget.success-messages.update"
              )
            });
          }

          this.getLicenses();
          this.$emit("reload-facility");
        } catch {
          if (this.adding) {
            this.$appInsights?.trackEvent({
              name: `Add Licenses Server Error`
            });
          } else {
            this.$appInsights?.trackEvent({
              name: `Edit Licenses Server Error`
            });
          }
          this.$store.commit("application/SET_ERROR", {
            message: i18n.t(
              "admin.facility-dashboard.licenses-widget.error-messages.save-license"
            )
          });
        } finally {
          this.saving = false;
        }
      }
    }
  }
};
</script>

<style scoped lang="postcss">
:v-deep(.v-input--switch > .v-input__control > .v-input__slot > .v-label) {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
}
</style>
