<template>
  <v-dialog :value="showDialog" @input="(e) => cancel()" max-width="750">
    <v-card>
      <v-card-title>
        {{ $t("slideshows.overlay-content.add-images-title") }}
      </v-card-title>
      <v-card-text>
        <p>
          {{ $t("slideshows.overlay-content.add-images-content") }}
        </p>
        <div v-if="invalidMessages.length" class="tw-mb-5" style="color: red">
          <p>{{ $t("slideshows.error-messages.add-image-error") }}</p>
          <ul>
            <li v-for="(item, index) in invalidMessages" :key="index">
              {{ item }}
            </li>
          </ul>
        </div>
        <ValidationObserver ref="imageSlideForm">
          <v-form @submit.prevent="validateAndSubmitForm">
            <v-file-input
              v-model="file"
              show-size
              label="Image"
              prepend-icon="mdi-image"
              :accept="validFileTypes.join(', ')"
              @change="validateFile"
            />

            <ValidationProvider
              :name="$t('slideshows.slide-description')"
              :rules="{ required: true }"
              v-slot="{ errors }"
            >
              <v-text-field
                v-if="validFile"
                :error-messages="errors"
                :label="$t('slideshows.slide-description')"
                v-model="slideName"
                :hint="$t('slideshows.slide-description-hint')"
              />
            </ValidationProvider>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel()">
          {{ $t("ui.actions.cancel") }}
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          :disabled="slideName === ''"
          :loading="loading"
          @click="validateAndSubmitForm()"
        >
          {{ $t("ui.actions.add-image") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/plugins/i18n";
import slideService from "@/api/slideService";

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    file: null,
    loading: false,
    invalidMessages: [],
    slideName: "",
    valid: false,
    validFile: false,
    validFileTypes: ["image/png", "image/jpg", "image/gif", "image/jpeg"]
  }),
  methods: {
    cancel() {
      this.$emit("close-add-images-dialog");
      this.file = null;
      this.validFile = false;
      this.slideName = "";
    },
    validateFile(file) {
      if (!file) {
        this.invalidMessages = [];
        this.validFile = false;
        return;
      }

      const invalidMessagesArray = [];

      if (this.validFileTypes.indexOf(file.type) < 0) {
        invalidMessagesArray.push(
          i18n.t("slideshows.error-messages.invalid-file-type")
        );
      }

      // 1048576 is 1 MB
      if (file.size > 1048576) {
        invalidMessagesArray.push(
          i18n.t("slideshows.error-messages.file-too-large")
        );
      }

      this.invalidMessages = invalidMessagesArray;

      if (invalidMessagesArray.length) {
        this.$appInsights?.trackEvent({
          name: `Add Image Error`
        });
      }

      this.validFile = !invalidMessagesArray.length;
    },
    async validateAndSubmitForm() {
      this.loading = true;
      const valid = await this.$refs.imageSlideForm.validate();

      if (!valid) {
        this.loading = false;
        return;
      }

      try {
        const response = await slideService.postImage(
          this.$store.state.customers.facilityId,
          this.file,
          this.slideName
        );

        this.handleSuccess(response);
      } catch (error) {
        this.handleError(error);
      }

      this.loading = false;
    },
    handleSuccess(response) {
      this.$emit("add-images-successful", response);
      this.file = null;
      this.slideName = "";
      this.validFile = false;
    },
    handleError(response) {
      let message;

      switch (response) {
        case 409:
          message = i18n.t("slideshows.error-messages.duplicate-slide-name");
          break;
        case 413:
          message = i18n.t("slideshows.error-messages.file-too-large");
          break;
        case 415:
          message = i18n.t("slideshows.error-messages.invalid-file-type");
          break;
        default:
          message = i18n.t("slideshows.error-messages.add-image-slide");
          break;
      }

      this.invalidMessages.push(message);
      this.$appInsights?.trackEvent({
        name: `Add Image Server Error`
      });
    }
  }
};
</script>
