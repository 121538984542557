<template>
  <v-col
    cols="12"
    lg="6"
    class="tw-px-0"
    :style="{ 'max-width': $vuetify.breakpoint.lgAndUp ? '49.4%' : null }"
  >
    <v-sheet v-if="fullscreen" :height="previousWidgetHeight" />
    <div :class="{ widgetModalContainer: fullscreen }">
      <v-overlay :value="fullscreen" :z-index="0" />
      <v-card
        ref="widgetCard"
        v-click-outside="{
          handler: onClickOutside,
          closeConditional: canExitFullscreen
        }"
        outlined
        :max-width="fullscreen ? '90vw' : undefined"
        class="tw-py-4 tw-flex tw-flex-col tw-justify-between"
        width="100%"
        :height="cardHeight"
        style="max-height: 90vh"
      >
        <v-snackbar
          top
          :color="showError ? 'error' : 'primary'"
          :absolute="true"
          :value="showSnackbar"
          :timeout="snackbarTimeout"
          @input="showSnackbar = false"
          class="tw-z-10"
        >
          {{ snackbarMessage }}
          <template v-if="showError" v-slot:action="{ close }">
            <v-btn icon @click="showSnackbar = false" v-bind="close">
              <v-icon> mdi-close-thick </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-card-title class="tw-flex tw-px-4 tw-py-0">
          <h3>{{ $t(currentTitle) }}</h3>
          <slot name="titleCustomContent"></slot>
        </v-card-title>
        <div class="tw-px-4 tw-py-0 tw-overflow-y-auto">
          <slot
            :fullscreen="fullscreen"
            :changeWidgetCurrentTitle="changeWidgetCurrentTitle"
            :setLocalizedWidgetMessage="setLocalizedWidgetMessage"
            :clearLocalizedWidgetMessage="clearLocalizedWidgetMessage"
            :setWidgetFullscreen="setWidgetFullscreen"
          ></slot>
        </div>
        <v-spacer />
        <v-card-actions
          class="tw-justify-between tw-items-center tw-px-4 tw-py-0"
        >
          <div class="tw-flex">
            <slot name="additional-widget-action"></slot>
          </div>
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  primary
                  v-bind="attrs"
                  v-on="on"
                  @click="setWidgetFullscreen(!fullscreen)"
                >
                  <v-icon>
                    {{ fullscreen ? "mdi-fullscreen-exit" : "mdi-fullscreen" }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  fullscreen
                    ? $t("admin.dashboard-widget.close-fullscreen")
                    : $t("admin.dashboard-widget.open-fullscreen")
                }}
              </span>
            </v-tooltip>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-col>
</template>

<script>
import { lockScreen, unlockScreen } from "@/utils/handleLockingScreen";
export default {
  props: {
    maxWidth: { type: String, default: "80%" },
    title: { type: String }
  },
  mounted() {
    document.addEventListener("keydown", this.onKeydown);
    this.currentTitle = this.title;
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeydown);
  },
  data() {
    return {
      showError: false,
      showSnackbar: false,
      snackbarMessage: "",
      defaultSnackbarTimeout: 5000,
      customSnackbarTimeout: 0,
      infiniteSnackbarTimeout: -1,
      previousWidgetHeight: 0,
      fullscreen: false,
      currentTitle: ""
    };
  },
  computed: {
    cardHeight() {
      return this.$vuetify.breakpoint.lgAndUp && !this.fullscreen
        ? "540px"
        : "auto";
    },
    snackbarTimeout() {
      if (this.customSnackbarTimeout) {
        return this.customSnackbarTimeout;
      } else if (this.showError) {
        return this.infiniteSnackbarTimeout;
      } else {
        return this.defaultSnackbarTimeout;
      }
    }
  },
  methods: {
    canExitFullscreen() {
      if (
        !document.querySelector(".v-dialog__content--active") &&
        !document.querySelector(".menuable__content__active")
      ) {
        return true;
      }
      return false;
    },
    onClickOutside() {
      this.fullscreen = false;
    },
    onKeydown(e) {
      if (e.key === "Escape") {
        this.fullscreen = false;
      }
    },
    clearLocalizedWidgetMessage() {
      this.showSnackbar = false;
    },
    setLocalizedWidgetMessage({
      message = "",
      showError = false,
      customSnackbarTimeout = 0
    }) {
      this.snackbarMessage = message;
      this.showError = showError;
      this.showSnackbar = true;
      this.customSnackbarTimeout = customSnackbarTimeout;
    },
    setWidgetFullscreen(newFullscreenValue) {
      this.previousWidgetHeight = newFullscreenValue
        ? this.$refs.widgetCard.$el.offsetHeight
        : 0;

      this.fullscreen = newFullscreenValue;
    },
    changeWidgetCurrentTitle(title) {
      this.currentTitle = title;
    }
  },
  watch: {
    fullscreen(value) {
      if (value) {
        lockScreen();
      } else {
        unlockScreen();
      }
      this.$emit("fullscreen", value);
    }
  }
};
</script>

<style lang="postcss" scoped>
.widgetModalContainer {
  align-items: center;
  justify-content: center;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  z-index: 225;
  transition: 1s;
}

.widgetOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgb(33, 33, 33);
  opacity: 0.46;
}
</style>
