<template>
  <div class="tw-ml-4 tw-mr-4 tw-mb-4">
    <div
      :class="{
        'tw-capitalize': true,
        'inactive-text': !filterText,
        'tw-whitespace-no-wrap': true,
        'tw-font-bold': true
      }"
    >
      <slot></slot>
    </div>
    <v-btn
      small
      outlined
      block
      color="primary"
      :disabled="disabled"
      @click="(e) => $emit('click', e)"
      class="edit-filter text-capitalize tw-block tw-max-w-full"
    >
      <span class="tw-font-bold tw-truncate">{{ filterText }}</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "filters-entry",
  props: {
    filterText: String,
    disabled: Boolean
  }
};
</script>

<style lang="postcss" scoped>
button {
  min-height: 48px;
}
.inactive-text {
  color: rgba(0, 0, 0, 0.54);
}
</style>
