<template>
  <div v-if="activeFilters.length > 0">
    <report-filters
      v-if="!this.isPrint"
      :outsideSelectedFilter="outsideSelectedFilter"
      @clear-outside-selected-filter="setOutsideSelectedFilter()"
      class="tw-mb-4"
    />
    <div v-if="!loadingFilters" class="tw-flex tw-flex-wrap">
      <template v-if="activeFilters.length >= 5 && !this.isPrint">
        <div class="smartlink-filter-column">
          <report-filter-list
            :filterTexts="filterTexts.column1"
            @update-outside-selected-filter="setOutsideSelectedFilter"
          />
        </div>
        <div class="smartlink-filter-column">
          <report-filter-list
            :filterTexts="filterTexts.column2"
            @update-outside-selected-filter="setOutsideSelectedFilter"
          />
        </div>
      </template>
      <template v-else>
        <report-filter-list
          :filterTexts="filterTexts.all"
          @update-outside-selected-filter="setOutsideSelectedFilter"
        />
      </template>
    </div>
    <v-progress-circular
      v-else
      style="position: relative; left: 50%; margin-bottom: 5px"
      indeterminate
      color="primary"
    />
    <div class="print:tw-mt-10"></div>
  </div>
</template>

<script>
import ReportFilterList from "@/views/reports/components/filters/components/ReportFilterList.vue";
import ReportFilters from "@/views/reports/components/filters/ReportFilters.vue";
import DisabledFiltersMixin from "@/views/reports/components/filters/DisabledFiltersMixin";
import { mapGetters, mapState } from "vuex";
import { DISPLAY_MODES } from "@/store/modules/application.js";
import { FILTERS } from "@/types/filters";
import i18n from "@/plugins/i18n";

export default {
  mixins: [DisabledFiltersMixin],
  components: {
    ReportFilterList,
    ReportFilters
  },
  data: () => ({
    outsideSelectedFilter: {}
  }),
  computed: {
    ...mapState("reports", ["reportDateRange", "loadingFilters"]),
    ...mapState("application", ["displayMode"]),
    ...mapGetters("reports", ["activeFilters"]),
    isPrint() {
      return this.displayMode === DISPLAY_MODES.PRINT;
    },
    activeFiltersState() {
      const result = {};
      this.activeFilters.forEach(
        (filterId) =>
          (result[filterId] = this.$store.state[`filters/${filterId}`])
      );
      return result;
    },
    filterTexts() {
      const activeFilterIds = this.activeFilters;
      const filtersText = [];

      activeFilterIds.forEach((filterId) => {
        const title = i18n.t(`filters.${filterId}`);

        const filterDisabledState = this.filterDisabledStates[filterId];
        const disabled = Boolean(filterDisabledState?.disabled);
        const text = disabled
          ? filterDisabledState.disabledText
          : this.getSelectedText(filterId);

        filtersText.push({
          title,
          text,
          id: filterId,
          disabled
        });
      });

      const column1 = filtersText.filter(function (item, i) {
        return i % 2 === 0;
      });

      const column2 = filtersText.filter(function (item, i) {
        return i % 2 !== 0;
      });

      return {
        all: filtersText,
        column1: column1,
        column2: column2
      };
    }
  },
  methods: {
    getSelectedText(filterId) {
      if (filterId === FILTERS.TIMEFRAME) {
        const reportDateRange = this.reportDateRange;

        if (this.isPrint) {
          return reportDateRange;
        }

        const filterText =
          this.$store.getters[`filters/${filterId}/filterText`]();

        const suffix = reportDateRange ? `| ${reportDateRange}` : "";

        return `${filterText} ${suffix}`;
      }

      if (this.isPrint) {
        return this.$store.getters[`filters/${filterId}/selectedText`]();
      }

      return this.$store.getters[`filters/${filterId}/filterText`]();
    },
    setOutsideSelectedFilter(filterId) {
      if (!filterId) {
        this.outsideSelectedFilter = {};
        return;
      }

      this.outsideSelectedFilter = {
        filterId,
        filterState: this.$store.state[`filters/${filterId}`]
      };
    }
  }
};
</script>

<style scoped>
.smartlink-filter-column {
  flex: 0 0 50%;
}
@media only screen and (max-width: 750px) {
  .smartlink-filter-column {
    flex: 0 0 100%;
  }
}
</style>
