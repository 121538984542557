<template>
  <v-list-item id="navigation-drawer__language-select">
    <v-list-item-content>
      <v-select
        :items="languages"
        v-model="currentLanguage"
        hide-details
        class="tw-p-0 tw-m-0"
      >
        <div slot="prepend" class="tw-pt-1">
          <img class="smartlink-flag-icon" :src="currentLanguage.icon" />
        </div>
      </v-select>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import LanguageSelectionMixin from "@/mixins/LanguageSelectionMixin";

export default {
  name: "drawer-language-select",
  mixins: [LanguageSelectionMixin]
};
</script>
