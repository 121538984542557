<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" color="purellGray" class="tw-block">
        <img class="tw-mr-1 smartlink-flag-icon" :src="currentLanguage.icon" />
        {{ currentLanguage.text }}
        <span class="tw-ml-2 tw-text-purellGray">
          <v-icon> mdi-chevron-down </v-icon>
        </span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="lang in languages"
        :key="lang.value"
        @click="currentLanguage = lang.value"
      >
        <div class="tw-flex tw-flex-row">
          <span class="tw-mr-2">
            <img class="smartlink-flag-icon" :src="lang.icon" />
          </span>
          {{ lang.text }}
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import LanguageSelectionMixin from "@/mixins/LanguageSelectionMixin";

export default {
  mixins: [LanguageSelectionMixin]
};
</script>

<style scoped lang="postcss">
.v-btn {
  @apply tw-capitalize;
}
</style>
