<template>
  <div
    class="
      tw-flex tw-flex-col-reverse
      md:tw-flex-row
      tw-my-3 tw-justify-between
    "
  >
    <v-list-item-group multiple class="tw-overflow-hidden tw-w-full">
      <v-list-item
        v-for="item in searchResult"
        :key="item.id"
        @click="onToggle(item.id)"
        active-class="multiselect-active-item"
      >
        <template v-slot:default>
          <v-list-item-action>
            <v-checkbox
              :input-value="value ? value.includes(item.id) : false"
              :value="value ? value.includes(item.id) : false"
              color="primary"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-list-item>
    </v-list-item-group>
    <v-list-item
      v-if="showOtherOption"
      class="tw-h-0 otherOption"
      key="otherOption"
      @click="chooseOtherOption"
    >
      <template v-slot:default>
        <v-list-item-action>
          <v-checkbox
            :input-value="value.length === 0"
            :value="value.length === 0"
            color="primary"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ otherOptionText }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-list-item>
  </div>
</template>

<script>
import filtersPopupItemsMixin from "../mixins/filtersPopupItemsMixin";

export default {
  mixins: [filtersPopupItemsMixin],
  props: {
    otherOptionText: String,
    showOtherOption: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    chooseOtherOption() {
      this.$emit("input", []);
    },
    onToggle(id) {
      const newValue = [...this.value];
      const itemIndex = this.value.indexOf(id);
      if (itemIndex > -1) {
        newValue.splice(itemIndex, 1);
      } else {
        newValue.push(id);
      }

      this.$emit("input", newValue);
    }
  }
};
</script>

<style scoped lang="postcss">
@media (max-width: 767px) {
  .otherOption {
    margin: 0 16px;
    padding: 0;
    border-bottom: 1px solid grey;
  }
}

.multiselect-active-item::before {
  opacity: 0;
}
</style>
