export function performanceBandForLimit({ indicators = [], limit = 0 } = {}) {
  if (!indicators.length) {
    return undefined;
  }

  const sortedIndicators = indicators.sort((a, b) => a.limit - b.limit);
  for (let i = 0; i < sortedIndicators.length; i++) {
    if (sortedIndicators[i].limit >= limit) {
      return sortedIndicators[i];
    }
  }

  return sortedIndicators[sortedIndicators.length - 1];
}

export function determineThresholdColor(thresholds, value) {
  if (!value || Array.isArray(value)) {
    return null;
  }

  if (!thresholds || !thresholds.length) {
    return null;
  }

  const sortedPerformanceThresholds = [...thresholds].sort(
    (a, b) => a.value - b.value
  );
  let backgroundColor;
  for (let i = 0; i < sortedPerformanceThresholds.length; i++) {
    if (value >= sortedPerformanceThresholds[i].value) {
      backgroundColor = sortedPerformanceThresholds[i].color;
    }
  }

  return backgroundColor;
}
