<template>
  <div>
    <report-performance-thresholds
      :performanceThresholds="performanceThresholds"
    />
    <report-block-row :items="firstRow" />
    <report-block-row :items="secondRow" />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportPerformanceThresholds from "@/views/reports/components/reportSections/ReportPerformanceThresholds.vue";
import ReportBlockRow from "@/views/reports/components/reportSections/reportBlock/ReportBlockRow.vue";
import { organizeRow } from "@/views/reports/components/reportSections/reportBlock/reportBlockUtils";

import { mapState } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportPerformanceThresholds,
    ReportBlockRow
  },
  data() {
    return {
      compatibleReportId: 19,
      reportFilters: ["locations"],
      hiddenReportActions: ["csvExport", "rawExport"],
      items: [],
      firstRow: [],
      secondRow: [],
      performanceThresholds: {},
      refreshInterval: 60000
    };
  },
  computed: {
    ...mapState("reports", ["isSlideshow"]),
    facilityHasShifts() {
      return this.$store.state.facilities?.byId[
        this.$store.state.customers.facilityId
      ]?.hasShifts;
    }
  },
  methods: {
    createFirstRow() {
      const firstRow = [
        {
          title: i18n.t("reports.today-performance"),
          value: "complianceIndex",
          display: "Percentage",
          dataKey: "todayData",
          color: "blue"
        }
      ];

      if (this.facilityHasShifts) {
        firstRow.unshift({
          title: i18n.t("reports.shift-performance"),
          value: "complianceIndex",
          display: "Percentage",
          dataKey: "shiftData",
          color: "indigo"
        });
      }

      this.firstRow = organizeRow(
        firstRow,
        this.items,
        this.performanceThresholds?.thresholds
      );
    },
    createSecondRow() {
      const secondRow = [
        {
          title: i18n.t("reports.hand-hygiene-perf"),
          color: "purple",
          rows: [
            {
              value: "complianceIndex",
              display: "Percentage",
              dataKey: "todayData",
              text: i18n.t("timeframes.today")
            },
            {
              value: "complianceIndex",
              display: "Percentage",
              dataKey: "sevenDayData",
              text: i18n.t("timeframes.last-7-days")
            },
            {
              value: "complianceIndex",
              display: "Percentage",
              dataKey: "thirtyDayData",
              text: i18n.t("timeframes.last-30-days")
            }
          ]
        },
        {
          title: i18n.t("reports.hand-hygiene-events"),
          color: "green",
          rows: [
            {
              value: "numberOfEvents",
              display: "Locale",
              dataKey: "todayData",
              text: i18n.t("timeframes.today")
            },
            {
              value: "numberOfEvents",
              display: "Locale",
              dataKey: "sevenDayData",
              text: i18n.t("timeframes.last-7-days")
            },
            {
              value: "numberOfEvents",
              display: "Locale",
              dataKey: "thirtyDayData",
              text: i18n.t("timeframes.last-30-days")
            }
          ]
        },
        {
          title: i18n.t("reports.hand-hygiene-opps"),
          color: "sky-blue",
          rows: [
            {
              value: "numberOfOpportunities",
              display: "Locale",
              dataKey: "todayData",
              text: i18n.t("timeframes.today")
            },
            {
              value: "numberOfOpportunities",
              display: "Locale",
              dataKey: "sevenDayData",
              text: i18n.t("timeframes.last-7-days")
            },
            {
              value: "numberOfOpportunities",
              display: "Locale",
              dataKey: "thirtyDayData",
              text: i18n.t("timeframes.last-30-days")
            }
          ]
        }
      ];

      if (this.facilityHasShifts) {
        secondRow[0].rows.push({
          value: "complianceIndex",
          display: "Percentage",
          dataKey: "shiftData",
          text: i18n.t("shifts.current")
        });
        secondRow[1].rows.push({
          value: "numberOfEvents",
          display: "Locale",
          dataKey: "shiftData",
          text: i18n.t("shifts.current")
        });
        secondRow[2].rows.push({
          value: "numberOfOpportunities",
          display: "Locale",
          dataKey: "shiftData",
          text: i18n.t("shifts.current")
        });
      }

      this.secondRow = organizeRow(
        secondRow,
        this.items,
        this.performanceThresholds?.thresholds
      );
    },
    handleReportResponse() {
      this.items = {
        ...this.reportResponse
      };

      this.performanceThresholds =
        this.reportResponse.performanceThresholds || {};
      this.createFirstRow();
      this.createSecondRow();
    }
  }
};
</script>
