<template>
  <default-layout>
    <v-container>
      <v-card>
        <section class="tw-px-4 sm:tw-px-8 tw-inline-block tw-w-full tw-py-10">
          <router-view />
        </section>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/Default";

export default {
  components: {
    DefaultLayout
  }
};
</script>
