export const FILTERS = {
  SHIFTS: "shifts",
  IMSJOBROLES: "imsJobRoles",
  OBVJOBROLES: "obvJobRoles",
  LOCATIONS: "locations",
  DEVICES: "devices",
  EMPLOYEES: "employees",
  HYGIENEPROTOCOLOPPORTUNITIES: "hygieneProtocolOpportunities",
  PRECAUTIONOPTIONS: "precautionOptions",
  ACTIVITYBY: "activityBy",
  TIMEFRAME: "timeframe",
  OBVYEARS: "obvYears",
  MONTHS: "months",
  REPLACEMENT_DATE: "daysToReplacement",
  EXPIRATION_DATE: "monthsToExpiration",
  OBSERVER_OPTIONS: "activeObserversOnly"
};
