<template>
  <div>
    <div v-if="loading">
      <p>
        {{
          employeeId
            ? $t("admin.employee.loading.edit")
            : $t("admin.employee.loading.add")
        }}
      </p>
      <v-progress-linear indeterminate />
    </div>
    <div v-else>
      <h3 class="tw-mb-5">
        {{
          employeeId
            ? $t("admin.employee.title.edit")
            : $t("admin.employee.title.add")
        }}
      </h3>
      <p>
        {{
          employeeId
            ? $t("admin.employee.help-text.edit")
            : $t("admin.employee.help-text.add")
        }}
      </p>
      <ValidationObserver ref="employeeForm" v-slot="{ pristine }">
        <v-form @submit.prevent="validateAndSubmit">
          <v-container>
            <v-row dense>
              <v-col cols="12" lg="6">
                <ValidationProvider
                  :name="$t('admin.employee.identifier')"
                  :rules="{ required: true, min: 5, max: 15 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="identifier"
                    :error-messages="errors"
                    :label="$t('admin.employee.identifier')"
                    :counter="15"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" lg="6">
                <ValidationProvider
                  :name="$t('admin.employee.first-name')"
                  :rules="{ required: true, max: 50 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="firstName"
                    :error-messages="errors"
                    :label="$t('admin.employee.first-name')"
                    :counter="50"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="6">
                <ValidationProvider
                  :name="$t('admin.employee.last-name')"
                  :rules="{ required: true, max: 50 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="lastName"
                    :error-messages="errors"
                    :label="$t('admin.employee.last-name')"
                    :counter="50"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" lg="6">
                <ValidationProvider
                  :name="$t('admin.employee.job-role')"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    auto-select-first
                    v-model="jobRoleId"
                    :error-messages="errors"
                    :label="$t('admin.employee.job-role')"
                    :items="
                      jobRoles.map((f) => ({
                        text: f.name,
                        value: f.id
                      }))
                    "
                    items-text="text"
                    items-value="value"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" lg="6">
                <ValidationProvider
                  :name="$t('admin.employee.department')"
                  :rules="{ required: false, max: 50 }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="department"
                    :error-messages="errors"
                    :label="$t('admin.employee.department')"
                    :counter="50"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense v-if="!employeeId">
              <v-col cols="12" lg="6">
                <v-autocomplete
                  auto-select-first
                  v-model="badgeId"
                  :label="$t('admin.employee.badge')"
                  :items="
                    badges.length > 0
                      ? badges.map((f) => ({
                          text: f.serialNumber,
                          value: f.id
                        }))
                      : [
                          {
                            text: $t('admin.employee.no-badges'),
                            value: ''
                          }
                        ]
                  "
                  items-text="text"
                  items-value="value"
                  :disabled="badges.length === 0"
                />
              </v-col>
            </v-row>
            <div v-else class="mt-6 mb-6">
              <v-row dense>
                <v-col
                  cols="12"
                  lg="12"
                  class="tw-flex tw-flex-wrap tw-justify-between"
                >
                  <h3>{{ $t("admin.employee.badges") }}</h3>
                  <v-btn
                    class="tw-max-w-xs"
                    primary
                    color="primary"
                    @click="addBadge"
                    :disabled="
                      availableBadges.length === 0 ||
                      (currentBadge !== undefined &&
                        currentBadge.badgeId === null)
                    "
                  >
                    {{ $t("admin.employee.new-badge") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" lg="12">
                  <v-data-table
                    class="badges-data-table small-headers autoheight"
                    item-key="id"
                    :items="assignedBadges"
                    :headers="[
                      {
                        text: $t('admin.employee.badge-type'),
                        value: 'badgeType',
                        class: 'tw-align-text-top',
                        width: '125px'
                      },
                      {
                        text: $t('admin.employee.badge-number'),
                        value: 'badgeNumber',
                        class: 'tw-align-text-top'
                      },
                      {
                        value: 'makePermanent',
                        class: 'tw-align-text-top',
                        width: '200px'
                      },
                      {
                        value: 'unassign',
                        class: 'tw-align-text-top',
                        width: '150px'
                      }
                    ]"
                    :no-data-text="$t('admin.employee.no-badges-assigned')"
                    :disable-sort="true"
                    :disable-pagination="true"
                    :items-per-page="-1"
                    :hide-default-footer="true"
                  >
                    <template v-slot:[`item.badgeType`]="{ item }">
                      <b>
                        {{
                          item.isPermanent
                            ? $t("admin.employee.permanent")
                            : $t("admin.employee.temporary")
                        }}
                        {{ !item.isAssigned ? "*" : "" }}
                      </b>
                    </template>
                    <template v-slot:[`item.badgeNumber`]="{ item }">
                      <span v-if="item.badgeNumber !== ''">
                        {{ item.badgeNumber }}
                      </span>

                      <ValidationProvider
                        :name="$t('admin.employee.badge')"
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                        v-else
                      >
                        <v-autocomplete
                          auto-select-first
                          v-model="item.badgeId"
                          :error-messages="errors"
                          :items="
                            availableBadges.map((f) => ({
                              text: f.serialNumber,
                              value: f.id
                            }))
                          "
                          items-text="text"
                          items-value="value"
                        />
                      </ValidationProvider>
                    </template>
                    <template v-slot:[`item.makePermanent`]="{ item }">
                      <ValidationProvider>
                        <v-checkbox
                          v-model="item.makePermanent"
                          :label="$t('admin.employee.make-permanent')"
                          @click="toggleMakePermanent(item)"
                          v-if="!item.isPermanent"
                          :disabled="
                            item.unassign ||
                            (makePermanentBadge !== undefined &&
                              makePermanentBadge.id !== item.id)
                          "
                        />
                      </ValidationProvider>
                    </template>
                    <template v-slot:[`item.unassign`]="{ item }">
                      <ValidationProvider>
                        <v-checkbox
                          v-model="item.unassign"
                          :label="$t('admin.employee.unassign')"
                          v-if="item.isAssigned"
                          :disabled="
                            item.makePermanent ||
                            (item.isPermanent &&
                              makePermanentBadge !== undefined)
                          "
                        />
                        <div v-else>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                class="left-neg-5 top-neg-2"
                                @click="removeBadge(item)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("admin.employee.remove") }}</span>
                          </v-tooltip>
                        </div>
                      </ValidationProvider>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
            <v-row dense class="tw-mb-3">
              <v-col cols="12" lg="6">
                <v-btn
                  class="tw-mr-5 tw-w-24"
                  :disabled="employeeId && pristine"
                  :loading="saving"
                  color="primary"
                  type="submit"
                >
                  {{ $t("ui.actions.save") }}
                </v-btn>
                <v-btn
                  class="tw-mr-5 tw-w-24"
                  color="background2"
                  @click="closeCreateEmployeeView(false)"
                >
                  {{ $t("ui.actions.cancel") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import employeeService from "@/api/employeeService";
import imsJobRoleService from "@/api/imsJobRoleService";
import badgeService from "@/api/badgeService";

import { find, filter, orderBy } from "lodash";
import { scrollToFirstError } from "@/utils/scrollToFirstError";

export default {
  props: {
    customerId: { type: Number },
    employeeId: { type: Number }
  },
  data() {
    return {
      loading: true,
      saving: false,
      jobRoles: [],
      badges: [],
      identifier: "",
      firstName: "",
      lastName: "",
      jobRoleId: "",
      department: "",
      badgeId: "",
      assignedBadges: [],
      currentBadge: undefined,
      makePermanentBadge: undefined
    };
  },
  computed: {
    availableBadges() {
      return filter(
        this.badges,
        (b) =>
          !find(
            this.assignedBadges,
            (item) => item !== this.currentBadge && item.badgeId === b.id
          )
      );
    }
  },
  async created() {
    this.$appInsights?.trackEvent({
      name: `Manage Employee View Loaded`
    });
    await this.loadJobRoles();
    await this.loadBadges();

    if (this.employeeId) {
      await this.loadEmployee();
    } else {
      await this.setUniqueIdentifier();
    }

    this.loading = false;
  },
  methods: {
    async loadJobRoles() {
      try {
        this.jobRoles = orderBy(
          await imsJobRoleService.getActiveJobRolesByCustomerId(
            this.customerId
          ),
          ["name"]
        );
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.employee.error-messages.load-job-roles")
        });
      }
    },
    async loadBadges() {
      try {
        this.badges = orderBy(
          await badgeService.getBadgesByCustomerId(this.customerId),
          ["serialNumber"]
        );
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.employee.error-messages.load-badges")
        });
      }
    },
    async loadEmployee() {
      try {
        const employee = await employeeService.get(this.employeeId);
        this.identifier = employee.employeeIdentifier;
        this.firstName = employee.firstName;
        this.lastName = employee.lastName;
        this.jobRoleId = employee.jobRoleId;
        this.department = employee.department;
        this.assignedBadges = employee.badges.map((b) => ({
          id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
          badgeId: b.id,
          badgeNumber: b.serialNumber,
          isPermanent: b.isPermanent,
          isAssigned: true,
          makePermanent: false,
          unassign: false
        }));
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.employee.error-messages.load-employee")
        });
      }
    },
    async setUniqueIdentifier() {
      try {
        this.identifier = await employeeService.getUniqueIdentifier(
          this.customerId
        );
      } catch (err) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.employee.error-messages.load-employee")
        });
      }
    },
    addBadge() {
      if (this.currentBadge) {
        this.currentBadge.badgeNumber = find(
          this.badges,
          (b) => b.id === this.currentBadge.badgeId
        ).serialNumber;
      }
      const defaultToPermanent = this.assignedBadges.length === 0;

      this.currentBadge = {
        id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
        badgeId: null,
        badgeNumber: "",
        isPermanent: defaultToPermanent,
        isAssigned: false,
        makePermanent: defaultToPermanent,
        unassign: false
      };
      this.assignedBadges.push(this.currentBadge);

      if (defaultToPermanent) {
        this.toggleMakePermanent(this.currentBadge);
      }
    },
    removeBadge(item) {
      const index = this.assignedBadges.findIndex((b) => b.id === item.id);
      this.assignedBadges.splice(index, 1);

      if (this.currentBadge.id === item.id) {
        this.currentBadge = undefined;
      }
      if (item.makePermanent) {
        this.toggleMakePermanent({ makePermanent: false });
      }
    },
    toggleMakePermanent(item) {
      this.makePermanentBadge = item.makePermanent ? item : undefined;

      const permanentBadge = find(
        this.assignedBadges,
        (b) => b.isAssigned && b.isPermanent
      );
      if (permanentBadge) {
        permanentBadge.unassign = item.makePermanent;
      }
    },
    async validateAndSubmit() {
      const valid = await this.$refs.employeeForm.validate();

      if (!valid) {
        await this.$nextTick();
        scrollToFirstError(this.$el);

        this.$appInsights?.trackEvent({
          name: `Employee Form Error`
        });
        return;
      }

      this.loading = true;
      this.saving = true;

      try {
        const badges = [];
        if (this.employeeId) {
          filter(this.assignedBadges, (b) => !b.unassign).forEach((b) => {
            badges.push({
              id: b.badgeId,
              employeeId: this.employeeId,
              isPermanent: b.isPermanent || b.makePermanent
            });
          });
        } else if (this.badgeId !== "") {
          badges.push({
            id: this.badgeId,
            isPermanent: true
          });
        }

        await employeeService.saveEmployee({
          id: this.employeeId,
          customerId: this.customerId,
          employeeIdentifier: this.identifier,
          firstName: this.firstName,
          lastName: this.lastName,
          jobRoleId: this.jobRoleId,
          department: this.department,
          badges: badges
        });
        this.$appInsights?.trackEvent({
          name: `Manage Employee Form Success`
        });
      } catch (err) {
        this.$appInsights?.trackEvent({
          name: `Manage Employee Server Error`
        });
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("admin.employee.error-messages.save")
        });
        return;
      } finally {
        this.loading = false;
        this.saving = false;
      }

      this.closeCreateEmployeeView(true);
    },
    closeCreateEmployeeView(reloadEmployees) {
      this.$emit("close-create-employee-view", reloadEmployees);
    }
  }
};
</script>

<style scoped>
.left-neg-5 {
  left: -5px;
}
.top-neg-2 {
  top: -2px;
}
</style>
