<template>
  <div>
    <report-summary :cells="cells" />
    <report-performance-bands :performanceBands="performanceBands" />
    <report-table :headers="headers" :items="items" />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";

import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportPerformanceBands from "@/views/reports/components/reportSections/ReportPerformanceBands.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";

import {
  sumEntries,
  setEventsIndicator,
  createPercentage
} from "@/views/reports/utils/calculate";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";
import i18n from "@/plugins/i18n";

export default {
  mixins: [reportMixin],
  components: {
    ReportSummary,
    ReportPerformanceBands,
    ReportTable
  },
  data() {
    return {
      compatibleReportId: 38,
      reportFilters: ["shifts", "imsJobRoles", "locations", "timeframe"],
      hiddenReportActions: ["saveSlide"],
      cells: [],
      performanceBands: [],
      headers: [
        {
          text: i18n.t("reports.table.role"),
          value: "jobRole",
          sortable: true
        },
        {
          text: i18n.t("reports.table.compliant-opportunities"),
          value: "compliantOpportunities",
          sortable: true,
          display: "Locale"
        },
        {
          text: i18n.t("reports.table.total-opportunities"),
          value: "opportunities",
          sortable: true,
          display: "Locale"
        },
        {
          text: i18n.t("reports.table.performance-rate-ins"),
          value: "inPerformance",
          sortable: true,
          display: "Percentage"
        },
        {
          text: i18n.t("reports.table.performance-rate-outs"),
          value: "outPerformance",
          sortable: true,
          display: "Percentage"
        },
        {
          text: i18n.t("reports.table.performance"),
          value: "performance",
          sortable: true,
          display: "Percentage"
        }
      ],
      items: []
    };
  },
  methods: {
    createCsvData() {
      let headers = this.headers;
      headers = headers.filter((header) => header.value !== "indicator");
      const headersText = headers.map((header) => header.text);
      const data = csvDataMapper(this.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    },
    createSummary() {
      const data = this.reportResponse.dataPoints;

      const totalOpportunities = sumEntries({
        entries: data,
        inputField: "opportunities"
      });
      const totalCompliant = sumEntries({
        entries: data,
        inputField: "compliantOpportunities"
      });
      const totalPercentage = createPercentage({
        dividend: totalCompliant,
        divisor: totalOpportunities
      });

      this.cells = [
        {
          text: i18n.t("reports.compliant-opportunities"),
          value: totalCompliant,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total-opportunities"),
          value: totalOpportunities,
          display: "Locale"
        },
        {
          text: i18n.t("reports.overall-performance-rate"),
          value: totalPercentage,
          display: "Percentage"
        }
      ];
    },
    createPerformanceBands() {
      this.performanceBands = this.reportResponse.performanceBands;

      if (this.performanceBands.length) {
        const headers = this.headers;

        headers.push({
          text: i18n.t("reports.table.indicator"),
          value: "indicator",
          sortable: false,
          display: "Indicator"
        });

        this.headers = headers;
      }
    },
    createTable() {
      let items = this.reportResponse.dataPoints;

      items = setEventsIndicator({
        items,
        performanceBands: this.performanceBands,
        performanceField: "performance"
      });

      this.items = items;
    },
    handleReportResponse() {
      this.createSummary();
      this.createPerformanceBands();
      this.createTable();
    }
  }
};
</script>
