import HHCompliance from "@/views/reports/OBV/HHCompliance.vue";
import PPECompliance from "@/views/reports/OBV/PPECompliance.vue";
import HHQuickNotes from "@/views/reports/OBV/HHQuickNotes.vue";
import HHMoments from "@/views/reports/OBV/HHMoments.vue";
import HHQuarterlyActivityHistory from "@/views/reports/OBV/HHQuarterlyActivityHistory.vue";
import HHMonthlyActivityHistory from "@/views/reports/OBV/HHMonthlyActivityHistory.vue";
import HHObserver from "@/views/reports/OBV/HHObserver.vue";
import HHJobRoleAndUnit from "@/views/reports/OBV/HHJobRoleAndUnit.vue";
import HHJobRoleAndMoments from "@/views/reports/OBV/HHJobRoleAndMoments";
import HHJobRoleAndOutcome from "@/views/reports/OBV/HHJobRoleAndOutcome";

export const OBV_VUE_COMPONENTS = {
  HHQuickNotes,
  HHCompliance,
  HHMoments,
  HHObserver,
  PPECompliance,
  HHQuarterlyActivityHistory,
  HHMonthlyActivityHistory,
  HHJobRoleAndUnit,
  HHJobRoleAndMoments,
  HHJobRoleAndOutcome
};
