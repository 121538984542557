import { get } from "./apiClient";

export default {
  async getHistory() {
    return await get("systemStatusHistory");
  },
  async getFullStatus() {
    return await get("systemStatus");
  }
};
