<template>
  <div>
    <report-summary :cells="cells" />
    <report-fixed-legend :goal="goal" :hideComplianceLegend="true" />
    <report-graph :chartData="chartData" />
    <report-table
      :showSearch="false"
      :showTotalRow="true"
      :items="tableData.items"
      :headers="tableData.headers"
      :showCustomHeader="true"
      :customHeaderSort="customHeaderSort"
    >
      <template v-slot:customHeader>
        <thead v-if="tableData.headers.length" class="v-data-table-header">
          <tr class="smartlink-report-table-preheader">
            <template v-for="(header, index) in tableData.headers">
              <th
                @click="sortColumn(header.value)"
                class="smartlink-report-tall-header"
                rowspan="2"
                v-if="index < 1"
                :key="header.text"
              >
                {{ header.text }}
                <v-icon
                  v-if="header.value === customHeaderSort.value"
                  color="black"
                  small
                >
                  {{
                    customHeaderSort.columnDescending
                      ? "mdi-arrow-down"
                      : "mdi-arrow-up"
                  }}
                </v-icon>
              </th>
            </template>
            <th
              v-for="header in preHeaders"
              :key="header.text"
              :colspan="header.colspan"
            >
              {{ header.text }}
            </th>
          </tr>
          <tr class="smartlink-report-table-primary-header">
            <template v-for="(header, index) in tableData.headers">
              <th
                @click="sortColumn(header.value)"
                v-if="index > 0"
                :key="`${header.text}${index}`"
              >
                {{ header.text }}
                <v-icon
                  v-if="header.value === customHeaderSort.value"
                  color="black"
                  small
                >
                  {{
                    customHeaderSort.columnDescending
                      ? "mdi-arrow-down"
                      : "mdi-arrow-up"
                  }}
                </v-icon>
              </th>
            </template>
          </tr>
        </thead>
      </template>
      <template v-slot:totalRow>
        <tr class="smartlink-table-total-row">
          <td>{{ $t("reports.total") }}</td>
          <td v-for="(column, index) in tableData.totalRowColumns" :key="index">
            <display-formatter-locale :value="column.total" />
          </td>
        </tr>
      </template>
    </report-table>
    <dl class="dl-horizontal-inline">
      <dt>{{ $t("reports.total-observations-abbreviation") }}</dt>
      <dd>{{ $t("reports.total-observations") }}</dd>
      <dt>{{ $t("reports.compliant-observations-abbreviation") }}</dt>
      <dd>{{ $t("reports.compliant-observations") }}</dd>
      <dt>{{ $t("reports.non-compliant-observations-abbreviation") }}</dt>
      <dd>{{ $t("reports.non-compliant-observations") }}</dd>
    </dl>
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";
import customHeaderSortMixin from "@/views/reports/mixins/customHeaderSortMixin";
import ReportSummary from "@/views/reports/components/reportSections/ReportSummary.vue";
import ReportFixedLegend from "@/views/reports/components/reportSections/ReportFixedLegend.vue";
import ReportTable from "@/views/reports/components/reportSections/reportTable/ReportTable.vue";
import DisplayFormatterLocale from "@/views/reports/components/reportSections/displayFormatters/DisplayFormatterLocale.vue";
import ReportGraph from "@/views/reports/components/reportSections/reportGraph/ReportGraph.vue";
import i18n from "@/plugins/i18n";
import ChartBuilder, {
  GraphDataTypes
} from "@/views/reports/components/reportSections/reportGraph/chartBuilder";
import { mapState } from "vuex";
import groupBy from "lodash/groupBy";
import { csvDataMapper } from "@/views/reports/utils/csvDataMapper";

export default {
  mixins: [reportMixin, customHeaderSortMixin],
  components: {
    ReportSummary,
    ReportFixedLegend,
    ReportTable,
    ReportGraph,
    DisplayFormatterLocale
  },
  data() {
    return {
      compatibleReportId: 35,
      reportFilters: ["timeframe", "locations", "obvJobRoles"],
      hiddenReportActions: ["saveSlide"],
      cells: [],
      reportData: {},
      showAllRows: -1,
      goal: 0,
      chartData: {},
      tableData: {
        headers: [],
        items: [],
        totalRowColumns: []
      },
      preHeaders: [],
      customHeaderSort: {}
    };
  },
  computed: {
    ...mapState("reports", ["isSlideshow"])
  },
  mounted() {
    this.setCustomHeaderSort("jobRole");
  },
  methods: {
    handleReportResponse() {
      if (
        !this.reportResponse?.dataPoints ||
        !Array.isArray(this.reportResponse.dataPoints) ||
        !Array.isArray(this.reportResponse.momentTotals)
      ) {
        return;
      }

      this.reportData = { ...this.reportResponse };
      this.reportData.dataPoints = this.reportData.dataPoints.map(
        (dataPoint) => ({
          ...dataPoint,
          moment: i18n.t(`protocolOpportunities.${dataPoint.moment}`)
        })
      );
      this.reportData.momentTotals = this.reportData.momentTotals.map(
        (total) => ({
          ...total,
          moment: i18n.t(`protocolOpportunities.${total.moment}`)
        })
      );
      this.goal = this.reportResponse.performanceGoal;
      this.createSummary();
      this.createGraph();
      this.createTableData();
    },
    createSummary() {
      this.cells = [
        {
          text: i18n.t("reports.compliant"),
          value: this.reportData.compliantObservations,
          display: "Locale"
        },
        {
          text: i18n.t("reports.total"),
          value: this.reportData.totalObservations,
          display: "Locale"
        },
        {
          text: i18n.t("reports.performance-rate"),
          value: this.reportData.complianceIndex,
          display: "Percentage"
        }
      ];
    },
    createGraph() {
      const data = this.reportData.dataPoints;
      const builder = new ChartBuilder({
        isSlideshow: this.isSlideshow,
        isPrint: this.isPrint
      });
      builder
        .chartCategorized({
          items: data,
          valueField: "complianceIndex",
          valueFieldType: GraphDataTypes.FIXED,
          datasetField: "jobRole",
          categoryField: "moment",
          valueScaleOptions: {
            label: i18n.t("reports.compliance"),
            ticks: {
              format: "${value}%"
            }
          }
        })
        .valueScale.showGoal(this.goal);
      this.chartData = builder.build();
    },
    createTableData() {
      const headers = [];
      headers.push({
        text: i18n.t("job-roles.singular"),
        value: "jobRole",
        csvHeader: i18n.t("job-roles.singular")
      });

      const momentData = groupBy(this.reportData.dataPoints, "moment");
      const preHeaders = [];
      Object.keys(momentData).forEach((moment) => {
        preHeaders.push({
          text: moment,
          colspan: 3
        });
        headers.push({
          text: i18n.t("reports.total-observations-abbreviation"),
          value: `${moment}-Observations`,
          csvHeader: `${moment} ${i18n.t(
            "reports.total-observations-abbreviation"
          )}`
        });
        headers.push({
          text: i18n.t("reports.compliant-observations-abbreviation"),
          value: `${moment}-Compliant`,
          csvHeader: `${moment} ${i18n.t(
            "reports.compliant-observations-abbreviation"
          )}`
        });
        headers.push({
          text: i18n.t("reports.non-compliant-observations-abbreviation"),
          value: `${moment}-NonCompliant`,
          csvHeader: `${moment} ${i18n.t(
            "reports.non-compliant-observations-abbreviation"
          )}`
        });
      });
      preHeaders.push({
        text: i18n.t("reports.total"),
        colspan: 3
      });
      headers.push({
        text: i18n.t("reports.total-observations-abbreviation"),
        value: "Total-Observations",
        csvHeader: `${i18n.t("reports.total")} ${i18n.t(
          "reports.total-observations-abbreviation"
        )}`
      });
      headers.push({
        text: i18n.t("reports.compliant-observations-abbreviation"),
        value: "Total-Compliant",
        csvHeader: `${i18n.t("reports.total")} ${i18n.t(
          "reports.compliant-observations-abbreviation"
        )}`
      });
      headers.push({
        text: i18n.t("reports.non-compliant-observations-abbreviation"),
        value: "Total-NonCompliant",
        csvHeader: `${i18n.t("reports.total")} ${i18n.t(
          "reports.non-compliant-observations-abbreviation"
        )}`
      });
      this.preHeaders = preHeaders;
      this.tableData.headers = headers;
      const jobRoleData = groupBy(this.reportData.dataPoints, "jobRole");
      const tableDataItems = [];
      let tableTotalObservations = 0,
        tableTotalCompliant = 0,
        tableTotalNonCompliant = 0;
      Object.keys(jobRoleData).forEach((jobRole) => {
        const rowData = {};
        let rowTotalObservations = 0;
        let rowTotalCompliant = 0;
        let rowTotalNonCompliant = 0;
        rowData.jobRole = jobRole;
        Object.entries(momentData).forEach((entry) => {
          const [moment, momentJobRoles] = entry;
          const match = momentJobRoles.find((item) => item.jobRole === jobRole);
          rowData[`${moment}-Observations`] =
            match === undefined ? null : match.totalObservations;
          rowTotalObservations += rowData[`${moment}-Observations`] ?? 0;
          rowData[`${moment}-Compliant`] =
            match === undefined ? null : match.compliantObservations;
          rowTotalCompliant += rowData[`${moment}-Compliant`] ?? 0;
          rowData[`${moment}-NonCompliant`] =
            match === undefined
              ? null
              : match.totalObservations - match.compliantObservations;
          rowTotalNonCompliant += rowData[`${moment}-NonCompliant`] ?? 0;
        });
        rowData["Total-Observations"] = rowTotalObservations;
        tableTotalObservations += rowTotalObservations;
        rowData["Total-Compliant"] = rowTotalCompliant;
        tableTotalCompliant += rowTotalCompliant;
        rowData["Total-NonCompliant"] = rowTotalNonCompliant;
        tableTotalNonCompliant += rowTotalNonCompliant;
        tableDataItems.push(rowData);
      });
      this.tableData.items = tableDataItems;
      const totalRow = [];
      Object.keys(momentData).forEach((moment) => {
        const momentTotal = this.reportData.momentTotals.find(
          (momentTotal) => momentTotal.moment === moment
        );
        totalRow.push({
          total:
            momentTotal === undefined ? null : momentTotal.totalObservations
        });
        totalRow.push({
          total:
            momentTotal === undefined ? null : momentTotal.compliantObservations
        });
        totalRow.push({
          total:
            momentTotal === undefined
              ? null
              : momentTotal.totalObservations -
                momentTotal.compliantObservations
        });
      });
      totalRow.push({ total: tableTotalObservations });
      totalRow.push({ total: tableTotalCompliant });
      totalRow.push({ total: tableTotalNonCompliant });
      this.tableData.totalRowColumns = totalRow;
    },
    sortColumn(headerValue) {
      const columnDescending = this.customHeaderSort.value === headerValue;
      const clearSort =
        this.customHeaderSort.columnDescending && columnDescending;

      this.customHeaderSort = {
        value: clearSort ? null : headerValue,
        columnDescending,
        clearSort
      };
    },
    createCsvData() {
      const headers = this.tableData.headers;
      const headersText = headers.map((header) => header.csvHeader);
      const data = csvDataMapper(this.tableData.items, headers);

      this.$store.commit("reports/setCsvData", {
        headersText,
        data
      });
    }
  }
};
</script>

<style scoped lang="postcss">
.smartlink-report-table-preheader th {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;
  text-align: center !important;
}
.smartlink-report-table-primary-header th {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}
.smartlink-report-tall-header {
  border-left: 1px solid #eee;
}
dl.dl-horizontal-inline dt {
  display: inline-block;
  font-weight: bold;
}

dl.dl-horizontal-inline dt::after {
  content: ":";
}

dl.dl-horizontal-inline dd {
  display: inline-block;
  margin-left: 5px;
}

dl.dl-horizontal-inline dd::after {
  content: "|";
  margin-left: 5px;
}

dl.dl-horizontal-inline dd:last-child::after {
  content: "";
  margin-left: 0;
}
</style>
