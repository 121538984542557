<template>
  <v-skeleton-loader :type="`image`" v-if="reportLoading || !chartData.type" />
  <div v-else style="position: relative">
    <v-speed-dial
      id="smartlink-report-actions"
      v-if="
        displayMode !== DISPLAY_MODES.PRINT && !isSlideshow && !instructionsCard
      "
      v-model="showReportGraphActions"
      :absolute="true"
      :open-on-hover="false"
      direction="right"
      transition="slide-x-reverse-transition"
      :style="positionSpeedDial"
    >
      <template v-slot:activator>
        <v-btn
          @click="fireAppInsightsEvent('Toggle Graph Actions Speed Dial')"
          v-model="showReportGraphActions"
          color="primary"
          fab
        >
          <v-icon v-if="showReportGraphActions"> mdi-close </v-icon>
          <v-icon v-else> mdi-chart-{{ chartData.type }} </v-icon>
        </v-btn>
      </template>
      <v-card style="padding: 15px; white-space: nowrap">
        <v-btn
          v-if="showClearGraph"
          name="clearGraphBtn"
          depressed
          color="primary"
          class="tw-mx-2"
          @click="onClearGraph"
        >
          {{ $t("ui.clear-graph") }}
        </v-btn>
        <v-switch
          v-if="showZoomReportAction"
          v-model="zoomReport"
          @change="fireAppInsightsEvent('Graph Actions: Report Zoom')"
          :label="$t('reports.graph.zoom-report')"
          hide-details
        />
        <v-switch
          v-if="showDisplayTresholdsAction"
          v-model="displayThresholds"
          @change="fireAppInsightsEvent('Graph Actions: Display Thresholds')"
          :label="$t('reports.graph.display-thresholds')"
          hide-details
        />
        <v-switch
          name="display-performance-rates-switch"
          @change="
            fireAppInsightsEvent(
              'Graph Actions: Display Performance Indicators'
            )
          "
          v-model="displayDataLabels"
          :label="customDataLabelToggleLabel || defaultDataLabelToggleLabel"
          hide-details
        />
      </v-card>
    </v-speed-dial>
    <div
      style="position: relative"
      :class="instructionsCard ? 'show-scrim' : ''"
    >
      <report-graph-renderer
        v-if="chartData.type"
        :height="500"
        :chartData="chartData"
        ref="chart"
        class="graph"
      />
      <v-card v-if="instructionsCard" id="instructionsCard" elevation="2">
        <h3>{{ instructionsCard.heading }}</h3>
        <p>
          {{ instructionsCard.body }}
        </p>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportGraphRenderer from "./components/ReportGraphRenderer.vue";
import { DISPLAY_MODES } from "@/store/modules/application";
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";

export default {
  components: {
    ReportGraphRenderer
  },
  props: {
    instructionsCard: {
      type: Object
    },
    chartData: {
      type: Object,
      required: true
    },
    repositionSpeedDial: {
      type: Boolean
    },
    showLegend: {
      type: Boolean
    },
    customDataLabelToggleLabel: {
      type: String
    },
    showZoomReportAction: {
      type: Boolean
    },
    showDisplayTresholdsAction: {
      type: Boolean
    }
  },
  data() {
    return {
      DISPLAY_MODES,
      showReportGraphActions: false,
      zoomReport: false,
      displayThresholds: true,
      displayDataLabels: true,
      defaultDataLabelToggleLabel: i18n.t(
        "reports.graph.display-performance-rates"
      )
    };
  },
  computed: {
    ...mapState("application", ["displayMode"]),
    ...mapState("preferences", ["language"]),
    ...mapState("reports", [
      "isSlideshow",
      "reportId",
      "reportLoading",
      "selectedData",
      "graphActions"
    ]),
    showClearGraph() {
      return this.selectedData.length > 0;
    },
    positionSpeedDial() {
      return this.repositionSpeedDial
        ? {
            top: "30px",
            left: "-30px"
          }
        : {
            top: "-60px",
            left: "27px"
          };
    },
    graphActionsState() {
      return {
        displayDataLabels: this.displayDataLabels,
        zoomReport: this.zoomReport,
        displayThresholds: this.displayThresholds
      };
    }
  },
  mounted() {
    const urlQueryGraphActions = this.$route?.query?.graphActions;

    const urlJsonGraphActions = urlQueryGraphActions
      ? JSON.parse(urlQueryGraphActions)
      : {};

    this.displayDataLabels = urlQueryGraphActions
      ? urlJsonGraphActions.displayDataLabels
      : this.graphActions.displayDataLabels;
    this.displayThresholds = urlQueryGraphActions
      ? urlJsonGraphActions.displayThresholds
      : this.graphActions.displayThresholds;
    this.zoomReport = urlQueryGraphActions
      ? urlJsonGraphActions.zoomReport
      : this.graphActions.zoomReport;
  },
  watch: {
    chartData: {
      immediate: true,
      async handler() {
        if (this.reportLoading) {
          return;
        }

        await this.$nextTick();
        this.$refs.chart.update();
      }
    },
    reportLoading(isReportLoading) {
      if (!isReportLoading && this.$refs.chart) {
        this.$refs.chart.update();
      }
    },
    displayMode(value) {
      if (value === DISPLAY_MODES.NORMAL) {
        this.$refs.chart.update();
      }
    },
    graphActionsState(value) {
      this.$store.commit("reports/setGraphActions", value);
    }
  },
  methods: {
    fireAppInsightsEvent(name) {
      this.$appInsights?.trackEvent({ name });
    },
    onClearGraph() {
      this.$emit("on-clear-graph", []);
    }
  }
};
</script>

<style scoped>
#instructionsCard {
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.show-scrim {
  background: #999;
}
.smartlink-graph-header.absolute-position {
  position: absolute;
  top: -37px;
  right: 0;
}
@media print {
  .graph {
    @apply tw-pointer-events-none;
  }
}
#smartlink-report-actions .v-speed-dial--direction-right .v-speed-dial__list {
  flex-direction: column;
}
#smartlink-report-actions
  .v-speed-dial__list
  .v-card
  .v-input--selection-controls:first-child {
  margin-top: 0;
}
</style>
