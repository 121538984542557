<template>
  <div>
    <v-snackbar color="primary" top v-model="showSuccessMessage" timeout="5000">
      {{ successMessage }}
    </v-snackbar>
    <facility-selection-bar />
    <div class="tw-flex tw-flex-wrap tw-justify-between tw-mb-5">
      <h1>{{ $t("alert-subscriptions.manage-alert-subscriptions") }}</h1>
      <div>
        <v-btn
          :to="{
            name: 'createAlertSubscription',
            query: {
              isInstantAlert: false
            }
          }"
          color="primary"
          class="tw-mr-5 tw-my-5 md:tw-my-0"
        >
          {{ $t("alert-subscriptions.new-scheduled-alert") }}
        </v-btn>
        <v-btn
          :to="{
            name: 'createAlertSubscription',
            query: {
              isInstantAlert: true
            }
          }"
          color="primary"
        >
          {{ $t("alert-subscriptions.new-instant-alert") }}
        </v-btn>
      </div>
    </div>
    <created-alert-subscriptions-table />
    <h2>{{ $t("alert-subscriptions.subscribed-alert-subscriptions") }}</h2>
    <subscribed-alert-subscriptions-table />
  </div>
</template>

<script>
import FacilitySelectionBar from "@/components/layout/FacilitySelectionBar.vue";
import CreatedAlertSubscriptionsTable from "@/views/alertSubscriptions/components/CreatedAlertSubscriptionsTable.vue";
import SubscribedAlertSubscriptionsTable from "@/views/alertSubscriptions/components/SubscribedAlertSubscriptionsTable.vue";

export default {
  components: {
    FacilitySelectionBar,
    CreatedAlertSubscriptionsTable,
    SubscribedAlertSubscriptionsTable
  },
  data: () => ({
    showSuccessMessage: false,
    successMessage: ""
  }),
  mounted() {
    if (this.$route.params.successMessage) {
      this.setSuccessMessage(this.$route.params.successMessage);
    }
  },
  methods: {
    setSuccessMessage(message) {
      this.showSuccessMessage = true;
      this.successMessage = message;
    }
  }
};
</script>
