import { PRODUCTS } from "@/types/products";
import { AMS_REPORTS } from "@/types/amsReports";
import { IMS_REPORTS } from "@/types/imsReports";
import { OBV_REPORTS } from "@/types/obvReports";
import { SA_REPORTS } from "@/types/saReports";

export function determineProductFromReportId(reportId) {
  if (reportId === undefined || reportId === null) {
    return null;
  }
  let id;
  if (!Number.isInteger(reportId)) {
    id = parseInt(reportId);
    if (isNaN(id)) {
      return null;
    }
  } else {
    id = reportId;
  }
  if (Object.values(AMS_REPORTS).indexOf(id) !== -1) {
    return PRODUCTS.ECM;
  } else if (Object.values(OBV_REPORTS).indexOf(id) !== -1) {
    return PRODUCTS.OBS;
  } else if (Object.values(SA_REPORTS).indexOf(id) !== -1) {
    return PRODUCTS.SA;
  } else if (Object.values(IMS_REPORTS).indexOf(id) !== -1) {
    return PRODUCTS.IMS;
  } else {
    return null;
  }
}
