<template>
  <div class="tw-w-full tw-px-4 tw-py-4" data-name="custom-dates-selection">
    <v-date-picker
      v-model="dates"
      :max="max"
      :picker-date.sync="pickerDate"
      @input="onInput"
      :title-date-format="createTitle"
      full-width
      range
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        start: undefined,
        end: undefined
      })
    }
  },
  data: () => ({
    dates: [],
    pickerDate: undefined,
    max: new Date().toISOString()
  }),
  mounted() {
    if (this.value.start) {
      const start = this.value.start.toLocaleDateString("en-CA");
      this.dates.push(start);
      this.dates.push(this.value.end.toLocaleDateString("en-CA"));
      this.pickerDate = start;
    }
  },
  methods: {
    onInput() {
      const sortedDates = [...this.dates]
        .map((d) => new Date(d.replace(/-/g, "/")))
        .sort((a, b) => new Date(a) - new Date(b));

      const start = sortedDates[0];
      let end = null;

      if (this.dates.length > 1) {
        const today = new Date();
        if (today.getDate() === sortedDates[1].getDate()) {
          end = new Date(
            sortedDates[1].setHours(today.getHours(), today.getMinutes())
          );
        } else {
          end = new Date(sortedDates[1].setHours(23, 59));
        }
      }

      this.$emit("input", { start, end });
    },
    createTitle(dates) {
      const sortedDates = [...dates].sort((a, b) => new Date(a) - new Date(b));
      return sortedDates.join(" - ");
    }
  }
};
</script>
