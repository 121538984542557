<template>
  <div
    class="
      tw-flex tw-flex-col tw-min-h-200px tw-text-center tw-w-full
      rounded
      tw-mb-6
      lg:tw-mx-15px
    "
    :class="[
      `${bgColor}`,
      'lg:tw-max-w-sm',
      { 'lg:tw-max-w-2xl tw-min-h-300px': isSlideshow }
    ]"
    ref="reportBlock"
    :style="blockStyleObject"
    @click="reportId ? loadReport(reportId) : null"
  >
    <h3
      class="
        tw-text-white tw-font-black tw-px-1.5 tw-py-5
        rounded-tl rounded-tr
        tw-w tw-text-2xl
      "
      :class="[
        `${headerColor}`,
        bodyIsSingleValue || isSlideshow || reportId
          ? ''
          : 'lg:tw-px-4 xl:tw-px-1 lg:tw-text-xl',
        { 'tw-text-4xl': isSlideshow }
      ]"
    >
      {{ title }}
    </h3>
    <div
      ref="valueRow"
      v-if="bodyIsSingleValue"
      class="
        tw-flex
        tw-text-white
        tw-text-5xl
        tw-font-bold
        tw-justify-center
        tw-h-full
        tw-items-center
        tw-pb-5
      "
      :class="{ 'text-7xl': isSlideshow }"
    >
      <component :is="display || 'div'" :value="body" />
    </div>
    <div v-else class="tw-w-full tw-h-full" style="padding: 10px 20px">
      <div
        ref="valueRow"
        v-for="row in body"
        :key="row.text"
        class="
          tw-flex tw-text-white tw-text-lg
          lg:tw-text-sm
          xl:tw-text-lg
          tw-font-bold tw-w-full
        "
        :class="{ 'xl:tw-text-3xl': isSlideshow }"
        v-show="row.value >= 0"
      >
        <div class="tw-uppercase tw-text-right tw-pr-2.5 tw-w-3/5">
          {{ row.text }}
        </div>
        <div class="tw-text-right tw-pr-2.5 tw-w-2/5">
          <component
            :is="row.display || 'td'"
            :value="row.value != null ? row.value : '0.0'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayFormatterPercentage from "../displayFormatters/DisplayFormatterPercentage.vue";
import DisplayFormatterLocale from "../displayFormatters/DisplayFormatterLocale.vue";
import DisplayFormatterNumber from "../displayFormatters/DisplayFormatterNumber.vue";
import { mapState } from "vuex";
import { navigateToReport } from "@/router";

export default {
  components: {
    Percentage: DisplayFormatterPercentage,
    Locale: DisplayFormatterLocale,
    Number: DisplayFormatterNumber
  },
  props: {
    bgColor: {
      type: String,
      default: "tw-bg-smartlink-summary-blue"
    },
    headerColor: {
      type: String,
      default: "tw-bg-smartlink-summary-blue"
    },
    thresholdColor: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ""
    },
    body: {
      type: [String, Number, Array],
      default: "0.0"
    },
    display: {
      type: String,
      default: "div"
    },
    reportId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState("reports", ["isSlideshow"]),
    bodyIsSingleValue() {
      return !Array.isArray(this.body);
    },
    blockStyleObject() {
      const { thresholdColor, reportId } = this;
      return {
        backgroundColor: thresholdColor,
        cursor: reportId ? "pointer" : undefined
      };
    }
  },
  methods: {
    async loadReport(reportId) {
      await this.$store.dispatch("reports/resetFilters");
      navigateToReport(reportId);
    }
  }
};
</script>

<style scoped lang="postcss">
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

@media print {
  div {
    break-inside: avoid;
  }
}
</style>
