<template>
  <v-dialog
    :value="confirmationDialogData.isActive"
    @input="$emit('toggle-confirmation-dialog')"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ confirmationDialogData.title }}
      </v-card-title>
      <v-card-text>
        <p>
          {{ confirmationDialogData.content }}
        </p>
        <strong>{{ confirmationDialogData.name }}</strong>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="purellGray"
          text
          @click="$emit('toggle-confirmation-dialog')"
        >
          {{ $t("ui.actions.cancel") }}
        </v-btn>
        <v-btn color="error" @click="$emit('action-confirmed')">
          {{ confirmationDialogData.confirmButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    confirmationDialogData: {
      type: Object
    }
  }
};
</script>
