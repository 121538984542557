import { get, put } from "./apiClient";

export default {
  async get(locationId) {
    return await get(`location/${locationId}/performanceThresholds`);
  },
  async put(locationId, performanceThresholds) {
    await put(
      `location/${locationId}/performanceThresholds`,
      performanceThresholds
    );
  }
};
