<template>
  <v-skeleton-loader :type="`table-heading`" v-if="reportLoading" />
  <ul
    v-else-if="thresholds.length"
    class="
      tw-flex tw-flex-col
      md:tw-flex-row
      print:tw-flex-row print:tw-justify-center
      md:tw-justify-center
      tw-flex-wrap tw-mx-auto tw-list-none
      print:tw-text-sm
    "
  >
    <li
      v-for="(threshold, i) in thresholds"
      class="tw-p-2 tw-flex tw-flex-row tw-items-center"
      :key="i"
    >
      <span
        ><svg
          width="45"
          height="10"
          class="tw-px-1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0"
            y1="3"
            x2="30"
            y2="3"
            :stroke="threshold.color"
            :stroke-dasharray="threshold.dashValue"
            stroke-width="3"
          />
        </svg>
      </span>
      <div class="tw-flex tw-flex-row" :class="{ 'tw-text-2xl': isSlideshow }">
        <div>{{ threshold.title }}</div>
        <div v-if="threshold.limit" class="tw-px-1">
          ({{ threshold.limit }}%)
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { hexToRgba } from "@/utils/colorFormatters";
import { mapState } from "vuex";
export default {
  props: {
    baseline: {
      type: Number,
      default: 0
    },
    goal: {
      type: Number,
      default: 0
    },
    hideComplianceLegend: {
      type: Boolean
    }
  },
  computed: {
    ...mapState("reports", ["isSlideshow", "reportLoading"]),
    thresholds() {
      if (!this.goal) {
        return [];
      }

      const thresholds = [];

      if (this.goal) {
        const goal = {
          color: hexToRgba("#9BC84A", 1),
          limit: this.goal,
          title: this.$t("reports.goal"),
          dashValue: 10
        };
        thresholds.push(goal);
      }

      if (!this.hideComplianceLegend) {
        const complianceLegend = {
          color: hexToRgba("#00659B"),
          limit: undefined,
          title: this.$t("reports.trend-analysis"),
          dashValue: 0
        };

        thresholds.push(complianceLegend);
      }

      return thresholds;
    }
  }
};
</script>

<style scoped lang="postcss">
ul {
  @apply tw-px-0;
}
</style>
