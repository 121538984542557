<template>
  <no-header-footer>
    <router-view />
  </no-header-footer>
</template>

<script>
import NoHeaderFooter from "@/layouts/NoHeaderFooter.vue";
export default {
  components: {
    NoHeaderFooter
  }
};
</script>

<style lang="postcss">
.login-form input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 1000px white; /* override webkit autofill background */
}
</style>
