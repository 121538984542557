export const OBV_REPORTS = {
  HH_QUICK_NOTES: 7,
  HH_COMPLIANCE: 8,
  HH_MOMENTS: 9,
  HH_OBSERVER: 10,
  HH_ACTIVITY_HISTORY_QUARTERLY: 17,
  HH_ACTIVITY_HISTORY_MONTHLY: 12,
  PPE_COMPLIANCE: 18,
  HH_JOB_ROLE_AND_UNIT: 11,
  HH_JOB_ROLE_AND_MOMENTS: 35,
  HH_JOB_ROLE_AND_OUTCOME: 36
};

export const OBV_REPORT_COMPONENT_NAMES = {
  [OBV_REPORTS.HH_QUICK_NOTES]: "HHQuickNotes",
  [OBV_REPORTS.HH_COMPLIANCE]: "HHCompliance",
  [OBV_REPORTS.HH_MOMENTS]: "HHMoments",
  [OBV_REPORTS.HH_OBSERVER]: "HHObserver",
  [OBV_REPORTS.PPE_COMPLIANCE]: "PPECompliance",
  [OBV_REPORTS.HH_ACTIVITY_HISTORY_QUARTERLY]: "HHQuarterlyActivityHistory",
  [OBV_REPORTS.HH_ACTIVITY_HISTORY_MONTHLY]: "HHMonthlyActivityHistory",
  [OBV_REPORTS.HH_JOB_ROLE_AND_UNIT]: "HHJobRoleAndUnit",
  [OBV_REPORTS.HH_JOB_ROLE_AND_MOMENTS]: "HHJobRoleAndMoments",
  [OBV_REPORTS.HH_JOB_ROLE_AND_OUTCOME]: "HHJobRoleAndOutcome"
};

export const OBV_REPORT_DISPLAY_NAMES = {
  [OBV_REPORTS.HH_QUICK_NOTES]: "reports.obv.hh-quick-notes",
  [OBV_REPORTS.HH_COMPLIANCE]: "reports.obv.hh-compliance",
  [OBV_REPORTS.HH_MOMENTS]: "reports.obv.hh-moments",
  [OBV_REPORTS.HH_OBSERVER]: "reports.obv.hh-observer",
  [OBV_REPORTS.PPE_COMPLIANCE]: "reports.obv.ppe-compliance",
  [OBV_REPORTS.HH_ACTIVITY_HISTORY_QUARTERLY]: "reports.obv.quarterly-activity",
  [OBV_REPORTS.HH_ACTIVITY_HISTORY_MONTHLY]: "reports.obv.monthly-activity",
  [OBV_REPORTS.HH_JOB_ROLE_AND_UNIT]: "reports.obv.hh-job-role-and-unit",
  [OBV_REPORTS.HH_JOB_ROLE_AND_MOMENTS]: "reports.obv.hh-job-role-and-moments",
  [OBV_REPORTS.HH_JOB_ROLE_AND_OUTCOME]: "reports.obv.hh-job-role-and-outcome"
};

// These values MUST match the reportName in the `dbo.Reports.sql` file
export const OBV_REPORT_RAW_EXPORT_NAMES = {
  [OBV_REPORTS.HH_QUICK_NOTES]: "OBS HH Quick Notes Report",
  [OBV_REPORTS.HH_COMPLIANCE]: "OBS Performance Report",
  [OBV_REPORTS.HH_MOMENTS]: "OBS HH Moments Report",
  [OBV_REPORTS.HH_OBSERVER]: "OBS HH Observer Report",
  [OBV_REPORTS.PPE_COMPLIANCE]: "OBS PPE Compliance Report",
  [OBV_REPORTS.HH_ACTIVITY_HISTORY_QUARTERLY]: "OBS Activity Report",
  [OBV_REPORTS.HH_ACTIVITY_HISTORY_MONTHLY]: "OBS Monthly Activity Report",
  [OBV_REPORTS.HH_JOB_ROLE_AND_UNIT]: "OBS HH Job Role and Unit Report",
  [OBV_REPORTS.HH_JOB_ROLE_AND_MOMENTS]: "OBS HH Job Role and Moments Report",
  [OBV_REPORTS.HH_JOB_ROLE_AND_OUTCOME]: "OBS HH Job Role and Outcome Report"
};
