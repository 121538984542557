<template>
  <div class="tw-flex tw-flex-col tw-pb-6">
    <div
      class="
        tw-flex
        tw-flex-row
        tw-pt-6
        tw-pb-10
        tw-justify-center
        tw-align-center
        tw-relative
      "
    >
      <img
        :src="logo"
        style="max-width: 100px"
        class="tw-mr-6 tw-absolute tw-left-0"
      />
      <h1 class="tw-text-4xl">{{ slideName }}</h1>
    </div>
    <v-skeleton-loader type="image@3" v-if="!initialLoadComplete" />
    <template v-if="initialLoadComplete">
      <transition name="fade" mode="out-in" appear>
        <component
          v-if="!imageUrl"
          :is="reportComponent"
          :reportResponse="reportResponse"
        />
        <img
          v-else
          :src="imageUrl"
          class="image-slide tw-max-w-full tw-w-auto tw-h-auto tw-self-center"
        />
      </transition>
    </template>
  </div>
</template>

<script>
import { AMS_VUE_COMPONENTS } from "@/views/reports/AMS/amsVueComponents";
import { AMS_REPORT_COMPONENT_NAMES } from "@/types/amsReports";
import slideshowsService from "@/api/slideshowsService";
import { registerReportsModules } from "@/views/reports/store/registerReportsModules";
import addFiltersStore from "@/store/modules/filters/filters";
import { TIMEFRAMES_STRINGS } from "@/types/timeframes";
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";
import logo from "@/assets/images/logos/purellLogo.svg";
import { sortBy } from "lodash";

export default {
  props: {
    facilityId: {
      type: Number
    },
    slideshowId: {
      type: Number
    }
  },
  components: {
    ...AMS_VUE_COMPONENTS
  },
  data() {
    return {
      logo,
      initialLoadComplete: false,
      slideshowResponse: {},
      slideIndex: 0,
      nextSlideIndex: 0,
      slide: {},
      reportResponse: {},
      imageUrl: null,
      slideName: ""
    };
  },
  computed: {
    ...mapState("reports", ["reportId"]),
    reportComponent() {
      return AMS_REPORT_COMPONENT_NAMES[this.reportId];
    }
  },
  created() {
    registerReportsModules(this.$store);
    addFiltersStore(this.$store);
  },
  async beforeMount() {
    try {
      const response = await slideshowsService.getSlideshow(this.slideshowId);
      response.slides = sortBy(response.slides, (slide) => slide.order);
      this.slideshowResponse = response;
    } catch (error) {
      this.$store.commit("application/SET_ERROR", {
        message: i18n.t("slideshows.error-messages.get-slideshow")
      });

      this.$appInsights?.trackEvent({
        name: `View Slideshow: Slideshow Service Error`
      });
    }
  },
  beforeDestroy() {
    if (this.nextSlideTimeout != null) {
      clearTimeout(this.nextSlideTimeout);
    }
  },
  methods: {
    setTimeframe(slide) {
      const filters = JSON.parse(slide.reportFilters);
      if (filters.ReportDateRange) {
        this.$store.commit("filters/timeframe/set", {
          field: "activeIds",
          value: TIMEFRAMES_STRINGS[filters.ReportDateRange]
        });
      }
    },
    async getSlide() {
      let nextSlideIndex = this.nextSlideIndex;
      let nextSlide;
      try {
        nextSlide = await slideshowsService.getSlide(
          this.slideshowResponse.slides[nextSlideIndex].id
        );
        this.$store.commit("application/CLEAR_ERROR");
      } catch (error) {
        this.$store.commit("application/SET_ERROR", {
          message: i18n.t("slideshows.error-messages.get-slide")
        });

        this.$appInsights?.trackEvent({
          name: `View Slideshow: Slide Service Error`
        });
        return;
      } finally {
        if (!this.initialLoadComplete) {
          this.initialLoadComplete = true;
        }
        this.slide = nextSlide;
        this.slideIndex = nextSlideIndex;
        nextSlideIndex++;
        if (nextSlideIndex >= this.slideshowResponse.slides.length) {
          nextSlideIndex = 0;
        }
        this.nextSlideIndex = nextSlideIndex;
      }
      this.slideName = this.slideshowResponse.slides[this.slideIndex].name;
      this.imageUrl = this.slide.imageUrl;
      if (!this.imageUrl) {
        this.setTimeframe(this.slide);
        this.slide.reportData.reportId = this.slide.reportId;
        this.$store.commit("reports/setReportId", this.slide.reportId);
        this.reportResponse = this.slide.reportData;
        this.$store.commit(
          "reports/setGraphActions",
          this.slide.reportControls
        );

        this.$store.commit("reports/setReportLoading", false);
      }
    },
    scheduleNextSlide(secondsToWait) {
      this.nextSlideTimeout = setTimeout(async () => {
        await this.getSlide();
        this.scheduleNextSlide(this.slideshowResponse.interval);
      }, secondsToWait * 1000);
    }
  },
  watch: {
    async slideshowResponse() {
      this.$store.commit("reports/setReportLoading", false);
      this.$store.commit("reports/setSlideshowFlag", true);
      this.scheduleNextSlide(0);
    }
  }
};
</script>

<style scoped lang="postcss">
.image-slide {
  max-height: 90vh;
}
</style>
