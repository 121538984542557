<template>
  <div>
    <report-performance-thresholds
      :performanceThresholds="performanceThresholds"
    />
    <report-block-row :items="shiftsRow" />
    <report-block-row v-if="items.length > 1" :items="combinedRow" />
  </div>
</template>

<script>
import reportMixin from "@/views/reports/mixins/reportMixin";
import { mapState } from "vuex";

import ReportPerformanceThresholds from "@/views/reports/components/reportSections/ReportPerformanceThresholds.vue";
import ReportBlockRow from "@/views/reports/components/reportSections/reportBlock/ReportBlockRow.vue";

import { determineThresholdColor } from "@/utils/performanceBandCalculator";
import { sumEntries, createPercentage } from "@/views/reports/utils/calculate";
import i18n from "@/plugins/i18n";

const blockColors = [
  "light-blue",
  "medium-blue",
  "dark-blue",
  "dark-dark-blue",
  "dark-dark-dark-blue",
  "dark-dark-dark-dark-blue"
];

export default {
  mixins: [reportMixin],
  components: {
    ReportPerformanceThresholds,
    ReportBlockRow
  },
  data() {
    return {
      compatibleReportId: 30,
      reportFilters: ["timeframe", "locations", "shifts"],
      hiddenReportActions: ["csvExport", "rawExport"],
      forcedReportFilters: ["shifts"],
      performanceThresholds: {},
      items: [],
      shiftsRow: [],
      combinedRow: [],
      previousShifts: null
    };
  },
  computed: {
    ...mapState("reports", ["loadingFilters"])
  },
  created() {
    if (!this.loadingFilters) {
      this.adjustSelectedShifts();
    }
  },
  destroyed() {
    this.revertPreviousShifts();
  },
  watch: {
    loadingFilters() {
      if (!this.loadingFilters) {
        this.adjustSelectedShifts();
      }
    }
  },
  methods: {
    adjustSelectedShifts() {
      this.previousShifts = this.$store.state["filters/shifts"].activeIds;

      const urlQueryFilters = this.$route?.query?.filters;
      const shiftIds = urlQueryFilters?.includes("shiftIds")
        ? JSON.parse(urlQueryFilters).shiftIds
        : this.$store.state["filters/shifts"].allIds;

      this.$store.commit("filters/shifts/set", {
        field: "activeIds",
        value: shiftIds
      });
    },
    revertPreviousShifts() {
      this.$store.commit(`filters/shifts/set`, {
        field: "activeIds",
        value: this.previousShifts
      });
    },
    handleReportResponse() {
      this.performanceThresholds =
        this.reportResponse.performanceThresholds || {};

      this.items = this.reportResponse.dataPoints.map((item) => {
        return {
          ...item,
          compliance: item.opportunities ? item.compliance : null
        };
      });

      const totalDispenses = sumEntries({
        entries: this.items,
        inputField: "dispenses"
      });
      const totalOpportunities = sumEntries({
        entries: this.items,
        inputField: "opportunities"
      });
      const totalPercentage = createPercentage({
        dividend: totalDispenses,
        divisor: totalOpportunities
      });

      this.shiftsPerformance = totalPercentage;

      this.organizeShiftsRow();
      this.organizeCombinedRow();
    },
    organizeShiftsRow() {
      const blocks = [];

      this.items.forEach((item, index) => {
        blocks.push({
          title: `${item.shiftName} ${i18n.t("performance.singular")}`,
          body: item.compliance,
          display: "Percentage",
          bgColor: `tw-bg-smartlink-shift-comparison-${blockColors[index]}`,
          thresholdColor: determineThresholdColor(
            this.performanceThresholds?.thresholds,
            item.compliance
          )
        });
      });

      this.shiftsRow = blocks;
    },
    organizeCombinedRow() {
      const blocks = [
        {
          title: i18n.t("reports.combined-performance"),
          body: this.shiftsPerformance,
          display: "Percentage",
          bgColor: "tw-bg-smartlink-summary-blue-light",
          thresholdColor: determineThresholdColor(
            this.performanceThresholds?.thresholds,
            this.shiftsPerformance
          )
        }
      ];

      this.combinedRow = blocks;
    }
  }
};
</script>
