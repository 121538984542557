import { post } from "@/api/apiClient";

let filterRequests = [];
const filterResponseCache = {};
let debounceTimer = undefined;
const debounceDelay = 10;

function GetFilterFromCache(facilityId, filterName) {
  return (
    filterResponseCache[facilityId] &&
    filterResponseCache[facilityId][filterName]
  );
}

function cacheFilterResponse(facilityId, response) {
  filterResponseCache[facilityId] = {
    ...filterResponseCache[facilityId],
    ...response
  };
}

function setDebounceTimer() {
  debounceTimer = setTimeout(fetchFilters, debounceDelay);
}

function clearDebounceTimer() {
  if (typeof debounceTimer === "number") {
    clearTimeout(debounceTimer);
    debounceTimer = undefined;
  }
}

async function fetchFilters() {
  const filterRequestsCopy = filterRequests;
  filterRequests = [];
  debounceTimer = undefined;
  try {
    const uniqueFacilityIds = Array.from(
      new Set(filterRequestsCopy.map((r) => r.facilityId))
    );
    await Promise.all(
      uniqueFacilityIds.map((facilityId) => {
        return new Promise((resolve, reject) => {
          const facilityFilterRequests = Array.from(
            new Set(
              filterRequestsCopy
                .filter((r) => r.facilityId === facilityId)
                .map((r) => r.filterName)
            )
          );
          return post("reportFilters", {
            facilityId,
            requestedFilters: facilityFilterRequests
          })
            .then((response) => {
              cacheFilterResponse(facilityId, response);
              resolve(filterResponseCache[facilityId]);
            })
            .catch((reason) => {
              reject(reason);
            });
        });
      })
    );
    filterRequestsCopy.forEach((request) => {
      request.resolve(
        filterResponseCache[request.facilityId][request.filterName]
      );
    });
  } catch {
    filterRequestsCopy.forEach((request) => {
      request.reject();
    });
  }
}

export default function (filterName) {
  return {
    async list({ facilityId } = {}) {
      if (!facilityId) {
        throw new Error("Facility ID is required");
      }
      const cachedResponse = GetFilterFromCache(facilityId, filterName);
      if (cachedResponse) {
        return cachedResponse;
      }

      clearDebounceTimer();

      let resolveCallback;
      let rejectCallback;
      const promise = new Promise((resolve, reject) => {
        resolveCallback = resolve;
        rejectCallback = reject;
      });
      filterRequests.push({
        facilityId,
        filterName,
        resolve: resolveCallback,
        reject: rejectCallback
      });
      setDebounceTimer();
      return await promise;
    }
  };
}
