import AlertStatus from "@/views/reports/SA/AlertStatus.vue";
import BatteryAlerts from "@/views/reports/SA/BatteryAlerts.vue";
import DeviceAlerts from "@/views/reports/SA/DeviceAlerts.vue";
import DispenserUsage from "@/views/reports/SA/DispenserUsage.vue";
import ExpirationAlerts from "@/views/reports/SA/ExpirationAlerts.vue";
import OfflineDevices from "@/views/reports/SA/OfflineDevices.vue";
import RefillAlerts from "@/views/reports/SA/RefillAlerts.vue";
import FacilitySummary from "@/views/reports/SA/FacilitySummary.vue";

export const SA_VUE_COMPONENTS = {
  FacilitySummary,
  AlertStatus,
  DeviceAlerts,
  RefillAlerts,
  OfflineDevices,
  BatteryAlerts,
  ExpirationAlerts,
  DispenserUsage
};
