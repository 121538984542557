<template>
  <v-navigation-drawer
    absolute
    temporary
    v-model="visible"
    name="go-navigation-drawer"
    style="height: 100vh"
  >
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title ref="app-drawer-username" class="title">
            {{ $store.state.user.userName }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list
      v-if="menuItems.primaryMenuItems && menuItems.primaryMenuItems.length"
    >
      <v-list-item
        v-for="item in menuItems.primaryMenuItems"
        :key="item.href"
        text
        :name="item.name"
        :href="item.href"
      >
        <v-list-item-content>
          {{ $t(item.translationKey) }}
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider
      ref="primary-secondary-divider"
      v-if="menuItems.primaryMenuItems.length > 0"
    />

    <v-list>
      <v-list-item
        v-for="item in menuItems.secondaryMenuItems"
        :key="item.href"
        :href="item.href"
      >
        <v-list-item-title>
          {{ $t(item.translationKey) }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        ref="app-drawer-logout-menu"
        @click="$emit('log-out')"
        name="go-logout-btn"
      >
        <v-list-item-title>
          {{ $t("navigation.log-out") }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list>
      <drawer-language-select />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import DrawerLanguageSelect from "../navigationDrawer/components/DrawerLanguageSelect.vue";
import { menuItems } from "./menuItems";

export default {
  components: {
    DrawerLanguageSelect
  },
  computed: {
    menuItems() {
      return menuItems(this.$store, this.$router);
    },
    visible: {
      get() {
        return this.$store.state.application.navDrawerVisible;
      },
      set(value) {
        this.$store.commit("application/SET_NAV_DRAWER", value);
      }
    }
  }
};
</script>
