export const TIMEFRAMES = {
  CUSTOM: 0,
  LAST_24_HOURS: 1,
  LAST_7_DAYS: 2,
  LAST_30_DAYS: 3,
  LAST_MONTH: 4,
  TODAY: 5,
  LAST_90_DAYS: 6,
  LAST_6_MONTHS: 7,
  LAST_12_MONTHS: 8,
  YESTERDAY: 9
};

export const TIMEFRAMES_STRINGS = {
  custom: TIMEFRAMES.CUSTOM,
  last24Hours: TIMEFRAMES.LAST_24_HOURS,
  today: TIMEFRAMES.TODAY,
  lastSevenDays: TIMEFRAMES.LAST_7_DAYS,
  lastThirtyDays: TIMEFRAMES.LAST_30_DAYS,
  lastNinetyDays: TIMEFRAMES.LAST_90_DAYS,
  lastSixMonths: TIMEFRAMES.LAST_6_MONTHS,
  lastTwelveMonths: TIMEFRAMES.LAST_12_MONTHS,
  yesterday: TIMEFRAMES.YESTERDAY
};
