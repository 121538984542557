export function scrollToFirstError($el) {
  const rect = $el.getBoundingClientRect();
  const isInViewport =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  if (!isInViewport) {
    $el
      .querySelector(".v-input.error--text:first-of-type input")
      .scrollIntoView()
      .focus();
  }
}
