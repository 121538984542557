import PerformanceHistory from "@/views/reports/AMS/PerformanceHistory.vue";
import PerformanceBarGraph from "@/views/reports/AMS/PerformanceBarGraph.vue";
import PerformanceSummary from "@/views/reports/AMS/PerformanceSummary.vue";
import RoomLevelMetrics from "@/views/reports/AMS/RoomLevelMetrics.vue";
import DispenserEvents from "@/views/reports/AMS/DispenserEvents.vue";
import DeviceMetrics from "@/views/reports/AMS/DeviceMetrics.vue";
import ShiftComparison from "@/views/reports/AMS/ShiftComparison.vue";

export const AMS_VUE_COMPONENTS = {
  PerformanceHistory,
  PerformanceBarGraph,
  PerformanceSummary,
  RoomLevelMetrics,
  DispenserEvents,
  DeviceMetrics,
  ShiftComparison
};
