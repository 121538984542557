import { get, post, put } from "./apiClient";

export default {
  async list({ facilityId } = {}) {
    if (!facilityId) {
      throw new Error("Facility ID is required");
    }

    return await get(`Employees?facilityId=${facilityId}`);
  },
  async getEmployeesByCustomerId(customerId) {
    return await get(`Employees/${customerId}`);
  },
  async getUniqueIdentifier(customerId) {
    return await get(`Employee/Identifier/${customerId}`);
  },
  async saveEmployee(employee) {
    if (employee.id) {
      return await put(`Employee`, employee);
    } else {
      return await post(`Employee`, employee);
    }
  },
  async get(employeeId) {
    return await get(`Employee/${employeeId}`);
  }
};
